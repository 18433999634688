import React, { Component } from 'react';
import { Container, Card, Row, Col, Form, Button, ButtonGroup, Spinner, Modal } from 'react-bootstrap';
import { t } from '../../helpers/translation_helper';
import NavBar from '../_partials/NavBar/_NavBar';
import Header from '../_partials/Header/_Header';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faCheckCircle, faSearch, faUser, faXmark, faLayerGroup, faHandsHoldingChild, faPeopleGroup, faListCheck, faCircleCheck, faEllipsis, faAngleRight, faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { brand_colors } from '../../helpers/brand_colors_helper';
import { Tooltip as Tooltiper } from 'react-tooltip';
import { NavLink } from 'react-router-dom';
import { getStudents, setStudents } from '../../redux/slices/studentListSlice';
import { changeColorOpacity, isTeacher } from '../../helpers/general_helpers';
import { handleFormErrors, setTxtField } from '../../helpers/form_helpers';
import { colorPalette } from '../../constants/colorsPalette';
import axios from 'axios';
import { toast } from 'react-toastify';
import { GET } from '../../api';
import ReactPaginate from 'react-paginate';
import Pagination from '../_partials/Pagination';

const portalURL = process.env.REACT_APP_PORTAL_URL;
const apiURL = process.env.REACT_APP_API_URL;


class StudentGroups extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchExpanded: false,
            workingIndex: [],
            cdt: {
                rows: [10, 25, 50, 'All'],
            },
            showGroupModal: false,
            showColorPicker: false,
            stdgrp_id: '',
            stdgrp_name: '',
            stdgrp_desc: '',
            stdgrp_status: true,
            stdgrp_color: 'rgb(0, 0, 0)',
            selectedSubject: this.props.selectedClass.academic_classmain_subject[0],
            groups: null
        }
        this.searchFieldRef = React.createRef();
    }

    componentDidMount() {
        this.getGroups(this.props.selectedClass.academic_classmain_subject[0]);
        this.getStudents({
            rows: this.props.studentListRows,
            page: 0,
            search: '',
            status: this.props.studentListStatus
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.selectedClass && prevProps.selectedClass.class.class_id !== this.props.selectedClass.class.class_id) {
            this.getStudents({
                rows: this.props.studentListRows,
                page: 0,
                search: '',
                status: this.props.studentListStatus
            });
            this.getGroups(this.props.selectedClass.academic_classmain_subject[0]);
            this.setState({ searchExpanded: false });
            this.searchFieldRef.current.value = '';
        }
    }

    getStudents = (params) => {
        this.props.getStudents({
            child_status: params.status,
            search: params.search,
            limit: params.rows,
            page: params.page,
            studentgroup: true
        });
    }

    handleOpenGroupModal = () => {
        this.setState({ showGroupModal: true })
    }

    handleCloseGroupModal = () => {
        this.setState({ showGroupModal: false });
        this.resetForm();
    }

    handleOpenColorPicker = () => {
        this.setState({ showColorPicker: true })
    }

    handleCloseColorPicker = () => {
        this.setState({ showColorPicker: false })
    }

    addUpdateGroup = async () => {

        const { authData, selectedClass } = this.props;
        const { auth_key } = authData.loginData;
        const { employee_id } = authData.userData;
        const { center_id } = authData.centerData;
        const { class_id } = selectedClass.class;
        const {
            stdgrp_id,
            stdgrp_name,
            stdgrp_desc,
            stdgrp_status,
            stdgrp_color,
            selectedSubject
        } = this.state;

        const fData = new FormData();
        fData.append('center_id', center_id);
        fData.append('studentgroup', JSON.stringify({
            employee_id,
            class_id,
            acs_id: selectedSubject.acs_id,
            stdgrp_id: 0,
            stdgrp_name,
            stdgrp_desc,
            stdgrp_status: stdgrp_status ? 1 : 0,
            stdgrp_color,
        }));

        try {

            const res = await axios.post(apiURL + 'student-group/save', fData, {
                params: {
                    auth_key: auth_key,
                    appname: process.env.REACT_APP_NAME,
                },
            });


            if (res.status === 200) {
                if (!res.data.type) {
                    handleFormErrors(res.data.data);
                    return;
                }
                this.handleCloseGroupModal();
                this.getGroups(this.state.selectedSubject);
            }


        } catch (err) {
            console.log('err', err)

        }

    }

    getGroups = async (sub) => {

        this.setState({ selectedSubject: sub, groups: null });

        const { authData, selectedClass } = this.props;
        const { center_id } = authData.centerData;
        const { auth_key } = authData.loginData;
        const { employee_id } = authData.userData;
        const { class_id } = selectedClass.class;


        if (sub === undefined) {
            this.setState({
                groups: []
            });
            return;
        }

        const { acs_id } = sub;


        try {
            const res = await GET('student-group/fetch', {
                params: {
                    class_id,
                    employee_id,
                    acs_id,
                },
            });

            if (res.status === 200) {
                this.setState({
                    groups: res.data
                });
            }

        } catch (err) {
            console.log('err', err)

        }
    }

    handleChangeCheckField = (e) => {
        this.setState(
            {
                [e.target.name]: this.state[e.target.name] == 0 ? 1 : 0,
            }
        );
    };

    toggleGroup = async (stdgrp_id, student, studentIndex) => {

        this.setState({ workingIndex: [studentIndex] });

        const { authData, studentListData: students } = this.props;
        const { auth_key } = authData.loginData;
        const { center_id } = authData.centerData;
        const fData = new FormData();

        fData.append('center_id', center_id);
        fData.append('child_id', student.child_id);
        fData.append('stdgrp_id', stdgrp_id);

        try {

            const res = await axios.post(apiURL + 'student-group/toggle-group-student', fData, {
                params: {
                    auth_key: auth_key,
                    appname: process.env.REACT_APP_NAME,
                },
            });


            if (res.status === 200) {
                toast.success(t(res.data.message));

                let updatedStudents = [...students];
                let newV;

                const findIndex = students.findIndex((studentItem) => studentItem.child_id == student.child_id);

                if (res.data.message == 'Group removed!') {
                    newV = { ...student, studentgroups: [...student.studentgroups] };
                    const findGroupIndex = newV.studentgroups.findIndex((group) => group.stdgrp_id == stdgrp_id);
                    newV.studentgroups.splice(findGroupIndex, 1);
                } else {
                    newV = { ...student, studentgroups: [...student.studentgroups, { stdgrp_id }] };
                }

                updatedStudents[findIndex] = newV;

                this.props.setStudents(updatedStudents);

                this.setState({ workingIndex: [] });

            }

        } catch (err) {
            console.log('err', err)

            this.setState({ workingIndex: [] });
        }
    }

    checkGroup = (stdgrp_id, student) => {
        const studentgroups = student.studentgroups;
        if (studentgroups) {
            return studentgroups.find((grp) => grp.stdgrp_id == stdgrp_id);
        }
        return null
    }

    resetForm = () => {
        this.setState({
            stdgrp_id: '',
            stdgrp_name: '',
            stdgrp_desc: '',
            stdgrp_status: true,
            stdgrp_color: 'rgb(0, 0, 0)',
        });
    }

    render() {
        return (
            <>
                <Card
                    className='border-0 mb-4'
                    style={{ borderRadius: '20px' }}>
                    <Card.Body>
                        {this.props.selectedClass.academic_classmain_subject.length > 0 && this.props.selectedClass.academic_classmain_subject.map((item, index) =>
                            <Button key={index} className='me-2' variant={this.state.selectedSubject && this.state.selectedSubject.acs_name === item.acs_name ? 'primary' : 'light'} onClick={() => {
                                this.getGroups(item);
                            }}>{t(item.acs_name)}</Button>
                        )}
                        {this.props.selectedClass.academic_classmain_subject.length == 0 &&
                            <Button disabled={true} className='me-2' variant='light'>{t('No Subjects Available!')}</Button>
                        }
                    </Card.Body>
                </Card>
                <Card
                    className='border-0'
                    style={{ borderRadius: '20px' }}>
                    <Card.Body>
                        <Row>
                            <Col md={6} className='d-flex flex-column'>
                                <div className='d-flex flex-row align-items-center mb-2'>
                                    <strong>{t("Groups")}</strong>
                                    <div className='expandable-search ms-2' style={{ width: this.state.searchExpanded ? 200 : 35 }}>
                                        <input
                                            ref={this.searchFieldRef}
                                            defaultValue={this.props.studentListSearch}
                                            onChange={(e) => this.getStudents({
                                                rows: this.props.studentListRows,
                                                page: 0,
                                                search: e.target.value,
                                                status: this.props.studentListStatus
                                            })}
                                            type='text'
                                            style={{ width: this.state.searchExpanded ? 165 : 0, paddingLeft: this.state.searchExpanded ? 15 : 0, opacity: this.state.searchExpanded ? 1 : 0 }}
                                            placeholder={t('Search Student...')} />
                                        <button
                                            data-tooltip-id="tooltip"
                                            data-tooltip-content={t('Search Student')}
                                            data-tooltip-place="top"
                                            onClick={() => {
                                                this.setState({ searchExpanded: !this.state.searchExpanded });
                                                this.props.studentListSearch != '' && this.state.searchExpanded && this.getStudents({
                                                    rows: this.props.studentListRows,
                                                    page: 0,
                                                    search: '',
                                                    status: this.props.studentListStatus
                                                });
                                                this.searchFieldRef.current.value = '';
                                                !this.state.searchExpanded && this.searchFieldRef.current.focus();
                                            }}>
                                            <FontAwesomeIcon
                                                icon={this.state.searchExpanded ? faXmark : faSearch}
                                                color={brand_colors[this.props.defaultTheme.theme_id].color18}
                                            />
                                        </button>
                                    </div>
                                </div>
                                <div className='d-flex flex-row align-items-center'>
                                    <div>
                                        {t('Show')}
                                        <Form.Select
                                            value={this.props.studentListRows}
                                            size="sm"
                                            onChange={(e) => this.getStudents({
                                                rows: e.target.value,
                                                page: 0,
                                                search: this.props.studentListSearch,
                                                status: this.props.studentListStatus
                                            })}
                                            style={{ width: 70, marginInline: 5, display: 'inline-block' }}>
                                            {this.state.cdt.rows.map((row, row_index) => (<option key={row_index} >{row}</option>))}
                                        </Form.Select>
                                        {t('Records')}
                                    </div>
                                </div>
                            </Col>
                            <Col md={6} className='d-flex justify-content-end align-items-start'>
                                <button
                                    onClick={() => this.handleOpenGroupModal()}
                                    className='btn btn-success btn-sm me-2'><FontAwesomeIcon icon={faPlus} color={brand_colors[this.props.defaultTheme.theme_id].color8} style={{ fontSize: 12 }} />{' '}{t('Add Group')}</button>
                                <ButtonGroup size='sm'>
                                    <Button
                                        onClick={() => this.getStudents({
                                            rows: this.props.studentListRows,
                                            status: true,
                                            page: 0,
                                            search: this.props.studentListSearch
                                        })}
                                        variant={this.props.studentListStatus ? 'success' : 'light'}
                                    >{t('Only Active')}</Button>
                                    <Button
                                        onClick={() => this.getStudents({
                                            rows: this.props.studentListRows,
                                            status: false,
                                            page: 0,
                                            search: this.props.studentListSearch
                                        })}
                                        variant={this.props.studentListStatus ? 'light' : 'success'}
                                    >{t('Show All')}</Button>
                                </ButtonGroup>
                            </Col>
                            {this.props.studentListFetching &&
                                <Col md={12} className='py-5 d-flex flex-column align-items-center'>
                                    <Spinner as='span' animation='grow' size='sm' /> {t('Loading Students')}
                                </Col>
                            }
                            {!this.props.studentListFetching &&
                                <Col md={12} className='pt-1 student-attendance-list'>
                                    {this.props.studentListData.map((student, student_index) => (
                                        <Row key={student_index}
                                            style={{ opacity: this.state.workingIndex.includes(student_index) ? 0.5 : 1 }}
                                            className='student-attendance-list-item'>
                                            <Col md={3} className='d-flex align-items-center'>
                                                <div style={{
                                                    backgroundImage: `url(${(student.picture) ? portalURL + student.center_id + '/children/' + student.picture : 'https://via.placeholder.com/150x150?text=' + student.child_name[0].toUpperCase()})`,
                                                    width: 60,
                                                    height: 60,
                                                    backgroundSize: 'cover',
                                                    backgroundPosition: 'center center',
                                                    borderRadius: 30,
                                                    marginRight: 15
                                                }}></div>
                                                {student.child_name}
                                                {this.state.workingIndex.includes(student_index) &&
                                                    <Spinner animation="border" size='sm' variant="dark" className='ms-3' />
                                                }
                                            </Col>
                                            <Col md={9} className='d-flex align-items-center'>
                                                <div className=''>
                                                    {this.state.groups == null && (
                                                        <Spinner animation="border" size="sm" />
                                                    )}
                                                    {this.state.groups && this.state.groups.length > 0 &&
                                                        <>
                                                            {this.state.groups.map((grp, grp_i) =>
                                                                <div
                                                                    className='group'
                                                                    key={grp_i}
                                                                    title={grp.stdgrp_name}
                                                                    onClick={() => this.toggleGroup(grp.stdgrp_id, student, student_index)}
                                                                    style={{ backgroundColor: changeColorOpacity(grp.stdgrp_color, 0.3), color: grp.stdgrp_color, fontSize: 12, fontWeight: '600' }}>
                                                                    {grp.stdgrp_name}
                                                                    {this.checkGroup(grp.stdgrp_id, student) &&
                                                                        <FontAwesomeIcon icon={faCircleCheck} color={grp.stdgrp_color} />
                                                                    }
                                                                </div>)}
                                                        </>
                                                    }
                                                    {this.state.groups && this.state.groups.length == 0 &&
                                                        <div>{t('No Groups Found!')}</div>
                                                    }
                                                </div>
                                            </Col>
                                        </Row>
                                    ))}
                                </Col>
                            }
                        </Row>
                        <Pagination
                            pageCount={this.props.studentListLastPage}
                            forcePage={this.props.studentListPage}
                            callbackParams={{
                                rows: this.props.studentListRows,
                                status: this.props.studentListStatus,
                                search: this.props.studentListSearch
                            }}
                            callback={this.getStudents}
                        />
                        <Modal show={this.state.showGroupModal} onHide={this.handleCloseGroupModal}>
                            <Modal.Header closeButton>
                                <Modal.Title>{t('Create Group')}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Row>
                                    <Col md={8}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>{t('Name')}</Form.Label>
                                            <Form.Control type="text" defaultValue={this.state.stdgrp_name} onChange={(e) => {
                                                setTxtField('stdgrp_name', e.target.value, this);
                                            }} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={2}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>{t('Color')}</Form.Label>
                                            <div className='selected-color' onClick={() => {
                                                this.handleOpenColorPicker();
                                            }}>
                                                <div style={{ backgroundColor: this.state.stdgrp_color }}></div>
                                            </div>
                                            {this.state.showColorPicker &&
                                                <div className='color-palette'>
                                                    {colorPalette.map((color, color_i) => (
                                                        <div
                                                            key={color_i}
                                                            onClick={() => {
                                                                this.setState({
                                                                    stdgrp_color: color.code,
                                                                    showColorPicker: false
                                                                })
                                                            }}
                                                            style={{ backgroundColor: color.code }}
                                                        ></div>
                                                    ))}
                                                </div>
                                            }
                                        </Form.Group>
                                    </Col>
                                    <Col md={2}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>{t('Status')}</Form.Label>
                                            <Form.Check
                                                type='switch'
                                                onChange={
                                                    this
                                                        .handleChangeCheckField
                                                }
                                                name='stdgrp_status'
                                                defaultChecked={this.state.stdgrp_status}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={12}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>{t('Description')}</Form.Label>
                                            <Form.Control type="text" defaultValue={this.state.stdgrp_desc} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={this.handleCloseGroupModal}>
                                    {t('Close')}
                                </Button>
                                <Button variant="primary" onClick={() => {
                                    this.addUpdateGroup();
                                }}>
                                    {t('Add')}
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </Card.Body>
                </Card>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    authData: state.auth.authData,
    selectedClass: state.selectedClass.data,
    defaultLanguage: state.language.defaultLanguage,
    defaultTheme: state.theme.defaultTheme,
    studentListFetching: state.studentList.fetching,
    studentListData: state.studentList.data,
    studentListSearch: state.studentList.query,
    studentListRows: state.studentList.rows,
    studentListOffset: state.studentList.offset,
    studentListStatus: state.studentList.status,
    studentListOrder: state.studentList.order,
    studentListDir: state.studentList.dir,
    studentListLastPage: state.studentList.last_page,
    studentListPage: state.studentList.page,
    studentListTotalRecords: state.studentList.total_records,
});

const mapDispatchToProps = () => ({
    getStudents,
    setStudents
})

export default connect(mapStateToProps, mapDispatchToProps())(StudentGroups);
