import React, { Component } from 'react';
import { Container, Card, Row, Col, Badge, Button, Modal, Form, Spinner, Tabs, Tab, Alert, ListGroup, Offcanvas, ButtonGroup, } from 'react-bootstrap';
import { t } from '../../helpers/translation_helper';
import NavBar from '../_partials/NavBar/_NavBar';
import Header from '../_partials/Header/_Header';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch, faTimesCircle, faPlus, faCloudArrowUp, faEdit, faGlobe, faArrowUpRightFromSquare, faEye, faFilePdf, faPeopleGroup, faInfoCircle, faFolder, faAngleLeft, faUpDownLeftRight, faFile, faBarsStaggered, faRefresh } from '@fortawesome/free-solid-svg-icons';
import { faCircle, faCheckCircle, faCircleDot, faComments, faTrashCan, faShareFromSquare, } from '@fortawesome/free-regular-svg-icons';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { brand_colors } from '../../helpers/brand_colors_helper';
import { handleFormErrors } from '../../helpers/form_helpers';

import imgAddButton from '../../assets/images/add-button-small.png';
import imgHyperlink from '../../assets/images/hyperlink-small.png';
import imgAttachment from '../../assets/images/attachment-small.png';
import imgLabelTag from '../../assets/images/label-tag.png';
import imgCalendar from '../../assets/images/calendar.png';
import imgDescription from '../../assets/images/description.png';

import { withParams } from '../../helpers/params_helper';
import axios from 'axios';
import { file } from 'jszip';
import { Chance } from 'chance';
import SweetAlert from 'react-bootstrap-sweetalert';
import { GET, POST } from '../../api';
import { changeColorOpacity, getExtension } from '../../helpers/general_helpers';
import { sendCloudMessage } from '../../helpers/cloud_message';
import { resolveObjectURL } from 'buffer';
import GeneralCard from '../../helpers/GeneralCard';
const chance = new Chance();

// Vars
const apiURL = process.env.REACT_APP_API_URL;
const portalURL = process.env.REACT_APP_PORTAL_URL;
const controller = 'academic-subject-lessons';
const imgDir = 'academic_subject_lessons';

/**
 * Lesson Component
 */
class Lesson extends Component {

  constructor(props) {

    super();

    let lessonData = props.location.state.lessonData

    console.log('lessonData', lessonData);
    //  States
    this.state = {
      readonly: false,
      working: false,
      modalResources: {
        show: false,
        toggle: (parent, child) => this.setState({ modalResources: { ...this.state.modalResources, show: !this.state.modalResources.show }, formSectionActiveModal: { parent: parent, child: child } })
      },
      modalAddResource: {
        show: false,
        title: '',
        parent: null,
        child: null,
        toggle: (title = '', parent = null, child = null, onSave = () => { }) => this.setState({ modalAddResource: { ...this.state.modalAddResource, show: !this.state.modalAddResource.show, title, parent, child, onSave } }),
        onSave: () => { }
      },
      selectedResource: null,
      deleteModal: { show: false, id: 0, action: () => { } },
      addModal: { show: false, title: null, id: 0, action: () => { }, allData: [], selectedData: [], onClose: () => this.setState({ addModal: { ...this.state.addModal, show: false } }) },
      getAcademicSubjectLessonFlow: {
        folders: [],
        data: [],
        activeFolder: null,
      },
      externalResource: [
        { value: 'youtube', label: 'Youtube' },
        { value: 'google_drive', label: 'Google Drive' },
        { value: 'my_folder', label: 'Other' },
        { value: 'attachment', label: 'Attachment' },
      ],
      examTypes: [],
      termsDefault: [],
      curriculum: [],
      // Data
      academicLessonAttributes: [],
      getAcademicClassmainSubjectsData: [],
      getSubjectAssignedEmployeesData: [],
      // Lesson Form fields
      academicSubjectLessonResources: [],
      getLessonResourcesLoading: false,
      academicSubjectLessonExams: [],
      getLessonExamsLoading: false,
      academicSubjectLessons: {
        asl_id: lessonData.asl_id,
        acs_id: lessonData.acs_id,
        as_id: lessonData.as_id,
        asl_name: lessonData.asl_name,
        asl_coef: lessonData.asl_coef,
        asl_code: lessonData.asl_code,
        center_id: lessonData.center_id,
        asl_period: lessonData.asl_period,
        asl_attach: lessonData.asl_attach,
        asl_link: lessonData.asl_link,
        asl_order: lessonData.asl_order,
        asl_sen_required: lessonData.asl_sen_required,
        asl_ict_usage: lessonData.asl_ict_usage,
        asl_afl: lessonData.asl_afl,
        asl_notes1: lessonData.asl_notes1,
        asl_notes2: lessonData.asl_notes2,
        asl_notes3: lessonData.asl_notes3,
        asl_type1: lessonData.asl_type1,
        asl_type2: lessonData.asl_type2,
        asl_theme: lessonData.asl_theme,
        asl_chat_blog: lessonData.asl_chat_blog,
        asl_identity: lessonData.asl_identity,
        asl_differentiation: lessonData.asl_differentiation,
        asl_warmup: lessonData.asl_warmup,
        asl_objectives_description: lessonData.asl_objectives_description,
        asl_preparation: lessonData.asl_preparation,
        asl_usefulness: lessonData.asl_usefulness,
        asl_steps: lessonData.asl_steps,
        asl_ending: lessonData.asl_ending,
        asl_homeworks: lessonData.asl_homeworks,
        asl_oral: lessonData.asl_oral,
        asl_activities: lessonData.asl_activities,
        asl_written_work: lessonData.asl_written_work,
        asl_student_work: lessonData.asl_student_work,
        asl_dual_assessment: lessonData.asl_dual_assessment,
        asl_self_assessment: lessonData.asl_self_assessment,
        asl_objectives_list: lessonData.asl_objectives_list,
        asl_drop_quiz: lessonData.asl_drop_quiz,
        agegroup_id: lessonData.agegroup_id,
        agegroup_type: lessonData.agegroup_type,
        asl_acs_ids: [],
        asl_aa_ids: []
      },
      image: lessonData.asl_attach,
      image_prev: portalURL + lessonData.center_id + '/' + imgDir + '/' + lessonData.asl_attach,
      image_deleted: 0,
      aslr_attachment: null,
      type1Opts: [
        { value: 'Interdisciplinary', label: 'Interdisciplinary' },
        { value: 'Subject', label: 'Subject' },
      ],
      type2Opts: [
        { value: 'Standalone', label: 'Standalone' },
        { value: 'Inquiry', label: 'Inquiry' },
      ],
      questionTypes: ['Conceptual', 'Debatable', 'Definitional', 'Factual'],
      sectionGroup: lessonData.agegroup_type,// General , All
      formSectionActive: {
        parent: '1',
        child: '1.1'
      },
      formSectionActiveModal: {
        parent: '1',
        child: '1.1'
      },
      formSections: [
        {
          uid: '1',
          sectionGroup: ['General', 'Primary Year Programme', 'Middle Year Programme', 'Diploma Programme', 'UAE MOE'],
          active: true,
          completed: false,
          name: 'Basics',
          desc: null,
          data: [
            {
              uid: '1.1',
              sectionGroup: ['General', 'Primary Year Programme', 'Middle Year Programme', 'Diploma Programme', 'UAE MOE'],
              active: false,
              completed: true,
              name: 'Lesson Focus',
              desc: null,
              image: require('../../assets/images/lesson/lesson-focus.png'),
              showSaveBtn: true,
              saveAction: () => this.updateLesson()
            },
            {
              uid: '1.2',
              sectionGroup: ['General', 'Primary Year Programme', 'Middle Year Programme', 'Diploma Programme', 'UAE MOE'],
              active: false,
              completed: false,
              name: 'Central Idea',
              desc: null,
              image: require('../../assets/images/lesson/idea.png'),
              showSaveBtn: true,
              saveAction: () => this.updateLesson('central_idea')
            },
            {
              uid: '1.3',
              sectionGroup: ['General', 'Primary Year Programme', 'Middle Year Programme', 'Diploma Programme', 'UAE MOE'],
              active: false,
              completed: false,
              name: 'Lesson Resources',
              enum: 'external_resource',
              desc: '',
              image: require('../../assets/images/lesson/hyperlink.png'),
              saveAction: () => { this.insertLessonResource(this.state.academicSubjectLessonResources.findIndex((v, i) => v.aslr_type == 'external_resource' && v.editing)) }
            },
            {
              uid: '1.4',
              sectionGroup: ['General', 'Primary Year Programme', 'Middle Year Programme', 'Diploma Programme', 'UAE MOE'],
              active: false,
              completed: false,
              name: 'Teaching Resources',
              enum: 'teaching_resource',
              desc: ['What is it we want students to know, understand and be able to do? How are learning goals and success criteria co-constructed between teachers and students?'],
              image: require('../../assets/images/lesson/hyperlink.png'),
              saveAction: () => { this.insertLessonResource(this.state.academicSubjectLessonResources.findIndex((v, i) => v.aslr_type == 'teaching_resource' && v.editing)) }
            },
            // {
            //   uid: '1.5',
            //   sectionGroup: ['Primary Year Programme', 'Middle Year Programme', 'Diploma Programme'],
            //   active: false,
            //   completed: false,
            //   name: 'Transdisciplinary Theme',
            //   enum: 'transdisciplinary_theme',
            //   image: require('../../assets/images/lesson/line-chart.png'),
            //   showSaveBtn: true,
            //   saveAction: () => this.saveMultiResources('transdisciplinary_theme')
            // },
          ]
        },
        {
          uid: '2',
          sectionGroup: ['General', 'Primary Year Programme', 'Middle Year Programme', 'Diploma Programme', 'UAE MOE'],
          active: false,
          completed: false,
          name: 'Learning Goals',
          desc: '',
          data: [
            {
              uid: '2.1',
              sectionGroup: ['Primary Year Programme', 'Middle Year Programme', 'Diploma Programme'],
              active: false,
              completed: false,
              name: 'Approaches to learning',
              enum: 'approaches_to_learning',
              image: require('../../assets/images/lesson/learning-approaches.png'),
              desc: (lessonData.agegroup_type == 'Middle Year Programme')
                ? 'The MYP extends IB approaches to learning (ATL) skills categories into 10 developmentally appropriate clusters. This framework provides common ground from which schools can develop their own ATL planning based on MYP units, student needs, and local circumstances and requirements. ATL skills are often interconnected. Individual skills and skills clusters frequently overlap and may be relevant to more than one skill category.'
                : 'The Approaches to Learning in PYP include transdisciplinary themes that are important for students learning both inside and outside the classroom. These are the tools that students use across all subject areas to develop into successful students. Students get the opportunity to practice these skills and reflect on how they apply these skills to their everyday learning.',
              showSaveBtn: true,
              saveAction: () => this.saveMultiResources('approaches_to_learning')
            },
            {
              uid: '2.2',
              sectionGroup: ['General', 'Primary Year Programme', 'Middle Year Programme', 'Diploma Programme'],
              active: false,
              completed: false,
              name: 'Scope & Sequences / Competencies',
              enum: 'competency',
              image: require('../../assets/images/lesson/scope-sequence.png'),
              desc: null,
              showSaveBtn: true,
              saveAction: () => this.saveMultiResources('competency')
            },
            {
              uid: '2.3',
              sectionGroup: ['General', 'Primary Year Programme', 'Middle Year Programme', 'Diploma Programme', 'UAE MOE'],
              active: false,
              completed: false,
              name: 'Learning Goals & Success Criteria',
              enum: 'ongoing_reflection',
              image: require('../../assets/images/lesson/goal.png'),
              desc: ['What is it we want students to know, understand and be able to do?', 'How are learning goals and success criteria co-constructed between teachers and students?'],
              saveAction: () => { this.insertLessonResource(this.state.academicSubjectLessonResources.findIndex((v, i) => v.aslr_type == 'ongoing_reflection' && v.editing)) }
            },
            {
              uid: '2.4',
              sectionGroup: ['General', 'Primary Year Programme', 'Middle Year Programme', 'Diploma Programme', 'UAE MOE'],
              active: false,
              completed: false,
              name: 'Key Vocab',
              enum: 'keyvocab',
              image: require('../../assets/images/lesson/hyperlink.png'),
              desc: [],
              saveAction: () => { this.insertLessonResource(this.state.academicSubjectLessonResources.findIndex((v, i) => v.aslr_type == 'keyvocab' && v.editing)) }
            },
            {
              uid: '2.5',
              sectionGroup: ['General', 'Primary Year Programme', 'Middle Year Programme', 'Diploma Programme', 'UAE MOE'],
              active: false,
              completed: false,
              name: 'Skills',
              enum: 'learnskill',
              image: require('../../assets/images/lesson/hyperlink.png'),
              desc: [],
              saveAction: () => { this.insertLessonResource(this.state.academicSubjectLessonResources.findIndex((v, i) => v.aslr_type == 'learnskill' && v.editing)) }
            }
          ]
        },
        {
          uid: '3',
          sectionGroup: ['Primary Year Programme', 'Middle Year Programme', 'Diploma Programme', 'UAE MOE'],
          active: false,
          completed: false,
          name: 'Learning Focus',
          desc: null,
          data: [
            {
              uid: '3.1',
              sectionGroup: ['Primary Year Programme', 'Middle Year Programme', 'Diploma Programme'],
              active: false,
              completed: false,
              name: 'Transdisciplinary Theme',
              enum: 'transdisciplinary_theme',
              image: require('../../assets/images/lesson/line-chart.png'),
              showSaveBtn: true,
              saveAction: () => this.saveMultiResources('transdisciplinary_theme')
            },
            {
              uid: '3.2',
              sectionGroup: ['Primary Year Programme', 'Middle Year Programme', 'Diploma Programme'],
              active: false,
              completed: false,
              name: 'Learner Profile',
              enum: 'learner_profile',
              image: require('../../assets/images/lesson/learner-profile.png'),
              desc: 'The aim of all IB programmes is to develop internationally minded people who, recognizing their common humanity and shared guardianship of the planet, help to create a better and more peaceful world. The IB learner profile represents 10 attributes valued by IB World Schools. We believe these attributes, and others like them, can help individuals and groups become responsible members of local, national and global communities.',
              showSaveBtn: true,
              saveAction: () => this.saveMultiResources('learner_profile')
            },
            {
              uid: '3.3',
              sectionGroup: ['Primary Year Programme', 'Middle Year Programme', 'Diploma Programme', 'UAE MOE'],
              active: false,
              completed: false,
              name: 'Key Concepts',
              enum: 'lesson_key_concept',
              image: require('../../assets/images/lesson/key-concepts.png'),
              desc: 'It help students to engage in abstract thinking and conceptual thinking to cope-with tricky ideas. Key Concepts can help learners to deal with knowledge in transdisciplinary themes, which the IB intends to encourage. This kind of learning is not limited to factual concepts and content, helping students to engage with broader concepts that can motivate inquisitive, creative and critical thinking.',
              showSaveBtn: true,
              saveAction: () => this.saveMultiResources('lesson_key_concept')
            },
            {
              uid: '3.4',
              sectionGroup: ['Primary Year Programme', 'Middle Year Programme', 'Diploma Programme', 'UAE MOE'],
              active: false,
              completed: false,
              name: 'Related Concepts',
              enum: 'related_concept',
              // related_concept_learn_area, related_concept_key_concept
              image: require('../../assets/images/lesson/related-concepts.png'),
              desc: ['A related concept deepens understanding of a key concept or a subject. As with key concepts some related concepts have relevance across other subjects and provide further opportunities to make connections across, between and beyond subjects. In contrast to the broad key concepts, related concepts are more narrowly focused.', 'Select related concepts for the lesson. You can select related concepts by subjects or by key concepts.'],
              showSaveBtn: true,
              saveAction: () => this.saveMultiResources('related_concept')
            },
            {
              uid: '3.5',
              sectionGroup: ['Primary Year Programme', 'Middle Year Programme', 'Diploma Programme'],
              active: false,
              completed: false,
              name: 'Lines of enquiry',
              enum: 'lines_of_enquiry',
              image: require('../../assets/images/lesson/inquiry.png'),
              desc: ['What lines of inquiry will define the scope of inquiry into the central idea?'],
              saveAction: () => { this.insertLessonResource(this.state.academicSubjectLessonResources.findIndex((v, i) => v.aslr_type == 'lines_of_enquiry' && v.editing)) }

            }
          ]
        },
        {
          uid: '4',
          sectionGroup: ['Primary Year Programme', 'Middle Year Programme', 'Diploma Programme', 'UAE MOE'],
          active: false,
          completed: false,
          name: 'Past & Future Learning',
          desc: null,
          data: [
            {
              uid: '4.1',
              sectionGroup: ['Primary Year Programme', 'Middle Year Programme', 'Diploma Programme', 'UAE MOE'],
              active: false,
              completed: false,
              name: 'Prior Learning',
              enum: 'prior_learning',
              image: require('../../assets/images/lesson/prior-learning.png'),
              desc: ['Collaboratively capture your ideas for assessing and evidencing prior learning', 'How are we assessing student’s prior knowledge, conceptual understanding and skills?', 'How are we using data and evidence of prior learning to inform planning?', 'How is our planning embracing student language profiles?'],
              saveAction: () => { this.insertLessonResource(this.state.academicSubjectLessonResources.findIndex((v, i) => v.aslr_type == 'prior_learning' && v.editing)) }
            },
            {
              uid: '4.2',
              sectionGroup: ['Primary Year Programme', 'Middle Year Programme', 'Diploma Programme'],
              active: false,
              completed: false,
              name: 'Action',
              enum: 'action_prior_learning',
              image: require('../../assets/images/lesson/actions.png'),
              desc: ['What opportunities are there for building on prior learning to support potential student-initiated action?'],
              showSaveBtn: true,
              saveAction: () => this.saveMultiResources('action_prior_learning')
            },
            {
              uid: '4.3',
              sectionGroup: ['Primary Year Programme', 'Middle Year Programme', 'Diploma Programme'],
              active: false,
              completed: false,
              name: 'Connections',
              enum: 'connection_transdisciplinary_past',
              image: require('../../assets/images/lesson/connections.png'),
              desc: ['Transdisciplinary and Past', 'Connections to past future learning, inside and outside the programme of inquiry', 'What connections are there to learning within and outside unit of inquiry?', 'What opportunities are there for the students to develop conceptual understanding to support transfer of learning across, between and beyond subjects?', 'How can we ensure that learning is purposeful and connects to local and global challenges and opportunities?'],
              saveAction: () => { this.insertLessonResource(this.state.academicSubjectLessonResources.findIndex((v, i) => v.aslr_type == 'connection_transdisciplinary_past' && v.editing)) }
            }
          ]
        },
        {
          uid: '5',
          sectionGroup: ['General', 'Primary Year Programme', 'Middle Year Programme', 'Diploma Programme', 'UAE MOE'],
          active: false,
          completed: false,
          name: 'Questions',
          desc: null,
          data: [
            {
              uid: '5.1',
              sectionGroup: ['General', 'Primary Year Programme', 'Middle Year Programme', 'Diploma Programme', 'UAE MOE'],
              active: false,
              completed: false,
              name: 'Teacher Questions',
              enum: 'question_teacher',
              image: require('../../assets/images/lesson/teacher-question.png'),
              desc: ['What teacher questions and provocations will infirm the lines of inquiry?'],
              saveAction: () => { this.insertLessonResource(this.state.academicSubjectLessonResources.findIndex((v, i) => v.aslr_type == 'question_teacher' && v.editing)) }
            },
            {
              uid: '5.2',
              sectionGroup: ['General', 'Primary Year Programme', 'Middle Year Programme', 'Diploma Programme'],
              active: false,
              completed: false,
              name: 'Student Questions',
              enum: 'question_student',
              image: require('../../assets/images/lesson/student-question.png'),
              desc: ['What student’s questions, prior knowledge, existing theories, experiences and interests will inform the lines of inquiry?'],
              saveAction: () => { this.insertLessonResource(this.state.academicSubjectLessonResources.findIndex((v, i) => v.aslr_type == 'question_student' && v.editing)) }
            }
          ]
        },
        {
          uid: '6',
          sectionGroup: ['General', 'Primary Year Programme', 'Middle Year Programme', 'Diploma Programme', 'UAE MOE'],
          active: false,
          completed: false,
          name: 'Reflections',
          desc: null,
          data: [
            {
              uid: '6.1',
              sectionGroup: ['General', 'Primary Year Programme', 'Middle Year Programme', 'Diploma Programme', 'UAE MOE'],
              active: false,
              completed: false,
              name: 'Reflections',
              enum: 'user_reflection',
              image: require('../../assets/images/lesson/teacher-question.png'),
              desc: ['Reflections can be viewed/added by any users assigned for the same subject.'],
              saveAction: () => { this.insertLessonResource(this.state.academicSubjectLessonResources.findIndex((v, i) => v.aslr_type == 'user_reflection' && v.editing)) }
            },
          ]
        },
        {
          uid: '7',
          sectionGroup: ['General', 'Primary Year Programme', 'Middle Year Programme', 'Diploma Programme', 'UAE MOE'],
          active: false,
          completed: false,
          name: 'Comments',
          desc: null,
          data: [
            {
              uid: '7.1',
              sectionGroup: ['General', 'Primary Year Programme', 'Middle Year Programme', 'Diploma Programme', 'UAE MOE'],
              active: false,
              completed: false,
              name: 'Comments',
              enum: 'user_comment',
              image: require('../../assets/images/lesson/teacher-question.png'),
              desc: ['You can tag different Users related to Lesson so they will receive a notification of your comment.'],
              saveAction: () => { this.insertLessonResource(this.state.academicSubjectLessonResources.findIndex((v, i) => v.aslr_type == 'user_comment' && v.editing)) }
            },
          ]
        },
        {
          uid: '8',
          sectionGroup: ['General', 'Primary Year Programme', 'Middle Year Programme', 'Diploma Programme'],
          active: false,
          completed: false,
          name: 'Assessment',
          desc: null,
          data: [
            {
              uid: '8.1',
              sectionGroup: ['General', 'Primary Year Programme', 'Middle Year Programme', 'Diploma Programme'],
              active: false,
              completed: false,
              name: 'Activity',
              desc: ['You can generally assign Activities to this lesson.'],
              image: require('../../assets/images/lesson/lesson-focus.png'),
              showSaveBtn: true,
              saveAction: () => this.updateLesson()
            },
            {
              uid: '8.2',
              sectionGroup: ['General', 'Primary Year Programme', 'Middle Year Programme', 'Diploma Programme'],
              active: false,
              completed: false,
              name: 'Exams',
              desc: ['You can add general description of the exams in this lesson.'],
              image: require('../../assets/images/lesson/lesson-focus.png'),
              showAddBtn: true,
              addAction: () => this.state.modalAddResource.toggle('Exams', '8', '8.2', () => this.insertLessonExam(this.state.academicSubjectLessonExams.findIndex((v, i) => v.editing))),
            },
          ]
        },
        {
          uid: '9',
          sectionGroup: ['Middle Year Programme', 'Diploma Programme'],
          active: false,
          completed: false,
          name: 'Service Learning',
          desc: [
            'Develop and apply knowledge and skills towards meeting an authentic community need',
            'Develop as leaders who take initiative, solve problems and work collaboratively with others',
            'Enjoy the experiences of both learning and service',
            'Develop a sense of caring about, and a responsibility for, others',
            'Gain a deeper understanding of themselves, their community and society through meaningful reflection',
            'Enhance and strengthen their experience with the existing school curriculum',
          ],
          data: [
            {
              uid: '9.1',
              sectionGroup: ['Middle Year Programme', 'Diploma Programme'],
              active: false,
              completed: false,
              name: 'Opportunities',
              enum: 'service_learning_opportunity',
              image: require('../../assets/images/lesson/learner-profile.png'),
              desc: 'What service learning opportunities will this unit provide?',
              showSaveBtn: true,
              saveAction: () => this.saveMultiResources('service_learning_opportunity')
            },
            {
              uid: '9.2',
              sectionGroup: ['Middle Year Programme', 'Diploma Programme'],
              active: false,
              completed: false,
              name: 'Outcomes',
              enum: 'service_learning_outcome',
              image: require('../../assets/images/lesson/learner-profile.png'),
              desc: 'What service learning outcomes will you address in this unit?',
              showSaveBtn: true,
              saveAction: () => this.saveMultiResources('service_learning_outcome')
            }
          ]
        },
        {
          uid: '10',
          sectionGroup: ['General', 'Primary Year Programme', 'Middle Year Programme', 'Diploma Programme', 'UAE MOE'],
          active: false,
          completed: false,
          name: 'Lesson Flow',
          desc: null,
          data: [
            {
              uid: '10.1',
              sectionGroup: ['General', 'Primary Year Programme', 'Middle Year Programme', 'Diploma Programme', 'UAE MOE'],
              active: false,
              completed: false,
              name: 'Lesson Flow',
              image: require('../../assets/images/lesson/teacher-question.png'),
              desc: ['Create the flow for the this lesson by creating folders and putting resources, exams, activities inside.'],
              showSaveBtn: true,
              saveAction: () => this.updateLessonFlow()
            },
          ]
        },
      ],
      dragAreaColor: {
        aslr_attachment: brand_colors[props.defaultTheme.theme_id].color18,
        toggleColor: (field, value) => this.setState({ dragAreaColor: { ...this.state.dragAreaColor, [field]: value } })
      }
    };

    this.image = React.createRef();
    this.aslr_attachment_ref = React.createRef();
  }

  /**
   * Component Did Mount
   */
  componentDidMount() {
    this.getCurriculum();
    this.getLessonResources();
    this.getLessonAttributes();
    this.getSubjectAssignedEmployees();
    this.getAcademicClassmainSubjects();
    this.getLessonExams()
    this.getExamTypes()
    this.getTermsDefault()
    this.getAcademicActivities()
    this.getAcademicSubjectLessonFlow()
    // 
    // let lessonData = this.props.location.state.lessonData
    // this.setState({
    //   academicSubjectLessons: {
    //     asl_id: lessonData.asl_id,
    //     acs_id: lessonData.acs_id,
    //     as_id: lessonData.as_id,
    //     asl_name: lessonData.asl_name,
    //     asl_coef: lessonData.asl_coef,
    //     asl_code: lessonData.asl_code,
    //     center_id: lessonData.center_id,
    //     asl_period: lessonData.asl_period,
    //     asl_attach: lessonData.asl_attach,
    //     asl_link: lessonData.asl_link,
    //     asl_order: lessonData.asl_order,
    //     asl_sen_required: lessonData.asl_sen_required,
    //     asl_ict_usage: lessonData.asl_ict_usage,
    //     asl_afl: lessonData.asl_afl,
    //     asl_notes1: lessonData.asl_notes1,
    //     asl_notes2: lessonData.asl_notes2,
    //     asl_notes3: lessonData.asl_notes3,
    //     asl_type1: lessonData.asl_type1,
    //     asl_type2: lessonData.asl_type2,
    //     asl_theme: lessonData.asl_theme,
    //     asl_chat_blog: lessonData.asl_chat_blog,
    //   },
    //   image: lessonData.asl_attach,
    //   image_prev: portalURL + lessonData.center_id + '/' + imgDir + '/' + lessonData.asl_attach,
    // })
  }

  /**
  * Preview Upload File
  * @returns
  */
  previewFile = (e) => {
    const reader = new FileReader();
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      reader.readAsDataURL(selectedFile);
    }
    if (!selectedFile.type.includes('image')) {
      toast.error(t('Only image file is allowed!'));
      return;
    }
    reader.onload = (readerEvent) => {
      this.setState({
        [e.target.name + '_deleted']: 0,
        [e.target.name + '_prev']: readerEvent.target.result,
        [e.target.name]: e.target.files[0],
      })
    }
  };

  getAcademicActivities = () => {
    if (this.getAcademicActivitiesReq) {
      this.getAcademicActivitiesReq.abort();
    }
    this.getAcademicActivitiesReq = new AbortController();
    GET('academic-activities/get-academic-activities', {
      signal: this.getAcademicActivitiesReq.signal
    })
      .then((res) => {
        let allAcademicActivities = res.data.map((v, i) => { return { ...v, label: v.aa_name, value: v.aa_id } })
        this.setState({
          allAcademicActivities: allAcademicActivities,
          academicSubjectLessons: {
            ...this.state.academicSubjectLessons,
            asl_aa_ids: allAcademicActivities.filter((v, i) => this.props.location.state.lessonData.asl_aa_ids.includes(parseInt(v.aa_id)))
          }
        }, () => this.checkCompletedFormSections())
      })
      .catch((err) => {
        console.log('err', err);
        if (axios.isCancel(err)) {

        } else {
          // toast.error(
          //   'Something went wrong while fetching chats and contacts3!'
          // );
        }
      });
  }

  getAcademicSubjectLessonFlow = () => {
    if (this.getAcademicSubjectLessonFlowReq) {
      this.getAcademicSubjectLessonFlowReq.abort();
    }
    this.getAcademicSubjectLessonFlowReq = new AbortController();
    GET(controller + '/get-academic-subject-lesson-flow', {
      params: {
        asl_id: this.props.location.state.lessonData.asl_id
      },
      signal: this.getAcademicSubjectLessonFlowReq.signal
    })
      .then((res) => {
        console.log('res', res);
        let resData = res.data
        if (resData) {
          let folders = resData.filter((v, i) => v.asl_flow_link_type == 'folder')
          let data = resData.filter((v, i) => v.asl_flow_link_type != 'folder')
          this.setState({
            getAcademicSubjectLessonFlow: {
              ...this.state.getAcademicSubjectLessonFlow,
              folders: folders,
              data: data,
            }
          })
        }
      })
      .catch((err) => {
        console.log('err', err);
        if (axios.isCancel(err)) {

        } else {
          // toast.error(
          //   'Something went wrong while fetching chats and contacts3!'
          // );
        }
      });
  }

  getTermsDefault = async () => {
    if (this.getTermsDefaultReq) {
      this.getTermsDefaultReq.abort();
    }
    this.getTermsDefaultReq = new AbortController();
    try {
      const res = await GET(controller + '/get-terms-default', { signal: this.getTermsDefaultReq.signal, });
      this.setState({ termsDefault: res.data.map((v, i) => { return { ...v, label: v.term_d_name, value: v.term_d_id } }) });
    } catch (err) {
      console.log('err', err)
      if (axios.isCancel(err)) {
      } else {
        toast.error(t('Something went wrong while fetching data!'));
      }
    }
  };

  getExamTypes = async () => {
    const res = await GET('exams/get-exam-types', {
      params: {
        examstypegrp_type: 'Exam'
      }
    });
    if (res) {
      const examTypes = [];
      res.data.map((item) => {
        examTypes.push({
          value: item.examstype_id,
          label: item.examstype_name,
          ...item
        });
      });
      this.setState({ examTypes });
    }
  };

  getCurriculum = async () => {
    this.setState({
      curriculum: [],
    });
    const { lessonData } = this.props.location.state;

    try {
      const res = await GET(controller + '/get-curriculum',
        {
          params: {
            asl_id: lessonData.asl_id,
            acs_id: lessonData.acs_id,
          },
        }
      );

      if (res) {
        this.setState({
          curriculum: res.data,
        });
      }
    } catch (err) {
      console.log('err', err)
      toast.error(t('Something went wrong while fetching curriculum!'));
    }
  };

  getLessonResources = async () => {
    this.setState({
      getLessonResourcesLoading: true,
    });
    const { lessonData } = this.props.location.state;
    try {
      const res = await GET(controller + '/get-lesson-resources',
        {
          params: {
            asl_id: lessonData.asl_id,
          },
        }
      );
      if (res) {
        this.setState({ getLessonResourcesLoading: false, academicSubjectLessonResources: res.data.map((v, i) => { return { ...v, aslr_tags: JSON.parse(v.aslr_tags) } }) }, () => {
          this.checkCompletedFormSections()
        });
      } else {
        this.setState({
          getLessonResourcesLoading: false
        });
      }
    } catch (err) {
      this.setState({
        getLessonResourcesLoading: false
      });
      console.log('err', err)
      toast.error(
        t('Something went wrong while fetching Lesson Resources!')
      );
    }
  };

  getLessonExams = async () => {
    this.setState({
      getLessonExamsLoading: true,
    });
    const { lessonData } = this.props.location.state;
    try {
      const res = await GET(controller + '/get-lesson-exams',
        {
          params: {
            asl_id: lessonData.asl_id,
          },
        }
      );
      if (res) {
        this.setState({
          academicSubjectLessonExams: res.data.map((v, i) => {
            return {
              ...v,
              term_d_id: { value: v.term_d_id, label: v.term_d_name, term_d_id: v.term_d_id, term_d_name: v.term_d_name },
              examstype_id: { value: v.examstype_id, label: v.examstype_name, examstype_id: v.examstype_id, examstype_name: v.examstype_name }
            }
          }), getLessonExamsLoading: false
        }, () => {
          this.checkCompletedFormSections()
        });
      } else {
        this.setState({
          getLessonExamsLoading: false,
        });
      }
    } catch (err) {
      this.setState({
        getLessonExamsLoading: false,
      });
      console.log('err', err)
      toast.error(
        t('Something went wrong while fetching Lesson Exams!')
      );
    }
  };

  getLessonAttributes = async () => {
    this.setState({
      cat5DataLoaded: false,
    });


    try {
      const res = await GET(controller + '/get-lesson-attributes');

      if (res) {
        this.setState({ academicLessonAttributes: res.data.map((v, i) => { return { ...v, agegroup_types: JSON.parse(v.agegroup_types) } }), cat5DataLoaded: true });
      }
    } catch (err) {
      console.log('err', err)
      toast.error(
        t('Something went wrong while fetching Lesson Attributes!')
      );

    }
  };

  getSubjectAssignedEmployees = async () => {
    try {
      const res = await GET(controller + '/get-subject-assigned-employees', {
        params: { acs_id: this.state.academicSubjectLessons.acs_id }
      });
      if (res) {
        let getSubjectAssignedEmployeesData = res.data.map((v, i) => {
          return { ...v, label: v.full_name, value: v.user_id }
        })
        this.setState({ getSubjectAssignedEmployeesData: getSubjectAssignedEmployeesData });
      }
    } catch (err) {
      console.log('err', err)
      toast.error(
        t('Something went wrong while fetching Lesson Attributes!')
      );
    }
  }

  getAcademicClassmainSubjects = async () => {
    try {
      const res = await GET('academic-classmain-subject/get-academic-classmain-subjects', {
        params: { agegroup_id: this.state.academicSubjectLessons.agegroup_id }
      });
      if (res) {
        let getAcademicClassmainSubjectsData = res.data.map((v, i) => {
          return { ...v, label: v.acs_name, value: v.acs_id }
        })
        this.setState({
          getAcademicClassmainSubjectsData: getAcademicClassmainSubjectsData,
          academicSubjectLessons: {
            ...this.state.academicSubjectLessons,
            asl_acs_ids: getAcademicClassmainSubjectsData.filter((v, i) => this.props.location.state.lessonData.asl_acs_ids.includes(parseInt(v.acs_id)))
          }
        });
      }
    } catch (err) {
      console.log('err', err)
      toast.error(
        t('Something went wrong while fetching Lesson Attributes!')
      );
    }
  }

  updateLessonFlow = async () => {
    try {
      let getAcademicSubjectLessonFlow = this.state.getAcademicSubjectLessonFlow
      let AcademicSubjectLessonFlowFolders = getAcademicSubjectLessonFlow.folders.map((v, i) => {
        return { ...v, asl_flow_order_no: i + 1 }
      })
      let AcademicSubjectLessonFlowData = getAcademicSubjectLessonFlow.data.map((v, i) => {
        return { ...v, asl_flow_order_no: i + 1 }
      })

      if (AcademicSubjectLessonFlowFolders.length <= 0 && AcademicSubjectLessonFlowData.length <= 0) {
        toast.warning(t('No data found for update!'));
        return
      }

      let fData = new FormData
      fData.append('AcademicSubjectLessonFlowFolders', JSON.stringify(AcademicSubjectLessonFlowFolders))
      fData.append('AcademicSubjectLessonFlowData', JSON.stringify(AcademicSubjectLessonFlowData))

      if (this.updateLessonFlowReq) {
        this.updateLessonFlowReq.abort();
      }
      this.updateLessonFlowReq = new AbortController();
      const res = await POST(
        controller + '/save-academic-subject-lesson-flow',
        fData,
        {
          params: {
            asl_id: this.props.location.state.lessonData.asl_id,
          },
          signal: this.updateLessonFlowReq.signal
        }
      );
      console.log('res', res);
      if (res) {
        let resData = res.data
        if (resData.type) {
          toast.success(t(resData.message));
        } else {
          if (resData.data != null) {
            handleFormErrors(resData.data);
          } else {
            toast[resData.messageType](t(resData.message));
          }
        }
      }
    } catch (err) {
      console.log('err', err)
      toast.error(t('Something went wrong while updating lesson flow!'));
    }
  }

  updateLesson = async (updateType = 'all') => {
    // this.setState({ working: true });

    const { asl_differentiation, asl_notes1, asl_warmup, asl_objectives_description, asl_preparation, asl_usefulness, asl_steps, asl_ending, asl_homeworks, asl_oral, asl_activities, asl_written_work, asl_student_work, asl_dual_assessment, asl_self_assessment, asl_objectives_list, asl_drop_quiz, asl_acs_ids, asl_aa_ids, asl_type1 } = this.state.academicSubjectLessons;

    const { authData, selectedClass } = this.props;
    const { auth_key, user_id } = authData.loginData;
    const { center_id, center_timezone } = authData.centerData;

    const fData = new FormData();
    fData.append('center_id', center_id);
    fData.append('center_timezone', center_timezone);
    fData.append('user_id', user_id);
    fData.append('asl_id', this.state.academicSubjectLessons.asl_id);
    if (updateType == 'all') {
      for (let i in this.state.academicSubjectLessons) {
        let v = this.state.academicSubjectLessons[i];
        fData.append('AcademicSubjectLessons[' + i + ']', (v == null) ? '' : v);
      }
      fData.append('asl_attach', this.state.image);
      fData.append('asl_attach_deleted', this.state.image_deleted);

      if (asl_type1 == 'Interdisciplinary') {
        fData.append('AcademicSubjectLessons[asl_acs_ids]', JSON.stringify(asl_acs_ids.map((v, i) => parseInt(v.acs_id))));
      } else {
        fData.append('AcademicSubjectLessons[asl_acs_ids]', JSON.stringify([]));
      }
      fData.append('AcademicSubjectLessons[asl_aa_ids]', JSON.stringify(asl_aa_ids.map((v, i) => parseInt(v.aa_id))));
    } else if (updateType == 'central_idea') {
      fData.append('AcademicSubjectLessons[asl_notes1]', (asl_notes1) ? asl_notes1 : '');
      fData.append('AcademicSubjectLessons[asl_differentiation]', (asl_differentiation) ? asl_differentiation : '');
      fData.append('AcademicSubjectLessons[asl_warmup]', (asl_warmup) ? asl_warmup : '');
      fData.append('AcademicSubjectLessons[asl_objectives_description]', (asl_objectives_description) ? asl_objectives_description : '');
      fData.append('AcademicSubjectLessons[asl_preparation]', (asl_preparation) ? asl_preparation : '');
      fData.append('AcademicSubjectLessons[asl_usefulness]', (asl_usefulness) ? asl_usefulness : '');
      fData.append('AcademicSubjectLessons[asl_steps]', (asl_steps) ? asl_steps : '');
      fData.append('AcademicSubjectLessons[asl_ending]', (asl_ending) ? asl_ending : '');
      fData.append('AcademicSubjectLessons[asl_homeworks]', (asl_homeworks) ? asl_homeworks : '');
      fData.append('AcademicSubjectLessons[asl_oral]', asl_oral);
      fData.append('AcademicSubjectLessons[asl_activities]', asl_activities);
      fData.append('AcademicSubjectLessons[asl_written_work]', asl_written_work);
      fData.append('AcademicSubjectLessons[asl_student_work]', asl_student_work);
      fData.append('AcademicSubjectLessons[asl_dual_assessment]', asl_dual_assessment);
      fData.append('AcademicSubjectLessons[asl_self_assessment]', asl_self_assessment);
      fData.append('AcademicSubjectLessons[asl_objectives_list]', asl_objectives_list);
      fData.append('AcademicSubjectLessons[asl_drop_quiz]', asl_drop_quiz);
    } else {
      fData.append('AcademicSubjectLessons[' + updateType + ']', this.state.academicSubjectLessons[updateType]);
    }




    try {
      const res = await POST(
        controller + '/update',
        fData,
        {
        }
      );
      if (res) {
        let resData = res.data
        if (resData.messageType !== 'error') {
          // this.setState({ working: false });
          toast.success(t('Updated successfully!'));

        } else {
          if (resData.data != null) {
            handleFormErrors(resData.data);
          } else {
            toast[resData.messageType](t(resData.message));
          }
          // this.setState({ working: false });
        }
      }
    } catch (err) {
      console.log('err', err)
      toast.error(t('Something went wrong while update lesson!'));

      // this.setState({ working: false });
    }
  };

  insertLessonExam = async (dataIndex) => {
    console.log('dataIndex', dataIndex);
    if (dataIndex == -1) {
      toast.warning(
        t('Please fill out the data!')
      );
      return
    }
    this.setState({
      uploadingLessonExam: true,
    });
    let fDataAcademicSubjectLessonExams = this.state.academicSubjectLessonExams[dataIndex]
    if (!fDataAcademicSubjectLessonExams.asle_name) {
      toast.warning(t('Title is required!'));
      return
    }
    if (!fDataAcademicSubjectLessonExams.examstype_id) {
      toast.warning(t('Please select Type!'));
      return
    }
    if (!fDataAcademicSubjectLessonExams.term_d_id) {
      toast.warning(t('Please select Term!'));
      return
    }

    fDataAcademicSubjectLessonExams.examstype_id = fDataAcademicSubjectLessonExams.examstype_id.value
    fDataAcademicSubjectLessonExams.term_d_id = fDataAcademicSubjectLessonExams.term_d_id.value
    const fData = new FormData();
    fData.append('AcademicSubjectLessonExams', JSON.stringify(fDataAcademicSubjectLessonExams));

    try {
      if (this.insertLessonExamReq) {
        this.insertLessonExamReq.abort();
      }
      this.insertLessonExamReq = new AbortController();

      const res = await POST(controller + '/save-lesson-exam', fData, { signal: this.insertLessonExamReq.signal });
      if (res) {
        if (res.data.type) {
          let newAcademicSubjectLessonExams = res.data.data
          let examstype_id = this.state.examTypes.find((v, i) => v.examstype_id == newAcademicSubjectLessonExams['examstype_id'])
          let term_d_id = this.state.termsDefault.find((v, i) => v.term_d_id == newAcademicSubjectLessonExams['term_d_id'])
          newAcademicSubjectLessonExams['examstype_id'] = (examstype_id) ? { ...examstype_id, value: examstype_id.examstype_id, label: examstype_id.examstype_name } : ''
          newAcademicSubjectLessonExams['term_d_id'] = (term_d_id) ? { ...term_d_id, value: term_d_id.term_d_id, label: term_d_id.term_d_name } : ''
          toast.success(t('Updated successfully!'));
          let academicSubjectLessonExams = this.state.academicSubjectLessonExams
          academicSubjectLessonExams.splice(dataIndex, 1)
          academicSubjectLessonExams.push(newAcademicSubjectLessonExams)
          this.setState({ academicSubjectLessonExams, uploadingLessonExam: false, modalAddResource: { ...this.state.modalAddResource, show: false } }, () => this.checkCompletedFormSections());
        } else {
          handleFormErrors(res.data.data);
          this.setState({
            uploadingLessonExam: false,
          });
        }
      } else {
        this.setState({
          uploadingLessonExam: false,
        });
      }
    } catch (err) {
      console.log('err', err)
      toast.error(
        t('Something went wrong while saving lesson resource!')
      );
      this.setState({
        uploadingLessonExam: false,
      });
    }
  };

  insertLessonResource = async (dataIndex) => {
    if (dataIndex == -1) {
      toast.warning(
        t('Please fill out the data!')
      );
      return
    }
    this.setState({
      uploadingResource: true,
    });
    const { authData, selectedClass } = this.props;
    const { center_id, center_timezone } = authData.centerData;
    const { auth_key, user_id, role_id } = authData.loginData;

    let fDataAcademicSubjectLessonResources = this.state.academicSubjectLessonResources[dataIndex]
    fDataAcademicSubjectLessonResources.aslr_tags = JSON.stringify(fDataAcademicSubjectLessonResources.aslr_tags)

    const aslr_attachment = this.state.aslr_attachment
    const fData = new FormData();
    fData.append('center_id', center_id);
    fData.append('center_timezone', center_timezone);
    fData.append('user_id', user_id);
    fData.append('role_id', role_id);
    fData.append('AcademicSubjectLessonResources', JSON.stringify(fDataAcademicSubjectLessonResources));
    if (aslr_attachment) {
      if (aslr_attachment.data) {
        fData.append('aslr_attachment', aslr_attachment.data);
      } else {
        fData.append('aslr_attachment', aslr_attachment);
      }
    }

    try {

      if (this.insertLessonResourceReq) {
        this.insertLessonResourceReq.abort();
      }
      this.insertLessonResourceReq = new AbortController();

      const res = await POST(controller + '/save-lesson-resource', fData, { signal: this.insertLessonResourceReq.signal });
      if (res) {
        if (res.data.type) {
          let newAcademicSubjectLessonResources = res.data.data
          newAcademicSubjectLessonResources['aslr_tags'] = JSON.parse(newAcademicSubjectLessonResources['aslr_tags'])
          toast.success(t('Updated successfully!'));
          let academicSubjectLessonResources = this.state.academicSubjectLessonResources
          academicSubjectLessonResources.splice(dataIndex, 1)
          academicSubjectLessonResources.push(newAcademicSubjectLessonResources)
          this.setState({ academicSubjectLessonResources, aslr_attachment: null, uploadingResource: false, }, () => this.checkCompletedFormSections());
          // this.setState({ working: false, academicSubjectLessonResources });

          if (['user_reflection', 'user_comment'].includes(newAcademicSubjectLessonResources['aslr_type'])) {
            res.data.sendCloudMessages.map((v, i) => {
              if (v.user_id) {
                sendCloudMessage(v.user_id, v.title, v.body, v.data)
              }
            })
          }

        } else {
          handleFormErrors(res.data.data);
          // this.setState({ working: false });
          this.setState({
            uploadingResource: false,
          });
        }
      } else {
        this.setState({
          uploadingResource: false,
        });
      }
    } catch (err) {
      console.log('err', err)
      toast.error(
        t('Something went wrong while saving lesson resource!')
      );
      this.setState({
        uploadingResource: false,
      });
      // this.setState({ working: false });
    }
  };

  deleteAcademicSubjectLessonResources = async () => {

    const { authData } = this.props;
    const { auth_key } = authData.loginData;

    const fData = new FormData();
    fData.append('aslr_id', this.state.deleteModal.id);
    try {
      const res = await axios.post(
        apiURL + controller + '/delete-lesson-resource',
        fData,
        {
          params: {
            auth_key: auth_key,
            appname: process.env.REACT_APP_NAME,
          },
        }
      );
      if (res.status == 200) {
        if (res.data.messageType !== 'error') {

          toast.warning(t('Deleted successfully!'));

          let academicSubjectLessonResources = this.state.academicSubjectLessonResources
          academicSubjectLessonResources = academicSubjectLessonResources.filter((v, i) => v.aslr_id != this.state.deleteModal.id)
          this.setState({ academicSubjectLessonResources, deleteModal: { ...this.state.deleteModal, show: false, id: 0, action: () => { } } }, () => this.checkCompletedFormSections());
          // this.setState({ working: false, academicSubjectLessonResources });

        } else {
          handleFormErrors(res.data.data);
          // this.setState({ working: false });
        }
      }
    } catch (err) {
      console.log('err', err)
      toast.error(
        t('Something went wrong while saving lesson resource!')
      );

      // this.setState({ working: false });
    }
  }

  deleteAcademicSubjectLessonExams = async () => {

    const fData = new FormData();
    fData.append('asle_id', this.state.deleteModal.id);
    try {
      const res = await POST(
        controller + '/delete-lesson-exam',
        fData,
      );
      if (res) {
        if (res.data.type) {
          toast.warning(t('Deleted successfully!'));
          let academicSubjectLessonExams = this.state.academicSubjectLessonExams
          academicSubjectLessonExams = academicSubjectLessonExams.filter((v, i) => v.asle_id != this.state.deleteModal.id)
          this.setState({ academicSubjectLessonExams, deleteModal: { ...this.state.deleteModal, show: false, id: 0, action: () => { } } }, () => this.checkCompletedFormSections());
          // this.setState({ working: false, academicSubjectLessonResources });
        } else {
          handleFormErrors(res.data.data);
          // this.setState({ working: false });
        }
      }
    } catch (err) {
      console.log('err', err)
      toast.error(
        t('Something went wrong while saving lesson resource!')
      );

      // this.setState({ working: false });
    }
  }

  saveMultiResources = async (enumType) => {

    let AcademicSubjectLessonResourcesFiltered = this.state.academicSubjectLessonResources
    if (enumType == 'transdisciplinary_theme') {
      AcademicSubjectLessonResourcesFiltered = AcademicSubjectLessonResourcesFiltered.filter((v, i) => (v.aslr_type == 'transdisciplinary_theme' || v.aslr_type == 'transdisciplinary_theme_focus'))
    }
    else if (enumType == 'related_concept') {
      AcademicSubjectLessonResourcesFiltered = AcademicSubjectLessonResourcesFiltered.filter((v, i) => (v.aslr_type == 'related_concept_learn_area' || v.aslr_type == 'related_concept_key_concept'))
    }
    else if (enumType == 'approaches_to_learning' || enumType == 'competency' || enumType == 'learner_profile' || enumType == 'lesson_key_concept' || enumType == 'action_prior_learning' || enumType == 'service_learning_outcome' || enumType == 'service_learning_opportunity') {
      AcademicSubjectLessonResourcesFiltered = AcademicSubjectLessonResourcesFiltered.filter((v, i) => (v.aslr_type == enumType))
    }

    if (AcademicSubjectLessonResourcesFiltered.length < 2 && enumType == 'transdisciplinary_theme') {
      toast.error(t('Please select Transdisciplinary Theme & at-least one of its focus!'));
      return
    }
    if (AcademicSubjectLessonResourcesFiltered.length < 1) {
      if (enumType == 'approaches_to_learning') {
        toast.error(t('Please select at-least one Approach to learning!'));
      } else if (enumType == 'competency') {
        toast.error(t('Please select at-least one Scope!'));
      } else if (enumType == 'learner_profile') {
        toast.error(t('Please select at-least one Learner Profile!'));
      } else if (enumType == 'lesson_key_concept') {
        toast.error(t('Please write at-least one Key Concept!'));
      } else if (enumType == 'action_prior_learning') {
        toast.error(t('Please write at-least one Action!'));
      } else if (enumType == 'related_concept') {
        toast.error(t('Please write at-least one Related concept!'));
      } else if (enumType == 'service_learning_outcome') {
        toast.error(t('Please select at-least one Outcome to Service learning!'));
      } else if (enumType == 'service_learning_opportunity') {
        toast.error(t('Please select at-least one Opportunity to Service learning!'));
      }
      return
    }
    const { authData, selectedClass } = this.props;
    const { center_id, center_timezone } = authData.centerData;
    const { auth_key, user_id } = authData.loginData;

    const fData = new FormData();
    fData.append('center_id', center_id);
    fData.append('center_timezone', center_timezone);
    fData.append('user_id', user_id);
    fData.append('enumType', enumType);
    fData.append('AcademicSubjectLessonResources', JSON.stringify(AcademicSubjectLessonResourcesFiltered));

    try {
      const res = await POST(
        controller + '/save-multi-resources',
        fData,
      );
      if (res) {
        console.log('save-multi-resources', res);
        if (res.data.type) {
          if (enumType == 'transdisciplinary_theme') {
            toast.success(t('Transdisciplinary Theme updated successfully!'));
          } else if (enumType == 'approaches_to_learning') {
            toast.success(t('Approaches to learning updated successfully!'));
          } else if (enumType == 'competency') {
            toast.success(t('Scope & Sequences updated successfully!'));
          } else if (enumType == 'learner_profile') {
            toast.success(t('Learner Profile updated successfully!'));
          } else if (enumType == 'lesson_key_concept') {
            toast.success(t('Key Concepts updated successfully!'));
          } else if (enumType == 'action_prior_learning') {
            toast.success(t('Actions updated successfully!'));
          } else if (enumType == 'related_concept') {
            toast.success(t('Related Concepts updated successfully!'));
          } else if (enumType == 'service_learning_opportunity') {
            toast.success(t('Opportunities updated successfully!'));
          } else if (enumType == 'service_learning_outcome') {
            toast.success(t('Outcomes updated successfully!'));
          }

          let academicSubjectLessonResourcesNew = this.state.academicSubjectLessonResources
          if (enumType == 'transdisciplinary_theme') {
            academicSubjectLessonResourcesNew = academicSubjectLessonResourcesNew.filter((v, i) =>
              (v.aslr_type != 'transdisciplinary_theme' && v.aslr_type != 'transdisciplinary_theme_focus')
            )
          } else if (enumType == 'related_concept') {
            academicSubjectLessonResourcesNew = academicSubjectLessonResourcesNew.filter((v, i) =>
              (v.aslr_type != 'related_concept_learn_area' || v.aslr_type != 'related_concept_key_concept')
            )
          } else if (enumType == 'approaches_to_learning' || enumType == 'competency' || enumType == 'learner_profile' || enumType == 'lesson_key_concept' || enumType == 'action_prior_learning' || enumType == 'service_learning_outcome' || enumType == 'service_learning_opportunity') {
            academicSubjectLessonResourcesNew = academicSubjectLessonResourcesNew.filter((v, i) =>
              (v.aslr_type != enumType)
            )
          }
          console.log('academicSubjectLessonResourcesNew', academicSubjectLessonResourcesNew);

          res.data.data.map((v, i) => {
            academicSubjectLessonResourcesNew.push(v)
          })

          this.setState({ academicSubjectLessonResources: academicSubjectLessonResourcesNew });
          // this.setState({ working: false, academicSubjectLessonResources });

        } else {
          handleFormErrors(res.data.data);
          // this.setState({ working: false });
        }
      }
    } catch (err) {
      console.log('err', err)
      toast.error(
        t('Something went wrong while saving lesson resource!')
      );

      // this.setState({ working: false });
    }
  }

  setStateAcademicSubjectLessons = (field, value) => {
    this.setState({ academicSubjectLessons: { ...this.state.academicSubjectLessons, [field]: value } }, () => {
      this.checkCompletedFormSections()
    })
  }

  setStateAcademicSubjectLessonResources = (field, value, aslr_type, aslr_id) => {
    let academicSubjectLessonResources = this.state.academicSubjectLessonResources
    let findIndex = academicSubjectLessonResources.findIndex((v, i) => v.aslr_type == aslr_type && v.aslr_id == aslr_id)
    if (findIndex != -1) {
      academicSubjectLessonResources[findIndex][field] = value
    } else {
      academicSubjectLessonResources.push({
        aslr_id: aslr_id,
        aslr_type: aslr_type,
        aslr_title: '',
        aslr_url_type: '',
        aslr_url: '',
        aslr_desc: '',
        aslr_tags: [],
        aslr_type_id: null,
        ab_id: this.props.location.state.lessonData.ab_id,
        as_id: this.props.location.state.lessonData.as_id,
        acs_id: this.props.location.state.lessonData.acs_id,
        asl_id: this.props.location.state.lessonData.asl_id,
        // classmain_id: this.props.selectedClass.class.classmain_id,
        [field]: value,
        editing: true
      })
    }
    this.setState({ academicSubjectLessonResources })
  }

  setStateAcademicSubjectLessonExams = (field, value, asle_id) => {
    let academicSubjectLessonExams = this.state.academicSubjectLessonExams
    let findIndex = academicSubjectLessonExams.findIndex((v, i) => v.asle_id == asle_id)
    if (findIndex != -1) {
      academicSubjectLessonExams[findIndex][field] = value
    } else {
      academicSubjectLessonExams.push({
        asle_id: asle_id,
        asle_name: '',
        asle_description: '',
        examstype_id: null,
        term_d_id: null,
        asle_is_exampaper: 0,
        asl_id: this.props.location.state.lessonData.asl_id,
        [field]: value,
        editing: true
      })
    }
    this.setState({ academicSubjectLessonExams })
  }



  // MARK FORM SECTIONS AS COMPLETE AFTER VERIFYING THE REQUIRED FIELDS DATA
  checkCompletedFormSections = () => {
    let formSections = this.state.formSections
    formSections = formSections.map((v, i) => {
      let data = v.data
      let sectionComplete = true
      data = data.map((vv, ii) => {
        if (vv.uid == '1.1') {
          let academicSubjectLessons = this.state.academicSubjectLessons
          let subSectionComplete = true
          if (academicSubjectLessons.asl_name) {
            subSectionComplete = true
          } else {
            subSectionComplete = false
            sectionComplete = false
          }
          return { ...vv, completed: subSectionComplete }
        }
        else if (vv.uid == '1.2') {
          let academicSubjectLessons = this.state.academicSubjectLessons
          let subSectionComplete = true
          if (academicSubjectLessons.asl_notes1) {
            subSectionComplete = true
          } else {
            subSectionComplete = false
            sectionComplete = false
          }
          return { ...vv, completed: subSectionComplete }

        } else if (vv.uid == '8.1') {
          let academicSubjectLessons = this.state.academicSubjectLessons
          let subSectionComplete = true
          if (academicSubjectLessons.asl_aa_ids.length > 0) {
            subSectionComplete = true
          } else {
            subSectionComplete = false
            sectionComplete = false
          }
          return { ...vv, completed: subSectionComplete }
        } else if (vv.uid == '8.2') {
          let academicSubjectLessonExams = this.state.academicSubjectLessonExams
          let subSectionComplete = true
          if (academicSubjectLessonExams.length > 0) {
            subSectionComplete = true
          } else {
            subSectionComplete = false
            sectionComplete = false
          }
          return { ...vv, completed: subSectionComplete }
        } else if (vv.uid == '10.1') {
          let getAcademicSubjectLessonFlow = this.state.getAcademicSubjectLessonFlow
          let subSectionComplete = true
          if (getAcademicSubjectLessonFlow.data.length > 0 || getAcademicSubjectLessonFlow.folders.length > 0) {
            subSectionComplete = true
          } else {
            subSectionComplete = false
            sectionComplete = false
          }
          return { ...vv, completed: subSectionComplete }
        } else if (['1.3', '1.4', '2.1', '2.2', '2.3', '2.4', '2.5', '3.5', '4.1', '4.3', '5.1', '5.2', '3.2', '3.3', '4.2', '6.1', '7.1', '9.1', '9.2'].includes(vv.uid)) {
          let academicSubjectLessonResources = this.state.academicSubjectLessonResources
          let academicSubjectLessonResourcesFind = academicSubjectLessonResources.filter((vvv, iii) => vvv.aslr_type == vv.enum)
          let subSectionComplete = true
          if (academicSubjectLessonResourcesFind.length > 0) {
            subSectionComplete = true
          } else {
            subSectionComplete = false
            sectionComplete = false
          }
          return { ...vv, completed: subSectionComplete }
        } else if (vv.uid == '3.1') {
          let academicSubjectLessonResources = this.state.academicSubjectLessonResources
          let academicSubjectLessonResourcesFind = academicSubjectLessonResources.filter((vvv, iii) => vvv.aslr_type == 'transdisciplinary_theme')
          let academicSubjectLessonResourcesFind2 = academicSubjectLessonResources.filter((vvv, iii) => vvv.aslr_type == 'transdisciplinary_theme_focus')
          let subSectionComplete = true
          if (academicSubjectLessonResourcesFind.length > 0 && academicSubjectLessonResourcesFind2.length > 0) {
            subSectionComplete = true
          } else {
            subSectionComplete = false
            sectionComplete = false
          }
          return { ...vv, completed: subSectionComplete }
        } else if (vv.uid == '3.4') {
          let academicSubjectLessonResources = this.state.academicSubjectLessonResources
          let academicSubjectLessonResourcesFind = academicSubjectLessonResources.filter((vvv, iii) => (vvv.aslr_type == 'related_concept_key_concept' || vvv.aslr_type == 'related_concept_learn_area'))
          let subSectionComplete = true
          if (academicSubjectLessonResourcesFind.length > 0) {
            subSectionComplete = true
          } else {
            subSectionComplete = false
            sectionComplete = false
          }
          return { ...vv, completed: subSectionComplete }
        } else {
          sectionComplete = false
          return { ...vv }
        }
      })
      return { ...v, data, completed: sectionComplete }
    })
    this.setState({ formSections: formSections })
  }

  /**
  * Preview Upload File
  * @returns
  */
  readFile = (selectedFile) => {
    return new Promise(function (resolve, reject) {
      let reader = new FileReader();
      const allowedFiles = ['application/pdf', 'image/jpeg', 'image/jpg', 'image/png', 'audio/x-m4a', 'audio/mp3', 'audio/mp4'];
      const allowedFilesDisplay = ['PDF', 'Image'];
      const allowedSize = 5000000;

      if (selectedFile.size) {

        if (selectedFile.size >= allowedSize) {
          toast.error(t('Max file size allowed is ' + allowedSize / 1000000 + ' MB!'));
          resolve({ type: 'error' });
          return;
        } else if (!allowedFiles.includes(selectedFile.type)) {
          toast.error(t('Allowed file type are ' + allowedFilesDisplay.toString()));
          resolve({ type: 'error' });
          return;
        } else {
          if (selectedFile.type.includes('image')) {
            reader.readAsDataURL(selectedFile);
          } else if (selectedFile.type.includes('audio')) {
            reader.readAsArrayBuffer(selectedFile);
          } else {
            reader.readAsText(selectedFile);
          }
        }

        reader.onload = async (readerEvent) => {
          resolve({ type: 'success', data: selectedFile, url: readerEvent.target.result });
        };
        reader.onerror = (error) => {
          resolve({ type: 'error' });
        };
      } else {
        resolve({ type: 'error' });
      }
    });
  };
  addAttachment = (e) => {
    const selectedFiles = e.target.files;
    let allFiles = [];

    for (let i = 0; i < selectedFiles.length; i++) {
      allFiles.push(this.readFile(selectedFiles[i]));
    }

    Promise.all(allFiles).then((values) => {
      let attachments = e.target.multiple ? this.state[e.target.name] : null;
      let selectedFiles = values;
      selectedFiles.map((v, i) => {
        if (v.data.type) {
          if (e.target.multiple) {
            attachments.push({ url: v.url, data: v.data });
          } else {
            attachments = { url: v.url, data: v.data };
          }
        }
      });

      this.setState({ [e.target.name]: attachments, dragAreaColor: { ...this.state.dragAreaColor, [e.target.name]: brand_colors[this.props.defaultTheme.theme_id].color18 } }, () => this.checkCompletedFormSections());
    });
  };

  handleDragFileArea = (e, name) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.type === "dragover") {
      this.state.dragAreaColor.toggleColor(name, brand_colors[this.props.defaultTheme.theme_id].color1)
    } else if (e.type === "dragleave") {
      this.state.dragAreaColor.toggleColor(name, brand_colors[this.props.defaultTheme.theme_id].color18)
    }
  }

  deleteAcademicSubjectLessonFlow = (item) => {
    this.setState({
      deleteModal: {
        ...this.state.deleteModal, show: true, id: item.asl_flow_id, action: () => {
          let getAcademicSubjectLessonFlow = this.state.getAcademicSubjectLessonFlow
          if (item.asl_flow_link_type == 'folder') {
            let findFolder = getAcademicSubjectLessonFlow.folders.find((v, i) => v.asl_flow_parent_id == item.asl_flow_id)
            let findData = getAcademicSubjectLessonFlow.data.find((v, i) => v.asl_flow_parent_id == item.asl_flow_id)
            if (!findFolder && !findData) {
              let folders = getAcademicSubjectLessonFlow.folders.filter((v, i) => v.asl_flow_id != item.asl_flow_id)
              this.setState({ deleteModal: { ...this.state.deleteModal, show: false }, getAcademicSubjectLessonFlow: { ...getAcademicSubjectLessonFlow, folders } })
              toast.warning(t('Deleted Successfully!'));
            } else {
              toast.error(t('Unable to delete, Data found in this folder!'));
              this.setState({ deleteModal: { ...this.state.deleteModal, show: false } })
            }
          } else {
            let data = getAcademicSubjectLessonFlow.data.filter((v, i) => v.asl_flow_id != item.asl_flow_id)
            this.setState({ deleteModal: { ...this.state.deleteModal, show: false }, getAcademicSubjectLessonFlow: { ...getAcademicSubjectLessonFlow, data } })
            toast.warning(t('Deleted Successfully!'));
          }
        }
      }
    })
  }

  renderThisFormSection = (formSectionActive) => {
    console.log('renderThisFormSection', this.state);
    let { externalResource, type1Opts, type2Opts, academicSubjectLessons, image, image_prev, image_deleted, aslr_attachment, sectionGroup, examTypes, termsDefault, getAcademicSubjectLessonFlow } = this.state;
    let { asl_name, asl_coef, asl_code, center_id, asl_period, asl_attach, asl_link, asl_order, asl_sen_required, asl_ict_usage, asl_afl, asl_notes1, asl_notes2, sl_notes3, asl_type1, asl_type2, asl_theme, asl_chat_blog, asl_differentiation, asl_warmup, asl_identity, asl_objectives_description, asl_preparation, asl_usefulness, asl_steps, asl_ending, asl_homeworks, asl_oral, asl_activities, asl_written_work, asl_student_work, asl_dual_assessment, asl_self_assessment, asl_objectives_list, asl_drop_quiz, asl_acs_ids, asl_aa_ids } = academicSubjectLessons

    return this.state.formSections.map((v, i) => {

      if (!v.sectionGroup.includes(this.state.sectionGroup)) {
        return null
      }

      if (formSectionActive.parent == v.uid) {
        return (
          <div className='custom-styled-form' >
            {v.data.map((vv, ii) => {

              if (!vv.sectionGroup.includes(this.state.sectionGroup)) {
                return null
              }

              let thisData, transdisciplinary_theme
              if (vv.uid == '1.3' || vv.uid == '1.4' || vv.uid == '2.3' || vv.uid == '2.4' || vv.uid == '2.5' || vv.uid == '3.5' || vv.uid == '4.1' || vv.uid == '4.3' || vv.uid == '5.1' || vv.uid == '5.2' || vv.uid == '6.1' || vv.uid == '7.1') {
                thisData = this.state.academicSubjectLessonResources.find((v, i) => v.aslr_type == vv.enum && v.editing)
              } else if (vv.uid == '8.2') {
                thisData = this.state.academicSubjectLessonExams.find((v, i) => v.editing)
              }
              if (vv.uid == '3.1') {
                transdisciplinary_theme = this.state.academicSubjectLessonResources.find((v, i) => v.aslr_type == vv.enum)
              }

              return (
                <>
                  <div className={'d-flex flex-row align-items-center justify-content-between section-heading'} id={'form_section_' + vv.uid}>
                    <div>
                      <img className='image' src={(vv.image) ? vv.image : null} />
                      <span className='heading'>{t(vv.name)}</span>
                    </div>
                    <div>
                      {vv.saveAction && vv.showSaveBtn && <button className='btn btn-sm btn-warning mb-1' onClick={vv.saveAction}>
                        {t(vv.name)}
                        <FontAwesomeIcon icon={faCloudArrowUp} style={{ fontSize: 20, marginLeft: 10 }} />
                      </button>}
                      {vv.addAction && vv.showAddBtn && <button className='btn btn-sm btn-primary mb-1' onClick={vv.addAction}>
                        {t(vv.name)}
                        <FontAwesomeIcon icon={faPlus} style={{ fontSize: 20, marginLeft: 10 }} />
                      </button>}
                    </div>
                  </div>

                  {/* LESSON FOCUS */}
                  {(vv.uid == '1.1') && <>
                    <Row>
                      <Col md={3}>
                        <Form.Group className='mb-3'>
                          <Form.Label>
                            <img src={imgAddButton} />
                            {t('Name')}
                          </Form.Label>
                          <Form.Control type='text' value={asl_name} onChange={(e) =>
                            this.setStateAcademicSubjectLessons(
                              'asl_name',
                              e.target.value,
                            )}
                          />
                        </Form.Group>
                      </Col>
                      {(this.state.sectionGroup != 'UAE MOE') &&
                        <Col md={3}>
                          <Form.Group className='mb-3'>
                            <Form.Label>
                              <img src={imgLabelTag} />
                              {t('Code')}
                            </Form.Label>
                            <Form.Control type='text' value={asl_code} onChange={(e) =>
                              this.setStateAcademicSubjectLessons(
                                'asl_code',
                                e.target.value,
                              )}
                            />
                          </Form.Group>
                        </Col>
                      }
                      <Col md={3}>
                        <Form.Group className='mb-3'>
                          <Form.Label>
                            <img src={imgHyperlink} />
                            {t('URL')}
                          </Form.Label>
                          <Form.Control type='text' value={asl_link} onChange={(e) =>
                            this.setStateAcademicSubjectLessons(
                              'asl_link',
                              e.target.value,
                            )}
                          />
                        </Form.Group>
                      </Col>
                      <Col md={3}>
                        <Form.Group className='mb-3'>
                          <Form.Label>
                            <img src={imgCalendar} />
                            {t('Period')}
                          </Form.Label>
                          <Form.Control type='number' value={asl_period} onChange={(e) =>
                            this.setStateAcademicSubjectLessons(
                              'asl_period',
                              e.target.value,
                            )}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={3}>
                        <div style={{ flexDirection: 'column', }} className='d-flex justify-content-center'>
                          <div style={{ height: 175, padding: 10, overflow: 'hidden', borderStyle: 'dashed', borderColor: this.state.dragAreaColor.aslr_attachment, borderWidth: 2, borderRadius: 20, position: 'relative' }} className='d-flex justify-content-center align-items-center'>
                            {image !== '' &&
                              image_prev !== '' ? (
                              <div className='d-flex justify-content-center align-items-center' style={{ flex: 1, }}>
                                <button style={{ padding: 3, border: 3, background: 'none', position: 'absolute', top: 0, right: 0, }}
                                  onClick={() => {
                                    this.setState({ image: '', image_prev: '', image_deleted: 1, });
                                    this.image.current.value = null;
                                  }}>
                                  <FontAwesomeIcon icon={faTimesCircle} color={brand_colors[this.props.defaultTheme.theme_id].color11} style={{ fontSize: 20, }} />
                                </button>
                                <img src={image_prev} alt={t('Preview')} style={{ objectFit: 'contain', width: '100%', height: '100%' }} />
                              </div>
                            ) : (
                              <button onClick={() => this.image.current.click()}
                                style={{ background: 'none', border: 0, flex: 1, }}>
                                <FontAwesomeIcon icon={faPlus} style={{ fontSize: 30, color: brand_colors[this.props.defaultTheme.theme_id].color18 }} />
                              </button>
                            )}
                          </div>
                          <Form.Control className='d-none' ref={this.image} type='file' onChange={this.previewFile} name='image' accept='.jpg, .jpeg, .png' />
                        </div>
                      </Col>
                      <Col md={3}>
                        <Form.Group className='mb-3'>
                          <Form.Label>
                            <img src={imgDescription} />
                            {t('Identity')}
                          </Form.Label>
                          <Form.Control as='textarea' rows={6} value={asl_identity} onChange={(e) =>
                            this.setStateAcademicSubjectLessons(
                              'asl_identity',
                              e.target.value,
                            )}
                          />
                        </Form.Group>
                      </Col>
                      {(this.state.sectionGroup != 'UAE MOE') &&
                        <Col md={6} className="mb-3">
                          <div key="radio_type1">
                            {type1Opts.map((v, i) => {
                              return <Form.Check type={'radio'} id={`radio_type1_${v.value}`} inline key={i} >
                                <Form.Check.Input type={'radio'} value={v.value} name="radio_type1" onChange={(e) => this.setStateAcademicSubjectLessons(
                                  'asl_type1',
                                  e.target.value,
                                )} checked={(asl_type1 == v.value) ? true : false} />
                                <Form.Check.Label className='no-style'>{t(v.label)}</Form.Check.Label>
                              </Form.Check>
                            })}
                          </div>
                          {(asl_type1 == 'Interdisciplinary') &&
                            <div>
                              <Form.Group>
                                <Select isMulti isClearable closeMenuOnSelect={false} className='mt-1' value={asl_acs_ids} options={this.state.getAcademicClassmainSubjectsData} onChange={(acs_ids) => {
                                  this.setStateAcademicSubjectLessons('asl_acs_ids', acs_ids)
                                }}
                                />
                              </Form.Group>
                            </div>
                          }
                        </Col>
                      }
                      {(this.state.sectionGroup != 'UAE MOE') &&

                        <Col md={3}>
                          <Form.Group className='mb-3'>
                            <Form.Label>{t('Theme')}</Form.Label>
                            <Form.Control type='text' value={asl_theme} onChange={(e) =>
                              this.setStateAcademicSubjectLessons(
                                'asl_theme',
                                e.target.value,
                              )}
                            />
                          </Form.Group>
                        </Col>
                      }
                      {(this.state.sectionGroup != 'UAE MOE') &&
                        <Col md={3} className="mb-3">
                          <div key="radio_type2">
                            {type2Opts.map((v, i) => {
                              return <Form.Check type={'radio'} id={`radio_type2_${v.value}`} key={i} inline>
                                <Form.Check.Input type={'radio'} value={v.value} name="radio_type2" onChange={(e) => this.setStateAcademicSubjectLessons(
                                  'asl_type2',
                                  e.target.value,
                                )} checked={(asl_type2 == v.value) ? true : false} />
                                <Form.Check.Label className='no-style'>{t(v.label)}</Form.Check.Label>
                              </Form.Check>
                            })}
                          </div>
                        </Col>
                      }
                      <Col md={2}>
                        <Form.Group className='d-flex flex-row mb-3 form-switch-wrap align-items-center'>
                          <Form.Label>
                            {t('Chat Blog')}
                          </Form.Label>
                          <Form.Check className='' type='switch' id='chatBlog' value={1} checked={asl_chat_blog == 1 ? true : false}
                            onChange={() =>
                              this.setStateAcademicSubjectLessons(
                                'asl_chat_blog',
                                (asl_chat_blog == 1) ? 0 : 1,
                              )}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </>
                  }
                  {/* Central Idea */}
                  {/*
                  `asl_oral`, `asl_activities`, `asl_written_work`, `asl_student_work`, `asl_dual_assessment`, `asl_self_assessment`, `asl_objectives_list`, `asl_drop_quiz`, `asl_identity`  */}
                  {/* asl_notes1
                  asl_differentiation, asl_differentiation
                  asl_steps, asl_steps
                  asl_objectives_description, asl_objectives_description
                  asl_ending, asl_ending
                  asl_preparation, asl_preparation
                  asl_usefulness, asl_usefulness
                  asl_homeworks, asl_homeworks */}
                  {(vv.uid == '1.2') &&

                    <Row>
                      <Col md={6}>
                        {/* Central Idea */}
                        <Form.Group className='mb-3'>
                          <Form.Label>
                            <img src={imgDescription} />
                            {t('Central Idea')}
                          </Form.Label>
                          <Form.Control as='textarea' rows={2} value={asl_notes1} onChange={(e) =>
                            this.setStateAcademicSubjectLessons(
                              'asl_notes1',
                              e.target.value,
                            )}
                          />
                        </Form.Group>

                        {/* Differentiation */}
                        <Form.Group className='mb-3'>
                          <Form.Label>
                            <img src={imgDescription} />
                            {t('Differentiation')}
                          </Form.Label>
                          <Form.Control as='textarea' rows={2} value={asl_differentiation} onChange={(e) =>
                            this.setStateAcademicSubjectLessons(
                              'asl_differentiation',
                              e.target.value,
                            )}
                          />
                        </Form.Group>
                        {/* Competence Goals */}
                        <Form.Group className='mb-3'>
                          <Form.Label>
                            <img src={imgDescription} />
                            {t('Competence Goals')}
                          </Form.Label>
                          <Form.Control as='textarea' rows={2} value={asl_objectives_description} onChange={(e) =>
                            this.setStateAcademicSubjectLessons(
                              'asl_objectives_description',
                              e.target.value,
                            )}
                          />
                        </Form.Group>
                        {/* Homeworks */}
                        <Form.Group className='mb-3'>
                          <Form.Label>
                            <img src={imgDescription} />
                            {t('Homeworks')}
                          </Form.Label>
                          <Form.Control as='textarea' rows={2} value={asl_homeworks} onChange={(e) =>
                            this.setStateAcademicSubjectLessons(
                              'asl_homeworks',
                              e.target.value,
                            )}
                          />
                        </Form.Group>
                      </Col>
                      {/* Explain it to students in brief */}
                      <Col md={6}>
                        <Form.Group className='mb-3'>
                          <Form.Label>
                            <img src={imgDescription} />
                            {t('Explain it to students in brief')}
                          </Form.Label>
                          <Form.Control as='textarea' rows={2} value={asl_usefulness} onChange={(e) =>
                            this.setStateAcademicSubjectLessons(
                              'asl_usefulness',
                              e.target.value,
                            )}
                          />
                        </Form.Group>
                        {/* Preparation */}
                        <Form.Group className='mb-3'>
                          <Form.Label>
                            <img src={imgDescription} />
                            {t('Preparation')}
                          </Form.Label>
                          <Form.Control as='textarea' rows={2} value={asl_preparation} onChange={(e) =>
                            this.setStateAcademicSubjectLessons(
                              'asl_preparation',
                              e.target.value,
                            )}
                          />
                        </Form.Group>
                        {/* Warm up */}
                        <Form.Group className='mb-3'>
                          <Form.Label>
                            <img src={imgDescription} />
                            {t('Warm up')}
                          </Form.Label>
                          <Form.Control as='textarea' rows={2} value={asl_warmup} onChange={(e) =>
                            this.setStateAcademicSubjectLessons(
                              'asl_warmup',
                              e.target.value,
                            )}
                          />
                        </Form.Group>
                        {/* Lesson Steps */}
                        <Form.Group className='mb-3'>
                          <Form.Label>
                            <img src={imgDescription} />
                            {t('Lesson Steps')}
                          </Form.Label>
                          <Form.Control as='textarea' rows={2} value={asl_steps} onChange={(e) =>
                            this.setStateAcademicSubjectLessons(
                              'asl_steps',
                              e.target.value,
                            )}
                          />
                        </Form.Group>
                        {/* Closing */}
                        <Form.Group className='mb-3'>
                          <Form.Label>
                            <img src={imgDescription} />
                            {t('Closing')}
                          </Form.Label>
                          <Form.Control as='textarea' rows={2} value={asl_ending} onChange={(e) =>
                            this.setStateAcademicSubjectLessons(
                              'asl_ending',
                              e.target.value,
                            )}
                          />
                        </Form.Group>
                      </Col>
                      <hr></hr>
                      <Col md={12}><strong>{t('Evaluation Types')}</strong></Col>
                      <Col md={12}>
                        <Row>
                          <Col md={3}>
                            <Form.Group>
                              <Form.Label className='ps-0' style={{
                                left: 0
                              }}>{t('Oral')}</Form.Label>
                              <div className='p-3 pb-0 ps-0'>
                                <Form.Check
                                  type="switch"
                                  id="asl_oral"
                                  label={false}
                                  onChange={() => this.setState({
                                    academicSubjectLessons: {
                                      ...this.state.academicSubjectLessons,
                                      asl_oral: asl_oral == 0 ? 1 : 0
                                    }
                                  })}
                                  checked={asl_oral == 1 ? true : false}
                                />
                              </div>
                            </Form.Group>
                          </Col>
                          <Col md={3}>
                            <Form.Group>
                              <Form.Label className='ps-0' style={{
                                left: 0
                              }}>{t('Activities')}</Form.Label>
                              <div className='p-3 pb-0 ps-0'>
                                <Form.Check
                                  type="switch"
                                  id="asl_activities"
                                  label={false}
                                  onChange={() => this.setState({
                                    academicSubjectLessons: {
                                      ...this.state.academicSubjectLessons,
                                      asl_activities: asl_activities == 0 ? 1 : 0
                                    }
                                  })}
                                  checked={asl_activities == 1 ? true : false}
                                />
                              </div>
                            </Form.Group>
                          </Col>
                          <Col md={3}>
                            <Form.Group>
                              <Form.Label className='ps-0' style={{
                                left: 0
                              }}>{t('Written Work')}</Form.Label>
                              <div className='p-3 pb-0 ps-0'>
                                <Form.Check
                                  type="switch"
                                  id="asl_written_work"
                                  label={false}
                                  onChange={() => this.setState({
                                    academicSubjectLessons: {
                                      ...this.state.academicSubjectLessons,
                                      asl_written_work: asl_written_work == 0 ? 1 : 0
                                    }
                                  })}
                                  checked={asl_written_work == 1 ? true : false}
                                />
                              </div>
                            </Form.Group>
                          </Col>
                          <Col md={3}>
                            <Form.Group>
                              <Form.Label className='ps-0' style={{
                                left: 0
                              }}>{t('Student Work')}</Form.Label>
                              <div className='p-3 pb-0 ps-0'>
                                <Form.Check
                                  type="switch"
                                  id="asl_student_work"
                                  label={false}
                                  onChange={() => this.setState({
                                    academicSubjectLessons: {
                                      ...this.state.academicSubjectLessons,
                                      asl_student_work: asl_student_work == 0 ? 1 : 0
                                    }
                                  })}
                                  checked={asl_student_work == 1 ? true : false}
                                />
                              </div>
                            </Form.Group>
                          </Col>
                          <Col md={3}>
                            <Form.Group>
                              <Form.Label className='ps-0' style={{
                                left: 0
                              }}>{t('Peer-Evaluation')}</Form.Label>
                              <div className='p-3 pb-0 ps-0'>
                                <Form.Check
                                  type="switch"
                                  id="asl_dual_assessment"
                                  label={false}
                                  onChange={() => this.setState({
                                    academicSubjectLessons: {
                                      ...this.state.academicSubjectLessons,
                                      asl_dual_assessment: asl_dual_assessment == 0 ? 1 : 0
                                    }
                                  })}
                                  checked={asl_dual_assessment == 1 ? true : false}
                                />
                              </div>
                            </Form.Group>
                          </Col>
                          <Col md={3}>
                            <Form.Group>
                              <Form.Label className='ps-0' style={{
                                left: 0
                              }}>{t('Self Assessment')}</Form.Label>
                              <div className='p-3 pb-0 ps-0'>
                                <Form.Check
                                  type="switch"
                                  id="asl_self_assessment"
                                  label={false}
                                  onChange={() => this.setState({
                                    academicSubjectLessons: {
                                      ...this.state.academicSubjectLessons,
                                      asl_self_assessment: asl_self_assessment == 0 ? 1 : 0
                                    }
                                  })}
                                  checked={asl_self_assessment == 1 ? true : false}
                                />
                              </div>
                            </Form.Group>
                          </Col>
                          <Col md={3}>
                            <Form.Group>
                              <Form.Label className='ps-0' style={{
                                left: 0
                              }}>{t('Rubrics/List')}</Form.Label>
                              <div className='p-3 pb-0 ps-0'>
                                <Form.Check
                                  type="switch"
                                  id="asl_objectives_list"
                                  label={false}
                                  onChange={() => this.setState({
                                    academicSubjectLessons: {
                                      ...this.state.academicSubjectLessons,
                                      asl_objectives_list: asl_objectives_list == 0 ? 1 : 0
                                    }
                                  })}
                                  checked={asl_objectives_list == 1 ? true : false}
                                />
                              </div>
                            </Form.Group>
                          </Col>
                          <Col md={3}>
                            <Form.Group>
                              <Form.Label className='ps-0' style={{
                                left: 0
                              }}>{t('Quiz')}</Form.Label>
                              <div className='p-3 pb-0 ps-0'>
                                <Form.Check
                                  type="switch"
                                  id="asl_drop_quiz"
                                  label={false}
                                  onChange={() => this.setState({
                                    academicSubjectLessons: {
                                      ...this.state.academicSubjectLessons,
                                      asl_drop_quiz: asl_drop_quiz == 0 ? 1 : 0
                                    }
                                  })}
                                  checked={asl_drop_quiz == 1 ? true : false}
                                />
                              </div>
                            </Form.Group>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  }
                  {/* Lesson Resources */}
                  {(vv.uid == '1.3') &&
                    <>
                      <Row>
                        <Col md={2} className='ps-0'>
                          <Form.Group>
                            <Select className='custom-select' options={externalResource} value={(thisData) ? { value: thisData.aslr_url_type, label: this.state.externalResource.find((vvv) => vvv.value == thisData.aslr_url_type)?.label } : ''} onChange={(externalResourceTypeObj) => {
                              this.setStateAcademicSubjectLessonResources(
                                'aslr_url_type',
                                externalResourceTypeObj.value,
                                vv.enum,
                                (thisData) ? thisData.aslr_id : vv.uid
                              )
                            }}
                            />
                          </Form.Group>
                        </Col>
                        <Col md={3}>
                          <Form.Group>
                            <Form.Control className='no-style' placeholder={t('Name here...')} type='text' value={(thisData) ? thisData.aslr_title : ''} onChange={(event) =>
                              this.setStateAcademicSubjectLessonResources(
                                'aslr_title',
                                event.target.value,
                                vv.enum,
                                (thisData) ? thisData.aslr_id : vv.uid
                              )}
                            />
                          </Form.Group>
                        </Col>
                        {(thisData && thisData.aslr_url_type == 'attachment')
                          ? <Col md="2">
                            <div style={{ flexDirection: 'column' }} className='pic-prev d-flex justify-content-center' id='form_section_2.4'>
                              <div style={{ height: 175, padding: 10, overflow: 'hidden', borderStyle: 'dashed', borderColor: this.state.dragAreaColor.aslr_attachment, borderWidth: 2, borderRadius: 20, position: 'relative' }} className='d-flex justify-content-center align-items-center' onDragEnter={(e) => this.handleDragFileArea(e, 'aslr_attachment')} onDragLeave={(e) => this.handleDragFileArea(e, 'aslr_attachment')} onDragOver={(e) => this.handleDragFileArea(e, 'aslr_attachment')} onDrop={(e) => {

                                e.preventDefault();
                                e.stopPropagation();
                                if (e.dataTransfer.files && e.dataTransfer.files[0]) {
                                  this.addAttachment({ target: { files: e.dataTransfer.files, multiple: false, name: 'aslr_attachment' } })
                                }
                              }}>
                                {aslr_attachment && aslr_attachment != 'deleted' && (!aslr_attachment.data.type.includes('pdf')) && (
                                  <img src={aslr_attachment.url} alt={t('Preview')} style={{ height: '100%' }} />
                                )}
                                {aslr_attachment && aslr_attachment != 'deleted' && (aslr_attachment.data.type.includes('pdf')) && (
                                  <img src={require('../../assets/images/pdf.png')} alt={t('PDF')} style={{ height: '100%' }} />
                                )}
                                {(aslr_attachment == null || aslr_attachment == 'deleted') &&
                                  (
                                    <button onClick={() => this.aslr_attachment_ref.current.click()} style={{ background: 'none', border: 0, flex: 1 }}>
                                      {/* <FontAwesomeIcon icon={faPlus} style={{ fontSize: 30, color: color18 }} /> */}
                                      <span style={{ fontSize: 20, fontWeight: 'bold', color: brand_colors[this.props.defaultTheme.theme_id].color18 }}>{t('Attachment')}</span>
                                    </button>
                                  )
                                }
                                {aslr_attachment && aslr_attachment != 'deleted' && (
                                  <FontAwesomeIcon
                                    icon={faTrashCan}
                                    style={{ fontSize: 14, color: brand_colors[this.props.defaultTheme.theme_id].color11, position: 'absolute', bottom: 6, right: 6, cursor: 'pointer', backgroundColor: brand_colors[this.props.defaultTheme.theme_id].color8, borderRadius: 100, padding: 3 }}
                                    onClick={() => this.setState({ aslr_attachment: 'deleted' })}
                                  />
                                )}
                              </div>
                              <Form.Control className='d-none' ref={this.aslr_attachment_ref} type='file' onChange={this.addAttachment} name='aslr_attachment' />
                            </div>
                          </Col>
                          : <Col md={3} className='ps-0'>
                            <Form.Group>
                              <Form.Control className='no-style' placeholder={t('URL')} type='text' value={(thisData) ? thisData.aslr_url : ''} onChange={(event) =>
                                this.setStateAcademicSubjectLessonResources(
                                  'aslr_url',
                                  event.target.value,
                                  vv.enum,
                                  (thisData) ? thisData.aslr_id : vv.uid
                                )}
                              />
                            </Form.Group>
                          </Col>
                        }
                        <Col md={2} className='ps-0'>
                          <div style={{ display: 'flex', flexDirection: 'row', whiteSpace: 'pre-wrap', cursor: 'pointer' }} className={(thisData && thisData.aslr_tags.length == 1) ? 'badge badge-light-success me-1 mb-1' : 'badge badge-light-warning me-1 mb-1'} onClick={() => {
                            let newThisData = []
                            if (!thisData || (thisData && thisData.aslr_tags.length <= 0)) {
                              newThisData = ['public']
                            } else {
                              newThisData = []
                            }
                            this.setStateAcademicSubjectLessonResources(
                              'aslr_tags',
                              newThisData,
                              vv.enum,
                              (thisData) ? thisData.aslr_id : vv.uid
                            )
                          }}>
                            <FontAwesomeIcon icon={faShareFromSquare} className='me-1' />
                            {(thisData && thisData.aslr_tags.length == 1) ? t('Share with Parents') : t('Don\'t share with Parents')}
                          </div>
                        </Col>
                        <Col md={1} className='ps-0'>
                          <Button disabled={this.state.uploadingResource} className='btn btn-primary btn-icon' onClick={() => vv.saveAction()}>
                            {this.state.uploadingResource ?
                              <Spinner as='span' animation='grow' size='sm' role='status' aria-hidden='true' />
                              :
                              <FontAwesomeIcon
                                icon={faPlus}
                                color={brand_colors[this.props.defaultTheme.theme_id].color8}
                                style={{
                                  fontSize: 16,
                                }}
                              />
                            }
                          </Button>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          {/* ONLY WITHOUT ATTACHMENT */}
                          <div className='d-flex flex-row flex-wrap' style={{ marginTop: '10px' }}>
                            {this.state.academicSubjectLessonResources.map((vvv, iii) => {
                              return (vvv.aslr_type == 'external_resource' && vvv.aslr_id != vv.uid && !vvv.editing && !vvv.aslr_attachment)
                                ? (<div key={iii} className="d-flex flex-column justify-content-end align-items-center listgroup">
                                  <div className="d-flex flex-row justify-content-center align-items-center">
                                    {vvv.aslr_url_type && <img src={require('../../assets/images/' + vvv.aslr_url_type + '.png')} className='image' />}
                                    <span className='title'>{vvv.aslr_title}</span>
                                    {(vvv.aslr_tags.length > 0) && <FontAwesomeIcon icon={faShareFromSquare} style={{ fontSize: 16, color: brand_colors[this.props.defaultTheme.theme_id].color4 }} title={t('Shared with parent')} />}
                                    <FontAwesomeIcon
                                      icon={faEdit}
                                      color={brand_colors[this.props.defaultTheme.theme_id].color16}
                                      style={{
                                        fontSize: 16, cursor: 'pointer', marginLeft: '10px', marginRight: '10px'
                                      }}
                                      onClick={() => {
                                        let academicSubjectLessonResources = this.state.academicSubjectLessonResources
                                        academicSubjectLessonResources = academicSubjectLessonResources.map((vvvv, iiii) => {
                                          return (iii == iiii) ? { ...vvvv, editing: true } : { ...vvvv }
                                        })
                                        this.setState({ academicSubjectLessonResources: academicSubjectLessonResources, aslr_attachment: null })
                                      }}
                                    />
                                    <FontAwesomeIcon
                                      icon={faTimesCircle}
                                      color={brand_colors[this.props.defaultTheme.theme_id].color11}
                                      style={{
                                        fontSize: 16, cursor: 'pointer'
                                      }}
                                      onClick={() => this.setState({ deleteModal: { ...this.state.deleteModal, show: true, id: vvv.aslr_id, action: () => this.deleteAcademicSubjectLessonResources() } })}
                                    />
                                  </div>
                                </div>) : null
                            })}
                          </div>
                          {/* ONLY ATTACHMENT */}
                          <div className='d-flex flex-row flex-wrap' style={{ marginTop: '10px' }}>
                            {this.state.academicSubjectLessonResources.map((vvv, iii) => {
                              return (vvv.aslr_type == 'external_resource' && vvv.aslr_id != vv.uid && !vvv.editing && vvv.aslr_attachment) &&
                                (<div key={iii} className="d-flex flex-column justify-content-between align-items-center listgroup" style={{ width: 'calc( 20% - 10px)' }}>
                                  {vvv.aslr_attachment && (!getExtension(vvv.aslr_attachment).includes('pdf')) && (
                                    <div className='mb-1'>
                                      <img src={portalURL + vvv.center_id + '/academic_subject_lesson_resources/' + vvv.aslr_attachment} style={{ objectFit: 'cover', width: '100%', height: '6rem', backgroundColor: brand_colors[this.props.defaultTheme.theme_id].color8, borderRadius: '1rem' }} />
                                    </div>
                                  )}
                                  {vvv.aslr_attachment && (getExtension(vvv.aslr_attachment).includes('pdf')) && (
                                    <div className='mb-1'>
                                      <img src={require('../../assets/images/pdf.png')} style={{ objectFit: 'cover', width: '100%', height: '9.5rem', backgroundColor: brand_colors[this.props.defaultTheme.theme_id].color8, borderRadius: '1rem' }} />
                                    </div>
                                  )}
                                  <div className="d-flex flex-row justify-content-center align-items-center">
                                    <img src={require('../../assets/images/' + vvv.aslr_url_type + '.png')} className='image' />
                                    <span className='title'>{vvv.aslr_title}</span>
                                    <FontAwesomeIcon
                                      icon={faEdit}
                                      color={brand_colors[this.props.defaultTheme.theme_id].color16}
                                      style={{
                                        fontSize: 16, cursor: 'pointer', marginLeft: '10px', marginRight: '10px'
                                      }}
                                      onClick={() => {
                                        let academicSubjectLessonResources = this.state.academicSubjectLessonResources
                                        academicSubjectLessonResources = academicSubjectLessonResources.map((vvvv, iiii) => {
                                          return (iii == iiii) ? { ...vvvv, editing: true } : { ...vvvv }
                                        })
                                        this.setState({ academicSubjectLessonResources: academicSubjectLessonResources, aslr_attachment: (vvv.aslr_attachment) ? { url: portalURL + vvv.center_id + '/academic_subject_lesson_resources/' + vvv.aslr_attachment, data: { type: getExtension(vvv.aslr_attachment) } } : null, })
                                      }}
                                    />
                                    <FontAwesomeIcon
                                      icon={faTimesCircle}
                                      color={brand_colors[this.props.defaultTheme.theme_id].color11}
                                      style={{
                                        fontSize: 16, cursor: 'pointer'
                                      }}
                                      onClick={() => this.setState({ deleteModal: { ...this.state.deleteModal, show: true, id: vvv.aslr_id, action: () => this.deleteAcademicSubjectLessonResources() } })}
                                    />
                                  </div>
                                </div>)
                            })}
                          </div>
                        </Col>
                      </Row>
                    </>
                  }

                  {/* Transdisciplinary Theme */}
                  {(vv.uid == '3.1') &&
                    <>
                      <Row>
                        <Col md={12}>
                          <span style={{ fontSize: 14, color: brand_colors[this.props.defaultTheme.theme_id].color9, opacity: 0.8 }}>{t('Select the transdisciplinary theme for this unit')}</span>
                        </Col>
                        <Col md={12} className='d-flex flex-row flex-wrap' style={{ marginTop: '10px' }}>
                          {this.state.academicLessonAttributes.map((vvv, iii) => {
                            return (vvv.ala_type == 'lesson_focus' && vvv.ala_parent_id == 0) && <div className='div-box-theme cursor-pointer' style={{ minWidth: '31%' }} onClick={() => {
                              let academicSubjectLessonResources = this.state.academicSubjectLessonResources
                              // DON'T CHANGE IN CASE THE SAME BOX IS CLICKED
                              if (transdisciplinary_theme && transdisciplinary_theme.aslr_type_id != vvv.ala_id) {
                                // REMOVE ALL CHILDREN
                                academicSubjectLessonResources = academicSubjectLessonResources.filter((vvvv, iiii) => {
                                  return (vvvv.aslr_type != 'transdisciplinary_theme_focus')
                                })
                                // THEN CHANGE THE PARENT SELECTION
                                academicSubjectLessonResources = academicSubjectLessonResources.map((vvvv, iiii) => {
                                  return (vvvv.aslr_type == 'transdisciplinary_theme')
                                    ? { ...vvvv, aslr_title: vvv.ala_title, aslr_type_id: vvv.ala_id }
                                    : { ...vvvv }
                                })
                              } else {
                                // ADD NEW PARENT
                                academicSubjectLessonResources.push({
                                  aslr_id: chance.unique(chance.integer, 1, { min: 99999999999, max: 999999999999 })[0],
                                  aslr_type: 'transdisciplinary_theme',
                                  aslr_title: (sectionGroup == 'Middle Year Programme') ? vvv.ala_title2 : vvv.ala_title,
                                  aslr_url_type: '',
                                  aslr_url: '',
                                  aslr_desc: '',
                                  aslr_tags: [],
                                  aslr_type_id: vvv.ala_id,
                                  ab_id: this.props.location.state.lessonData.ab_id,
                                  as_id: this.props.location.state.lessonData.as_id,
                                  acs_id: this.props.location.state.lessonData.acs_id,
                                  asl_id: this.props.location.state.lessonData.asl_id,
                                  // classmain_id: this.props.selectedClass.class.classmain_id,
                                })
                              }
                              this.setState({ academicSubjectLessonResources }, () => {
                                this.checkCompletedFormSections()
                              })
                            }}>
                              <div className='check'>
                                {(transdisciplinary_theme)
                                  ? <FontAwesomeIcon
                                    icon={(transdisciplinary_theme.aslr_type_id == vvv.ala_id) ? faCircleDot : faCircle}
                                    color={(transdisciplinary_theme.aslr_type_id == vvv.ala_id) ? brand_colors[this.props.defaultTheme.theme_id].color4 : brand_colors[this.props.defaultTheme.theme_id].color9}
                                    style={{ fontSize: 14, opacity: (transdisciplinary_theme.aslr_type_id == vvv.ala_id) ? 1 : 0.3 }}
                                  /> : null}
                              </div>
                              <div className='d-flex flex-row align-items-center'>
                                <div className='div_image'>
                                  <img src={portalURL + vvv.center_id + '/academic_lesson_attributes/' + vvv.ala_description_2} className="image" />
                                </div>
                                <div className='heading'>{(['Middle Year Programme', 'Diploma Programme'].includes(sectionGroup)) ? vvv.ala_title2 : vvv.ala_title}</div>
                              </div>
                            </div>
                          })}
                        </Col>
                      </Row>
                      <Row>
                        {(transdisciplinary_theme)
                          ? <Col md={12}>
                            <div style={{ fontSize: 14, color: brand_colors[this.props.defaultTheme.theme_id].color9, opacity: 0.8 }}>{t('Select the focus of transdisciplinary theme.')}</div>
                            <div style={{ fontSize: 14, color: brand_colors[this.props.defaultTheme.theme_id].color9, opacity: 0.6 }}>{t('Which parts of the Transdisciplinary Theme will the unit of inquiry focus on?')}</div>
                          </Col> : null}
                        <Col>
                          <div className='d-flex flex-row flex-wrap' style={{ marginTop: '10px' }}>
                            {(transdisciplinary_theme) && this.state.academicLessonAttributes.map((vvv, iii) => {
                              let academicSubjectLessonResourcesFind = this.state.academicSubjectLessonResources.find((vvvv, iiii) => {
                                return (vvvv.aslr_type == 'transdisciplinary_theme_focus' && vvvv.aslr_type_id == vvv.ala_id)
                              })

                              return (vvv.ala_type == 'lesson_focus' && transdisciplinary_theme.aslr_type_id == vvv.ala_parent_id)
                                ? (<div key={iii} className="d-flex flex-row justify-content-center align-items-center listgroup cursor-pointer" onClick={() => {
                                  let academicSubjectLessonResources = this.state.academicSubjectLessonResources
                                  let academicSubjectLessonResourcesFind = academicSubjectLessonResources.findIndex((vvvv, iiii) => {
                                    return (vvvv.aslr_type == 'transdisciplinary_theme_focus' && vvvv.aslr_type_id == vvv.ala_id)
                                  })
                                  // IF NOT IN ARRAY ALREADY THEN ADD
                                  if (academicSubjectLessonResourcesFind == -1) {
                                    academicSubjectLessonResources.push({
                                      aslr_id: chance.unique(chance.integer, 1, { min: 99999999999, max: 999999999999 })[0],
                                      aslr_type: 'transdisciplinary_theme_focus',
                                      aslr_title: vvv.ala_title,
                                      aslr_url_type: '',
                                      aslr_url: '',
                                      aslr_desc: '',
                                      aslr_tags: [],
                                      aslr_type_id: vvv.ala_id,
                                      ab_id: this.props.location.state.lessonData.ab_id,
                                      as_id: this.props.location.state.lessonData.as_id,
                                      acs_id: this.props.location.state.lessonData.acs_id,
                                      asl_id: this.props.location.state.lessonData.asl_id,
                                      // classmain_id: this.props.selectedClass.class.classmain_id,
                                    })
                                  }
                                  //JUST REMOVE FROM ARRAY IF ALREADY EXISTS
                                  else {
                                    academicSubjectLessonResources.splice(academicSubjectLessonResourcesFind, 1);
                                  }
                                  this.setState({ academicSubjectLessonResources }, () => {
                                    this.checkCompletedFormSections()
                                  })
                                }}>
                                  <FontAwesomeIcon
                                    icon={(academicSubjectLessonResourcesFind) ? faCheckCircle : faCircle}
                                    color={(academicSubjectLessonResourcesFind) ? brand_colors[this.props.defaultTheme.theme_id].color4 : brand_colors[this.props.defaultTheme.theme_id].color9}
                                    style={{ fontSize: 16, opacity: (academicSubjectLessonResourcesFind) ? 1 : 0.3 }}
                                  />
                                  <span className='title'>{vvv.ala_title}</span>
                                </div>) : null
                            })}
                          </div>
                        </Col>
                      </Row>
                    </>
                  }

                  {/* Approaches to learning */}
                  {(vv.uid == '2.1') &&
                    <Row style={{ marginBottom: '20px' }}>
                      <Col md={12}>
                        <Alert variant='secondary' className='mb-1'>
                          {vv.desc}
                        </Alert>
                      </Col>
                      <Col md="8">
                        {this.state.academicLessonAttributes.map((vvv, iii) => {
                          if (vvv.ala_type == vv.enum && vvv.ala_parent_id == 0 && vvv.agegroup_types.includes(sectionGroup)) {
                            let allChildren = this.state.academicLessonAttributes.filter((vvvv, iiii) => vvvv.ala_parent_id == vvv.ala_id)
                            let selectedChildren = []
                            allChildren.map((vvvv, iiii) => {
                              let find = this.state.academicSubjectLessonResources.find((vvvvv, iiiii) => (vv.enum == vvvvv.aslr_type && vvvv.ala_id == vvvvv.aslr_type_id))
                              if (find) {
                                selectedChildren.push(find)
                              }
                            })

                            return <div className='div-box-theme'>
                              <div className='d-flex flex-row' style={{ cursor: 'pointer' }} onClick={() => this.setState({
                                addModal: {
                                  ...this.state.addModal, show: true, title: 'Add ' + vvv.ala_title, enum: vv.enum, action: () => {
                                    let academicSubjectLessonResources = this.state.academicSubjectLessonResources
                                    allChildren.map((vvvv) => {
                                      let findSelectedIndex = this.state.addModal.selectedData.findIndex((vvvvv) => vvvv.ala_id == vvvvv.aslr_type_id)
                                      let findSelectedIndex2 = this.state.academicSubjectLessonResources.findIndex((vvvvv) => (vv.enum == vvvvv.aslr_type && vvvv.ala_id == vvvvv.aslr_type_id))

                                      if (findSelectedIndex !== -1 && findSelectedIndex2 === -1) {
                                        academicSubjectLessonResources.push(this.state.addModal.selectedData[findSelectedIndex])
                                      } else if (findSelectedIndex === -1 && findSelectedIndex2 !== -1) {
                                        academicSubjectLessonResources.splice(findSelectedIndex2, 1)
                                      }
                                    })
                                    this.setState({ academicSubjectLessonResources: academicSubjectLessonResources }, () => this.state.addModal.onClose())
                                  }, allData: allChildren, selectedData: selectedChildren
                                }
                              })}>
                                <div className='div_image'>
                                  <FontAwesomeIcon
                                    icon={faPlus}
                                    color={brand_colors[this.props.defaultTheme.theme_id].color18}
                                    style={{ fontSize: 16, }}
                                  />
                                </div>
                                <div className='heading'>{vvv.ala_title}</div>
                              </div>
                              <div style={{ fontSize: '13px', color: brand_colors[this.props.defaultTheme.theme_id].color9, opacity: '0.7' }}>{vvv.ala_description_1}</div>
                              {(vvv.ala_description_2) && <div style={{ fontSize: '13px', color: brand_colors[this.props.defaultTheme.theme_id].color9, opacity: '0.7' }}>{vvv.ala_description_2}</div>}
                              {(selectedChildren.length > 0)
                                && <div style={{ borderTopStyle: 'solid', borderTopWidth: 1, borderTopColor: brand_colors[this.props.defaultTheme.theme_id].color18, marginTop: '5px', paddingTop: '5px', width: '100%' }}>
                                  {selectedChildren.map((vvvv, iiii) => {
                                    return <div className='d-flex flex-row justify-content-between' style={{ color: brand_colors[this.props.defaultTheme.theme_id].color9, opacity: 0.7 }}>
                                      <div style={{ marginTop: '10px', fontSize: '14px', color: brand_colors[this.props.defaultTheme.theme_id].color9, opacity: 0.7 }}><span style={{ fontWeight: (vvvv.aslr_desc) ? '600' : 'normal' }}>{vvvv.aslr_title}</span>{(vvvv.aslr_desc) && ' : ' + vvvv.aslr_desc}</div>
                                    </div>
                                  })}
                                </div>
                              }
                            </div>
                          }
                          return null
                        })}
                      </Col>
                    </Row>
                  }

                  {/* Scope & Sequences */}
                  {(vv.uid == '2.2') &&
                    <Row style={{ marginBottom: '20px' }}>
                      <Col md="8">
                        {this.state.curriculum.map((vvv, iii) => {
                          let allChildren = vvv.curriculum
                          let selectedChildren = []
                          allChildren.map((vvvv, iiii) => {
                            let find = this.state.academicSubjectLessonResources.find((vvvvv, iiiii) => (vv.enum == vvvvv.aslr_type && vvvv.curriculum_id == vvvvv.aslr_type_id))
                            if (find) {
                              selectedChildren.push(find)
                            }
                          })

                          return <div className='div-box-theme'>
                            <div className='d-flex flex-row' style={{ cursor: 'pointer' }} onClick={() => this.setState({
                              addModal: {
                                ...this.state.addModal, show: true, title: 'Add ' + vvv.learnarea_desc, enum: vv.enum, action: () => {
                                  let academicSubjectLessonResources = this.state.academicSubjectLessonResources
                                  allChildren.map((vvvv) => {
                                    let findSelectedIndex = this.state.addModal.selectedData.findIndex((vvvvv) => vvvv.curriculum_id == vvvvv.aslr_type_id)
                                    let findSelectedIndex2 = this.state.academicSubjectLessonResources.findIndex((vvvvv) => (vv.enum == vvvvv.aslr_type && vvvv.curriculum_id == vvvvv.aslr_type_id))

                                    if (findSelectedIndex !== -1 && findSelectedIndex2 === -1) {
                                      academicSubjectLessonResources.push(this.state.addModal.selectedData[findSelectedIndex])
                                    } else if (findSelectedIndex === -1 && findSelectedIndex2 !== -1) {
                                      academicSubjectLessonResources.splice(findSelectedIndex2, 1)
                                    }
                                  })
                                  this.setState({ academicSubjectLessonResources: academicSubjectLessonResources }, () => this.state.addModal.onClose())
                                }, allData: allChildren, selectedData: selectedChildren
                              }
                            })}>
                              <div className='div_image'>
                                <FontAwesomeIcon
                                  icon={faPlus}
                                  color={brand_colors[this.props.defaultTheme.theme_id].color18}
                                  style={{ fontSize: 16, }}
                                />
                              </div>
                              <div className='heading'>{vvv.learnarea_desc || vvv.learnarea_code}</div>
                            </div>
                            {(selectedChildren.length > 0)
                              && <div style={{ borderTopStyle: 'solid', borderTopWidth: 1, borderTopColor: brand_colors[this.props.defaultTheme.theme_id].color18, marginTop: '5px', paddingTop: '5px', width: '100%' }}>
                                {selectedChildren.map((vvvv, iiii) => {
                                  return <div className='d-flex flex-row justify-content-between' style={{ color: brand_colors[this.props.defaultTheme.theme_id].color9, opacity: 0.7 }}>
                                    <div style={{ marginTop: '10px', fontSize: '14px', color: brand_colors[this.props.defaultTheme.theme_id].color9, opacity: 0.7 }}>{vvvv.aslr_title || vvvv.aslr_desc}</div>
                                  </div>
                                })}
                              </div>
                            }
                          </div>
                        })}

                      </Col>
                    </Row>
                  }

                  {/* 3.2 Learner Profile */}
                  {/* 9.1 Opportunities */}
                  {/* 9.2 Outcomes */}
                  {(vv.uid == '3.2' || vv.uid == '9.1' || vv.uid == '9.2') &&
                    <Row>
                      <Col md={12}>
                        <Alert variant='secondary' >{t(vv.desc)}</Alert>
                      </Col>
                      <Col md={12} className='d-flex flex-row flex-wrap'>
                        {this.state.academicLessonAttributes.map((vvv, iii) => {
                          let findIndexSelectedData = this.state.academicSubjectLessonResources.findIndex((vvvv) => (vvvv.aslr_type_id == vvv.ala_id && vvvv.aslr_type == vv.enum))
                          if (vvv.ala_type == vv.enum && vvv.ala_parent_id == 0) {
                            return <div className='div-box-theme cursor-pointer' style={{ flex: 'none', width: '47%' }} onClick={() => {
                              let academicSubjectLessonResources = this.state.academicSubjectLessonResources
                              if (findIndexSelectedData !== -1) {
                                academicSubjectLessonResources.splice(findIndexSelectedData, 1)
                              } else {
                                academicSubjectLessonResources.push({
                                  aslr_id: chance.unique(chance.integer, 1, { min: 99999999999, max: 999999999999 })[0],
                                  aslr_type: vv.enum,
                                  aslr_title: vvv.ala_title,
                                  aslr_url_type: '',
                                  aslr_url: '',
                                  aslr_desc: vvv.ala_description_1,
                                  aslr_tags: [],
                                  aslr_type_id: vvv.ala_id,
                                  ab_id: this.props.location.state.lessonData.ab_id,
                                  as_id: this.props.location.state.lessonData.as_id,
                                  acs_id: this.props.location.state.lessonData.acs_id,
                                  asl_id: this.props.location.state.lessonData.asl_id,
                                  // classmain_id: this.props.selectedClass.class.classmain_id,
                                })
                              }
                              this.setState({ academicSubjectLessonResources: academicSubjectLessonResources })
                            }}>
                              <div className='check'>
                                <FontAwesomeIcon
                                  icon={(findIndexSelectedData !== -1) ? faCheckCircle : faCircle}
                                  color={(findIndexSelectedData !== -1) ? brand_colors[this.props.defaultTheme.theme_id].color4 : brand_colors[this.props.defaultTheme.theme_id].color9}
                                  style={{ fontSize: 14, opacity: (findIndexSelectedData !== -1) ? 1 : 0.3 }}
                                />
                              </div>
                              <div className='heading'>{vvv.ala_title}</div>
                              {(vvv.ala_description_1) && <div className='description'>{vvv.ala_description_1}</div>}
                              {(vvv.ala_description_2) && <div className='description2'>{vvv.ala_description_2}</div>}
                            </div>
                          }
                        })}
                      </Col>

                    </Row>
                  }

                  {/* Key Concepts */}
                  {(vv.uid == '3.3') &&
                    <Row>
                      <Col md={12}>
                        <Alert variant='secondary' >{t(vv.desc)}</Alert>
                      </Col>
                      <Col md={12} className='d-flex flex-row flex-wrap'>
                        {this.state.academicLessonAttributes.map((vvv, iii) => {
                          let findIndexSelectedData = this.state.academicSubjectLessonResources.findIndex((vvvv) => (vvvv.aslr_type_id == vvv.ala_id && vvvv.aslr_type == vv.enum))

                          if (vvv.ala_type == vv.enum && vvv.ala_parent_id == 0 && vvv.agegroup_types.includes(sectionGroup)) {
                            return <div className='div-box-theme' style={{ flex: 'none', width: '47%' }}>
                              <div className='heading'>
                                {vvv.ala_title}
                              </div>
                              <div className='description'>{vvv.ala_description_2}</div>
                              <Form.Group className='mb-3' style={{ width: '100%' }}>
                                <Form.Label>
                                  <img src={imgDescription} />
                                  {vvv.ala_description_1}
                                </Form.Label>
                                <Form.Control as='textarea' rows={3} value={(findIndexSelectedData !== -1) ? this.state.academicSubjectLessonResources[findIndexSelectedData].aslr_desc : ''} onChange={(event) => {
                                  let academicSubjectLessonResources = this.state.academicSubjectLessonResources

                                  if (findIndexSelectedData != -1) {
                                    if (event.target.value == '') {
                                      academicSubjectLessonResources.splice(findIndexSelectedData, 1)
                                    } else {
                                      academicSubjectLessonResources[findIndexSelectedData]['aslr_desc'] = event.target.value
                                    }
                                  } else {
                                    academicSubjectLessonResources.push({
                                      aslr_id: chance.unique(chance.integer, 1, { min: 99999999999, max: 999999999999 })[0],
                                      aslr_type: vv.enum,
                                      aslr_title: vvv.ala_title,
                                      aslr_url_type: '',
                                      aslr_url: '',
                                      aslr_tags: [],
                                      aslr_desc: event.target.value,
                                      aslr_type_id: vvv.ala_id,
                                      ab_id: this.props.location.state.lessonData.ab_id,
                                      as_id: this.props.location.state.lessonData.as_id,
                                      acs_id: this.props.location.state.lessonData.acs_id,
                                      asl_id: this.props.location.state.lessonData.asl_id,
                                      // classmain_id: this.props.selectedClass.class.classmain_id,
                                    })
                                  }
                                  this.setState({ academicSubjectLessonResources })
                                }}
                                />
                              </Form.Group>
                            </div>
                          }
                        })}
                      </Col>
                    </Row>
                  }

                  {/* Related Concepts */}
                  {(vv.uid == '3.4') && <>
                    <Row style={{ marginBottom: '20px' }}>
                      <Col md={12}>
                        <Alert variant='secondary' >
                          {vv.desc.map((vvv) => {
                            return <div>{vvv}</div>
                          })}
                        </Alert>
                      </Col>
                      <Col md="6">
                        <div className='div-box-theme' style={{ marginRight: 0 }}>
                          <div className='d-flex flex-row' style={{ cursor: 'pointer' }}>
                            <div className='heading'>By Subject</div>
                          </div>
                          <div style={{ borderTopStyle: 'solid', borderTopWidth: 1, borderTopColor: brand_colors[this.props.defaultTheme.theme_id].color18, marginTop: '5px', paddingTop: '5px', width: '100%' }}>
                            <div className='d-flex flex-column justify-content-between' style={{}}>
                              {this.state.curriculum.map((vvv, iii) => {
                                let findIndexSelectedData = this.state.academicSubjectLessonResources.findIndex((vvvv) => (vvvv.aslr_type_id == vvv.learnarea_id && vvvv.aslr_type == 'related_concept_learn_area'))
                                let thisValue = []
                                if (findIndexSelectedData !== -1) {
                                  JSON.parse(this.state.academicSubjectLessonResources[findIndexSelectedData].aslr_desc).map((vvvv) => {
                                    thisValue.push({ label: vvvv, value: vvvv })
                                  })
                                }
                                return <div style={{ marginTop: '10px', fontSize: '14px', color: brand_colors[this.props.defaultTheme.theme_id].color9, opacity: 0.7 }}>
                                  <span style={{ fontWeight: '600' }}>{vvv.learnarea_desc || vvv.learnarea_code}</span>
                                  <CreatableSelect isMulti={true} placeholder={'Select or Write new'} menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} isClearable
                                    options={[]} value={thisValue}
                                    onChange={(event) => {
                                      let academicSubjectLessonResources = this.state.academicSubjectLessonResources

                                      if (findIndexSelectedData != -1) {
                                        if (event.length <= 0) {
                                          academicSubjectLessonResources.splice(findIndexSelectedData, 1)
                                        } else {
                                          academicSubjectLessonResources[findIndexSelectedData]['aslr_desc'] = JSON.stringify(event.map((vvvv) => vvvv.value))
                                        }
                                      } else {
                                        academicSubjectLessonResources.push({
                                          aslr_id: chance.unique(chance.integer, 1, { min: 99999999999, max: 999999999999 })[0],
                                          aslr_type: 'related_concept_learn_area',
                                          aslr_title: vvv.learnarea_desc,
                                          aslr_url_type: '',
                                          aslr_url: '',
                                          aslr_desc: JSON.stringify(event.map((vvvv) => vvvv.value)),
                                          aslr_tags: [],
                                          aslr_type_id: vvv.learnarea_id,
                                          ab_id: this.props.location.state.lessonData.ab_id,
                                          as_id: this.props.location.state.lessonData.as_id,
                                          acs_id: this.props.location.state.lessonData.acs_id,
                                          asl_id: this.props.location.state.lessonData.asl_id,
                                          // classmain_id: this.props.selectedClass.class.classmain_id,
                                        })
                                      }
                                      this.setState({ academicSubjectLessonResources })
                                    }}
                                  />
                                </div>
                              })}
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col md="6">
                        <div className='div-box-theme' style={{ marginRight: 0 }}>
                          <div className='d-flex flex-row' style={{ cursor: 'pointer' }}>
                            <div className='heading'>By Key concept</div>
                          </div>
                          <div style={{ borderTopStyle: 'solid', borderTopWidth: 1, borderTopColor: brand_colors[this.props.defaultTheme.theme_id].color18, marginTop: '5px', paddingTop: '5px', width: '100%' }}>
                            <div className='d-flex flex-column justify-content-between' style={{}}>
                              {this.state.academicLessonAttributes.map((vvv, iii) => {
                                if (vvv.ala_type == 'lesson_key_concept' && vvv.ala_parent_id == 0) {

                                  let findIndexSelectedData = this.state.academicSubjectLessonResources.findIndex((vvvv) => (vvvv.aslr_type_id == vvv.ala_id && vvvv.aslr_type == 'related_concept_key_concept'))
                                  let thisValue = []
                                  if (findIndexSelectedData !== -1) {
                                    JSON.parse(this.state.academicSubjectLessonResources[findIndexSelectedData].aslr_desc).map((vvvv) => {
                                      thisValue.push({ label: vvvv, value: vvvv })
                                    })
                                  }
                                  return <div style={{ marginTop: '10px', fontSize: '14px', color: brand_colors[this.props.defaultTheme.theme_id].color9, opacity: 0.7 }}>
                                    <span style={{ fontWeight: '600' }}>{vvv.ala_title}</span>
                                    <CreatableSelect isMulti={true} placeholder={'Select or Write new'} menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} isClearable
                                      options={[]} value={thisValue}
                                      onChange={(event) => {
                                        let academicSubjectLessonResources = this.state.academicSubjectLessonResources

                                        if (findIndexSelectedData != -1) {
                                          if (event.length <= 0) {
                                            academicSubjectLessonResources.splice(findIndexSelectedData, 1)
                                          } else {
                                            academicSubjectLessonResources[findIndexSelectedData]['aslr_desc'] = JSON.stringify(event.map((vvvv) => vvvv.value))
                                          }
                                        } else {
                                          academicSubjectLessonResources.push({
                                            aslr_id: chance.unique(chance.integer, 1, { min: 99999999999, max: 999999999999 })[0],
                                            aslr_type: 'related_concept_key_concept',
                                            aslr_title: vvv.ala_title,
                                            aslr_url_type: '',
                                            aslr_url: '',
                                            aslr_desc: JSON.stringify(event.map((vvvv) => vvvv.value)),
                                            aslr_tags: [],
                                            aslr_type_id: vvv.ala_id,
                                            ab_id: this.props.location.state.lessonData.ab_id,
                                            as_id: this.props.location.state.lessonData.as_id,
                                            acs_id: this.props.location.state.lessonData.acs_id,
                                            asl_id: this.props.location.state.lessonData.asl_id,
                                            // classmain_id: this.props.selectedClass.class.classmain_id,
                                          })
                                        }
                                        this.setState({ academicSubjectLessonResources })
                                      }}
                                    />
                                  </div>
                                }
                              })}


                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>

                  </>
                  }

                  {/* Action */}
                  {(vv.uid == '4.2') &&
                    <Row>
                      <Col md={12}>
                        <Alert variant='secondary' className='mb-1'>
                          {vv.desc.map((vvv) => {
                            return <div>{vvv}</div>
                          })}
                        </Alert>
                      </Col>
                      <Col md={12} className='d-flex flex-row flex-wrap'>
                        {this.state.academicLessonAttributes.map((vvv, iii) => {
                          let findIndexSelectedData = this.state.academicSubjectLessonResources.findIndex((vvvv) => (vvvv.aslr_type_id == vvv.ala_id && vvvv.aslr_type == vv.enum))
                          if (vvv.ala_type == vv.enum && vvv.ala_parent_id == 0) {
                            return <div className='div-box-theme' style={{ flex: 'none', width: '47%' }}>
                              <div className='heading'>
                                {vvv.ala_title}
                              </div>
                              <div className='description'>{vvv.ala_description_1}</div>
                              <Form.Group className='mb-3' style={{ width: '100%' }}>
                                <Form.Label>
                                  <img src={imgDescription} />
                                  {t('Note')}
                                </Form.Label>
                                <Form.Control as='textarea' rows={3} value={(findIndexSelectedData !== -1) ? this.state.academicSubjectLessonResources[findIndexSelectedData].aslr_desc : ''} onChange={(event) => {
                                  let academicSubjectLessonResources = this.state.academicSubjectLessonResources

                                  if (findIndexSelectedData != -1) {
                                    if (event.target.value == '') {
                                      academicSubjectLessonResources.splice(findIndexSelectedData, 1)
                                    } else {
                                      academicSubjectLessonResources[findIndexSelectedData]['aslr_desc'] = event.target.value
                                    }
                                  } else {
                                    academicSubjectLessonResources.push({
                                      aslr_id: chance.unique(chance.integer, 1, { min: 99999999999, max: 999999999999 })[0],
                                      aslr_type: vv.enum,
                                      aslr_title: vvv.ala_title,
                                      aslr_url_type: '',
                                      aslr_url: '',
                                      aslr_desc: event.target.value,
                                      aslr_tags: [],
                                      aslr_type_id: vvv.ala_id,
                                      ab_id: this.props.location.state.lessonData.ab_id,
                                      as_id: this.props.location.state.lessonData.as_id,
                                      acs_id: this.props.location.state.lessonData.acs_id,
                                      asl_id: this.props.location.state.lessonData.asl_id,
                                      // classmain_id: this.props.selectedClass.class.classmain_id,
                                    })
                                  }
                                  this.setState({ academicSubjectLessonResources })
                                }}
                                />
                              </Form.Group>
                            </div>
                          }
                        })}
                      </Col>

                    </Row>
                  }

                  {/* 1.4 Teaching Resources */}
                  {/* 2.3 Learning Goals & Success Criteria */}
                  {/* 2.4 Key Vocab */}
                  {/* 2.5 Key Skills */}
                  {/* 3.5 Lines of Inquiry */}
                  {/* 4.1 Prior Learning */}
                  {/* 4.3 Connections */}
                  {/* 5.1 Teacher Questions */}
                  {/* 5.2 Student Questions */}
                  {(['1.4', '2.3', '2.4', '2.5', '3.5', '4.1', '4.3', '5.1', '5.2'].includes(vv.uid)) &&
                    <>
                      <Row>
                        <Col md={12}>
                          {(vv.desc.length > 0) && <Alert variant='secondary' className='mb-1'>
                            {vv.desc.map((vvv) => {
                              return <div>{vvv}</div>
                            })}
                          </Alert>}
                        </Col>
                        {/* 5.1 Teacher Questions */}
                        {/* 5.2 Student Questions */}
                        {(['5.1', '5.2'].includes(vv.uid)) && ['Primary Year Programme', 'Middle Year Programme', 'Diploma Programme'].includes(this.state.sectionGroup) &&
                          <Col md={12}>
                            <Alert variant='light' className='mb-1'>
                              The lesson's lines of inquiry:
                              <hr style={{ margin: '0.5rem 0' }} />
                              {this.state.academicSubjectLessonResources.map((vvv, iii) => {
                                return (vvv.aslr_type == 'lines_of_enquiry' && vvv.aslr_id != vv.uid && !vvv.editing) &&
                                  <div style={{ fontSize: 14 }}>
                                    <FontAwesomeIcon
                                      icon={faCircle}
                                      color={brand_colors[this.props.defaultTheme.theme_id].color9}
                                      style={{
                                        fontSize: 10,
                                        marginRight: 5,
                                        opacity: 0.7
                                      }}
                                    />
                                    {vvv.aslr_title}
                                  </div>
                              })}
                            </Alert>
                          </Col>
                        }

                        <Col md={8}>
                          <Form.Group className='mb-3'>
                            <Form.Label>
                              <img src={imgDescription} />
                              {t('Note')}
                            </Form.Label>
                            <Form.Control as='textarea' rows={3} value={(thisData) ? thisData.aslr_title : ''} onChange={(event) =>
                              this.setStateAcademicSubjectLessonResources(
                                'aslr_title',
                                event.target.value,
                                vv.enum,
                                (thisData) ? thisData.aslr_id : vv.uid
                              )}
                            />
                          </Form.Group>
                        </Col>
                        <Col md={4} className='pt-2 d-flex flex-column justify-content-between'>
                          {/* 5.1 Teacher Questions */}
                          {/* 5.2 Student Questions */}
                          {/* MULTI SELECT OPTIONS FOR ASLR_DESC, Tags  */}
                          {(['5.1', '5.2'].includes(vv.uid)) &&
                            <div className='d-flex flex-row flex-wrap'>
                              {this.state.questionTypes.map((qtv) => {
                                let classForThis = 'badge badge-light-light me-1 d-flex flex-row cursor-pointer mb-1'
                                let selected = false
                                if (thisData && thisData.aslr_tags.includes(qtv)) {
                                  selected = true
                                  classForThis = 'badge badge-light-info me-1 d-flex flex-row cursor-pointer mb-1'
                                }
                                return <div className={classForThis} onClick={(event) => {
                                  let newThisData = []
                                  if (selected) {
                                    newThisData = thisData.aslr_tags.filter((qtvf) => qtvf != qtv)
                                  } else {
                                    if (thisData) {
                                      thisData.aslr_tags.push(qtv)
                                      newThisData = thisData.aslr_tags
                                    } else {
                                      newThisData = [qtv]
                                    }
                                  }
                                  this.setStateAcademicSubjectLessonResources(
                                    'aslr_tags',
                                    newThisData,
                                    vv.enum,
                                    (thisData) ? thisData.aslr_id : vv.uid
                                  )
                                }}>
                                  <FontAwesomeIcon icon={(selected) ? faCheckCircle : faTimesCircle} className='me-1' />
                                  {qtv}
                                </div>
                              })}
                            </div>
                          }

                          <Button className='btn btn-primary btn-icon' onClick={() => vv.saveAction()}>
                            <FontAwesomeIcon
                              icon={faPlus}
                              color={brand_colors[this.props.defaultTheme.theme_id].color8}
                              style={{
                                fontSize: 16,
                              }}
                            />
                          </Button>

                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div className='d-flex flex-row flex-wrap' style={{ marginTop: '10px' }}>
                            {this.state.academicSubjectLessonResources.map((vvv, iii) => {
                              return (vvv.aslr_type == vv.enum && vvv.aslr_id != vv.uid && !vvv.editing)
                                ? (<div key={iii} className="d-flex flex-column listgroup">
                                  <div className="d-flex flex-row justify-content-between mb-1">
                                    <span className='title'>{vvv.aslr_title}</span>
                                    <div className='d-flex flex-row'>
                                      <FontAwesomeIcon
                                        icon={faEdit}
                                        color={brand_colors[this.props.defaultTheme.theme_id].color16}
                                        style={{
                                          fontSize: 16, cursor: 'pointer', marginLeft: '10px', marginRight: '10px'
                                        }}
                                        onClick={() => {
                                          let academicSubjectLessonResources = this.state.academicSubjectLessonResources
                                          academicSubjectLessonResources = academicSubjectLessonResources.map((vvvv, iiii) => {
                                            return (iii == iiii) ? { ...vvvv, editing: true } : { ...vvvv }
                                          })
                                          this.setState({ academicSubjectLessonResources: academicSubjectLessonResources })
                                        }}
                                      />
                                      <FontAwesomeIcon
                                        icon={faTimesCircle}
                                        color={brand_colors[this.props.defaultTheme.theme_id].color11}
                                        style={{
                                          fontSize: 16, cursor: 'pointer'
                                        }}
                                        onClick={() => this.setState({ deleteModal: { ...this.state.deleteModal, show: true, id: vvv.aslr_id, action: () => this.deleteAcademicSubjectLessonResources() } })}
                                      />
                                    </div>
                                  </div>
                                  <div className='d-flex flex-row flex-wrap'>
                                    {vvv.aslr_tags.map((vvvv) => {
                                      return <div className='badge badge-light-info me-1 d-flex flex-row mb-1'>{vvvv}</div>
                                    })}
                                  </div>
                                </div>) : null
                            })}
                          </div>
                        </Col>
                      </Row>
                    </>
                  }

                  {/* 6.1 Reflections */}
                  {(['6.1'].includes(vv.uid)) &&
                    <>
                      <Row>
                        <Col md={12}>
                          {(vv.desc.length > 0) && <Alert variant='secondary' className='mb-1'>
                            {vv.desc.map((vvv) => {
                              return <div>{vvv}</div>
                            })}
                          </Alert>}
                        </Col>
                        <Col md={8}>
                          <Form.Group className='mb-1'>
                            <Form.Label>
                              <img src={imgDescription} />
                              {t('Title')}
                            </Form.Label>
                            <Form.Control type='text' value={(thisData) ? thisData.aslr_title : ''} onChange={(event) =>
                              this.setStateAcademicSubjectLessonResources(
                                'aslr_title',
                                event.target.value,
                                vv.enum,
                                (thisData) ? thisData.aslr_id : vv.uid
                              )} />
                          </Form.Group>
                          <Form.Group className='mb-1'>
                            <Form.Label>
                              <img src={imgDescription} />
                              {t('Hyperlink')}
                            </Form.Label>
                            <Form.Control type='text' value={(thisData) ? thisData.aslr_url : ''} onChange={(event) =>
                              this.setStateAcademicSubjectLessonResources(
                                'aslr_url',
                                event.target.value,
                                vv.enum,
                                (thisData) ? thisData.aslr_id : vv.uid
                              )} />
                          </Form.Group>
                          <Form.Group className='mb-1'>
                            <Form.Label>
                              <img src={imgDescription} />
                              {t('Description')}
                            </Form.Label>
                            <Form.Control as='textarea' rows={3} value={(thisData) ? thisData.aslr_desc : ''} onChange={(event) =>
                              this.setStateAcademicSubjectLessonResources(
                                'aslr_desc',
                                event.target.value,
                                vv.enum,
                                (thisData) ? thisData.aslr_id : vv.uid
                              )}
                            />
                          </Form.Group>
                        </Col>
                        <Col md={4} className='pt-3 d-flex flex-column justify-content-between'>
                          <div style={{ flexDirection: 'column' }} className='pic-prev d-flex justify-content-center mb-1' >
                            <div style={{ height: 175, padding: 10, overflow: 'hidden', borderStyle: 'dashed', borderColor: this.state.dragAreaColor.aslr_attachment, borderWidth: 2, borderRadius: 20, position: 'relative' }} className='d-flex justify-content-center align-items-center' onDragEnter={(e) => this.handleDragFileArea(e, 'aslr_attachment')} onDragLeave={(e) => this.handleDragFileArea(e, 'aslr_attachment')} onDragOver={(e) => this.handleDragFileArea(e, 'aslr_attachment')} onDrop={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              if (e.dataTransfer.files && e.dataTransfer.files[0]) {
                                this.addAttachment({ target: { files: e.dataTransfer.files, multiple: false, name: 'aslr_attachment' } })
                              }
                            }}>
                              {aslr_attachment && aslr_attachment != 'deleted' && (!aslr_attachment.data.type.includes('pdf')) && (
                                <img src={aslr_attachment.url} alt={t('Preview')} style={{ height: '100%' }} />
                              )}
                              {aslr_attachment && aslr_attachment != 'deleted' && (aslr_attachment.data.type.includes('pdf')) && (
                                <img src={require('../../assets/images/pdf.png')} alt={t('PDF')} style={{ height: '100%' }} />
                              )}
                              {(aslr_attachment == null || aslr_attachment == 'deleted') &&
                                (
                                  <button onClick={() => this.aslr_attachment_ref.current.click()} style={{ background: 'none', border: 0, flex: 1 }}>
                                    {/* <FontAwesomeIcon icon={faPlus} style={{ fontSize: 30, color: color18 }} /> */}
                                    <span style={{ fontSize: 20, fontWeight: 'bold', color: brand_colors[this.props.defaultTheme.theme_id].color18 }}>{t('Attachment')}</span>
                                  </button>
                                )
                              }
                              {aslr_attachment && aslr_attachment != 'deleted' && (
                                <FontAwesomeIcon
                                  icon={faTrashCan}
                                  style={{ fontSize: 14, color: brand_colors[this.props.defaultTheme.theme_id].color11, position: 'absolute', bottom: 6, right: 6, cursor: 'pointer', backgroundColor: brand_colors[this.props.defaultTheme.theme_id].color8, borderRadius: 100, padding: 3 }}
                                  onClick={() => this.setState({ aslr_attachment: 'deleted' })}
                                />
                              )}
                            </div>
                            <Form.Control className='d-none' ref={this.aslr_attachment_ref} type='file' onChange={this.addAttachment} name='aslr_attachment' />
                          </div>
                          <Button className='btn btn-primary btn-icon' disabled={this.state.uploadingResource} onClick={() => vv.saveAction()}>
                            <FontAwesomeIcon icon={faPlus} color={brand_colors[this.props.defaultTheme.theme_id].color8} style={{ fontSize: 16 }} />
                          </Button>
                        </Col>
                      </Row>
                      <hr />
                      <div className='d-flex flex-row flex-wrap' style={{ marginTop: '10px' }}>
                        {this.state.academicSubjectLessonResources.map((vvv, iii) => {
                          return (vvv.aslr_type == vv.enum && vvv.aslr_id != vv.uid && !vvv.editing)
                            ? (<div key={iii} className="d-flex flex-column listgroup">
                              {(vvv.created_by == this.props.authData.loginData.user_id) &&
                                <div className='d-flex flex-row justify-content-end mb-1'>
                                  <FontAwesomeIcon icon={faEdit} color={brand_colors[this.props.defaultTheme.theme_id].color16} style={{ fontSize: 16, cursor: 'pointer', marginLeft: '10px', marginRight: '10px' }} onClick={() => {
                                    let academicSubjectLessonResources = this.state.academicSubjectLessonResources
                                    academicSubjectLessonResources = academicSubjectLessonResources.map((vvvv, iiii) => {
                                      return (iii == iiii) ? { ...vvvv, editing: true } : { ...vvvv }
                                    })
                                    this.setState({ academicSubjectLessonResources: academicSubjectLessonResources, aslr_attachment: (vvv.aslr_attachment) ? { url: portalURL + vvv.center_id + '/academic_subject_lesson_resources/' + vvv.aslr_attachment, data: { type: getExtension(vvv.aslr_attachment) } } : null })
                                  }}
                                  />
                                  <FontAwesomeIcon icon={faTimesCircle} color={brand_colors[this.props.defaultTheme.theme_id].color11} style={{ fontSize: 16, cursor: 'pointer' }} onClick={() => this.setState({ deleteModal: { ...this.state.deleteModal, show: true, id: vvv.aslr_id, action: () => this.deleteAcademicSubjectLessonResources() } })}
                                  />
                                </div>
                              }
                              <div>
                                {vvv.aslr_attachment && (!getExtension(vvv.aslr_attachment).includes('pdf')) && (
                                  <div className='mb-1'>
                                    <a target='_blank' href={portalURL + vvv.center_id + '/academic_subject_lesson_resources/' + vvv.aslr_attachment} >
                                      <img src={portalURL + vvv.center_id + '/academic_subject_lesson_resources/' + vvv.aslr_attachment} style={{ objectFit: 'cover', width: '100%', height: '6rem', backgroundColor: brand_colors[this.props.defaultTheme.theme_id].color8, borderRadius: '1rem' }} />
                                    </a>
                                  </div>
                                )}
                                {vvv.aslr_attachment && (getExtension(vvv.aslr_attachment).includes('pdf')) && (
                                  <div className='mb-1'>
                                    <a target='_blank' href={portalURL + vvv.center_id + '/academic_subject_lesson_resources/' + vvv.aslr_attachment} >
                                      <img src={require('../../assets/images/pdf.png')} style={{ objectFit: 'cover', width: '100%', height: '6rem', backgroundColor: brand_colors[this.props.defaultTheme.theme_id].color8, borderRadius: '1rem' }} />
                                    </a>
                                  </div>
                                )}
                              </div>
                              <div className="d-flex flex-column justify-content-between mb-1" style={{ fontSize: 14 }}>
                                <div style={{ fontWeight: '600' }}>{vvv.aslr_title}</div>
                                <div>{vvv.aslr_desc}</div>
                                {vvv.aslr_url && (
                                  <p className='mb-1'>
                                    <FontAwesomeIcon icon={faArrowUpRightFromSquare} className='me-1' color={brand_colors[this.props.defaultTheme.theme_id].color4} />
                                    <a rel='noreferrer' href={vvv.aslr_url} target='_blank'>{vvv.aslr_url}</a>
                                  </p>
                                )}
                              </div>
                            </div>) : null
                        })}
                      </div>
                    </>
                  }

                  {/* 7.1 Comments */}
                  {(['7.1'].includes(vv.uid)) &&
                    <>
                      <Row>
                        <Col md={12}>
                          {(vv.desc.length > 0) && <Alert variant='secondary' className='mb-1'>
                            {vv.desc.map((vvv) => {
                              return <div>{vvv}</div>
                            })}
                          </Alert>}
                        </Col>
                        <Col md={7}>
                          <Form.Group className='mb-1'>
                            <Form.Label>
                              <img src={imgDescription} />
                              {t('Comment')}
                            </Form.Label>
                            <Form.Control as='textarea' rows={3} value={(thisData) ? thisData.aslr_title : ''} onChange={(event) =>
                              this.setStateAcademicSubjectLessonResources(
                                'aslr_title',
                                event.target.value,
                                vv.enum,
                                (thisData) ? thisData.aslr_id : vv.uid
                              )} />
                          </Form.Group>
                        </Col>
                        <Col md={5} className='pt-2 d-flex flex-column justify-content-between'>
                          <div className='d-flex flex-row flex-wrap'>

                            <div className='badge badge-light-warning me-1 d-flex flex-row cursor-pointer mb-1' onClick={() => {
                              let newThisData = []
                              if (!thisData || (thisData && thisData.aslr_tags.length != this.state.getSubjectAssignedEmployeesData.length)) {
                                newThisData = this.state.getSubjectAssignedEmployeesData.map((qtv) => qtv.user_id)
                              }
                              this.setStateAcademicSubjectLessonResources(
                                'aslr_tags',
                                newThisData,
                                vv.enum,
                                (thisData) ? thisData.aslr_id : vv.uid
                              )
                            }}>
                              <FontAwesomeIcon icon={(thisData && thisData.aslr_tags.length == this.state.getSubjectAssignedEmployeesData.length) ? faCheckCircle : faTimesCircle} className='me-1' />
                              {(thisData && thisData.aslr_tags.length == this.state.getSubjectAssignedEmployeesData.length) ? t('Un-Select All') : t('Select All')}
                            </div>

                            {this.state.getSubjectAssignedEmployeesData.map((qtv) => {
                              let classForThis = 'badge badge-light-light me-1 d-flex flex-row cursor-pointer mb-1'
                              let selected = false
                              if (thisData && thisData.aslr_tags.includes(qtv.user_id)) {
                                selected = true
                                classForThis = 'badge badge-light-info me-1 d-flex flex-row cursor-pointer mb-1'
                              }
                              return <div className={classForThis} onClick={(event) => {
                                let newThisData = []
                                if (selected) {
                                  newThisData = thisData.aslr_tags.filter((qtvf) => qtvf != qtv.user_id)
                                } else {
                                  if (thisData) {
                                    thisData.aslr_tags.push(qtv.user_id)
                                    newThisData = thisData.aslr_tags
                                  } else {
                                    newThisData = [qtv.user_id]
                                  }
                                }
                                this.setStateAcademicSubjectLessonResources(
                                  'aslr_tags',
                                  newThisData,
                                  vv.enum,
                                  (thisData) ? thisData.aslr_id : vv.uid
                                )
                              }}>
                                <FontAwesomeIcon icon={(selected) ? faCheckCircle : faTimesCircle} className='me-1' />
                                {qtv.full_name + ' (' + qtv.role_name + ')'}
                              </div>
                            })}
                          </div>
                          <Button className='btn btn-primary btn-icon' onClick={() => vv.saveAction()}>
                            <FontAwesomeIcon icon={faPlus} color={brand_colors[this.props.defaultTheme.theme_id].color8} style={{ fontSize: 16 }} />
                          </Button>
                        </Col>
                      </Row>
                      <hr />
                      <div className='d-flex flex-row flex-wrap' style={{ marginTop: '10px' }}>
                        {this.state.academicSubjectLessonResources.map((vvv, iii) => {
                          return (vvv.aslr_type == vv.enum && vvv.aslr_id != vv.uid && !vvv.editing)
                            ? (<div key={iii} className="d-flex flex-column listgroup">
                              {(vvv.created_by == this.props.authData.loginData.user_id) &&
                                <div className='d-flex flex-row justify-content-end mb-1'>
                                  <FontAwesomeIcon icon={faEdit} color={brand_colors[this.props.defaultTheme.theme_id].color16} style={{ fontSize: 16, cursor: 'pointer', marginLeft: '10px', marginRight: '10px' }} onClick={() => {
                                    let academicSubjectLessonResources = this.state.academicSubjectLessonResources
                                    academicSubjectLessonResources = academicSubjectLessonResources.map((vvvv, iiii) => {
                                      return (iii == iiii) ? { ...vvvv, editing: true } : { ...vvvv }
                                    })
                                    this.setState({ academicSubjectLessonResources: academicSubjectLessonResources, aslr_attachment: (vvv.aslr_attachment) ? { url: portalURL + vvv.center_id + '/academic_subject_lesson_resources/' + vvv.aslr_attachment, data: { type: getExtension(vvv.aslr_attachment) } } : null })
                                  }}
                                  />
                                  <FontAwesomeIcon icon={faTimesCircle} color={brand_colors[this.props.defaultTheme.theme_id].color11} style={{ fontSize: 16, cursor: 'pointer' }} onClick={() => this.setState({ deleteModal: { ...this.state.deleteModal, show: true, id: vvv.aslr_id, action: () => this.deleteAcademicSubjectLessonResources() } })}
                                  />
                                </div>
                              }
                              <div className="d-flex flex-column justify-content-between mb-1" style={{ fontSize: 14 }}>
                                <div>{vvv.aslr_title}</div>
                              </div>
                              <div className='d-flex flex-row flex-wrap'>
                                {vvv.aslr_tags.map((vvvv) => {
                                  let taggedUser = this.state.getSubjectAssignedEmployeesData.find((ae) => ae.user_id == vvvv)
                                  if (taggedUser) {
                                    return <div className='badge badge-light-info me-1 d-flex flex-row mb-1'>
                                      <FontAwesomeIcon icon={faInfoCircle} className='me-1' />
                                      {taggedUser.full_name + ' (' + taggedUser.role_name + ')'}
                                    </div>
                                  }
                                })}
                              </div>
                            </div>) : null
                        })}
                      </div>
                    </>
                  }




                  {/* 8.1 Activity */}
                  {(['8.1'].includes(vv.uid)) &&
                    <>
                      <Row>
                        <Col md={12}>
                          {(vv.desc.length > 0) && <Alert variant='secondary' className='mb-1'>
                            {vv.desc.map((vvv) => {
                              return <div>{vvv}</div>
                            })}
                          </Alert>}
                        </Col>
                      </Row>
                      <div className='d-flex flex-row align-items-center'>
                        <Col md={9}>
                          <div>
                            <Form.Group>
                              <Select isMulti isClearable closeMenuOnSelect={false} className='mt-1' value={asl_aa_ids} options={this.state.allAcademicActivities} onChange={(aa_ids) => {
                                this.setStateAcademicSubjectLessons('asl_aa_ids', aa_ids)
                              }}
                              />
                            </Form.Group>
                          </div>
                        </Col>
                        <Col className='ps-2'>
                          <ButtonGroup size="sm">
                            <Button variant="warning" onClick={() => this.getAcademicActivities()} title={t('Refresh after adding new Activity')}>
                              <FontAwesomeIcon icon={faRefresh} />
                            </Button>
                            <Button variant={'primary'} size='sm' onClick={() => window.open(window.location.origin + "/activities")}>
                              <span style={{ marginRight: 10 }}><FontAwesomeIcon icon={faPlus} /></span>
                              <span>{t('New Activity')}</span>
                            </Button>
                          </ButtonGroup>
                        </Col>
                      </div>
                      <div className='d-flex flex-row flex-wrap'>
                        {asl_aa_ids.map((vvv, iii) => {
                          console.log('Activity', vvv);
                          let data = {
                            allData: vvv,
                            name: vvv.aa_name,
                            description: vvv.aa_desc_cidea,
                            cardImage: (vvv.aa_image) ? portalURL + vvv.center_id + '/academic_activities/' + vvv.aa_image : require('../../assets/images/noimage.png'),
                          }
                          return <GeneralCard data={data} key={iii} />
                        })}
                      </div>
                    </>}

                  {/* 8.2 Exams */}
                  {(['8.2'].includes(vv.uid)) &&
                    <>
                      <Row>
                        <Col md={12}>
                          {(vv.desc.length > 0) && <Alert variant='secondary' className='mb-1'>
                            {vv.desc.map((vvv) => {
                              return <div>{vvv}</div>
                            })}
                          </Alert>}
                        </Col>
                      </Row>
                      <div className='d-flex flex-row flex-wrap' style={{ marginTop: '10px' }}>
                        {this.state.academicSubjectLessonExams.map((vvv, iii) => {
                          return (vvv.asle_id != vv.uid && !vvv.editing)
                            ? (<div key={iii} className="d-flex flex-column listgroup">
                              <div className='d-flex flex-row justify-content-end mb-1'>
                                <FontAwesomeIcon icon={faEdit} color={brand_colors[this.props.defaultTheme.theme_id].color16} style={{ fontSize: 16, cursor: 'pointer', marginLeft: '10px', marginRight: '10px' }} onClick={() => {
                                  let academicSubjectLessonExams = this.state.academicSubjectLessonExams
                                  academicSubjectLessonExams = academicSubjectLessonExams.map((vvvv, iiii) => {
                                    return (iii == iiii) ? { ...vvvv, editing: true } : { ...vvvv }
                                  })
                                  this.setState({ academicSubjectLessonExams: academicSubjectLessonExams }, () => vv.addAction())
                                }}
                                />
                                <FontAwesomeIcon icon={faTimesCircle} color={brand_colors[this.props.defaultTheme.theme_id].color11} style={{ fontSize: 16, cursor: 'pointer' }} onClick={() => this.setState({ deleteModal: { ...this.state.deleteModal, show: true, id: vvv.asle_id, action: () => this.deleteAcademicSubjectLessonExams() } })}
                                />
                              </div>
                              <div className="d-flex flex-column justify-content-between" style={{ fontSize: 14 }}>
                                <div className='d-flex flex-row mb-1'>
                                  <div className='mx-1'>
                                    <div className='badge badge-light-info'>{(parseInt(vvv.asle_is_exampaper)) ? 'Online' : 'Recitation'}</div>
                                  </div>
                                  <div className='mx-1'>
                                    <div className='badge badge-light-primary'>{vvv.examstype_id.label}</div>
                                  </div>
                                  <div className='mx-1'>
                                    <div className='badge badge-light-primary'>{vvv.term_d_id.label}</div>
                                  </div>
                                </div>
                                <div style={{ fontWeight: '600' }}>{vvv.asle_name}</div>
                                <div>{vvv.asle_description}</div>
                              </div>
                            </div>) : null
                        })}
                      </div>
                    </>
                  }

                  {/* 10.1 Lesson Flow */}
                  {(['10.1'].includes(vv.uid)) &&
                    <>
                      <Row>
                        <Col md={12}>
                          {(vv.desc.length > 0) && <Alert variant='secondary' className='mb-1'>
                            {vv.desc.map((vvv) => {
                              return <div>{vvv}</div>
                            })}
                          </Alert>}
                        </Col>
                        <Col md={12}>
                          <div>
                            <Button variant='warning' onClick={() => {
                              let folders = getAcademicSubjectLessonFlow.folders
                              folders.push({
                                asl_flow_id: chance.unique(chance.integer, 1, { min: 99999999999, max: 999999999999 })[0],
                                asl_flow_name: 'New Folder',
                                asl_flow_link_type: 'folder',
                                asl_flow_link_id: null,
                                asl_id: this.props.location.state.lessonData.asl_id,
                                asl_flow_parent_id: (getAcademicSubjectLessonFlow.activeFolder) ? getAcademicSubjectLessonFlow.activeFolder.asl_flow_id : null,
                                asl_flow_order_no: 0,
                              })
                              this.setState({
                                getAcademicSubjectLessonFlow: { ...getAcademicSubjectLessonFlow, folders }
                              })
                            }}>
                              <FontAwesomeIcon icon={faFolder} style={{}} className='me-1' />
                              {t('New Folder')}
                            </Button>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={8}>
                          <div className='mt-2 border-top pt-2'>
                            {(getAcademicSubjectLessonFlow.activeFolder) &&
                              <div className='d-flex flex-row align-items-center m-2 cursor-pointer' style={{ fontSize: 16, fontWeight: '600' }} onClick={() => {
                                let asl_flow_parent = getAcademicSubjectLessonFlow.activeFolder.asl_flow_parent_id
                                if (asl_flow_parent) {
                                  asl_flow_parent = getAcademicSubjectLessonFlow.folders.find((vvv, iii) => vvv.asl_flow_id === asl_flow_parent)
                                }
                                this.setState({ getAcademicSubjectLessonFlow: { ...getAcademicSubjectLessonFlow, activeFolder: asl_flow_parent } })
                              }}>
                                <FontAwesomeIcon icon={faAngleLeft} className='me-2' />
                                {getAcademicSubjectLessonFlow.activeFolder.asl_flow_name}
                              </div>
                            }

                            {/* FOLDERS */}
                            {(getAcademicSubjectLessonFlow.folders.length <= 0)
                              ? <Alert variant='light' className='p-1 m-2 text-center'>{t('No folder found')}</Alert>
                              : <div className='d-flex flex-row flex-wrap'>
                                {getAcademicSubjectLessonFlow.folders.map((vvv, iii) => {
                                  if ((getAcademicSubjectLessonFlow.activeFolder && getAcademicSubjectLessonFlow.activeFolder.asl_flow_id === vvv.asl_flow_parent_id) || (!getAcademicSubjectLessonFlow.activeFolder && vvv.asl_flow_parent_id == null)) {
                                    return <div className={`m-2 d-flex flex-column align-items-center justify-content-center py-1 px-2 ${vvv === this.state.draggingItem ? 'dragging' : ''}`} style={{ borderColor: changeColorOpacity(brand_colors[this.props.defaultTheme.theme_id].color16, 0.4), borderWidth: 1, borderRadius: 4, borderStyle: 'solid', fontSize: 14, fontWeight: '600', color: brand_colors[this.props.defaultTheme.theme_id].color9, opacity: 0.8, position: 'relative' }}
                                      draggable="true"
                                      onDragStart={(e) => this.handleDragStartLF(e, vvv)}
                                      onDragEnd={(e) => this.handleDragEndLF(e)}
                                      onDragOver={(e) => e.preventDefault()}
                                      onDrop={(e) => this.handleDropLF(e, vvv)}
                                    >
                                      <FontAwesomeIcon icon={faUpDownLeftRight} style={{ color: brand_colors[this.props.defaultTheme.theme_id].color1, fontSize: 14, position: 'absolute', top: 5, left: 5 }} className='mb-1 cursor-move' onClick={() => this.deleteAcademicSubjectLessonFlow(vvv)} />
                                      <FontAwesomeIcon icon={faTrashCan} style={{ color: brand_colors[this.props.defaultTheme.theme_id].color11, fontSize: 14, position: 'absolute', top: 5, right: 5 }} className='mb-1 cursor-pointer' onClick={() => this.deleteAcademicSubjectLessonFlow(vvv)} />
                                      <FontAwesomeIcon icon={faFolder} style={{ color: brand_colors[this.props.defaultTheme.theme_id].color16, fontSize: 30 }} className='mb-1 cursor-pointer' onClick={() => this.setState({ getAcademicSubjectLessonFlow: { ...getAcademicSubjectLessonFlow, activeFolder: vvv } })} />
                                      <Form.Control className='no-style text-center' size={'sm'} style={{ width: (vvv.asl_flow_name.length + 6) + 'ch' }} type='text' value={vvv.asl_flow_name} onChange={(e) => {
                                        let folders = getAcademicSubjectLessonFlow.folders
                                        folders[iii]['asl_flow_name'] = e.target.value
                                        this.setState({ getAcademicSubjectLessonFlow: { ...getAcademicSubjectLessonFlow, folders } })
                                      }} />
                                    </div>
                                  }
                                })}
                              </div>
                            }

                            {/* SELECTED RESOURCES */}
                            <div style={{ backgroundColor: changeColorOpacity(brand_colors[this.props.defaultTheme.theme_id].color1, (this.state.draggingItem && this.state.draggingItem.asl_flow_link_type == 'copy') ? 0.2 : 0), minHeight: '60vh', borderRadius: 10 }}
                              draggable="true"
                              onDragOver={(e) => e.preventDefault()}
                              onDrop={(e) => this.handleDropLF(e, null)}
                            >
                              <div className='d-flex flex-row flex-wrap'>
                                {getAcademicSubjectLessonFlow.data.map((vvv, iii) => {
                                  if ((getAcademicSubjectLessonFlow.activeFolder && getAcademicSubjectLessonFlow.activeFolder.asl_flow_id === vvv.asl_flow_parent_id) || (!getAcademicSubjectLessonFlow.activeFolder && vvv.asl_flow_parent_id == null)) {
                                    return <div className={`m-2 d-flex flex-column align-items-center justify-content-center py-1 px-2 ${vvv === this.state.draggingItem ? 'dragging' : ''}`} style={{ borderColor: changeColorOpacity(brand_colors[this.props.defaultTheme.theme_id].color16, 0.4), borderWidth: 1, borderRadius: 4, borderStyle: 'solid', fontSize: 14, fontWeight: '600', color: brand_colors[this.props.defaultTheme.theme_id].color9, opacity: 0.8, position: 'relative', minWidth: 'calc(33.43% - 1rem)', width: 'calc(33.43% - 1rem)' }}
                                      draggable="true"
                                      onDragStart={(e) => this.handleDragStartLF(e, vvv)}
                                      onDragEnd={(e) => this.handleDragEndLF(e)}
                                      onDragOver={(e) => e.preventDefault()}
                                      onDrop={(e) => this.handleDropLF(e, vvv)}
                                    >
                                      <FontAwesomeIcon icon={faUpDownLeftRight} style={{ color: brand_colors[this.props.defaultTheme.theme_id].color1, fontSize: 14, position: 'absolute', top: 5, left: 5 }} className='mb-1 cursor-move' onClick={() => this.deleteAcademicSubjectLessonFlow(vvv)} />
                                      <FontAwesomeIcon icon={faTrashCan} style={{ color: brand_colors[this.props.defaultTheme.theme_id].color11, fontSize: 14, position: 'absolute', top: 5, right: 5 }} className='mb-1 cursor-pointer' onClick={() => this.deleteAcademicSubjectLessonFlow(vvv)} />
                                      <FontAwesomeIcon icon={faFile} style={{ color: changeColorOpacity(brand_colors[this.props.defaultTheme.theme_id].color4, 0.5), fontSize: 30 }} className='mb-1' />
                                      {(vvv.asl_flow_link_type == 'external_resource') && <div className='badge badge-light-primary'>{t('Resource')}</div>}
                                      {(vvv.asl_flow_link_type == 'academic_activities') && <div className='badge badge-light-primary'>{t('Activity')}</div>}
                                      {(vvv.asl_flow_link_type == 'academic_subject_lesson_exams') && <div className='badge badge-light-primary'>{t('Exam')}</div>}

                                      {vvv.asl_flow_name}
                                    </div>
                                  }
                                })}
                              </div>
                            </div>

                          </div>
                        </Col>
                        {/* ALL RESOURCES IN Lesson Flow */}
                        <Col md={4}>
                          <div style={{ height: '80vh', overflowY: 'auto', borderStyle: 'solid', borderWidth: 1, borderColor: brand_colors[this.props.defaultTheme.theme_id].color10, borderRadius: 10, position: 'sticky', top: '5px' }}>
                            {/* Resources Lesson Flow */}
                            <div>
                              <div className='text-center py-2' style={{ fontWeight: '600', fontSize: 16 }}>{t('Resources')}</div>
                              <div className='p-1'>
                                {this.state.academicSubjectLessonResources.map((vvv, iii) => {
                                  return (vvv.aslr_type == 'external_resource') &&
                                    (<div key={iii} className="d-flex flex-column listgroup cursor-move" style={{ width: '100%', backgroundColor: changeColorOpacity(brand_colors[this.props.defaultTheme.theme_id].color1, 0.2), }}
                                      draggable="true"
                                      onDragStart={(e) => this.handleDragStartLF(e, { ...vvv, asl_flow_link_id: vvv.aslr_id, asl_flow_name: vvv.aslr_title, asl_flow_link_type: 'copy', asl_flow_link_type2: 'external_resource' })}
                                      onDragEnd={(e) => this.handleDragEndLF(e)}
                                      onDragOver={(e) => e.preventDefault()}
                                      onDrop={(e) => this.handleDropLF(e, vvv)}
                                    >
                                      <div className="d-flex align-items-center justify-content-center pb-1" style={{}}>
                                        <FontAwesomeIcon icon={faUpDownLeftRight} />
                                      </div>
                                      <div className="d-flex flex-row align-items-center justify-content-between p-1" style={{ backgroundColor: '#fff', borderRadius: 5 }}>
                                        <div className="d-flex flex-row justify-content-center align-items-center">
                                          {(vvv.aslr_url_type && !vvv.aslr_attachment) && <img src={require('../../assets/images/' + vvv.aslr_url_type + '.png')} className='image' style={{ objectFit: 'cover', width: '3rem', height: '3rem', backgroundColor: brand_colors[this.props.defaultTheme.theme_id].color8, borderRadius: '1rem' }} />}
                                          {vvv.aslr_attachment && (!getExtension(vvv.aslr_attachment).includes('pdf')) && (
                                            <div className='mb-1'>
                                              <img src={portalURL + vvv.center_id + '/academic_subject_lesson_resources/' + vvv.aslr_attachment} style={{ objectFit: 'cover', width: '3rem', height: '3rem', backgroundColor: brand_colors[this.props.defaultTheme.theme_id].color8, borderRadius: '1rem' }} />
                                            </div>
                                          )}
                                          {vvv.aslr_attachment && (getExtension(vvv.aslr_attachment).includes('pdf')) && (
                                            <div className='mb-1'>
                                              <img src={require('../../assets/images/pdf.png')} style={{ objectFit: 'cover', width: '3rem', height: '3rem', backgroundColor: brand_colors[this.props.defaultTheme.theme_id].color8, borderRadius: '1rem' }} />
                                            </div>
                                          )}
                                          <div className='title' title={vvv.aslr_url}>{vvv.aslr_title}</div>
                                        </div>
                                      </div>
                                    </div>)
                                })}

                              </div>
                            </div>
                            {/* Exams */}
                            <div>
                              <div className='text-center py-2' style={{ fontWeight: '600', fontSize: 16 }}>{t('Exams')}</div>
                              <div className='p-1'>
                                {this.state.academicSubjectLessonExams.map((vvv, iii) => {
                                  return <div key={iii} className="d-flex flex-column listgroup cursor-move" style={{ width: '100%', backgroundColor: changeColorOpacity(brand_colors[this.props.defaultTheme.theme_id].color1, 0.2), }}
                                    draggable="true"
                                    onDragStart={(e) => this.handleDragStartLF(e, { ...vvv, asl_flow_link_id: vvv.asle_id, asl_flow_name: vvv.asle_name, asl_flow_link_type: 'copy', asl_flow_link_type2: 'academic_subject_lesson_exams' })}
                                    onDragEnd={(e) => this.handleDragEndLF(e)}
                                    onDragOver={(e) => e.preventDefault()}
                                    onDrop={(e) => this.handleDropLF(e, vvv)}
                                  >
                                    <div className="d-flex align-items-center justify-content-center pb-1" style={{}}>
                                      <FontAwesomeIcon icon={faUpDownLeftRight} />
                                    </div>
                                    <div className="d-flex flex-row align-items-center p-1" style={{ backgroundColor: '#fff', borderRadius: 5 }}>
                                      <div className='title' style={{ flex: 1 }}>{vvv.asle_name}</div>
                                      <div className='d-flex flex-column'>
                                        <div className='badge badge-light-primary mb-1'>{vvv.examstype_id?.label}</div>
                                        <div className='badge badge-light-primary'>{vvv.term_d_id?.label}</div>
                                      </div>
                                    </div>
                                  </div>
                                })
                                }
                              </div>
                            </div>
                            {/* Activities */}
                            <div>
                              <div className='text-center py-2' style={{ fontWeight: '600', fontSize: 16 }}>{t('Activities')}</div>
                              <div className='p-1'>
                                {
                                  academicSubjectLessons.asl_aa_ids.map((vvv, iii) => {
                                    return <div key={iii} className="d-flex flex-column listgroup cursor-move" style={{ width: '100%', backgroundColor: changeColorOpacity(brand_colors[this.props.defaultTheme.theme_id].color1, 0.2), }}
                                      draggable="true"
                                      onDragStart={(e) => this.handleDragStartLF(e, { ...vvv, asl_flow_link_id: vvv.aa_id, asl_flow_name: vvv.aa_name, asl_flow_link_type: 'copy', asl_flow_link_type2: 'academic_activities' })}
                                      onDragEnd={(e) => this.handleDragEndLF(e)}
                                      onDragOver={(e) => e.preventDefault()}
                                      onDrop={(e) => this.handleDropLF(e, vvv)}
                                    >
                                      <div className="d-flex align-items-center justify-content-center pb-1" style={{}}>
                                        <FontAwesomeIcon icon={faUpDownLeftRight} />
                                      </div>
                                      <div className="d-flex flex-row align-items-center p-1" style={{ backgroundColor: '#fff', borderRadius: 5 }}>
                                        {vvv.aa_image && (!getExtension(vvv.aa_image).includes('pdf')) && (
                                          <div className='mb-1'>
                                            <img src={portalURL + vvv.center_id + '/academic_activities/' + vvv.aa_image} style={{ objectFit: 'cover', width: '3rem', height: '3rem', backgroundColor: brand_colors[this.props.defaultTheme.theme_id].color8, borderRadius: '1rem' }} />
                                          </div>
                                        )}
                                        <div className='title' style={{ flex: 1 }}>{vvv.aa_name}</div>
                                      </div>
                                    </div>
                                  })
                                }
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </>}

                </>
              )
            })
            }
          </div>
        )

      }
    })

  }

  // https://www.geeksforgeeks.org/drag-and-drop-sortable-list-using-reactjs/
  handleDragStartLF = (e, item) => {
    console.log('onDragStart', e, item)
    this.setState({ draggingItem: item })
    // e.dataTransfer.setData('text/plain', '')
  }

  handleDragEndLF = (e) => {
    console.log('onDragEnd', e)
    this.setState({ draggingItem: null })
  }

  handleDropLF = (e, targetItem) => {
    console.log('onDrop', e, targetItem)
    let { draggingItem, getAcademicSubjectLessonFlow } = this.state
    if (!draggingItem) return

    let dragType = draggingItem.asl_flow_link_type

    let items = []
    if (dragType == 'folder') {
      items = getAcademicSubjectLessonFlow.folders
    } else if (dragType == 'copy') {
      items = getAcademicSubjectLessonFlow.data
      draggingItem = {
        asl_flow_id: chance.unique(chance.integer, 1, { min: 99999999999, max: 999999999999 })[0],
        asl_flow_name: draggingItem.asl_flow_name,
        asl_flow_link_type: draggingItem.asl_flow_link_type2,//'external_resource','academic_activities','academic_subject_lesson_exams','folder'
        asl_flow_link_id: draggingItem.asl_flow_link_id,
        asl_id: this.props.location.state.lessonData.asl_id,
        asl_flow_parent_id: (getAcademicSubjectLessonFlow.activeFolder) ? getAcademicSubjectLessonFlow.activeFolder.asl_flow_id : null,
        asl_flow_order_no: 0,
      }
    } else {
      items = getAcademicSubjectLessonFlow.data
    }

    const currentIndex = items.indexOf(draggingItem)
    const targetIndex = items.indexOf(targetItem)

    if (currentIndex !== -1 && targetIndex !== -1) {
      items.splice(currentIndex, 1)
      items.splice(targetIndex, 0, draggingItem)

      if (draggingItem.asl_flow_link_type == 'folder') {
        this.setState({ getAcademicSubjectLessonFlow: { ...getAcademicSubjectLessonFlow, folders: items } })
      } else {
        this.setState({ getAcademicSubjectLessonFlow: { ...getAcademicSubjectLessonFlow, data: items } })
      }
    } else if (targetItem == null && dragType == 'copy') {
      items.push(draggingItem)
      this.setState({ getAcademicSubjectLessonFlow: { ...getAcademicSubjectLessonFlow, data: items } })
    }
  }

  renderThisFormSectionReadOnly = () => {
    let { formSectionActive, externalResource, type1Opts, type2Opts, academicSubjectLessons, image, image_prev, image_deleted } = this.state;
    let { asl_name, asl_coef, asl_code, center_id, asl_period, asl_attach, asl_link, asl_order, asl_sen_required, asl_ict_usage, asl_afl, asl_notes1, asl_notes2, sl_notes3, asl_type1, asl_type2, asl_theme, asl_chat_blog, } = academicSubjectLessons

    let transdisciplinary_theme = this.state.academicSubjectLessonResources.find((v, i) => v.aslr_type == 'transdisciplinary_theme')
    let transdisciplinary_theme_focus = this.state.academicSubjectLessonResources.filter((v, i) => v.aslr_type == 'transdisciplinary_theme_focus')


    let approaches_to_learning = []
    this.state.academicLessonAttributes.map((vvv, iii) => {
      if (vvv.ala_type == 'approaches_to_learning' && vvv.ala_parent_id == 0) {
        let allChildren = this.state.academicLessonAttributes.filter((vvvv, iiii) => vvvv.ala_parent_id == vvv.ala_id)
        let selectedChildren = []
        allChildren.map((vvvv, iiii) => {
          let find = this.state.academicSubjectLessonResources.find((vvvvv, iiiii) => ('approaches_to_learning' == vvvvv.aslr_type && vvvv.ala_id == vvvvv.aslr_type_id))
          if (find) {
            selectedChildren.push(find)
          }
        })
        if (selectedChildren.length > 0) {
          approaches_to_learning.push({
            title: vvv.ala_title,
            desc: vvv.ala_description_1,
            children: selectedChildren
          })
        }
      }
    })

    // competency
    let competency = []
    this.state.curriculum.map((vvv, iii) => {
      let allChildren = vvv.curriculum
      let selectedChildren = []
      allChildren.map((vvvv, iiii) => {
        let find = this.state.academicSubjectLessonResources.find((vvvvv, iiiii) => ('competency' == vvvvv.aslr_type && vvvv.curriculum_id == vvvvv.aslr_type_id))
        if (find) {
          selectedChildren.push(find)
        }
      })
      if (selectedChildren.length > 0) {
        competency.push({
          title: vvv.learnarea_desc || vvv.learnarea_code,
          children: selectedChildren
        })
      }
    })

    let related_concept_learn_area = []
    this.state.curriculum.map((vvv, iii) => {
      let findIndexSelectedData = this.state.academicSubjectLessonResources.findIndex((vvvv) => (vvvv.aslr_type_id == vvv.learnarea_id && vvvv.aslr_type == 'related_concept_learn_area'))
      let selectedChildren = []
      if (findIndexSelectedData !== -1) {
        selectedChildren = JSON.parse(this.state.academicSubjectLessonResources[findIndexSelectedData].aslr_desc)
      }
      if (selectedChildren.length > 0) {
        related_concept_learn_area.push({
          title: vvv.learnarea_desc,
          children: selectedChildren
        })
      }
    })

    let related_concept_key_concept = []
    this.state.academicLessonAttributes.map((vvv, iii) => {
      if (vvv.ala_type == 'lesson_key_concept' && vvv.ala_parent_id == 0) {

        let findIndexSelectedData = this.state.academicSubjectLessonResources.findIndex((vvvv) => (vvvv.aslr_type_id == vvv.ala_id && vvvv.aslr_type == 'related_concept_key_concept'))
        let selectedChildren = []
        if (findIndexSelectedData !== -1) {
          selectedChildren = JSON.parse(this.state.academicSubjectLessonResources[findIndexSelectedData].aslr_desc)
        }
        if (selectedChildren.length > 0) {
          related_concept_key_concept.push({
            title: vvv.ala_title,
            children: selectedChildren
          })
        }
      }
    })
    let action_prior_learning = []
    this.state.academicLessonAttributes.map((vvv, iii) => {
      let findIndexSelectedData = this.state.academicSubjectLessonResources.findIndex((vvvv) => (vvvv.aslr_type_id == vvv.ala_id && vvvv.aslr_type == 'action_prior_learning'))
      if (vvv.ala_type == 'action_prior_learning' && vvv.ala_parent_id == 0 && findIndexSelectedData !== -1) {
        action_prior_learning.push({
          title: vvv.ala_title,
          children: this.state.academicSubjectLessonResources[findIndexSelectedData].aslr_desc,
          desc: vvv.ala_description_1
        })
      }
    })

    return this.state.formSections.map((v, i) => {

      if (!v.sectionGroup.includes(this.state.sectionGroup)) {
        return null
      }

      return (
        <div className='lesson-view'>
          {v.data.map((vv, ii) => {

            if (!vv.sectionGroup.includes(this.state.sectionGroup)) {
              return null
            }

            let thisData
            if (vv.uid == '1.3' || vv.uid == '1.4' || vv.uid == '2.3' || vv.uid == '2.4' || vv.uid == '2.5' || vv.uid == '3.5' || vv.uid == '4.1' || vv.uid == '4.3' || vv.uid == '5.1' || vv.uid == '5.2') {
              thisData = this.state.academicSubjectLessonResources.find((v, i) => v.aslr_type == vv.enum && v.editing)
            }

            return (
              <>
                {/* <div className={'d-flex flex-row align-items-center justify-content-between section-heading'} id={'form_section_' + vv.uid}>
                                    <div>
                                        <img className='image' src={(vv.image) ? vv.image : null} />
                                        <span className='heading'>{vv.name}</span>
                                    </div>
                                    <div>
                                    </div>
                                </div> */}


                {/* LESSON FOCUS */}
                {(vv.uid == '1.1') &&
                  (<Row>
                    <Col md={3}>
                      <img src={image_prev} alt={t('Preview')} style={{ width: '100%' }} />
                    </Col>
                    <Col>
                      <div className={'d-flex flex-column align-items-center'}>
                        <div className={'d-flex flex-row align-items-center'}>
                          <span className='lesson-title-sub px-10'>{asl_code}</span>
                          <span className={'px-10 border-left lesson-title'}>{asl_name}</span>
                          <a className='border-left px-10' href={asl_link} target="_blank">
                            <FontAwesomeIcon icon={faGlobe} color={brand_colors[this.props.defaultTheme.theme_id].color4} style={{ fontSize: 20 }} />
                          </a>
                          <a className='border-left px-10' href={'to the blog page'} target="_blank">
                            <FontAwesomeIcon icon={faComments} color={brand_colors[this.props.defaultTheme.theme_id].color4} style={{ fontSize: 20 }} />
                          </a>
                        </div>
                        <div className='lesson-title-sub2'>{asl_type1} | {asl_type2}</div>
                        <div className='lesson-title-sub2'>Periods : {asl_period}</div>
                        <div className='lesson-title-sub2'>Theme : {asl_theme}</div>
                        <div className='lesson-title-sub2'>{asl_notes1}</div>
                      </div>
                    </Col>
                    {transdisciplinary_theme &&
                      <Col md={3}>
                        <div className='div-box-theme'>
                          {transdisciplinary_theme && this.state.academicLessonAttributes.map((vvv, iii) => {
                            return (vvv.ala_id == transdisciplinary_theme.aslr_type_id) &&
                              <div className='d-flex flex-row align-items-center'>
                                <div className='div_image'>
                                  <img src={portalURL + vvv.center_id + '/academic_lesson_attributes/' + vvv.ala_description_2} className="image" />
                                </div>
                                <div className='heading'>{vvv.ala_title}</div>
                              </div>
                          })}

                          <div className='d-flex flex-row flex-wrap' style={{ marginTop: '10px' }}>
                            {(transdisciplinary_theme) && this.state.academicLessonAttributes.map((vvv, iii) => {
                              let academicSubjectLessonResourcesFind = this.state.academicSubjectLessonResources.find((vvvv, iiii) => {
                                return (vvvv.aslr_type == 'transdisciplinary_theme_focus' && vvvv.aslr_type_id == vvv.ala_id)
                              })
                              return (vvv.ala_type == 'lesson_focus' && academicSubjectLessonResourcesFind)
                                ? (<div key={iii} className="d-flex flex-row justify-content-center">
                                  <FontAwesomeIcon icon={faCircle} color={brand_colors[this.props.defaultTheme.theme_id].color18} style={{ fontSize: 12, padding: '8px' }} />
                                  <span className='title'>{vvv.ala_title}</span>
                                </div>) : null
                            })}
                          </div>
                        </div>
                      </Col>
                    }
                  </Row>)
                }


                {/* Lesson Resources */}
                {(vv.uid == '1.3') &&
                  <>
                    <Row>
                      <div className={'d-flex flex-row align-items-center justify-content-between section-heading'} id={'form_section_' + vv.uid}>
                        <div>
                          <img className='image' src={(vv.image) ? vv.image : null} />
                          <span className='heading'>{vv.name}</span>
                        </div>
                        <div>
                        </div>
                      </div>
                      <Col>
                        <div className='d-flex flex-row flex-wrap justify-content-center' style={{ marginTop: '10px' }}>
                          {this.state.academicSubjectLessonResources.map((vvv, iii) => {
                            return (vvv.aslr_type == 'external_resource' && vvv.aslr_id != vv.uid) &&
                              (<div onClick={() => vvv.aslr_url_type == 'youtube' ? window.open(vvv.aslr_url, '', 'width=800,height=600') : window.open(vvv.aslr_url || portalURL + vvv.center_id + '/academic_subject_lesson_resources/' + vvv.aslr_attachment, '_blank')}
                                key={iii}
                                className='d-flex flex-column justify-content-center align-items-center box-shadow br-20 p-2 m-2'
                                style={{ cursor: 'pointer', width: '10%' }}>
                                <img src={require('../../assets/images/' + vvv.aslr_url_type + '.png')} style={{ width: '20%', }} />
                                <span className='lesson-title-sub text-center'>
                                  {vvv.aslr_title}
                                </span>
                              </div>)
                          })}
                        </div>
                      </Col>
                    </Row>
                  </>
                }


                {/* 1.4 Teaching Resources */}
                {/* 2.3 Learning Goals & Success Criteria */}
                {/* 2.4 Key Vocab */}
                {/* 2.5 Key Skills */}
                {/* 3.5 Lines of Inquiry */}
                {/* 4.1 Prior Learning */}
                {/* 4.3 Connections */}
                {/* 5.1 Teacher Questions */}
                {/* 5.2 Student Questions */}
                {(['1.4', '2.3', '2.4', '2.5', '3.5', '4.1', '4.3', '5.1', '5.2'].includes(vv.uid)) &&
                  <>
                    <Row>
                      <div className={'d-flex flex-row align-items-center justify-content-between section-heading'} id={'form_section_' + vv.uid}>
                        <div>
                          <img className='image' src={(vv.image) ? vv.image : null} />
                          <span className='heading'>{vv.name}</span>
                        </div>
                        <div>
                        </div>
                      </div>
                      <Col md={12}>
                        {(vv.desc.length > 0) && <Alert variant='secondary' className='mb-1'>
                          {vv.desc.map((vvv) => {
                            return <div>{vvv}</div>
                          })}
                        </Alert>}
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className='d-flex flex-row flex-wrap' style={{ marginTop: '10px' }}>
                          {this.state.academicSubjectLessonResources.map((vvv, iii) => {
                            return (vvv.aslr_type == vv.enum && vvv.aslr_id != vv.uid && !vvv.editing)
                              ? (<div key={iii} className="d-flex flex-row justify-content-center align-items-center listgroup">
                                <span className='title'>{vvv.aslr_title}</span>
                              </div>) : null
                          })}
                        </div>
                      </Col>
                    </Row>
                  </>
                }

                {/* Approaches to learning */}
                {(vv.uid == '2.1') && approaches_to_learning.length > 0 &&
                  <Row style={{ marginBottom: '20px' }}>
                    <div className={'d-flex flex-row align-items-center justify-content-between section-heading'} id={'form_section_' + vv.uid}>
                      <div>
                        <img className='image' src={(vv.image) ? vv.image : null} />
                        <span className='heading'>{vv.name}</span>
                      </div>
                      <div>
                      </div>
                    </div>
                    <Col md={12}>
                      <Alert variant='secondary' className='mb-1'>
                        {vv.desc}
                      </Alert>
                    </Col>
                    <Col md="12">
                      {
                        approaches_to_learning.map((vvv, iii) => {
                          return <div className='div-box-theme'>
                            <div className='d-flex flex-row' style={{ cursor: 'pointer' }}>
                              <div className='heading'>{vvv.title}</div>
                            </div>
                            <div style={{ fontSize: '13px', color: brand_colors[this.props.defaultTheme.theme_id].color9, opacity: '0.7' }}>{vvv.ala_description_1}</div>
                            {(vvv.children.length > 0)
                              && <div style={{ borderTopStyle: 'solid', borderTopWidth: 1, borderTopColor: brand_colors[this.props.defaultTheme.theme_id].color18, marginTop: '5px', paddingTop: '5px', width: '100%' }}>
                                {vvv.children.map((vvvv, iiii) => {
                                  return <div className='d-flex flex-row justify-content-between' style={{ color: brand_colors[this.props.defaultTheme.theme_id].color9, opacity: 0.7 }}>
                                    <div style={{ marginTop: '10px', fontSize: '14px', color: brand_colors[this.props.defaultTheme.theme_id].color9, opacity: 0.7 }}><span style={{ fontWeight: '600' }}>{vvvv.aslr_title}</span> : {vvvv.aslr_desc}</div>
                                  </div>
                                })}
                              </div>
                            }
                          </div>
                        })
                      }
                    </Col>
                  </Row>
                }

                {/* Scope & Sequences */}
                {(vv.uid == '2.2') && competency.length > 0 &&
                  <Row style={{ marginBottom: '20px' }}>
                    <div className={'d-flex flex-row align-items-center justify-content-between section-heading'} id={'form_section_' + vv.uid}>
                      <div>
                        <img className='image' src={(vv.image) ? vv.image : null} />
                        <span className='heading'>{vv.name}</span>
                      </div>
                      <div>
                      </div>
                    </div>
                    <Col md="12">
                      {competency.map((vvv, iii) => {
                        return <div className='div-box-theme'>
                          <div className='d-flex flex-row' style={{ cursor: 'pointer' }} >
                            <div className='heading'>{vvv.title}</div>
                          </div>
                          {(vvv.children.length > 0)
                            && <div style={{ borderTopStyle: 'solid', borderTopWidth: 1, borderTopColor: brand_colors[this.props.defaultTheme.theme_id].color18, marginTop: '5px', paddingTop: '5px', width: '100%' }}>
                              {vvv.children.map((vvvv, iiii) => {
                                return <div className='d-flex flex-row justify-content-between' style={{ color: brand_colors[this.props.defaultTheme.theme_id].color9, opacity: 0.7 }}>
                                  <div style={{ marginTop: '10px', fontSize: '14px', color: brand_colors[this.props.defaultTheme.theme_id].color9, opacity: 0.7 }}>{vvvv.aslr_title}</div>
                                </div>
                              })}
                            </div>
                          }
                        </div>
                      })}

                    </Col>
                  </Row>
                }

                {/* Learner Profile */}
                {(vv.uid == '3.2') &&
                  <Row>
                    <div className={'d-flex flex-row align-items-center justify-content-between section-heading'} id={'form_section_' + vv.uid}>
                      <div>
                        <img className='image' src={(vv.image) ? vv.image : null} />
                        <span className='heading'>{vv.name}</span>
                      </div>
                      <div>
                      </div>
                    </div>
                    <Col md={12}>
                      <Alert variant='secondary' >{t(vv.desc)}</Alert>
                    </Col>
                    <Col md={12} className='d-flex flex-row flex-wrap'>
                      {this.state.academicLessonAttributes.map((vvv, iii) => {
                        let findIndexSelectedData = this.state.academicSubjectLessonResources.findIndex((vvvv) => (vvvv.aslr_type_id == vvv.ala_id && vvvv.aslr_type == vv.enum))
                        if (vvv.ala_type == vv.enum && vvv.ala_parent_id == 0 && findIndexSelectedData !== -1) {
                          return <div className='div-box-theme cursor-pointer' style={{ flex: 'none', width: '47%' }}
                          >
                            <div className='heading'>{vvv.ala_title}</div>
                            <div className='description'>{vvv.ala_description_1}</div>
                          </div>
                        }
                      })}
                    </Col>

                  </Row>
                }

                {/* Key Concepts */}
                {(vv.uid == '3.3') &&
                  <Row>
                    <div className={'d-flex flex-row align-items-center justify-content-between section-heading'} id={'form_section_' + vv.uid}>
                      <div>
                        <img className='image' src={(vv.image) ? vv.image : null} />
                        <span className='heading'>{vv.name}</span>
                      </div>
                      <div>
                      </div>
                    </div>
                    <Col md={12}>
                      <Alert variant='secondary' >{t(vv.desc)}</Alert>
                    </Col>
                    <Col md={12} className='d-flex flex-row flex-wrap'>
                      {this.state.academicLessonAttributes.map((vvv, iii) => {
                        let findIndexSelectedData = this.state.academicSubjectLessonResources.findIndex((vvvv) => (vvvv.aslr_type_id == vvv.ala_id && vvvv.aslr_type == vv.enum))

                        if (vvv.ala_type == vv.enum && vvv.ala_parent_id == 0 && findIndexSelectedData !== -1) {
                          return <div className='div-box-theme' style={{ flex: 'none', width: '47%' }}>
                            <div className='heading'>
                              {vvv.ala_title}
                            </div>
                            <div className='description'>{vvv.ala_description_2}</div>
                            <div style={{ width: '100%' }}>
                              <div style={{ fontWeight: '600' }}>{vvv.ala_description_1}</div>
                              <div>{(findIndexSelectedData !== -1) ? this.state.academicSubjectLessonResources[findIndexSelectedData].aslr_desc : ''}</div>
                            </div>
                          </div>
                        }
                      })}
                    </Col>
                  </Row>
                }

                {/* Related Concepts */}
                {(vv.uid == '3.4') && (related_concept_learn_area.length > 0 || related_concept_key_concept.length > 0) &&
                  <>
                    <Row style={{ marginBottom: '20px' }}>
                      <div className={'d-flex flex-row align-items-center justify-content-between section-heading'} id={'form_section_' + vv.uid}>
                        <div>
                          <img className='image' src={(vv.image) ? vv.image : null} />
                          <span className='heading'>{vv.name}</span>
                        </div>
                        <div>
                        </div>
                      </div>
                      <Col md={12}>
                        <Alert variant='secondary' >
                          {vv.desc.map((vvv) => {
                            return <div>{vvv}</div>
                          })}
                        </Alert>
                      </Col>
                      {related_concept_learn_area.length > 0 &&
                        <Col md="6">
                          <div className='div-box-theme' style={{ marginRight: 0 }}>
                            <div className='d-flex flex-row' style={{ cursor: 'pointer' }}>
                              <div className='heading'>By Subject</div>
                            </div>
                            <div style={{ borderTopStyle: 'solid', borderTopWidth: 1, borderTopColor: brand_colors[this.props.defaultTheme.theme_id].color18, marginTop: '5px', paddingTop: '5px', width: '100%' }}>
                              <div className='d-flex flex-column justify-content-between' style={{}}>
                                {related_concept_learn_area.map((vvv, iii) => {

                                  return <div style={{ marginTop: '10px', fontSize: '14px', color: brand_colors[this.props.defaultTheme.theme_id].color9, opacity: 0.7 }}>
                                    <span style={{ fontWeight: '600' }}>{vvv.title}</span>
                                    <div className='d-flex flex-row flex-wrap'>
                                      {vvv.children.map((vvvv, iiii) => {
                                        return <span style={{
                                          padding: '5px 10px',
                                          margin: '3px 5px',
                                          background: brand_colors[this.props.defaultTheme.theme_id].color4,
                                          color: brand_colors[this.props.defaultTheme.theme_id].color8,
                                          borderRadius: '20px',
                                        }}>{vvvv}</span>
                                      })}
                                    </div>
                                  </div>
                                })}
                              </div>
                            </div>
                          </div>
                        </Col>
                      }
                      {related_concept_key_concept.length > 0 &&
                        <Col md="6">
                          <div className='div-box-theme' style={{ marginRight: 0 }}>
                            <div className='d-flex flex-row' style={{ cursor: 'pointer' }}>
                              <div className='heading'>By Key concept</div>
                            </div>
                            <div style={{ borderTopStyle: 'solid', borderTopWidth: 1, borderTopColor: brand_colors[this.props.defaultTheme.theme_id].color18, marginTop: '5px', paddingTop: '5px', width: '100%' }}>
                              <div className='d-flex flex-column justify-content-between' style={{}}>
                                {related_concept_key_concept.map((vvv, iii) => {

                                  return <div style={{ marginTop: '10px', fontSize: '14px', color: brand_colors[this.props.defaultTheme.theme_id].color9, opacity: 0.7 }}>
                                    <span style={{ fontWeight: '600' }}>{vvv.title}</span>
                                    <div className='d-flex flex-row flex-wrap'>
                                      {vvv.children.map((vvvv, iiii) => {
                                        return <span style={{
                                          padding: '5px 10px',
                                          margin: '3px 5px',
                                          background: brand_colors[this.props.defaultTheme.theme_id].color4,
                                          color: brand_colors[this.props.defaultTheme.theme_id].color8,
                                          borderRadius: '20px',
                                        }}>{vvvv}</span>
                                      })}
                                    </div>
                                  </div>
                                })}
                              </div>
                            </div>
                          </div>
                        </Col>
                      }

                    </Row>

                  </>
                }

                {/* Action */}
                {(vv.uid == '4.2') && action_prior_learning.length > 0 &&
                  <Row>
                    <div className={'d-flex flex-row align-items-center justify-content-between section-heading'} id={'form_section_' + vv.uid}>
                      <div>
                        <img className='image' src={(vv.image) ? vv.image : null} />
                        <span className='heading'>{vv.name}</span>
                      </div>
                      <div>
                      </div>
                    </div>
                    <Col md={12}>
                      <Alert variant='secondary' className='mb-1'>
                        {vv.desc.map((vvv) => {
                          return <div>{vvv}</div>
                        })}
                      </Alert>
                    </Col>
                    <Col md={12} className='d-flex flex-row flex-wrap'>
                      {action_prior_learning.map((vvv, iii) => {
                        return <div className='div-box-theme' style={{ flex: 'none', width: '47%' }}>
                          <div className='heading'>
                            {vvv.title}
                          </div>
                          <div className='description'>{vvv.desc}</div>
                          <div style={{ borderTopStyle: 'solid', borderTopWidth: 1, borderTopColor: brand_colors[this.props.defaultTheme.theme_id].color18, marginTop: '5px', paddingTop: '5px', width: '100%' }}>
                            <div>{vvv.children}</div>
                          </div>
                        </div>
                      })}
                    </Col>

                  </Row>
                }

              </>
            )
          })
          }
        </div>
      )

    })

  }

  // renderThisFormSectionReadOnly = () => {
  //   return <div>Test</div>
  // }

  // CHANGE AND SCROLL TO SECTION
  changeToFromSection = (parent, child) => {
    this.setState({
      formSectionActive: { parent: parent, child: child }
    }, () => {
      setTimeout(() => {
        const element = document.getElementById('form_section_' + child);
        if (element) {
          // 👇 Will scroll smoothly to the top of the next section
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }, 300);
    })
  }


  generatePDF = (lessonData) => {
    let URL = process.env.REACT_APP_API_URL + "academic-subject-lessons/generate-pdf-lesson?" +
      "auth_key=" + this.props.authData.authKey +
      "&center_id=" + this.props.authData.centerData.center_id +
      "&asl_id=" + lessonData.asl_id +
      "&acs_id=" + lessonData.acs_id

    window.open(
      URL,
      "PDF" + "_" + lessonData.asl_name
    );
  }

  renderModalAddResource = () => {
    if (!this.state.modalAddResource.show) {
      return null
    }

    let { externalResource, type1Opts, type2Opts, academicSubjectLessons, image, image_prev, image_deleted, aslr_attachment, sectionGroup, examTypes, termsDefault, getAcademicSubjectLessonFlow, modalAddResource } = this.state;
    let { asl_name, asl_coef, asl_code, center_id, asl_period, asl_attach, asl_link, asl_order, asl_sen_required, asl_ict_usage, asl_afl, asl_notes1, asl_notes2, sl_notes3, asl_type1, asl_type2, asl_theme, asl_chat_blog, asl_differentiation, asl_warmup, asl_identity, asl_objectives_description, asl_preparation, asl_usefulness, asl_steps, asl_ending, asl_homeworks, asl_oral, asl_activities, asl_written_work, asl_student_work, asl_dual_assessment, asl_self_assessment, asl_objectives_list, asl_drop_quiz, asl_acs_ids, asl_aa_ids } = academicSubjectLessons

    let thisData = null
    if (modalAddResource.parent == '8' && modalAddResource.child == '8.2') {
      thisData = this.state.academicSubjectLessonExams.find((v, i) => v.editing)
    }

    return this.state.formSections.map((v, i) => {
      if (modalAddResource.parent == v.uid) {
        return v.data.map((vv, ii) => {
          if (vv.uid == modalAddResource.child) {
            return <Modal size='lg' show={modalAddResource.show} onHide={modalAddResource.toggle}>
              <Modal.Header closeButton>
                <Modal.Title>
                  {modalAddResource?.title}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className="custom-styled-form">
                <div className='d-flex flex-row align-items-center mt-2 justify-content-between'>
                  <div>
                    <Form.Group>
                      <Form.Label>
                        <img src={imgDescription} />
                        {t('Title')}
                      </Form.Label>
                      <Form.Control type='text' value={(thisData) ? thisData.asle_name : ''} onChange={(event) =>
                        this.setStateAcademicSubjectLessonExams(
                          'asle_name',
                          event.target.value,
                          (thisData) ? thisData.asle_id : vv.uid
                        )} />
                    </Form.Group>
                  </div>
                  <div>
                    <ButtonGroup size="sm" className='me-3' style={{ borderRadius: 5, overflow: 'hidden' }}>
                      <Button
                        className='px-3'
                        style={{ fontWeight: '600', fontSize: 13 }}
                        onClick={() => this.setStateAcademicSubjectLessonExams(
                          'asle_is_exampaper',
                          0,
                          (thisData) ? thisData.asle_id : vv.uid
                        )}
                        variant={((thisData) ? (thisData.asle_is_exampaper == 0) : false) ? 'primary' : 'light'}>{t('Online')}</Button>
                      <Button
                        className='px-3'
                        style={{ fontWeight: '600', fontSize: 13 }}
                        onClick={() => this.setStateAcademicSubjectLessonExams(
                          'asle_is_exampaper',
                          1,
                          (thisData) ? thisData.asle_id : vv.uid
                        )}
                        variant={((thisData) ? (thisData.asle_is_exampaper == 1) : false) ? 'primary' : 'light'}>{t('Recitation')}</Button>
                    </ButtonGroup>
                  </div>
                  <div>
                    <Form.Group>
                      <Form.Label>
                        <img src={imgDescription} />
                        {t('Type')}
                      </Form.Label>
                      <Select classNamePrefix='custom-styled-select' placeholder={t('Select Type')} value={(thisData) ? thisData.examstype_id : ''} options={examTypes} onChange={(selected_examstype_id) => {
                        this.setStateAcademicSubjectLessonExams(
                          'examstype_id',
                          selected_examstype_id,
                          (thisData) ? thisData.asle_id : vv.uid
                        )
                      }}
                      />
                    </Form.Group>
                  </div>
                  <div>
                    <Form.Group>
                      <Form.Label>
                        <img src={imgDescription} />
                        {t('Term')}
                      </Form.Label>
                      <Select classNamePrefix='custom-styled-select' placeholder={t('Select Term')} value={(thisData) ? thisData.term_d_id : ''} options={termsDefault} onChange={(selected_term_d_id) => {
                        this.setStateAcademicSubjectLessonExams(
                          'term_d_id',
                          selected_term_d_id,
                          (thisData) ? thisData.asle_id : vv.uid
                        )
                      }}
                      />
                    </Form.Group>
                  </div>
                </div>
                <Form.Group className='mb-1'>
                  <Form.Label>
                    <img src={imgDescription} />
                    {t('Description')}
                  </Form.Label>
                  <Form.Control as='textarea' rows={3} value={(thisData) ? thisData.asle_description : ''} onChange={(event) =>
                    this.setStateAcademicSubjectLessonExams(
                      'asle_description',
                      event.target.value,
                      (thisData) ? thisData.asle_id : vv.uid
                    )}
                  />
                </Form.Group>
              </Modal.Body>
              <Modal.Footer>
                <Button variant='secondary' onClick={modalAddResource.toggle}> {t('Close')} </Button>
                <Button variant='warning' onClick={modalAddResource.onSave}> {t('Save')} </Button>
              </Modal.Footer>
            </Modal>
          }
        })
      }
    })
  }

  /**
   * Render Component
   * @returns {HTMLElement}
   */
  render() {
    const { formSectionActive, formSectionActiveModal, sectionGroup, modalResources } = this.state;

    const selectedClass = this.props.selectedClass;
    const { lessonData } = this.props.location.state;

    // State
    const { lessonDetails, selectedResource } = this.state;

    // Resources Link
    let resourcesLink;

    if (selectedResource && selectedResource.aslr_url_type == 'youtube') {
      resourcesLink = selectedResource.aslr_url;
      const resourceArr = resourcesLink.split('=');
      resourcesLink = 'https://youtube.com/embed/' + resourceArr[1];
    }
    const { lang_orientation: dir } = this.props.defaultLanguage;

    return (
      <Container fluid>
        <div id='iedu-layout'>
          <NavBar />
          <div id='page-content'>
            <Header lite={true} heading={t('Lesson / Unit Planner')} backBtn={true} />

            <div className={`grey-section lesson-plan`} style={{ height: 'auto', overflow: 'initial' }}>
              <div className='grey-section-header'>
                <h5 style={{ backgroundColor: brand_colors[this.props.defaultTheme.theme_id].color7 }}>
                  <span style={{ fontWeight: 600 }}>
                    {lessonData.asl_name}
                  </span>
                </h5>
                <div>
                  <button style={{ background: 'none', border: 0, marginRight: 10 }} onClick={() => this.generatePDF(lessonData)} title={t('Generate PDF')}>
                    <FontAwesomeIcon
                      icon={faFilePdf}
                      color={brand_colors[this.props.defaultTheme.theme_id].color11}
                      style={{ fontSize: 26, marginLeft: 5, }}
                    />
                  </button>
                  <button style={{ background: 'none', border: 0, }} onClick={() => this.setState({ readonly: !this.state.readonly })}>
                    <span style={{ fontSize: 20, marginRight: 5, color: brand_colors[this.props.defaultTheme.theme_id].color18, backgroundColor: brand_colors[this.props.defaultTheme.theme_id].color7 }}>{(this.state.readonly) ? 'Switch to Edit' : 'Switch to Readonly'}</span>
                    <FontAwesomeIcon
                      icon={(this.state.readonly) ? faEye : faEdit}
                      color={(this.state.readonly) ? brand_colors[this.props.defaultTheme.theme_id].color4 : brand_colors[this.props.defaultTheme.theme_id].color1}
                      style={{ fontSize: 26, marginLeft: 5, }}
                    />
                  </button>
                </div>
              </div>

              <div style={{ borderRadius: 10, marginTop: '20px' }}>

                <div className={'d-flex flex-row'}>

                  {/* FORM SECTION LIST START */}
                  {(!this.state.readonly) &&
                    <div style={{ backgroundColor: brand_colors[this.props.defaultTheme.theme_id].color8, marginLeft: 10, marginRight: 10, borderRadius: '20px', width: '250px', height: '100%', top: '5px', position: 'sticky' }}>

                      <div className={'d-flex flex-row justify-content-around flex-wrap'} style={{ borderBottom: 'solid 1px #7b7b7b', paddingTop: '1rem', marginLeft: '1rem', marginRight: '1rem', marginBottom: '1rem', }}>


                        <span className="cursor-pointer" style={{ color: brand_colors[this.props.defaultTheme.theme_id].color4, opacity: 1, fontSize: '16px', fontWeight: 'bold' }}>{sectionGroup}</span>
                        {/*! TODO: REMOVE LATER ONLY ONE WILL BE HERE*/}
                        {/* <div onClick={() => this.setState({ sectionGroup: 'General' })} className="cursor-pointer px-1 mx-1 text-center" style={{ color: (sectionGroup == 'General') ? brand_colors[this.props.defaultTheme.theme_id].color4 : brand_colors[this.props.defaultTheme.theme_id].color9, opacity: (sectionGroup == 'General') ? 1 : 0.7, fontSize: '16px', fontWeight: 'bold' }}>General</div>
                        <div onClick={() => this.setState({ sectionGroup: 'Primary Year Programme' })} className="cursor-pointer px-1 mx-1 text-center" style={{ color: (sectionGroup == 'Primary Year Programme') ? brand_colors[this.props.defaultTheme.theme_id].color4 : brand_colors[this.props.defaultTheme.theme_id].color9, opacity: (sectionGroup == 'Primary Year Programme') ? 1 : 0.7, fontSize: '16px', fontWeight: 'bold' }}>PYP</div>
                        <div onClick={() => this.setState({ sectionGroup: 'Middle Year Programme' })} className="cursor-pointer px-1 mx-1 text-center" style={{ color: (sectionGroup == 'Middle Year Programme') ? brand_colors[this.props.defaultTheme.theme_id].color4 : brand_colors[this.props.defaultTheme.theme_id].color9, opacity: (sectionGroup == 'Middle Year Programme') ? 1 : 0.7, fontSize: '16px', fontWeight: 'bold' }}>MYP</div>
                        <div onClick={() => this.setState({ sectionGroup: 'Diploma Programme' })} className="cursor-pointer px-1 mx-1 text-center" style={{ color: (sectionGroup == 'Diploma Programme') ? brand_colors[this.props.defaultTheme.theme_id].color4 : brand_colors[this.props.defaultTheme.theme_id].color9, opacity: (sectionGroup == 'Diploma Programme') ? 1 : 0.7, fontSize: '16px', fontWeight: 'bold' }}>DP</div>
                        <div onClick={() => this.setState({ sectionGroup: 'UAE MOE' })} className="cursor-pointer px-1 mx-1 text-center" style={{ color: (sectionGroup == 'UAE MOE') ? brand_colors[this.props.defaultTheme.theme_id].color4 : brand_colors[this.props.defaultTheme.theme_id].color9, opacity: (sectionGroup == 'UAE MOE') ? 1 : 0.7, fontSize: '16px', fontWeight: 'bold' }}>MOE</div> */}

                      </div>
                      <div style={{ paddingLeft: '1.5rem', paddingBottom: '1.5rem', height: '83vh', overflowY: 'auto' }}>
                        {this.state.formSections.map((v, i) => {
                          if (!v.sectionGroup.includes(this.state.sectionGroup)) {
                            return null
                          }

                          return (<div>
                            <div className='d-flex flex-row align-items-center' style={{ cursor: 'pointer', borderRadius: '20px', paddingLeft: '5px', paddingRight: '5px', backgroundColor: (v.uid == formSectionActive.parent) ? brand_colors[this.props.defaultTheme.theme_id].color19 : brand_colors[this.props.defaultTheme.theme_id].color8, zIndex: 1, position: 'relative' }} onClick={() => this.changeToFromSection(v.uid, v.uid + '.1')}>
                              <FontAwesomeIcon
                                icon={(v.completed) ? faCheckCircle : faCircle}
                                color={(v.uid == formSectionActive.parent) ? brand_colors[this.props.defaultTheme.theme_id].color20 : (v.completed) ? brand_colors[this.props.defaultTheme.theme_id].color4 : brand_colors[this.props.defaultTheme.theme_id].color9}
                                style={{
                                  fontSize: 12,
                                  marginRight: 5,
                                  opacity: (v.uid == formSectionActive.parent || v.completed) ? 1 : 0.7
                                }}
                              />
                              <span style={{ fontSize: 18, fontWeight: '600', color: (v.uid == formSectionActive.parent) ? brand_colors[this.props.defaultTheme.theme_id].color20 : brand_colors[this.props.defaultTheme.theme_id].color9, opacity: (v.uid == formSectionActive.parent) ? 1 : 0.7 }}>{v.name}</span>
                            </div>

                            <div style={{ paddingLeft: '1rem', borderLeft: 'solid 1px color18', marginLeft: '1.5rem', }}>
                              {v.data.map((vv, ii) => {
                                if (!vv.sectionGroup.includes(this.state.sectionGroup)) {
                                  return null
                                }
                                return (<div className='d-flex flex-row align-items-center' style={{ marginBottom: 5, marginTop: 2, cursor: 'pointer' }} onClick={() => this.changeToFromSection(v.uid, vv.uid)}>
                                  <FontAwesomeIcon
                                    icon={(vv.completed) ? faCheckCircle : faCircle}
                                    color={(vv.completed) ? brand_colors[this.props.defaultTheme.theme_id].color4 : brand_colors[this.props.defaultTheme.theme_id].color18}
                                    style={{ fontSize: 12, marginRight: 5, }}
                                  />
                                  <span style={{ fontSize: 14, color: (vv.completed) ? brand_colors[this.props.defaultTheme.theme_id].color4 : brand_colors[this.props.defaultTheme.theme_id].color18 }}>{vv.name}</span>
                                </div>)
                              })}
                            </div>
                          </div>)
                        })}
                      </div>
                    </div>
                  }
                  {/* FORM SECTION LIST END */}
                  <div style={{ backgroundColor: brand_colors[this.props.defaultTheme.theme_id].color8, marginLeft: 10, marginRight: 10, borderRadius: '20px', flex: 1, padding: '20px' }}>
                    {(this.state.readonly) ? this.renderThisFormSectionReadOnly() : this.renderThisFormSection(formSectionActive)}
                  </div>
                </div>
              </div>

            </div>

            {/* FLOATING BUTTONS */}
            <div className="d-flex flex-row justify-content-end" style={{ position: 'absolute', bottom: 0, right: 0, zIndex: 7 }}>
              <Button className="me-2 border-bottom-radius-0" variant='success' onClick={() => modalResources.toggle('10', '10.1')}>
                <FontAwesomeIcon icon={faBarsStaggered} className='me-1' />
                {t('Lesson Flow')}
              </Button>
              <Button className="me-2 border-bottom-radius-0" variant='success' onClick={() => modalResources.toggle('6', '6.1')}>
                <FontAwesomeIcon icon={faPeopleGroup} className='me-1' />
                {t('Reflections')}
              </Button>
              <Button className="me-2 border-bottom-radius-0" variant='success' onClick={() => modalResources.toggle('7', '7.1')}>
                <FontAwesomeIcon icon={faComments} className='me-1' />
                {t('Comments')}
              </Button>
            </div>

          </div>
        </div>

        <Offcanvas id='modalResources' show={modalResources.show} onHide={modalResources.toggle} placement={dir == 1 ? 'start' : 'end'} style={{ width: '50vw' }}>
          <Offcanvas.Body>
            {modalResources.show &&
              <div>
                {this.renderThisFormSection(formSectionActiveModal)}
              </div>
            }
          </Offcanvas.Body>
        </Offcanvas>

        {this.renderModalAddResource()}

        <Modal size='md' show={this.state.addModal.show} onHide={this.state.addModal.onClose}>
          <Modal.Header closeButton>
            <Modal.Title>
              {this.state.addModal.title}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              {this.state.addModal.allData.map((v, i) => {
                let findIndexSelectedData = -1
                if (this.state.addModal.enum == 'approaches_to_learning') {
                  findIndexSelectedData = this.state.addModal.selectedData.findIndex((vv, ii) => vv.aslr_type_id == v.ala_id)
                }
                else if (this.state.addModal.enum == 'competency') {
                  findIndexSelectedData = this.state.addModal.selectedData.findIndex((vv, ii) => vv.aslr_type_id == v.curriculum_id)
                }

                return <div className='d-flex flex-row cursor-pointer' style={{ marginTop: '10px', fontSize: '14px', color: brand_colors[this.props.defaultTheme.theme_id].color9, opacity: 0.7 }} onClick={() => {
                  let selectedData = this.state.addModal.selectedData

                  if (findIndexSelectedData !== -1) {
                    selectedData.splice(findIndexSelectedData, 1)
                  } else {
                    if (this.state.addModal.enum == 'approaches_to_learning') {
                      selectedData.push({
                        aslr_id: chance.unique(chance.integer, 1, { min: 99999999999, max: 999999999999 })[0],
                        aslr_type: this.state.addModal.enum,
                        aslr_title: v.ala_title,
                        aslr_url_type: '',
                        aslr_url: '',
                        aslr_desc: v.ala_description_1,
                        aslr_tags: [],
                        aslr_type_id: v.ala_id,
                        ab_id: this.props.location.state.lessonData.ab_id,
                        as_id: this.props.location.state.lessonData.as_id,
                        acs_id: this.props.location.state.lessonData.acs_id,
                        asl_id: this.props.location.state.lessonData.asl_id,
                        // classmain_id: this.props.selectedClass.class.classmain_id,
                      })
                    } else if (this.state.addModal.enum == 'competency') {
                      selectedData.push({
                        aslr_id: chance.unique(chance.integer, 1, { min: 99999999999, max: 999999999999 })[0],
                        aslr_type: this.state.addModal.enum,
                        aslr_title: v.curriculum_code || v.curriculum_desc,
                        aslr_url_type: '',
                        aslr_url: '',
                        aslr_desc: v.curriculum_desc || v.curriculum_code,
                        aslr_tags: [],
                        aslr_type_id: v.curriculum_id,
                        ab_id: this.props.location.state.lessonData.ab_id,
                        as_id: this.props.location.state.lessonData.as_id,
                        acs_id: this.props.location.state.lessonData.acs_id,
                        asl_id: this.props.location.state.lessonData.asl_id,
                        // classmain_id: this.props.selectedClass.class.classmain_id,
                      })
                    }

                  }
                  this.setState({ addModal: { ...this.state.addModal, selectedData: selectedData } })
                }}>
                  <FontAwesomeIcon icon={(findIndexSelectedData !== -1) ? faCheckCircle : faCircle} color={(findIndexSelectedData !== -1) ? brand_colors[this.props.defaultTheme.theme_id].color4 : brand_colors[this.props.defaultTheme.theme_id].color18} style={{ fontSize: '14px', marginTop: '5px', marginRight: '5px', marginLeft: '5px' }} />
                  {(this.state.addModal.enum == 'approaches_to_learning') && <div><span style={{ fontWeight: (v.ala_description_1) ? '600' : 'normal' }}>{v.ala_title}</span> {(v.ala_description_1) && ' : ' + v.ala_description_1}</div>}
                  {(this.state.addModal.enum == 'competency') && <div>{v.curriculum_code || v.curriculum_desc}</div>}
                </div>
              })}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={this.state.addModal.onClose}> {t('Close')} </Button>
            <Button variant='warning' onClick={this.state.addModal.action}> {t('Add Selected')} </Button>
          </Modal.Footer>
        </Modal>
        <SweetAlert
          show={this.state.deleteModal.show}
          warning
          showCancel
          confirmBtnText={t('Yes, delete it!')}
          confirmBtnBsStyle='danger'
          title={t('Are you sure?')}
          onConfirm={this.state.deleteModal.action}
          onCancel={() => this.setState({ deleteModal: { ...this.state.deleteModal, show: false, id: 0, action: () => { } } })}
          focusCancelBtn>
          {t('Confirm Delete!')}
        </SweetAlert>
      </Container >
    );
  }
}

const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  selectedClass: state.selectedClass.data,
  defaultLanguage: state.language.defaultLanguage,
  defaultTheme: state.theme.defaultTheme,
});

export default connect(mapStateToProps, null)(withParams(Lesson));
