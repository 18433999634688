import React, { Component } from 'react'
import { Container, Card, Row, Col, Spinner, Button, Table, Badge, Form, Image, Modal } from 'react-bootstrap'
import { t } from '../../helpers/translation_helper'
import NavBar from '../_partials/NavBar/_NavBar'
import Header from '../_partials/Header/_Header'
import { connect } from 'react-redux'
import axios from 'axios'
import { toast } from 'react-toastify'
import { GET, POST } from '../../api'
import imgDescription from '../../assets/images/description.png'
import { brand_colors } from '../../helpers/brand_colors_helper'
import TimetablePeriods from './TimetablePeriods'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faPencil, faPlus, faSearch, faXmark } from '@fortawesome/free-solid-svg-icons'
import Select from 'react-select'
import moment from 'moment'
import { handleFormErrors } from '../../helpers/form_helpers'
import Dragula from 'react-dragula'
import { Link } from 'react-router-dom'
var Chance = require('chance')
var chance = new Chance()

const apiURL = process.env.REACT_APP_API_URL
const portalURL = process.env.REACT_APP_PORTAL_URL

class TimetableManagement extends Component {

  drake;

  constructor(props) {
    super(props)
    this.state = {
      timetablePeriods: {
        tp_id: null,
        toggleTp: (tp_id) => {
          let newState = { timetablePeriods: { ...this.state.timetablePeriods, tp_id } }
          if ((tp_id == this.state.timetablePeriods.tp_id)) {
            newState = { timetablePeriods: { ...this.state.timetablePeriods, tp_id: null } }
          }
          this.setState(newState, () => this.getTimetableDetails())
        }
      },
      days: [
        { name: 'Sunday', active: true },
        { name: 'Monday', active: true },
        { name: 'Tuesday', active: true },
        { name: 'Wednesday', active: true },
        { name: 'Thursday', active: true },
        { name: 'Friday', active: true },
        { name: 'Saturday', active: true },
      ],
      timetable_period_timings: [],
      timetable: [],
      employee_timetable_availability: [],
      employees: {
        data: [],
        search: '',
        changeSearch: (search) => this.setState({ employees: { ...this.state.employees, search } })
      },
      classesData: {
        data: [],
        options: [],
        selected: []
      },
      modalEmployees: {
        show: false,
        toggle: (title = 'Edit Employee', data) => this.setState({ modalEmployees: { ...this.state.modalEmployees, show: !this.state.modalEmployees.show, title, data } }),
        title: '',
        save: this.updateEmployee,
        saving: false,
        data: null,
        setState: (field, value) => this.setState({ modalEmployees: { ...this.state.modalEmployees, data: { ...this.state.modalEmployees.data, [field]: value } } })
      },
      showEmployeesView: false,
      periodTypes: [],
      agegroups: []
    }
    this.employee_timetable_availability = []
  }

  componentDidMount() {
    this.checkWeekendDays()
    this.getAgegroups()
    this.getClasses()
  }

  reRenderTargetElements = () => {
    return
    const { employee_timetable_availability } = this.state
    this.drake.containers.map((container) => {
      if (container.classList[0] == 'drop-target') {
        console.log('RERENDERcontainer', employee_timetable_availability)
        let tpt_id = container.getAttribute('tpt_id')
        let day = container.getAttribute('day')
        container.innerHTML = ''
        const this_employee_timetable_availability = employee_timetable_availability.filter((v) => v.tpt_id == tpt_id && v.day == day)
        if (this_employee_timetable_availability.length > 0) {
          this_employee_timetable_availability.map((v) => {
            const employeeNode = document.createElement("div")
            employeeNode.className = 'employee-reel-item'
            employeeNode.setAttribute('employee_id', v.employee_id)
            employeeNode.setAttribute('employee_name', v.employee_name)
            employeeNode.innerHTML = `<div class='badge badge-success'>${v.employee_name}</div>`
            container.appendChild(employeeNode)
          })
        }
      }
    })
  }

  checkWeekendDays = () => {
    let weekend_days = this.props.authData.centerData.weekend_days
    if (weekend_days) {
      weekend_days = weekend_days.split(',')
    }
    let days = this.state.days.map((v, i) => {
      if (weekend_days.includes(v.name.toLowerCase())) {
        return { ...v, active: false }
      }
      return v
    })
    this.setState({ days })
  }

  getTimetableDetails = async () => {
    let timetablePeriods = this.state.timetablePeriods
    if (!timetablePeriods.tp_id) {
      return null
    }
    try {
      const res = await GET('timetable/get-timetable-details', {
        params: {
          tp_id: timetablePeriods.tp_id
        },
      })
      if (res.status == 200) {
        let resData = res.data
        this.setState({
          timetable_period_timings: resData.timetable_period_timings.map(item => {
            return {
              uuid: chance.guid(),
              ...item,
            }
          }),
          timetable: resData.timetable,
          employees: { ...this.state.employees, data: resData.employees, search: '' },
          employee_timetable_availability: resData.employee_timetable_availability
        }, () => {
          this.getClassesOptions()
        })
      }
    } catch (err) {
      console.log('err', err)
      toast.error(t('Something went wrong while fetching academic subjects!'))
    }
  }

  getAgegroups = async () => {
    try {
      const res = await GET('timetable/get-age-groups');
      this.setState({
        agegroups: res.data
      });
    } catch (err) {
      console.log(err);
    }
  }

  getClasses = async () => {
    try {
      const res = await GET('timetable/get-classes', {
      })
      if (res.status == 200) {
        let resData = res.data
        this.setState({
          classesData: { ...this.state.classesData, data: resData },
        })
      }
    } catch (err) {
      console.log('err', err)
      toast.error(t('Something went wrong while fetching academic subjects!'))
    }
  }

  getClassesOptions = () => {
    let timetablePeriods = this.state.timetablePeriods
    let classesData = this.state.classesData
    let timetable = this.state.timetable
    classesData.selected = []
    classesData.options = []
    classesData.data.map((v, i) => {
      let findTimeTable = timetable.find((vv) => (vv.class_id == v.class_id && vv.tp_id == timetablePeriods.tp_id))
      if (findTimeTable) {
        classesData.selected.push({ label: v.class_theme, value: v.class_id, ...v })
      }
      let findTimeTable2 = timetable.find((vv) => (vv.class_id == v.class_id && vv.tp_id != timetablePeriods.tp_id))
      if (!findTimeTable2) {
        classesData.options.push({ label: v.class_theme, value: v.class_id, ...v })
      }
    })
    this.setState({ classesData })
  }

  setStateTime = (i, field, value) => {
    let timetable_period_timings = this.state.timetable_period_timings
    timetable_period_timings[i][field] = value
    this.setState({ timetable_period_timings })
  }


  updateEmployee = async () => {
    if (this.updateEmployeeReq) {
      this.updateEmployeeReq.abort()
    }
    this.updateEmployeeReq = new AbortController()
    this.setState({ modalEmployees: { ...this.state.modalEmployees, saving: true } })
    let modalEmployees = JSON.parse(JSON.stringify(this.state.modalEmployees))

    modalEmployees.data.work_start_time = (modalEmployees.data.work_start_time) ? moment(modalEmployees.data.work_start_time, 'HH:mm').format('h:mm A') : ''
    modalEmployees.data.work_end_time = (modalEmployees.data.work_end_time) ? moment(modalEmployees.data.work_end_time, 'HH:mm').format('h:mm A') : ''
    modalEmployees.data.break_start_time = (modalEmployees.data.break_start_time) ? moment(modalEmployees.data.break_start_time, 'HH:mm').format('h:mm A') : ''
    modalEmployees.data.break_end_time = (modalEmployees.data.break_end_time) ? moment(modalEmployees.data.break_end_time, 'HH:mm').format('h:mm A') : ''
    const fData = new FormData()
    fData.append('employee_id', modalEmployees.data.employee_id)
    fData.append('EmployeesData', JSON.stringify(modalEmployees.data))
    const res = await POST(
      'employees/update-employee-timing',
      fData,
      {
        signal: this.updateEmployeeReq.signal,
      }
    )
    if (!res.data.type) {
      if (res.data.data != null) {
        handleFormErrors(res.data.data)
      } else {
        toast.error(t('Something went wrong while saving!'))
      }
    } else {
      let employees = this.state.employees
      employees.data = employees.data.map((v, i) => {
        if (v.employee_id == modalEmployees.data.employee_id) {
          console.log('here', modalEmployees.data.break_start_time)
          return { ...v, ...modalEmployees.data }
        }
        return v
      })
      console.log('employees', employees)
      this.setState({
        modalEmployees: {
          ...this.state.modalEmployees,
          show: false,
          saving: false,
          data: null
        },
        employees: employees
      })
      toast.success(t(res.data.message))
    }

  }



  saveTimetableDetails = async () => {
    if (this.saveTimetableDetailsReq) {
      this.saveTimetableDetailsReq.abort()
    }
    this.saveTimetableDetailsReq = new AbortController()
    let classesData = this.state.classesData

    let timetable_period_timings = this.state.timetable_period_timings.map(item => {
      return {
        ...item,
        tp_type_id: item.tp_type_id == 0 || !item.tp_type_id ? null : item.tp_type_id
      }
    });
    let employee_timetable_availability = this.state.employee_timetable_availability

    let old_end_time = null
    let time_error = false
    if (timetable_period_timings.length <= 0) {
      toast.warning('Please add periods in this timetable!');
      return false;
    }

    timetable_period_timings.map((v, i) => {
      let time_start = v.time_start;
      let time_end = v.time_end;
      if (time_start == "" || time_end == "") {
        time_error = true
        toast.warning('Start Time and End Time cannot be empty!');
      }
      else if (moment(time_start, "HH:mm").isAfter(moment(time_end, "HH:mm"))) {
        time_error = true
        toast.warning('Start Time should not be bigger than End Time!',
          'Error!', {
          "showMethod": "slideDown",
          "hideMethod": "slideUp",
          timeOut: 3000
        });
      }
      else if (moment(time_start, "HH:mm").isSame(moment(time_end, "HH:mm"))) {
        time_error = true
        toast.warning('Start Time should not Equal to End Time!');
      } else {
        if (old_end_time != null) {
          if (moment(old_end_time, "HH:mm").isAfter(moment(time_start, "HH:mm"))) {
            time_error = true
            toast.warning('Start Time should be bigger than previous Period End Time!');
          }
        }
      }
      old_end_time = time_end;
    })
    if (time_error) {
      toast.error('Time should be synchronous in order!');
      return false;
    }

    const fData = new FormData()
    // TIMINGS OF PERIODS
    fData.append('timetable_period_timings', JSON.stringify(timetable_period_timings))
    // EMPLOYEES AVAILABILITY
    fData.append('employee_timetable_availability', JSON.stringify(employee_timetable_availability))
    // CLASSES ASSIGNED TO
    fData.append('timetable', JSON.stringify(classesData.selected))
    fData.append('tp_id', this.state.timetablePeriods.tp_id)
    const res = await POST(
      'timetable/save-timetable-details',
      fData,
      {
        signal: this.saveTimetableDetailsReq.signal,
      }
    )
    if (!res.data.type) {
      if (res.data.data != null) {
        handleFormErrors(res.data.data)
      } else {
        toast[res.data.messageType](t(res.data.message))
      }
    } else {
      this.getTimetableDetails()
      // this.setState({
      //     modalEmployees: {
      //         ...this.state.modalEmployees,
      //         show: false,
      //         saving: false,
      //         data: null
      //     },
      //     employees: employees
      // })
      toast.success(t(res.data.message))
    }
  }

  removeTpt = (tpt_id) => {
    let timetable_period_timings = this.state.timetable_period_timings
    let employee_timetable_availability = this.state.employee_timetable_availability
    let find_timetable_period_timings = timetable_period_timings.findIndex((v) => tpt_id == v.tpt_id)
    timetable_period_timings.splice(find_timetable_period_timings, 1)

    employee_timetable_availability = employee_timetable_availability.filter((v) => v.tpt_id != tpt_id)
    this.setState({ timetable_period_timings, employee_timetable_availability })
    // AND REMOVE FROM TimetableDetails in Controller
  }

  setPeriodTypes = (periodTypes) => {
    this.setState({ periodTypes });
  }

  Dragula = () => {
    const $this = this;

    if (document.getElementById('drag-times')) {
      let options = {
      };
      this.drake = Dragula([document.getElementById('drag-times')], options);

      this.drake.on('drop', function (el, target, source) {
        // const uuid = el.dataset.uuid;
        // console.log('uuid', uuid);
        // console.log('timetable_period_timings', $this.state.timetable_period_timings);
        // console.log('document.getElementById(drag-times)', document.getElementById('drag-times').children);

        var order = [];

        for (var child in document.getElementById('drag-times').children) {
          order.push(document.getElementById('drag-times').children[child].dataset?.uuid);
        }

        const uuids = order.filter(item => item);
        let timetable_period_timings = $this.state.timetable_period_timings
        uuids.map((uuid, i) => {
          let findIndex_t = timetable_period_timings.findIndex(item => item.uuid == uuid)
          timetable_period_timings[findIndex_t].order_no = i + 1
        });

        const updatedOrder = uuids.map((uuid, index) => {
          return timetable_period_timings.find(item => item.uuid == uuid);
        });

        $this.setState({
          timetable_period_timings: updatedOrder
        });

      });


    }
  }

  handleClassesDDL = (options) => {

    const newSelection = options.filter(groupOption => !this.state.classesData.selected.includes(groupOption));

    this.setState({
      classesData: {
        ...this.state.classesData,
        selected: [
          ...this.state.classesData.selected,
          ...newSelection
        ]
      }
    });
  }

  render() {
    console.log('this.state', this.state)

    const { timetablePeriods, days, timetable_period_timings, classesData, employees, modalEmployees, showEmployeesView, employee_timetable_availability } = this.state
    const { defaultTheme } = this.props

    const classesGrouped = this.state.agegroups.map(item => {
      const options = this.state.classesData.options.filter(c => c.agegroup_id == item.agegroup_id);
      return {
        label: (() => {
          return (<div
            onClick={() => this.handleClassesDDL(options)}
            style={{
              padding: '10px 13px',
              color: '#fff',
              fontWeight: 'bold',
              backgroundColor: item.agegroup_color,
              cursor: 'pointer',
              margin: '0 -12px',
              fontSize: 15
            }}>{item.agegroup_code}</div>)
        })(),
        options
      }
    });

    console.log('classesGrouped', classesGrouped);

    return (
      <Container fluid>
        <div id='iedu-layout'>
          <NavBar />
          <div id='page-content'>
            <Header lite={true} heading={t('Timetable Management')} backBtn={true} showCenterSelector={true} />
            <div className='grey-section'>
              <TimetablePeriods timetablePeriods={timetablePeriods} setPeriodTypes={this.setPeriodTypes} />
            </div>
            <div className='grey-section'>
              <div className='grey-section-header'>
                <h5>
                  <span className='me-1'>{t('Period Timings')}</span>
                  <span className='mx-1' style={{ opacity: 0.6, fontWeight: 'normal' }}>|</span>
                  <span className='mx-1' style={{ opacity: 0.8, fontSize: '1rem', fontWeight: 'normal' }}>{t('Please add periods in timetable')}</span>
                </h5>
                <div className='d-flex flex-row align-items-center px-1 justify-content-end' style={{ flex: 1, }}>
                  <Link to={'/timetable-details-generator?id=' + timetablePeriods.tp_id}>
                    <Button variant='warning' className='me-2'>
                      {t('View Timetables')}
                    </Button>
                  </Link>
                  <Link to={'/timetables-generator?id=' + timetablePeriods.tp_id}>
                    <Button variant='warning' className='me-2'>
                      {t('Generate Timetables')}
                    </Button>
                  </Link>
                  <Button variant='success' onClick={() => this.saveTimetableDetails()}>{t('Save')}</Button>
                </div>
              </div>
              <div className='mt-1'>
                <Card>
                  <Card.Body>
                    {/* Classes */}
                    <div className='mt-2 border-top pt-2'>
                      <div className='grey-section-header'>
                        <h5>
                          <span className='me-1'>{t('Classes')}</span>
                          <span className='mx-1' style={{ opacity: 0.6, fontWeight: 'normal' }}>|</span>
                          <span className='mx-1' style={{ opacity: 0.8, fontSize: '1rem', fontWeight: 'normal' }}>{t('Assign classes for this Timetable.')}</span>
                        </h5>
                      </div>
                      <Form.Group className='mt-2' style={{ width: '100%' }}>
                        <Select
                          closeMenuOnSelect={false}
                          isMulti
                          placeholder={t('Select Classes...')}
                          options={classesGrouped}
                          onChange={(event) => {
                            this.setState({ classesData: { ...classesData, selected: event } })
                          }}
                          value={classesData.selected}
                          menuPortalTarget={document.body}
                          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999, }), control: (base) => ({ ...base, minHeight: 30 }) }}
                          formatOptionLabel={option => (
                            <div>
                              <span style={{ fontSize: 14 }}>{option.label}</span>
                            </div>
                          )}
                        />
                      </Form.Group>
                    </div>


                    {/* <div className='d-flex flex-row align-items-center px-1 justify-content-start mt-2' style={{ flex: 1 }}>
                                            <Form.Check type="switch" onChange={() => this.setState({ showEmployeesView: !showEmployeesView })} checked={showEmployeesView} />
                                            {<div style={{ color: (!showEmployeesView) ? brand_colors[defaultTheme.theme_id].color4 : brand_colors[defaultTheme.theme_id].color9, fontWeight: '600', fontSize: 14 }}>{t('All Employees are available anytime')}</div>}
                                            <div className='mx-1'> / </div>
                                            {<div style={{ color: (showEmployeesView) ? brand_colors[defaultTheme.theme_id].color4 : brand_colors[defaultTheme.theme_id].color9, fontWeight: '600', fontSize: 14 }}>{t('Employees are available for assigned times only')}</div>}
                                        </div> */}
                    {/* EMPLOYEES */}
                    {showEmployeesView &&
                      <div className='mt-2 border-top pt-2'>
                        <div className='grey-section-header'>
                          <h5>
                            <span className='me-1'>{t('Employees')}</span>
                            <span className='mx-1' style={{ opacity: 0.6, fontWeight: 'normal' }}>|</span>
                            <span className='mx-1' style={{ opacity: 0.8, fontSize: '1rem', fontWeight: 'normal' }}>{t('Please drag & drop Employees in Timetable according to their availability.')}</span>
                          </h5>
                          <div className='d-flex flex-row align-items-center px-1' style={{ flex: 1, }}>
                            <div className='d-flex flex-row'>
                              <div className='cb-expandable-search mx-1' style={{ width: 187 }}>
                                <input
                                  onChange={(e) => employees.changeSearch(e.target.value)}
                                  value={employees.search}
                                  type='text'
                                  style={{ width: 150, paddingLeft: 15, opacity: 1, backgroundColor: brand_colors[defaultTheme.theme_id].color7 }}
                                  placeholder={t('Search Employee')} />
                                <Button
                                  data-tooltip-id="tooltip"
                                  data-tooltip-content={t('Search')}
                                  data-tooltip-place="top"
                                  title={t('Search')}
                                  variant={(employees.search) ? 'danger' : 'primary'}
                                  onClick={() => employees.changeSearch('')}>
                                  <FontAwesomeIcon
                                    icon={employees.search ? faXmark : faSearch}
                                    color={brand_colors[defaultTheme.theme_id].color8}
                                  />
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div style={{ overflowX: 'auto', overflowY: 'hidden' }}>
                          <div className='employees-reel d-flex flex-row' id='employees-reel' ref={this.Dragula}>
                            {employees.data.map((v, i) => {
                              if (employees.search != '' && !(v.employee_name).toLowerCase().includes((employees.search).toLowerCase())) {
                                return null
                              }
                              return <div key={i} className='employee-reel-item' employee_id={v.employee_id} employee_name={v.employee_name} style={{
                                padding: '0.5rem', minWidth: '20vw', backgroundColor: brand_colors[this.props.defaultTheme.theme_id].color8, borderRadius: '1rem', margin: '1rem', position: 'relative', boxShadow: '-1px 2px 16px 2px rgba(0,0,0,0.1)', cursor: 'grab'
                              }}>
                                <div className='d-flex flex-column'>
                                  <div className='d-flex flex-row align-items-center'>
                                    <div className='d-flex flex-row' style={{ flex: 1 }}>
                                      <div className='d-flex align-items-center me-3'>
                                        <Image src={(v.employee_picture) ? portalURL + v.center_id + '/employees/' + v.employee_picture : 'https://via.placeholder.com/150x150?text=' + v.employee_name[0].toUpperCase()} style={{ objectFit: 'cover', width: '2rem', height: '2rem', backgroundColor: brand_colors[this.props.defaultTheme.theme_id].color8, borderRadius: '100%' }} />
                                      </div>
                                      <div className='d-flex align-items-start flex-column' style={{ flex: 1 }}>
                                        <div style={{ fontWeight: '600', fontSize: 13 }}>
                                          {v.employee_name}
                                        </div>
                                        {(v.role_id == 3 || v.role_id == 4)
                                          ? <Badge bg='success' style={{ fontSize: 12 }}>
                                            {v.role_name}
                                          </Badge>
                                          : <Badge bg='danger' style={{ fontSize: 12 }}>
                                            {v.role_name}
                                          </Badge>
                                        }

                                      </div>
                                    </div>
                                    <div style={{ fontSize: 12 }}>
                                      <FontAwesomeIcon title={t('Edit')} icon={faPencil} color={brand_colors[defaultTheme.theme_id].color16} className='cursor-pointer ms-3' onClick={() => modalEmployees.toggle(v.employee_name, {
                                        ...v,
                                        work_start_time: (v.work_start_time) ? moment(v.work_start_time, 'h:mm A').format('HH:mm') : '',
                                        work_end_time: (v.work_end_time) ? moment(v.work_end_time, 'h:mm A').format('HH:mm') : '',
                                        break_start_time: (v.break_start_time) ? moment(v.break_start_time, 'h:mm A').format('HH:mm') : '',
                                        break_end_time: (v.break_end_time) ? moment(v.break_end_time, 'h:mm A').format('HH:mm') : ''
                                      })} />
                                    </div>
                                  </div>
                                  <div className='mt-1 d-flex flex-row justify-content-between align-items-center'>
                                    <div style={{ fontWeight: '600', fontSize: 12 }}>{t('Work')}</div>
                                    <Badge bg='success'>{v.work_start_time}</Badge>
                                    <Badge bg='warning'>{v.work_end_time}</Badge>
                                  </div>
                                  <div className='mt-1 d-flex flex-row justify-content-between align-items-center'>
                                    <div style={{ fontWeight: '600', fontSize: 12 }}>{t('Break')}</div>
                                    <Badge bg='success'>{v.break_start_time}</Badge>
                                    <Badge bg='warning'>{v.break_end_time}</Badge>
                                  </div>
                                </div>
                              </div>
                            })}
                            <div className='px-2'></div>
                          </div>
                        </div>

                      </div>
                    }

                  </Card.Body>
                </Card>
              </div>

              {/* TIMETABLE PERIOD TIMINGS */}
              <div className='mt-1' style={{ width: (showEmployeesView) ? '100%' : '50%' }}>
                <Card>
                  <Card.Body>
                    <Table hover striped bordered size="sm" responsive>
                      <thead>
                        <tr>
                          <th className='text-center' style={{ width: '10vw' }}>
                            <div className='d-flex flex-column'>
                              {t('Time')}
                              <Button className='btn-sm btn-add' variant='success' onClick={() => {
                                this.setState({
                                  timetable_period_timings: timetable_period_timings.concat({
                                    uuid: chance.guid(),
                                    tp_id: timetablePeriods.tp_id,
                                    tpt_id: chance.unique(chance.integer, 1, { min: 99999999999, max: 999999999999 })[0],
                                    order_no: (timetable_period_timings.length > 0) ? parseInt(timetable_period_timings[timetable_period_timings.length - 1].order_no) + 1 : 1,
                                    time_start: (timetable_period_timings.length > 0) ? timetable_period_timings[timetable_period_timings.length - 1].time_end : '00:00',
                                    time_end: (timetable_period_timings.length > 0) ? timetable_period_timings[timetable_period_timings.length - 1].time_end : '00:00',
                                    tp_type_id: 0,
                                  })
                                })
                              }}>
                                {t('Add Period')}
                                <FontAwesomeIcon icon={faPlus} color={brand_colors[defaultTheme.theme_id].color8} style={{ fontSize: 16 }} className='mx-1' />
                              </Button>
                            </div>
                          </th>

                          {showEmployeesView && days.map((v, i) => {
                            return <th className={(v.active) ? 'text-center table-success' : 'text-center table-danger'}>{(v.name).slice(0, 3)}</th>
                          })}

                        </tr>
                      </thead>
                      <tbody id='drag-times' ref={this.Dragula}>
                        {timetable_period_timings.map((v, i) => {
                          return <tr data-uuid={v.uuid}>
                            <th>
                              <div className='d-flex flex-column '>

                                <div className='d-flex flex-row justify-content-between'>
                                  {/* DELETE TIMETABLE PERIOD TIMING*/}
                                  <div className='d-flex flex-column align-items-center justify-content-center cursor-pointer' style={{ padding: '0.5rem 1rem', }} onClick={() => this.removeTpt(v.tpt_id)}>
                                    <Image title={t('Remove')} src={require('../../assets/images/remove-red.png')} style={{ width: '1rem', height: '1rem' }} />
                                  </div>
                                  <div style={{
                                    paddingTop: 5,
                                    paddingRight: 10
                                  }}>
                                    <FontAwesomeIcon
                                      icon={faBars}
                                      color={'#000'}
                                    />
                                  </div>
                                </div>
                                <div className='d-flex flex-row align-items-center justify-content-center'>

                                  <Badge bg='success' className='my-1'>
                                    <Form.Control type="time" size='sm'
                                      value={v.time_start}
                                      onChange={(e) => this.setStateTime(i, 'time_start', e.target.value)} />
                                  </Badge>
                                  <Badge bg='warning' className='my-1'>
                                    <Form.Control type="time" size='sm'
                                      value={v.time_end}
                                      onChange={(e) => this.setStateTime(i, 'time_end', e.target.value)} />
                                  </Badge>
                                  <Badge
                                    style={{ flex: 1 }}
                                    bg='info' className='my-1'>
                                    <select
                                      value={v.tp_type_id || ''}
                                      onChange={(e) => this.setStateTime(i, 'tp_type_id', e.target.value)}
                                      style={{
                                        width: '100%',
                                        height: 31
                                      }}>
                                      <option value={0}>{t('Select Period Type')}</option>
                                      {this.state.periodTypes.map((item, index) => (
                                        <option key={index}
                                          value={item.tp_type_id}>{item.tp_type_name}</option>
                                      ))}
                                    </select>
                                  </Badge>

                                </div>
                              </div>
                            </th>
                            {
                              showEmployeesView &&
                              days.map((vv) => {
                                let this_employee_timetable_availability = employee_timetable_availability.filter((vvv) => vvv.tpt_id == v.tpt_id && vvv.day == vv.name.toLowerCase())
                                console.log('here', employee_timetable_availability, this_employee_timetable_availability, v.tpt_id, vv.name);
                                return <td className={(vv.active) ? 'drop-target' : 'no-drop table-danger'} day={vv.name.toLowerCase()} tpt_id={v.tpt_id}>
                                  {this_employee_timetable_availability.map((vvv, iii) => {
                                    return <Badge key={iii} style={{ cursor: 'grab' }} bg='success me-1 mb-1' eta_id={vvv.eta_id}>{vvv.employee_name}</Badge>
                                  })}
                                </td>
                              })
                            }
                          </tr>
                        })}
                      </tbody>
                    </Table>
                  </Card.Body>
                </Card>
              </div>

            </div>

          </div>
        </div>

        {/* MODAL UPDATE EMPLOYEE TIMMINGS*/}
        <Modal show={modalEmployees.show} onHide={() => modalEmployees.toggle()} >
          <Modal.Header closeButton>
            <Modal.Title>{modalEmployees.title}</Modal.Title>
          </Modal.Header>
          {modalEmployees.show &&
            <Modal.Body className='custom-styled-form'>
              <Row>
                <Col md="2">
                  <div>{t('Work')}</div>
                </Col>
                <Col md="5">
                  <Form.Group className='mb-3'>
                    <Form.Label>
                      <img src={imgDescription} />
                      {t('Start')}
                    </Form.Label>
                    <Form.Control type='time' value={modalEmployees.data.work_start_time} onChange={(event) => modalEmployees.setState('work_start_time', event.target.value)} />
                  </Form.Group>
                </Col>
                <Col md="5">
                  <Form.Group className='mb-3'>
                    <Form.Label>
                      <img src={imgDescription} />
                      {t('End')}
                    </Form.Label>
                    <Form.Control type='time' value={modalEmployees.data.work_end_time} onChange={(event) => modalEmployees.setState('work_end_time', event.target.value)} />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md="2">
                  <div>{t('Break')}</div>
                </Col>
                <Col md="5">
                  <Form.Group className='mb-3'>
                    <Form.Label>
                      <img src={imgDescription} />
                      {t('Start')}
                    </Form.Label>
                    <Form.Control type='time' value={modalEmployees.data.break_start_time} onChange={(event) => modalEmployees.setState('break_start_time', event.target.value)} />
                  </Form.Group>
                </Col>
                <Col md="5">
                  <Form.Group className='mb-3'>
                    <Form.Label>
                      <img src={imgDescription} />
                      {t('End')}
                    </Form.Label>
                    <Form.Control type='time' value={modalEmployees.data.break_end_time} onChange={(event) => modalEmployees.setState('break_end_time', event.target.value)} />
                  </Form.Group>
                </Col>
              </Row>
            </Modal.Body>
          }
          <Modal.Footer>
            <Button variant="secondary" onClick={() => modalEmployees.toggle()}>
              {t('Close')}
            </Button>
            <Button variant="primary" onClick={modalEmployees.save}>
              {t('Save Changes')}
            </Button>
          </Modal.Footer>
        </Modal>
      </Container >
    )
  }
}

const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  defaultLanguage: state.language.defaultLanguage,
  selectedClass: state.selectedClass.data,
  defaultTheme: state.theme.defaultTheme,
})


const mapDispatchToProps = () => ({
})
export default connect(mapStateToProps, mapDispatchToProps())(TimetableManagement)
