import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import logo2 from '../../../assets/images/logo-thumb2.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHomeUser, faCommentAlt, faDownload, faUser, faBook, faCalendarWeek, faTimeline, faCaretRight, faCog, faPuzzlePiece, faCalendarCheck, faGear, faGears, faUsers, faUserTie, faAddressCard, faCaretLeft, faCarSide, faFileCircleCheck, faPaste, faChartColumn, faChartPie, faComments, faToolbox, faBuildingUser, faPeopleGroup, faMessage, faBuildingColumns, faCoins, faGraduationCap, faHandHoldingDollar, faArrowsTurnRight, } from '@fortawesome/free-solid-svg-icons';
import { t } from '../../../helpers/translation_helper';
import { connect } from 'react-redux';
import { brand_colors } from '../../../helpers/brand_colors_helper';
import { ifNavAllowed } from '../../../helpers/general_helpers';
import { Tooltip as Tooltiper } from 'react-tooltip';
import { faCalendarDays } from '@fortawesome/free-regular-svg-icons';
const portalURL = process.env.REACT_APP_PORTAL_URL;
const appURL = process.env.REACT_APP_URL;

/**
 * Navigation Bar Partial Component
 */
class NavBar extends Component {

  state = {
    subMenuOffsetY: 300,
    menuData: [
      // {
      //   id: this.props.authData.redirectTo,
      //   name: 'Dashboard',
      //   path: this.props.authData.redirectTo,
      //   active: true,
      //   image: this.props.authData.centerData.logo,
      //   icon: null
      // },
      {
        id: 'admin-school-setup',
        name: 'School Setup',
        path: '/admin/school-setup',
        active: true,
        image: null,
        icon: faGear
      },
      {
        id: 'user-timeline',
        name: 'Communication',
        path: '/user-timeline',
        active: true,
        image: null,
        icon: faComments
      },
      {
        id: 'consolidated-communication',
        name: 'Consolidated Communication',
        path: '/consolidated-communication',
        active: true,
        image: null,
        icon: faComments
      },
      {
        id: 'alumni-communication',
        name: 'Alumni Communication',
        path: '/alumni-communication',
        active: true,
        image: null,
        icon: faComments
      },
      {
        id: 'lms',
        name: 'LMS',
        path: '/lms',
        active: true,
        image: null,
        icon: faGraduationCap
      },

      // MOVED TO STUDENTS ➡️
      // {
      //   id: 'alumni',
      //   name: 'Alumni',
      //   path: '/alumni',
      //   active: true,
      //   image: null,
      //   icon: faGraduationCap
      // },
      // {
      //   id: 'download-media',
      //   name: 'Download Media',
      //   path: '/download-media',
      //   active: true,
      //   image: null,
      //   icon: faDownload
      // },
      {
        id: 'hr-payroll',
        name: 'Team Affairs',
        path: '/hr-payroll',
        active: true,
        image: null,
        icon: faPeopleGroup
      },
      {
        id: 'payroll-main',
        name: 'Payroll',
        path: '/payroll-main',
        active: true,
        image: null,
        icon: faHandHoldingDollar
      },
      {
        id: 'weekly-lesson-plans',
        name: 'Weekly Lesson Plans',
        path: '/weekly-lesson-plans',
        active: false,
        image: null,
        icon: faCalendarWeek
      },
      // {
      //   id: ([3, 4, 15].includes(this.props.authData.loginData.role_id))
      //     ? 'students'
      //     : (ifNavAllowed('student-information')) ? 'student-information' : 'students',
      //   name: 'Student Affairs',
      //   path: ([3, 4, 15].includes(this.props.authData.loginData.role_id))
      //     ? '/students/information'
      //     : (ifNavAllowed('student-information')) ? '/students/student-information' : '/students/information',
      //   active: true,
      //   image: null,
      //   icon: faUsers
      // },
      {
        id: 'students-dashboard',
        name: 'Student Affairs',
        path: '/students-dashboard',
        active: true,
        image: null,
        icon: faUsers
      },
      {
        id: 'operations',
        name: 'Operations',
        path: '/operations',
        active: true,
        image: null,
        icon: faToolbox
      },
      {
        id: 'finance',
        name: 'Finance',
        path: '/finance-main',
        active: true,
        image: null,
        icon: faCoins
      },
      {
        id: 'early-childhood',
        name: 'Objectives & Journals',
        path: '/early-childhood',
        active: true,
        image: null,
        icon: faPuzzlePiece
      },
      {
        id: 'timetable-main',
        name: 'Timetable',
        path: '/timetable-main',
        active: true,
        image: null,
        icon: faCalendarDays
      },
      {
        id: 'student-pick-drop',
        name: 'Std Pick & Drop',
        path: '/student-pick-drop',
        active: true,
        image: null,
        icon: faCarSide
      },
      {
        id: 'report-center',
        name: 'Report Center',
        path: '/report-center',
        active: true,
        image: null,
        icon: faChartPie
      },
      {
        id: 'chat-monitor',
        name: 'Chat Monitor',
        path: '/chat-monitor',
        active: (this.props.authData.userData.employee_chat_monitor == 1 || this.props.authData.loginData.role_id == 22),
        image: null,
        icon: faMessage
      },
      {
        id: 'crm',
        name: 'CRM',
        path: '/crm/dashboard',
        active: true,
        image: null,
        icon: faAddressCard
      },
      {
        id: 'appointments',
        name: 'Appointments',
        path: '/appointments',
        active: true,
        image: null,
        icon: faCalendarCheck
      },
      {
        id: 'withdrawal',
        name: 'Withdrawal',
        path: '/withdrawal',
        active: true,
        image: null,
        icon: faArrowsTurnRight
      },
    ]
  }

  handleNavItemHover = (e) => {
    this.setState({ subMenuOffsetY: e.target.getBoundingClientRect().top + 9 });
  }

  render() {
    const { defaultTheme } = this.props;
    const { role_id } = this.props.authData.loginData;
    const { employee_chat_monitor } = this.props.authData.userData;
    const { lang_orientation: dir } = this.props.defaultLanguage;

    return (
      <aside id='navbar'>
        <div className='blue-bar'>
          <NavLink //USER WITH CLASSES ASSIGNMENT e.g TEACHER
            title={t('Dashboard')}
            activeclassname='active'
            to={this.props.authData.redirectTo}>
            <div
              className='logo'
              style={{
                backgroundImage:
                  'url(' +
                  this.props.authData.centerData.logo +
                  ')',
                backgroundSize: '100% auto',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center center',
                marginBottom: 10,
              }}></div>
          </NavLink>



          <div style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            flex: 1,
            overflowY: 'auto',
            overflowX: 'hidden',
            width: '100%'
          }}>
            <ul>
              {this.state.menuData.map((v, i) => {
                // console.log('ifNavAllowed', v.id, ifNavAllowed(v.id));
                if (ifNavAllowed(v.id) && v.active) {
                  return <li key={i}>
                    <NavLink
                      activeclassname='active'
                      to={v.path}>
                      <FontAwesomeIcon icon={v.icon} color={brand_colors[defaultTheme.theme_id].color8} />
                      <div>{t(v.name)}</div>
                    </NavLink>
                  </li>
                }
              })}

              {/* SAMPLE MULTI MENU */}
              {/* <li className='has-children'>
                <div className='position-relative'>
                  <NavLink
                    onMouseEnter={this.handleNavItemHover}
                    data-tooltip-id="tooltip"
                    data-tooltip-content={t('Sample Nav')}
                    data-tooltip-place="top"
                    to='/'>
                    <FontAwesomeIcon
                      icon={faCog}
                      color={brand_colors[defaultTheme.theme_id].color8}
                    />
                  </NavLink>
                  <FontAwesomeIcon
                    icon={faCaretRight}
                    color={brand_colors[defaultTheme.theme_id].color8}
                    style={{ fontSize: 15, position: 'absolute', top: 18, right: 15 }}
                  />
                </div>
                <ul className='children' style={{ top: this.state.subMenuOffsetY }}>
                  <li>
                    <NavLink
                      to='/'>
                      Link 1
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to='/'>
                      Link 2
                    </NavLink>
                    <FontAwesomeIcon
                      icon={faCaretRight}
                      color={brand_colors[defaultTheme.theme_id].color8}
                      style={{ fontSize: 15, position: 'absolute', top: 18, right: 15 }}
                    />
                    <ul className='children'>
                      <li>
                        <NavLink
                          to='/'>
                          Link 1
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to='/'>
                          Link 2
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to='/'>
                          Link 3
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <NavLink
                      to='/'>
                      Link 3
                    </NavLink>
                  </li>
                </ul>
              </li> */}
            </ul>
          </div>
          <div className='logo' title='Educore Systems and Technologies FZE' style={{ marginBottom: 20, marginTop: 10 }}>
            <a
              href='https://educore.io/' target='_blank'>
              <img src={logo2} alt='Educore' />
            </a>
          </div>
        </div>
        {/* <Chats /> */}
        <Tooltiper id="tooltip" />
      </aside >
    );
  }
}

const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  selectedClass: state.selectedClass.data,
  languages: state.language.languages,
  defaultLanguage: state.language.defaultLanguage,
  defaultTheme: state.theme.defaultTheme
});

export default connect(mapStateToProps, null)(NavBar);
