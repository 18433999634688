import React, { Component } from 'react';
import { Container, Card, Row, Col, ButtonGroup, Button, DropdownButton, Dropdown, Form, Modal, Spinner, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faCheckCircle, faSearch, faUser, faXmark, faLayerGroup, faHandsHoldingChild, faPeopleGroup, faListCheck, faPrint, faPencil, faTrash, faCheckDouble, faSortDown, faSortUp, faThumbsUp, faThumbsDown } from '@fortawesome/free-solid-svg-icons';
import { faEye, faEyeSlash, faFileExcel, faFilePdf } from '@fortawesome/free-regular-svg-icons';
import { t } from '../../helpers/translation_helper';
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import Select from 'react-select';
import NavBar from '../_partials/NavBar/_NavBar';
import Header from '../_partials/Header/_Header';
import { connect } from 'react-redux';
import moment from 'moment';
import { brand_colors } from '../../helpers/brand_colors_helper';
import { GET, POST } from '../../api';
import { toast } from 'react-toastify';
import { handleFormErrors } from '../../helpers/form_helpers';
import Flatpickr from 'react-flatpickr';
import axios from 'axios';
import { renderTableBody, renderTableHead } from '../../helpers/table_helper';
let cancelToken;

class Services extends Component {

  searchFieldRef = React.createRef();

  servicesTypes = [
    { value: 0, label: 'Service' },
    { value: 1, label: 'Inventory' },
    { value: 2, label: 'Resources' },
    { value: 3, label: 'Assets' },
  ];

  taxExempt = [
    { value: 0, label: 'Not Exempted' },
    { value: 1, label: 'Tax Exempt' },
  ];

  depTypes = [
    { value: 'month', label: 'Months' },
    { value: 'year', label: 'Year' },
  ];

  defaultFormFields = {
    services_id: 0,
    services_name: '',
    services_fee: '',
    services_cost: '',
    services_type: 0,
    services_vat: '',
    expats_only: 0,
    tax_exempt: 0,
    chapt_id: '',
    acc_num: '',
    qb_account: '',
    cogs_chapt_id: '',
    stock_chapt_id: '',
    cogs_chapt_id_disabled: false,
    stock_chapt_id_disabled: false,
    services_moq: '',
    services_desc: '',
    sad_purchase_date: '',
    sad_purchase_price: '',
    sad_warranty_expire: '',
    sad_useful_life: '',
    sad_residual_value: '',
    sad_serial_number: '',
    sad_depreciation_type: '',
    sad_depreciation_rate: '',
    cloc_id: '',
  }

  state = {
    ...this.defaultFormFields,
    isEditing: false,
    working: false,
    cdt: {
      fetching: false,
      search: '',
      rows: 10,
      offset: 0,
      order: 'services.services_id',
      dir: 'DESC',
      lastPage: 0,
      page: 0,
      totalRecords: 0,
      rowsOptions: [10, 25, 50, 'All'],
      columns: [
        {
          id: 'services.services_id',
          column: 'services_id',
          name: 'Actions',
          visibility: true
        },
        {
          id: 'services.services_name',
          column: 'services_name',
          name: 'Name',
          visibility: true
        },
        {
          id: 'services.services_fee',
          column: 'services_fee',
          name: 'Fee',
          visibility: true
        },
        {
          id: 'services.services_desc',
          column: 'services_desc',
          name: 'Description',
          visibility: true
        },
        {
          id: 'services.acc_num',
          column: 'acc_num',
          name: 'Account',
          visibility: true
        },
        {
          id: 'chapters.chapt_desc',
          column: 'chapt_desc',
          name: 'Chart',
          visibility: true
        },
        {
          id: 'services.services_type',
          column: 'services_type',
          name: 'Type',
          visibility: true
        },
        {
          id: 'services.services_vat',
          column: 'services_vat',
          name: 'VAT',
          visibility: true
        },
        {
          id: 'services.services_cost',
          column: 'services_cost',
          name: 'Cost',
          visibility: true
        },
        {
          id: 'services.qb_account',
          column: 'qb_account',
          name: '3rd Part Account',
          visibility: true
        },
        {
          id: 'services.tax_exempt',
          column: 'tax_exempt',
          name: 'Tax Exempt',
          visibility: true
        },
      ],
      data: [],
    },
    showFormModal: false,
    chapters: [],
    cogs: [],
    stocks: [],
    assetLocations: [],
  }

  componentDidMount() {
    const {
      search,
      rows: limit,
      order,
      dir
    } = this.state.cdt;
    this.fetch(search, limit, order, dir, 0);
    this.getSetDDLs();
  }

  getSetDDLs = async () => {

    try {
      const response = await axios.all([
        GET('services/get-chapters'),
        GET('services/get-chapters-cogs'),
        GET('services/get-chapters-stock'),
        GET('services/get-center-locations'),
      ]);

      // Chapters
      const chapters = response[0].data.map((item) => {
        return {
          value: item.chapt_id,
          label: item.chapt_desc,
        }
      });

      // COGS
      const cogs = response[1].data.map((item) => {
        return {
          value: item.chapt_id,
          label: item.chapt_desc,
        }
      });

      // Stocks
      const stocks = response[2].data.map((item) => {
        return {
          value: item.chapt_id,
          label: item.chapt_desc,
        }
      });

      // Asset Locations
      const assetLocations = response[3].data.map((item) => {
        return {
          value: item.cloc_id,
          label: item.cloc_name,
        }
      });

      this.setState({
        chapters,
        cogs,
        stocks,
        assetLocations,
      });

    } catch (err) {
      console.log('err', err)

    }


  }

  fetch = async (search, limit, order, dir, page) => {

    this.setState({
      cdt: {
        ...this.state.cdt,
        fetching: true,
      }
    });

    if (cancelToken) {
      cancelToken.abort();
    }
    cancelToken = new AbortController();

    try {
      const res = await GET('services/get', {
        signal: cancelToken.signal,
        params: {
          search,
          order,
          dir,
          limit,
          offset: parseInt(page) * parseInt(limit)
        },
      });

      if (res.status === 200) {
        this.setState({
          cdt: {
            ...this.state.cdt,
            fetching: false,
            search,
            rows: parseInt(limit),
            offset: parseInt(page) * parseInt(limit),
            order,
            dir,
            page,
            totalRecords: parseInt(res.data.total_count_filtered),
            lastPage: Math.ceil(parseInt(res.data.total_count_filtered) / parseInt(limit)),
            data: res.data.data,
          }
        });
      }

    } catch (err) {
      console.log('err', err)
      this.setState({
        cdt: {
          ...this.state.cdt,
          fetching: false,
        }
      });

    }
  }

  showHideColumn = (column_index) => {
    const columns = this.state.cdt.columns;
    columns[column_index].visibility = !columns[column_index].visibility;
    this.setState({ ctd: { ...this.state.ctd, columns } })
  }

  handleOpenFormModal = () => {
    this.setState({
      showFormModal: true
    });
  }

  handleCloseFormModal = () => {
    this.setState({
      showFormModal: false,
      isEditing: false,
      ...this.defaultFormFields
    });
  }

  saveUpdate = async () => {

    const { authData } = this.props;
    const { center_id } = authData.centerData;

    this.setState({ working: true });

    const {
      services_id,
      services_name,
      services_fee,
      services_cost,
      services_type,
      services_vat,
      expats_only,
      tax_exempt,
      chapt_id,
      acc_num,
      qb_account,
      cogs_chapt_id,
      stock_chapt_id,
      services_moq,
      services_desc,
      sad_purchase_date,
      sad_purchase_price,
      sad_warranty_expire,
      sad_useful_life,
      sad_residual_value,
      sad_serial_number,
      sad_depreciation_type,
      sad_depreciation_rate,
      cloc_id,
    } = this.state;

    const fData = new FormData();
    fData.append('center_id', center_id);
    fData.append('services_id', services_id);
    fData.append('ServicesData', JSON.stringify({
      services_name,
      services_fee,
      services_cost: services_cost ? services_cost : 0,
      services_type,
      services_vat: services_vat ? services_vat : 0,
      expats_only,
      tax_exempt,
      chapt_id: chapt_id.value,
      acc_num,
      qb_account,
      cogs_chapt_id: cogs_chapt_id ? cogs_chapt_id.value : 0,
      stock_chapt_id: stock_chapt_id ? stock_chapt_id.value : 0,
      services_moq,
      services_desc,
    }));
    if (services_type == 3) {
      fData.append('ServiceAssetDetail', JSON.stringify({
        sad_purchase_date,
        sad_purchase_price,
        sad_warranty_expire,
        sad_useful_life,
        sad_residual_value,
        sad_serial_number,
        sad_depreciation_type: sad_depreciation_type.value,
        sad_depreciation_rate,
        cloc_id: cloc_id.value,
      }));
    }

    try {
      const res = await POST('services/save', fData);
      if (res.data.type) {
        this.setState({ working: false });
        toast.success(t(res.data.message));
        this.fetch('', this.state.cdt.rows, this.state.cdt.order, this.state.cdt.dir, 0);
        this.handleCloseFormModal();
      } else {
        // toast.error(t(res.data.message));
        handleFormErrors(res.data.data)
        this.setState({ working: false });
      }
    } catch (err) {
      console.log('err', err)
      toast.error(t('Something went wrong!'));

      this.setState({ working: false });
    }

  }

  edit = (data) => {



    this.setState({
      ...this.state,
      ...data,
      chapt_id: { value: data.chapt_id, label: data.chapt_desc },
      cogs_chapt_id: this.getSelectValue(data.cogs_chapt_id, 'cogs'),
      stock_chapt_id: this.getSelectValue(data.stock_chapt_id, 'stocks'),
      cogs_chapt_id_disabled: data.cogs_chapt_id != 0,
      stock_chapt_id_disabled: data.stock_chapt_id != 0,
      sad_depreciation_type: data.sad_depreciation_type ? ({ value: data.sad_depreciation_type, label: data.sad_depreciation_type.charAt(0).toUpperCase() + data.sad_depreciation_type.slice(1) }) : null,
      cloc_id: data.cloc_id ? this.getSelectValue(data.cloc_id, 'assetLocations') : null,
      isEditing: true,
    });

    this.handleOpenFormModal();
  }

  delete = async (services_id) => {
    const { authData } = this.props;
    const { center_id } = authData.centerData;

    const fData = new FormData();
    fData.append('center_id', center_id);
    fData.append('services_id', services_id);

    try {
      const res = await POST('services/delete', fData);
      if (res.data.type) {
        toast.success(t(res.data.message));
        this.fetch(this.state.cdt.search, this.state.cdt.rows, this.state.cdt.order, this.state.cdt.dir, this.state.cdt.page);
      } else {
        toast.error(t(res.data.message));
      }
    } catch (err) {
      console.log('err', err)
      toast.error(t('Something went wrong!'));

      this.setState({ working: false });
    }
  }

  getSelectValue = (value, list) => {
    if (value != 0) {
      return {
        value,
        label: this.state[list].find(item => item.value == value).label
      };
    } else {
      return null
    }
  }

  exportExcel = async () => {

    const excelData = this.state.cdt.data.map((item) => {
      const row = {};
      this.state.cdt.columns
        .filter((column) => column.visibility)
        .map((column) => {
          if (column.name !== 'Actions') {
            row[column.name] = item[column.column];
          }
        });
      return row;
    });

    const fileName = 'Services Export ' + moment().format('DD-MM-YYYY');
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const ws = XLSX.utils.json_to_sheet(excelData);

    const cellStyle = {
      font: {
        bold: true,
      },
    }

    Array.from('ABCEFGHIJ').map((letter) => {
      ws[letter + "1"].s = cellStyle;
    });

    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });

    FileSaver.saveAs(data, fileName + fileExtension);
  }

  renderCell = (column_data, column_id, column_index, dataObj) => {
    const { authData } = this.props;
    const { center_id } = authData.centerData;

    let tdData;

    // ------------------------------------------------
    if (column_id == 'services_id') { // Action Buttons
      tdData = <>
        <Button
          onClick={() => this.edit(dataObj)}
          variant="warning" size='sm' className='me-1'>
          <FontAwesomeIcon icon={faPencil} />
        </Button>
        <Button
          onClick={() => this.delete(column_data)}
          variant="danger" size='sm' className='me-1'>
          <FontAwesomeIcon icon={faTrash} />
        </Button>
      </>;
      // ----------------------------------------------------
    } else if (column_id == 'services_type') { // Services Type
      tdData = this.servicesTypes.find(item => item.value == column_data).label;
      // -----------------------------------------------
    } else if (column_id == 'tax_exempt') { // Tax Exempt
      tdData = this.taxExempt.find(item => item.value == column_data).label;
      // ---
    } else {
      tdData = column_data;
    }


    return <td key={column_index}>{tdData}</td>;
  }

  render() {
    const { cdt } = this.state
    return (
      <Container fluid>
        <div id='iedu-layout'>
          <NavBar />
          <div id='page-content'>
            <Header lite={true} heading={t('Services')} backBtn={true} />
            <div className='grey-section'>
              <Card
                className='border-0'
                style={{ borderRadius: '20px' }}>
                <Card.Body>
                  <Row>
                    <Col md={6}>
                      <ButtonGroup size='sm' className='me-3 float-left'>
                        {/* <Button><FontAwesomeIcon icon={faFilePdf} /> {t('PDF')}</Button> */}
                        <Button onClick={() => this.exportExcel()}>
                          <FontAwesomeIcon icon={faFileExcel} /> {t('Excel')}
                        </Button>
                        {/* <Button><FontAwesomeIcon icon={faPrint} /> {t('Print')}</Button> */}
                        <DropdownButton
                          autoClose={'outside'}
                          size='sm'
                          as={ButtonGroup}
                          title={t('Column Visibility')}>
                          {this.state.cdt.columns.map((column, column_index) => (
                            <Dropdown.Item key={column_index} className={column.visibility ? 'column-name-item' : 'column-name-item active'} onClick={() => this.showHideColumn(column_index)}>
                              <FontAwesomeIcon icon={column.visibility ? faEye : faEyeSlash} /> {column.name}
                            </Dropdown.Item>
                          ))}
                        </DropdownButton>
                      </ButtonGroup>
                      <div style={{ display: 'inline-block' }}>
                        {t('Show')}
                        <Form.Select
                          value={this.props.employeesListRows}
                          size="sm"
                          onChange={(e) => this.fetch(this.state.cdt.search, e.target.value, this.state.cdt.order, this.state.cdt.dir, this.state.cdt.page)}
                          style={{ width: 70, marginInline: 5, display: 'inline-block' }}>
                          {this.state.cdt.rowsOptions.map((row, row_index) => (<option key={row_index} >{row}</option>))}
                        </Form.Select>
                        {t('Records')}
                      </div>
                    </Col>
                    <Col md={6} className='d-flex justify-content-end'>
                      <div className='cdt-search'>
                        <input type='text'
                          ref={this.searchFieldRef}
                          defaultValue={this.state.cdt.search}
                          onChange={(e) => this.fetch(e.target.value, this.state.cdt.rows, this.state.cdt.order, this.state.cdt.dir, 0)}
                          placeholder={t('Search...')} />
                        <button
                          onClick={() => {
                            this.fetch('', this.state.cdt.rows, this.state.cdt.order, this.state.cdt.dir, 0);
                            this.searchFieldRef.current.value = '';
                          }}>
                          <FontAwesomeIcon
                            icon={this.state.cdt.search == '' ? faSearch : faXmark}
                            color={brand_colors[this.props.defaultTheme.theme_id].color18}
                          />
                        </button>
                      </div>
                      <button
                        onClick={this.handleOpenFormModal}
                        className='btn btn-success btn-sm ms-3'><FontAwesomeIcon icon={faPlus} color={brand_colors[this.props.defaultTheme.theme_id].color8} style={{ fontSize: 12 }} />{' '}{t('Add Service')}</button>
                    </Col>
                    {this.state.cdt.fetching &&
                      <Col md={12} className='py-5 d-flex flex-column align-items-center'>
                        <Spinner as='span' animation='grow' size='sm' /> {t('Loading Data')}
                      </Col>
                    }
                    {!this.state.cdt.fetching && this.state.cdt.data.length === 0 &&
                      <Col md={12} className='py-5 d-flex flex-column align-items-center'>
                        {t('No Data Found')}
                      </Col>
                    }
                    {!this.state.cdt.fetching && this.state.cdt.data.length !== 0 &&
                      <Col md={12} className='pt-2'>
                        <div id="datatable-list" className='mb-3'>
                          <Table responsive>
                            {renderTableHead(this)}
                            {renderTableBody(this)}
                          </Table>
                        </div>
                        {this.state.cdt.rows < this.state.cdt.totalRecords &&
                          <ButtonGroup className='pagination'>
                            {Array.from({ length: this.state.cdt.lastPage }).map((_, index) => (
                              <Button
                                key={index}
                                variant="light"
                                onClick={() => this.fetch(this.state.cdt.search, this.state.cdt.rows, this.state.cdt.order, this.state.cdt.dir, index)}
                                className={this.state.cdt.page === index ? 'active' : ''}>{index + 1}</Button>
                            ))}
                          </ButtonGroup>
                        }
                      </Col>
                    }
                  </Row>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
        <Modal size='lg' show={this.state.showFormModal} onHide={this.handleCloseFormModal}>
          <Modal.Header closeButton>
            <Modal.Title>{this.state.isEditing ? t('Edit') : t('Add')} {t('Service')}</Modal.Title>
          </Modal.Header>
          <Modal.Body className='custom-styled-form'>
            <Row>
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>{t('Name')}</Form.Label>
                  <Form.Control type="text"
                    defaultValue={this.state.services_name}
                    onBlur={(e) => this.setState({ services_name: e.target.value })} />
                </Form.Group>
              </Col>
              <Col md={2}>
                <Form.Group className="mb-3">
                  <Form.Label>{t('Fee')}</Form.Label>
                  <Form.Control type="text"
                    defaultValue={this.state.services_fee}
                    onBlur={(e) => this.setState({ services_fee: e.target.value })}
                    placeholder='0.00'
                  />
                </Form.Group>
              </Col>
              <Col md={2}>
                <Form.Group className="mb-3">
                  <Form.Label>{t('Cost')}</Form.Label>
                  <Form.Control type="text"
                    defaultValue={this.state.services_cost}
                    onBlur={(e) => this.setState({ services_cost: e.target.value })}
                    placeholder='0.00'
                  />
                </Form.Group>
              </Col>
              <Col md={2}>
                <Form.Group className="mb-3">
                  <Form.Label>{t('VAT')}</Form.Label>
                  <Form.Control type="text"
                    defaultValue={this.state.services_vat}
                    onBlur={(e) => this.setState({ services_vat: e.target.value })}
                    placeholder='0.00'
                  />
                </Form.Group>
              </Col>
              <Col md={2} className='ps-0'>
                <Form.Group className="mb-3">
                  <Form.Label>{t('Expats Only')}</Form.Label>
                  <div className='p-3 pb-0'>
                    <Form.Check
                      type="switch"
                      id="expats_only"
                      label={false}
                      onChange={() => this.setState({ expats_only: this.state.expats_only == 1 ? 0 : 1 })}
                      checked={this.state.expats_only == 1}
                    />
                  </div>
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>{t('Chart')}</Form.Label>
                  <Select
                    value={this.state.chapt_id}
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (
                        base
                      ) => ({
                        ...base,
                        zIndex: 9999,
                      }),
                    }}
                    options={this.state.chapters}
                    onChange={(obj) => this.setState({ chapt_id: obj })}
                    isDisabled={this.state.isEditing}
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>{t('Account')}</Form.Label>
                  <Form.Control type="text"
                    defaultValue={this.state.acc_num}
                    onBlur={(e) => this.setState({ acc_num: e.target.value })}
                    readOnly
                    placeholder={t('Auto-Generate')}
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>{t('3rd Party Account')}</Form.Label>
                  <Form.Control type="text"
                    defaultValue={this.state.qb_account}
                    onBlur={(e) => this.setState({ qb_account: e.target.value })}
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>{t('COGS Account')}</Form.Label>
                  <Select
                    value={this.state.cogs_chapt_id}
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (
                        base
                      ) => ({
                        ...base,
                        zIndex: 9999,
                      }),
                    }}
                    options={this.state.cogs}
                    onChange={(obj) => this.setState({ cogs_chapt_id: obj })}
                    isDisabled={this.state.cogs_chapt_id_disabled}
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>{t('Stock Account')}</Form.Label>
                  <Select
                    value={this.state.stock_chapt_id}
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (
                        base
                      ) => ({
                        ...base,
                        zIndex: 9999,
                      }),
                    }}
                    options={this.state.stocks}
                    onChange={(obj) => this.setState({ stock_chapt_id: obj })}
                    isDisabled={this.state.stock_chapt_id_disabled}
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>{t('MOQ')}</Form.Label>
                  <Form.Control type="text"
                    defaultValue={this.state.services_moq}
                    onBlur={(e) => this.setState({ services_moq: e.target.value })}
                  />
                </Form.Group>
              </Col>
              <Col md={12}>
                <Form.Group className="mb-3">
                  <Form.Label>{t('Description')}</Form.Label>
                  <Form.Control type="text"
                    defaultValue={this.state.services_desc}
                    onBlur={(e) => this.setState({ services_desc: e.target.value })}
                  />
                </Form.Group>
              </Col>
              <Col md={7} className='px-0'>
                <Form.Group className="mb-3">
                  <Form.Label>{t('Type')}</Form.Label>
                  <div className="p-3 pb-0">
                    {this.servicesTypes.map((item, index) => (
                      <Form.Check
                        key={index}
                        className='me-4'
                        inline
                        label={t(item.label)}
                        name="services_type"
                        type='radio'
                        id={`services_type_${index}`}
                        onChange={(e) => this.setState({ services_type: item.value })}
                        checked={this.state.services_type == item.value}
                      />
                    ))}
                  </div>
                </Form.Group>
              </Col>
              <Col md={5} className='px-0'>
                <Form.Group className="mb-3">
                  <Form.Label>{t('Tax Exempt')}</Form.Label>
                  <div className="p-3 pb-0">
                    {this.taxExempt.map((item, index) => (
                      <Form.Check
                        key={index}
                        className='me-4'
                        inline
                        label={t(item.label)}
                        name="tax_exempt"
                        type='radio'
                        id={`tax_exempt_${index}`}
                        onChange={(e) => this.setState({ tax_exempt: item.value })}
                        checked={this.state.tax_exempt == item.value}
                      />
                    ))}
                  </div>
                </Form.Group>
              </Col>
              {this.state.services_type == 3 &&
                <>
                  <Col md={12}><hr /></Col>
                  <Col md={12}><h5 className='mb-0'>{t('Assets Details')}</h5></Col>
                  <Col md={4}>
                    <Form.Group className="mb-3">
                      <Form.Label>{t('Purchase Date')}</Form.Label>
                      <Flatpickr
                        className='form-control'
                        value={this.state.sad_purchase_date}
                        onChange={([
                          date,
                        ]) => {
                          this.setState(
                            {
                              sad_purchase_date:
                                moment(
                                  date
                                ).format(
                                  'YYYY-MM-DD'
                                ),
                            }
                          );
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group className="mb-3">
                      <Form.Label>{t('Purchase Price')}</Form.Label>
                      <Form.Control type="text"
                        defaultValue={this.state.sad_purchase_price}
                        onBlur={(e) => this.setState({ sad_purchase_price: e.target.value })}
                        placeholder='0.00'
                      />
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group className="mb-3">
                      <Form.Label>{t('Warranty Expiry Date')}</Form.Label>
                      <Flatpickr
                        className='form-control'
                        value={this.state.sad_warranty_expire}
                        onChange={([
                          date,
                        ]) => {
                          this.setState(
                            {
                              sad_warranty_expire:
                                moment(
                                  date
                                ).format(
                                  'YYYY-MM-DD'
                                ),
                            }
                          );
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group className="mb-3">
                      <Form.Label>{t('Useful Life (Months)')}</Form.Label>
                      <Form.Control type="number"
                        defaultValue={this.state.sad_useful_life}
                        onChange={(e) => this.setState({ sad_useful_life: e.target.value })}
                        min={0}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group className="mb-3">
                      <Form.Label>{t('Residual Value')}</Form.Label>
                      <Form.Control type="text"
                        defaultValue={this.state.sad_residual_value}
                        onBlur={(e) => this.setState({ sad_residual_value: e.target.value })}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group className="mb-3">
                      <Form.Label>{t('Serial Number')}</Form.Label>
                      <Form.Control type="text"
                        defaultValue={this.state.sad_serial_number}
                        onBlur={(e) => this.setState({ sad_serial_number: e.target.value })}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group className="mb-3">
                      <Form.Label>{t('Depreciation Type')}</Form.Label>
                      <Select
                        value={this.state.sad_depreciation_type}
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (
                            base
                          ) => ({
                            ...base,
                            zIndex: 9999,
                          }),
                        }}
                        options={this.depTypes}
                        onChange={(obj) => this.setState({ sad_depreciation_type: obj })}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group className="mb-3">
                      <Form.Label>{t('Depreciation Rate')}</Form.Label>
                      <Form.Control type="text"
                        defaultValue={this.state.sad_depreciation_rate}
                        onBlur={(e) => this.setState({ sad_depreciation_rate: e.target.value })}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group className="mb-3">
                      <Form.Label>{t('Location of Asset')}</Form.Label>
                      <Select
                        value={this.state.cloc_id}
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (
                            base
                          ) => ({
                            ...base,
                            zIndex: 9999,
                          }),
                        }}
                        options={this.state.assetLocations}
                        onChange={(obj) => this.setState({ cloc_id: obj })}
                      />
                    </Form.Group>
                  </Col>
                </>
              }
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleCloseFormModal}>
              {t('Close')}
            </Button>
            <Button disabled={this.state.working} variant='success' onClick={() => this.saveUpdate()}>
              {t('Save')} {this.state.working ? <Spinner as='span' animation='grow' size='sm' role='status' aria-hidden='true' /> : ''}
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  defaultLanguage: state.language.defaultLanguage,
  defaultTheme: state.theme.defaultTheme,
});

export default connect(mapStateToProps, null)(Services);
