import { useCallback, useEffect, useState } from "react";
import { store } from "../redux/store";
import { t } from "./translation_helper";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faCircle, faCircleCheck, faCircleDot,
  faCircleLeft,
  faCircleStop,
  faCircleUp,
  faCircleUser, faDotCircle
} from "@fortawesome/free-regular-svg-icons";
var Buffer = require('buffer/').Buffer  // note: the trailing slash is important!

/**
 * General Helpers
 */
export const trimThisDown = (text, limit) => {
  return text.length > limit ? text.substring(0, limit) + '...' : text;
};
export const getExtension = (name) => {
  if (name && name !== 'null' && name !== '' && name !== 'NULL') {
    let nameSplit = name.split('.')
    if (nameSplit.length < 2) {
      return name
    }
    let ext = nameSplit[1].toLowerCase()
    if (ext == 'pdf') {
      return 'pdf'
    } else if (ext == 'wav' || ext == 'm4a' || ext == 'mp3' || ext == 'mp4') {
      return 'audio'
    } else {
      return 'image'
    }
  }
  else {
    return name
  }
}

export const generateColors = (count) => {
  const colors = [];
  for (let i = 0; i < count; i++) {
    const hue = (i * 360) / count; // Distribute hues evenly
    const color = `hsl(${hue}, 70%, 50%)`; // Use HSL to generate colors
    colors.push(color);
  }
  return colors;
};

export const getUrlParam = () => {
  var vars = {};
  window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
    vars[key] = value;
  });
  return vars;
}

export const ifNavAllowed = (link) => {
  // const authData = JSON.parse(localStorage.getItem('authData'));
  // DEVELOPMENT
  // return true
  let state = store.getState()
  let authData = state.auth.authData

  if (authData) {
    const { allowedNavs, loginData } = authData;
    if (loginData.role_id == 22) {
      return true
    }
    else if (allowedNavs) {
      return allowedNavs.find((nav) => nav.link === link);
    } else {
      return true
    }
  } else {
    return true
  }
}



export function renderChildName(child_name, child_name_2, family_name, family_name_2) {
  let state = store.getState()
  let { child_name_column, concat_family_name } = state.preferences
  let name = ''
  if (child_name_column == 1 && child_name_2 && child_name_2 != "") {
    name = child_name_2
  } else {
    name = child_name
  }

  if (concat_family_name == 1 && child_name_column == 1 && family_name_2 && family_name_2 != "") {
    name += ' ' + family_name_2
  }
  else if (concat_family_name == 1 && child_name_column == 0 && family_name && family_name != "") {
    name += ' ' + family_name
  }
  else if (concat_family_name == 1 && family_name && family_name != "") {
    name += ' ' + family_name
  }
  return name
}
export function uniqueByAttribute(x, f) {
  return Object.values(x.reduce((a, b) => ((a[f(b)] = b), a), {}));
}

export function uniqueByAttribute2(arr, props = []) {
  return [...new Map(arr.map(entry => [props.map(k => entry[k]).join('|'), entry])).values()]
}



export function isTeacher(role_id) {
  return 3 == role_id;
}

export function changeColorOpacity(color, opacity) {
  const hexRegex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;
  const rgbRegex = /^rgb(a)?\(\s*((25[0-5]|2[0-4]\d|1\d{2}|\d{1,2})\s*,\s*){2}(25[0-5]|2[0-4]\d|1\d{2}|\d{1,2})\s*(,\s*\d+(\.\d+)?)?\)$/i;
  const hslRegex = /^hsla?\(\s*((360|3[0-5]\d|[12]\d{2}|[1-9]\d|\d)\s*,\s*){2}(360|3[0-5]\d|[12]\d{2}|[1-9]\d|\d)\s*(,\s*\d+(\.\d+)?)?\)$/i;

  // IF HEX
  if (hexRegex.test(color)) {
    if (color.length > 7)
      color = color.substring(0, color.length - 2);
    const _opacity = Math.round(Math.min(Math.max(opacity, 0), 1) * 255);
    let opacityHex = _opacity.toString(16).toUpperCase()
    if (opacityHex.length == 1)
      opacityHex = "0" + opacityHex;
    return color + opacityHex;
  }
  // IF RGB OR RGBA
  else if (rgbRegex.test(color)) {
    if (color.substring(0, 4) === 'rgba') {
      return color.replace(/[^,]+(?=\))/, opacity)
    } else {
      return color.replace(')', ', ' + opacity + ')').replace('rgb', 'rgba')
    }
  } else {
    return color
  }

}

export function numberFormat(nStr) {
  nStr += '';
  var x = nStr.split('.');
  var x1 = x[0];
  var x2 = x.length > 1 ? '.' + x[1] : '';
  var rgx = /(\d+)(\d{3})/;
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, '$1' + ',' + '$2');
  }
  return x1 + x2;
}


//var sellerNameBuf = getTLVForValue("1","Ahmad Seller")
export const getTLVForValue = (tagNum, tagValue) => {
  var tagBuf = Buffer.from([tagNum], 'utf8')
  var tagValueLenBuf = Buffer.from([tagValue.length], 'utf8')
  var tagValueBuf = Buffer.from(tagValue, 'utf8')
  var bufsArray = [tagBuf, tagValueLenBuf, tagValueBuf]
  return Buffer.concat(bufsArray)
}
//1.Sellers's Name
//2.VAT registration NUM
//3.TimeStamp e.g 2022-04-25T15:30:00Z
//4.Invoice Total with VAT
//4.VAT Total
export const generateQRCode = (sellerName, vatRegistartionName, timeStamp, taxTotalName, vatTotal) => {
  var sellerNameBuf = getTLVForValue("1", sellerName)
  var vatRegistartionNameBuf = getTLVForValue("2", vatRegistartionName)
  var timeStampBuf = getTLVForValue("3", timeStamp)
  var taxTotalNameBuf = getTLVForValue("4", taxTotalName)
  var vatTotalBuf = getTLVForValue("5", vatTotal)
  var tagsBufsArray = [sellerNameBuf, vatRegistartionNameBuf, timeStampBuf, taxTotalNameBuf, vatTotalBuf]//hashedXMLBuf,keyBuf,signatureBuf
  var qrCodeBuf = Buffer.concat(tagsBufsArray)
  var qrCodeB64 = qrCodeBuf.toString('base64')
  return qrCodeB64
}


export const DDL_ExamstypegrpTypes = () => {
  return [{ label: 'Exam', value: 'Exam', color: '#15bbe7' }, { label: 'Homework', value: 'Homework', color: '#ee3a81' }, { label: 'Assignment', value: 'Assignment', color: '#00d045' }, { label: 'Project', value: 'Project', color: '#8864ed' }, { label: 'Other', value: 'Other', color: '#F39400' }]
}
export const DDL_ExamstypegrpTypes2 = () => {
  return [{ label: 'Internal', value: 'Internal', color: '#15bbe7' }, { label: 'External', value: 'External', color: '#ee3a81' }]
}

export const DDL_ExamstypeModes = () => {
  return [
    { value: 'Sum', label: 'Sum', desc: 'Sum all the exams' },
    { value: 'Average_Grade', label: 'Average Grade', desc: 'Take average of all exams' },
    { value: 'Highest_Grade', label: 'Highest Grade', desc: 'Take the Highest grade from all the exams' },
    { value: 'Highest_Multiplied', label: 'Highest Multiplied', desc: 'Take the Highest grade from all the exams multiplied by total number of exams' },
  ]
}

export const checkRegistration = (student, day, style = {}, rBool = false) => {

  let isRegistered = false;
  const registrations = student.registrations?.filter(item => parseInt(item.term_active) == 1 && item.class_id == student.class_id);
  // student.class_id is the class from which we are fetching the data
  if (registrations?.length) {
    registrations.map((v, i) => {
      if (isRegistered) {
        return
      }
      isRegistered = moment(day).isBetween(v.date_from, v.date_to);
      if (isRegistered && parseInt(v['session_' + moment(day).format('ddd').toLowerCase()]) == 0) {
        isRegistered = false
      }
    })
  }
  if (rBool) {
    return isRegistered
  } else {
    return (<span style={{ ...style }}><FontAwesomeIcon icon={faCircle} style={{ color: isRegistered ? '#00d045' : '#dc3545', marginRight: '6px' }} title={isRegistered ? t('Registered') : t('Not Registered')} /></span>)

  }

}

export const capitalizeFirstLetter = (word) => {
  return word.charAt(0).toUpperCase() + word.slice(1)
}

export const colorShades = (color) => {

  if (color.includes('rgb')) {

    let a = color.split("(")[1].split(")")[0];
    a = a.split(",");
    let b = a.map(function (x) {
      x = parseInt(x).toString(16);
      return (x.length == 1) ? "0" + x : x;
    });

    color = '#' + b.join("");

  }

  let colors = [];

  let ii = 100;
  for (let i = 0; i <= 50; i++) {
    ii -= 10;
    if (i % 8 == 0) {
      ii = 100;
    }
    colors.push(color + (ii == 100 ? '' : ii));
  }

  return colors;
}

export const allGradesLevelMOE = [
  {
    gradeslevel_from: 90,
    gradeslevel_to: 100,
    gradeslevel_grade: 4
  },
  {
    gradeslevel_from: 80,
    gradeslevel_to: 89.99,
    gradeslevel_grade: 3
  },
  {
    gradeslevel_from: 70,
    gradeslevel_to: 79.99,
    gradeslevel_grade: 2
  },
  {
    gradeslevel_from: 60,
    gradeslevel_to: 69.99,
    gradeslevel_grade: 1
  },
  {
    gradeslevel_from: 0,
    gradeslevel_to: 59.99,
    gradeslevel_grade: 0
  },
]
