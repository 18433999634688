import React, { Component } from 'react';
import { Container, Card, Row, Col, ButtonGroup, Button, Form, Spinner, Modal, Badge, InputGroup } from 'react-bootstrap';
import { t } from '../../helpers/translation_helper';
import NavBar from '../_partials/NavBar/_NavBar';
import Header from '../_partials/Header/_Header';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faCheckCircle, faSearch, faUser, faXmark, faLayerGroup, faHandsHoldingChild, faPeopleGroup, faListCheck, faAngleLeft, faAngleRight, faEllipsis, faWalking, faBan, faPerson, faClipboardUser, faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { brand_colors } from '../../helpers/brand_colors_helper';
import { Tooltip as Tooltiper } from 'react-tooltip';
import { getStudents, setStudents, fetchingStudents } from '../../redux/slices/studentListSlice';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import walkingSolidBlue from '../../assets/images/student-list/walking-solid-blue.png';
import walkingSolidOrink from '../../assets/images/student-list/walking-solid-orink.png';
import quickTime from '../../assets/images/student-list/quick-time-white.png';
import selectTime from '../../assets/images/student-list/select-time-white.png';
import moment from 'moment';
import { changeColorOpacity, checkRegistration, isTeacher } from '../../helpers/general_helpers';
import { GET, POST } from '../../api';
import ReactPaginate from 'react-paginate';
import Pagination from '../_partials/Pagination';
import { sendCloudMessage } from '../../helpers/cloud_message';
import { DateRangePicker } from 'react-date-range';

const portalURL = process.env.REACT_APP_PORTAL_URL;
const apiURL = process.env.REACT_APP_API_URL;


class StudentAttendance extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchExpanded: false,
      studentIndex: null,
      student: null,
      workingIndex: [],
      working: false,
      attType: 'custom',
      cdt: {
        rows: [10, 25, 50, 'All'],
      },
      absences: [],
      showAttModal: {
        show: false,
        onlyAbs: false,
        toggleModal: (studentIndex = null, student = null, data = { attTimeIn: '', attTimeOut: '', attComment: '', attAbsType: '', attAbsent: false }, onlyAbs = false) => this.setState({ studentIndex, student, working: false, showAttModal: { ...this.state.showAttModal, show: !this.state.showAttModal.show, ...data, onlyAbs } }),
        attTimeIn: '',
        attTimeOut: '',
        attComment: '',
        attAbsType: '',
        attAbsent: false,
      },
      showBulkAttModal: {
        show: false,
        onlyAbs: false,
        toggleModal: (onlyAbs = false) => this.setState({ attType: 'custom', working: false, showBulkAttModal: { ...this.state.showBulkAttModal, show: !this.state.showBulkAttModal.show, onlyAbs, attTimeIn: '', attTimeOut: '', attComment: '', attAbsType: '', attAbsent: false } }),
        attTimeIn: '',
        attTimeOut: '',
        attComment: '',
        attAbsType: '',
        attAbsent: false,
      },
      acs_id: null,
      ttd_id: null,
      class_id: null,
      studentList: [],
      timetable: null,
      timetable_periods: null,
      selectedTimetable: null,
      selectedTTSubs_working: false,
      selectedTTSubs: [],
      selectedTTSub: {
        acs_id: null,
        ttd_id: null
      },
      totalNotMarked: 0,
      totalPresent: 0,
      totalAbsent: 0,
      selectedDateRange: { startDate: new Date(), endDate: new Date(), key: 'selection' },
      showDateRange: false,
    }
    this.searchFieldRef = React.createRef();
  }

  componentDidMount() {
    this.getStudents({
      rows: 50,
      page: 0,
      search: '',
      status: this.props.studentListStatus
    });
    this.getAbsences();

    this.getAssignedSubjects();

  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.selectedClass && prevProps.selectedClass.class.class_id !== this.props.selectedClass.class.class_id) {
      this.getStudents({
        rows: this.props.studentListRows,
        page: 0,
        search: '',
        status: this.props.studentListStatus
      });
      this.getAssignedSubjects();
      this.setState({ searchExpanded: false });
      this.searchFieldRef.current.value = '';
    }

    console.log('calculateAttendanceLegends', !this.props.studentListFetching, this.props.studentListFetching != prevProps.studentListFetching);
    if (!this.props.studentListFetching && this.props.studentListFetching != prevProps.studentListFetching) {
      this.calculateAttendanceLegends()
    }
  }

  getStudents = (params) => {
    this.props.getStudents({
      search: params.search,
      limit: params.rows,
      page: params.page,
      child_status: params.status,
      attendance_today: true,
      attendance_subject: true,
      registrations: true,
      showAllClasses: false,
    });
    this.setState({ studentList: [] });
  }

  calculateAttendanceLegends = () => {
    let totalNotMarked = 0
    let totalPresent = 0
    let totalAbsent = 0
    this.props.studentListData.map((student) => {
      let selectChildAttendance = student.childattendance
      if (this.state.selectedTTSub.acs_id) {
        selectChildAttendance = this.haveAttendance(this.state.selectedTTSub, student.childattendance_all)
      }

      if (selectChildAttendance) {
        if (!selectChildAttendance.att_datetime_in && !selectChildAttendance.att_datetime_out && parseInt(selectChildAttendance.absence_id) == 0) {
          totalNotMarked++
        } else if (parseInt(selectChildAttendance.absence_id) != 0) {
          totalAbsent++
        } else if (selectChildAttendance.att_datetime_in || selectChildAttendance.att_datetime_out) {
          totalPresent++
        }
      }
    })
    this.setState({
      totalNotMarked,
      totalPresent,
      totalAbsent,
    });
  }


  getAbsences = async () => {
    try {
      const res = await GET('children/get-absences');
      if (res) {
        this.setState({
          absences: res.data
        });
      }
    } catch (err) {
      console.log('err', err)
    }
  }

  getAssignedSubjects = async () => {
    const { authData, selectedClass } = this.props;
    const { employee_id } = authData.userData;
    const { class_id } = selectedClass.class;

    try {
      const res = await GET('timetable/get-assigned-subjects', {
        params: {
          employee_id,
          class_id
        }
      });
      console.log('getAssignedSubjects', res.data);
      const selectedTTSubs = res.data.filter(item => {
        console.log('selectedTTSubs', item.dayname == moment().format('dddd'), item.tp_id != null, item.ttd_id != null, item.ttd_id == null);
        if ((item.dayname == moment().format('dddd') && item.tp_id != null && item.ttd_id != null) || (item.ttd_id == null)) {
          return item
        }
      });
      console.log('getAssignedSubjects', selectedTTSubs);
      this.setState({
        selectedTTSubs: [
          ...selectedTTSubs,
        ]
      });
    } catch (err) {
      console.log(err);
    }
  }

  quickAttendance = async (studentIndex, studentData, attType) => {

    this.props.fetchingStudents(true)
    this.setState({ workingIndex: [studentIndex] });

    const { studentListData: students, } = this.props;

    let extra_params = {};

    if (this.state.selectedTTSub.acs_id != null) {
      const { acs_id, ttd_id, class_id, time_start, time_end } = this.state.selectedTTSub;
      extra_params = {
        acs_id,
        ttd_id,
        time_start,
        time_end
      }
    }

    const request_params = {
      class_id: studentData.class_id,
      child_id: studentData.child_id,
      attType,
      ...extra_params
    };

    try {
      const res = await GET('children/save-quick-attendance', {
        params: request_params
      });

      if (res) {
        toast.success(t(res.data.message));

        let updatedStudentsList = [...students];
        const findIndex = students.findIndex((student) => student.child_id == studentData.child_id);

        let updatedStudent;
        if (this.state.selectedTTSub.acs_id != null) {
          updatedStudent = { ...studentData, childattendance_all: res.data.childattendance_all }
        } else {
          updatedStudent = { ...studentData, childattendance: res.data.data }
        }

        updatedStudentsList[findIndex] = updatedStudent;

        this.props.setStudents(updatedStudentsList);

        this.setState({ workingIndex: [], studentList: [] }, () => {
          [...document.querySelectorAll('.child-selector')].map((checkbox) => {
            checkbox.checked = false;
          })
        });

      }
    } catch (err) {
      console.log('err', err)
      toast.error(t('Something went wrong!'));

      this.props.fetchingStudents(false)
      this.setState({ workingIndex: [] });
    }

  }

  customAttendance = async () => {

    const { studentIndex, student } = this.state;
    this.setState({ workingIndex: [studentIndex] });

    const { authData, studentListData } = this.props;
    const { auth_key } = authData.loginData;
    const { center_id, center_timezone } = authData.centerData;
    const { attAbsType, attComment, attTimeIn, attTimeOut, attAbsent } = this.state.showAttModal;

    if (attAbsent && attAbsType == '') {
      toast.warning(t('Please select Absence'));
      return
    }

    this.props.fetchingStudents(true)

    let extra_params = {};
    if (this.state.selectedTTSub.acs_id) {
      const { acs_id, ttd_id } = this.state.selectedTTSub;
      extra_params = {
        acs_id,
        ttd_id,
      }
    }

    let attendance = {
      class_id: student.class_id,
      child_id: student.child_id,
      center_id,
      att_comment: attComment,
      att_datetime_in: (attTimeIn) ? moment().format('YYYY-MM-DD') + ' ' + attTimeIn : null,
      att_datetime_out: (attTimeOut) ? moment().format('YYYY-MM-DD') + ' ' + attTimeOut : null,
      absence_id: (attAbsent) ? attAbsType : 0,
      absent: (attAbsent) ? 1 : 0,
      acs_id: null,
      ttd_id: null,
      absent_start_date: moment(this.state.selectedDateRange.startDate).format('YYYY-MM-DD'),
      absent_end_date: moment(this.state.selectedDateRange.endDate).format('YYYY-MM-DD'),
      ...extra_params
    };

    // const findAtt = studentData.childattendance_all.find(item => item.att_date == moment().format('YYYY-MM-DD'));

    const fData = new FormData();
    fData.append('center_timezone', center_timezone);
    fData.append('attendance', JSON.stringify(attendance));

    try {
      if (this.customAttendanceReq) {
        this.customAttendanceReq.abort();
      }
      this.customAttendanceReq = new AbortController();
      const res = await POST('children/save-attendance-new', fData, {
        signal: this.customAttendanceReq.signal,
      });

      if (res) {
        toast.success(t(res.data.message));

        if (res.data.type) {
          let updatedStudentsList = [...studentListData];
          const findIndex = updatedStudentsList.findIndex((v) => student.child_id == v.child_id);
          console.log('updatedStudentsList', updatedStudentsList, findIndex);

          let updatedStudent;
          let absentCloudMessages = []
          if (this.state.selectedTTSub.acs_id) {
            updatedStudent = { ...student, childattendance_all: res.data.childattendance_all, timestamp: new Date().getTime() }
            if (parseInt(res.data.data.absent) != 0) {
              absentCloudMessages.push({ user_id: student.parent_user_id, title: 'Subject Attendance', body: student.child_name + ' has been marked absent for Subject:' + this.state.selectedTTSub.acs_name + '. ' + res.data.data.att_comment, data: {} })
            }
          } else {
            updatedStudent = { ...student, childattendance: res.data.data, timestamp: new Date().getTime() }
            if (parseInt(res.data.data.absent) != 0) {
              absentCloudMessages.push({ user_id: student.parent_user_id, title: 'Todays Attendance', body: student.child_name + ' has been marked absent. ' + res.data.data.att_comment, data: {} })
            }
          }
          // SEND NOTIFICATION ONLY IN CASE OF ABSENCE
          absentCloudMessages.map((acm) => {
            sendCloudMessage(acm.user_id, acm.title, acm.body, acm.data)
          })

          updatedStudentsList[findIndex] = updatedStudent;
          this.props.setStudents(updatedStudentsList);
          this.setState({
            studentIndex: null, student: null, working: false, workingIndex: [],
            showAttModal: {
              ...this.state.showAttModal, show: false,
              attTimeIn: '',
              attTimeOut: '',
              attComment: '',
              attAbsType: '',
              attAbsent: false,
            },
            studentList: []
          }, () => [...document.querySelectorAll('.child-selector')].map((checkbox) => {
            checkbox.checked = false;
          }))
        }
      }
    } catch (err) {
      console.log('err', err)
      this.props.fetchingStudents(false)
      this.setState({ working: false, workingIndex: [] });
    }

  }

  bulkQuickAttendance = async () => {

    const { studentList, attType } = this.state;

    if (studentList.length === 0) {
      toast.error(t('Select at least one child for quick bulk attendance!'));
      return;
    }

    this.props.fetchingStudents(true)
    const workingIndex = studentList.map((student) => {
      return student.student_index;
    })

    this.setState({ working: true, workingIndex });

    const { authData, selectedClass } = this.props;
    const { auth_key } = authData.loginData;
    const { center_id, center_timezone } = authData.centerData;
    const { attTimeIn, attTimeOut, attAbsType, attAbsent } = this.state.showBulkAttModal;

    const fData = new FormData();
    fData.append('center_id', center_id);
    fData.append('center_timezone', center_timezone);
    fData.append('attType', attType);
    // fData.append('class_id', selectedClass.class.class_id);

    const childrenList = studentList.map((student) => {
      return {
        child_id: student.student_id,
        class_id: student.class_id,
      }
    });
    fData.append('childrenList', JSON.stringify(childrenList));

    if (this.state.selectedTTSub.acs_id) {
      const { acs_id, ttd_id, time_start, time_end } = this.state.selectedTTSub;
      fData.append('acs_id', acs_id);
      fData.append('ttd_id', ttd_id);
      fData.append('time_start', time_start);
      fData.append('time_end', time_end);
    }
    if (attTimeIn) {
      fData.append('att_datetime_in', (attTimeIn) ? moment().format('YYYY-MM-DD') + ' ' + attTimeIn : null);
    }
    if (attTimeOut) {
      fData.append('att_datetime_out', (attTimeOut) ? moment().format('YYYY-MM-DD') + ' ' + attTimeOut : null);
    }
    fData.append('absence_id', (attAbsent) ? attAbsType : 0);
    fData.append('absent', attAbsent ? 1 : 0);
    try {
      if (this.bulkQuickAttendanceReq) {
        this.bulkQuickAttendanceReq.abort()
      }
      this.bulkQuickAttendanceReq = new AbortController()
      const res = await POST('children/save-attendance-for-all-new', fData, {
        signal: this.bulkQuickAttendanceReq.signal
      });

      if (res) {
        toast.success(t(res.data.message));
        if (res.data.type) {
          const updatedStudentsList = JSON.parse(JSON.stringify(this.props.studentListData));

          for (const student_id in res.data.data) {
            const findIndex = updatedStudentsList.findIndex((student) => student.child_id == student_id);
            if (this.state.selectedTTSub.acs_id) {
              updatedStudentsList[findIndex].childattendance_all = res.data.data[student_id];
            } else {
              updatedStudentsList[findIndex].childattendance = res.data.data[student_id].find((att) => att.ttd_id == null);
            }
            updatedStudentsList[findIndex].timestamp = new Date().getTime()
          }

          this.props.setStudents(updatedStudentsList);
          this.setState({
            studentList: [],
            working: false,
            workingIndex: [],
            showBulkAttModal: {
              ...this.state.showBulkAttModal, show: false, onlyAbs: false, attTimeIn: '', attTimeOut: '', attComment: '', attAbsType: '', attAbsent: false
            }
          }, () =>
            [...document.querySelectorAll('.child-selector')].map((checkbox) => {
              checkbox.checked = false;
            })
          )
        }
      }
    } catch (err) {
      console.log('err', err)
      toast.error(t('Something went wrong!'));
      this.props.fetchingStudents(false)
      this.setState({ working: false });
    }

  }

  haveAttendance = (subject, attendances) => {
    if (attendances?.length > 0) {
      const attendance = attendances.find((att) => {
        return att.ttd_id == subject.ttd_id && att.acs_id == subject.acs_id;
      });
      return attendance;
    }
    return false
  }


  handleChangeCheckField = (e) => {
    this.setState(
      {
        [e.target.name]: this.state[e.target.name] == 0 ? 1 : 0,
      }
    );
  };

  checkUncheckStudent = (student_id, class_id, student_index) => {

    const findIndex = this.state.studentList.findIndex((c) => c.student_id == student_id);

    const studentList = this.state.studentList;

    if (findIndex == -1) {
      studentList.push({ student_id, class_id, student_index });
    } else {
      studentList.splice(findIndex, 1);
    }

    this.setState({ studentList });

  }

  checkUncheckAllStudents = (uncheck = false) => {
    if (uncheck) {
      this.setState({ studentList: [] });
      [...document.querySelectorAll('.child-selector')].map((checkbox) => {
        checkbox.checked = false;
      });
    } else {

      const studentList = [];

      this.props.studentListData.map((student, student_index) => {
        studentList.push({
          student_id: student.child_id,
          class_id: student.class_id,
          student_index
        });
      });

      this.setState({ studentList });

      [...document.querySelectorAll('.child-selector')].map((checkbox) => {
        checkbox.checked = true;
      });
    }
  }

  render() {
    console.log('CUSTOM', this.state, this.props);
    return (
      <>
        {[3, 22].includes(parseInt(this.props.authData.loginData.role_id)) &&
          <>
            <Card
              className='border-0'
              style={{ borderRadius: '20px', marginBottom: 5 }}>
              <Card.Body style={{
                padding: 20,
                paddingBottom: 11
              }}>
                <Button style={{ position: 'relative' }} className='me-2 mb-2' variant={this.state.selectedTTSub.acs_id != null ? 'light' : 'primary'} onClick={() => this.setState({ selectedTTSub: { acs_id: null, ttd_id: null } }, () => this.calculateAttendanceLegends())}>
                  {t('Today')}
                  {this.state.selectedTTSub.acs_id == null &&
                    <div style={{ position: 'absolute', right: -5, top: -5, }}>
                      <FontAwesomeIcon icon={faCheckCircle} style={{ color: brand_colors[this.props.defaultTheme.theme_id].color4, fontSize: 20, backgroundColor: brand_colors[this.props.defaultTheme.theme_id].color8, borderRadius: 100 }} />
                    </div>
                  }
                </Button>
                {this.state.selectedTTSubs_working &&
                  <Button disabled={true} variant='light'><Spinner
                    as='span'
                    size='sm'
                    role='status'
                    aria-hidden='true'
                  />
                    {t('Loading Subjects')}
                  </Button>
                }
                {!this.state.selectedTTSubs_working && this.state.selectedTTSubs.map((item, index) =>
                  <Button style={{ position: 'relative' }} key={index} className='me-2 mb-2' variant={this.state.selectedTTSub && this.state.selectedTTSub.ttd_id == item.ttd_id ? 'primary' : 'light'} onClick={() => this.setState({ selectedTTSub: item }, () => this.calculateAttendanceLegends())}>
                    {t(item.acs_name)} {item.tp_name && <Badge bg='success'>{t(item.tp_name)}</Badge>}
                    {/* <pre style={{ textAlign: 'left' }}>{JSON.stringify(item, null, 1)}</pre> */}
                    {(this.state.selectedTTSub && this.state.selectedTTSub.ttd_id == item.ttd_id) &&
                      <div style={{ position: 'absolute', right: -5, top: -5, }}>
                        <FontAwesomeIcon icon={faCheckCircle} style={{ color: brand_colors[this.props.defaultTheme.theme_id].color4, fontSize: 20, backgroundColor: brand_colors[this.props.defaultTheme.theme_id].color8, borderRadius: 100 }} />
                      </div>
                    }
                  </Button>
                )}
              </Card.Body>
            </Card>
          </>
        }
        <Card
          className='border-0'
          style={{ borderRadius: '20px' }}>
          <Card.Body>
            <Row>
              <Col md={4} className='d-flex flex-column'>
                <div className='d-flex flex-row align-items-center mb-2'>
                  <strong>{t("Attendance")}</strong>
                  <div className='expandable-search ms-2' style={{ width: this.state.searchExpanded ? 200 : 35 }}>
                    <input
                      ref={this.searchFieldRef}
                      defaultValue={this.props.studentListSearch}
                      onChange={(e) => this.getStudents({
                        rows: this.props.studentListRows,
                        page: 0,
                        search: e.target.value,
                        status: this.props.studentListStatus
                      })}
                      type='text'
                      style={{ width: this.state.searchExpanded ? 165 : 0, paddingLeft: this.state.searchExpanded ? 15 : 0, opacity: this.state.searchExpanded ? 1 : 0 }}
                      placeholder={t('Search Student...')} />
                    <button
                      data-tooltip-id="tooltip"
                      data-tooltip-content={t('Search Student')}
                      data-tooltip-place="top"
                      onClick={() => {
                        this.setState({ searchExpanded: !this.state.searchExpanded });
                        this.props.studentListSearch != '' && this.state.searchExpanded && this.getStudents({
                          rows: this.props.studentListRows,
                          page: 0,
                          search: '',
                          status: this.props.studentListStatus
                        });
                        this.searchFieldRef.current.value = '';
                        !this.state.searchExpanded && this.searchFieldRef.current.focus();
                      }}>
                      <FontAwesomeIcon
                        icon={this.state.searchExpanded ? faXmark : faSearch}
                        color={brand_colors[this.props.defaultTheme.theme_id].color18}
                      />
                    </button>
                  </div>
                </div>
                <div className='d-flex flex-row align-items-center'>
                  <div>
                    {t('Show')}
                    <Form.Select
                      value={this.props.studentListRows}
                      size="sm"
                      onChange={(e) => this.getStudents({
                        rows: e.target.value,
                        page: 0,
                        search: this.props.studentListSearch,
                        status: this.props.studentListStatus
                      })}
                      style={{ width: 70, marginInline: 5, display: 'inline-block' }}>
                      {this.state.cdt.rows.map((row, row_index) => (<option key={row_index} >{row}</option>))}
                    </Form.Select>
                    {t('Records')}
                  </div>
                </div>
              </Col>
              <Col md={8} className='d-flex justify-content-end align-items-start'>
                <div>
                  <Badge bg='success' className='me-2'>{t('Total Present:')} {this.state.totalPresent}</Badge>
                  <Badge bg='danger' className='me-2'>{t('Total Absent:')} {this.state.totalAbsent}</Badge>
                  <Badge bg='warning' className='me-2'>{t('Total Not Marked:')} {this.state.totalNotMarked}</Badge>
                  <Badge bg='info' className='me-2'>{t('Total Students:')} {this.props.studentListTotalCount}</Badge>
                </div>
                <ButtonGroup size='sm'>
                  <Button
                    onClick={() => this.getStudents({
                      rows: this.props.studentListRows,
                      status: true,
                      page: 0,
                      search: this.props.studentListSearch
                    })}
                    variant={this.props.studentListStatus ? 'success' : 'light'}
                  >{t('Only Active')}</Button>
                  <Button
                    onClick={() => this.getStudents({
                      rows: this.props.studentListRows,
                      status: false,
                      page: 0,
                      search: this.props.studentListSearch
                    })}
                    variant={this.props.studentListStatus ? 'light' : 'success'}
                  >{t('Show All')}</Button>
                </ButtonGroup>
              </Col>
            </Row>
            <Row>
              {this.props.studentListFetching &&
                <Col md={12} className='py-5 d-flex flex-column align-items-center'>
                  <Spinner as='span' animation='grow' size='sm' /> {t('Loading Students')}
                </Col>
              }
              {!this.props.studentListFetching &&
                <Col md={12} className='student-attendance-list'>
                  <Row className='student-attendance-list-item pt-2' style={{
                    backgroundColor: 'rgb(247, 247, 247)'
                  }}>
                    <Col md={6} className='d-flex flex-column'>
                      <strong>{t('Bulk Attendance')}</strong>
                      <div>
                        <button className='btn btn-sm btn-success me-1' onClick={() => this.checkUncheckAllStudents()}>{t('Check All')}</button>
                        <button className='btn btn-sm btn-danger' onClick={() => this.checkUncheckAllStudents(true)}>{t('Uncheck All')}</button>
                      </div>
                    </Col>
                    <Col md={2} className='d-flex align-items-center'>
                    </Col>
                    <Col md={2} className='d-flex align-items-center'>
                      <FontAwesomeIcon title={t('Quick Check-In')} className='cursor-pointer' icon={faWalking} style={{ margin: '0 7px', fontSize: 30, color: brand_colors[this.props.defaultTheme.theme_id].color1 }}
                        onClick={() => this.setState({ attType: 'quick_checkin' }, () => this.bulkQuickAttendance())}
                      />
                      <FontAwesomeIcon title={t('Quick Check-Out')} className='cursor-pointer' icon={faWalking} style={{ margin: '0 7px', fontSize: 30, color: brand_colors[this.props.defaultTheme.theme_id].color16 }}
                        onClick={() => this.setState({ attType: 'quick_checkout' }, () => this.bulkQuickAttendance())}
                      />
                      {/* <FontAwesomeIcon title={t('Absent')} className='cursor-pointer' icon={faBan} style={{ margin: '0 7px', fontSize: 30, color: brand_colors[this.props.defaultTheme.theme_id].color11 }}
                        onClick={() => this.state.showBulkAttModal.toggleModal(true)}
                      /> */}

                      <span style={{ marginLeft: '7px', paddingLeft: '14px', borderLeft: 'solid 1px ' + brand_colors[this.props.defaultTheme.theme_id].color10 }}>
                        <FontAwesomeIcon title={t('Custom Attendance')} className='cursor-pointer' icon={faClipboardUser} style={{ fontSize: 30, color: brand_colors[this.props.defaultTheme.theme_id].color4 }}
                          onClick={() => this.state.showBulkAttModal.toggleModal()}
                        />
                      </span>
                    </Col>
                  </Row>
                  {this.props.studentListData.map((student, student_index) => {
                    let selectChildAttendance = student.childattendance
                    if (this.state.selectedTTSub.acs_id) {
                      selectChildAttendance = this.haveAttendance(this.state.selectedTTSub, student.childattendance_all)
                      if (!selectChildAttendance) {
                        selectChildAttendance = {
                          absence_id: 0,
                          absent: 0,
                          acs_id: null,
                          att_comment: null,
                          att_date: moment().format('YYYY-MM-DD'),
                          att_datetime_in: null,
                          att_datetime_out: null,
                          attendance_id: 0,
                          center_id: student.center_id,
                          child_id: student.child_id,
                          child_pickup_id_in: null,
                          child_pickup_id_out: null,
                          class_id: student.class_id,
                          comment: '',
                          material_id: null,
                          time_end: null,
                          time_start: null,
                          ttd_id: null,
                        }
                      }
                    }

                    return <Row key={student_index}
                      style={{ opacity: this.state.workingIndex.includes(student_index) ? 0.5 : 1 }}
                      className='student-attendance-list-item'>
                      <Col md={6} className='d-flex align-items-center'>
                        <div className='me-3'><input type='checkbox' onClick={(e) => this.checkUncheckStudent(e.target.value, student.class_id, student_index)} value={student.child_id} className='child-selector form-check-input' /></div>
                        <div>
                          <div style={{
                            backgroundImage: `url(${(student.picture) ? portalURL + student.center_id + '/children/' + student.picture : 'https://via.placeholder.com/150x150?text=' + student.child_name[0].toUpperCase()})`, width: 60, height: 60, backgroundSize: 'cover', backgroundPosition: 'center center', borderRadius: 30, marginRight: 15
                          }}></div>
                        </div>
                        {student.child_name}
                        {this.state.workingIndex.includes(student_index) &&
                          <Spinner animation="border" size='sm' variant="dark" className='ms-3' />
                        }
                        {checkRegistration(student, moment().format('YYYY-MM-DD'), {
                          marginLeft: 10
                        })}
                      </Col>

                      <Col md={2} className='d-flex align-items-center'>
                        {(selectChildAttendance) &&
                          <div className='d-flex flex-column'>
                            {/* IN */}
                            {selectChildAttendance && selectChildAttendance.att_datetime_in && <div className='badge badge-light-primary'>{moment(selectChildAttendance.att_datetime_in).format('hh:mm:ss A')}</div>
                            }
                            {/* OUT */}
                            {selectChildAttendance && selectChildAttendance.att_datetime_out && <div className='badge badge-light-warning'>{moment(selectChildAttendance.att_datetime_out).format('hh:mm:ss A')}</div>
                            }
                            {/* ABSENT */}
                            {selectChildAttendance && selectChildAttendance.absent != 0 && <div className='badge badge-light-danger'>{(selectChildAttendance.absence_id) ? this.state.absences.find((ab) => ab.absence_id == selectChildAttendance.absence_id)?.absence_name : t('Absent')}</div>
                            }
                            {/* COMMENT */}
                            {selectChildAttendance && selectChildAttendance.att_comment && <div className='badge badge-light-info mt-1'>{selectChildAttendance.att_comment}</div>
                            }
                          </div>
                        }
                      </Col>
                      <Col md={4} className='d-flex align-items-center'>
                        <div>
                          {/* CHECK IN */}
                          {
                            (selectChildAttendance && selectChildAttendance.att_datetime_in == null && selectChildAttendance.att_datetime_out == null && selectChildAttendance.absent == 0) &&
                            <FontAwesomeIcon title={t('Quick Check-In')} className='cursor-pointer' icon={faWalking} style={{ margin: '0 7px', fontSize: 30, color: brand_colors[this.props.defaultTheme.theme_id].color1 }}
                              onClick={() => this.quickAttendance(student_index, student, 'checkin')}
                            />
                          }
                          {/* CHECK OUT */}
                          {(selectChildAttendance && selectChildAttendance.att_datetime_in != null && selectChildAttendance.att_datetime_out == null && selectChildAttendance.absent == 0) &&
                            <FontAwesomeIcon title={t('Quick Check-Out')} className='cursor-pointer' icon={faWalking} style={{ margin: '0 7px', fontSize: 30, color: brand_colors[this.props.defaultTheme.theme_id].color16 }}
                              onClick={() => this.quickAttendance(student_index, student, 'checkout')}
                            />
                          }
                          {/* ABSENCE */}
                          {(selectChildAttendance && selectChildAttendance.att_datetime_in == null && selectChildAttendance.att_datetime_out == null && parseInt(selectChildAttendance.absence_id) == 0) &&
                            <FontAwesomeIcon title={t('Absent')} className='cursor-pointer' icon={faBan} style={{ margin: '0 7px', fontSize: 30, color: brand_colors[this.props.defaultTheme.theme_id].color11 }}
                              onClick={() => this.state.showAttModal.toggleModal(student_index, student, {
                                attTimeIn: (selectChildAttendance && selectChildAttendance.att_datetime_in) ? moment(selectChildAttendance.att_datetime_in).format('HH:mm') : '',
                                attTimeOut: (selectChildAttendance && selectChildAttendance.att_datetime_out) ? moment(selectChildAttendance.att_datetime_out).format('HH:mm') : '',
                                attComment: (selectChildAttendance && selectChildAttendance.att_comment) ? selectChildAttendance.att_comment : '',
                                attAbsType: selectChildAttendance && selectChildAttendance.absence_id ? selectChildAttendance.absence_id : '',
                                attAbsent: (selectChildAttendance && parseInt(selectChildAttendance.absence_id) != 0) ? true : false,
                              }, true)}
                            />
                          }
                          {/* RESET */}
                          {(selectChildAttendance && (selectChildAttendance.att_datetime_in != null || selectChildAttendance.att_datetime_out != null || selectChildAttendance.absent != 0)) &&
                            <FontAwesomeIcon title={t('Reset Attendance')} className='cursor-pointer' icon={faPerson} style={{ margin: '0 7px', fontSize: 30, color: brand_colors[this.props.defaultTheme.theme_id].color10 }}
                              onClick={() => this.quickAttendance(student_index, student, 'reset')}
                            />
                          }

                          {/* CUSTOM ATTENDANCE */}
                          {(selectChildAttendance) &&
                            <span style={{ marginLeft: '7px', paddingLeft: '14px', borderLeft: 'solid 1px ' + brand_colors[this.props.defaultTheme.theme_id].color10 }}>
                              <FontAwesomeIcon title={t('Custom Attendance')} className='cursor-pointer' icon={faClipboardUser} style={{ fontSize: 30, color: brand_colors[this.props.defaultTheme.theme_id].color4 }}
                                onClick={() => this.state.showAttModal.toggleModal(student_index, student, {
                                  attTimeIn: (selectChildAttendance && selectChildAttendance.att_datetime_in) ? moment(selectChildAttendance.att_datetime_in).format('HH:mm') : '',
                                  attTimeOut: (selectChildAttendance && selectChildAttendance.att_datetime_out) ? moment(selectChildAttendance.att_datetime_out).format('HH:mm') : '',
                                  attComment: (selectChildAttendance && selectChildAttendance.att_comment) ? selectChildAttendance.att_comment : '',
                                  attAbsType: selectChildAttendance && selectChildAttendance.absence_id ? selectChildAttendance.absence_id : '',
                                  attAbsent: (selectChildAttendance && parseInt(selectChildAttendance.absence_id) != 0) ? true : false,
                                })}
                              />
                            </span>
                          }
                        </div>
                      </Col>
                    </Row>
                  })}
                </Col>
              }
            </Row>
            <Pagination
              pageCount={this.props.studentListLastPage}
              forcePage={this.props.studentListPage}
              callbackParams={{
                rows: this.props.studentListRows,
                status: this.props.studentListStatus,
                search: this.props.studentListSearch
              }}
              callback={this.getStudents}
            />


            <Modal show={this.state.showAttModal.show} onHide={this.state.showAttModal.toggleModal}>
              <Modal.Header closeButton>
                <Modal.Title>{t('Attendance')}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {!this.state.showAttModal.onlyAbs &&
                  <Row>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>{t('Attendance Time')}</Form.Label>
                        <input
                          disabled={this.state.showAttModal.attAbsent}
                          type='time'
                          className='form-control'
                          value={this.state.showAttModal.attTimeIn}
                          onChange={(e) => {
                            this.setState({ showAttModal: { ...this.state.showAttModal, attTimeIn: e.target.value } });
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>{t('Attendance Time')}</Form.Label>
                        <input
                          disabled={this.state.showAttModal.attAbsent}
                          type='time'
                          className='form-control'
                          value={this.state.showAttModal.attTimeOut}
                          onChange={(e) => {
                            this.setState({ showAttModal: { ...this.state.showAttModal, attTimeOut: e.target.value } });
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                }
                <Row>
                  <Col md={4}>
                    <Form className='me-3 pt-2'>
                      <Form.Check
                        type="switch"
                        label={t('Absent')}
                        checked={this.state.showAttModal.attAbsent}
                        onChange={() =>
                          this.setState({ showAttModal: { ...this.state.showAttModal, attAbsent: !this.state.showAttModal.attAbsent, attAbsType: '' } })
                        }
                      />
                    </Form>
                  </Col>
                  <Col md={8} className='mb-3'>
                    <Form.Select disabled={!this.state.showAttModal.attAbsent} value={this.state.showAttModal.attAbsType} onChange={(e) => this.setState({ showAttModal: { ...this.state.showAttModal, attAbsType: e.target.value } })
                    }>
                      <option value={''}>--Select--</option>
                      {this.state.absences.map((abs, abs_i) => (
                        <option value={abs.absence_id} key={abs_i}>{abs.absence_name}</option>
                      ))}
                    </Form.Select>
                  </Col>
                  <Col md={12}>
                    <Form.Group className="mb-3">
                      <input
                        type='text'
                        placeholder={t('Comment')}
                        className='form-control'
                        defaultValue={this.state.showAttModal.attComment}
                        onBlur={(e) => this.setState({ showAttModal: { ...this.state.showAttModal, attComment: e.target.value } })}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={12} className='position-relative'>
                    <InputGroup>
                      <Form.Control
                        onClick={() => this.setState({ showDateRange: true })}
                        value={
                          moment(this.state.selectedDateRange.startDate).format('DD-MM-YYYY') + ' -- ' + moment(this.state.selectedDateRange.endDate).format('DD-MM-YYYY')
                        }
                        readOnly
                        disabled={!this.state.showAttModal.attAbsent}
                      />
                      <Button variant={this.state.showDateRange ? 'danger' : 'primary'}
                        disabled={!this.state.showAttModal.attAbsent}
                        style={{ width: 40 }}
                        onClick={() => this.setState({ showDateRange: !this.state.showDateRange })}
                        id="button-addon2">
                        <FontAwesomeIcon icon={this.state.showDateRange ? faXmark : faCalendarAlt} style={{ fontSize: 16, color: brand_colors[this.props.defaultTheme.theme_id].color8, }} />
                      </Button>
                    </InputGroup>
                    {this.state.showDateRange &&
                      <div style={{
                        backgroundColor: '#fff',
                        padding: 10,
                        position: 'absolute',
                        border: '#F2F2F2 2px solid',
                        zIndex: 2,
                        top: 37,
                        left: -45,
                        borderRadius: 7
                      }}>
                        <DateRangePicker
                          ranges={[this.state.selectedDateRange]}
                          onChange={(date) => {
                            this.setState({
                              selectedDateRange: { startDate: new Date(date.selection.startDate), endDate: new Date(date.selection.endDate), key: 'selection' }
                            })
                          }}
                          style={{ backgroundColor: '#fff' }}
                          minDate={new Date()}
                        />
                      </div>
                    }
                  </Col>
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <Button disabled={this.state.working} variant="warning" onClick={() => {
                  this.customAttendance();
                }}>
                  {t('Update')}
                </Button>
              </Modal.Footer>
            </Modal>


            <Modal show={this.state.showBulkAttModal.show} onHide={this.state.showBulkAttModal.toggleModal}>
              <Modal.Header closeButton>
                <Modal.Title>{t('Bulk Attendance')}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {!this.state.showBulkAttModal.onlyAbs &&
                  <Row>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>{t('Attendance Time')}</Form.Label>
                        <input
                          disabled={this.state.showBulkAttModal.attAbsent}
                          type='time'
                          className='form-control'
                          value={this.state.showBulkAttModal.attTimeIn}
                          onChange={(e) => {
                            this.setState({ showBulkAttModal: { ...this.state.showBulkAttModal, attTimeIn: e.target.value } });
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>{t('Attendance Time')}</Form.Label>
                        <input
                          disabled={this.state.showBulkAttModal.attAbsent}
                          type='time'
                          className='form-control'
                          value={this.state.showBulkAttModal.attTimeOut}
                          onChange={(e) => {
                            this.setState({ showBulkAttModal: { ...this.state.showBulkAttModal, attTimeOut: e.target.value } });
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                }
                {/* BULK ABSENT COMMENTED */}
                {/* <Row>
                  <Col md={4}>
                    <Form className='me-3 pt-2'>
                      <Form.Check
                        type="switch"
                        label={t('Absent')}
                        checked={this.state.showBulkAttModal.attAbsent}
                        onChange={() =>
                          this.setState({ showBulkAttModal: { ...this.state.showBulkAttModal, attAbsent: !this.state.showBulkAttModal.attAbsent, attAbsType: '' } })
                        }
                      />
                    </Form>
                  </Col>
                  <Col md={8} className='mb-3'>
                    <Form.Select disabled={!this.state.showBulkAttModal.attAbsent} value={this.state.showBulkAttModal.attAbsType} onChange={(e) => this.setState({ showBulkAttModal: { ...this.state.showBulkAttModal, attAbsType: e.target.value } })
                    }>
                      <option value={''}>--Select--</option>
                      {this.state.absences.map((abs, abs_i) => (
                        <option value={abs.absence_id} key={abs_i}>{abs.absence_name}</option>
                      ))}
                    </Form.Select>
                  </Col>
                  <Col md={12}>
                    <Form.Group className="mb-3">
                      <input
                        type='text'
                        placeholder={t('Comment')}
                        className='form-control'
                        defaultValue={this.state.showBulkAttModal.attComment}
                        onBlur={(e) => this.setState({ showBulkAttModal: { ...this.state.showBulkAttModal, attComment: e.target.value } })}
                      />
                    </Form.Group>
                  </Col>
                </Row> */}
              </Modal.Body>
              <Modal.Footer>
                <Button disabled={this.state.working} variant="warning" onClick={() => {
                  this.bulkQuickAttendance();
                }}>
                  {t('Update')}
                </Button>
              </Modal.Footer>
            </Modal>
          </Card.Body>
        </Card>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  selectedClass: state.selectedClass.data,
  defaultLanguage: state.language.defaultLanguage,
  defaultTheme: state.theme.defaultTheme,
  studentListFetching: state.studentList.fetching,
  studentListData: state.studentList.data,
  studentListSearch: state.studentList.query,
  studentListRows: state.studentList.rows,
  studentListOffset: state.studentList.offset,
  studentListStatus: state.studentList.status,
  studentListOrder: state.studentList.order,
  studentListDir: state.studentList.dir,
  studentListLastPage: state.studentList.last_page,
  studentListPage: state.studentList.page,
  studentListTotalRecords: state.studentList.total_records,
  studentListTotalCount: state.studentList.total_count,
});

const mapDispatchToProps = () => ({
  getStudents,
  setStudents,
  fetchingStudents
});

export default connect(mapStateToProps, mapDispatchToProps())(StudentAttendance);
