import React, { Component } from 'react';
import { Badge, Button, Modal } from 'react-bootstrap';
import { connect } from "react-redux";
import { t } from '../../helpers/translation_helper';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { brand_colors } from '../../helpers/brand_colors_helper';
import axios from 'axios';
import { toast } from 'react-toastify';
import TimelineCard from '../Dashboard/TimelineCard';
import { GET } from '../../api';

// API URL
const apiURL = process.env.REACT_APP_API_URL;

class AssessLogRow extends Component {
  state = {
    showModal: false,
    data: this.props.data,
    ut_data: null
  }

  toggleParentView = async () => {
    this.setState({ data: { ...this.state.data, assesslog_parentview: this.state.data.assesslog_parentview == 1 ? 0 : 1 } });

    const { authData } = this.props;
    const { auth_key } = authData.loginData;
    const { assesslog_id } = this.state.data;

    try {
      const res = await GET('student-tracker/toggle-share-assesslog', {
        params: {
          assesslog_id
        }
      });

      if (!res.data.type) {
        this.setState({ data: { ...this.state.data, assesslog_parentview: this.state.data.assesslog_parentview == 1 ? 0 : 1 } });
        toast.error(t(res.data.message));
      } else {
        toast.success(t(res.data.message));
      }

    } catch (err) {
      console.log('err', err)

      toast.error(t('Something went wrong!'));
    }

  }

  handleModal = async () => {
    this.setState({ showModal: true });

    const { authData, data } = this.props;
    const { center_id, center_timezone } = authData.centerData;
    const { loginData } = authData;
    const { auth_key } = authData.loginData;

    try {
      const res = await GET('user-timeline/get-user-timeline', {
        params: {
          ut_id: data.ut_id,
        }
      });

      if (!res.data.type) {
        this.setState({ ut_data: res.data.data });
        toast.error(t(res.data.message));
      }

    } catch (err) {
      console.log('err', err)

      toast.error(t('Something went wrong!'));
    }

  }

  render() {

    const { assesslog_name, assesslog_date, assesslog_parentview } = this.state.data;

    return (<tr>
      <td><Badge bg="dark">{moment(assesslog_date).format('DD-MM-YYYY')}</Badge></td>
      <td>{assesslog_name}</td>
      <td>{assesslog_parentview == 1 ? (<Badge bg="success">{t('ALLOWED')}</Badge>) : <Badge bg="danger">{t('NOT ALLOWED')}</Badge>}</td>
      <td>
        <Button variant='primary' size='sm' className='me-2' onClick={() => this.toggleParentView()}>
          <FontAwesomeIcon icon={assesslog_parentview == 1 ? faEyeSlash : faEye} color={brand_colors[this.props.defaultTheme.theme_id].color8} style={{ fontSize: 12 }} />
        </Button>
        <Button variant='info' size='sm' onClick={() => this.handleModal()}>
          <FontAwesomeIcon icon={faMagnifyingGlass} color={brand_colors[this.props.defaultTheme.theme_id].color8} style={{ fontSize: 12 }} />
        </Button>
        <Modal
          show={this.state.showModal && this.state.ut_data != null}
          onHide={() => this.setState({ showModal: false })}
          size='lg'>
          <Modal.Header closeButton>
            <Modal.Title>{t('Assessment Details')}</Modal.Title>
          </Modal.Header>
          <Modal.Body className='journal-tl-card'>
            <TimelineCard
              data={this.state.ut_data}
              authData={this.props.authData}
              dir={this.props.dir}
              children={this.props.allStudents}
              showClass={true}
              allowEdit={false}
              editUserTimeline={null}
              deleteUserTimeline={null}
              defaultTheme={this.props.defaultTheme}
            />
          </Modal.Body>
        </Modal>
      </td>
    </tr>);
  }
}

const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  defaultLanguage: state.language.defaultLanguage,
  selectedClass: state.selectedClass.data,
  children: state.children.list,
  defaultTheme: state.theme.defaultTheme,
});

export default connect(mapStateToProps, null)(AssessLogRow);