import React, { Component } from 'react';
import { Container, Card, Table, Row, Col, Spinner, Image, Badge, Button, Form, Offcanvas, Dropdown, Modal, ButtonGroup, DropdownButton, } from 'react-bootstrap';
import { t } from '../../helpers/translation_helper';
import NavBar from '../_partials/NavBar/_NavBar';
import Header from '../_partials/Header/_Header';
import { connect } from 'react-redux';
import axios from 'axios';
import { toast } from 'react-toastify';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight, faAnglesLeft, faAnglesRight, faCalendarAlt, faCheck, faCheckCircle, faCheckDouble, faClock, faD, faEdit, faEllipsisV, faExternalLink, faFileAlt, faFilterCircleXmark, faGear, faGripVertical, faPaperclip, faPlus, faSearch, faTimes, faTrashAlt, faTrashCan, faXmark, } from '@fortawesome/free-solid-svg-icons';
import { Scrollbar } from 'react-scrollbars-custom';
import imgDescription from '../../assets/images/description.png';
import { brand_colors } from '../../helpers/brand_colors_helper';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Link } from 'react-router-dom';
import { faCircle, faThumbsDown, faThumbsUp } from '@fortawesome/free-regular-svg-icons';
import { handleFormErrors, readFile } from '../../helpers/form_helpers';
import { getExtension } from '../../helpers/general_helpers';
import { colorPalette } from '../../constants/colorsPalette';
import { GET, POST } from '../../api';

// API URL
const apiURL = process.env.REACT_APP_API_URL;

// Portal URL
const portalURL = process.env.REACT_APP_PORTAL_URL;

class LearnArea extends Component {

  dragItem = React.createRef();
  dragOverItem = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      deleteModal: { show: false, id: 0, action: () => { }, title: null, desc: null },
      learnAreaData: {
        openSearch: false,
        toggleSearch: () => this.setState({ learnAreaData: { ...this.state.learnAreaData, openSearch: !this.state.learnAreaData.openSearch, search: '' } }, () => {
          if (!this.state.learnAreaData.openSearch) {
            this.getLearnArea()
          }
        }),
        changeSearch: (search = '') => this.setState({ learnAreaData: { ...this.state.learnAreaData, search } }, () => this.getLearnArea()),
        search: '',
        order: 'learnarea.learnarea_order',
        dir: 'ASC',
        orderName: 'Newest First',
        changeOrder: (orderName = 'Newest First', order = 'learnarea.learnarea_id', dir = 'DESC') => this.setState({ learnAreaData: { ...this.state.learnAreaData, orderName, order, dir } }, () => this.getLearnArea()),
        limit: 100,
        offset: 0,
        data: [],
        total_count: 0,
        total_count_filtered: 0,
        refreshing: false,
        finished: false,
      },
      modalLearnArea: {
        show: false,
        toggle: (title = 'New Main Domain') => this.setState({ modalLearnArea: { ...this.state.modalLearnArea, show: !this.state.modalLearnArea.show, title } }),
        title: '',
        save: this.saveLearnArea,
        saving: false,
      },
      learnarea: {
        learnarea_id: 0,
        learnarea_code: '',
        learnarea_desc: '',
        learnarea_desc2: '',
        as_image: '',
        learnarea_color: '',
        learnarea_status: 1,
        allow_parent: 0,
        ab_id: props.academicBoard.ab_id,
      },
      as_image: null,
      dragAreaColor: {
        as_image: brand_colors[this.props.defaultTheme.theme_id].color18,
        toggleColor: (field, value) => this.setState({ dragAreaColor: { ...this.state.dragAreaColor, [field]: value } })
      }
    }
    this.as_image_ref = React.createRef();
  }

  componentDidMount() {
    if (this.props.academicBoard.ab_id) {
      this.getLearnArea()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.academicBoard.ab_id != prevProps.academicBoard.ab_id || this.props.learnArea.learnarea_id != prevProps.learnArea.learnarea_id) {
      this.setState({
        learnAreaData: {
          ...this.state.learnAreaData,
          data: [],
          total_count: 0,
          total_count_filtered: 0,
          refreshing: false,
          finished: false
        },
        learnarea: {
          learnarea_id: 0,
          learnarea_code: '',
          learnarea_desc: '',
          learnarea_desc2: '',
          as_image: '',
          learnarea_color: '',
          learnarea_status: 1,
          allow_parent: 0,
          ab_id: this.props.academicBoard.ab_id,
        },
      }, () => this.getLearnArea());
    }
  }

  getLearnArea = async () => {
    if (!this.props.academicBoard.ab_id) {
      return
    }

    if (this.getLearnAreaReq) {
      this.getLearnAreaReq.abort();
    }
    this.getLearnAreaReq = new AbortController();

    this.setState({
      learnAreaData: {
        ...this.state.learnAreaData,
        refreshing: true
      }
    })

    try {
      const res = await GET('learn-area/get',
        {
          params: {
            ab_id: this.props.academicBoard.ab_id,
            search: this.state.learnAreaData.search,
            order: this.state.learnAreaData.order,
            dir: this.state.learnAreaData.dir,
            limit: this.state.learnAreaData.limit,
            offset: this.state.learnAreaData.offset,
          },
          signal: this.getLearnAreaReq.signal,
        }
      );
      this.setState({
        learnAreaData: {
          ...this.state.learnAreaData,
          data: res.data.data,
          total_count: res.data.total_count,
          total_count_filtered: res.data.total_count_filtered,
          refreshing: false,
          finished: true,
        }
      });
    } catch (err) {
      console.log('err', err)
      if (axios.isCancel(err)) {

      } else {
        toast.error(t('Something went wrong while fetching data!'));

      }
    }
  };

  saveLearnArea = async () => {
    this.setState({ modalLearnArea: { ...this.state.modalLearnArea, saving: true } });
    const { auth_key, user_id, full_name, role_id } =
      this.props.authData.loginData;
    const { center_id, center_timezone } = this.props.authData.centerData;
    const { learnarea, as_image } = this.state;

    const fData = new FormData();
    fData.append('user_id', user_id);
    fData.append('center_id', center_id);
    fData.append('center_timezone', center_timezone);
    fData.append('learnarea_id', learnarea.learnarea_id);
    if (as_image) {
      fData.append('as_image', as_image.data)
    }
    fData.append('LearnAreaData', JSON.stringify(learnarea));

    try {
      const res = await axios.post(
        apiURL + 'learn-area/save',
        fData,
        {
          params: {
            auth_key: auth_key,
            appname: process.env.REACT_APP_NAME,
          },
        }
      );


      if (!res.data.type) {
        if (res.data.data != null) {
          handleFormErrors(res.data.data);
        } else {
          toast.error(t('Something went wrong while saving!'));
        }
        this.setState({ modalLearnArea: { ...this.state.modalLearnArea, saving: false } });
      } else {
        let learnAreaData = this.state.learnAreaData
        if (learnarea.learnarea_id != 0) {
          learnAreaData.data = learnAreaData.data.map((v, i) => {
            if (v.learnarea_id == learnarea.learnarea_id) {
              return { ...v, ...res.data.data }
            }
            return v
          })
        } else {
          learnAreaData.data = [res.data.data, ...learnAreaData.data]
        }

        this.setState({
          learnAreaData,
          modalLearnArea: {
            ...this.state.modalLearnArea,
            show: false,
            saving: false,
          },
          learnarea: {
            learnarea_id: 0,
            learnarea_code: '',
            learnarea_desc: '',
            learnarea_desc2: '',
            as_image: '',
            learnarea_color: '',
            learnarea_status: true,
            allow_parent: false,
            ab_id: this.props.academicBoard.ab_id,
          },
          as_image: null,
        });
        toast.success(t(res.data.message));

      }
    } catch (err) {
      console.log('err', err)
      toast.error(t('Something went wrong while sending message!'));
      this.setState({ modalLearnArea: { ...this.state.modalLearnArea, saving: false } });
    }
  };
  toggleStatusLearnArea = async (learnarea_id) => {
    const { auth_key, user_id, full_name, role_id } =
      this.props.authData.loginData;
    const { center_id, center_timezone } = this.props.authData.centerData;
    const fData = new FormData();
    fData.append('center_id', center_id);
    fData.append('learnarea_id', learnarea_id);
    try {
      const res = await axios.post(
        apiURL + 'learn-area/toggle-status',
        fData,
        {
          params: {
            auth_key: auth_key,
            appname: process.env.REACT_APP_NAME,
          },
        }
      );

      if (!res.data.status) {
        toast.error(t('Something went wrong while updating!'));
      } else {
        let learnAreaData = this.state.learnAreaData
        learnAreaData.data = learnAreaData.data.map((v, i) => {
          if (v.learnarea_id == learnarea_id) {
            return { ...v, learnarea_status: (v.learnarea_status == 1) ? 0 : 1 }
          }
          return v
        })
        this.setState({ learnAreaData })
        toast.warning(t('Status updated'));
      }
    } catch (err) {
      console.log('err', err)
      toast.error(t('Something went wrong while updating!'));

      this.setState({ msgSending: false });
    }
  };
  setStateLearnArea = (field, value) => {
    this.setState({ learnarea: { ...this.state.learnarea, [field]: value } })
  }
  handleDragFileArea = (e, name) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.type === "dragover") {
      this.state.dragAreaColor.toggleColor(name, brand_colors[this.props.defaultTheme.theme_id].color1)
    } else if (e.type === "dragleave") {
      this.state.dragAreaColor.toggleColor(name, brand_colors[this.props.defaultTheme.theme_id].color18)
    }
  }
  addAttachment = (e) => {
    const selectedFiles = e.target.files;
    let allFiles = [];
    for (let i = 0; i < selectedFiles.length; i++) {
      allFiles.push(readFile(selectedFiles[i]));
    }
    Promise.all(allFiles).then((values) => {
      let attachments = e.target.multiple ? this.state[e.target.name] : null;
      let selectedFiles = values;
      selectedFiles.map((v, i) => {
        if (v.data.type) {
          if (e.target.multiple) {
            attachments.push({ url: v.url, data: v.data });
          } else {
            attachments = { url: v.url, data: v.data };
          }
        }
      });
      this.setState({ [e.target.name]: attachments, dragAreaColor: { ...this.state.dragAreaColor, [e.target.name]: brand_colors[this.props.defaultTheme.theme_id].color18 } });
    });
  };
  deleteAttachment = (field, actionFun = null) => {
    let learnarea = this.state.learnarea
    if (learnarea.learnarea_id != null && learnarea.learnarea_id != 0) {
      this.setState({
        deleteModal: {
          ...this.state.deleteModal, show: true, action: () => {
            // DELETE FILE CALL 
            try {
              GET('academic-boards/delete-academic-board-image', {
                params: {
                  key: learnarea.learnarea_id,
                  field: field,
                },
              }).then((res) => {
                if (res) {
                  if (actionFun) {
                    actionFun()
                  }
                  learnarea[field] = null
                  let learnAreaData = this.state.learnAreaData
                  learnAreaData.data = learnAreaData.data.map((vv, ii) => {
                    if (vv.learnarea_id == this.state.learnarea.learnarea_id) {
                      vv[field] = null;
                    }
                    return vv
                  })
                  this.setState({
                    deleteModal: {
                      ...this.state.deleteModal, show: false
                    },
                    learnAreaData,
                    [field]: null,
                    learnarea,
                  });
                  toast.warning(t('Attachment deleted'));
                } else {
                  toast.error(t('Something went wrong while deleting!'));
                }
              })
            } catch (err) {
              console.log('err', err)
              toast.error(t('Something went wrong while deleting!'));

            }
          }
        }
      })
    } else {
      learnarea[field] = null
      this.setState({ [field]: null, learnarea });
      if (actionFun) {
        actionFun()
      }
    }
  }
  handleOpenColorPicker = () => {
    this.setState({ showColorPicker: true })
  }
  deleteLearnArea = (learnarea_id) => {
    this.setState({
      deleteModal: {
        ...this.state.deleteModal, show: true, action: () => {
          // DELETE FILE CALL 
          try {
            GET('learn-area/delete', {
              params: {
                learnarea_id: learnarea_id,
              },
            }).then((res) => {

              if (res.data.type) {
                let learnAreaData = this.state.learnAreaData
                learnAreaData.data = learnAreaData.data.filter((v, i) => {
                  if (v.learnarea_id != learnarea_id) {
                    return true
                  }
                  return false
                })
                this.setState({
                  learnAreaData, deleteModal: {
                    ...this.state.deleteModal, show: false
                  }
                })
                toast.warning(t(res.data.message));
              } else {
                this.setState({
                  deleteModal: {
                    ...this.state.deleteModal, show: false
                  }
                })
                toast.error(t(res.data.message));
              }
            })
          } catch (err) {
            console.log('err', err)
            toast.error(t('Something went wrong while deleting!'));

          }
        }
      }
    })
  }

  dragStart = (e) => {
    this.dragItem.current = e.target.id;
  }

  dragEnter = (e) => {
    this.dragOverItem.current = e.currentTarget.id;
  }

  drop = () => {
    const dragItemIndex = this.dragItem.current.replace('learnarea_', '');
    const dragOverItemIndex = this.dragOverItem.current.replace('learnarea_', '');
    const copyLearnAreas = [...this.state.learnAreaData.data];
    const dragItemContent = copyLearnAreas[dragItemIndex];
    copyLearnAreas.splice(dragItemIndex, 1);
    copyLearnAreas.splice(dragOverItemIndex, 0, dragItemContent);
    this.dragItem.current = null;
    this.dragOverItem.current = null;

    this.setState({
      learnAreaData: {
        ...this.state.learnAreaData,
        data: copyLearnAreas
      }
    }, () => this.updateLearnAreaOrder());

  }

  updateLearnAreaOrder = async () => {
    try {
      const fData = new FormData();
      this.state.learnAreaData.data.map(item => fData.append('learnarea[]', item.learnarea_id));
      const res = await POST('learn-area/update-order', fData);
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    const { defaultTheme, academicBoard, learnArea } = this.props;
    const { learnAreaData, modalLearnArea, learnarea, as_image } = this.state;

    console.log('%cSTATE', 'font-size: 20px; color: orange;');
    console.log(this.state);

    return (<div>
      <div className='grey-section-header mt-2 flex-row align-items-center'>
        <h5 className='mb-0'>
          <span>
            {t('Main Domain')}
          </span>
        </h5>
        <div className='d-flex flex-row align-items-center'>

          {/* REMOVE SELECTED */}
          {learnArea.learnarea_id &&
            <div className='cb-expandable-search mx-1' >
              <Button
                variant='warning'
                data-tooltip-id="tooltip"
                data-tooltip-content={t('Remove Filter')}
                data-tooltip-place="top"
                title={t('Remove Filter')}
                onClick={() => learnArea.toggle(null)}
              >
                <FontAwesomeIcon
                  icon={faFilterCircleXmark}
                  color={brand_colors[defaultTheme.theme_id].color8}
                />
              </Button>
            </div>
          }

          <ButtonGroup className='mx-1'>
            <div className='cb-expandable-search' style={{ width: learnAreaData.openSearch ? 178 : 32, borderRadius: 0 }}>
              <input
                ref={this.searchFieldRef}
                onChange={(e) => learnAreaData.changeSearch(e.target.value)}
                value={learnAreaData.search}
                type='text'
                style={{ width: learnAreaData.openSearch ? 150 : 0, paddingLeft: learnAreaData.openSearch ? 15 : 0, opacity: learnAreaData.openSearch ? 1 : 0, backgroundColor: brand_colors[defaultTheme.theme_id].color8, fontSize: 14 }}
                placeholder={t('Search')} />
              <Button
                style={{ border: 'inherit', }}
                size='sm'
                title={t('Search')}
                variant={(learnAreaData.search) ? 'danger' : 'primary'}
                onClick={() => learnAreaData.toggleSearch()}>
                <FontAwesomeIcon
                  icon={learnAreaData.openSearch ? faXmark : faSearch}
                  color={brand_colors[defaultTheme.theme_id].color8}
                />
              </Button>
            </div>
            <DropdownButton size='sm' as={ButtonGroup} title={'ORDER BY: ' + learnAreaData.orderName} id="bg-nested-dropdown">
              <Dropdown.Item onClick={() => learnAreaData.changeOrder('Newest First', 'learnarea.learnarea_id', 'DESC')}>Newest First</Dropdown.Item>
              <Dropdown.Item onClick={() => learnAreaData.changeOrder('Oldest First', 'learnarea.learnarea_id', 'ASC')}>Oldest First</Dropdown.Item>
              <Dropdown.Item onClick={() => learnAreaData.changeOrder('Code ASC', 'learnarea.learnarea_code', 'ASC')}>Code ASC</Dropdown.Item>
              <Dropdown.Item onClick={() => learnAreaData.changeOrder('Code DESC', 'learnarea.learnarea_code', 'DESC')}>Code DESC</Dropdown.Item>
              <Dropdown.Item onClick={() => learnAreaData.changeOrder('Desc ASC', 'learnarea.learnarea_desc', 'ASC')}>Desc ASC</Dropdown.Item>
              <Dropdown.Item onClick={() => learnAreaData.changeOrder('Desc DESC', 'learnarea.learnarea_desc', 'DESC')}>Desc DESC</Dropdown.Item>
            </DropdownButton>
          </ButtonGroup>

          <div>
            <Button
              onClick={() => (academicBoard.ab_id)
                ? this.setState({
                  learnarea: {
                    learnarea_id: 0,
                    learnarea_code: '',
                    learnarea_desc: '',
                    learnarea_desc2: '',
                    as_image: '',
                    learnarea_color: '',
                    learnarea_status: 1,
                    allow_parent: 0,
                    ab_id: this.props.academicBoard.ab_id,
                  },
                }, () => modalLearnArea.toggle())
                : toast.warning(t('Please select a Curriculum first!'))}
              className='btn-sm btn-add'
              variant='success'>
              {t('Add Main Domain')}{' '}
              <FontAwesomeIcon
                icon={faPlus}
                color={brand_colors[defaultTheme.theme_id].color8}
                style={{
                  fontSize: 16,
                }}
              />
            </Button>
          </div>
        </div>
      </div>
      <div style={{ height: '30vh' }}>

        {!academicBoard.ab_id
          ? <div className='d-flex mt-2 align-items-center'>
            <div style={{ color: brand_colors[defaultTheme.theme_id].color18 }}>{t('Please select a Curriculum')}</div>
          </div>
          : <div>
            {learnAreaData.refreshing && !learnAreaData.finished &&
              <div className='d-flex mt-2 align-items-center'>
                <Spinner animation='grow' variant='dark' />
              </div>
            }
            {/* {!learnAreaData.refreshing && learnAreaData.finished && learnAreaData.data.length > 0 &&
                            <div className='d-flex mt-2 align-items-center'>
                                <div style={{ color: brand_colors[defaultTheme.theme_id].color18 }}>NO MORE DATA...</div>
                            </div>
                        } */}
            {!learnAreaData.refreshing && learnAreaData.finished && learnAreaData.data.length <= 0 &&
              <div className='d-flex mt-2 align-items-center'>
                <div style={{ color: brand_colors[defaultTheme.theme_id].color18 }}>NO DATA FOUND...</div>
              </div>
            }
          </div>
        }



        {academicBoard.ab_id &&
          <Scrollbar style={{ width: '100%', height: '100%' }}>
            {learnAreaData.data.map((v, i) => {

              return <div key={i} className='scroll-list-card'
                id={`learnarea_${i}`}
                onDragStart={(e) => this.dragStart(e)}
                onDragEnter={(e) => this.dragEnter(e)}
                onDragEnd={this.drop}
                draggable>
                <div className='d-flex flex-row justify-content-between align-items-center p-0 m-0'>
                  <div className='cd-div-actionbtn align-items-center' style={{ width: this.state.searchExpanded ? 108 : 14 }}>
                    <FontAwesomeIcon icon={faGripVertical} color={brand_colors[defaultTheme.theme_id].color18} className='cursor-pointer' onClick={() => this.setState({ searchExpanded: !this.state.searchExpanded })} />

                    <div className='d-flex flex-row align-items-center all-btns' style={{ opacity: this.state.searchExpanded ? 1 : 0 }} >
                      {/* EDIT */}
                      <FontAwesomeIcon icon={faEdit} color={brand_colors[defaultTheme.theme_id].color16} className='cursor-pointer ms-3'
                        onClick={() => this.setState({
                          modalLearnArea: { ...modalLearnArea, show: true, title: 'Edit Main Domain' },
                          learnarea: { ...learnarea, ...v },
                          as_image: (v.as_image) ? { url: portalURL + v.center_id + '/learnareas/' + v.as_image, data: { type: getExtension(v.as_image) } } : null,
                        })} />
                      {/* DELETE */}
                      <FontAwesomeIcon icon={faTrashCan} color={brand_colors[defaultTheme.theme_id].color11} className='cursor-pointer ms-3' onClick={() => this.deleteLearnArea(v.learnarea_id)} />
                      {/* STATUS */}
                      <FontAwesomeIcon icon={(v.learnarea_status == 1) ? faThumbsDown : faThumbsUp} color={(v.learnarea_status == 1) ? brand_colors[defaultTheme.theme_id].color12 : brand_colors[defaultTheme.theme_id].color11} className='cursor-pointer ms-3' title={(v.learnarea_status == 1) ? t('DeActivate') : t('Activate')}
                        onClick={() => this.toggleStatusLearnArea(v.learnarea_id)} />
                    </div>
                  </div>
                  <Badge bg='dark' className='me-2'>{i + 1}</Badge>
                  <div className='me-2' style={{ color: v.learnarea_color }}>{v.learnarea_code}</div>
                  <div>{v.learnarea_desc}</div>
                </div>
                <div>
                  {/* TOGGLE CHECK*/}
                  <div className='d-flex flex-row align-items-center me-2 cursor-pointer' style={{ backgroundColor: (learnArea.learnarea_id == v.learnarea_id) ? brand_colors[this.props.defaultTheme.theme_id].color4_light : brand_colors[this.props.defaultTheme.theme_id].color7, borderRadius: '0.8rem', padding: '0.2rem 0.8rem' }} onClick={() => learnArea.toggle(v.learnarea_id)}>
                    <div className='d-flex align-items-center justify-content-center me-2' style={{ backgroundColor: brand_colors[this.props.defaultTheme.theme_id].color8, borderRadius: '100%', height: '0.9rem', width: '0.9rem' }}>
                      {(learnArea.learnarea_id == v.learnarea_id) && <FontAwesomeIcon icon={faCheckCircle} color={brand_colors[this.props.defaultTheme.theme_id].color4} style={{ fontSize: '0.8rem', }} />}
                    </div>
                    <div style={{ color: (learnArea.learnarea_id == v.learnarea_id) ? brand_colors[this.props.defaultTheme.theme_id].color4 : brand_colors[this.props.defaultTheme.theme_id].color18, fontWeight: '600', fontSize: '0.8rem' }}>
                      {v.state ? t('Preview') : t('Preview')}
                    </div>
                  </div>
                </div>
              </div>
            })}
          </Scrollbar>}
      </div>

      <SweetAlert
        show={this.state.deleteModal.show}
        warning
        showCancel
        confirmBtnText={t('Yes, delete it!')}
        confirmBtnBsStyle='danger'
        title={(this.state.deleteModal.title) ? this.state.deleteModal.title : t('Are you sure?')}
        onConfirm={this.state.deleteModal.action}
        onCancel={() => this.setState({ deleteModal: { ...this.state.deleteModal, show: false, id: 0, action: () => { } } })}
        focusCancelBtn>
        {(this.state.deleteModal.desc) ? this.state.deleteModal.desc : t('Confirm Delete!')}
      </SweetAlert>

      {/* MODAL NEW/EDIT ACADEMIC BOARD */}
      <Modal show={modalLearnArea.show} onHide={() => modalLearnArea.toggle()} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{modalLearnArea.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className='custom-styled-form'>
          <Row>
            <Col md="8">
              <Row>
                <Col md="12">
                  <Form.Group className='mb-3'>
                    <Form.Label>
                      <img src={imgDescription} />
                      {t('Code')}
                    </Form.Label>
                    <Form.Control type='text' defaultValue={learnarea.learnarea_code} onBlur={(event) => this.setStateLearnArea('learnarea_code', event.target.value)} />
                  </Form.Group>
                </Col>
                <Col md="12">
                  <Form.Group className='mb-3'>
                    <Form.Label>
                      <img src={imgDescription} />
                      {t('Description')}
                    </Form.Label>
                    < Form.Control as="textarea" rows={3} defaultValue={learnarea.learnarea_desc} onBlur={(event) => this.setStateLearnArea('learnarea_desc', event.target.value)} />
                  </Form.Group>
                </Col>
                <Col md="12">
                  <Form.Group className='mb-3'>
                    <Form.Label>
                      <img src={imgDescription} />
                      {t('Description 2')}
                    </Form.Label>
                    < Form.Control as="textarea" rows={3} defaultValue={learnarea.learnarea_desc2} onBlur={(event) => this.setStateLearnArea('learnarea_desc2', event.target.value)} />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md="4" className='text-center'>
                  <Form.Group className="mb-3">
                    <Form.Label className='no-style'>{t('Color')}</Form.Label>
                    <div className='selected-color' onClick={() => {
                      this.handleOpenColorPicker();
                    }}>
                      <div style={{ backgroundColor: learnarea.learnarea_color }}></div>
                    </div>
                    {this.state.showColorPicker &&
                      <div className='color-palette'>
                        {colorPalette.map((color, color_i) => (
                          <div
                            key={color_i}
                            onClick={() => {
                              this.setState({
                                learnarea: {
                                  ...this.state.learnarea,
                                  learnarea_color: color.code
                                },
                                showColorPicker: false
                              })
                            }}
                            style={{ backgroundColor: color.code }}
                          ></div>
                        ))}
                      </div>
                    }
                  </Form.Group>
                </Col>
                <Col md="4" className='text-center'>
                  <Form.Label className='no-style'>
                    {t('Status')}
                  </Form.Label>
                  <Form.Check
                    type="switch"
                    style={{}}
                    value={1}
                    checked={(learnarea.learnarea_status == 0) ? false : true}
                    onChange={(event) => this.setStateLearnArea('learnarea_status', (learnarea.learnarea_status == 1) ? 0 : 1)}
                  />
                </Col>
                <Col md="4" className='text-center'>
                  <Form.Label className='no-style'>
                    {t('Parent Observation')}
                  </Form.Label>
                  <Form.Check
                    type="switch"
                    style={{}}
                    value={1}
                    checked={(learnarea.allow_parent == 0) ? false : true}
                    onChange={(event) => this.setStateLearnArea('allow_parent', (learnarea.allow_parent == 1) ? 0 : 1)}
                  />
                </Col>
              </Row>
            </Col>
            {/* <Col md="6">
                            <div style={{ flexDirection: 'column' }} className='pic-prev d-flex justify-content-center mb-3'>
                                <div style={{ height: 175, padding: 10, overflow: 'hidden', borderStyle: 'dashed', borderColor: this.state.dragAreaColor.as_image, borderWidth: 2, borderRadius: 20, position: 'relative' }} className='d-flex justify-content-center align-items-center'
                                    onDragEnter={(e) => this.handleDragFileArea(e, 'as_image')} onDragLeave={(e) => this.handleDragFileArea(e, 'as_image')} onDragOver={(e) => this.handleDragFileArea(e, 'as_image')} onDrop={(e) => {
                                        
                                        e.preventDefault();
                                        e.stopPropagation();
                                        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
                                            this.addAttachment({ target: { files: e.dataTransfer.files, multiple: false, name: 'as_image' } })
                                        }
                                    }}>
                                    {as_image && (!as_image.data.type.includes('pdf')) && (
                                        <img src={as_image.url} alt={t('Preview')} style={{ height: '100%' }} />
                                    )}
                                    {as_image == null &&
                                        <button onClick={() => this.as_image_ref.current.click()} style={{ background: 'none', border: 0, flex: 1 }}>
                                            <span style={{ fontSize: 20, fontWeight: 'bold', color: brand_colors[this.props.defaultTheme.theme_id].color18 }}>{t('Image')}</span>
                                        </button>
                                    }

                                    {as_image && (
                                        <FontAwesomeIcon
                                            icon={faTrashCan}
                                            style={{ fontSize: 14, color: brand_colors[this.props.defaultTheme.theme_id].color11, position: 'absolute', bottom: 6, right: 6, cursor: 'pointer', backgroundColor: brand_colors[this.props.defaultTheme.theme_id].color8, borderRadius: 100, padding: 3 }}
                                            onClick={() => this.deleteAttachment('as_image')}
                                        />
                                    )}
                                </div>
                                <Form.Control className='d-none' ref={this.as_image_ref} type='file' onChange={this.addAttachment} name='as_image' />
                            </div>
                        </Col> */}
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => modalLearnArea.toggle()}>
            Close
          </Button>
          <Button variant="primary" disabled={modalLearnArea.saving} onClick={modalLearnArea.save}>
            Save Changes
            {modalLearnArea.saving &&
              <Spinner as='span' animation='grow' size='sm' role='status' aria-hidden='true' />
            }
          </Button>
        </Modal.Footer>


      </Modal>

    </div>);
  }
}


const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  defaultLanguage: state.language.defaultLanguage,
  selectedClass: state.selectedClass.data,
  defaultTheme: state.theme.defaultTheme,
});

export default connect(mapStateToProps, null)(LearnArea);
