import React, { Component } from 'react';
import { Container, Card, Row, Col, Button, Badge, Modal, Form, Spinner, Offcanvas, Alert, Table } from 'react-bootstrap';
import { t } from '../../../helpers/translation_helper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faHomeUser, faPencil, faPlus, faArrowAltCircleLeft, faFilePdf, faTimesCircle, faCheck, faBan, faTrash, faUserGraduate, faFileMedical, faScaleBalanced, faFileLines, faTable, faFileInvoiceDollar, faAsterisk, faFolder, faLink } from '@fortawesome/free-solid-svg-icons';
import { faCircle, faCheckCircle, faCircleDot, faComments } from '@fortawesome/free-regular-svg-icons';
import Select from 'react-select';
import { connect } from 'react-redux';
import { brand_colors } from '../../../helpers/brand_colors_helper';
import { toast } from 'react-toastify';
import { setAddEditStudentModal, setParents, setParent } from '../../../redux/slices/addEditStudentModalSlice';
import { setAddEditParentModal } from '../../../redux/slices/addEditParentModalSlice';
import { getStudents } from '../../../redux/slices/studentListSlice';
import { handleFormErrors } from '../../../helpers/form_helpers';
import Flatpickr from 'react-flatpickr';

import axios from 'axios';
import moment from 'moment';
import { isTeacher } from '../../../helpers/general_helpers';
import { GET, POST } from '../../../api';
var Chance = require('chance');
var chance = new Chance();

const apiURL = process.env.REACT_APP_API_URL;
const portalURL = process.env.REACT_APP_PORTAL_URL;


class AddEditStudent extends Component {

  picture = React.createRef();
  child_passport = React.createRef();
  child_eid_page = React.createRef();
  visa_page = React.createRef();
  indemnity_formcopy = React.createRef();
  med_formcopy = React.createRef();
  vaccination_copy = React.createRef();
  birth_certcopy = React.createRef();
  child_health_attachment = React.createRef();
  attachments_parent_child_temp = React.createRef();

  formSections = [
    {
      uid: chance.guid(),
      active: true,
      name: 'Student Details',
      icon: faUserGraduate
    },
    {
      uid: chance.guid(),
      active: true,
      name: 'Health',
      icon: faFileMedical
    },
    {
      uid: chance.guid(),
      active: true,
      name: 'Legal',
      icon: faScaleBalanced
    },
    // {
    //   uid: chance.guid(),
    //   active: true,
    //   name: 'Forms',
    //   icon: faTable
    // },
    {
      uid: chance.guid(),
      active: true,
      name: 'Documents',
      icon: faFolder
    },
    {
      uid: chance.guid(),
      active: true,
      name: 'Accounts',
      icon: faFileInvoiceDollar
    },
    {
      uid: chance.guid(),
      active: true,
      name: 'Others',
      icon: faAsterisk
    },
  ];

  state = {
    firstLoad: true,
    formSections: this.formSections,
    formSectionActive: {
      parent: this.formSections[0].uid,
    },
    // Modal
    modal: {
      config: {
        name: '',
        size: '',
        show: false,
        getDataFromKey: '',
        setDataToKey: '',
      },
      initialData: {},
      healthData: {
        child_health_id: 0,
        child_health_type: '',
        child_health_name: '',
        child_health_desc: '',
        child_health_date: '',
        child_health_attachment: '',
        child_health_attachment_prev: '',
      },
    },
    // DDLs
    nationalities: [],
    defaultDiscounts: [],
    studentStatuses: [],
    bloodGroups: [
      { value: 'A+', label: 'A+' },
      { value: 'A-', label: 'A-' },
      { value: 'B+', label: 'B+' },
      { value: 'B-', label: 'B-' },
      { value: 'AB+', label: 'AB+' },
      { value: 'AB-', label: 'AB-' },
      { value: 'O+', label: 'O+' },
      { value: 'O-', label: 'O-' },
    ],
    religions: [],
    mainAccounts: [],
    transportTypes: [],
    joiningGrades: [],
    illnesses: [],
    allergies: [],
    vaccines: [],
    doses: [
      { value: '1st Dose', label: '1st Dose' },
      { value: '2nd Dose', label: '2nd Dose' },
      { value: '3rd Dose', label: '3rd Dose' },
      { value: '4th Dose', label: '4th Dose' },
      { value: '5th Dose', label: '5th Dose' },
      { value: '6th Dose', label: '6th Dose' },
      { value: 'Booster Dose', label: 'Booster Dose' },
    ],
    passport_calendar: 0,
    birth_calendar: 0,
    residency_calendar: 0,
    // Student Details
    child_name: '',
    arabic_name: '',
    parent_id: '',
    birth_date: moment().format('YYYY-MM-DD'),
    birth_city: '',
    country_id: '',
    id_number: '',
    subscription_end: '',
    notes: '',
    child_code: '',
    auth_id: '',
    child_discount_id: '',
    moe_id: '',
    picture: '',
    picture_prev: '',
    picture_delete_flag: null,
    user_name: '',
    user_pass: '',
    gmail: '',
    student_status: '',
    staff_child: 0,
    child_reg: 0,
    gender: 'M',
    child_status: 1,
    // Health
    child_medical_name: '',
    child_medical_doctor: '',
    child_medical_clinic: '',
    child_medical_phone: '',
    child_bloodgroup: '',
    medical_note: '',
    child_sen: 0,
    child_pp: 0,
    child_ea: 0,
    child_lac: 0,
    health: [],
    chronicIllnessesList: [],
    allergiesList: [],
    illnessesList: [],
    conditionsList: [],
    vaccinationsList: [],
    // Legal
    passport_number: '',
    passport_validity: '',
    residency_number: '',
    residency_validity: '',
    religion: '',
    first_name_ar: '',
    mid_name_ar: '',
    last_name_ar: '',
    first_name_en: '',
    mid_name_en: '',
    last_name_en: '',
    // Forms
    // child_passcopy: 0,
    // visa_copy: 0,
    // child_eid: 0,
    // birth_certificate: 0,
    // med_form: 0,
    // indemnity_form: 0,
    // child_vacc: 0,
    // Photo: 0,
    // Documents
    child_passport: '',
    child_passport_prev: '',
    child_passport_delete_flag: null,
    child_eid_page: '',
    child_eid_page_prev: '',
    child_eid_page_delete_flag: null,
    visa_page: '',
    visa_page_prev: '',
    visa_page_delete_flag: null,
    indemnity_formcopy: '',
    indemnity_formcopy_prev: '',
    indemnity_formcopy_delete_flag: null,
    med_formcopy: '',
    med_formcopy_prev: '',
    med_formcopy_delete_flag: null,
    vaccination_copy: '',
    vaccination_copy_prev: '',
    vaccination_copy_delete_flag: null,
    birth_certcopy: '',
    birth_certcopy_prev: '',
    birth_certcopy_delete_flag: null,
    // Accounts
    chapt_id: '',
    account_num: '',
    qb_account: '',
    sponsor_name: '',
    sponsor_trn: '',
    // Others
    activity_notes: '',
    first_lang: '',
    second_lang: '',
    transport_type: '',
    child_not_active_notes: '',
    prev_school: '',
    joining_grade: '',
    take_pictures: 1,
    pic_media: 0,
    pic_social: 0,
    pic_nursery: 0,
    attachments_parent_child: [],
    attachments_parent_child_temp: { prev: '', data: '' },
    documentTypes: {
      selected: null,
      data: [
        { label: 'ID 1', value: 'child_id_1' },
        { label: 'ID 2', value: 'child_id_2' },
        { label: 'Passport', value: 'child_passport' },
        { label: 'Visa', value: 'child_visa' },
        { label: 'Vaccination', value: 'child_vaccination' },
        { label: 'Birth Certificate', value: 'child_birth_certificate' },
        { label: 'Medical Insurance', value: 'child_medical_insurance' },
        { label: 'Indemnity Form', value: 'child_indemnity' },
      ]
    }
  }

  componentDidMount() {
    this.getSetDDLs();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.child_id !== this.props.child_id && this.props.child_id !== 0) {
      if (this.state.firstLoad) {
        this.setState({ firstLoad: false }, () => this.getSetDDLs())
      } else {
        this.edit();
      }
    } else if (this.props.addEditStudentModal && this.state.firstLoad) {
      this.setState({ firstLoad: false }, () => this.getSetDDLs())
    }

    if (this.props.showModal !== prevProps.showModal && !this.props.isEditing && this.props.showModal) {

      let default_country = this.state.nationalities[0];

      if (this.props.authData.centerData.country_id) {
        default_country = this.state.nationalities.find(item => item.value == this.props.authData.centerData.country_id);
      }

      this.setState({
        chapt_id: this.state.mainAccounts[0],
        country_id: default_country,
      });
    }
  }

  getStudents = () => {
    this.props.getStudents({});
  }

  getSetDDLs = async () => {
    try {
      const response = await axios.all([
        GET('children/get-parents'),
        GET('children/get-countries'),
        GET('children/get-discounts'),
        GET('children/get-student-status'),
        GET('children/get-religion'),
        GET('children/get-chapters'),
        GET('children/get-transport-type'),
        GET('children/get-age-groups'),
        GET('children/get-illness'),
        GET('children/get-allergy'),
        GET('children/get-vaccine'),
      ]);

      // Parents
      const parents = response[0].data.map((item) => {
        return {
          value: item.parent_id,
          label: item.parent_name,
        }
      });
      this.props.setParents(parents);

      // Nationalities
      const nationalities = response[1].data.map((item) => {
        return {
          value: item.country_id,
          label: item.name_en,
        }
      });

      // Default Discounts
      const defaultDiscounts = response[2].data.map((item) => {
        return {
          value: item.discount_id,
          label: item.discount_name,
        }
      });

      // Default Discounts
      const studentStatuses = response[3].data.map((item) => {
        return {
          value: item.stdstat_id,
          label: item.stdstat_name,
        }
      });

      // Religions
      const religions = response[4].data.map((item) => {
        return {
          value: item.religion_id,
          label: item.religion_name,
        }
      });

      // Main Accounts
      const mainAccounts = response[5].data.map((item) => {
        return {
          value: item.chapt_id,
          label: item.chapt_desc,
        }
      });

      // Transport Types
      const transportTypes = response[6].data.map((item) => {
        return {
          value: item.transporttype_id,
          label: item.transport_name,
        }
      });

      // Joining Grades
      const joiningGrades = response[7].data.map((item) => {
        return {
          value: item.agegroup_id,
          label: item.agegroup_code,
        }
      });

      // Illnesses
      const illnesses = response[8].data.map((item) => {
        return {
          value: item.illness_name,
          label: item.illness_name,
        }
      });

      // Allergies
      const allergies = response[9].data.map((item) => {
        return {
          value: item.allergy_name,
          label: item.allergy_name,
        }
      });

      // Vaccines
      const vaccines = response[10].data.map((item) => {
        return {
          value: item.vaccine_name,
          label: item.vaccine_name,
        }
      });

      this.setState({
        nationalities,
        defaultDiscounts,
        studentStatuses,
        religions,
        mainAccounts,
        transportTypes,
        joiningGrades,
        illnesses,
        allergies,
        vaccines
      }, () => {
        // AHMAD
        this.edit();
      });

    } catch (err) {
      console.log('err', err)

    }
  }

  changeToFromSection = (parent, child) => {
    this.setState(
      {
        formSectionActive: { parent: parent, child: child },
      }
    );
  };

  handleClose = () => {
    this.props.setAddEditStudentModal({ showModal: false, child_id: 0, isEditing: false });
    this.setState({
      formSections: this.formSections,
      formSectionActive: {
        parent: this.formSections[0].uid,
      },
      passport_calendar: 0,
      birth_calendar: 0,
      residency_calendar: 0,
      // Student Details
      child_name: '',
      arabic_name: '',
      birth_date: moment().format('YYYY-MM-DD'),
      birth_city: '',
      country_id: '',
      id_number: '',
      subscription_end: '',
      notes: '',
      child_code: '',
      auth_id: '',
      child_discount_id: '',
      moe_id: '',
      picture: '',
      picture_prev: '',
      picture_delete_flag: null,
      user_name: '',
      user_pass: '',
      gmail: '',
      student_status: '',
      staff_child: 0,
      child_reg: 0,
      gender: 'M',
      child_status: 1,
      // Health
      child_medical_name: '',
      child_medical_doctor: '',
      child_medical_clinic: '',
      child_medical_phone: '',
      child_bloodgroup: '',
      medical_note: '',
      child_sen: 0,
      child_pp: 0,
      child_ea: 0,
      child_lac: 0,
      chronicIllnessesList: [],
      allergiesList: [],
      illnessesList: [],
      conditionsList: [],
      vaccinationsList: [],
      // Legal
      passport_number: '',
      passport_validity: '',
      residency_number: '',
      residency_validity: '',
      religion: '',
      first_name_ar: '',
      mid_name_ar: '',
      last_name_ar: '',
      first_name_en: '',
      mid_name_en: '',
      last_name_en: '',
      // Forms
      // child_passcopy: 0,
      // visa_copy: 0,
      // child_eid: 0,
      // birth_certificate: 0,
      // med_form: 0,
      // indemnity_form: 0,
      // child_vacc: 0,
      // Photo: 0,
      // Documents
      child_passport: '',
      child_passport_prev: '',
      child_passport_delete_flag: null,
      child_eid_page: '',
      child_eid_page_prev: '',
      child_eid_page_delete_flag: null,
      visa_page: '',
      visa_page_prev: '',
      visa_page_delete_flag: null,
      indemnity_formcopy: '',
      indemnity_formcopy_prev: '',
      indemnity_formcopy_delete_flag: null,
      med_formcopy: '',
      med_formcopy_prev: '',
      med_formcopy_delete_flag: null,
      vaccination_copy: '',
      vaccination_copy_prev: '',
      vaccination_copy_delete_flag: null,
      birth_certcopy: '',
      birth_certcopy_prev: '',
      birth_certcopy_delete_flag: null,
      // Accounts
      chapt_id: '',
      account_num: '',
      qb_account: '',
      sponsor_name: '',
      sponsor_trn: '',
      // Others
      activity_notes: '',
      first_lang: '',
      second_lang: '',
      transport_type: '',
      child_not_active_notes: '',
      prev_school: '',
      joining_grade: '',
      take_pictures: 0,
      pic_media: 0,
      pic_social: 0,
      pic_nursery: 0,
      attachments_parent_child: [],
      attachments_parent_child_temp: { prev: '', data: '' },
    });

    this.props.setParent(null);
  }

  handleOpenModal = (name, size, getDataFromKey, setDataToKey) => {
    this.setState({
      modal: {
        ...this.state.modal,
        config: {
          name,
          size,
          show: true,
          getDataFromKey,
          setDataToKey,
        },
        initialData: {
          ...this.state.modal[getDataFromKey]
        },
      }
    });
  }

  handleCloseModal = () => {
    this.setState({
      modal: {
        ...this.state.modal,
        config: {
          ...this.state.modal.config,
          show: false,
        },
        initialData: {},
        [this.state.modal.config.getDataFromKey]: {
          ...this.state.modal.initialData
        }
      }
    });
  }

  addModalItem = () => {

    let data = this.state.modal[this.state.modal.config.getDataFromKey];
    data.uuid = chance.guid();

    // If Health
    if (
      this.state.modal.config.name === 'Chronic Illness' ||
      this.state.modal.config.name === 'Allergy' ||
      this.state.modal.config.name === 'Illness' ||
      this.state.modal.config.name === 'Condition' ||
      this.state.modal.config.name === 'Vaccination'
    ) {
      data.child_health_type = this.state.modal.config.name;
    }

    let list = [...this.state[this.state.modal.config.setDataToKey]];
    list.push(data);
    this.setState({ [this.state.modal.config.setDataToKey]: list });

    this.handleCloseModal();
  }

  deleteModalItem = (listName, uuid) => {
    const list = this.state[listName];
    const index = list.findIndex(item => item.uuid === uuid);
    list.splice(index, 1);
    this.setState({ [listName]: list });
  }


  previewFile = (e) => {
    const reader = new FileReader();

    const selectedFile = e.target.files[0];

    if (selectedFile) {
      reader.readAsDataURL(selectedFile);
    }

    if (
      !selectedFile.type.includes('image') &&
      !selectedFile.type.includes('pdf')
    ) {
      toast.error(t('Only image or PDF file is allowed!'));
      return;
    }

    reader.onload = (readerEvent) => {
      if (e.target.name == 'attachments_parent_child_temp') {
        this.setState({
          [e.target.name]: { prev: (e.target.files[0].type.includes('pdf')) ? 'pdf' : readerEvent.target.result, data: e.target.files[0] },
        })
      } else {
        if (selectedFile.type.includes('image')) {
          this.setState({
            [e.target.name + '_prev']: readerEvent.target.result,
            [e.target.name]: e.target.files[0],
          });
        } else if (selectedFile.type.includes('pdf')) {
          this.setState({
            [e.target.name + '_prev']: readerEvent.target.result,
            [e.target.name]: e.target.files[0],
          });
        }
      }
    };
  };


  previewFileModal = (modal) => (e) => {

    const reader = new FileReader();

    const selectedFile = e.target.files[0];

    if (selectedFile) {
      reader.readAsDataURL(selectedFile);
    }

    if (
      !selectedFile.type.includes('image') &&
      !selectedFile.type.includes('pdf')
    ) {
      toast.error(t('Only image or PDF file is allowed!'));
      return;
    }

    reader.onload = (readerEvent) => {
      this.setState({
        modal: {
          ...this.state.modal,
          [modal]: {
            ...this.state.modal[modal],
            [e.target.name]: e.target.files[0],
            [e.target.name + '_prev']: selectedFile.type.includes('image') ? readerEvent.target.result : 'pdf',
          }
        }
      });
    };
  };


  saveUpdate = async () => {

    this.setState({ working: true });

    const { authData } = this.props;
    const { auth_key } = authData.loginData;
    const { employee_id } = authData.userData;
    const { center_id, center_timezone } = authData.centerData;

    const {
      passport_calendar,
      birth_calendar,
      residency_calendar,
      // Student Details
      child_name,
      arabic_name,
      birth_date,
      birth_city,
      country_id,
      id_number,
      subscription_end,
      notes,
      child_code,
      auth_id,
      child_discount_id,
      moe_id,
      picture,
      picture_prev,
      picture_delete_flag,
      user_name,
      user_pass,
      gmail,
      student_status,
      staff_child,
      child_reg,
      gender,
      child_status,
      // Health
      child_medical_name,
      child_medical_doctor,
      child_medical_clinic,
      child_medical_phone,
      child_bloodgroup,
      medical_note,
      child_sen,
      child_pp,
      child_ea,
      child_lac,
      health,
      // Legal
      passport_number,
      passport_validity,
      residency_number,
      residency_validity,
      religion,
      first_name_ar,
      mid_name_ar,
      last_name_ar,
      first_name_en,
      mid_name_en,
      last_name_en,
      // Forms
      // child_passcopy,
      // visa_copy,
      // child_eid,
      // birth_certificate,
      // med_form,
      // indemnity_form,
      // child_vacc,
      // Photo,
      // Documents
      child_passport,
      child_passport_delete_flag,
      child_eid_page,
      child_eid_page_delete_flag,
      visa_page,
      visa_page_delete_flag,
      indemnity_formcopy,
      indemnity_formcopy_delete_flag,
      med_formcopy,
      med_formcopy_delete_flag,
      vaccination_copy,
      vaccination_copy_delete_flag,
      birth_certcopy,
      birth_certcopy_delete_flag,
      // Accounts
      chapt_id,
      account_num,
      qb_account,
      sponsor_name,
      sponsor_trn,
      // Others
      activity_notes,
      first_lang,
      second_lang,
      transport_type,
      child_not_active_notes,
      prev_school,
      joining_grade,
      take_pictures,
      pic_media,
      pic_social,
      pic_nursery,
      attachments_parent_child
    } = this.state;


    const fData = new FormData();

    fData.append('center_id', center_id);
    fData.append('employee_id', employee_id);
    fData.append('child_id', this.props.child_id);
    fData.append('picture', picture);
    fData.append('child_passport', child_passport);
    fData.append('child_eid_page', child_eid_page);
    fData.append('visa_page', visa_page);
    fData.append('indemnity_formcopy', indemnity_formcopy);
    fData.append('med_formcopy', med_formcopy);
    fData.append('vaccination_copy', vaccination_copy);
    fData.append('birth_certcopy', birth_certcopy);

    health.map((item, index) => {
      if (item.child_health_attachment) {
        fData.append(`children_health_files[${index}]`, item.child_health_attachment);
      }
    });

    const refined_health = health.map((item) => {
      return {
        ...item,
        child_health_name: item.child_health_name.value,
        child_health_desc: item.child_health_desc.value,
      }
    });

    const data = {
      passport_calendar,
      birth_calendar,
      residency_calendar,
      // Student Details
      child_name,
      arabic_name,
      parent_id: this.props.selectedParent && this.props.selectedParent.value,
      birth_date,
      birth_city,
      country_id: country_id.value,
      id_number,
      subscription_end,
      notes,
      child_code,
      auth_id,
      child_discount_id: child_discount_id.value,
      picture,
      picture_prev: '',
      picture_delete_flag: null,
      moe_id,
      user_name,
      user_pass,
      gmail,
      student_status: student_status.value,
      staff_child,
      child_reg,
      gender,
      child_status,
      // Health
      child_medical_name,
      child_medical_doctor,
      child_medical_clinic,
      child_medical_phone,
      child_bloodgroup: child_bloodgroup.value,
      medical_note,
      child_sen,
      child_pp,
      child_ea,
      child_lac,
      children_health: refined_health,
      // Legal
      passport_number,
      passport_validity,
      residency_number,
      residency_validity,
      religion: religion.value,
      first_name_ar,
      mid_name_ar,
      last_name_ar,
      first_name_en,
      mid_name_en,
      last_name_en,
      // Forms
      // child_passcopy,
      // visa_copy,
      // child_eid,
      // birth_certificate,
      // med_form,
      // indemnity_form,
      // child_vacc,
      // Photo,
      // Accounts
      chapt_id: chapt_id.value,
      account_num,
      qb_account,
      sponsor_name,
      sponsor_trn,
      // Others
      activity_notes,
      first_lang,
      second_lang,
      transport_type: transport_type.value,
      child_not_active_notes,
      prev_school,
      joining_grade: joining_grade.value,
      take_pictures,
      pic_media,
      pic_social,
      pic_nursery,
      attachments_parent_child: attachments_parent_child.map((item) => {
        return {
          apc_attachment_type: item.apc_attachment_type,
          apc_id: item.apc_id,
        }
      })
    }

    fData.append('ChildrenData', JSON.stringify(data));

    attachments_parent_child.map((item, index) => {
      if (item.apc_attachment_name.type) {
        fData.append(`attachments_parent_child[${index}]`, item.apc_attachment_name, item.apc_attachment_name.name);
      }
    });


    try {
      const res = await POST('children/save', fData);

      if (res.data.type) {
        this.setState({ working: false });
        toast.success(t(res.data.message));
        this.getStudents();
        this.handleClose();
      } else {
        console.log('v=Array.isArray(res.data.data', res, res.data.data.constructor.name == 'Object');
        if (res.data.data.constructor.name == 'Object') {
          handleFormErrors(res.data.data)
        } else {
          toast.error(t(res.data.message));
        }
        this.setState({ working: false });
      }
    } catch (err) {
      console.log('err', err)
      toast.error(t('Something went wrong!'));

      this.setState({ working: false });
    }

  }

  edit = async () => {
    if (!this.props.child_id || this.props.child_id == 0) {
      return
    }
    const { authData } = this.props;
    const { center_id } = authData.centerData;

    const request_params = {
      child_id: this.props.child_id,
    };

    try {
      const res = await GET('children/edit', { params: request_params });

      if (res.status == 200) {

        console.log('📦 res.data', res.data);

        const {
          passport_calendar, birth_calendar, residency_calendar,
          // Student Details
          child_name, arabic_name, parent_id, parent_name, birth_date, birth_city, country_id, name_en, id_number, subscription_end, notes, child_code, auth_id, child_discount_id, discount_name, picture, moe_id, user_name, user_pass, gmail, student_status, stdstat_name, staff_child, child_reg, gender, child_status,
          // Health
          child_medical_name, child_medical_doctor, child_medical_clinic, child_medical_phone, child_bloodgroup, medical_note, child_sen, child_pp, child_ea, child_lac, children_health,
          // Legal
          passport_number, passport_validity, residency_number, residency_validity, religion, religion_name, first_name_ar, mid_name_ar, last_name_ar, first_name_en, mid_name_en, last_name_en,
          // Forms
          // child_passcopy, visa_copy, child_eid, birth_certificate, med_form, indemnity_form, child_vacc, Photo,
          // Documents
          child_passport, child_eid_page, visa_page, indemnity_formcopy, med_formcopy, vaccination_copy, birth_certcopy,
          // Accounts
          chapt_id, chapt_desc, account_num, qb_account,
          sponsor_name, sponsor_trn,
          // Others
          activity_notes, first_lang, second_lang, transport_type, transport_name, child_not_active_notes, prev_school, joining_grade, agegroup_code, take_pictures, pic_media, pic_social, pic_nursery,
          attachments_parent_child
        } = res.data


        const children_health_updated = children_health
          .map((item) => {
            return {
              ...item,
              uuid: chance.guid(),
              child_health_name: {
                value: item.child_health_name,
                label: item.child_health_name
              },
              child_health_desc: {
                value: item.child_health_desc,
                label: item.child_health_desc
              },
              child_health_attachment_prev: portalURL + center_id + '/children_health/' + item.child_health_attachment,
            }
          });


        this.setState(
          {
            passport_calendar,
            birth_calendar,
            residency_calendar,
            // Student Details
            child_name,
            arabic_name,
            birth_date,
            birth_city,
            country_id: {
              value: country_id,
              label: name_en
            },
            id_number,
            subscription_end,
            notes,
            child_code,
            auth_id,
            child_discount_id: {
              value: child_discount_id,
              label: discount_name,
            },
            picture,
            picture_prev: portalURL + center_id + '/children/' + picture,
            picture_delete_flag: picture,
            moe_id,
            user_name,
            user_pass,
            gmail,
            student_status: {
              value: student_status,
              label: stdstat_name
            },
            staff_child,
            child_reg,
            gender,
            child_status,
            // Health
            child_medical_name,
            child_medical_doctor,
            child_medical_clinic,
            child_medical_phone,
            child_bloodgroup: {
              value: child_bloodgroup,
              label: child_bloodgroup
            },
            medical_note,
            child_sen,
            child_pp,
            child_ea,
            child_lac,
            health: children_health_updated,
            // Legal
            passport_number,
            passport_validity,
            residency_number,
            residency_validity,
            religion: {
              value: religion,
              label: religion_name
            },
            first_name_ar,
            mid_name_ar,
            last_name_ar,
            first_name_en,
            mid_name_en,
            last_name_en,
            // Forms
            // child_passcopy,
            // visa_copy,
            // child_eid,
            // birth_certificate,
            // med_form,
            // indemnity_form,
            // child_vacc,
            // Photo,
            // Documents
            child_passport: !child_passport || child_passport == 'NULL' ? '' : child_passport,
            child_passport_prev: (!child_passport || child_passport == 'NULL') ? '' : portalURL + center_id + '/childrendox/' + child_passport,
            child_passport_delete_flag: child_passport,
            child_eid_page: !child_eid_page || child_eid_page == 'NULL' ? '' : child_eid_page,
            child_eid_page_prev: (!child_eid_page || child_eid_page == 'NULL') ? '' : portalURL + center_id + '/childrendox/' + child_eid_page,
            child_eid_page_delete_flag: child_eid_page,
            visa_page: !visa_page || visa_page == 'NULL' ? '' : visa_page,
            visa_page_prev: (!visa_page || visa_page == 'NULL') ? '' : portalURL + center_id + '/childrendox/' + visa_page,
            visa_page_delete_flag: visa_page,
            indemnity_formcopy: !indemnity_formcopy || indemnity_formcopy == 'NULL' ? '' : indemnity_formcopy,
            indemnity_formcopy_prev: (!indemnity_formcopy || indemnity_formcopy == 'NULL') ? '' : portalURL + center_id + '/childrendox/' + indemnity_formcopy,
            indemnity_formcopy_delete_flag: indemnity_formcopy,
            med_formcopy: !med_formcopy || med_formcopy == 'NULL' ? '' : med_formcopy,
            med_formcopy_prev: (!med_formcopy || med_formcopy == 'NULL') ? '' : portalURL + center_id + '/childrendox/' + med_formcopy,
            med_formcopy_delete_flag: med_formcopy,
            vaccination_copy: !vaccination_copy || vaccination_copy == 'NULL' ? '' : vaccination_copy,
            vaccination_copy_prev: (!vaccination_copy || vaccination_copy == 'NULL') ? '' : portalURL + center_id + '/childrendox/' + vaccination_copy,
            vaccination_copy_delete_flag: vaccination_copy,
            birth_certcopy: !birth_certcopy || birth_certcopy == 'NULL' ? '' : birth_certcopy,
            birth_certcopy_prev: (!birth_certcopy || birth_certcopy == 'NULL') ? '' : portalURL + center_id + '/childrendox/' + birth_certcopy,
            birth_certcopy_delete_flag: birth_certcopy,
            // Accounts
            chapt_id: {
              value: chapt_id,
              label: chapt_desc
            },
            account_num,
            qb_account,
            sponsor_name,
            sponsor_trn,
            // Others
            activity_notes,
            first_lang,
            second_lang,
            transport_type: {
              value: transport_type,
              label: transport_name
            },
            child_not_active_notes,
            prev_school,
            joining_grade: {
              value: joining_grade,
              label: agegroup_code
            },
            take_pictures,
            pic_media,
            pic_social,
            pic_nursery,
            attachments_parent_child
          }
        );

        this.props.setParent({
          value: parent_id,
          label: parent_name
        });

      }

    } catch (err) {
      console.log('err', err)
      toast.error(t('Something went wrong!'));

    }

  }

  render() {

    console.log('this.addeditStd', this.state);


    let healthModalDDL;
    if (
      this.state.modal.config.name == 'Chronic Illness' ||
      this.state.modal.config.name == 'Illness' ||
      this.state.modal.config.name == 'Condition'
    ) {
      healthModalDDL = this.state.illnesses;
    } else if (this.state.modal.config.name == 'Allergy') {
      healthModalDDL = this.state.allergies;
    } else if (this.state.modal.config.name == 'Vaccination') {
      healthModalDDL = this.state.vaccines;
    }

    return (
      <>
        <Offcanvas
          show={this.props.addEditStudentModal}
          onHide={this.handleClose}
          placement='bottom'
          style={{ height: '100%' }}>
          <Offcanvas.Header id='iedu-header' style={{ marginTop: '1rem', marginRight: '1rem', marginLeft: '1rem', marginBottom: 0 }}>
            <div className='d-flex flex-row' style={{ flex: 1 }}>
              <span className='header-back-btn' onClick={this.handleClose} style={{ marginRight: 10 }}>
                <FontAwesomeIcon icon={faArrowAltCircleLeft} color={brand_colors[this.props.defaultTheme.theme_id].color9} />
              </span>
              <Offcanvas.Title>{this.props.isEditing ? t('Edit Student') : t('Add Student')} {this.props.isEditing && '(' + this.state.child_name + ')'}</Offcanvas.Title>
            </div>
            <div>
              <Button disabled={this.state.working} variant='success' onClick={() => this.saveUpdate()}>
                {t('Save')} {this.state.working ? <Spinner as='span' animation='grow' size='sm' role='status' aria-hidden='true' /> : ''}
              </Button>
            </div>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div className={'d-flex flex-row grey-section'} style={{
              backgroundImage: 'url(https://academic.educore.io/static/media/pegboard-bg.9b84e4936acef29bbcd9.png)',
              backgroundRepeat: 'repeat',
              backgroundPosition: '10px 12px',
              overflow: 'hidden',
            }}>
              <div style={{ backgroundColor: brand_colors[this.props.defaultTheme.theme_id].color8, marginRight: 10, borderRadius: '20px', width: '250px', height: '100%', position: 'sticky', padding: 20 }}>
                {this.state.formSections && this.state.formSections.map((v, i) => {
                  return (
                    <div key={i} className='d-flex flex-row align-items-center' style={{ cursor: 'pointer', borderRadius: 10, padding: 15, paddingBlock: 5, backgroundColor: v.uid == this.state.formSectionActive.parent ? brand_colors[this.props.defaultTheme.theme_id].color19 : brand_colors[this.props.defaultTheme.theme_id].color8, zIndex: 1, position: 'relative' }} onClick={() => this.changeToFromSection(v.uid, v.uid + '.1')}>
                      <div style={{ width: 20 }}>
                        <FontAwesomeIcon
                          icon={v.icon}
                          color={v.uid == this.state.formSectionActive.parent ? brand_colors[this.props.defaultTheme.theme_id].color20 : v.completed ? brand_colors[this.props.defaultTheme.theme_id].color4 : brand_colors[this.props.defaultTheme.theme_id].color9}
                          style={{
                            fontSize: 13,
                            position: 'relative',
                            top: -1
                          }}
                        />
                      </div>
                      <span style={{ fontSize: 18, fontWeight: '600', color: v.uid == this.state.formSectionActive.parent ? brand_colors[this.props.defaultTheme.theme_id].color20 : brand_colors[this.props.defaultTheme.theme_id].color9, opacity: v.uid == this.state.formSectionActive.parent ? 1 : 0.7 }}>{t(v.name)}</span>
                    </div>
                  );
                })}
              </div>
              <Col className='custom-styled-form hide-scrollbar' style={{ backgroundColor: brand_colors[this.props.defaultTheme.theme_id].color8, marginLeft: 10, borderRadius: '20px', padding: '1rem', overflow: 'auto', height: '75vh' }}>
                {/* Student Details */}
                {this.state.formSectionActive.parent == this.formSections[0].uid && (
                  <div id={`form_section_${this.formSections[0].uid}`}>
                    <h5>{t(this.formSections[0].name)}</h5>
                    <Row>
                      <Col md={9}>
                        <Row>
                          <Col md={4}>
                            <Form.Group className="mb-3">
                              <Form.Label>{t('Name')} <span className='text-danger'>*</span></Form.Label>
                              <Form.Control type="text"
                                defaultValue={this.state.child_name}
                                onBlur={(e) => this.setState({ child_name: e.target.value })} />
                            </Form.Group>
                          </Col>
                          <Col md={4}>
                            <Form.Group className="mb-3">
                              <Form.Label>{t('Arabic Name')}</Form.Label>
                              <Form.Control type="text"
                                defaultValue={this.state.arabic_name}
                                onBlur={(e) => this.setState({ arabic_name: e.target.value })} />
                            </Form.Group>
                          </Col>
                          <Col md={4}>
                            <Row>
                              <Col md={!this.props.child_id ? 10 : 12} className='pe-0'>
                                <Form.Group className="mb-3">
                                  <Form.Label>{t('Parent')} <span className='text-danger'>*</span></Form.Label>
                                  <Select
                                    value={this.props.selectedParent}
                                    menuPortalTarget={document.body}
                                    styles={{
                                      menuPortal: (
                                        base
                                      ) => ({
                                        ...base,
                                        zIndex: 9999,
                                      }),
                                    }}
                                    options={this.props.parents}
                                    onChange={(obj) => this.props.setParent(obj)}
                                  />
                                </Form.Group>
                              </Col>
                              {!this.props.child_id &&
                                <Col md={2} style={{ paddingTop: 24 }}>
                                  <Button
                                    onClick={() => this.props.setAddEditParentModal({ showModal: true, parent_id: 0, isEditing: false })}
                                    variant='success'>
                                    <FontAwesomeIcon icon={faPlus} color={brand_colors[this.props.defaultTheme.theme_id].color8} style={{ fontSize: 12 }} />
                                  </Button>
                                </Col>
                              }
                            </Row>
                          </Col>
                          <Col md={4}>
                            <Form.Group className="mb-3">
                              <Form.Label>{t('Birthday')} <span className='text-danger'>*</span></Form.Label>
                              <Flatpickr
                                style={{ width: '100%' }}
                                className='form-control'
                                value={this.state.birth_date}
                                onChange={([
                                  date,
                                ]) => {
                                  this.setState(
                                    {
                                      birth_date:
                                        moment(
                                          date
                                        ).format(
                                          'YYYY-MM-DD'
                                        ),
                                    }
                                  );
                                }}
                                options={{
                                  static: true
                                }}
                              />
                            </Form.Group>
                          </Col>
                          <Col md={4}>
                            <Form.Group className="mb-3">
                              <Form.Label>{t('Birth City')}</Form.Label>
                              <Form.Control type="text"
                                defaultValue={this.state.birth_city}
                                onBlur={(e) => this.setState({ birth_city: e.target.value })} />
                            </Form.Group>
                          </Col>
                          <Col md={4}>
                            <Form.Group className="mb-3">
                              <Form.Label>{t('Nationality')} <span className='text-danger'>*</span></Form.Label>
                              <Select
                                value={this.state.country_id}
                                menuPortalTarget={document.body}
                                styles={{
                                  menuPortal: (
                                    base
                                  ) => ({
                                    ...base,
                                    zIndex: 9999,
                                  }),
                                }}
                                options={this.state.nationalities}
                                onChange={(obj) => this.setState({ country_id: obj })}
                              />
                            </Form.Group>
                          </Col>
                          <Col md={4}>
                            <Form.Group className="mb-3">
                              <Form.Label>{t('ID Number')} <span className='text-danger'>*</span></Form.Label>
                              <Form.Control type="text"
                                defaultValue={this.state.id_number}
                                onBlur={(e) => this.setState({ id_number: e.target.value })} />
                            </Form.Group>
                          </Col>
                          <Col md={4}>
                            <Form.Group className="mb-3">
                              <Form.Label>{t('Joining Date')}</Form.Label>
                              <Flatpickr
                                className='form-control'
                                value={this.state.subscription_end}
                                onChange={([
                                  date,
                                ]) => {
                                  this.setState(
                                    {
                                      subscription_end:
                                        moment(
                                          date
                                        ).format(
                                          'YYYY-MM-DD'
                                        ),
                                    }
                                  );
                                }}
                              />
                            </Form.Group>
                          </Col>
                          <Col md={4}>
                            <Form.Group className="mb-3">
                              <Form.Label>{t('Student Code')}</Form.Label>
                              <Form.Control type="text"
                                defaultValue={this.state.child_code}
                                onBlur={(e) => this.setState({ child_code: e.target.value })} />
                            </Form.Group>
                          </Col>
                          <Col md={12}>
                            <Form.Group className="mb-3">
                              <Form.Label>{t('Notes')}</Form.Label>
                              <Form.Control type="text"
                                defaultValue={this.state.notes}
                                onBlur={(e) => this.setState({ notes: e.target.value })} />
                            </Form.Group>
                          </Col>
                          <Col md={4}>
                            <Form.Group className="mb-3">
                              <Form.Label>{t('Auth ID')}</Form.Label>
                              <Form.Control type="text"
                                defaultValue={this.state.auth_id}
                                onBlur={(e) => this.setState({ auth_id: e.target.value })} />
                            </Form.Group>
                          </Col>
                          <Col md={4}>
                            <Form.Group className="mb-3">
                              <Form.Label>{t('Default Discount')}</Form.Label>
                              <Select
                                value={this.state.child_discount_id}
                                menuPortalTarget={document.body}
                                styles={{
                                  menuPortal: (
                                    base
                                  ) => ({
                                    ...base,
                                    zIndex: 9999,
                                  }),
                                }}
                                options={this.state.defaultDiscounts}
                                onChange={(obj) => this.setState({ child_discount_id: obj })}
                              />
                            </Form.Group>
                          </Col>
                          <Col md={4}>
                            <Form.Group className="mb-3">
                              <Form.Label>{t('MOE ID')}</Form.Label>
                              <Form.Control type="text"
                                defaultValue={this.state.moe_id}
                                onBlur={(e) => this.setState({ moe_id: e.target.value })} />
                            </Form.Group>
                          </Col>
                          <Col md={4}>
                            <Form.Group className="mb-3">
                              <Form.Label>{t('Username')}</Form.Label>
                              <Form.Control type="text"
                                defaultValue={this.state.user_name}
                                autoComplete={'new-password'}
                                onBlur={(e) => this.setState({ user_name: e.target.value })} />
                            </Form.Group>
                          </Col>
                          <Col md={4}>
                            <Form.Group className="mb-3">
                              <Form.Label>{t('Password')}</Form.Label>
                              <Form.Control type="password"
                                defaultValue={this.state.user_pass}
                                autoComplete={'new-password'}
                                onBlur={(e) => this.setState({ user_pass: e.target.value })} />
                            </Form.Group>
                          </Col>
                          <Col md={4}>
                            <Form.Group className="mb-3">
                              <Form.Label>{t('Gmail')}</Form.Label>
                              <Form.Control type="text"
                                defaultValue={this.state.gmail}
                                onBlur={(e) => this.setState({ gmail: e.target.value })} />
                            </Form.Group>
                          </Col>
                          <Col md={4}>
                            <Form.Group className="mb-3">
                              <Form.Label>{t('Reg Type')}</Form.Label>
                              <div className="p-3 pb-0">
                                <Form.Check
                                  className='me-4'
                                  inline
                                  label={t('Regular')}
                                  name="child_reg"
                                  type='radio'
                                  id='child_reg_1'
                                  onChange={(e) => this.setState({ child_reg: 0 })}
                                  checked={this.state.child_reg == 0}
                                />
                                <Form.Check
                                  inline
                                  label={t('PM')}
                                  name="child_reg"
                                  type='radio'
                                  id='child_reg_2'
                                  onChange={(e) => this.setState({ child_reg: 1 })}
                                  checked={this.state.child_reg == 1}
                                />
                              </div>
                            </Form.Group>
                          </Col>
                          <Col md={4}>
                            <Form.Group className="mb-3">
                              <Form.Label>{t('Gender')}</Form.Label>
                              <div className="p-3 pb-0">
                                <Form.Check
                                  className='me-4'
                                  inline
                                  label={t('Male')}
                                  name="gender"
                                  type='radio'
                                  id='gender_1'
                                  onChange={(e) => this.setState({ gender: 'M' })}
                                  checked={this.state.gender == 'M'}
                                />
                                <Form.Check
                                  inline
                                  label={t('Female')}
                                  name="gender"
                                  type='radio'
                                  id='gender_2'
                                  onChange={(e) => this.setState({ gender: 'F' })}
                                  checked={this.state.gender == 'F'}
                                />
                              </div>
                            </Form.Group>
                          </Col>
                          <Col md={2}>
                            <Form.Group className="mb-3">
                              <Form.Label>{t('Staff Child')}</Form.Label>
                              <div className='p-3 pb-0'>
                                <Form.Check
                                  type="switch"
                                  id="staff_child"
                                  label={false}
                                  onChange={() => this.setState({ staff_child: !this.state.staff_child })}
                                  checked={this.state.staff_child == 1 ? true : false}
                                />
                              </div>
                            </Form.Group>
                          </Col>
                          <Col md={1}>
                            <Form.Group className="mb-3">
                              <Form.Label>{t('Status')}</Form.Label>
                              <div className='p-3 pb-0'>
                                <Form.Check
                                  type="switch"
                                  id="child_status"
                                  label={false}
                                  onChange={() => this.setState({ child_status: (this.state.child_status == 1) ? 0 : 1 })}
                                  checked={(this.state.child_status == 1) ? true : false}
                                />
                              </div>
                            </Form.Group>
                          </Col>
                        </Row>
                      </Col>
                      <Col md={3}>
                        <Row>
                          <Col md={12}>
                            <Form.Group className="mb-3">
                              <Form.Label>{t('Picture')}</Form.Label>
                              <div
                                style={{
                                  flexDirection:
                                    'column',
                                }}
                                className='pic-prev d-flex justify-content-center'>
                                <div
                                  style={{
                                    borderRadius: 5,
                                    borderStyle:
                                      'dashed',
                                    borderWidth: 2,
                                    borderColor:
                                      '#ced4da',
                                    height: 348,
                                    display: 'flex',
                                    overflow:
                                      'hidden',
                                  }}>
                                  {this.state.picture !==
                                    '' &&
                                    this.state.picture_prev !==
                                    '' ? (
                                    <div
                                      className='d-flex justify-content-center align-items-center'
                                      style={{
                                        flex: 1,
                                        position:
                                          'relative',
                                      }}>
                                      <button
                                        style={{
                                          padding: 3,
                                          border: 3,
                                          background:
                                            'none',
                                          position:
                                            'absolute',
                                          top: 0,
                                          right: 0,
                                        }}
                                        onClick={() => {
                                          this.setState(
                                            {
                                              picture:
                                                '',
                                              picture_prev:
                                                '',
                                              picture_delete_flag: false,
                                            });
                                          this.picture.current.value =
                                            null;
                                        }}>
                                        <FontAwesomeIcon
                                          icon={
                                            faTimesCircle
                                          }
                                          color='rgb(220, 53, 69)'
                                          style={{
                                            fontSize: 20,
                                          }}
                                        />
                                      </button>
                                      <img
                                        src={
                                          this.state.picture_prev
                                        }
                                        alt={t(
                                          'Preview'
                                        )}
                                        width='100'
                                      />
                                    </div>
                                  ) : (
                                    <button
                                      onClick={() =>
                                        this.picture.current.click()
                                      }
                                      style={{
                                        background:
                                          'none',
                                        border: 0,
                                        flex: 1,
                                      }}>
                                      <FontAwesomeIcon
                                        icon={
                                          faPlus
                                        }
                                        style={{
                                          fontSize: 30,
                                        }}
                                      />
                                    </button>
                                  )}
                                </div>
                                <Form.Control
                                  className='d-none'
                                  ref={
                                    this
                                      .picture
                                  }
                                  type='file'
                                  onChange={
                                    this.previewFile
                                  }
                                  name='picture'
                                  accept='.jpg, .jpeg, .png'
                                />
                              </div>
                            </Form.Group>
                          </Col>
                          <Col md={12}>
                            <Form.Group className="mb-3">
                              <Form.Label>{t('Student Status')}</Form.Label>
                              <Select
                                value={this.state.student_status}
                                menuPortalTarget={document.body}
                                styles={{
                                  menuPortal: (
                                    base
                                  ) => ({
                                    ...base,
                                    zIndex: 9999,
                                  }),
                                }}
                                options={this.state.studentStatuses}
                                onChange={(obj) => this.setState({ student_status: obj })}
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>)}
                {/* Health */}
                {this.state.formSectionActive.parent == this.formSections[1].uid && (
                  <div id={`form_section_${this.formSections[1].uid}`}>
                    <h5 className='mb-3'>{t(this.formSections[1].name)}</h5>
                    <Row>
                      <Col md={12}>
                        <h6 className='mb-0'>{t('Medical Contact')}</h6>
                      </Col>
                      <Col md={3}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t('Contact Name')}</Form.Label>
                          <Form.Control type="text"
                            defaultValue={this.state.child_medical_name}
                            onBlur={(e) => this.setState({ child_medical_name: e.target.value })} />
                        </Form.Group>
                      </Col>
                      <Col md={3}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t('Doctor')}</Form.Label>
                          <Form.Control type="text"
                            defaultValue={this.state.child_medical_doctor}
                            onBlur={(e) => this.setState({ child_medical_doctor: e.target.value })} />
                        </Form.Group>
                      </Col>
                      <Col md={3}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t('Clinic')}</Form.Label>
                          <Form.Control type="text"
                            defaultValue={this.state.child_medical_clinic}
                            onBlur={(e) => this.setState({ child_medical_clinic: e.target.value })} />
                        </Form.Group>
                      </Col>
                      <Col md={3}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t('Phone')}</Form.Label>
                          <Form.Control type="text"
                            defaultValue={this.state.child_medical_phone}
                            onBlur={(e) => this.setState({ child_medical_phone: e.target.value })} />
                        </Form.Group>
                      </Col>
                      <Col md={12}><hr /></Col>
                      <Col md={3}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t('Blood Group')}</Form.Label>
                          <Select
                            value={this.state.child_bloodgroup}
                            menuPortalTarget={document.body}
                            styles={{
                              menuPortal: (
                                base
                              ) => ({
                                ...base,
                                zIndex: 9999,
                              }),
                            }}
                            options={this.state.bloodGroups}
                            onChange={(obj) => this.setState({ child_bloodgroup: obj })}
                          />
                        </Form.Group>
                      </Col>
                      <Col md={9}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t('Health Note')}</Form.Label>
                          <Form.Control type="text"
                            defaultValue={this.state.medical_note}
                            onBlur={(e) => this.setState({ medical_note: e.target.value })} />
                        </Form.Group>
                      </Col>
                      <Col md={3}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t('SEN ( Special Educational Needs)')}</Form.Label>
                          <div className="p-3 pb-0">
                            <Form.Check
                              className='me-4'
                              inline
                              label={t('Yes')}
                              name="child_sen"
                              type='radio'
                              id='child_sen_1'
                              onChange={(e) => this.setState({ child_sen: 1 })}
                              checked={this.state.child_sen == 1}
                            />
                            <Form.Check
                              inline
                              label={t('No')}
                              name="child_sen"
                              type='radio'
                              id='child_sen_2'
                              onChange={(e) => this.setState({ child_sen: 0 })}
                              checked={this.state.child_sen == 0}
                            />
                          </div>
                        </Form.Group>
                      </Col>
                      <Col md={3}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t('PP')}</Form.Label>
                          <div className="p-3 pb-0">
                            <Form.Check
                              className='me-4'
                              inline
                              label={t('Yes')}
                              name="child_pp"
                              type='radio'
                              id='child_pp_1'
                              onChange={(e) => this.setState({ child_pp: 1 })}
                              checked={this.state.child_pp == 1}
                            />
                            <Form.Check
                              inline
                              label={t('No')}
                              name="child_pp"
                              type='radio'
                              id='child_pp_2'
                              onChange={(e) => this.setState({ child_pp: 0 })}
                              checked={this.state.child_pp == 0}
                            />
                          </div>
                        </Form.Group>
                      </Col>
                      <Col md={3}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t('EA')}</Form.Label>
                          <div className="p-3 pb-0">
                            <Form.Check
                              className='me-4'
                              inline
                              label={t('Yes')}
                              name="child_ea"
                              type='radio'
                              id='child_ea_1'
                              onChange={(e) => this.setState({ child_ea: 1 })}
                              checked={this.state.child_ea == 1}
                            />
                            <Form.Check
                              inline
                              label={t('No')}
                              name="child_ea"
                              type='radio'
                              id='child_ea_2'
                              onChange={(e) => this.setState({ child_ea: 0 })}
                              checked={this.state.child_ea == 0}
                            />
                          </div>
                        </Form.Group>
                      </Col>
                      <Col md={3}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t('LAC')}</Form.Label>
                          <div className="p-3 pb-0">
                            <Form.Check
                              className='me-4'
                              inline
                              label={t('Yes')}
                              name="child_lac"
                              type='radio'
                              id='child_lac_1'
                              onChange={(e) => this.setState({ child_lac: 1 })}
                              checked={this.state.child_lac == 1}
                            />
                            <Form.Check
                              inline
                              label={t('No')}
                              name="child_lac"
                              type='radio'
                              id='child_lac_2'
                              onChange={(e) => this.setState({ child_lac: 0 })}
                              checked={this.state.child_lac == 0}
                            />
                          </div>
                        </Form.Group>
                      </Col>
                      <Col md={12}><hr /></Col>
                      <Col md={12}>
                        <h5 className='mb-3'>{t('Add Health')}</h5>
                      </Col>
                      <Col md={12}>
                        <Row>
                          <Col>
                            <Col md={12} className='mb-4 health-table'>
                              <Row>
                                <Col><h6>{t('Chronic Illness')}</h6></Col>
                                <Col md='auto'>
                                  <button
                                    onClick={() => this.handleOpenModal('Chronic Illness', 'md', 'healthData', 'health')}
                                    style={{ height: 20, lineHeight: '10px' }}
                                    className='btn btn-success btn-sm'><FontAwesomeIcon icon={faPlus} color={brand_colors[this.props.defaultTheme.theme_id].color8} style={{ fontSize: 12 }} /></button>
                                </Col>
                              </Row>
                              <Table bordered size="sm">
                                <thead>
                                  <tr>
                                    <th style={{ width: '25%' }}>{t('Name')}</th>
                                    <th style={{ width: '45%' }}>{t('Info, Treatment, Details')}</th>
                                    <th style={{ width: '20%', borderRight: 0 }}>{t('Date')}</th>
                                    <th style={{ width: '10%', borderLeft: 0 }}></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.health
                                    .filter((item => item.child_health_type === 'Chronic Illness'))
                                    .map((item, index) => (
                                      <tr key={index}>
                                        <td>{item.child_health_name.label}</td>
                                        <td>{item.child_health_desc.label}</td>
                                        <td style={{ borderRight: 0 }}>{item.child_health_date}</td>
                                        <td style={{ borderLeft: 0, textAlign: 'right' }}>
                                          <button
                                            onClick={() => this.deleteModalItem('health', item.uuid)}
                                            style={{ width: 20, height: 20, lineHeight: '10px', padding: 0 }}
                                            className='btn btn-danger btn-sm'><FontAwesomeIcon icon={faTrash} color={brand_colors[this.props.defaultTheme.theme_id].color8} style={{ fontSize: 12 }} /></button>
                                        </td>
                                      </tr>
                                    ))}
                                </tbody>
                              </Table>
                            </Col>
                            <Col md={12} className='mb-4 health-table'>
                              <Row>
                                <Col><h6>{t('Illness')}</h6></Col>
                                <Col md='auto'>
                                  <button
                                    onClick={() => this.handleOpenModal('Illness', 'md', 'healthData', 'health')}
                                    style={{ height: 20, lineHeight: '10px' }}
                                    className='btn btn-success btn-sm'><FontAwesomeIcon icon={faPlus} color={brand_colors[this.props.defaultTheme.theme_id].color8} style={{ fontSize: 12 }} /></button>
                                </Col>
                              </Row>
                              <Table bordered size="sm">
                                <thead>
                                  <tr>
                                    <th style={{ width: '25%' }}>{t('Name')}</th>
                                    <th style={{ width: '45%' }}>{t('Info, Treatment, Details')}</th>
                                    <th style={{ width: '20%', borderRight: 0 }}>{t('Date')}</th>
                                    <th style={{ width: '10%', borderLeft: 0 }}></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.health
                                    .filter((item => item.child_health_type === 'Illness'))
                                    .map((item, index) => (
                                      <tr key={index}>
                                        <td>{item.child_health_name.label}</td>
                                        <td>{item.child_health_desc.label}</td>
                                        <td style={{ borderRight: 0 }}>{item.child_health_date}</td>
                                        <td style={{ borderLeft: 0, textAlign: 'right' }}>
                                          <button
                                            onClick={() => this.deleteModalItem('health', item.uuid)}
                                            style={{ width: 20, height: 20, lineHeight: '10px', padding: 0 }}
                                            className='btn btn-danger btn-sm'><FontAwesomeIcon icon={faTrash} color={brand_colors[this.props.defaultTheme.theme_id].color8} style={{ fontSize: 12 }} /></button>
                                        </td>
                                      </tr>
                                    ))}
                                </tbody>
                              </Table>
                            </Col>
                            <Col md={12} className='mb-4 health-table'>
                              <Row>
                                <Col><h6>{t('Vaccination')}</h6></Col>
                                <Col md='auto'>
                                  <button
                                    onClick={() => this.handleOpenModal('Vaccination', 'md', 'healthData', 'health')}
                                    style={{ height: 20, lineHeight: '10px' }}
                                    className='btn btn-success btn-sm'><FontAwesomeIcon icon={faPlus} color={brand_colors[this.props.defaultTheme.theme_id].color8} style={{ fontSize: 12 }} /></button>
                                </Col>
                              </Row>
                              <Table bordered size="sm">
                                <thead>
                                  <tr>
                                    <th style={{ width: '25%' }}>{t('Name')}</th>
                                    <th style={{ width: '25%' }}>{t('Dose No.')}</th>
                                    <th style={{ width: '20%' }}>{t('Date')}</th>
                                    <th style={{ width: '20%', borderRight: 0 }}>{t('Attachment')}</th>
                                    <th style={{ width: '10%', borderLeft: 0 }}></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.health
                                    .filter((item => item.child_health_type === 'Vaccination'))
                                    .map((item, index) => (
                                      <tr key={index}>
                                        <td>{item.child_health_name.label}</td>
                                        <td>{item.child_health_desc.label}</td>
                                        <td>{item.child_health_date}</td>
                                        <td style={{ borderRight: 0 }}>
                                          {item.child_health_attachment_prev &&
                                            <img
                                              src={
                                                item.child_health_attachment_prev
                                              }
                                              alt={t(
                                                'Preview'
                                              )}
                                              width='100%'
                                              className='m-1'
                                            />
                                          }
                                        </td>
                                        <td style={{ borderLeft: 0, textAlign: 'right' }}>
                                          <button
                                            onClick={() => this.deleteModalItem('health', item.uuid)}
                                            style={{ width: 20, height: 20, lineHeight: '10px', padding: 0 }}
                                            className='btn btn-danger btn-sm'><FontAwesomeIcon icon={faTrash} color={brand_colors[this.props.defaultTheme.theme_id].color8} style={{ fontSize: 12 }} /></button>
                                        </td>
                                      </tr>
                                    ))}
                                </tbody>
                              </Table>
                            </Col>
                          </Col>
                          <Col>
                            <Col md={12} className='mb-4 health-table'>
                              <Row>
                                <Col><h6>{t('Allergy')}</h6></Col>
                                <Col md='auto'>
                                  <button
                                    onClick={() => this.handleOpenModal('Allergy', 'md', 'healthData', 'health')}
                                    style={{ height: 20, lineHeight: '10px' }}
                                    className='btn btn-success btn-sm'><FontAwesomeIcon icon={faPlus} color={brand_colors[this.props.defaultTheme.theme_id].color8} style={{ fontSize: 12 }} /></button>
                                </Col>
                              </Row>
                              <Table bordered size="sm">
                                <thead>
                                  <tr>
                                    <th style={{ width: '25%' }}>{t('Name')}</th>
                                    <th style={{ width: '45%' }}>{t('Info, Treatment, Details')}</th>
                                    <th style={{ width: '20%', borderRight: 0 }}>{t('Date')}</th>
                                    <th style={{ width: '10%', borderLeft: 0 }}></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.health
                                    .filter((item => item.child_health_type === 'Allergy'))
                                    .map((item, index) => (
                                      <tr key={index}>
                                        <td>{item.child_health_name.label}</td>
                                        <td>{item.child_health_desc.label}</td>
                                        <td style={{ borderRight: 0 }}>{item.child_health_date}</td>
                                        <td style={{ borderLeft: 0, textAlign: 'right' }}>
                                          <button
                                            onClick={() => this.deleteModalItem('health', item.uuid)}
                                            style={{ width: 20, height: 20, lineHeight: '10px', padding: 0 }}
                                            className='btn btn-danger btn-sm'><FontAwesomeIcon icon={faTrash} color={brand_colors[this.props.defaultTheme.theme_id].color8} style={{ fontSize: 12 }} /></button>
                                        </td>
                                      </tr>
                                    ))}
                                </tbody>
                              </Table>
                            </Col>
                            <Col md={12} className='mb-4 health-table'>
                              <Row>
                                <Col><h6>{t('Condition')}</h6></Col>
                                <Col md='auto'>
                                  <button
                                    onClick={() => this.handleOpenModal('Condition', 'md', 'healthData', 'health')}
                                    style={{ height: 20, lineHeight: '10px' }}
                                    className='btn btn-success btn-sm'><FontAwesomeIcon icon={faPlus} color={brand_colors[this.props.defaultTheme.theme_id].color8} style={{ fontSize: 12 }} /></button>
                                </Col>
                              </Row>
                              <Table bordered size="sm">
                                <thead>
                                  <tr>
                                    <th style={{ width: '25%' }}>{t('Name')}</th>
                                    <th style={{ width: '45%' }}>{t('Info, Treatment, Details')}</th>
                                    <th style={{ width: '20%', borderRight: 0 }}>{t('Date')}</th>
                                    <th style={{ width: '10%', borderLeft: 0 }}></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.health
                                    .filter((item => item.child_health_type === 'Condition'))
                                    .map((item, index) => (
                                      <tr key={index}>
                                        <td>{item.child_health_name.label}</td>
                                        <td>{item.child_health_desc.label}</td>
                                        <td style={{ borderRight: 0 }}>{item.child_health_date}</td>
                                        <td style={{ borderLeft: 0, textAlign: 'right' }}>
                                          <button
                                            onClick={() => this.deleteModalItem('health', item.uuid)}
                                            style={{ width: 20, height: 20, lineHeight: '10px', padding: 0 }}
                                            className='btn btn-danger btn-sm'><FontAwesomeIcon icon={faTrash} color={brand_colors[this.props.defaultTheme.theme_id].color8} style={{ fontSize: 12 }} /></button>
                                        </td>
                                      </tr>
                                    ))}
                                </tbody>
                              </Table>
                            </Col>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>)}
                {/* Legal */}
                {this.state.formSectionActive.parent == this.formSections[2].uid && (
                  <div id={`form_section_${this.formSections[2].uid}`}>
                    <h5>{t(this.formSections[2].name)}</h5>
                    <Row>
                      <Col md={3}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t('Passport Number')}</Form.Label>
                          <Form.Control type="text"
                            defaultValue={this.state.passport_number}
                            onBlur={(e) => this.setState({ passport_number: e.target.value })} />
                        </Form.Group>
                      </Col>
                      <Col md={2}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t('Passport Validity')}</Form.Label>
                          <Flatpickr
                            className='form-control'
                            value={this.state.passport_validity}
                            onChange={([
                              date,
                            ]) => {
                              this.setState(
                                {
                                  passport_validity:
                                    moment(
                                      date
                                    ).format(
                                      'YYYY-MM-DD'
                                    ),
                                }
                              );
                            }}
                          />
                        </Form.Group>
                      </Col>
                      <Col md={3}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t('Residency Number')}</Form.Label>
                          <Form.Control type="text"
                            defaultValue={this.state.residency_number}
                            onBlur={(e) => this.setState({ residency_number: e.target.value })} />
                        </Form.Group>
                      </Col>
                      <Col md={2}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t('Residency Validity')}</Form.Label>
                          <Flatpickr
                            className='form-control'
                            value={this.state.residency_validity}
                            onChange={([
                              date,
                            ]) => {
                              this.setState(
                                {
                                  residency_validity:
                                    moment(
                                      date
                                    ).format(
                                      'YYYY-MM-DD'
                                    ),
                                }
                              );
                            }}
                          />
                        </Form.Group>
                      </Col>
                      <Col md={2}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t('Religion')}</Form.Label>
                          <Select
                            value={this.state.religion}
                            menuPortalTarget={document.body}
                            styles={{
                              menuPortal: (
                                base
                              ) => ({
                                ...base,
                                zIndex: 9999,
                              }),
                            }}
                            options={this.state.religions}
                            onChange={(obj) => this.setState({ religion: obj })}
                          />
                        </Form.Group>
                      </Col>
                      <Col md={4}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t('First Name AR')}</Form.Label>
                          <Form.Control type="text"
                            defaultValue={this.state.first_name_ar}
                            onBlur={(e) => this.setState({ first_name_ar: e.target.value })} />
                        </Form.Group>
                      </Col>
                      <Col md={4}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t('Middle Name AR')}</Form.Label>
                          <Form.Control type="text"
                            defaultValue={this.state.mid_name_ar}
                            onBlur={(e) => this.setState({ mid_name_ar: e.target.value })} />
                        </Form.Group>
                      </Col>
                      <Col md={4}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t('Last Name AR')}</Form.Label>
                          <Form.Control type="text"
                            defaultValue={this.state.last_name_ar}
                            onBlur={(e) => this.setState({ last_name_ar: e.target.value })} />
                        </Form.Group>
                      </Col>
                      <Col md={4}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t('First Name')}</Form.Label>
                          <Form.Control type="text"
                            defaultValue={this.state.first_name_en}
                            onBlur={(e) => this.setState({ first_name_en: e.target.value })} />
                        </Form.Group>
                      </Col>
                      <Col md={4}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t('Middle Name')}</Form.Label>
                          <Form.Control type="text"
                            defaultValue={this.state.mid_name_en}
                            onBlur={(e) => this.setState({ mid_name_en: e.target.value })} />
                        </Form.Group>
                      </Col>
                      <Col md={4}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t('Last Name')}</Form.Label>
                          <Form.Control type="text"
                            defaultValue={this.state.last_name_en}
                            onBlur={(e) => this.setState({ last_name_en: e.target.value })} />
                        </Form.Group>
                      </Col>
                    </Row>
                  </div>)}
                {/* Forms */}
                {/* {this.state.formSectionActive.parent == this.formSections[3].uid && (
                  <div id={`form_section_${this.formSections[3].uid}`}>
                    <h5 className='mb-3'>{t(this.formSections[3].name)}</h5>
                    <Row>
                      <Col md={12}>
                        <h6 className='mb-0'>{t('Legal Documents')}</h6>
                      </Col>
                      <Col md={3}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t('Passport')}</Form.Label>
                          <div className="p-3 pb-0">
                            <Form.Check
                              className='me-4'
                              inline
                              label={t('Yes')}
                              name="child_passcopy"
                              type='radio'
                              id='child_passcopy_1'
                              onChange={(e) => this.setState({ child_passcopy: 1 })}
                              checked={this.state.child_passcopy == 1}
                            />
                            <Form.Check
                              inline
                              label={t('No')}
                              name="child_passcopy"
                              type='radio'
                              id='child_passcopy_2'
                              onChange={(e) => this.setState({ child_passcopy: 0 })}
                              checked={this.state.child_passcopy == 0}
                            />
                          </div>
                        </Form.Group>
                      </Col>
                      <Col md={3}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t('Visa')}</Form.Label>
                          <div className="p-3 pb-0">
                            <Form.Check
                              className='me-4'
                              inline
                              label={t('Yes')}
                              name="visa_copy"
                              type='radio'
                              id='visa_copy_1'
                              onChange={(e) => this.setState({ visa_copy: 1 })}
                              checked={this.state.visa_copy == 1}
                            />
                            <Form.Check
                              inline
                              label={t('No')}
                              name="visa_copy"
                              type='radio'
                              id='visa_copy_2'
                              onChange={(e) => this.setState({ visa_copy: 0 })}
                              checked={this.state.visa_copy == 0}
                            />
                          </div>
                        </Form.Group>
                      </Col>
                      <Col md={3}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t('ID')}</Form.Label>
                          <div className="p-3 pb-0">
                            <Form.Check
                              className='me-4'
                              inline
                              label={t('Yes')}
                              name="child_eid"
                              type='radio'
                              id='child_eid_1'
                              onChange={(e) => this.setState({ child_eid: 1 })}
                              checked={this.state.child_eid == 1}
                            />
                            <Form.Check
                              inline
                              label={t('No')}
                              name="child_eid"
                              type='radio'
                              id='child_eid_2'
                              onChange={(e) => this.setState({ child_eid: 0 })}
                              checked={this.state.child_eid == 0}
                            />
                          </div>
                        </Form.Group>
                      </Col>
                      <Col md={3}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t('Birth Certificate')}</Form.Label>
                          <div className="p-3 pb-0">
                            <Form.Check
                              className='me-4'
                              inline
                              label={t('Yes')}
                              name="birth_certificate"
                              type='radio'
                              id='birth_certificate_1'
                              onChange={(e) => this.setState({ birth_certificate: 1 })}
                              checked={this.state.birth_certificate == 1}
                            />
                            <Form.Check
                              inline
                              label={t('No')}
                              name="birth_certificate"
                              type='radio'
                              id='birth_certificate_2'
                              onChange={(e) => this.setState({ birth_certificate: 0 })}
                              checked={this.state.birth_certificate == 0}
                            />
                          </div>
                        </Form.Group>
                      </Col>
                      <Col md={12}><hr /></Col>
                      <Col md={12}>
                        <h6 className='mb-0'>{t('Health Documents')}</h6>
                      </Col>
                      <Col md={3}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t('Medical Form')}</Form.Label>
                          <div className="p-3 pb-0">
                            <Form.Check
                              className='me-4'
                              inline
                              label={t('Yes')}
                              name="med_form"
                              type='radio'
                              id='med_form_1'
                              onChange={(e) => this.setState({ med_form: 1 })}
                              checked={this.state.med_form == 1}
                            />
                            <Form.Check
                              inline
                              label={t('No')}
                              name="med_form"
                              type='radio'
                              id='med_form_2'
                              onChange={(e) => this.setState({ med_form: 0 })}
                              checked={this.state.med_form == 0}
                            />
                          </div>
                        </Form.Group>
                      </Col>
                      <Col md={3}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t('Indemnity Form')}</Form.Label>
                          <div className="p-3 pb-0">
                            <Form.Check
                              className='me-4'
                              inline
                              label={t('Yes')}
                              name="indemnity_form"
                              type='radio'
                              id='indemnity_form_1'
                              onChange={(e) => this.setState({ indemnity_form: 1 })}
                              checked={this.state.indemnity_form == 1}
                            />
                            <Form.Check
                              inline
                              label={t('No')}
                              name="indemnity_form"
                              type='radio'
                              id='indemnity_form_2'
                              onChange={(e) => this.setState({ indemnity_form: 0 })}
                              checked={this.state.indemnity_form == 0}
                            />
                          </div>
                        </Form.Group>
                      </Col>
                      <Col md={3}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t('Child Vaccination')}</Form.Label>
                          <div className="p-3 pb-0">
                            <Form.Check
                              className='me-4'
                              inline
                              label={t('Yes')}
                              name="child_vacc"
                              type='radio'
                              id='child_vacc_1'
                              onChange={(e) => this.setState({ child_vacc: 1 })}
                              checked={this.state.child_vacc == 1}
                            />
                            <Form.Check
                              inline
                              label={t('No')}
                              name="child_vacc"
                              type='radio'
                              id='child_vacc_2'
                              onChange={(e) => this.setState({ child_vacc: 0 })}
                              checked={this.state.child_vacc == 0}
                            />
                          </div>
                        </Form.Group>
                      </Col>
                      <Col md={3}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t('Photo')}</Form.Label>
                          <div className="p-3 pb-0">
                            <Form.Check
                              className='me-4'
                              inline
                              label={t('Yes')}
                              name="Photo"
                              type='radio'
                              id='Photo_1'
                              onChange={(e) => this.setState({ Photo: 1 })}
                              checked={this.state.Photo == 1}
                            />
                            <Form.Check
                              inline
                              label={t('No')}
                              name="Photo"
                              type='radio'
                              id='Photo_2'
                              onChange={(e) => this.setState({ Photo: 0 })}
                              checked={this.state.Photo == 0}
                            />
                          </div>
                        </Form.Group>
                      </Col>
                    </Row>
                  </div>)} */}
                {/* Documents */}
                {this.state.formSectionActive.parent == this.formSections[3].uid && (
                  <div id={`form_section_${this.formSections[3].uid}`}>
                    <h5>{t(this.formSections[3].name)}</h5>
                    <Row>
                      <Col md={12} className='mb-2'>
                        <Alert variant='info' className='mb-0'>{t('File Format should be .PNG .JPEG .JPG .PDF not exceeding 500kb as required.')}</Alert>
                      </Col>


                      {/* NEW STRUCTURE OF FILES */}
                      <Col md={12}>
                        {/* ADD NEW */}
                        <Row>
                          <Col md="auto">
                            <div style={{ flexDirection: 'column', width: 200, height: 200 }} className='pic-prev d-flex justify-content-center'>
                              <div style={{ borderRadius: 5, borderStyle: 'dashed', borderWidth: 2, borderColor: '#ced4da', height: 348, display: 'flex', overflow: 'hidden', }}>

                                {this.state.attachments_parent_child_temp.prev
                                  ? <div className='d-flex justify-content-center align-items-center'
                                    style={{ flex: 1, position: 'relative' }}>
                                    <button
                                      style={{ padding: 3, border: 3, background: 'none', position: 'absolute', top: 0, right: 0, }}
                                      onClick={() => {
                                        this.setState({ attachments_parent_child_temp: { prev: null, data: null } })
                                      }}>
                                      <FontAwesomeIcon icon={faTimesCircle} color='rgb(220, 53, 69)' style={{ fontSize: 20, }} />
                                    </button>
                                    {(this.state.attachments_parent_child_temp.data.type.includes('pdf'))
                                      ? (<FontAwesomeIcon icon={faFilePdf} style={{ fontSize: 20, color: brand_colors[this.props.defaultTheme.theme_id].color11 }} />)
                                      : (<img src={this.state.attachments_parent_child_temp.prev} onClick={() => window.open(this.state.attachments_parent_child_temp.prev)} alt={t('Preview')} style={{ width: '100%', height: '100%', objectFit: 'cover' }} className="cursor-pointer" />)}
                                  </div>
                                  : <div className='d-flex justify-content-center align-items-center' style={{ flex: 1, position: 'relative', }} >
                                    <button onClick={() => this.attachments_parent_child_temp.current.click()} style={{ background: 'none', border: 0, flex: 1, }}>
                                      <FontAwesomeIcon icon={faPlus} style={{ fontSize: 30, }} />
                                      <Form.Control className='d-none' ref={this.attachments_parent_child_temp} type='file' onChange={this.previewFile} name='attachments_parent_child_temp' accept='.jpg, .jpeg, .png, .pdf' />
                                    </button>
                                  </div>
                                }

                              </div>
                            </div>
                          </Col>
                          <Col md="auto" >
                            <div className='d-flex flex-column' style={{ width: 200 }}></div>
                            <div className='mb-1'>
                              <Select
                                options={this.state.documentTypes.data.filter((v, i) => {
                                  let find_attachments_parent_child = this.state.attachments_parent_child.find((vv, ii) => vv.apc_attachment_type == v.value)
                                  if (find_attachments_parent_child) {
                                    return false
                                  }
                                  return true
                                })}
                                value={this.state.documentTypes.selected}
                                menuPortalTarget={document.body}
                                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999, }), }}
                                onChange={(obj) => this.setState({ documentTypes: { ...this.state.documentTypes, selected: obj } })}
                              />
                            </div>
                            <div className='d-grid'>
                              <Button onClick={() => {
                                let documentTypes = this.state.documentTypes
                                let attachments_parent_child_temp = this.state.attachments_parent_child_temp
                                if (!documentTypes.selected) {
                                  toast.error(t('Please select File Type!'));
                                  return
                                }
                                if (!attachments_parent_child_temp.prev) {
                                  toast.error(t('Please select a File!'));
                                  return
                                }
                                let attachments_parent_child = this.state.attachments_parent_child
                                attachments_parent_child.push({
                                  apc_attachment_name_prev: this.state.attachments_parent_child_temp.prev,
                                  apc_attachment_name: this.state.attachments_parent_child_temp.data,
                                  apc_attachment_type: documentTypes.selected.value,
                                  apc_folder: null,
                                  apc_id: 0,
                                  apc_table_name: 'parents',
                                })
                                this.setState({ attachments_parent_child, documentTypes: { ...documentTypes, selected: null }, attachments_parent_child_temp: { prev: null, data: null } })
                              }}>
                                <FontAwesomeIcon icon={faPlus} />
                              </Button>
                            </div>
                          </Col>
                        </Row>

                        {/* attachments_parent_child DATA */}
                        <Row>
                          {this.state.attachments_parent_child.length == 0 &&
                            <Col className='text-center py-5 text-muted'>
                              {t('No data found!')}
                            </Col>
                          }
                          {this.state.attachments_parent_child.length > 0 && this.state.attachments_parent_child.map((item, index) => {

                            let apc_attachment_name_URL = (item.apc_attachment_name_prev)
                              ? item.apc_attachment_name_prev
                              : (item.apc_folder)
                                ? portalURL + item.center_id + '/' + item.apc_folder + '/' + item.apc_attachment_name
                                : portalURL + item.center_id + '/attachments_parent_child/' + item.apc_attachment_name

                            let apc_attachment_type = this.state.documentTypes.data.find((dt) => dt.value == item.apc_attachment_type)

                            return <Col md={3}>
                              <Form.Group className="mb-3">
                                <Form.Label>{apc_attachment_type.label}</Form.Label>
                                <div style={{ flexDirection: 'column' }} className='pic-prev d-flex justify-content-center'>
                                  <div style={{ borderRadius: 5, borderStyle: 'dashed', borderWidth: 2, borderColor: brand_colors[this.props.defaultTheme.theme_id].color10, height: 200, display: 'flex', overflow: 'hidden', }}>
                                    {<div className='d-flex justify-content-center align-items-center'
                                      style={{ flex: 1, position: 'relative' }}>
                                      <button style={{ padding: 3, border: 3, background: 'none', position: 'absolute', top: 0, right: 0, }} >
                                        <FontAwesomeIcon icon={faLink} color={brand_colors[this.props.defaultTheme.theme_id].color4} style={{ fontSize: 20, }} className='me-1' onClick={() => window.open(apc_attachment_name_URL)} />
                                        <FontAwesomeIcon icon={faTimesCircle} color={brand_colors[this.props.defaultTheme.theme_id].color11} style={{ fontSize: 20, }} onClick={() => {
                                          let attachments_parent_child = this.state.attachments_parent_child
                                          attachments_parent_child.splice(index, 1)
                                          this.setState({ attachments_parent_child })
                                        }} />
                                      </button>
                                      {((item.apc_attachment_name.type && item.apc_attachment_name.type.includes('pdf')) || (!Array.isArray(item.apc_attachment_name) && item.apc_attachment_name.toString().toLowerCase().indexOf(".pdf") >= 0))
                                        ? (apc_attachment_name_URL != 'pdf')
                                          ? <embed src={apc_attachment_name_URL} style={{ width: '100%', height: '100%' }} />
                                          : <FontAwesomeIcon icon={faFilePdf} style={{ fontSize: 20, color: brand_colors[this.props.defaultTheme.theme_id].color11 }} />
                                        : (<img src={apc_attachment_name_URL} onClick={() => window.open(apc_attachment_name_URL)} alt={t('Preview')} style={{ width: '100%', height: '100%', objectFit: 'cover' }} className="cursor-pointer" />)}
                                    </div>}
                                  </div>
                                </div>
                              </Form.Group>
                            </Col>
                          })}
                        </Row>
                      </Col>
                      {/* END NEW STRUCTURE OF FILES */}
                      <hr></hr>
                      <Col md={12}>
                        <Alert variant='warning' className='mb-0'>{t('These files will be moved to the upper section. If you want to add or update any file please do it in upper section.')}</Alert>
                      </Col>

                      {/* Passport-> child_passport, child_passport_prev, child_passport_delete_flag, this.child_passport */}
                      <Col md={3}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t('Passport')}</Form.Label>
                          <div style={{ flexDirection: 'column' }} className='pic-prev d-flex justify-content-center'>
                            <div style={{ borderRadius: 5, borderStyle: 'dashed', borderWidth: 2, borderColor: '#ced4da', height: 200, display: 'flex', overflow: 'hidden', }}>
                              {this.state.child_passport && this.state.child_passport_prev
                                ? (<div className='d-flex justify-content-center align-items-center'
                                  style={{ flex: 1, position: 'relative' }}>
                                  <button
                                    style={{ padding: 3, border: 3, background: 'none', position: 'absolute', top: 0, right: 0, }}
                                    onClick={() => {
                                      this.setState({ child_passport: '', child_passport_prev: '', child_passport_delete_flag: false, })
                                      this.child_passport.current.value = null;
                                    }}>
                                    <FontAwesomeIcon icon={faTimesCircle} color='rgb(220, 53, 69)' style={{ fontSize: 20, }} />
                                  </button>
                                  {((Array.isArray(this.state.child_passport) && this.state.child_passport.type.includes('pdf')) || (!Array.isArray(this.state.child_passport) && this.state.child_passport.toString().toLowerCase().indexOf(".pdf") >= 0))
                                    ? (<embed src={this.state.child_passport_prev} style={{ width: '100%', height: '100%' }} />)
                                    : (<img src={this.state.child_passport_prev} onClick={() => window.open(this.state.child_passport_prev)} alt={t('Preview')} width='100' className="cursor-pointer" />)}
                                </div>)
                                : (<button onClick={() => this.child_passport.current.click()} style={{ background: 'none', border: 0, flex: 1, }}>
                                  <FontAwesomeIcon icon={faPlus} style={{ fontSize: 30, }} className="cursor-pointer" />
                                </button>)
                              }
                            </div>
                            <Form.Control className='d-none' ref={this.child_passport} type='file' onChange={this.previewFile} name='child_passport' accept='.jpg, .jpeg, .png, .pdf' />
                          </div>
                        </Form.Group>
                      </Col>

                      {/* ID-> child_eid_page, child_eid_page_prev, child_eid_page_delete_flag, this.child_eid_page */}
                      <Col md={3}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t('ID')}</Form.Label>
                          <div style={{ flexDirection: 'column' }} className='pic-prev d-flex justify-content-center'>
                            <div style={{ borderRadius: 5, borderStyle: 'dashed', borderWidth: 2, borderColor: '#ced4da', height: 200, display: 'flex', overflow: 'hidden', }}>
                              {this.state.child_eid_page && this.state.child_eid_page_prev
                                ? (<div className='d-flex justify-content-center align-items-center'
                                  style={{ flex: 1, position: 'relative' }}>
                                  <button
                                    style={{ padding: 3, border: 3, background: 'none', position: 'absolute', top: 0, right: 0, }}
                                    onClick={() => {
                                      this.setState({ child_eid_page: '', child_eid_page_prev: '', child_eid_page_delete_flag: false, })
                                      this.child_eid_page.current.value = null;
                                    }}>
                                    <FontAwesomeIcon icon={faTimesCircle} color='rgb(220, 53, 69)' style={{ fontSize: 20, }} />
                                  </button>
                                  {((Array.isArray(this.state.child_eid_page) && this.state.child_eid_page.type.includes('pdf')) || (!Array.isArray(this.state.child_eid_page) && this.state.child_eid_page.toString().toLowerCase().indexOf(".pdf") >= 0))
                                    ? (<embed src={this.state.child_eid_page_prev} style={{ width: '100%', height: '100%' }} />)
                                    : (<img src={this.state.child_eid_page_prev} onClick={() => window.open(this.state.child_eid_page_prev)} alt={t('Preview')} width='100' className="cursor-pointer" />)}
                                </div>)
                                : (<button onClick={() => this.child_eid_page.current.click()} style={{ background: 'none', border: 0, flex: 1, }}>
                                  <FontAwesomeIcon icon={faPlus} style={{ fontSize: 30, }} />
                                </button>)
                              }
                            </div>
                            <Form.Control className='d-none' ref={this.child_eid_page} type='file' onChange={this.previewFile} name='child_eid_page' accept='.jpg, .jpeg, .png, .pdf' />
                          </div>
                        </Form.Group>
                      </Col>

                      {/* Visa-> visa_page, visa_page_prev, visa_page_delete_flag, this.visa_page */}
                      <Col md={3}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t('Visa')}</Form.Label>
                          <div style={{ flexDirection: 'column' }} className='pic-prev d-flex justify-content-center'>
                            <div style={{ borderRadius: 5, borderStyle: 'dashed', borderWidth: 2, borderColor: '#ced4da', height: 200, display: 'flex', overflow: 'hidden', }}>
                              {this.state.visa_page && this.state.visa_page_prev
                                ? (<div className='d-flex justify-content-center align-items-center'
                                  style={{ flex: 1, position: 'relative' }}>
                                  <button
                                    style={{ padding: 3, border: 3, background: 'none', position: 'absolute', top: 0, right: 0, }}
                                    onClick={() => {
                                      this.setState({ visa_page: '', visa_page_prev: '', visa_page_delete_flag: false, })
                                      this.visa_page.current.value = null;
                                    }}>
                                    <FontAwesomeIcon icon={faTimesCircle} color='rgb(220, 53, 69)' style={{ fontSize: 20, }} />
                                  </button>
                                  {((Array.isArray(this.state.visa_page) && this.state.visa_page.type.includes('pdf')) || (!Array.isArray(this.state.visa_page) && this.state.visa_page.toString().toLowerCase().indexOf(".pdf") >= 0))
                                    ? (<embed src={this.state.visa_page_prev} style={{ width: '100%', height: '100%' }} />)
                                    : (<img src={this.state.visa_page_prev} onClick={() => window.open(this.state.visa_page_prev)} alt={t('Preview')} width='100' className="cursor-pointer" />)}
                                </div>)
                                : (<button onClick={() => this.visa_page.current.click()} style={{ background: 'none', border: 0, flex: 1, }}>
                                  <FontAwesomeIcon icon={faPlus} style={{ fontSize: 30, }} />
                                </button>)
                              }
                            </div>
                            <Form.Control className='d-none' ref={this.visa_page} type='file' onChange={this.previewFile} name='visa_page' accept='.jpg, .jpeg, .png, .pdf' />
                          </div>
                        </Form.Group>
                      </Col>

                      {/* Indemnity Form-> indemnity_formcopy, indemnity_formcopy_prev, indemnity_formcopy_delete_flag, this.indemnity_formcopy */}
                      <Col md={3}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t('Indemnity Form')}</Form.Label>
                          <div style={{ flexDirection: 'column' }} className='pic-prev d-flex justify-content-center'>
                            <div style={{ borderRadius: 5, borderStyle: 'dashed', borderWidth: 2, borderColor: '#ced4da', height: 200, display: 'flex', overflow: 'hidden', }}>
                              {this.state.indemnity_formcopy && this.state.indemnity_formcopy_prev
                                ? (<div className='d-flex justify-content-center align-items-center'
                                  style={{ flex: 1, position: 'relative' }}>
                                  <button
                                    style={{ padding: 3, border: 3, background: 'none', position: 'absolute', top: 0, right: 0, }}
                                    onClick={() => {
                                      this.setState({ indemnity_formcopy: '', indemnity_formcopy_prev: '', indemnity_formcopy_delete_flag: false, })
                                      this.indemnity_formcopy.current.value = null;
                                    }}>
                                    <FontAwesomeIcon icon={faTimesCircle} color='rgb(220, 53, 69)' style={{ fontSize: 20, }} />
                                  </button>
                                  {((Array.isArray(this.state.indemnity_formcopy) && this.state.indemnity_formcopy.type.includes('pdf')) || (!Array.isArray(this.state.indemnity_formcopy) && this.state.indemnity_formcopy.toString().toLowerCase().indexOf(".pdf") >= 0))
                                    ? (<embed src={this.state.indemnity_formcopy_prev} style={{ width: '100%', height: '100%' }} />)
                                    : (<img src={this.state.indemnity_formcopy_prev} onClick={() => window.open(this.state.indemnity_formcopy_prev)} alt={t('Preview')} width='100' className="cursor-pointer" />)}
                                </div>)
                                : (<button onClick={() => this.indemnity_formcopy.current.click()} style={{ background: 'none', border: 0, flex: 1, }}>
                                  <FontAwesomeIcon icon={faPlus} style={{ fontSize: 30, }} />
                                </button>)
                              }
                            </div>
                            <Form.Control className='d-none' ref={this.indemnity_formcopy} type='file' onChange={this.previewFile} name='indemnity_formcopy' accept='.jpg, .jpeg, .png, .pdf' />
                          </div>
                        </Form.Group>
                      </Col>

                      {/* Medical Form-> med_formcopy, med_formcopy_prev, med_formcopy_delete_flag, this.med_formcopy */}
                      <Col md={3}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t('Medical Form')}</Form.Label>
                          <div style={{ flexDirection: 'column' }} className='pic-prev d-flex justify-content-center'>
                            <div style={{ borderRadius: 5, borderStyle: 'dashed', borderWidth: 2, borderColor: '#ced4da', height: 200, display: 'flex', overflow: 'hidden', }}>
                              {this.state.med_formcopy && this.state.med_formcopy_prev
                                ? (<div className='d-flex justify-content-center align-items-center'
                                  style={{ flex: 1, position: 'relative' }}>
                                  <button
                                    style={{ padding: 3, border: 3, background: 'none', position: 'absolute', top: 0, right: 0, }}
                                    onClick={() => {
                                      this.setState({ med_formcopy: '', med_formcopy_prev: '', med_formcopy_delete_flag: false, })
                                      this.med_formcopy.current.value = null;
                                    }}>
                                    <FontAwesomeIcon icon={faTimesCircle} color='rgb(220, 53, 69)' style={{ fontSize: 20, }} />
                                  </button>
                                  {((Array.isArray(this.state.med_formcopy) && this.state.med_formcopy.type.includes('pdf')) || (!Array.isArray(this.state.med_formcopy) && this.state.med_formcopy.toString().toLowerCase().indexOf(".pdf") >= 0))
                                    ? (<embed src={this.state.med_formcopy_prev} style={{ width: '100%', height: '100%' }} />)
                                    : (<img src={this.state.med_formcopy_prev} onClick={() => window.open(this.state.med_formcopy_prev)} alt={t('Preview')} width='100' className="cursor-pointer" />)}
                                </div>)
                                : (<button onClick={() => this.med_formcopy.current.click()} style={{ background: 'none', border: 0, flex: 1, }}>
                                  <FontAwesomeIcon icon={faPlus} style={{ fontSize: 30, }} />
                                </button>)
                              }
                            </div>
                            <Form.Control className='d-none' ref={this.med_formcopy} type='file' onChange={this.previewFile} name='med_formcopy' accept='.jpg, .jpeg, .png, .pdf' />
                          </div>
                        </Form.Group>
                      </Col>

                      {/* Vaccination-> vaccination_copy, vaccination_copy_prev, vaccination_copy_delete_flag, this.vaccination_copy */}
                      <Col md={3}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t('Vaccination')}</Form.Label>
                          <div style={{ flexDirection: 'column' }} className='pic-prev d-flex justify-content-center'>
                            <div style={{ borderRadius: 5, borderStyle: 'dashed', borderWidth: 2, borderColor: '#ced4da', height: 200, display: 'flex', overflow: 'hidden', }}>
                              {this.state.vaccination_copy && this.state.vaccination_copy_prev
                                ? (<div className='d-flex justify-content-center align-items-center'
                                  style={{ flex: 1, position: 'relative' }}>
                                  <button
                                    style={{ padding: 3, border: 3, background: 'none', position: 'absolute', top: 0, right: 0, }}
                                    onClick={() => {
                                      this.setState({ vaccination_copy: '', vaccination_copy_prev: '', vaccination_copy_delete_flag: false, })
                                      this.vaccination_copy.current.value = null;
                                    }}>
                                    <FontAwesomeIcon icon={faTimesCircle} color='rgb(220, 53, 69)' style={{ fontSize: 20, }} />
                                  </button>
                                  {((Array.isArray(this.state.vaccination_copy) && this.state.vaccination_copy.type.includes('pdf')) || (!Array.isArray(this.state.vaccination_copy) && this.state.vaccination_copy.toString().toLowerCase().indexOf(".pdf") >= 0))
                                    ? (<embed src={this.state.vaccination_copy_prev} style={{ width: '100%', height: '100%' }} />)
                                    : (<img src={this.state.vaccination_copy_prev} onClick={() => window.open(this.state.vaccination_copy_prev)} alt={t('Preview')} width='100' className="cursor-pointer" />)}
                                </div>)
                                : (<button onClick={() => this.vaccination_copy.current.click()} style={{ background: 'none', border: 0, flex: 1, }}>
                                  <FontAwesomeIcon icon={faPlus} style={{ fontSize: 30, }} />
                                </button>)
                              }
                            </div>
                            <Form.Control className='d-none' ref={this.vaccination_copy} type='file' onChange={this.previewFile} name='vaccination_copy' accept='.jpg, .jpeg, .png, .pdf' />
                          </div>
                        </Form.Group>
                      </Col>

                      {/* Birth Certificate-> birth_certcopy, birth_certcopy_prev, birth_certcopy_delete_flag, this.birth_certcopy */}
                      <Col md={3}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t('Birth Certificate')}</Form.Label>
                          < div style={{ flexDirection: 'column' }} className='pic-prev d-flex justify-content-center'>
                            <div style={{ borderRadius: 5, borderStyle: 'dashed', borderWidth: 2, borderColor: '#ced4da', height: 200, display: 'flex', overflow: 'hidden', }}>
                              {this.state.birth_certcopy && this.state.birth_certcopy_prev
                                ? (<div className='d-flex justify-content-center align-items-center'
                                  style={{ flex: 1, position: 'relative' }}>
                                  <button
                                    style={{ padding: 3, border: 3, background: 'none', position: 'absolute', top: 0, right: 0, }}
                                    onClick={() => {
                                      this.setState({ birth_certcopy: '', birth_certcopy_prev: '', birth_certcopy_delete_flag: false, })
                                      this.birth_certcopy.current.value = null;
                                    }}>
                                    <FontAwesomeIcon icon={faTimesCircle} color='rgb(220, 53, 69)' style={{ fontSize: 20, }} />
                                  </button>
                                  {((Array.isArray(this.state.birth_certcopy) && this.state.birth_certcopy.type.includes('pdf')) || (!Array.isArray(this.state.birth_certcopy) && this.state.birth_certcopy.toString().toLowerCase().indexOf(".pdf") >= 0))
                                    ? (<embed src={this.state.birth_certcopy_prev} style={{ width: '100%', height: '100%' }} />)
                                    : (<img src={this.state.birth_certcopy_prev} onClick={() => window.open(this.state.birth_certcopy_prev)} alt={t('Preview')} width='100' className="cursor-pointer" />)}
                                </div>)
                                : (<button onClick={() => this.birth_certcopy.current.click()} style={{ background: 'none', border: 0, flex: 1, }}>
                                  <FontAwesomeIcon icon={faPlus} style={{ fontSize: 30, }} />
                                </button>)
                              }
                            </div>
                            <Form.Control className='d-none' ref={this.birth_certcopy} type='file' onChange={this.previewFile} name='birth_certcopy' accept='.jpg, .jpeg, .png, .pdf' />
                          </div>
                        </Form.Group>
                      </Col>





                    </Row>
                  </div>)}
                {/* Accounts */}
                {this.state.formSectionActive.parent == this.formSections[4].uid && (
                  <div id={`form_section_${this.formSections[4].uid}`}>
                    <h5>{t(this.formSections[4].name)}</h5>
                    <Row>
                      <Col md={4}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t('Chart')} <span className='text-danger'>*</span></Form.Label>
                          <Select
                            classNamePrefix='custom-styled-select'
                            value={this.state.chapt_id}
                            menuPortalTarget={document.body}
                            styles={{
                              menuPortal: (
                                base
                              ) => ({
                                ...base,
                                zIndex: 9999,
                              }),
                            }}
                            options={this.state.mainAccounts}
                            onChange={(obj) => this.setState({ chapt_id: obj })}
                          />
                        </Form.Group>
                      </Col>
                      <Col md={4}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t('Account Number')}</Form.Label>
                          <Form.Control type="text"
                            defaultValue={this.state.account_num}
                            onBlur={(e) => this.setState({ account_num: e.target.value })} />
                        </Form.Group>
                      </Col>
                      <Col md={4}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t('3rd Party Account')}</Form.Label>
                          <Form.Control type="text"
                            defaultValue={this.state.qb_account}
                            onBlur={(e) => this.setState({ qb_account: e.target.value })} />
                        </Form.Group>
                      </Col>
                      <Col md={4}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t('Sponsor Name')}</Form.Label>
                          <Form.Control type="text"
                            defaultValue={this.state.sponsor_name}
                            onBlur={(e) => this.setState({ sponsor_name: e.target.value })} />
                        </Form.Group>
                      </Col>
                      <Col md={4}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t('Sponsor TRN')}</Form.Label>
                          <Form.Control type="text"
                            defaultValue={this.state.sponsor_trn}
                            onBlur={(e) => this.setState({ sponsor_trn: e.target.value })} />
                        </Form.Group>
                      </Col>
                    </Row>
                  </div>)}
                {/* Others */}
                {this.state.formSectionActive.parent == this.formSections[5].uid && (
                  <div id={`form_section_${this.formSections[5].uid}`}>
                    <h5>{t(this.formSections[5].name)}</h5>
                    <Row>
                      <Col md={4}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t('Activities Notes')}</Form.Label>
                          <Form.Control type="text"
                            defaultValue={this.state.activity_notes}
                            onBlur={(e) => this.setState({ activity_notes: e.target.value })} />
                        </Form.Group>
                      </Col>
                      <Col md={4}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t('1st Language')}</Form.Label>
                          <Form.Control type="text"
                            defaultValue={this.state.first_lang}
                            onBlur={(e) => this.setState({ first_lang: e.target.value })} />
                        </Form.Group>
                      </Col>
                      <Col md={4}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t('2nd Language')}</Form.Label>
                          <Form.Control type="text"
                            defaultValue={this.state.second_lang}
                            onBlur={(e) => this.setState({ second_lang: e.target.value })} />
                        </Form.Group>
                      </Col>
                      <Col md={3}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t('Transport Type')}</Form.Label>
                          <Select
                            value={this.state.transport_type}
                            menuPortalTarget={document.body}
                            styles={{
                              menuPortal: (
                                base
                              ) => ({
                                ...base,
                                zIndex: 9999,
                              }),
                            }}
                            options={this.state.transportTypes}
                            onChange={(obj) => this.setState({ transport_type: obj })}
                          />
                        </Form.Group>
                      </Col>
                      <Col md={3}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t('Child Not Active Notes')}</Form.Label>
                          <Form.Control type="text"
                            defaultValue={this.state.child_not_active_notes}
                            onBlur={(e) => this.setState({ child_not_active_notes: e.target.value })} />
                        </Form.Group>
                      </Col>
                      <Col md={3}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t('Previous School')}</Form.Label>
                          <Form.Control type="text"
                            defaultValue={this.state.prev_school}
                            onBlur={(e) => this.setState({ prev_school: e.target.value })} />
                        </Form.Group>
                      </Col>
                      <Col md={3}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t('Joining Grade')}</Form.Label>
                          <Select
                            value={this.state.joining_grade}
                            menuPortalTarget={document.body}
                            styles={{
                              menuPortal: (
                                base
                              ) => ({
                                ...base,
                                zIndex: 9999,
                              }),
                            }}
                            options={this.state.joiningGrades}
                            onChange={(obj) => this.setState({ joining_grade: obj })}
                          />
                        </Form.Group>
                      </Col>
                      <Col md={12}><hr /></Col>
                      <Col md={12}>
                        <h6 className='mb-0'>{t('Pictures and sharing Consent')}</h6>
                      </Col>
                      <Col md={3}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t('On App')}</Form.Label>
                          <div className="p-3 pb-0">
                            <Form.Check
                              className='me-4'
                              inline
                              label={t('Allow')}
                              name="take_pictures"
                              type='radio'
                              id='take_pictures_1'
                              onChange={(e) => this.setState({ take_pictures: 1 })}
                              checked={this.state.take_pictures == 1}
                            />
                            <Form.Check
                              inline
                              label={t('No')}
                              name="take_pictures"
                              type='radio'
                              id='take_pictures_2'
                              onChange={(e) => this.setState({ take_pictures: 0 })}
                              checked={this.state.take_pictures == 0}
                            />
                          </div>
                        </Form.Group>
                      </Col>
                      <Col md={3}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t('For Advertising')}</Form.Label>
                          <div className="p-3 pb-0">
                            <Form.Check
                              className='me-4'
                              inline
                              label={t('Allow')}
                              name="pic_media"
                              type='radio'
                              id='pic_media_1'
                              onChange={(e) => this.setState({ pic_media: 1 })}
                              checked={this.state.pic_media == 1}
                            />
                            <Form.Check
                              inline
                              label={t('No')}
                              name="pic_media"
                              type='radio'
                              id='pic_media_2'
                              onChange={(e) => this.setState({ pic_media: 0 })}
                              checked={this.state.pic_media == 0}
                            />
                          </div>
                        </Form.Group>
                      </Col>
                      <Col md={3}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t('On Social Media')}</Form.Label>
                          <div className="p-3 pb-0">
                            <Form.Check
                              className='me-4'
                              inline
                              label={t('Allow')}
                              name="pic_social"
                              type='radio'
                              id='pic_social_1'
                              onChange={(e) => this.setState({ pic_social: 1 })}
                              checked={this.state.pic_social == 1}
                            />
                            <Form.Check
                              inline
                              label={t('No')}
                              name="pic_social"
                              type='radio'
                              id='pic_social_2'
                              onChange={(e) => this.setState({ pic_social: 0 })}
                              checked={this.state.pic_social == 0}
                            />
                          </div>
                        </Form.Group>
                      </Col>
                      <Col md={3}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t('Inside School')}</Form.Label>
                          <div className="p-3 pb-0">
                            <Form.Check
                              className='me-4'
                              inline
                              label={t('Allow')}
                              name="pic_nursery"
                              type='radio'
                              id='pic_nursery_1'
                              onChange={(e) => this.setState({ pic_nursery: 1 })}
                              checked={this.state.pic_nursery == 1}
                            />
                            <Form.Check
                              inline
                              label={t('No')}
                              name="pic_nursery"
                              type='radio'
                              id='pic_nursery_2'
                              onChange={(e) => this.setState({ pic_nursery: 0 })}
                              checked={this.state.pic_nursery == 0}
                            />
                          </div>
                        </Form.Group>
                      </Col>
                    </Row>
                  </div>)}
              </Col>
            </div>
          </Offcanvas.Body>
        </Offcanvas>
        <Modal
          show={this.state.modal.config.show}
          onHide={this.handleCloseModal}
          size={this.state.modal.config.size}>
          <Modal.Header closeButton>
            <Modal.Title>{t('Add') + ' ' + t(this.state.modal.config.name)}</Modal.Title>
          </Modal.Header>
          <Modal.Body className='custom-styled-form'>
            {this.state.modal.config.name === 'Vaccination' &&
              <Row>
                <Col md={6}>
                  <Row>
                    <Col md={12}>
                      <Form.Group className="mb-3">
                        <Form.Label>{t('Type')}</Form.Label>
                        <Select
                          value={this.state.modal.healthData.child_health_name}
                          menuPortalTarget={document.body}
                          styles={{
                            menuPortal: (
                              base
                            ) => ({
                              ...base,
                              zIndex: 9999,
                            }),
                          }}
                          options={healthModalDDL}
                          onChange={(obj) => this.setState({
                            modal: {
                              ...this.state.modal,
                              healthData: {
                                ...this.state.modal.healthData,
                                child_health_name: obj
                              }
                            }
                          })}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={12}>
                      <Form.Group className="mb-3">
                        <Form.Label>{t('Dose')}</Form.Label>
                        <Select
                          value={this.state.modal.healthData.child_health_desc}
                          menuPortalTarget={document.body}
                          styles={{
                            menuPortal: (
                              base
                            ) => ({
                              ...base,
                              zIndex: 9999,
                            }),
                          }}
                          options={this.state.doses}
                          onChange={(obj) => this.setState({
                            modal: {
                              ...this.state.modal,
                              healthData: {
                                ...this.state.modal.healthData,
                                child_health_desc: obj
                              }
                            }
                          })}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={12}>
                      <Form.Group className="mb-3">
                        <Form.Label>{t('Date')}</Form.Label>
                        <Flatpickr
                          className='form-control'
                          value={this.state.modal.healthData.child_health_type}
                          onChange={([
                            date,
                          ]) => {
                            this.setState({
                              modal: {
                                ...this.state.modal,
                                healthData: {
                                  ...this.state.modal.healthData,
                                  child_health_type: moment(
                                    date
                                  ).format(
                                    'YYYY-MM-DD'
                                  ),
                                }
                              }
                            });
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t('Attachment')}</Form.Label>
                    <div
                      style={{
                        flexDirection:
                          'column',
                      }}
                      className='pic-prev d-flex justify-content-center'>
                      <div
                        style={{
                          borderRadius: 5,
                          borderStyle:
                            'dashed',
                          borderWidth: 2,
                          borderColor:
                            '#ced4da',
                          height: 192,
                          display: 'flex',
                          overflow:
                            'hidden',
                        }}>
                        {this.state.modal.healthData.child_health_attachment !==
                          '' &&
                          this.state.modal.healthData.child_health_attachment_prev !==
                          '' ? (
                          <div
                            className='d-flex justify-content-center align-items-center'
                            style={{
                              flex: 1,
                              position:
                                'relative',
                            }}>
                            <button
                              style={{
                                padding: 3,
                                border: 3,
                                background:
                                  'none',
                                position:
                                  'absolute',
                                top: 0,
                                right: 0,
                              }}
                              onClick={() => {
                                this.setState({
                                  modal: {
                                    ...this.state.modal,
                                    healthData: {
                                      ...this.state.modal.healthData,
                                      child_health_attachment: '',
                                      child_health_attachment_prev: '',
                                    }
                                  }
                                });
                                this.child_health_attachment.current.value =
                                  null;
                              }}>
                              <FontAwesomeIcon
                                icon={
                                  faTimesCircle
                                }
                                color='rgb(220, 53, 69)'
                                style={{
                                  fontSize: 20,
                                }}
                              />
                            </button>
                            <img
                              src={
                                this.state.modal.healthData.child_health_attachment_prev
                              }
                              alt={t(
                                'Preview'
                              )}
                              width='100'
                            />
                          </div>
                        ) : (
                          <button
                            onClick={() =>
                              this.child_health_attachment.current.click()
                            }
                            style={{
                              background:
                                'none',
                              border: 0,
                              flex: 1,
                            }}>
                            <FontAwesomeIcon
                              icon={
                                faPlus
                              }
                              style={{
                                fontSize: 30,
                              }}
                            />
                          </button>
                        )}
                      </div>
                      <Form.Control
                        className='d-none'
                        ref={
                          this
                            .child_health_attachment
                        }
                        type='file'
                        onChange={this.previewFileModal('healthData')}
                        name='child_health_attachment'
                        accept='.jpg, .jpeg, .png'
                      />
                    </div>
                  </Form.Group>
                </Col>
              </Row>
            }
            {
              (this.state.modal.config.name === 'Chronic Illness' ||
                this.state.modal.config.name === 'Illness' ||
                this.state.modal.config.name === 'Allergy' ||
                this.state.modal.config.name === 'Condition') &&
              <Row>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t('Type')}</Form.Label>
                    <Select
                      value={this.state.modal.healthData.child_health_name}
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (
                          base
                        ) => ({
                          ...base,
                          zIndex: 9999,
                        }),
                      }}
                      options={healthModalDDL}
                      onChange={(obj) => this.setState({
                        modal: {
                          ...this.state.modal,
                          healthData: {
                            ...this.state.modal.healthData,
                            child_health_name: obj
                          }
                        }
                      })}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t('Date')}</Form.Label>
                    <Flatpickr
                      className='form-control'
                      value={this.state.modal.healthData.child_health_date}
                      onChange={([
                        date,
                      ]) => {
                        this.setState({
                          modal: {
                            ...this.state.modal,
                            healthData: {
                              ...this.state.modal.healthData,
                              child_health_date: moment(
                                date
                              ).format(
                                'YYYY-MM-DD'
                              ),
                            }
                          }
                        });
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col md={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t('Info, Treatment, Details')}</Form.Label>
                    <Form.Control type="text"
                      defaultValue={this.state.modal.healthData.child_health_desc}
                      onBlur={(e) => this.setState({
                        modal: {
                          ...this.state.modal,
                          healthData: {
                            ...this.state.modal.healthData,
                            child_health_desc: {
                              value: e.target.value,
                              label: e.target.value,
                            }
                          }
                        }
                      })} />
                  </Form.Group>
                </Col>
              </Row>
            }
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleCloseModal}>
              {t('Close')}
            </Button>
            <Button variant="primary" onClick={() => this.addModalItem()}>
              {t('Add')}
            </Button>
          </Modal.Footer>
        </Modal>
      </>);
  }
}

const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  defaultTheme: state.theme.defaultTheme,
  addEditStudentModal: state.addEditStudentModal.showModal,
  parents: state.addEditStudentModal.parents,
  child_id: state.addEditStudentModal.child_id,
  selectedParent: state.addEditStudentModal.selectedParent,
  isEditing: state.addEditStudentModal.isEditing,
  showModal: state.addEditStudentModal.showModal,
  studentListSearch: state.studentList.query,
  studentListRows: state.studentList.rows,
  studentListOffset: state.studentList.offset,
  studentListStatus: state.studentList.status,
  studentListOrder: state.studentList.order,
  studentListDir: state.studentList.dir,
  studentListPage: state.studentList.page,
});

const mapDispatchToProps = () => ({
  setAddEditStudentModal,
  setAddEditParentModal,
  getStudents,
  setParents,
  setParent
});

export default connect(mapStateToProps, mapDispatchToProps())(AddEditStudent);
