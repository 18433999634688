import React, { Component } from 'react';
import { Container, Card, Row, Col, Button, Spinner, Modal, Table, Badge, Offcanvas, Breadcrumb, Form, Accordion, Image } from 'react-bootstrap';
import { t } from '../../helpers/translation_helper';
import NavBar from '../_partials/NavBar/_NavBar';
import Header from '../_partials/Header/_Header';
import { connect } from 'react-redux';
import Select from 'react-select';
import { brand_colors } from '../../helpers/brand_colors_helper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight, faArrowRight, faChartLine, faChartPie, faCheckCircle, faCompress, faExpand, faEye, faList, faPencil, faPlus, faRemove, faSearch, faStar, faThumbsDown, faThumbsUp, faTrash, faTrashCan, faUser } from '@fortawesome/free-solid-svg-icons';
import { GET, POST } from '../../api';
import axios from 'axios';
import { toast } from 'react-toastify';
import ReactApexChart from "react-apexcharts";
import { colorShades, getExtension, renderChildName } from '../../helpers/general_helpers';
import moment from 'moment';
import { Chance } from 'chance';
import SweetAlert from 'react-bootstrap-sweetalert';
import Slider from "react-slick";

import imgDescription from '../../assets/images/description.png';
import { sendCloudMessage } from '../../helpers/cloud_message';
import { handleFormErrors } from '../../helpers/form_helpers';
import { faCircle } from '@fortawesome/free-regular-svg-icons';

const chance = new Chance();
const apiURL = process.env.REACT_APP_API_URL;
const portalURL = process.env.REACT_APP_PORTAL_URL;

var d = new Date();

class ProgressAnalysis extends Component {

  state = {
    academicBoards: [],
    students: [],
    generalData: [],
    fetching: false,
    fetchingData: false,
    selectedAcademicBoard: null,
    selectedClass: null,
    progressModal: {
      show: false,
      data: null
    },
    expandedData: null,
    highestAssval: 0,
    observationsModal: {
      show: false
    },
    deleteModal: { show: false, id: 0, action: () => { }, title: null, desc: null },
    allStudents: [],
    allStudentsRefreshing: false,
    processingEdit: false,
    allTerms: [],
    selectedTerm: {},
    assessmentMenuData: {},
    assessmentMenu: {
      progassIndex: 0,
      learnareaIndex: 0,
      lrnaspectIndex: 0,
      agegroupIndex: 0,
      changeValue: (key, value) => this.setState({ assessmentMenu: { ...this.state.assessmentMenu, [key]: value } }, () => {
        this.getLastOverallAssessmentAnswers()
        this.getLastAssessmentAnswers()
      })
    },
    activeObsTab: 'Observation',
    getUserTimelineData: {
      refreshing: false,
      finished: false,
      limit: 20,
      offset: 0,
      data: [],
      time: moment()
    },
    userTimelineModal: {
      show: false,
      toggleModal: (user_timeline = {}) => this.setState({
        userTimelineModal: {
          ...this.state.userTimelineModal,
          show: (user_timeline.name) ? true : false,
          user_timeline
        },
      }, () => {
        if (user_timeline.name) {
          if (user_timeline.ut_type == 'Assessment') {
            // this.getAssessmentMenu()
          }
          this.resetUserTimelineForm()
        }
      }),
      user_timeline: {},
      saving: false
    },
    formSectionActive: {
      parent: '1',
      child: '1.1',
    },
    formSections: [],
    allAlbums: [],
    // NEW USER_TIMELINE
    user_timeline: {
      ut_id: null,
      ut_title: '',
      ut_description: '',
      ut_url: '',
      ut_pintotop: 0,
      ut_publish_on: null,
      ut_publish_status: 0,
      ut_type: null,
      utc_type: 'Teacher Parent',
      class_ids: [],
      ut_type_table_id: null,
      ut_type_table: null,
    },
    ut_attachment_1: null,
    ut_attachment_2: null,
    user_timeline_tagged: [],
    user_timeline_tagged_all: true,
    user_timeline_metadata: {
      start_datetime: '',
      end_datetime: '',
      fees: '',
      album_id: '',
      description2: '',
      assesslog_next_steps: '',

      taken_to_hospital: false,//INCIDENT REPORT
      taken_to_hospital_name: '',//INCIDENT REPORT
      taken_immediately: false,//INCIDENT REPORT
      taken_later_date: null,//INCIDENT REPORT

      allow_resubmit: false,//POLLS,SURVERY
      roles: [],//POLLS,SURVERY
      class_ids: [],//POLLS,SURVERY
      term_ids: [],//POLLS,SURVERY
      acs_ids: []
    },
    user_timeline_media: [],//utm_name, utm_url
    assess_aspect: [],//assaspect_id, center_id, assesslog_id, lrnaspect_id, agegroup_id, assagegroup_value, assagegroup_value_num
    assess_curr: [],//asscurr_id, assesslog_id, curriculum_id, center_id, curr_aspect_id, curr_agegroup_id, asscurr_value, asscurr_value_num,
    // videoProcessor: {
    //     modal: false,
    //     toggleModal: () => this.setState({ videoProcessor: { ...this.state.videoProcessor, modal: !this.state.videoProcessor.modal } }),
    //     themeColors: props.route.params.themeColors,
    //     onConfirmCol: null, onConfirmFun: () => { }, onCancelFun: () => { }, videoData: null
    // },
    // utm_video: null,
    dragAreaColor: {
      ut_attachment_1: brand_colors[this.props.defaultTheme.theme_id].color18,
      ut_attachment_2: brand_colors[this.props.defaultTheme.theme_id].color18,
      user_timeline_media: brand_colors[this.props.defaultTheme.theme_id].color18,
      toggleColor: (field, value) => this.setState({ dragAreaColor: { ...this.state.dragAreaColor, [field]: value } })
    },
    cdt: {
      fetching: false,
      search: '',
      rows: 8,
      offset: 0,
      order: 'user_timeline.ut_id',
      dir: 'DESC',
      lastPage: 0,
      page: 0,
      totalRecords: 0,
      rowsOptions: [10, 25, 50, 'All'],
      columns: [
        {
          id: 'user_timeline.ut_id',
          column: 'ut_id',
          name: 'Actions',
          visibility: true,
          sortable: true
        },
        {
          id: 'user_timeline.ut_title',
          column: 'ut_title',
          name: 'Title',
          visibility: true,
          sortable: true
        },
        {
          id: 'classes',
          column: 'classes',
          name: 'Class',
          visibility: true,
          sortable: false
        },
        {
          id: 'created_by_name',
          column: 'created_by_name',
          name: 'Teacher',
          visibility: true,
          sortable: true
        },
        {
          id: 'user_timeline_tagged',
          column: 'user_timeline_tagged',
          name: 'Student',
          visibility: true,
          sortable: false
        },
        {
          id: 'user_timeline.created_on',
          column: 'created_on',
          name: 'Date',
          visibility: true,
          sortable: true
        },
      ],
      data: [],
    },
    preview: false,
    selectedDateRange: { startDate: d.setMonth(d.getMonth() - 1), endDate: new Date(), key: 'selection' },
    showDateRange: false,
    selectedClassId: '',
    reviewObsFlag: false,
    imagesSlider: {
      show: false,
      data: null
    },
    assesslogResults: {
      show: false,
    },
    agegroups: [],
    selectedAgeGroup: null,
    abAgegroups: [],
  }

  constructor(props) {
    super(props);

    this.defaultTimelineTypes = []

    // if (props.authData.allowedUtTypes && props.authData.allowedUtTypes.includes("Assessment")) {
    this.defaultTimelineTypes.push({
      uid: chance.unique(chance.integer, 1, { min: 99999999999, max: 999999999999 })[0],
      name: "Observation",
      ut_type: "Assessment",
      picture: require('../../assets/images/user-timeline/grade.png'),
      active: true,
      show: true,
      classmain_types: [0, 1, 2, 3, 4],
    })
    // }

    this.defaultTimelineTypes.push({
      uid: chance.unique(chance.integer, 1, { min: 99999999999, max: 999999999999 })[0],
      name: "Monthly Observation",
      ut_type: "CheckList",
      picture: require('../../assets/images/user-timeline/grade.png'),
      active: true,
      show: true,
      classmain_types: [0, 1, 2, 3, 4],
    })

    this.formSections = [
      {
        uid: '1',
        active: true,
        completed: false,
        name: 'Tag Student',
        desc: null,
        include_ut_type: ['Notification', 'Event', 'Newsletter', 'Upload', 'Media', 'Daily Report', 'Note', 'Assignment', 'Online Class', 'Assessment', 'Video', 'Exam', 'Agenda', 'Resources', 'Incident Report', 'Exam Retake', 'Online Homework', 'Academic Activity', 'CheckList'],
        data: [
          {
            uid: '1.1',
            active: false,
            completed: false,
            name: 'Class',
            desc: null,
            image: require('../../assets/images/lesson/lesson-focus.png'),
            include_ut_type: ['Notification', 'Event', 'Newsletter', 'Upload', 'Media', 'Daily Report', 'Note', 'Assignment', 'Online Class', 'Assessment', 'Video', 'Exam', 'Agenda', 'Resources', 'Incident Report', 'Exam Retake', 'Online Homework', 'Academic Activity', 'CheckList'],
          },
          {
            uid: '1.2',
            active: false,
            completed: false,
            name: 'Students',
            desc: null,
            image: require('../../assets/images/lesson/lesson-focus.png'),
            include_ut_type: ['Notification', 'Event', 'Newsletter', 'Upload', 'Media', 'Daily Report', 'Note', 'Assignment', 'Online Class', 'Assessment', 'Video', 'Exam', 'Agenda', 'Resources', 'Incident Report', 'Exam Retake', 'Online Homework', 'Academic Activity', 'CheckList'],
          },
        ]
      },
      {
        uid: '3',
        active: false,
        completed: false,
        name: 'Media',
        desc: null,
        include_ut_type: ['Media', 'Assessment', 'CheckList'],
        data: [
          {
            uid: '3.1',
            active: false,
            completed: false,
            name: 'Album',
            desc: null,
            image: require('../../assets/images/lesson/lesson-focus.png'),
            include_ut_type: ['Media'],
          },
          {
            uid: '3.2',
            active: false,
            completed: false,
            name: 'Multiple Attachments',
            desc: null,
            image: require('../../assets/images/lesson/lesson-focus.png'),
            include_ut_type: ['Media', 'Assessment', 'CheckList'],
          },
        ],
      },
      {
        uid: '7',
        active: false,
        completed: false,
        name: 'Observation',
        desc: null,
        include_ut_type: ['Assessment', 'CheckList'],
        data: [
          {
            uid: '7.1',
            active: false,
            completed: false,
            name: 'Observation',
            desc: null,
            image: require('../../assets/images/lesson/lesson-focus.png'),
            include_ut_type: ['Assessment', 'CheckList'],
          },
          {
            uid: '7.2',
            active: false,
            completed: false,
            name: 'Next Steps',
            desc: null,
            image: require('../../assets/images/lesson/lesson-focus.png'),
            include_ut_type: ['Assessment', 'CheckList'],
          },
          {
            uid: '7.3',
            active: false,
            completed: false,
            name: 'Objective',
            desc: null,
            image: require('../../assets/images/lesson/lesson-focus.png'),
            include_ut_type: ['Assessment', 'CheckList'],
          },
        ]
      },
    ]

    this.UTList = React.createRef();
    this.ut_attachment_1_ref = React.createRef();
    this.ut_attachment_2_ref = React.createRef();
    this.filePicker = React.createRef();
    this.survey_q_opt_attachment_ref = []
    this.ref_form_section_1_2 = React.createRef()
  }

  componentDidMount() {
    this.getAcademicBoards();
    this.getTerms()
    this.getAssessmentMenu()
  }

  getAcademicBoards = async () => {
    try {
      const res = await GET('progress-analysis/get-academic-boards');
      const academicBoards = res.data.map(item => {
        return {
          value: item.ab_id,
          label: item.ab_name,
          ...item
        }
      });
      this.setState({ academicBoards });
    } catch (err) {
      console.log(err);
    }
  }

  getAbAgegroups = async () => {
    const { selectedAcademicBoard } = this.state;
    try {
      const res = await GET('progress-analysis/get-age-groups', {
        params: {
          ab_id: selectedAcademicBoard.ab_id
        }
      });
      this.setState({
        abAgegroups: res.data
      });
    } catch (err) {
      console.log(err);
    }
  }

  getStudents = async () => {

    const { selectedAcademicBoard, selectedClass } = this.state;

    if (!selectedAcademicBoard || !selectedClass) {
      toast.error(t('Please select Curriculum and Class!'));
      return;
    }

    this.setState({
      fetching: true,
      students: [],
      selectedAgeGroup: {
        agegroup_id: selectedClass.agegroup_id
      }
    });

    try {

      const studentsList = await GET('progress-analysis/get-children-list', {
        params: {
          class_ids: selectedClass.class_id
        }
      });

      const highestAssval = await GET('progress-analysis/get-highest-assval', {
        params: {
          ab_id: selectedAcademicBoard.ab_id
        }
      });

      const centerlic = await GET('progress-analysis/get-active-centerlic');

      console.log('%cRESPONSE FROM', 'color:yellow');
      console.log(studentsList);
      console.log(highestAssval);
      console.log(centerlic);
      this.setState({
        students: studentsList.data,
        highestAssval: highestAssval.data,
        centerlic: centerlic.data,
        fetching: false
      });
    } catch (err) {
      toast.error(t('Something went wrong!'));
      this.setState({ fetching: false });
      console.log(err);
    }

  }

  getData = async () => {

    this.setState({ fetchingData: true });

    const { selectedAcademicBoard, selectedClass } = this.state;

    try {
      const generalData = await GET('progress-analysis/get-general-data', {
        params: {
          ab_id: selectedAcademicBoard.ab_id,
          child_id: this.state.progressModal.data.child_id,
          agegroup_id: selectedClass.agegroup_id
        }
      });

      this.setState({
        fetchingData: false,
        agegroups: generalData.data.agegroups,
        generalData: generalData.data.agegroups.map(agegroup => {
          return {
            ...agegroup,
            learnareas: generalData.data.general_data.map(item => {

              const options = {
                plotOptions: {
                  radialBar: {
                    startAngle: -90,
                    endAngle: 90,
                    dataLabels: {
                      name: {
                        fontSize: '20px',
                        offsetY: 20,
                      },
                      value: {
                        offsetY: -30,
                        fontSize: '35px'
                      },
                    },
                  },
                },
                colors: colorShades(item.learnarea_color),
                fill: {
                  colors: colorShades(item.learnarea_color)
                },
                labels: item.lrnaspects.map(item => item.lrnaspect_code)
              }

              return {
                ...item,
                series: item.lrnaspects.map(item => isNaN(parseFloat(parseInt(item.covered_curr.filter(item => item.curr_agegroup_id == agegroup.agegroup_id).length) / parseInt(item.curr_count.filter(item => item.agegroup_id == agegroup.agegroup_id).length) * 100).toFixed(2)) ? '0.00' : parseFloat(parseInt(item.covered_curr.filter(item => item.curr_agegroup_id == agegroup.agegroup_id).length) / parseInt(item.curr_count.filter(item => item.agegroup_id == agegroup.agegroup_id).length) * 100).toFixed(2)),
                options,
                lrnaspects: item.lrnaspects.map(a_item => {
                  return {
                    ...a_item,
                    // series: a_item.covered_curr.map(c_item => parseFloat(parseInt(c_item.last_assmt.assmt_value) / parseInt(this.state.highestAssval.assmt_value)) * 100),
                    series: a_item.all_curr
                      .filter(curr => curr.agegroup_id == agegroup.agegroup_id)
                      .map(c_item => {

                        const covered_curr = a_item.covered_curr.find(d_item => d_item.curr_agegroup_id == agegroup.agegroup_id && d_item.curriculum_id == c_item.curriculum_id);

                        return parseFloat(parseInt(covered_curr?.last_assmt?.assmt_value) / parseInt(this.state.highestAssval.assmt_value)) * 100
                      }),
                    options: {
                      ...options,
                      plotOptions: {
                        ...options.plotOptions,
                        radialBar: {
                          ...options.plotOptions.radialBar,
                          dataLabels: {
                            ...options.plotOptions.radialBar.dataLabels,
                            name: {
                              ...options.plotOptions.radialBar.dataLabels.name,
                              offsetY: 30,
                            },
                            value: {
                              show: false
                            },
                          }
                        }
                      },
                      // labels: a_item.covered_curr.map(c_item => `${c_item.index}. ${c_item.curriculum_code.length >= 57 ? c_item.curriculum_code.substring(0, 57) + '...' : c_item.curriculum_code}`)
                      labels: a_item.all_curr
                        .filter(curr => curr.agegroup_id == agegroup.agegroup_id)
                        .map((c_item, c_index) => `${c_index + 1}. ${c_item.curriculum_code.length >= 57 ? c_item.curriculum_code.substring(0, 57) + '...' : c_item.curriculum_code}`)
                    }
                  }
                })
              }

            })
          }
        }),
      });
    } catch (err) {
      this.setState({ fetchingData: true });
      console.log(err);
    }
  }

  getObservations = async (params) => {

    this.setState({
      cdt: {
        ...this.state.cdt,
        fetching: true,
        data: params.page == 0 ? [] : this.state.cdt.data
      }
    });

    if (this.fetchReq) {
      this.fetchReq.abort();
    }
    this.fetchReq = new AbortController();

    try {
      const { centerlic, selectedClass, observationsModal } = this.state;

      const res = await GET('user-timeline/get-observations-review', {
        signal: this.fetchReq.signal,
        params: {
          search: params.search,
          limit: params.limit,
          order: params.order,
          dir: params.dir,
          page: params.page,
          offset: parseInt(params.page) * parseInt(params.limit),
          class_id: [selectedClass.class_id],
          startDate: centerlic.date_from,
          endDate: centerlic.date_to,
          reviewObsFlag: this.state.reviewObsFlag,
          child_id: observationsModal.data.child_id
        },
      });

      this.setState({
        cdt: {
          ...this.state.cdt,
          fetching: false,
          search: params.search,
          rows: parseInt(params.limit),
          offset: parseInt(params.page) * parseInt(params.limit),
          order: params.order,
          dir: params.dir,
          page: params.page,
          totalRecords: parseInt(res.data.total_count_filtered),
          lastPage: Math.ceil(parseInt(res.data.total_count_filtered) / parseInt(params.limit)),
          data: [...this.state.cdt.data, ...res.data.data],
        }
      });
    } catch (err) {
      console.log(err);
    }
  }

  getClassesChildren = (classes, actionType) => {
    if (classes.length <= 0) {
      if (actionType == 'newTimeline') {
        if (this.ref_form_section_1_2.current) {
          this.ref_form_section_1_2.current.setValue([])
        }
        this.setState({ allStudents: [], allStudentsRefreshing: true })
      }
      return
    } else if (actionType == 'newTimeline' && this.state.selectedTerm.value == undefined) {
      if (this.ref_form_section_1_2.current) {
        this.ref_form_section_1_2.current.setValue([])
      }
      this.setState({ allStudents: [], allStudentsRefreshing: true })
    } else {
      // if (this.ref_form_section_1_2.current) {
      //   this.ref_form_section_1_2.current.setValue([])
      // }
      this.setState({ allStudents: [], allStudentsRefreshing: true })
    }


    const fData = new FormData()
    fData.append('center_id', this.props.authData.centerData.center_id)
    fData.append('class_ids', JSON.stringify(classes.map((v, i) => v.value)))
    if (actionType == 'newTimeline') {
      fData.append('term_id', this.state.selectedTerm.value)
    }
    if (typeof this.getClassesChildrenRequest != typeof undefined) {
      this.getClassesChildrenRequest.abort();
    }

    this.getClassesChildrenRequest = new AbortController();
    axios.post(apiURL + 'children/get-classes-children', fData, {
      params: {
        auth_key: this.props.authData.loginData.auth_key,
        appname: process.env.REACT_APP_NAME,
      },
      signal: this.getClassesChildrenRequest.signal,
    })
      .then((res) => {

        let resData = res.data
        if (Array.isArray(resData)) {
          if (actionType == 'newTimeline') {
            this.setState({ allStudents: resData, allStudentsRefreshing: false })
          }
        }
      })
      .catch((err) => {
        if (axios.isCancel(err)) {

        } else {
          toast.error(
            'Something went wrong while fetching chats and contacts1!'
          );

        }
      });
  }

  getAssessmentMenu = () => {
    if (typeof this.getAssessmentMenuRequest != typeof undefined) {
      this.getAssessmentMenuRequest.abort();
    }

    this.getAssessmentMenuRequest = new AbortController();

    if (typeof this.getAllCurriculumRequest != typeof undefined) {
      this.getAllCurriculumRequest.abort();
    }

    this.getAllCurriculumRequest = new AbortController();

    axios
      .all([
        GET('assesslog/get-assessment-menu', {
          params: {
            auth_key: this.props.authData.loginData.auth_key,
            center_id: this.props.authData.centerData.center_id,
            appname: process.env.REACT_APP_NAME,
          },
          signal: this.getAssessmentMenuRequest.signal,
        }),
        GET('assesslog/get-all-curriculum', {
          params: {
            auth_key: this.props.authData.loginData.auth_key,
            center_id: this.props.authData.centerData.center_id,
            appname: process.env.REACT_APP_NAME,
          },
          signal: this.getAllCurriculumRequest.signal,
        }),
      ])
      .then(
        axios.spread((assessmentMenuData, getAllCurriculumData) => {
          this.setState({ assessmentMenuData: { ...assessmentMenuData.data, allCurriculum: getAllCurriculumData.data } })
        }))
      .catch((err) => {
        if (axios.isCancel(err)) {

        } else {
          toast.error(
            'Something went wrong while fetching chats and contacts4!'
          );

        }
      });
  }

  getLastOverallAssessmentAnswers = () => {
    if (this.state.user_timeline_tagged.length < 1 || this.state.user_timeline_tagged.length > 1) {
      return
    }
    if (typeof this.getLastOverallAssessmentAnswersReq != typeof undefined) {
      this.getLastOverallAssessmentAnswersReq.abort();
    }
    this.getLastOverallAssessmentAnswersReq = new AbortController();
    GET('assesslog/get-last-overall-assessment-answers', {
      params: {
        auth_key: this.props.authData.loginData.auth_key,
        center_id: this.props.authData.centerData.center_id,
        child_id: this.state.user_timeline_tagged[0].value,
        lrnaspect_id: this.state.assessmentMenuData.lrnaspect.find((v, i) => this.state.assessmentMenu.lrnaspectIndex == i).lrnaspect_id,
        agegroup_id: this.state.assessmentMenuData.agegroup.find((v, i) => this.state.assessmentMenu.agegroupIndex == i).agegroup_id,
        appname: process.env.REACT_APP_NAME,
      },
      signal: this.getLastOverallAssessmentAnswersReq.signal,
    })
      .then((res) => {


      })
      .catch((err) => {
        if (axios.isCancel(err)) {

        } else {
          toast.error(
            'Something went wrong while fetching chats and contacts5!'
          );

        }
      });
  }

  getLastAssessmentAnswers = () => {
    if (this.state.user_timeline_tagged.length < 1 || this.state.user_timeline_tagged.length > 1) {
      return
    }
    let assessmentMenuData = this.state.assessmentMenuData
    let assessmentMenu = this.state.assessmentMenu
    let lrnaspect_id = assessmentMenuData.lrnaspect.find((v, i) => this.state.assessmentMenu.lrnaspectIndex == i).lrnaspect_id
    let agegroup_id = assessmentMenuData.agegroup.find((v, i) => this.state.assessmentMenu.agegroupIndex == i).agegroup_id

    let curriculum_ids = []
    assessmentMenuData.allCurriculum.map((v, i) => {
      if (assessmentMenuData.lrnaspect[assessmentMenu.lrnaspectIndex].lrnaspect_id != v.lrnaspect_id || assessmentMenuData.agegroup[assessmentMenu.agegroupIndex].agegroup_id != v.agegroup_id) {
        return null
      }
      curriculum_ids.push(v.curriculum_id)
    })
    let fData = new FormData()
    fData.append('center_id', this.props.authData.centerData.center_id)
    fData.append('child_id', this.state.user_timeline_tagged[0].value)
    fData.append('lrnaspect_id', lrnaspect_id)
    fData.append('agegroup_id', agegroup_id)
    fData.append('curriculum_ids', JSON.stringify(curriculum_ids))
    this.getLastAssessmentAnswersReq = new AbortController();
    axios.post(apiURL + 'assesslog/get-last-assessment-answers-multi-curriculum', fData, {
      params: {
        auth_key: this.props.authData.loginData.auth_key,
        appname: process.env.REACT_APP_NAME,
      },
      signal: this.getLastAssessmentAnswersReq.signal,
    })
      .then((res) => {

        if (res) {
          let resData = res.data
          this.setState({ assessmentMenuData: { ...this.state.assessmentMenuData, lastAssessmentAnswers: resData } })
          // let lastAssessmentAnswers = this.state.lastAssessmentAnswers
          // let lastAssessmentAnswersFindIndex = lastAssessmentAnswers.findIndex((v, i) => v.curriculum_id == curriculum_id)
          // if (lastAssessmentAnswersFindIndex == -1) {
          //     let lastAnswers = resData
          //     lastAssessmentAnswers.push({
          //         curriculum_id: curriculum_id,
          //         lastAnswers: lastAnswers
          //     })
          // } else {
          //     let lastAnswers = lastAssessmentAnswers[lastAssessmentAnswersFindIndex].lastAnswers
          //     resData.map((v, i) => {
          //         let lastAnswersFindIndex = lastAnswers.findIndex((vv, ii) => vv.asscurr_id == v.asscurr_id)
          //         if (lastAnswersFindIndex == -1) {
          //             lastAnswers.push(v)
          //         }
          //     })
          //     lastAssessmentAnswers[lastAssessmentAnswersFindIndex].lastAnswers = lastAnswers
          // }

          // this.setState({ lastAssessmentAnswers: lastAssessmentAnswers })
        }
      })
  }

  getTerms = () => {
    if (this.getTermsRequest) {
      this.getTermsRequest.abort();
    }
    this.getTermsRequest = new AbortController();
    GET('user-timeline/get-terms', {
      params: {
        auth_key: this.props.authData.loginData.auth_key,
        center_id: this.props.authData.centerData.center_id,
        appname: process.env.REACT_APP_NAME,
      },
      signal: this.getTermsRequest.signal,
    })
      .then((res) => {
        let selectedTerm = null
        res.data.find((v) => { if (v.term_active == 1) { selectedTerm = v } })
        this.setState({ allTerms: res.data, selectedTerm: (selectedTerm) ? { ...selectedTerm, label: selectedTerm.term_name, value: selectedTerm.term_id, active: (selectedTerm.term_active == 1) ? true : false } : null })
      })
      .catch((err) => {
        if (axios.isCancel(err)) {

        } else {
          toast.error(
            'Something went wrong while fetching chats and contacts6!'
          );

        }
      });
  }

  expandGraph = (data) => {
    console.log(data);
    this.setState({
      expandedData: data
    });
  }

  showProgressModal = (data) => {
    this.setState({
      progressModal: {
        show: true,
        data
      },
      selectedAgeGroup: {
        agegroup_id: this.state.selectedClass.agegroup_id
      }
    }, () => this.getData());
  }

  hideProgressModal = () => {
    this.setState({
      progressModal: {
        show: false,
        data: null
      },
      expandedData: null,
    });
  }

  showObservationsModal = (data) => {
    this.setState({
      observationsModal: {
        show: true,
        data
      }
    }, () => this.getObservations({
      search: this.state.cdt.search,
      limit: this.state.cdt.rows,
      order: this.state.cdt.order,
      dir: this.state.cdt.dir,
      page: 0
    }));
  }

  hideObservationsModal = () => {
    this.setState({
      observationsModal: {
        show: false,
        data: null
      },
    });
  }

  renderAssessAspectOptions = () => {
    let assessmentMenuData = this.state.assessmentMenuData
    let assessmentMenu = this.state.assessmentMenu

    let thisAns = {}
    let selectedVal = this.state.assess_aspect.find((vv, ii) => (vv.lrnaspect_id == assessmentMenuData.lrnaspect[assessmentMenu.lrnaspectIndex].lrnaspect_id && vv.agegroup_id
      == assessmentMenuData.agegroup[assessmentMenu.agegroupIndex].agegroup_id))
    if (selectedVal) {
      let findAss = assessmentMenuData.assessment.find((vv, ii) => vv.assmt_id == selectedVal.assagegroup_value)
      if (findAss) {
        thisAns = (this.props.defaultLanguage.lang_id == 1)
          ? { label: findAss.assmt_desc || findAss.assmt_code, value: findAss.assmt_id, assmt_color: findAss.assmt_color }
          : { label: findAss.assmt_desc2 || findAss.assmt_desc || findAss.assmt_code, value: findAss.assmt_id, assmt_color: findAss.assmt_color }

        // thisAns = { label: findAss.assmt_code, value: findAss.assmt_id, assmt_color: findAss.assmt_color }
      }
    }

    return <Breadcrumb.Item active style={{ minWidth: 200, maxWidth: 200, width: 200 }}>
      <Form.Group>
        <Select
          options={assessmentMenuData.assessment.filter((v) => { if (parseInt(assessmentMenuData.progass[assessmentMenu.progassIndex].progass_id) == parseInt(v.progass_id)) { return true } return false }).map((v, i) => {
            if (this.props.defaultLanguage.lang_id == 1) {
              return { label: v.assmt_code || v.assmt_desc, value: v.assmt_id, assmt_color: v.assmt_color }
            } else {
              return { label: v.assmt_code || v.assmt_desc2 || v.assmt_desc, value: v.assmt_id, assmt_color: v.assmt_color }
            }
          })}
          onChange={(event) => {
            this.selectAssessment(event.value)
          }}
          value={thisAns}
          menuPortalTarget={document.body}
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999, }), control: (base) => ({ ...base, minHeight: 30, height: 30 }) }}
          formatOptionLabel={option => (
            <div>
              <span style={{ color: option.assmt_color, fontSize: 14 }}>{option.label}</span>
            </div>
          )}
        />
      </Form.Group>
    </Breadcrumb.Item>
  }

  changeToFromSection = (parent, child) => {
    this.setState(
      {
        formSectionActive: { parent: parent, child: child },
      },
      () => {
        setTimeout(() => {
          const element = document.getElementById('form_section_' + child);
          if (element) {
            // 👇 Will scroll smoothly to the top of the next section
            element.scrollIntoView({ behavior: 'smooth' });
          }
        }, 300);
      }
    );
  };

  saveUserTimeline = async () => {
    this.setState({
      userTimelineModal: {
        ...this.state.userTimelineModal,
        saving: true
      }
    })
    if (this.state.userTimelineModal.saving) {
      return
    }

    let user_timeline = JSON.parse(JSON.stringify(this.state.user_timeline))
    let user_timeline_metadata = JSON.parse(JSON.stringify(this.state.user_timeline_metadata))
    let user_timeline_tagged = this.state.user_timeline_tagged
    let userTimelineModal = this.state.userTimelineModal

    let { ut_attachment_1, ut_attachment_2, user_timeline_media } = this.state

    if (user_timeline_metadata.album_id) {
      user_timeline_metadata.album_id = user_timeline_metadata.album_id.value
    }
    user_timeline.ut_type = userTimelineModal.user_timeline.ut_type

    if (['Media', 'Video', 'Incident Report', 'Assessment', 'Agenda', 'Academic Activity', 'CheckList'].includes(user_timeline.ut_type) && user_timeline.class_ids.length > 1) {
      this.setState({
        userTimelineModal: {
          ...this.state.userTimelineModal,
          saving: false
        }
      })
      toast.error(t('Only one class should be selected.'));
      return
    }

    user_timeline.ut_publish_status = (user_timeline.ut_publish_status) ? 1 : 0
    user_timeline.ut_publish_on = (user_timeline.ut_publish_on) ? moment(user_timeline.ut_publish_on).format('YYYY-MM-DD HH:mm:ss') : null
    user_timeline.class_ids = user_timeline.class_ids.map((v, i) => parseInt(v.value))

    user_timeline_metadata.allow_resubmit = (user_timeline_metadata.allow_resubmit) ? 1 : 0
    user_timeline_metadata.roles = user_timeline_metadata.roles.map((v, i) => v.value)
    user_timeline_metadata.term_ids = user_timeline_metadata.term_ids.map((v, i) => v.value)
    user_timeline_metadata.class_ids = user_timeline_metadata.class_ids.map((v, i) => parseInt(v.value))
    user_timeline_metadata.acs_ids = user_timeline_metadata.acs_ids.map((v, i) => parseInt(v.value))
    user_timeline_metadata.start_datetime = (user_timeline_metadata.start_datetime) ? moment(user_timeline_metadata.start_datetime).format('YYYY-MM-DD HH:mm:ss') : null
    user_timeline_metadata.end_datetime = (user_timeline_metadata.end_datetime) ? moment(user_timeline_metadata.end_datetime).format('YYYY-MM-DD HH:mm:ss') : null
    user_timeline_metadata.taken_later_date = (user_timeline_metadata.taken_later_date) ? moment(user_timeline_metadata.taken_later_date).format('YYYY-MM-DD') : null
    user_timeline.ut_type_table_id = (user_timeline.ut_type_table_id) ? user_timeline.ut_type_table_id.value : null
    console.log('user_timeline', user_timeline);
    let fData = new FormData()
    fData.append('center_id', this.props.authData.centerData.center_id)
    fData.append('class_id', 'undefined')
    fData.append('user_timeline', JSON.stringify(user_timeline))
    fData.append('user_timeline_metadata', JSON.stringify(user_timeline_metadata))
    fData.append('user_timeline_tagged_all', this.state.user_timeline_tagged_all)
    fData.append('term_id', this.state.selectedTerm.value)
    fData.append('user_timeline_tagged', JSON.stringify(user_timeline_tagged))
    fData.append('ut_id', user_timeline.ut_id)
    fData.append('ut_type', userTimelineModal.user_timeline.ut_type)
    fData.append('user_id', this.props.authData.loginData.user_id)
    fData.append('center_timezone', this.props.authData.centerData.center_timezone)
    fData.append('employee_id', this.props.authData.userData.employee_id)
    fData.append('employee_photo_approve', this.props.authData.userData.employee_photo_approve)
    if (ut_attachment_1) {
      fData.append('ut_attachment_1', ut_attachment_1.data)
    }
    if (ut_attachment_2) {
      fData.append('ut_attachment_2', ut_attachment_2.data)
    }
    if (user_timeline_media.length > 0) {
      user_timeline_media.map((v, i) => {
        if (v.data) {
          fData.append('user_timeline_media[]', v.data)
        }
      })
    }

    if (
      userTimelineModal.user_timeline.ut_type == 'Assessment' ||
      userTimelineModal.user_timeline.ut_type == 'CheckList'
    ) {
      fData.append('assess_aspect', JSON.stringify(this.state.assess_aspect))
      fData.append('assess_curr', JSON.stringify(this.state.assess_curr))
    }

    // return
    if (this.saveUserTimelineReq != undefined) {
      this.saveUserTimelineReq.abort();
    }
    this.saveUserTimelineReq = new AbortController();

    let URL = 'user-timeline/save-user-timeline'
    if (
      userTimelineModal.user_timeline.ut_type == 'Assessment' ||
      userTimelineModal.user_timeline.ut_type == 'CheckList'
    ) {
      URL = 'assesslog/save-assesslog'
    }
    else if (userTimelineModal.user_timeline.ut_type == 'Policy') {
      URL = 'center/save-center-policies'
    }

    POST(URL, fData, {
      signal: this.saveUserTimelineReq.signal,
    })
      .then((res) => {

        if (res.data.type) {

          // SEND NOTIFICATIONS
          if (user_timeline.ut_publish_on == null && parseInt(user_timeline.ut_publish_status) == 1 && userTimelineModal.user_timeline.ut_type != 'Policy') {
            let ut_type = user_timeline.ut_type
            let ut_id = user_timeline.ut_id

            // Assessment
            if (
              ut_type == 'Assessment' ||
              ut_type == 'CheckList'
            ) {
              res.data.user_timeline_tagged.map((v, i) => {
                let body = user_timeline.ut_title
                let data = { 'ut_id': res.data.data.ut_id, 'actionType': 'assesslog' }
                if (v.child_user_id) {
                  let title = (ut_id) ? 'New: Observation' : 'Updated: Observation'
                  sendCloudMessage(v.child_user_id, title, body, data)
                }
                if (v.parent_user_id) {
                  let title = (ut_id) ? 'New: Observation for ' + renderChildName(v.child_name, v.arabic_name, v.family_name, v.family_name_2) : 'Updated: Observation for ' + renderChildName(v.child_name, v.arabic_name, v.family_name, v.family_name_2)
                  sendCloudMessage(v.parent_user_id, title, body, data)
                }
              })
            }
          }

          // RESET FROM
          this.setState({
            userTimelineModal: {
              ...this.state.userTimelineModal,
              show: false,
              user_timeline: {},
              saving: false
            },
            assesslogResults: {
              show: false,
            },
            assessmentMenu: {
              ...this.state.assessmentMenu,
              progassIndex: 0,
              learnareaIndex: 0,
              lrnaspectIndex: 0,
              agegroupIndex: 0,
            },
            formSectionActive: {
              parent: '1',
              child: '1.1',
            },
            formSections: this.formSections,
            user_timeline: {
              ut_id: null,
              ut_title: '',
              ut_description: '',
              ut_url: '',
              ut_pintotop: 0,
              ut_publish_on: null,
              ut_publish_status: 0,
              ut_type: null,
              utc_type: 'Teacher Parent',
              class_ids: [],
              ut_type_table_id: null,
              ut_type_table: null,
            },
            ut_attachment_1: null,
            ut_attachment_2: null,
            user_timeline_tagged: [],
            user_timeline_tagged_all: true,
            user_timeline_metadata: {
              start_datetime: null,
              end_datetime: null,
              fees: '',
              album_id: '',
              description2: '',
              assesslog_next_steps: '',
              taken_to_hospital: false,
              taken_to_hospital_name: '',
              taken_immediately: false,
              taken_later_date: '',
              allow_resubmit: false,
              roles: [],
              class_ids: [],
              term_ids: [],
              acs_ids: []
            },
            user_timeline_media: [],
            assess_aspect: [],
            assess_curr: [],
            getUserTimelineData: { ...this.state.getUserTimelineData, data: [], refreshing: false, finished: false, offset: 0, time: moment() }
          },
            () => this.getObservations({
              search: this.state.cdt.search,
              limit: this.state.cdt.rows,
              order: this.state.cdt.order,
              dir: this.state.cdt.dir,
              page: 0
            })
          )



          toast.success(t(res.data.message));

        } else {
          this.setState({
            userTimelineModal: {
              ...this.state.userTimelineModal,
              saving: false
            }
          })
          if (res.data.data != null) {
            handleFormErrors(res.data.data);
          } else {
            toast.error(t(res.data.message));
          }
        }
      })
  }

  resetUserTimelineForm = () => {
    this.setState({
      preview: false,
      formSectionActive: {
        parent: '1',
        child: '1.1',
      },
      formSections: this.formSections,
      user_timeline: {
        ut_id: null,
        ut_title: '',
        ut_description: '',
        ut_url: '',
        ut_pintotop: 0,
        ut_publish_on: null,
        ut_publish_status: 0,
        ut_type: null,
        utc_type: 'Teacher Parent',
        class_ids: [],
        ut_type_table_id: null,
        ut_type_table: null,
      },
      ut_attachment_1: null,
      ut_attachment_2: null,
      user_timeline_tagged: [],
      user_timeline_tagged_all: true,
      user_timeline_metadata: {
        start_datetime: null,
        end_datetime: null,
        fees: '',
        album_id: '',
        description2: '',
        assesslog_next_steps: '',
        taken_to_hospital: false,
        taken_to_hospital_name: '',
        taken_immediately: false,
        taken_later_date: '',
        allow_resubmit: false,
        roles: [],
        class_ids: [],
        term_ids: [],
        acs_ids: []
      },
      user_timeline_media: [],
      assess_aspect: [],
      assess_curr: [],
    })
  }

  editUserTimeline = (ut_id, preview = false, assessments = false) => {

    this.setState({ processingEdit: true, preview })

    if (this.editUserTimelineReq) {
      this.editUserTimelineReq.abort();
    }

    this.editUserTimelineReq = new AbortController();
    GET('user-timeline/edit-user-timeline', {
      params: {
        ut_id: ut_id,
      },
      signal: this.editUserTimelineReq.signal,
    })
      .then((res) => {

        if (res.data) {

          let user_timeline = res.data.user_timeline
          let user_timeline_metadata = (res.data.user_timeline_metadata) ? res.data.user_timeline_metadata : {}
          let user_timeline_tagged = res.data.user_timeline_tagged
          let user_timeline_tagged_users = res.data.user_timeline_tagged_users
          let class_ids = JSON.parse(user_timeline.class_ids)
          let class_id = class_ids[0]
          let assess_aspect = []
          let assess_curr = []
          let agegroupIndex = 0

          class_ids = class_ids.map((v, i) => {
            let findClass = this.props.authData.classes.find((vv, ii) => v == vv.class.class_id)
            if (findClass) {
              return { label: findClass.class.class_theme, value: findClass.class.class_id, ...findClass.class }
            }
          })

          let classData = this.props.authData.classes.find((v, i) => v.class.class_id == class_id)

          user_timeline_metadata.acs_ids = []
          if (
            user_timeline.ut_type == 'Assessment' ||
            user_timeline.ut_type == 'CheckList'
          ) {
            assess_aspect = user_timeline.assess_aspect
            assess_curr = user_timeline.assess_curr
            agegroupIndex = this.state.assessmentMenuData.agegroup.findIndex((v, i) => class_ids[0].agegroup_id == v.agegroup_id)
          }

          user_timeline_metadata.start_datetime = (user_timeline_metadata && user_timeline_metadata.start_datetime) ? moment(user_timeline_metadata.start_datetime, "YYYY-MM-DD HH:mm:ss") : null
          user_timeline_metadata.end_datetime = (user_timeline_metadata && user_timeline_metadata.end_datetime) ? moment(user_timeline_metadata.end_datetime, "YYYY-MM-DD HH:mm:ss") : null
          user_timeline.ut_publish_status = (parseInt(user_timeline.ut_publish_status) == 1) ? true : false
          user_timeline.class_ids = class_ids

          let modal = {
            userTimelineModal: {
              ...this.state.userTimelineModal,
              show: true,
              user_timeline: this.defaultTimelineTypes.find((v, i) => v.ut_type == user_timeline.ut_type)
            },
            formSectionActive: {
              parent: '1',
              child: '1.1',
            },
            formSections: this.formSections,
          }

          if (assessments) {
            modal = {
              userTimelineModal: {
                ...this.state.userTimelineModal,
                user_timeline: this.defaultTimelineTypes.find((v, i) => v.ut_type == user_timeline.ut_type)
              },
              assesslogResults: {
                show: true,
              },
            }
          }

          // POPULATE FROM
          this.setState({
            processingEdit: false,
            assessmentMenu: {
              ...this.state.assessmentMenu,
              progassIndex: 0,
              learnareaIndex: 0,
              lrnaspectIndex: 0,
              agegroupIndex: agegroupIndex,
            },
            user_timeline: {
              ut_id: null,
              ut_title: '',
              ut_description: '',
              ut_url: '',
              ut_pintotop: 0,
              ut_publish_on: null,
              ut_publish_status: 0,
              ut_type: null,
              utc_type: 'Teacher Parent',
              class_ids: [],
              ut_type_table_id: null,
              ut_type_table: null,
              ...user_timeline,
              // class_ids: class_ids[0],
            },
            ut_attachment_1: (user_timeline.ut_attachment_1) ? { url: portalURL + user_timeline.center_id + '/user_timeline/' + user_timeline.ut_attachment_1, data: { type: getExtension(user_timeline.ut_attachment_1) } } : null,
            ut_attachment_2: (user_timeline.ut_attachment_2) ? { url: portalURL + user_timeline.center_id + '/user_timeline/' + user_timeline.ut_attachment_2, data: { type: getExtension(user_timeline.ut_attachment_2) } } : null,
            user_timeline_tagged_all: true,
            user_timeline_tagged: user_timeline_tagged.map((v, i) => { return { label: v.child_name, value: v.child_id, ...v } }),
            user_timeline_metadata: {
              start_datetime: null,
              end_datetime: null,
              fees: '',
              album_id: '',
              description2: '',
              assesslog_next_steps: '',
              taken_to_hospital: false,
              taken_to_hospital_name: '',
              taken_immediately: false,
              taken_later_date: '',
              allow_resubmit: false,
              roles: [],
              class_ids: [],
              term_ids: [],
              acs_ids: [],
              ...user_timeline_metadata
            },
            user_timeline_media: res.data.user_timeline_media.map((v, i) => {
              let this_url = portalURL + v.center_id + '/user_timeline_media/' + v.utm_name
              if (v.utm_folder != null) {
                if (v.utm_folder == 'educorepublic') {
                  this_url = v.utm_url
                } else {
                  this_url = portalURL + v.center_id + '/' + v.utm_folder + '/' + v.utm_name
                }
              }
              return {
                data: { type: 'image' },
                url: this_url,
                utm_id: v.utm_id
              }
            }),
            assess_aspect: assess_aspect,
            assess_curr: assess_curr,
            ...modal
          }, () => !assessments && this.checkCompletedFormSections())
        }
      })
      .catch((err) => {
        if (axios.isCancel(err)) {

        } else {
          console.log('err', err);
          toast.error(
            'Something went wrong while fetching!'
          );

        }
        this.setState({ processingEdit: false })
      });


  }

  deleteUserTimeline = (ut) => {
    let role_id = this.props.authData.loginData.role_id
    let user_id = this.props.authData.loginData.user_id
    if ([1, 8, 12, 15, 19, 22].includes(role_id) || ut.created_by == user_id) {
      if (![''].includes(ut.ut_type)) {
        this.setState({
          deleteModal: {
            ...this.state.deleteModal, show: true, id: ut.ut_id, title: 'Are you sure?', desc: 'It will remove all the related things to this posts also!', action: async () => {
              if (this.deleteUserTimelineReq) {
                this.deleteUserTimelineReq.abort()
              }
              this.deleteUserTimelineReq = new AbortController()
              let res = await GET('user-timeline/delete-user-timeline', {
                params: { ut_id: ut.ut_id },
                signal: this.deleteUserTimelineReq.signal
              })
              let resData = res.data
              let getUserTimelineData = JSON.parse(JSON.stringify(this.state.cdt.data))
              this.setState({
                cdt: {
                  ...this.state.cdt,
                  data: getUserTimelineData.filter((v, i) => v.ut_id != ut.ut_id),
                }, deleteModal: { ...this.state.deleteModal, show: false }
              })
              toast[resData.messageType](t(resData.message));
            }
          }
        })

      } else {
        toast.error(t('Delete not allowed for this Post contact admin!'));
      }
    } else {
      toast.error(t('Delete not allowed for you, contact admin!'));
    }
  }

  checkCompletedFormSections = () => {
    let { user_timeline, user_timeline_metadata, user_timeline_tagged, user_timeline_media, ut_attachment_1, ut_attachment_2, assess_aspect, assess_curr, user_timeline_tagged_all } = this.state;

    if (this.checkCompletedFormSectionsTimer) {
      clearTimeout(this.checkCompletedFormSectionsTimer)
    }
    this.checkCompletedFormSectionsTimer = setTimeout(() => {
      let formSections = this.state.formSections;
      formSections = formSections.map((v, i) => {
        let data = v.data;
        let sectionComplete = true;
        data = data.map((vv, ii) => {
          if (vv.uid == '1.1') {
            let subSectionComplete = true;
            if (user_timeline.class_ids.length > 0) {
              subSectionComplete = true;
            } else {
              subSectionComplete = false;
              sectionComplete = false;
            }
            return { ...vv, completed: subSectionComplete };
          } else if (vv.uid == '1.2') {
            let subSectionComplete = true;
            if (user_timeline_tagged.length > 0 || user_timeline_tagged_all) {
              subSectionComplete = true;
            } else {
              subSectionComplete = false;
              sectionComplete = false;
            }
            return { ...vv, completed: subSectionComplete };
          } else if (vv.uid == '2.1') {
            let subSectionComplete = true;
            if (user_timeline.ut_title) {
              subSectionComplete = true;
            } else {
              subSectionComplete = false;
              sectionComplete = false;
            }
            return { ...vv, completed: subSectionComplete };
          } else if (vv.uid == '2.2' || vv.uid == '7.1') {
            let subSectionComplete = true;
            if (user_timeline.ut_description) {
              subSectionComplete = true;
            } else {
              subSectionComplete = false;
              sectionComplete = false;
            }
            return { ...vv, completed: subSectionComplete };
          } else if (vv.uid == '2.3') {
            let subSectionComplete = true;
            if (ut_attachment_1) {
              subSectionComplete = true;
            } else {
              subSectionComplete = false;
              sectionComplete = false;
            }
            return { ...vv, completed: subSectionComplete };
          } else if (vv.uid == '2.4' || vv.uid == '6.4') {
            let subSectionComplete = true;
            if (ut_attachment_2) {
              subSectionComplete = true;
            } else {
              subSectionComplete = false;
              sectionComplete = false;
            }
            return { ...vv, completed: subSectionComplete };
          } else if (vv.uid == '2.5') {
            let subSectionComplete = true;
            if (user_timeline.ut_url) {
              subSectionComplete = true;
            } else {
              subSectionComplete = false;
              sectionComplete = false;
            }
            return { ...vv, completed: subSectionComplete };
          } else if (vv.uid == '5.1') {
            let subSectionComplete = true;
            if (user_timeline_metadata.fees) {
              subSectionComplete = true;
            } else {
              subSectionComplete = false;
              sectionComplete = false;
            }
            return { ...vv, completed: subSectionComplete };
          } else if (vv.uid == '5.2' || vv.uid == '9.1' || vv.uid == '8.2' || vv.uid == '12.2' || vv.uid == '10.1' || vv.uid == '11.1') {
            let subSectionComplete = true;
            if (user_timeline_metadata.start_datetime) {
              subSectionComplete = true;
            } else {
              subSectionComplete = false;
              sectionComplete = false;
            }
            return { ...vv, completed: subSectionComplete };
          } else if (vv.uid == '5.3' || vv.uid == '6.1' || vv.uid == '12.3' || vv.uid == '10.2' || vv.uid == '11.2') {
            let subSectionComplete = true;
            if (user_timeline_metadata.end_datetime) {
              subSectionComplete = true;
            } else {
              subSectionComplete = false;
              sectionComplete = false;
            }
            return { ...vv, completed: subSectionComplete };
          } else if (vv.uid == '3.1') {
            let subSectionComplete = true;
            if (user_timeline_metadata.album_id) {
              subSectionComplete = true;
            } else {
              subSectionComplete = false;
              sectionComplete = false;
            }
            return { ...vv, completed: subSectionComplete };
          } else if (vv.uid == '3.2') {
            let subSectionComplete = true;
            if (user_timeline_media.length > 0) {
              subSectionComplete = true;
            } else {
              subSectionComplete = false;
              sectionComplete = false;
            }
            return { ...vv, completed: subSectionComplete };
          } else if (vv.uid == '9.2') {
            let subSectionComplete = true;
            if (user_timeline_metadata.taken_to_hospital) {
              subSectionComplete = true;
            } else {
              subSectionComplete = false;
              sectionComplete = false;
            }
            return { ...vv, completed: subSectionComplete };
          } else if (vv.uid == '9.3') {
            let subSectionComplete = true;
            if (user_timeline_metadata.taken_immediately) {
              subSectionComplete = true;
            } else {
              subSectionComplete = false;
              sectionComplete = false;
            }
            return { ...vv, completed: subSectionComplete };
          } else if (vv.uid == '9.4') {
            let subSectionComplete = true;
            if (user_timeline_metadata.taken_to_hospital_name) {
              subSectionComplete = true;
            } else {
              subSectionComplete = false;
              sectionComplete = false;
            }
            return { ...vv, completed: subSectionComplete };
          } else if (vv.uid == '9.5' || vv.uid == '7.2' || vv.uid == '6.2') {
            let subSectionComplete = true;
            if (user_timeline_metadata.description2) {
              subSectionComplete = true;
            } else {
              subSectionComplete = false;
              sectionComplete = false;
            }
            return { ...vv, completed: subSectionComplete };
          } else if (vv.uid == '7.3') {
            let subSectionComplete = true;
            if (assess_aspect.length > 0 || assess_curr.length > 0) {
              subSectionComplete = true;
            } else {
              subSectionComplete = false;
              sectionComplete = false;
            }
            return { ...vv, completed: subSectionComplete };
          }

          else if (vv.uid == '8.1' || vv.uid == '6.3' || vv.uid == '12.4') {
            let subSectionComplete = true;
            if (user_timeline_metadata.acs_ids.length > 0) {
              subSectionComplete = true;
            } else {
              subSectionComplete = false;
              sectionComplete = false;
            }
            return { ...vv, completed: subSectionComplete };
          }
          else if (vv.uid == '12.1') {
            let subSectionComplete = true;
            if (user_timeline.ut_type_table_id) {
              subSectionComplete = true;
            } else {
              subSectionComplete = false;
              sectionComplete = false;
            }
            return { ...vv, completed: subSectionComplete };
          } else if (vv.uid == '10.3' || vv.uid == '11.3') {
            let subSectionComplete = true;
            if (user_timeline_metadata.allow_resubmit) {
              subSectionComplete = true;
            } else {
              subSectionComplete = false;
              sectionComplete = false;
            }
            return { ...vv, completed: subSectionComplete };
          }
          else if (vv.uid == '10.4' || vv.uid == '11.4' || vv.uid == '13.1') {
            let subSectionComplete = true;
            if (user_timeline_metadata.roles.length > 0) {
              subSectionComplete = true;
            } else {
              subSectionComplete = false;
              sectionComplete = false;
            }
            return { ...vv, completed: subSectionComplete };
          }

          else {
            sectionComplete = false;
            return { ...vv };
          }
        });
        return { ...v, data, completed: sectionComplete };
      });
      this.setState({ formSections: formSections });
    }, 1000);

  };

  setStateUserTimeline = (field, value) => {

    if (this.setStateTimer) {
      clearTimeout(this.setStateTimer)
    }
    this.setStateTimer = setTimeout(() => {

      this.setState({ user_timeline: { ...this.state.user_timeline, [field]: value } }, () => {
        if (field == 'class_ids') {
          //! IF ONE CLASS IS SELECTED 
          if (this.state.user_timeline.class_ids.length == 1) {
            // FETCH CHILDREN OF THIS CLASS
            // this.getClassesChildren((this.state.user_timeline.class_ids.value) ? [this.state.user_timeline.class_ids] : [], 'newTimeline')
            // FETCH ALBUMS IN CASE OF CHANGE CLASS AND UT_TYPE = MEDIA
            if (
              this.state.userTimelineModal.user_timeline.ut_type == 'Assessment' ||
              this.state.userTimelineModal.user_timeline.ut_type == 'CheckList'
            ) {
              if (this.state.assessmentMenuData) {
                let agegroupIndex = this.state.assessmentMenuData.agegroup.findIndex((v, i) => value[0].agegroup_id == v.agegroup_id)
                // 
                this.setState({ assessmentMenu: { ...this.state.assessmentMenu, agegroupIndex: agegroupIndex } })
              }
            }
          }
          //! IF MORE THAN ONE CLASS IS SELECTED
          else if (this.state.user_timeline.class_ids.length > 1) {

          }
          // this.setState({ user_timeline_tagged: [] })
        }
        this.checkCompletedFormSections()
      });
    }, 100);

  };

  setStateUserTimelineMetadata = (field, value) => {
    let new_user_timeline_metadata = { ...this.state.user_timeline_metadata, [field]: value }
    if (field == 'roles') {
      new_user_timeline_metadata = { ...this.state.user_timeline_metadata, [field]: value, class_ids: [], term_ids: [] }
    }
    this.setState({ user_timeline_metadata: new_user_timeline_metadata }, () => {
      this.checkCompletedFormSections()
    });
  };

  readFile = (selectedFile) => {
    return new Promise(function (resolve, reject) {
      let reader = new FileReader();
      const allowedFiles = ['application/pdf', 'image/jpeg', 'image/jpg', 'image/png', 'audio/x-m4a', 'audio/mp3', 'audio/mp4'];
      const allowedFilesDisplay = ['PDF', 'Image'];
      const allowedSize = 5000000;

      if (selectedFile.size) {

        if (selectedFile.size >= allowedSize) {
          toast.error(t('Max file size allowed is ' + allowedSize / 1000000 + ' MB!'));
          resolve({ type: 'error' });
          return;
        } else if (!allowedFiles.includes(selectedFile.type)) {
          toast.error(t('Allowed file type are ' + allowedFilesDisplay.toString()));
          resolve({ type: 'error' });
          return;
        } else {
          if (selectedFile.type.includes('image')) {
            reader.readAsDataURL(selectedFile);
          } else if (selectedFile.type.includes('audio')) {
            reader.readAsArrayBuffer(selectedFile);
          } else {
            reader.readAsText(selectedFile);
          }
        }

        reader.onload = async (readerEvent) => {
          resolve({ type: 'success', data: selectedFile, url: readerEvent.target.result });
        };
        reader.onerror = (error) => {
          resolve({ type: 'error' });
        };
      } else {
        resolve({ type: 'error' });
      }
    });
  };

  addAttachment = (e) => {

    const selectedFiles = e.target.files;
    let allFiles = [];

    for (let i = 0; i < selectedFiles.length; i++) {
      allFiles.push(this.readFile(selectedFiles[i]));
    }

    Promise.all(allFiles).then((values) => {
      let attachments = e.target.multiple ? this.state[e.target.name] : null;
      let selectedFiles = values;
      selectedFiles.map((v, i) => {
        if (v.data.type) {
          if (e.target.multiple) {
            attachments.push({ url: v.url, data: v.data });
          } else {
            attachments = { url: v.url, data: v.data };
          }
        }
      });

      this.setState({ [e.target.name]: attachments, dragAreaColor: { ...this.state.dragAreaColor, [e.target.name]: brand_colors[this.props.defaultTheme.theme_id].color18 } }, () => this.checkCompletedFormSections());
    });
  };

  selectAssessment = (assmt_id, actionType = 'default', curriculum_id = null) => {
    let assessmentMenuData = this.state.assessmentMenuData
    let assessmentMenu = this.state.assessmentMenu
    if (actionType != 'selectedCurriculum') {
      let lrnaspect_id = assessmentMenuData.lrnaspect[assessmentMenu.lrnaspectIndex].lrnaspect_id
      let agegroup_id = assessmentMenuData.agegroup[assessmentMenu.agegroupIndex].agegroup_id
      let assess_aspect = this.state.assess_aspect

      let assess_aspectFindIndex = assess_aspect.findIndex((v, i) => (v.lrnaspect_id == lrnaspect_id && v.agegroup_id == agegroup_id))
      if (assess_aspectFindIndex == -1) {
        assess_aspect.push({
          assaspect_id: 0,
          center_id: this.props.authData.centerData.center_id,
          assesslog_id: 0,
          lrnaspect_id: lrnaspect_id,
          agegroup_id: agegroup_id,
          assagegroup_value: assmt_id,
          assagegroup_value_num: 0,
        })
      } else {
        assess_aspect[assess_aspectFindIndex].assagegroup_value = assmt_id
      }
      this.setState({ assess_aspect: assess_aspect })
    } else {
      let assess_aspect = this.state.assess_aspect
      let lrnaspect_id = assessmentMenuData.lrnaspect[assessmentMenu.lrnaspectIndex].lrnaspect_id
      let agegroup_id = assessmentMenuData.agegroup[assessmentMenu.agegroupIndex].agegroup_id

      // INSERT assess_aspect
      let assess_aspectFindIndex = assess_aspect.findIndex((v, i) => (v.lrnaspect_id == lrnaspect_id && v.agegroup_id == agegroup_id))
      if (assess_aspectFindIndex == -1) {
        assess_aspect.push({
          assaspect_id: 0,
          center_id: this.props.authData.centerData.center_id,
          assesslog_id: 0,
          lrnaspect_id: lrnaspect_id,
          agegroup_id: agegroup_id,
          assagegroup_value: 0,
          assagegroup_value_num: 0,
        })
      } else {
        // assess_aspect[assess_aspectFindIndex].assagegroup_value = assmt_id
      }
      // INSERT assess_curr
      let assess_curr = this.state.assess_curr
      let assess_currFindIndex = assess_curr.findIndex((v, i) => (v.curr_aspect_id == lrnaspect_id && v.curr_agegroup_id == agegroup_id && v.curriculum_id == curriculum_id))

      if (assess_currFindIndex == -1) {
        assess_curr.push({
          asscurr_id: 0,
          assesslog_id: 0,
          curriculum_id: curriculum_id,
          center_id: this.props.authData.centerData.center_id,
          curr_aspect_id: lrnaspect_id,
          curr_agegroup_id: agegroup_id,
          asscurr_value: assmt_id,
          asscurr_value_num: 0,
        })
      } else {
        assess_curr[assess_currFindIndex].asscurr_value = assmt_id
      }
      this.setState({ assess_aspect: assess_aspect, assess_curr: assess_curr })
    }
  }

  removeAssessAspect = (key, complete = false) => {
    let assess_aspect = this.state.assess_aspect
    let assess_curr = this.state.assess_curr
    let lrnaspect_id = assess_aspect[key].lrnaspect_id
    let agegroup_id = assess_aspect[key].agegroup_id
    if (!complete) {
      let assess_currFindIndex = assess_curr.findIndex((v, i) => v.curr_aspect_id == lrnaspect_id && v.curr_agegroup_id == agegroup_id)
      if (assess_currFindIndex == -1) {
        assess_aspect.splice(key, 1);
      } else {
        assess_aspect[key].assagegroup_value = 0
      }
    } else {
      this.setState({
        deleteModal: {
          ...this.state.deleteModal, show: true, id: key, id1: lrnaspect_id, id2: agegroup_id, title: 'Are you sure?', desc: 'It will remove the Aspect with its Objectives!', action: () => {
            let deleteModal = this.state.deleteModal
            let assess_aspect = this.state.assess_aspect
            let assess_curr = this.state.assess_curr
            assess_curr = assess_curr.filter((v, i) => (v.curr_aspect_id != deleteModal.id1 || v.curr_agegroup_id != deleteModal.id2))
            assess_aspect.splice(deleteModal.id, 1);
            this.setState({ assess_aspect: assess_aspect, assess_curr: assess_curr, deleteModal: { ...this.state.deleteModal, show: false } })
          }
        }
      })

      // Are you sure?
      // It will remove the Aspect with its Objectives!

    }
    this.setState({ assess_aspect: assess_aspect, assess_curr: assess_curr })
  }

  removeAssessCurr = (key) => {
    let assess_aspect = this.state.assess_aspect
    let assess_curr = this.state.assess_curr
    let curr_aspect_id = assess_curr[key].curr_aspect_id
    let curr_agegroup_id = assess_curr[key].curr_agegroup_id
    assess_curr.splice(key, 1);
    let assess_currFindIndex = assess_curr.findIndex((v, i) => v.curr_aspect_id == curr_aspect_id && v.curr_agegroup_id == curr_agegroup_id)
    if (assess_currFindIndex == -1) {
      let assess_aspectFindIndex = assess_aspect.findIndex((v, i) => v.lrnaspect_id == curr_aspect_id && v.agegroup_id == curr_agegroup_id)
      if (assess_aspect[assess_aspectFindIndex].assagegroup_value == 0) {
        assess_aspect.splice(assess_aspectFindIndex, 1);
      }
    }
    this.setState({ assess_aspect: assess_aspect, assess_curr: assess_curr })
  }

  renderAssessmentList = () => {
    let assess_aspect = this.state.assess_aspect
    let assess_curr = this.state.assess_curr
    let assessmentMenuData = this.state.assessmentMenuData

    let assess_aspectLen = assess_aspect.filter((v, i) => v.assagegroup_value != 0)
    return <div style={{
      position: 'sticky',
      top: this.state.assesslogResults.show ? 3 : -12
    }}>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', padding: '0 10px 0 10px' }}>
        <div>
          <span style={{ fontSize: 14, fontWeight: 'bold', color: brand_colors[this.props.defaultTheme.theme_id].color9, opacity: 0.7 }}>Total Aspects: {assess_aspectLen.length}</span>
        </div>
        <div>
          <span style={{ fontSize: 14, fontWeight: 'bold', color: brand_colors[this.props.defaultTheme.theme_id].color9, opacity: 0.7 }}>Total Objectives: {assess_curr.length}</span>
        </div>
      </div>
      {assess_aspect.map((v, i) => {
        let activeLrnaspectData = assessmentMenuData.lrnaspect.find((vv, ii) => vv.lrnaspect_id == v.lrnaspect_id)
        let activeAgegroupData = assessmentMenuData.agegroup.find((vv, ii) => vv.agegroup_id == v.agegroup_id)
        let activelLearnareaData = assessmentMenuData.learnarea.find((vv, ii) => vv.learnarea_id == activeLrnaspectData.learnarea_id)
        let activelProgassData = assessmentMenuData.progass.find((vv, ii) => vv.progass_id == activelLearnareaData?.progass_id)
        let assessmentFind = assessmentMenuData.assessment.find((vv, ii) => vv.assmt_id == v.assagegroup_value)

        return <div key={i}>
          <div style={{ margin: '5px 0 5px 0', backgroundColor: brand_colors[this.props.defaultTheme.theme_id].color19, padding: '5px 10px 5px 10px' }}>

            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', justifyContent: 'space-between', }}>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', }}>
                <div>
                  <span style={{ fontSize: 14, fontWeight: 'bold', color: brand_colors[this.props.defaultTheme.theme_id].color1 }}>{activelProgassData?.progass_code}</span>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <div style={{ margin: '0 10px' }}>
                    <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: 12, color: brand_colors[this.props.defaultTheme.theme_id].color4 }} />
                  </div>
                  <span style={{ fontSize: 14, fontWeight: 'bold', color: activeAgegroupData.agegroup_color }}>{activeAgegroupData.agegroup_code}</span>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <div style={{ margin: '0 10px' }}>
                    <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: 12, color: brand_colors[this.props.defaultTheme.theme_id].color4 }} />
                  </div>
                  {activelLearnareaData &&
                    <span style={{ fontSize: 14, fontWeight: 'bold', color: activelLearnareaData.learnarea_color }}>
                      {(parseInt(this.props.defaultLanguage.lang_id) == 1)
                        ? activelLearnareaData.learnarea_desc || activelLearnareaData.learnarea_code
                        : activelLearnareaData.learnarea_desc2 || activelLearnareaData.learnarea_desc || activelLearnareaData.learnarea_code
                      }
                    </span>
                  }
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <div style={{ margin: '0 10px' }}>
                    <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: 12, color: brand_colors[this.props.defaultTheme.theme_id].color4 }} />
                  </div>
                  <span style={{ fontSize: 14, fontWeight: 'bold', color: brand_colors[this.props.defaultTheme.theme_id].color1 }}>{
                    (this.props.defaultLanguage.lang_id == 1)
                      ? activeLrnaspectData.lrnaspect_desc || activeLrnaspectData.lrnaspect_code
                      : activeLrnaspectData.lrnaspect_desc2 || activeLrnaspectData.lrnaspect_desc || activeLrnaspectData.lrnaspect_code
                  }</span>
                </div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>
                {(assessmentFind) ? <span style={{ fontSize: 14, color: (assessmentFind.assmt_color) ? assessmentFind.assmt_color : brand_colors[this.props.defaultTheme.theme_id].color1, fontWeight: 'bold' }}>{
                  (this.props.defaultLanguage.lang_id == 1)
                    ? assessmentFind.assmt_desc || assessmentFind.assmt_code
                    : assessmentFind.assmt_desc2 || assessmentFind.assmt_desc || assessmentFind.assmt_code
                }</span> : null}
              </div>
            </div>
            {!this.state.preview &&
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row' }}>
                <div style={{}}>
                  <Badge bg='danger' className='cursor-pointer' onClick={() => this.removeAssessAspect(i, true)}>
                    <FontAwesomeIcon icon={faRemove} style={{ color: brand_colors[this.props.defaultTheme.theme_id].color8, marginRight: 5 }} />
                    {t('Remove Group')}
                  </Badge>
                </div>
                <div style={{}}>
                  {(assessmentFind) && <Badge bg='danger' className='cursor-pointer' onClick={() => this.removeAssessAspect(i)}>
                    <FontAwesomeIcon icon={faRemove} style={{ color: brand_colors[this.props.defaultTheme.theme_id].color8, marginRight: 5 }} />
                    {t('Remove Aspect')}
                  </Badge>}
                </div>
              </div>
            }
          </div>
          <div>
            {assess_curr.map((vv, ii) => {
              if (vv.curr_aspect_id == activeLrnaspectData.lrnaspect_id && vv.curr_agegroup_id == activeAgegroupData.agegroup_id) {

                let curriculumDataFind = {
                  curriculum_code: vv.curriculum_code,
                  curriculum_desc: vv.curriculum_desc,
                  curriculum_desc: vv.curriculum_desc,
                  curriculum_id: vv.curriculum_id,
                  curriculum_ref_num: vv.curriculum_ref_num,
                }
                if (!vv.curriculum_code) {
                  curriculumDataFind = assessmentMenuData.allCurriculum.find((vvv, ii) => vvv.curriculum_id == vv.curriculum_id)
                }

                let assessmentFind2 = assessmentMenuData.assessment.find((vvv, ii) => vvv.assmt_id == vv.asscurr_value)
                return <div key={ii} style={{ paddingVertical: 5, position: 'relative', padding: '5px 10px 5px 10px', borderBottom: '1px solid ' + brand_colors[this.props.defaultTheme.theme_id].color18 }}>

                  <div style={{ alignItems: 'center', justifyContent: 'space-between', display: 'flex', flexDirection: 'row' }}>
                    <div style={{}}>
                      <span style={{ fontSize: 14, color: (assessmentFind2.assmt_color) ? assessmentFind2.assmt_color : brand_colors[this.props.defaultTheme.theme_id].color1, fontWeight: 'bold' }}>{
                        (this.props.defaultLanguage.lang_id == 1)
                          ? assessmentFind2.assmt_desc || assessmentFind2.assmt_code
                          : assessmentFind2.assmt_desc2 || assessmentFind2.assmt_desc || assessmentFind2.assmt_code
                      }</span>
                    </div>
                    <div style={{}}>
                      <span style={{ fontSize: 14, color: brand_colors[this.props.defaultTheme.theme_id].color1, fontWeight: 'bold', opacity: 0.8 }}>#{curriculumDataFind.curriculum_ref_num}</span>
                    </div>
                  </div>
                  <div style={{}}>
                    <span style={{ fontSize: 14, color: brand_colors[this.props.defaultTheme.theme_id].color9, opacity: 0.8 }}>
                      {
                        (parseInt(this.props.defaultLanguage.lang_id) == 1)
                          ? curriculumDataFind.curriculum_code
                          : curriculumDataFind.curriculum_code
                      }
                    </span>
                  </div>
                  {!this.state.preview &&
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', flexDirection: 'row' }}>
                      <div style={{}}>
                        <Badge bg='danger' className='cursor-pointer' onClick={() => this.removeAssessCurr(ii)}>
                          <FontAwesomeIcon icon={faRemove} style={{ color: brand_colors[this.props.defaultTheme.theme_id].color8, marginRight: 5 }} />
                          {t('Remove Objective')}
                        </Badge>
                      </div>
                    </div>
                  }
                </div>
              }
            })}
          </div>
        </div>
      })}
    </div>
  }

  deleteUserTimelineMedia = (v, i) => {
    let user_timeline_media = this.state.user_timeline_media
    if (v.utm_id) {
      this.setState({
        deleteModal: {
          ...this.state.deleteModal, show: true, action: () => {
            // DELETE FILE CALL 
            try {
              GET('user-timeline/delete-user-timeline-media', {
                params: {
                  auth_key: this.props.authData.loginData.auth_key,
                  key: v.utm_id,
                  field: 'utm_name',
                  center_id: this.props.authData.centerData.center_id,
                  appname: process.env.REACT_APP_NAME,
                },
              }).then((res) => {
                if (res) {
                  let getUserTimelineData = this.state.getUserTimelineData
                  getUserTimelineData.data = getUserTimelineData.data.map((vv, ii) => {
                    if (vv.ut_id == this.state.user_timeline.ut_id) {
                      vv.user_timeline_media.splice(i, 1);
                    }
                    return vv
                  })
                  user_timeline_media.splice(i, 1);
                  this.setState({
                    user_timeline_media, deleteModal: {
                      ...this.state.deleteModal, show: false
                    },
                    getUserTimelineData
                  });
                } else {
                  toast.error(t('Something went wrong while deleting!'));
                }
              })
            } catch (err) {
              console.log('err', err)
              toast.error(t('Something went wrong while deleting!'));

            }
          }
        }
      })
    } else {
      user_timeline_media.splice(i, 1);
      this.setState({ user_timeline_media });
    }
  }

  deleteUserTimelineAttachment = (field, actionFun = null) => {
    let user_timeline = this.state.user_timeline
    if (user_timeline.ut_id != null) {
      this.setState({
        deleteModal: {
          ...this.state.deleteModal, show: true, action: () => {
            // DELETE FILE CALL 
            try {
              GET('user-timeline/delete-user-timeline-attachment', {
                params: {
                  auth_key: this.props.authData.loginData.auth_key,
                  key: user_timeline.ut_id,
                  field: field,
                  center_id: this.props.authData.centerData.center_id,
                  appname: process.env.REACT_APP_NAME,
                },
              }).then((res) => {
                if (res) {
                  if (actionFun) {
                    actionFun()
                  }
                  user_timeline[field] = null
                  let getUserTimelineData = this.state.getUserTimelineData
                  getUserTimelineData.data = getUserTimelineData.data.map((vv, ii) => {
                    if (vv.ut_id == this.state.user_timeline.ut_id) {
                      vv[field] = null;
                    }
                    return vv
                  })
                  this.setState({
                    deleteModal: {
                      ...this.state.deleteModal, show: false
                    },
                    getUserTimelineData,
                    [field]: null,
                    user_timeline,
                  }, () => this.checkCompletedFormSections());
                } else {
                  toast.error(t('Something went wrong while deleting!'));
                }
              })
            } catch (err) {
              console.log('err', err)
              toast.error(t('Something went wrong while deleting!'));

            }
          }
        }
      })
    } else {
      user_timeline[field] = null
      this.setState({ [field]: null, user_timeline }, () => this.checkCompletedFormSections());
      if (actionFun) {
        actionFun()
      }
    }
  }

  handleDragFileArea = (e, name) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.type === "dragover") {
      this.state.dragAreaColor.toggleColor(name, brand_colors[this.props.defaultTheme.theme_id].color1)
    } else if (e.type === "dragleave") {
      this.state.dragAreaColor.toggleColor(name, brand_colors[this.props.defaultTheme.theme_id].color18)
    }
  }

  renderForm = () => {
    const { user_timeline, user_timeline_metadata, user_timeline_media, user_timeline_tagged, ut_attachment_1, ut_attachment_2, selectedTerm } = this.state
    const dir = this.props.defaultLanguage.lang_orientation
    const { userTimelineModal, formSections, formSectionActive } = this.state
    const { assessmentMenu, assessmentMenuData } = this.state
    let activeUserTimeline = userTimelineModal.user_timeline

    {/* NEW TIMELINE */ }
    return <Offcanvas show={userTimelineModal.show} style={{ height: '100%', zIndex: 100000 }} placement='bottom' onHide={userTimelineModal.toggleModal}>
      <Offcanvas.Header id='iedu-header' style={{ marginTop: '1rem', marginRight: '1rem', marginLeft: '1rem', marginBottom: 0 }}>
        <div className='d-flex flex-row  align-items-center justify-content-center'>
          <span className='d-flex align-items-center justify-content-center btn-back-white' onClick={userTimelineModal.toggleModal}>
            <FontAwesomeIcon icon={(dir == 1) ? faAngleRight : faAngleLeft} color={brand_colors[this.props.defaultTheme.theme_id].color9} />
          </span>
          <Offcanvas.Title>
            <div className='d-flex flex-row align-items-center justify-content-center title-back-color8' >
              <div className='avatar'>
                {userTimelineModal.user_timeline &&
                  <img src={userTimelineModal.user_timeline.picture} width="38" height="38" />
                }
              </div>
              <span style={{ fontSize: 20, margin: '0 10px' }}>{userTimelineModal.user_timeline.name} {this.state.preview && t('Preview')}</span>
            </div>
          </Offcanvas.Title>
        </div>
        {!this.state.preview &&
          <div className='custom-styled-form'>
            <div className='d-flex flex-row justify-content-between align-items-end'>

              {(this.props.authData.userData && (parseInt(this.props.authData.userData.curriculumshare) == 0))
                && <div style={{ margin: '0 20px', backgroundColor: brand_colors[this.props.defaultTheme.theme_id].color8, textAlign: 'center', padding: '5px 10px', borderRadius: 20 }}>
                  <span style={{ fontSize: 13, color: brand_colors[this.props.defaultTheme.theme_id].color11, opacity: 0.7, fontWeight: 'bold' }}>
                    {t('You are not allowed to share with parents')}
                  </span>
                </div>
              }

              {/* Publish Later */}
              {
                (this.state.userTimelineModal.user_timeline.ut_type == 'Assessment' ||
                  this.state.userTimelineModal.user_timeline.ut_type == 'CheckList')
                &&
                <div style={{ margin: '0 20px', backgroundColor: brand_colors[this.props.defaultTheme.theme_id].color8, textAlign: 'center', padding: '5px 10px', borderRadius: 20 }}>
                  <span style={{ fontSize: 13, color: brand_colors[this.props.defaultTheme.theme_id].color9, opacity: 0.7, fontWeight: 'bold' }}>
                    {t('Share with parent')}
                  </span>
                  <Form.Check
                    disabled={this.props.authData.userData && (parseInt(this.props.authData.userData.curriculumshare) == 0)}
                    type="switch"
                    value={1}
                    checked={user_timeline.ut_publish_status}
                    onChange={(event) => {
                      this.setState({ user_timeline: { ...this.state.user_timeline, ['ut_publish_on']: (event.target.checked) ? null : moment().add(10, 'years').toDate(), ut_publish_status: event.target.checked } })
                    }}
                  />
                </div>
              }

              <Button disabled={this.state.userTimelineModal.saving} variant={user_timeline.ut_publish_status ? 'success' : 'warning'} onClick={() => setTimeout(() => {
                this.saveUserTimeline()
              }, 300)}>
                {(user_timeline.ut_publish_status) ? t('Publish Now') : t('Publish Later')} {this.state.userTimelineModal.saving ? <Spinner as='span' animation='grow' size='sm' role='status' aria-hidden='true' /> : ''}
              </Button>
            </div>
          </div>
        }
      </Offcanvas.Header>
      <Offcanvas.Body>
        <div
          className={'d-flex flex-row grey-section'}
          style={{
            overflow: 'initial',
            backgroundImage: 'url(https://academic.educore.io/static/media/pegboard-bg.9b84e4936acef29bbcd9.png)',
            backgroundRepeat: 'repeat',
            backgroundPosition: '10px 12px',
            overflow: 'hidden',
          }}
        >
          <div style={{ backgroundColor: brand_colors[this.props.defaultTheme.theme_id].color8, marginLeft: 10, marginRight: 10, borderRadius: '20px', width: '250px', height: '100%', top: '5px', position: 'sticky' }}>
            <div style={{ paddingRight: '1.5rem', paddingBottom: '1.5rem', paddingTop: '1rem' }}>
              {formSections.map((v, i) => {

                let allowRender = false
                if (v.include_ut_type.includes(activeUserTimeline.ut_type) || v.include_ut_type.includes('*')) {
                  allowRender = true
                }
                if (!allowRender) {
                  return null
                }

                return (
                  <div key={i}>
                    <div className='d-flex flex-row align-items-center' style={{ cursor: 'pointer', borderRadius: '20px', paddingLeft: '5px', paddingRight: '5px', zIndex: 1, position: 'relative' }} onClick={() => this.changeToFromSection(v.uid, v.uid + '.1')}>
                      <span style={{ fontSize: 18, fontWeight: '600', color: brand_colors[this.props.defaultTheme.theme_id].color9, opacity: 0.7 }}>{v.name}</span>
                    </div>

                    <div style={{ paddingLeft: '1rem', borderLeft: 'solid 1px color18', marginLeft: '1.5rem' }}>
                      {v.data.map((vv, ii) => {

                        let allowRender2 = false
                        if (vv.include_ut_type.includes(activeUserTimeline.ut_type) || vv.include_ut_type.includes('*')) {
                          allowRender2 = true
                        }
                        if (!allowRender2) {
                          return null
                        }

                        return (
                          <div key={ii} className='d-flex flex-row align-items-center' style={{ marginBottom: 5, marginTop: 2, cursor: 'pointer' }} onClick={() => this.changeToFromSection(v.uid, vv.uid)}>
                            <FontAwesomeIcon icon={vv.completed ? faCheckCircle : faCircle} color={vv.completed ? brand_colors[this.props.defaultTheme.theme_id].color4 : brand_colors[this.props.defaultTheme.theme_id].color18} style={{ fontSize: 12, margin: '0 5px' }} />
                            <span style={{ fontSize: 14, color: vv.completed ? brand_colors[this.props.defaultTheme.theme_id].color4 : brand_colors[this.props.defaultTheme.theme_id].color18 }}>{vv.name}</span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <Col className='custom-styled-form hide-scrollbar' style={{ backgroundColor: brand_colors[this.props.defaultTheme.theme_id].color8, marginLeft: 10, marginRight: 10, borderRadius: '20px', padding: '1rem', overflow: 'auto', height: '70vh' }}>

            {/* SECTION 1 */}
            {['Notification', 'Event', 'Newsletter', 'Upload', 'Media', 'Daily Report', 'Note', 'Assignment', 'Online Class', 'Assessment', 'Video', 'Exam', 'Agenda', 'Resources', 'Incident Report', 'Exam Retake', 'Online Homework', 'Academic Activity', 'CheckList'].includes(activeUserTimeline.ut_type) && (
              <div id='form_section_1'>
                <div className={'d-flex flex-row align-items-center justify-content-between section-heading'}>
                  <span className='heading'>{'Tag Students'}</span>
                </div>
                <Row>
                  <Col md="4">
                    {/* CLASS */}
                    <div id='form_section_1.1'>
                      <Form.Group className='mb-3'>
                        {this.state.preview ?
                          <>
                            <strong>{t('Classes:')}</strong>{' '}
                            {user_timeline.class_ids.map(item => {
                              return item.label;
                            }).join(',')}
                          </>
                          :
                          <>
                            <Form.Label>{t('Class')}</Form.Label>
                            <Select
                              options={this.props.authData.classes.map((v, i) => { return { label: v.class.class_theme, value: v.class.class_id, ...v.class } })}
                              onChange={(event) => {
                                this.setStateUserTimeline('class_ids', [event])
                              }}
                              defaultValue={user_timeline.class_ids[0]}
                              menuPortalTarget={document.body}
                              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                              formatOptionLabel={option => (
                                <div className="d-flex flex-row">
                                  {option.class_logo ? <img src={portalURL + option.center_id + '/classes/' + option.class_logo} style={{ width: 25, height: 25, objectFit: 'cover', marginRight: 5 }} /> : ''}
                                  <div style={{ fontSize: 14 }}>
                                    <div >{option.label}</div>
                                    <div>
                                      <Badge bg="info">{option.agegroup_code}</Badge>
                                    </div>
                                  </div>
                                </div>
                              )}
                            />
                          </>
                        }
                      </Form.Group>
                    </div>
                    {/* Term */}
                    <div>
                      <Form.Group className='mb-3'>
                        {this.state.preview ?
                          <>
                            <strong>{t('Term:')}</strong>{' '}
                            {selectedTerm.label}
                          </>
                          :
                          <>
                            <Form.Label>{t('Term')}</Form.Label>
                            <Select
                              options={this.state.allTerms.map((v, i) => { return { ...v, label: v.term_name, value: v.term_id, active: (v.term_active == 1) ? true : false } })}
                              onChange={(event) =>
                                this.setState({ selectedTerm: event }, () => {
                                  // this.getClassesChildren((this.state.user_timeline.class_ids.value) ? [this.state.user_timeline.class_ids] : [], 'newTimeline')
                                })
                              }
                              defaultValue={selectedTerm}
                              menuPortalTarget={document.body}
                              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                              formatOptionLabel={option => (
                                <div>
                                  <span>
                                    {option.label}
                                    {option.active &&
                                      <Badge bg='success' className='ms-2'>Active</Badge>
                                    }
                                  </span>
                                </div>
                              )}
                            />
                          </>
                        }
                      </Form.Group>
                    </div>
                  </Col>
                  <Col>
                    {/* STUDENTS */}
                    <div id='form_section_1.2'>
                      {this.state.preview ?
                        <>
                          <strong>{t('Tagged Students:')}</strong>{' '}
                          {user_timeline_tagged.map(item => {
                            return item.label
                          }).join(',')}
                        </>
                        :
                        <>
                          {!['Assessment', 'CheckList'].includes(activeUserTimeline.ut_type) && (
                            <Form.Group >
                              <Form.Check type="switch" label={t('Select all Students in selected classes')} checked={this.state.user_timeline_tagged_all} onChange={() => {
                                if (this.setStateTimer) {
                                  clearTimeout(this.setStateTimer)
                                }
                                this.setStateTimer = setTimeout(() => {
                                  this.setState({ user_timeline_tagged: [], user_timeline_tagged_all: !this.state.user_timeline_tagged_all }, () => {
                                    this.ref_form_section_1_2.current.setValue([])
                                    this.checkCompletedFormSections()
                                  })
                                }, 500);
                              }} />
                            </Form.Group>
                          )}
                          <Form.Group className='mb-3'>
                            <Form.Label>{t('Tag Students')} ( {(this.state.user_timeline_tagged_all) ? 'ALL' : user_timeline_tagged.length} )</Form.Label>
                            <Select
                              closeMenuOnSelect={false}
                              isMulti={true}
                              ref={this.ref_form_section_1_2}
                              options={this.state.allStudents.map((v, i) => { return { label: v.child_name, value: v.child_id, ...v } })}
                              onChange={(event) => {
                                if (this.setStateTimer) {
                                  clearTimeout(this.setStateTimer)
                                }
                                this.setStateTimer = setTimeout(() => {
                                  this.setState({ user_timeline_tagged: event }, () => {
                                    if (
                                      activeUserTimeline.ut_type == 'Assessment' ||
                                      activeUserTimeline.ut_type == 'CheckList'
                                    ) {
                                      this.getLastOverallAssessmentAnswers()
                                      this.getLastAssessmentAnswers()
                                    }
                                    this.checkCompletedFormSections()
                                  })
                                }, 2000);
                              }}
                              defaultValue={user_timeline_tagged}
                              menuPortalTarget={document.body}
                              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                              formatOptionLabel={option => (
                                <div>
                                  {option.picture ? <img src={portalURL + option.center_id + '/children/' + option.picture} style={{ width: 25, height: 25, objectFit: 'cover', marginRight: 5, borderRadius: 10 }} /> : ''}
                                  <span>{option.label}</span>
                                  {user_timeline.class_ids.length > 0 && <Badge bg="info" className='ms-1'>{option.class_theme}</Badge>}
                                </div>
                              )}
                              autoload={false}
                              isLoading={this.state.allStudentsRefreshing}
                              onFocus={() => this.getClassesChildren(user_timeline.class_ids, 'newTimeline')}
                            />
                          </Form.Group>
                          <Form.Group className='mb-3' style={{ paddingTop: 6 }}>
                            {this.state.preview ?
                              <>
                                <strong>{t('Title:')}</strong>{' '}
                                {user_timeline.ut_title}
                              </>
                              :
                              <>
                                <Form.Label>
                                  <img src={imgDescription} alt='' />
                                  {t('Title')}
                                </Form.Label>
                                <Form.Control type='text' defaultValue={user_timeline.ut_title} onBlur={(event) => this.setStateUserTimeline('ut_title', event.target.value)} />
                              </>
                            }
                          </Form.Group>
                        </>
                      }
                    </div>
                  </Col>
                </Row>
              </div>
            )}

            {/* SECTION 3 */}
            {['Media', 'Assessment', 'CheckList'].includes(activeUserTimeline.ut_type) && (<>
              <div className={'d-flex flex-row align-items-center justify-content-between section-heading'} id='form_section_3'>
                <span className='heading'>{'Media'}</span>
              </div>
              <Row>
                {!this.state.preview &&
                  <Col md='3' className='mb-1' id='form_section_3.2'>
                    <div style={{ flexDirection: 'column' }} className='pic-prev d-flex justify-content-center'>
                      <div style={{ height: 100, display: 'flex', overflow: 'hidden', borderStyle: 'dashed', borderColor: this.state.dragAreaColor.user_timeline_media, borderWidth: 2, borderRadius: 20 }} onDragEnter={(e) => this.handleDragFileArea(e, 'user_timeline_media')} onDragLeave={(e) => this.handleDragFileArea(e, 'user_timeline_media')} onDragOver={(e) => this.handleDragFileArea(e, 'user_timeline_media')} onDrop={(e) => {

                        e.preventDefault();
                        e.stopPropagation();
                        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
                          this.addAttachment({ target: { files: e.dataTransfer.files, multiple: true, name: 'user_timeline_media' } })
                        }
                      }}>
                        <button onClick={() => this.filePicker.current.click()} style={{ background: 'none', border: 0, flex: 1 }}>
                          <FontAwesomeIcon icon={faPlus} style={{ fontSize: 30, color: brand_colors[this.props.defaultTheme.theme_id].color18 }} />
                        </button>
                      </div>
                      <Form.Control className='d-none' ref={this.filePicker} type='file' multiple onChange={this.addAttachment} name='user_timeline_media' />
                    </div>
                  </Col>
                }
                {/* ALL ATTACHMENTS */}
                {!this.state.preview && user_timeline_media.map((v, i) => {
                  return (
                    <Col key={i} md='3' className='mb-1'>
                      <div style={{ position: 'relative', borderRadius: 5, borderStyle: 'solid', borderWidth: 1, borderColor: '#ced4da', height: 100, padding: 10 }} className='d-flex justify-content-center align-items-center'>
                        {/* IMAGE */}
                        {v.data.type.includes('image') && (
                          <>
                            <img src={v.url} alt={t('Preview')} style={{ height: '100%' }} />
                          </>
                        )}
                        <button
                          onClick={() => {
                            this.deleteUserTimelineMedia(v, i)
                          }}
                          style={{ background: 'none', border: 0 }}
                        >
                          <FontAwesomeIcon icon={faTrashCan} style={{ fontSize: 12, color: brand_colors[this.props.defaultTheme.theme_id].color11, position: 'absolute', bottom: 5, right: 5, cursor: 'pointer' }} />
                        </button>
                      </div>
                    </Col>
                  );
                })}
                {this.state.preview && user_timeline_media.map((v, i) => {
                  return (
                    <Col key={i} md='6' className='mb-3'>
                      <div style={{ position: 'relative', borderRadius: 5, borderStyle: 'solid', borderWidth: 1, borderColor: '#ced4da', height: 300, padding: 10 }} className='d-flex justify-content-center align-items-center'>
                        {/* IMAGE */}
                        {v.data.type.includes('image') && (
                          <>
                            <img src={v.url} alt={t('Preview')} style={{ height: '100%' }} />
                          </>
                        )}
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </>
            )}
            {/* SECTION 7 */}
            {['Assessment', 'CheckList'].includes(activeUserTimeline.ut_type) && (<>
              <div className={'d-flex flex-row align-items-center justify-content-between section-heading'} id='form_section_7'>
                <span className='heading'>{t('Observation')}</span>
              </div>
              <Row>
                {/* Observation */}
                <Col id='form_section_7.1'>
                  <Form.Group className="mb-3">
                    {this.state.preview ?
                      <>
                        <strong>{t('Observation:')}</strong>
                        <p>{user_timeline.ut_description}</p>
                      </>
                      :
                      <>
                        <Form.Label>{t('Observation')}</Form.Label>
                        <Form.Control as="textarea" rows={3} defaultValue={user_timeline.ut_description} onBlur={(event, editor) => this.setStateUserTimeline('ut_description', event.target.value)} />
                      </>
                    }
                  </Form.Group>
                </Col>
                {/* Next Steps */}
                <Col id='form_section_7.2'>
                  <Form.Group className="mb-3">
                    {this.state.preview ?
                      <>
                        <strong>{t('Next Steps:')}</strong>
                        <p>{user_timeline_metadata.description2}</p>
                      </>
                      :
                      <>
                        <Form.Label>{t('Next Steps')}</Form.Label>
                        <Form.Control as="textarea" rows={3} defaultValue={user_timeline_metadata.description2} onBlur={(event, editor) => this.setStateUserTimelineMetadata('description2', event.target.value)} />
                      </>
                    }
                  </Form.Group>
                </Col>
              </Row>

              <Row id='form_section_7.3' className="pt-3">
                {!this.state.preview &&
                  <Row className="pe-0">
                    <Col md={6}>
                      <Accordion>
                        {/* BOARDS */}
                        {assessmentMenuData.progass && assessmentMenuData.progass.map((a_item, a_index) => {
                          return <Accordion.Item key={a_index} eventKey={`a_${a_index}`}>
                            <Accordion.Header><strong>{a_item.progass_code}</strong></Accordion.Header>
                            <Accordion.Body>
                              <Accordion>
                                {/* AGE GROUPS */}
                                {assessmentMenuData.agegroup && assessmentMenuData.agegroup
                                  .filter(b_item => b_item.progass_id == a_item.progass_id)
                                  .map((b_item, b_index) => {
                                    return <Accordion.Item key={b_index} eventKey={`b_${b_index}`}>
                                      <Accordion.Header><span style={{
                                        display: 'inline-block',
                                        width: 15,
                                        height: 15,
                                        background: b_item.agegroup_color,
                                        borderRadius: 3,
                                        marginRight: 5
                                      }}></span><strong>{b_item.agegroup_code}</strong></Accordion.Header>
                                      <Accordion.Body>
                                        <Accordion>
                                          {/* LEARNAERAS */}
                                          {assessmentMenuData.learnarea &&
                                            assessmentMenuData.learnarea
                                              .filter(c_item => c_item.progass_id == a_item.progass_id)
                                              .map((c_item, c_index) => {
                                                return <Accordion.Item key={c_index} eventKey={`c_${c_index}`}>
                                                  <Accordion.Header><span style={{
                                                    display: 'inline-block',
                                                    width: 15,
                                                    height: 15,
                                                    background: c_item.learnarea_color,
                                                    borderRadius: 3,
                                                    marginRight: 5
                                                  }}></span><strong>{c_item.learnarea_code}</strong></Accordion.Header>
                                                  <Accordion.Body>
                                                    <Accordion defaultActiveKey={'d_0'}>
                                                      {assessmentMenuData.lrnaspect &&
                                                        assessmentMenuData.lrnaspect
                                                          .filter(d_item => d_item.learnarea_id == c_item.learnarea_id)
                                                          .map((d_item, d_index) => {

                                                            let curriculums = [];

                                                            if (assessmentMenuData.allCurriculum) {
                                                              curriculums = assessmentMenuData.allCurriculum
                                                                .filter(e_item => e_item.lrnaspect_id == d_item.lrnaspect_id && e_item.agegroup_id == b_item.agegroup_id)
                                                            }

                                                            return <Accordion.Item key={d_index} eventKey={`d_${d_index}`}>
                                                              <Accordion.Header><strong>{d_item.lrnaspect_desc}</strong><small className='text-muted ms-1'>({curriculums.length})</small></Accordion.Header>
                                                              <Accordion.Body>
                                                                {curriculums.map((e_item, e_index) => {
                                                                  return <>
                                                                    <div key={e_index}><strong>#{e_item.curriculum_ref_num}</strong> {e_item.curriculum_code}</div>
                                                                    {assessmentMenuData.assessment
                                                                      .filter(f_item => f_item.progass_id == a_item.progass_id)
                                                                      .map((f_item, f_index) => <button style={{
                                                                        background: 'transparent',
                                                                        border: f_item.assmt_color + ' 1px solid',
                                                                        color: f_item.assmt_color,
                                                                        borderRadius: 4,
                                                                        marginRight: 7,
                                                                        marginTop: 7
                                                                      }}
                                                                        onClick={() => this.addAssessment(
                                                                          a_item.progass_id,
                                                                          b_item.agegroup_id,
                                                                          c_item.learnarea_id,
                                                                          d_item.lrnaspect_id,
                                                                          e_item,
                                                                          f_item
                                                                        )}
                                                                        key={f_index}>{f_item.assmt_code}</button>)}
                                                                    {(curriculums.length - 1) != e_index && <hr />}
                                                                  </>
                                                                })}
                                                              </Accordion.Body>
                                                            </Accordion.Item>
                                                          })}
                                                    </Accordion>
                                                  </Accordion.Body>
                                                </Accordion.Item>
                                              })}
                                        </Accordion>
                                      </Accordion.Body>
                                    </Accordion.Item>
                                  })}
                              </Accordion>
                            </Accordion.Body>
                          </Accordion.Item>
                        })}
                      </Accordion>
                    </Col>
                    <Col md={6} className="pe-0">
                      {this.renderAssessmentList()}
                    </Col>
                  </Row>
                }
              </Row>

              {this.state.preview && this.renderAssessmentList()}

            </>)}

            {/* EXTRA PADDING FROM BOTTOM */}
            <div style={{ height: 400 }}></div>
          </Col>
        </div>
      </Offcanvas.Body >
    </Offcanvas >
  }

  publishUnpublish = async (ut_id) => {

    const data = this.state.cdt.data;

    const index = this.state.cdt.data.findIndex(item => item.ut_id == ut_id);

    const status = (parseInt(this.state.cdt.data[index].ut_publish_status) == 0) ? 1 : 0;

    data[index].ut_publish_status = status;

    const fData = new FormData();
    fData.append('ut_id', ut_id);
    fData.append('status', status);
    fData.append('assesslog_id', data[index].ut_type_table_id);

    try {
      const res = await POST('user-timeline/pub-un-pub', fData);
      toast.success(t('Status updated successfully!'));
      this.setState({
        cdt: {
          ...this.state.cdt,
          data,
        }
      });
    } catch (err) {
      console.log(err);
    }


  }

  markReviewed = async (ut_id) => {

    const { authData } = this.props;

    const data = this.state.cdt.data;

    const index = this.state.cdt.data.findIndex(item => item.ut_id == ut_id);

    if (data[index].reviewed_by) {
      toast.info(t('Already marked as reviewed!'));
    } else {

      data[index].reviewed_by = authData.loginData.full_name;
      data[index].reviewed_date = moment();

      const fData = new FormData();
      fData.append('assesslog_id', data[index].ut_type_table_id);

      try {
        const res = await POST('user-timeline/reviewed', fData);
        toast.success(t('Marked as reviewed successfully!'));
        this.setState({
          cdt: {
            ...this.state.cdt,
            data,
          }
        });
      } catch (err) {
        console.log(err);
      }
    }

  }

  addAssessment = (progass_id, agegroup_id, learnarea_id, lrnaspect_id, curriculum, assessment) => {
    const { user_timeline, assess_aspect, assess_curr } = this.state;

    const find_assess_aspect = assess_aspect.findIndex(item => item.agegroup_id == agegroup_id && item.lrnaspect_id == lrnaspect_id);
    const find_assess_curr = assess_curr.findIndex(item => item.curr_agegroup_id == agegroup_id && item.curr_aspect_id == lrnaspect_id && item.curriculum_id == curriculum.curriculum_id);

    if (find_assess_aspect == -1) {
      assess_aspect.unshift({
        assaspect_id: 0,
        center_id: user_timeline.center_id,
        assesslog_id: user_timeline.ut_type_table_id,
        lrnaspect_id: lrnaspect_id,
        agegroup_id: agegroup_id,
        assagegroup_value: 0,
        assagegroup_value_num: 0
      });
    }

    if (find_assess_curr == -1) {
      assess_curr.unshift({
        asscurr_id: 0,
        assesslog_id: 0,
        curriculum_id: curriculum.curriculum_id,
        center_id: user_timeline.center_id,
        curr_aspect_id: lrnaspect_id,
        curr_agegroup_id: agegroup_id,
        asscurr_value: assessment.assmt_id,
        asscurr_value_num: 0,
        curriculum_ref_num: curriculum.curriculum_ref_num,
        curriculum_code: curriculum.curriculum_code,
        curriculum_desc: curriculum.curriculum_desc,
        curriculum_desc2: curriculum.curriculum_desc2
      });
    } else {
      assess_curr[find_assess_curr].asscurr_value = assessment.assmt_id;
    }

    this.setState({ assess_aspect, assess_curr });

  }

  renderCard = (dataObj, index) => {
    console.log('dataObj', dataObj);

    const fontSize = 12;
    const limit = 200;
    const obs_img_placeholder = require('../../assets/images/obs-placeholder.jpg');

    let thumbURL;

    if (dataObj.user_timeline_media.length > 0) {
      if (dataObj.user_timeline_media[0].utm_folder == 'educorepublic') {
        thumbURL = dataObj.user_timeline_media[0].utm_url;
      } else {
        thumbURL = portalURL + dataObj.center_id + '/user_timeline_media/' + dataObj.user_timeline_media[0].utm_name;
      }
    }


    return <Col className='d-flex' md={3}>
      <div style={{
        width: '100%',
        backgroundColor: '#ffffff',
        boxShadow: '0 1px 2px rgb(0 0 0 / 20%)',
        borderRadius: 15,
        marginBottom: 20,
        padding: 20,
        position: 'relative',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column'
      }}>
        <div style={{ flex: 1 }}>
          <div style={{
            position: 'absolute',
            top: 0,
            right: 0,
            display: 'flex'
          }}>
            <Badge
              bg="dark"
              style={{
                borderRadius: 0,
                borderBottomLeftRadius: 5,
                fontSize: 11,
                paddingRight: 7
              }}>
              {moment(dataObj.updated_on).format('DD-MM-YYYY')}
            </Badge>
            {dataObj.ut_publish_status == 0 ?
              <Badge
                style={{
                  borderRadius: 0,
                  fontSize: 11,
                  paddingRight: 7
                }}
                bg="danger">{t('NOT PUBLISHED')}</Badge>
              :
              <Badge
                style={{
                  borderRadius: 0,
                  fontSize: 11,
                  paddingRight: 7
                }}
                bg="success">{t('PUBLISHED')}</Badge>
            }
          </div>
          <Row className='pt-2'>
            <Col md={6} className='mb-2' style={{ fontSize }}>
              {dataObj.user_timeline_tagged[0]?.child_name} ({dataObj.classes[0]?.class_theme})
            </Col>
            <Col md={6} className='mb-2 text-end' style={{ fontSize }}> <FontAwesomeIcon icon={faUser} color='#F39400' /> {dataObj.created_by_name}</Col>
          </Row>
          <Row>
            <Col md={12}>
              {dataObj.user_timeline_media.length > 0 ?
                <div className='mb-2'
                  onClick={() => this.setState({
                    imagesSlider: {
                      show: true,
                      data: dataObj
                    }
                  })}
                  style={{
                    cursor: 'pointer',
                    height: 149,
                    borderRadius: 10,
                    backgroundImage: `url(${thumbURL})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center center',
                    backgroundColor: '#C9C8C8'
                  }}>
                </div>
                :
                <Image src={obs_img_placeholder} className='img-fluid mb-2' style={{ borderRadius: 10 }} />
              }
            </Col>
          </Row>
          <Row>
            <Col style={{ fontSize }} className='mb-3'>
              <strong>{dataObj.ut_title}</strong><br />
              {(dataObj.ut_description.length > limit && !this.state?.[`show_${index}`]) ? dataObj.ut_description.substring(0, limit) + '...' : dataObj.ut_description}
              <br />
              {dataObj.ut_description.length > limit &&
                <span
                  onClick={() => this.setState({
                    [`show_${index}`]: !this.state?.[`show_${index}`]
                  })}
                  className='cursor-pointer badge bg-dark mt-2'>
                  {!this.state?.[`show_${index}`] ? t('Read More...') : t('Read Less...')}
                </span>
              }
            </Col>
          </Row>
        </div>
        <Row>
          <Col md={8} className='pe-0' style={{ paddingBottom: 12 }}>
            <div style={{
              background: '#f2f2f2',
              borderRadius: 10,
              textAlign: 'center',
              padding: 4,
              fontSize,
            }}>
              <strong>{t('Reviewed')}</strong>
              <div style={{
                background: '#fff',
                borderRadius: 5,
                marginTop: 3,
                padding: '2px 0',
                height: 40,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}>{dataObj.reviewed_by ?
                <>
                  {dataObj.reviewed_by}<br />{moment(dataObj.reviewed_date).format('DD-MM-YYYY')}
                </>
                :
                'N/A'
                }
              </div>
            </div>
          </Col>
          <Col md={4}>
            {dataObj.user_timeline_media.length == 0 ?
              <Button style={{
                width: '100%',
                height: 67,
                padding: '0 10px',
                cursor: 'cancel',
                borderRadius: 10,
              }} size='sm' disabled>Images <br /><Badge style={{ marginTop: 5 }} bg="light" text="dark">
                  N/A
                </Badge></Button> :
              <Button
                onClick={() => this.setState({
                  imagesSlider: {
                    show: true,
                    data: dataObj
                  }
                })}
                style={{
                  width: '100%',
                  height: 67,
                  padding: '0 10px',
                  borderRadius: 10,
                }} size='sm'>Images<br /><Badge style={{ marginTop: 5 }} bg="light" text="dark">
                  {dataObj.user_timeline_media.length}
                </Badge></Button>
            }
          </Col>
        </Row>
        <div className='d-flex obs-btns'>
          <Button
            style={{ borderRadius: 10 }}
            onClick={() => this.deleteUserTimeline(dataObj)}
            variant="danger" size='sm' className='me-1'>
            <FontAwesomeIcon icon={faTrash} />
          </Button>
          <Button
            style={{ borderRadius: 10 }}
            onClick={() => this.editUserTimeline(dataObj.ut_id)}
            variant="warning" size='sm' className='me-1'>
            <FontAwesomeIcon icon={faPencil} />
          </Button>
          {(!this.props.authData.userData || (parseInt(this.props.authData.userData.curriculumshare) == 1)) &&
            <Button
              style={{ borderRadius: 10 }}
              title={dataObj.ut_publish_status == 0 ? t('Publish Now') : t('Unpublish Now')}
              onClick={() => this.publishUnpublish(dataObj.ut_id)}
              variant={dataObj.ut_publish_status == 0 ? 'success' : 'danger'} size='sm' className='me-1'>
              <FontAwesomeIcon
                color='#fff' icon={dataObj.ut_publish_status == 0 ? faThumbsUp : faThumbsDown} />
            </Button>
          }
          <Button
            style={{ borderRadius: 10 }}
            onClick={() => this.markReviewed(dataObj.ut_id)}
            variant="info" size='sm' className='me-1' title={t('Mark Reviewed')}>
            <FontAwesomeIcon icon={faStar} style={{ color: dataObj.reviewed_by ? 'rgb(236 219 160)' : '#fff' }} />
          </Button>
          <Button
            style={{ borderRadius: 10 }}
            onClick={() => {
              this.editUserTimeline(dataObj.ut_id, false, true);
            }}
            variant="info" size='sm'>
            <FontAwesomeIcon icon={faList} />
          </Button>
        </div>
      </div>
    </Col>
  }

  render() {

    console.log('%cSTATE', 'font-size: 20px; color: orange;');
    console.log(this.state);

    const sliderOptions = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      // autoplay: true,
      // autoplaySpeed: 3576,
      // pauseOnHover: false
    };

    const { assessmentMenu, assessmentMenuData } = this.state;

    const page = this.state.cdt.page + 1;

    return (
      <Container fluid>
        <div id='iedu-layout'>
          <NavBar />
          <div id='page-content'>
            <Header lite={true} heading={t('Progress Analysis')} backBtn={true} />
            <div className='grey-section'>
              <Card
                className='border-0'
                style={{
                  borderRadius: '20px',
                  marginBottom: 25,
                  boxShadow: '0 1px 2px rgb(0 0 0 / 20%)'
                }}>
                <Card.Body>
                  <Row>
                    <Col md={3} className='pe-0'>
                      <Select
                        isClearable
                        placeholder={t('Select Curriculum')}
                        options={this.state.academicBoards}
                        onChange={(obj) => {
                          this.setState({
                            selectedAcademicBoard: obj
                          }, () => this.getAbAgegroups());
                        }}
                        menuPortalTarget={document.body}
                        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                      />
                    </Col>
                    <Col md={3} className='pe-0'>
                      <Select
                        isClearable
                        placeholder={t('Select Class')}
                        options={this.props.authData.classes
                          .filter(item => {
                            const agegroup_ids = this.state.abAgegroups.map(ag => {
                              return ag.agegroup_id
                            });
                            return agegroup_ids.includes(item.class.agegroup_id);
                          })
                          .map((v, i) => { return { label: v.class.class_theme, value: v.class.class_id, ...v.class } })}
                        onChange={(obj) => this.setState({ selectedClass: obj })}
                        menuPortalTarget={document.body}
                        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                      />
                    </Col>
                    <Col className='d-flex align-items-center'>
                      <Button variant={'success'}
                        onClick={this.getStudents}>
                        {this.state.fetching
                          ? <Spinner as='span' animation='grow' size='sm' role='status' aria-hidden='true' />
                          : <FontAwesomeIcon icon={faSearch} style={{ fontSize: 16, color: brand_colors[this.props.defaultTheme.theme_id].color8, }} />
                        }
                      </Button>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
              {this.state.students.length === 0 &&
                <div className='p-5 text-center'>
                  {t('No Data Available!')}
                </div>
              }
              <Row>
                {this.state.students.map((item, index) => {

                  let studentImg = require('../../assets/images/user.jpg');

                  if (item.picture) {
                    studentImg = portalURL + item.center_id + '/children/' + item.picture;
                  }

                  return <Col key={index} md={2} className="d-flex">
                    <Card style={{
                      flex: 1,
                      borderRadius: '20px',
                      marginBottom: 20,
                      boxShadow: '0 1px 2px rgb(0 0 0 / 20%)',
                      border: 0
                    }}>
                      <Card.Body className='text-center'>
                        <div style={{
                          width: 75,
                          height: 75,
                          borderRadius: 50,
                          backgroundImage: `url(${studentImg})`,
                          backgroundSize: 'cover',
                          backgroundPosition: 'center center',
                          margin: '0 auto 10px',
                          border: '#f2f2f2 2px solid'
                        }}></div>
                        <div className='mb-2'>{item.child_name}</div>
                        <Row>
                          <Col className='pe-1'>
                            <Button onClick={() => this.showProgressModal(item)}
                              title={t('View Progress')}
                              style={{ width: '100%', borderRadius: 10 }} variant="primary" size="sm">
                              <FontAwesomeIcon icon={faChartPie} className='me-1' />
                            </Button>
                          </Col>
                          <Col className='ps-1'>
                            <Button onClick={() => this.showObservationsModal(item)}
                              title={t('View Observations')}
                              style={{ width: '100%', borderRadius: 10 }} variant="success" size="sm">
                              <FontAwesomeIcon icon={faEye} className='me-1' />
                            </Button>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                })}
              </Row>
            </div>
          </div>
        </div>


        {/* Progress */}
        <Modal Modal
          size='xxl'
          show={this.state.progressModal.show} onHide={this.hideProgressModal}>
          <Modal.Header closeButton>
            <Modal.Title>{t('Progress of') + ' ' + this.state.progressModal.data?.child_name}</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ backgroundColor: '#F2F2F2', padding: 25, paddingBottom: 0 }}>
            {this.state.expandedData ?
              <Row>
                <Col md={12}>
                  <Card style={{
                    border: 0,
                    borderRadius: 20,
                    marginBottom: 20
                  }}>
                    <Card.Body>
                      <Row>
                        <Col className='d-flex align-items-center'>
                          <h4 className='mb-0 ms-2' style={{
                            color: this.state.expandedData.learnarea_color
                          }}>{this.state.expandedData.learnarea_code} <span style={{
                            backgroundColor: this.state.expandedData.agegroup.agegroup_color,
                            fontSize: 15,
                            color: '#fff',
                            borderRadius: 5,
                            padding: '3px 10px',
                            position: 'relative',
                            top: -2
                          }}>{this.state.expandedData.agegroup.agegroup_code}</span></h4>
                        </Col>
                        <Col className='text-end'>
                          <Button onClick={() => this.setState({ expandedData: null })}>
                            <FontAwesomeIcon icon={faCompress} />
                          </Button>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
                {this.state.expandedData.lrnaspects.map((item, index) => {
                  return <Col md={6} key={index} className='mb-4 d-flex'>
                    <Card style={{
                      border: 0,
                      borderRadius: 20,
                      width: '100%',
                      // boxShadow: '0 1px 2px rgb(0 0 0 / 20%)',
                    }}>
                      <Card.Body style={{ paddingBottom: 24, position: 'relative' }}>
                        <div style={{
                          flex: 1,
                          height: 380,
                          overflow: 'hidden',
                        }}>
                          <ReactApexChart
                            options={item.options}
                            series={item.series}
                            type="radialBar"
                            height={700}
                          />
                        </div>
                        <h5 className='text-center'>{item.lrnaspect_code}</h5>
                        <Table size="sm" style={{ width: 'calc(100% - 20px)', margin: '0 10px' }}>
                          {/* <tbody>
                            {item.covered_curr.length == 0 &&
                              <tr>
                                <td colSpan={3} style={{ textAlign: 'center' }}>{t('No Data Available!')}</td>
                              </tr>
                            }
                            {item.covered_curr.map((b_item, b_index) => {
                              return <tr key={b_index}>
                                <td style={{ width: '4%' }}><strong>{b_item.index}.</strong></td>
                                <td>{b_item.curriculum_code}</td>
                                <td style={{ width: '10%', textAlign: 'right', color: b_item.last_assmt.assmt_color }}>
                                  {b_item.last_assmt.assmt_code}
                                </td>
                              </tr>
                            })}
                          </tbody> */}
                          <tbody>
                            {item.all_curr.length == 0 &&
                              <tr>
                                <td colSpan={3} style={{ textAlign: 'center' }}>{t('No Data Available!')}</td>
                              </tr>
                            }
                            {item.all_curr
                              .filter(curr => curr.agegroup_id == this.state.expandedData.agegroup.agegroup_id)
                              .map((b_item, b_index) => {

                                const covered_curr = item.covered_curr.find(c_item => c_item.curr_agegroup_id == this.state.expandedData.agegroup.agegroup_id && c_item.curriculum_id == b_item.curriculum_id);

                                return <tr key={b_index}>
                                  <td style={{ width: '4%' }}><strong>{b_index + 1}.</strong></td>
                                  <td>{b_item.curriculum_code}</td>
                                  {/* <td style={{ width: '10%', textAlign: 'right', color: b_item.last_assmt.assmt_color }}>
                                  {b_item.last_assmt.assmt_code}
                                </td> */}
                                  <td style={{ width: '10%', textAlign: 'right', color: covered_curr?.last_assmt?.assmt_color }}>
                                    {covered_curr?.last_assmt?.assmt_code}
                                  </td>
                                </tr>
                              })}
                          </tbody>
                        </Table>
                      </Card.Body>
                    </Card>
                  </Col>
                })}
              </Row>
              :
              <Row>
                <Col md={12}>
                  {this.state.agegroups.map((item, index) =>
                    <Button
                      key={index}
                      onClick={() => this.setState({ selectedAgeGroup: item })}
                      style={{
                        backgroundColor: item.agegroup_color,
                        borderColor: item.agegroup_color
                      }}
                      className='mb-3 me-2'><FontAwesomeIcon className='me-2' icon={(this.state.selectedAgeGroup && this.state.selectedAgeGroup.agegroup_id == item.agegroup_id) ? faCheckCircle : faCircle} />{item.agegroup_code}</Button>
                  )}
                </Col>
                {this.state.fetchingData &&
                  <Col md={12} className='text-center'>
                    <div className="p-5">
                      <Spinner animation='grow' size='sm' />{' '}
                      {t('Loading...')}
                    </div>
                  </Col>
                }
                {this.state.generalData
                  .filter(item => (this.state.selectedAgeGroup && item.agegroup_id == this.state.selectedAgeGroup.agegroup_id))
                  .map((a_item, a_index) => <>
                    {a_item.learnareas.map((b_item, b_index) => <Col key={a_index} md={4} className='mb-4 d-flex'>
                      <Card style={{
                        border: 0,
                        borderRadius: 20,
                        width: '100%',
                        // boxShadow: '0 1px 2px rgb(0 0 0 / 20%)',
                      }}>
                        <Card.Body style={{ paddingBottom: 24, position: 'relative' }}>
                          <Button onClick={() => this.expandGraph({
                            ...b_item,
                            agegroup: a_item
                          })}
                            style={{
                              position: 'absolute',
                              top: 20,
                              right: 20,
                              zIndex: 1
                            }}>
                            <FontAwesomeIcon icon={faExpand} />
                          </Button>
                          <div style={{
                            flex: 1,
                            height: 250,
                            overflow: 'hidden',
                          }}>
                            <ReactApexChart
                              options={b_item.options}
                              series={b_item.series}
                              type="radialBar"
                              height={450}
                            />
                          </div>
                          <h4 className='text-center'
                            style={{
                              color: b_item.learnarea_color
                            }}
                          >{b_item.learnarea_code}
                            <br />
                            <span style={{
                              backgroundColor: a_item.agegroup_color,
                              fontSize: 15,
                              color: '#fff',
                              borderRadius: 5,
                              padding: '3px 10px'
                            }}>{a_item.agegroup_code}</span>
                          </h4>
                          <Table size="sm" style={{ width: 'calc(100% - 20px)', margin: '0 10px' }}>
                            <tbody>
                              {b_item.lrnaspects.map((c_item, c_index) => {

                                const colors = colorShades(b_item.learnarea_color);

                                return <tr key={c_index}>
                                  <td style={{ color: colors[c_index] }}>{c_item.lrnaspect_code}</td>
                                  <td style={{ width: '10%', textAlign: 'right' }}>{isNaN(parseFloat(parseInt(c_item.covered_curr.filter(item => item.curr_agegroup_id == a_item.agegroup_id).length) / parseInt(c_item.curr_count.filter(item => item.agegroup_id == a_item.agegroup_id).length) * 100).toFixed(2)) ? '0.00' : parseFloat(parseInt(c_item.covered_curr.filter(item => item.curr_agegroup_id == a_item.agegroup_id).length) / parseInt(c_item.curr_count.filter(item => item.agegroup_id == a_item.agegroup_id).length) * 100).toFixed(2)}%</td>
                                </tr>
                              })}
                            </tbody>
                          </Table>
                        </Card.Body>
                      </Card>
                    </Col>)}
                  </>)}
              </Row>
            }
          </Modal.Body>
        </Modal>

        {/* Observations */}
        <Modal
          size='xxl'
          show={this.state.observationsModal.show} onHide={this.hideObservationsModal}
          style={(this.state.assesslogResults.show || this.state.imagesSlider.show) && { zIndex: 10 }}>
          <Modal.Header closeButton>
            <Row style={{ flex: 1 }}>
              <Col><Modal.Title>{t('Observations of') + ' ' + this.state.observationsModal.data?.child_name}</Modal.Title></Col>
              <Col className='d-flex justify-content-end align-items-center pe-4'>
                <Form.Check
                  checked={this.state.reviewObsFlag}
                  type="switch"
                  id="custom-switch"
                  label="Not Reviewed Observations"
                  onClick={() => this.setState({ reviewObsFlag: !this.state.reviewObsFlag }, () => this.getObservations({
                    search: this.state.cdt.search,
                    limit: this.state.cdt.rows,
                    order: this.state.cdt.order,
                    dir: this.state.cdt.dir,
                    page: 0
                  }))}
                />
              </Col>
            </Row>
          </Modal.Header>
          <Modal.Body style={{ backgroundColor: '#F2F2F2' }}>
            <Row>
              {/* {this.state.cdt.fetching && this.state.cdt.page == 0 &&
                <Col md={12} className='py-5 d-flex flex-column align-items-center'>
                  <Spinner as='span' animation='grow' size='sm' /> {t('Loading Data')}
                </Col>
              } */}
              {!this.state.cdt.fetching && this.state.cdt.data.length === 0 &&
                <Col md={12} className='py-5 d-flex flex-column align-items-center'>
                  {t('No Data Found')}
                </Col>
              }
              {this.state.cdt.data.length !== 0 &&
                <Col md={12}>
                  <Row>
                    {this.state.cdt.data.map((item, index) => this.renderCard(item, index))}
                  </Row>
                  <Row>
                    <Col className='text-center'>
                      <Button
                        disabled={page >= this.state.cdt.lastPage}
                        onClick={() =>
                          this.getObservations({
                            search: this.state.cdt.search,
                            limit: this.state.cdt.rows,
                            order: this.state.cdt.order,
                            dir: this.state.cdt.dir,
                            page
                          })
                        }
                        variant='dark'>{page >= this.state.cdt.lastPage ? t('Nothing To Load More') : t('Load More...')}</Button>
                    </Col>
                  </Row>
                </Col>}
            </Row>
          </Modal.Body>
        </Modal>

        {/* {
          this.state.processingEdit &&
          <div style={{ height: '100vh', width: '100vw', position: 'fixed', backgroundColor: 'rgba(0,0,0,0.5)', top: 0, left: 0, justifyContent: 'center', alignItems: 'center', display: 'flex', zIndex: 99 }}>
            <img src={loaderAnim} alt='iEducore' width='120' />
          </div>
        } */}

        <SweetAlert
          show={this.state.deleteModal.show}
          warning
          showCancel
          confirmBtnText={t('Yes, delete it!')}
          confirmBtnBsStyle='danger'
          title={(this.state.deleteModal.title) ? this.state.deleteModal.title : t('Are you sure?')}
          onConfirm={this.state.deleteModal.action}
          onCancel={() => this.setState({ deleteModal: { ...this.state.deleteModal, show: false, id: 0, action: () => { } } })}
          focusCancelBtn>
          {(this.state.deleteModal.desc) ? this.state.deleteModal.desc : t('Confirm Delete!')}
        </SweetAlert>

        {/* Images Slider */}
        <Modal
          centered
          size='lg'
          show={this.state.imagesSlider.show}
          onHide={() => this.setState({
            imagesSlider: {
              show: false,
              data: null
            }
          })}
        >
          <Modal.Header closeButton>
            <Modal.Title>{t('Images')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Slider className='obsrv-images-slider' {...sliderOptions}>
              {this.state.imagesSlider.data?.user_timeline_media.map((item, index) => {

                let imgURL = portalURL + this.state.imagesSlider.data.center_id + '/user_timeline_media/' + item.utm_name;

                if (item.utm_folder == 'educorepublic') {
                  imgURL = item.utm_url;
                }

                return <div key={index}>
                  <img src={imgURL} className='img-fluid' alt='1' />
                </div>
              })
              }
            </Slider>
          </Modal.Body>
        </Modal>

        {/* Assesslog Results */}
        <Modal
          size='xxl'
          show={this.state.assesslogResults.show}
          onHide={() => this.setState({
            assesslogResults: {
              show: false,
            },
          })}
        >
          <Modal.Header closeButton>
            <Modal.Title>{this.state.user_timeline_tagged[0]?.child_name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col><p>{this.state.user_timeline.ut_description}</p></Col>
            </Row>
            <Row>
              <Col md={6}>
                <Accordion>
                  {/* BOARDS */}
                  {assessmentMenuData.progass && assessmentMenuData.progass.map((a_item, a_index) => {
                    return <Accordion.Item key={a_index} eventKey={`a_${a_index}`}>
                      <Accordion.Header><strong>{a_item.progass_code}</strong></Accordion.Header>
                      <Accordion.Body>
                        <Accordion>
                          {/* AGE GROUPS */}
                          {assessmentMenuData.agegroup && assessmentMenuData.agegroup
                            .filter(b_item => b_item.progass_id == a_item.progass_id)
                            .map((b_item, b_index) => {
                              return <Accordion.Item key={b_index} eventKey={`b_${b_index}`}>
                                <Accordion.Header><span style={{
                                  display: 'inline-block',
                                  width: 15,
                                  height: 15,
                                  background: b_item.agegroup_color,
                                  borderRadius: 3,
                                  marginRight: 5
                                }}></span><strong>{b_item.agegroup_code}</strong></Accordion.Header>
                                <Accordion.Body>
                                  <Accordion>
                                    {/* LEARNAERAS */}
                                    {assessmentMenuData.learnarea &&
                                      assessmentMenuData.learnarea
                                        .filter(c_item => c_item.progass_id == a_item.progass_id)
                                        .map((c_item, c_index) => {
                                          return <Accordion.Item key={c_index} eventKey={`c_${c_index}`}>
                                            <Accordion.Header><span style={{
                                              display: 'inline-block',
                                              width: 15,
                                              height: 15,
                                              background: c_item.learnarea_color,
                                              borderRadius: 3,
                                              marginRight: 5
                                            }}></span><strong>{c_item.learnarea_code}</strong></Accordion.Header>
                                            <Accordion.Body>
                                              <Accordion>
                                                {assessmentMenuData.lrnaspect &&
                                                  assessmentMenuData.lrnaspect
                                                    .filter(d_item => d_item.learnarea_id == c_item.learnarea_id)
                                                    .map((d_item, d_index) => {

                                                      let curriculums = [];

                                                      if (assessmentMenuData.allCurriculum) {
                                                        curriculums = assessmentMenuData.allCurriculum
                                                          .filter(e_item => e_item.lrnaspect_id == d_item.lrnaspect_id && e_item.agegroup_id == b_item.agegroup_id)
                                                      }

                                                      return <Accordion.Item key={d_index} eventKey={`d_${d_index}`}>
                                                        <Accordion.Header><strong>{d_item.lrnaspect_desc}</strong><small className='text-muted ms-1'>({curriculums.length})</small></Accordion.Header>
                                                        <Accordion.Body>
                                                          {curriculums.map((e_item, e_index) => {
                                                            return <>
                                                              <div key={e_index}><strong>#{e_item.curriculum_ref_num}</strong> {e_item.curriculum_code}</div>
                                                              {assessmentMenuData.assessment
                                                                .filter(f_item => f_item.progass_id == a_item.progass_id)
                                                                .map((f_item, f_index) => <button style={{
                                                                  background: 'transparent',
                                                                  border: f_item.assmt_color + ' 1px solid',
                                                                  color: f_item.assmt_color,
                                                                  borderRadius: 4,
                                                                  marginRight: 7,
                                                                  marginTop: 7
                                                                }}
                                                                  onClick={() => this.addAssessment(
                                                                    a_item.progass_id,
                                                                    b_item.agegroup_id,
                                                                    c_item.learnarea_id,
                                                                    d_item.lrnaspect_id,
                                                                    e_item,
                                                                    f_item
                                                                  )}
                                                                  key={f_index}>{f_item.assmt_code}</button>)}
                                                              {(curriculums.length - 1) != e_index && <hr />}
                                                            </>
                                                          })}
                                                        </Accordion.Body>
                                                      </Accordion.Item>
                                                    })}
                                              </Accordion>
                                            </Accordion.Body>
                                          </Accordion.Item>
                                        })}
                                  </Accordion>
                                </Accordion.Body>
                              </Accordion.Item>
                            })}
                        </Accordion>
                      </Accordion.Body>
                    </Accordion.Item>
                  })}
                </Accordion>
              </Col>
              <Col md={6}>
                {this.renderAssessmentList()}
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" disabled={this.state.userTimelineModal.saving} onClick={() => this.saveUserTimeline()}>{t('Save')}
              {this.state.userTimelineModal.saving && <Spinner as='span' animation='grow' size='sm' role='status' aria-hidden='true' />}</Button>
          </Modal.Footer>
        </Modal>

        {this.renderForm()}

      </Container >
    );
  }
}

const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  defaultLanguage: state.language.defaultLanguage,
  defaultTheme: state.theme.defaultTheme,
});

export default connect(mapStateToProps, null)(ProgressAnalysis);
