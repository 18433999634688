import React, { Component } from 'react';
import { Row, Col, Button, Spinner, Modal, Form, Table } from 'react-bootstrap';
import { t } from '../../../helpers/translation_helper';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faCircleCheck, faPenToSquare, faPlus } from '@fortawesome/free-solid-svg-icons';
import { GET, POST } from '../../../api';
import { setEvalCat, setEvalTopic } from '../../../redux/slices/evaluationSlice';
import { toast } from 'react-toastify';
import { handleFormErrors } from '../../../helpers/form_helpers';
import { Scrollbar } from 'react-scrollbars-custom';
import { brand_colors } from '../../../helpers/brand_colors_helper';
import SweetAlert from 'react-bootstrap-sweetalert';

class Categories extends Component {

  defaultFormFields = {
    appraisalcat_id: 0,
    appraisalcat_code: '',
    appraisalcat_desc: '',
  }

  state = {
    ...this.defaultFormFields,
    deleteModal: { show: false, id: 0, action: () => { } },
    cats: [],
    working: false,
    isEditing: false,
    showFormModal: false,
  }

  componentDidUpdate(prevProps) {
    if (this.props.evalGroup && prevProps.evalGroup !== this.props.evalGroup) {
      this.getCats();
    }
    else if (!this.props.evalGroup && prevProps.evalGroup !== this.props.evalGroup) {
      this.setState({ cats: [] });
    }
  }

  getCats = async () => {

    this.setState({ cats: null });

    try {
      const res = await GET('evaluation-setup/get-cats', {
        params: {
          appraisalgrp_id: this.props.evalGroup
        }
      });

      if (res.status == 200) {
        this.setState({
          cats: res.data
        });
      }

    } catch (err) {
      console.log(err);
    }
  }

  catCheck = (id) => {
    return this.props.evalCat == id;
  }

  handleOpenFormModal = () => {
    this.setState({
      showFormModal: true
    });
  }

  handleCloseFormModal = () => {
    this.setState({
      showFormModal: false,
      isEditing: false,
      ...this.defaultFormFields
    });
  }

  saveUpdate = async () => {

    const { authData } = this.props;
    const { center_id } = authData.centerData;

    this.setState({ working: true });

    const {
      appraisalcat_id,
      appraisalcat_code,
      appraisalcat_desc
    } = this.state;

    const fData = new FormData();
    fData.append('center_id', center_id);
    fData.append('evalCatData', JSON.stringify({
      appraisalcat_id,
      appraisalgrp_id: this.props.evalGroup,
      appraisalcat_code,
      appraisalcat_desc,
    }));

    try {
      const res = await POST('evaluation-setup/save-cat', fData);
      if (res.data.type) {
        this.setState({ working: false });
        toast.success(t(res.data.message));
        this.getCats();
        this.handleCloseFormModal();
      } else {
        // toast.error(t(res.data.message));
        handleFormErrors(res.data.data)
        this.setState({ working: false });
      }
    } catch (err) {
      console.log('err', err)
      toast.error(t('Something went wrong!'));

      this.setState({ working: false });
    }

  }

  edit = (data) => {
    this.setState({
      isEditing: true,
      appraisalcat_id: data.appraisalcat_id,
      appraisalcat_code: data.appraisalcat_code,
      appraisalcat_desc: data.appraisalcat_desc,
    });
    this.handleOpenFormModal();
  }

  delete = async (data) => {
    this.setState({
      deleteModal: {
        ...this.state.deleteModal, show: true, id: data, action: async () => {
          try {
            const res = await GET('evaluation-setup/delete-group', {
              params: {
                appraisalgrp_id: data.appraisalgrp_id
              }
            });
            if (res.data.type) {
              this.setState({
                groups: this.state.groups.filter(item => item.appraisalgrp_id !== data.appraisalgrp_id),
                deleteModal: { ...this.state.deleteModal, show: false }
              });
              toast.success(t(res.data.message));
              this.props.setEvalGroup(null);
            } else {
              this.setState({
                deleteModal: { ...this.state.deleteModal, show: false }
              });
              toast.error(t(res.data.message));
            }
          } catch (err) {
            this.setState({
              deleteModal: { ...this.state.deleteModal, show: false }
            });
            console.log(err);
            toast.error(t('Something went wrong!'));
          }
        }
      }
    })
  }

  render() {
    return (
      <div>
        <div className='grey-section-header px-0'>
          <h5><span className="me-1">{t('Categories')}</span>
            <span className="mx-1" style={{ opacity: 0.6, fontWeight: "normal" }}>|</span><span className="mx-1" style={{
              opacity: 0.8,
              fontSize: '1rem',
              fontWeight: 'normal'
            }}>{t('Please select a Category')}</span>
          </h5>
          <Button variant="success" size="sm" title={t('Add Category')}
            disabled={!this.props.evalGroup}
            onClick={this.handleOpenFormModal}>
            <FontAwesomeIcon icon={faPlus} /> {t('Category')}
          </Button>
        </div>
        <div style={{
          height: 250,
          overflowY: 'auto'
        }}>
          {this.state.cats == null &&
            <div className='text-center p-4'
              style={{
                flex: 1
              }}>
              <Spinner animation="grow" size="sm" className='me-2' />{t('Loading')}
            </div>
          }
          {this.state.cats && this.state.cats.length == 0 &&
            <div className='text-center p-4'
              style={{
                flex: 1
              }}>
              {t('No Data Found')}
            </div>
          }
          {this.state.cats &&
            <Scrollbar style={{ width: '100%', height: '100%' }} >
              {this.state.cats.map((cat, cat_i) => (
                <div key={cat_i} className='scroll-list-card'>
                  <div className='d-flex flex-row justify-content-between align-items-center p-0 m-0'>
                    <div className='me-2'>
                      <FontAwesomeIcon
                        title={t('Edit Sub-Topic')}
                        onClick={() => this.edit(cat)}
                        style={{
                          cursor: 'pointer'
                        }}
                        icon={faPenToSquare} color={brand_colors[this.props.defaultTheme.theme_id].color16} className='ms-1' />
                    </div>
                    <div className='me-2'>
                      {cat.appraisalcat_code}
                    </div>
                  </div>
                  <div className='me-2'>
                    <Button variant="light" size="sm" style={{
                      borderRadius: 15,
                      color: this.catCheck(cat.appraisalcat_id) ? brand_colors[this.props.defaultTheme.theme_id].color4 : brand_colors[this.props.defaultTheme.theme_id].color18,
                      backgroundColor: this.catCheck(cat.appraisalcat_id) ? brand_colors[this.props.defaultTheme.theme_id].color4_light : brand_colors[this.props.defaultTheme.theme_id].color7,
                      borderColor: this.catCheck(cat.appraisalcat_id) ? brand_colors[this.props.defaultTheme.theme_id].color4_light : brand_colors[this.props.defaultTheme.theme_id].color7,
                    }}
                      onClick={() => {
                        this.props.setEvalCat(this.catCheck(cat.appraisalcat_id) ? null : cat.appraisalcat_id);
                        this.props.setEvalTopic(null);
                      }}>
                      <FontAwesomeIcon icon={this.catCheck(cat.appraisalcat_id) ? faCircleCheck : faCircle} color={this.catCheck(cat.appraisalcat_id) ? brand_colors[this.props.defaultTheme.theme_id].color4 : brand_colors[this.props.defaultTheme.theme_id].color8} className='me-1' />
                      {t('Preview')}
                    </Button>
                  </div>
                </div>
              ))}
            </Scrollbar>
          }
        </div>
        <Modal
          size={'md'}
          show={this.state.showFormModal}
          onHide={this.handleCloseFormModal}>
          <Modal.Header closeButton>
            <Modal.Title>{this.state.isEditing ? t('Edit') : t('Add')} {t('Category')}</Modal.Title>
          </Modal.Header>
          <Modal.Body className='custom-styled-form'>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>{t('Category')}</Form.Label>
                  <Form.Control type="text"
                    defaultValue={this.state.appraisalcat_code}
                    onChange={(e) => this.setState({ appraisalcat_code: e.target.value })} />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>{t('Description')}</Form.Label>
                  <Form.Control type="text"
                    defaultValue={this.state.appraisalcat_desc}
                    onChange={(e) => this.setState({ appraisalcat_desc: e.target.value })} />
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleCloseFormModal}>
              {t('Close')}
            </Button>
            <Button disabled={this.state.working} variant='success' onClick={() => this.saveUpdate()}>
              {t('Save')} {this.state.working ? <Spinner as='span' animation='grow' size='sm' role='status' aria-hidden='true' /> : ''}
            </Button>
          </Modal.Footer>
        </Modal>

        <SweetAlert show={this.state.deleteModal.show} warning showCancel confirmBtnText={t('Yes, delete it!')} confirmBtnBsStyle='danger' title={t('Are you sure?')} onConfirm={this.state.deleteModal.action} onCancel={() => this.setState({ deleteModal: { ...this.state.deleteModal, show: false, id: 0, action: () => { } } })} focusCancelBtn>
          {t('Confirm Delete!')}
        </SweetAlert>

      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  defaultLanguage: state.language.defaultLanguage,
  evalGroup: state.evaluation.group,
  evalCat: state.evaluation.cat,
  defaultTheme: state.theme.defaultTheme,
});

const mapDispatchToProps = () => ({
  setEvalCat,
  setEvalTopic
});

export default connect(mapStateToProps, mapDispatchToProps())(Categories);
