import React, { Component } from 'react';
import { Container, Card, Row, Col, Button, Spinner, InputGroup, Form, Table, Badge, ButtonGroup, DropdownButton, Dropdown } from 'react-bootstrap';
import { t } from '../../helpers/translation_helper';
import NavBar from '../_partials/NavBar/_NavBar';
import Header from '../_partials/Header/_Header';
import { connect } from 'react-redux';
import { brand_colors } from '../../helpers/brand_colors_helper';
import { faCalendar, faEye, faEyeSlash, faSearch, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateRangePicker } from 'react-date-range';
import moment from 'moment';
import { GET } from '../../api';
import { numberFormat } from '../../helpers/general_helpers';
import { faCalendarAlt, faFileExcel } from '@fortawesome/free-regular-svg-icons';
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import { renderTableBody, renderTableHead } from '../../helpers/table_helper';
import Pagination from '../_partials/Pagination';
import Select from 'react-select';
import { toast } from 'react-toastify';
let cancelToken;


class StudentsMasterReport extends Component {

  state = {
    fetching: false,
    terms: [],
    term_id: '',
    classes: [],
    class_id: '',
    employeeSalary: '',
    cdt: {
      fetching: false,
      search: '',
      rows: 10,
      offset: 0,
      order: 'ch.child_name',
      dir: 'ASC',
      lastPage: 0,
      page: 0,
      totalRecords: 0,
      rowsOptions: [10, 25, 50, 'All'],
      columns: [
        {
          id: 'ch.child_name',
          column: 'child_name',
          name: 'Student Name',
          visibility: true,
          sortable: true
        },
        {
          id: 'ch.arabic_name',
          column: 'arabic_name',
          name: 'Name 2',
          visibility: true,
          sortable: true
        },
        {
          id: 'ch.first_name_en',
          column: 'first_name_en',
          name: 'First Name',
          visibility: true,
          sortable: true
        },
        {
          id: 'ch.first_name_ar',
          column: 'first_name_ar',
          name: 'First Name 2',
          visibility: true,
          sortable: true
        },
        {
          id: 'ch.mid_name_en',
          column: 'mid_name_en',
          name: 'Middle Name',
          visibility: true,
          sortable: true
        },
        {
          id: 'ch.mid_name_ar',
          column: 'mid_name_ar',
          name: 'Middle Name 2',
          visibility: true,
          sortable: true
        },
        {
          id: 'ch.last_name_en',
          column: 'last_name_en',
          name: 'Last Name',
          visibility: true,
          sortable: true
        },
        {
          id: 'ch.last_name_ar',
          column: 'last_name_ar',
          name: 'Last Name 2',
          visibility: true,
          sortable: true
        },
        {
          id: 'ch.gender',
          column: 'gender',
          name: 'Gender',
          visibility: true,
          sortable: true
        },
        {
          id: 'ch.child_code',
          column: 'child_code',
          name: 'Code',
          visibility: true,
          sortable: true
        },
        {
          id: 'ch.birth_date',
          column: 'birth_date',
          name: 'Birth Date',
          visibility: true,
          sortable: true
        },
        {
          id: 'cn.name_en',
          column: 'name_en',
          name: 'Nationality',
          visibility: true,
          sortable: true
        },
        {
          id: 'ch.birth_city',
          column: 'birth_city',
          name: 'City of birth',
          visibility: true,
          sortable: true
        },
        {
          id: 'rl.religion_name',
          column: 'religion_name',
          name: 'Religion',
          visibility: true,
          sortable: true
        },
        {
          id: 'ch.notes',
          column: 'notes',
          name: 'Notes',
          visibility: true,
          sortable: true
        },
        {
          id: 'ch.first_lang',
          column: 'first_lang',
          name: '1st Language',
          visibility: true,
          sortable: true
        },
        {
          id: 'ch.second_lang',
          column: 'second_lang',
          name: '2nd Language',
          visibility: true,
          sortable: true
        },
        {
          id: 'ch.activity_notes',
          column: 'activity_notes',
          name: 'Activities',
          visibility: true,
          sortable: true
        },
        {
          id: 'ch.child_sen',
          column: 'child_sen',
          name: 'SEN',
          visibility: true,
          sortable: true
        },
        {
          id: 'ch.child_ea',
          column: 'child_ea',
          name: 'EA',
          visibility: true,
          sortable: true
        },
        {
          id: 'ch.child_pp',
          column: 'child_pp',
          name: 'PP',
          visibility: true,
          sortable: true
        },
        {
          id: 'ch.child_lac',
          column: 'child_lac',
          name: 'LAC',
          visibility: true,
          sortable: true
        },
        {
          id: 'ch.take_pictures',
          column: 'take_pictures',
          name: 'Take Pic On App',
          visibility: true,
          sortable: true
        },
        {
          id: 'ch.pic_nursery',
          column: 'pic_nursery',
          name: 'Take Pic Inside School',
          visibility: true,
          sortable: true
        },
        {
          id: 'ch.pic_media',
          column: 'pic_media',
          name: 'Take Pic For Advertising',
          visibility: true,
          sortable: true
        },
        {
          id: 'ch.pic_social',
          column: 'pic_social',
          name: 'Take Pic On Social Media',
          visibility: true,
          sortable: true
        },
        {
          id: 'ch.child_bloodgroup',
          column: 'child_bloodgroup',
          name: 'BloodGroup',
          visibility: true,
          sortable: true
        },
        {
          id: 'ch.prev_school',
          column: 'prev_school',
          name: 'Prev. School',
          visibility: true,
          sortable: true
        },
        {
          id: 'ch.moe_id',
          column: 'moe_id',
          name: 'MOE ID',
          visibility: true,
          sortable: true
        },
        {
          id: 'ch.auth_id',
          column: 'auth_id',
          name: 'AUTH ID',
          visibility: true,
          sortable: true
        },
        {
          id: 't.transport_name',
          column: 'transport_name',
          name: 'Transport',
          visibility: true,
          sortable: true
        },
        {
          id: 'st.stdstat_name',
          column: 'stdstat_name',
          name: 'Status',
          visibility: true,
          sortable: true
        },
        {
          id: 'staff_child',
          column: 'staff_child',
          name: 'Staff Child',
          visibility: true,
          sortable: true
        },
        {
          id: 'reg_date',
          column: 'reg_date',
          name: 'Registration Date',
          visibility: true,
          sortable: true
        },
        {
          id: 'date_from',
          column: 'date_from',
          name: 'Registration From',
          visibility: true,
          sortable: true
        },
        {
          id: 'date_to',
          column: 'date_to',
          name: 'Registration To',
          visibility: true,
          sortable: true
        },
        {
          id: 'classmain_name',
          column: 'classmain_name',
          name: 'Grade Level',
          visibility: true,
          sortable: true
        },
        {
          id: 'class_theme',
          column: 'class_theme',
          name: 'Class',
          visibility: true,
          sortable: true
        },
        {
          id: 'session_name',
          column: 'session_name',
          name: 'Session Name',
          visibility: true,
          sortable: true
        },
        {
          id: 'session_sun',
          column: 'session_sun',
          name: 'Su',
          visibility: true,
          sortable: true
        },
        {
          id: 'session_mon',
          column: 'session_mon',
          name: 'Mo',
          visibility: true,
          sortable: true
        },
        {
          id: 'session_tue',
          column: 'session_tue',
          name: 'Tu',
          visibility: true,
          sortable: true
        },
        {
          id: 'session_wed',
          column: 'session_wed',
          name: 'We',
          visibility: true,
          sortable: true
        },
        {
          id: 'session_thu',
          column: 'session_thu',
          name: 'Th',
          visibility: true,
          sortable: true
        },
        {
          id: 'session_fri',
          column: 'session_fri',
          name: 'Fr',
          visibility: true,
          sortable: true
        },
        {
          id: 'account_num',
          column: 'account_num',
          name: 'Account Num',
          visibility: true,
          sortable: true
        },
        {
          id: 'qb_account',
          column: 'qb_account',
          name: '3rd Party',
          visibility: true,
          sortable: true
        },
        {
          id: 'id_number',
          column: 'id_number',
          name: 'ID Num',
          visibility: true,
          sortable: true
        },
        {
          id: 'id_validity',
          column: 'id_validity',
          name: 'ID Validity',
          visibility: true,
          sortable: true
        },
        {
          id: 'passport_number',
          column: 'passport_number',
          name: 'Passport Num',
          visibility: true,
          sortable: true
        },
        {
          id: 'passport_validity',
          column: 'passport_validity',
          name: 'Passport Validity',
          visibility: true,
          sortable: true
        },
        {
          id: 'residency_number',
          column: 'residency_number',
          name: 'Residency Num',
          visibility: true,
          sortable: true
        },
        {
          id: 'residency_validity',
          column: 'residency_validity',
          name: 'Residency Validity',
          visibility: true,
          sortable: true
        },
        // {
        //   id: 'child_passcopy',
        //   column: 'child_passcopy',
        //   name: 'Passport Copy',
        //   visibility: true,
        //   sortable: true
        // },
        // {
        //   id: 'visa_copy',
        //   column: 'visa_copy',
        //   name: 'Visa Copy',
        //   visibility: true,
        //   sortable: true
        // },
        // {
        //   id: 'child_eid',
        //   column: 'child_eid',
        //   name: 'ID Copy',
        //   visibility: true,
        //   sortable: true
        // },
        // {
        //   id: 'birth_certificate',
        //   column: 'birth_certificate',
        //   name: 'Birth Cert',
        //   visibility: true,
        //   sortable: true
        // },
        // {
        //   id: 'med_form',
        //   column: 'med_form',
        //   name: 'Medical Form',
        //   visibility: true,
        //   sortable: true
        // },
        // {
        //   id: 'indemnity_form',
        //   column: 'indemnity_form',
        //   name: 'Indemnity Copy',
        //   visibility: true,
        //   sortable: true
        // },
        // {
        //   id: 'child_vacc',
        //   column: 'child_vacc',
        //   name: 'Vaccination Copy',
        //   visibility: true,
        //   sortable: true
        // },
        // {
        //   id: 'Photo',
        //   column: 'Photo',
        //   name: 'Student Photo',
        //   visibility: true,
        //   sortable: true
        // },
        {
          id: 'phone1',
          column: 'phone1',
          name: 'Phone 1',
          visibility: true,
          sortable: true
        },
        {
          id: 'phone2',
          column: 'phone2',
          name: 'Phone 2',
          visibility: true,
          sortable: true
        },
        {
          id: 'email1',
          column: 'email1',
          name: 'Email 1',
          visibility: true,
          sortable: true
        },
        {
          id: 'email2',
          column: 'email2',
          name: 'Email 2',
          visibility: true,
          sortable: true
        },
        {
          id: 'spouse_phone',
          column: 'spouse_phone',
          name: 'Spouse Phone',
          visibility: true,
          sortable: true
        },
        {
          id: 'spouse_email',
          column: 'spouse_email',
          name: 'Spouse Email',
          visibility: true,
          sortable: true
        },
        {
          id: 'emergency_contact_name',
          column: 'emergency_contact_name',
          name: 'Emergency Contact',
          visibility: true,
          sortable: true
        },
        {
          id: 'emergency_phone1',
          column: 'emergency_phone1',
          name: 'Emergency Phone 1',
          visibility: true,
          sortable: true
        },
        {
          id: 'emergency_phone2',
          column: 'emergency_phone2',
          name: 'Emergency Phone 2',
          visibility: true,
          sortable: true
        },
        {
          id: 'child_medical_name',
          column: 'child_medical_name',
          name: 'Medical Emergency Name',
          visibility: true,
          sortable: true
        },
        {
          id: 'child_medical_doctor',
          column: 'child_medical_doctor',
          name: 'Medical Emergency Doctor',
          visibility: true,
          sortable: true
        },
        {
          id: 'child_medical_clinic',
          column: 'child_medical_clinic',
          name: 'Medical Emergency Clinic',
          visibility: true,
          sortable: true
        },
        {
          id: 'child_medical_phone',
          column: 'child_medical_phone',
          name: 'Medical Emergency Phone',
          visibility: true,
          sortable: true
        },
        {
          id: 'parent_role',
          column: 'parent_role',
          name: 'Role',
          visibility: true,
          sortable: true
        },
        {
          id: 'parent_name',
          column: 'parent_name',
          name: 'Parent Name',
          visibility: true,
          sortable: true
        },
        {
          id: 'parent_name_2',
          column: 'parent_name_2',
          name: 'Parent Name 2',
          visibility: true,
          sortable: true
        },
        {
          id: 'family_name',
          column: 'family_name',
          name: 'Family Name',
          visibility: true,
          sortable: true
        },
        {
          id: 'family_name_2',
          column: 'family_name_2',
          name: 'Family Name 2',
          visibility: true,
          sortable: true
        },
        {
          id: 'parent_country_name',
          column: 'parent_country_name',
          name: 'Nationality',
          visibility: true,
          sortable: true
        },
        {
          id: 'father_id_number',
          column: 'father_id_number',
          name: 'ID Num',
          visibility: true,
          sortable: true
        },
        {
          id: 'employer_father',
          column: 'employer_father',
          name: 'Father Employer',
          visibility: true,
          sortable: true
        },
        {
          id: 'position_id_father',
          column: 'position_id_father',
          name: 'Father Position',
          visibility: true,
          sortable: true
        },
        {
          id: 'parent_education_level',
          column: 'parent_education_level',
          name: 'Father Education',
          visibility: true,
          sortable: true
        },
        {
          id: 'parent_relationship_status',
          column: 'parent_relationship_status',
          name: 'Relationship Status',
          visibility: true,
          sortable: true
        },
        {
          id: 'grandfather',
          column: 'grandfather',
          name: 'Grand Father Name',
          visibility: true,
          sortable: true
        },
        {
          id: 'grandfather_2',
          column: 'grandfather_2',
          name: 'Grand Father Name 2',
          visibility: true,
          sortable: true
        },
        {
          id: 'residency_validity',
          column: 'residency_validity',
          name: 'Residency Validity',
          visibility: true,
          sortable: true
        },
        {
          id: 'passport_number',
          column: 'passport_number',
          name: 'Passport Number',
          visibility: true,
          sortable: true
        },
        {
          id: 'passport_validity',
          column: 'passport_validity',
          name: 'Passport Validity',
          visibility: true,
          sortable: true
        },
        {
          id: 'visa_number',
          column: 'visa_number',
          name: 'Visa Number',
          visibility: true,
          sortable: true
        },
        {
          id: 'visa_date',
          column: 'visa_date',
          name: 'Visa Date',
          visibility: true,
          sortable: true
        },
        {
          id: 'visa_expiry_date',
          column: 'visa_expiry_date',
          name: 'Visa Expiry Date',
          visibility: true,
          sortable: true
        },
        {
          id: 'residency_number',
          column: 'residency_number',
          name: 'Residency Number',
          visibility: true,
          sortable: true
        },
        {
          id: 'address',
          column: 'address',
          name: 'Address',
          visibility: true,
          sortable: true
        },
        {
          id: 'general_area',
          column: 'general_area',
          name: 'Area',
          visibility: true,
          sortable: true
        },
        {
          id: 'parent_type',
          column: 'parent_type',
          name: 'Type',
          visibility: true,
          sortable: true
        },
        {
          id: 'spouse_role',
          column: 'spouse_role',
          name: 'Spouse Role',
          visibility: true,
          sortable: true
        },
        {
          id: 'spouse_name',
          column: 'spouse_name',
          name: 'Spouse Name',
          visibility: true,
          sortable: true
        },
        {
          id: 'spouse_name_2',
          column: 'spouse_name_2',
          name: 'Spouse Name 2',
          visibility: true,
          sortable: true
        },
        {
          id: 'spouse_country',
          column: 'spouse_country',
          name: 'Spouse Nationality',
          visibility: true,
          sortable: true
        },
        {
          id: 'spouse_id_number',
          column: 'spouse_id_number',
          name: 'ID Num',
          visibility: true,
          sortable: true
        },
        {
          id: 'employer_mother',
          column: 'employer_mother',
          name: 'Employer',
          visibility: true,
          sortable: true
        },
        {
          id: 'position_id_mother',
          column: 'position_id_mother',
          name: 'Position',
          visibility: true,
          sortable: true
        },
        {
          id: 'spouse_education_level',
          column: 'spouse_education_level',
          name: 'Education',
          visibility: true,
          sortable: true
        },
      ],
      data: [],
    },
  }

  componentDidMount() {
    this.fetch({
      search: this.state.cdt.search,
      limit: this.state.cdt.rows,
      order: this.state.cdt.order,
      dir: this.state.cdt.dir,
      page: this.state.cdt.page
    });
  }


  fetch = async (params) => {

    this.setState({
      showDateRange: false,
      cdt: {
        ...this.state.cdt,
        fetching: true,
      }
    });

    if (this.fetchReq) {
      this.fetchReq.abort();
    }
    this.fetchReq = new AbortController();

    try {
      const res = await GET('students-master-report/get', {
        signal: this.fetchReq.signal,
        params: {
          search: params.search,
          order: params.order,
          dir: params.dir,
          limit: params.limit,
          offset: parseInt(params.page) * parseInt(params.limit),
        },
      });


      if (res.status === 200) {

        this.setState({
          cdt: {
            ...this.state.cdt,
            fetching: false,
            search: params.search,
            rows: parseInt(params.limit),
            offset: parseInt(params.page) * parseInt(params.limit),
            order: params.order,
            dir: params.dir,
            page: params.page,
            totalRecords: parseInt(res.data.total_count_filtered),
            lastPage: Math.ceil(parseInt(res.data.total_count_filtered) / parseInt(params.limit)),
            data: res.data.data,
          },
        });
      }

    } catch (err) {
      console.log('err', err)
      this.setState({
        cdt: {
          ...this.state.cdt,
          fetching: false,
        }
      });

    }
  }

  exportExcel = async () => {

    const excelData = this.state.cdt.data.map((item) => {
      const row = {};
      this.state.cdt.columns
        .filter((column) => column.visibility)
        .map((column) => {

          if (
            column.column == 'child_sen' ||
            column.column == 'child_ea' ||
            column.column == 'child_pp' ||
            column.column == 'child_lac' ||
            column.column == 'take_pictures' ||
            column.column == 'pic_nursery' ||
            column.column == 'pic_media' ||
            column.column == 'pic_social' ||
            column.column == 'staff_child' ||
            column.column == 'session_sun' ||
            column.column == 'session_mon' ||
            column.column == 'session_tue' ||
            column.column == 'session_wed' ||
            column.column == 'session_thu' ||
            column.column == 'session_fri' ||
            column.column == 'session_sat' ||
            column.column == 'visa_copy' ||
            column.column == 'child_eid' ||
            column.column == 'birth_certificate' ||
            column.column == 'med_form' ||
            column.column == 'indemnity_form' ||
            column.column == 'child_vacc' ||
            column.column == 'Photo'
          ) {
            row[column.name] = item[column.column] == 0 ? 'NO' : 'YES';
          } else {
            row[column.name] = item[column.column];
          }


        });
      return row;
    });

    const fileName = 'Students Master Report ' + moment().format('DD-MM-YYYY');
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const ws = XLSX.utils.json_to_sheet(excelData);

    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });

    FileSaver.saveAs(data, fileName + fileExtension);
  }

  showHideColumn = (column_index) => {
    const columns = this.state.cdt.columns;
    columns[column_index].visibility = !columns[column_index].visibility;
    this.setState({ ctd: { ...this.state.ctd, columns } })
  }

  renderCell = (column_data, column_id, column_index, dataObj) => {
    const { authData } = this.props;
    const { center_id } = authData.centerData;

    let tdData;

    if (
      column_id == 'child_sen' ||
      column_id == 'child_ea' ||
      column_id == 'child_pp' ||
      column_id == 'child_lac' ||
      column_id == 'take_pictures' ||
      column_id == 'pic_nursery' ||
      column_id == 'pic_media' ||
      column_id == 'pic_social' ||
      column_id == 'staff_child' ||
      column_id == 'session_sun' ||
      column_id == 'session_mon' ||
      column_id == 'session_tue' ||
      column_id == 'session_wed' ||
      column_id == 'session_thu' ||
      column_id == 'session_fri' ||
      column_id == 'session_sat' ||
      column_id == 'visa_copy' ||
      column_id == 'child_eid' ||
      column_id == 'birth_certificate' ||
      column_id == 'med_form' ||
      column_id == 'indemnity_form' ||
      column_id == 'child_vacc' ||
      column_id == 'Photo'
    ) {
      tdData = column_data == 0 ? 'NO' : 'YES';
    } else {
      tdData = column_data;
    }



    return <td key={column_index}>{tdData}</td>;
  }

  render() {

    return (
      <Container fluid>
        <div id='iedu-layout'>
          <NavBar />
          <div id='page-content'>
            <Header lite={true} heading={t('Students Master Report')} backBtn={true} />
            <Card
              className='border-0'
              style={{ borderRadius: '20px' }}>
              <Card.Header style={{
                backgroundColor: '#fff',
                borderTopLeftRadius: '20px',
                borderTopRightRadius: '20px',
              }}>
                <Row>
                  <Col md={6} className='d-flex align-items-center'>
                    <ButtonGroup size='sm' className='me-3 float-left'>
                      {/* <Button><FontAwesomeIcon icon={faFilePdf} /> {t('PDF')}</Button> */}
                      <Button onClick={() => this.exportExcel()}>
                        <FontAwesomeIcon icon={faFileExcel} /> {t('Excel')}
                      </Button>
                      {/* <Button><FontAwesomeIcon icon={faPrint} /> {t('Print')}</Button> */}
                      <DropdownButton
                        autoClose={'outside'}
                        size='sm'
                        as={ButtonGroup}
                        title={t('Column Visibility')}>
                        {this.state.cdt.columns.map((column, column_index) => (
                          <Dropdown.Item key={column_index} className={column.visibility ? 'column-name-item' : 'column-name-item active'} onClick={() => this.showHideColumn(column_index)}>
                            <FontAwesomeIcon icon={column.visibility ? faEye : faEyeSlash} /> {column.name}
                          </Dropdown.Item>
                        ))}
                      </DropdownButton>
                    </ButtonGroup>
                    <div style={{ display: 'inline-block' }}>
                      {t('Show')}
                      <Form.Select
                        value={this.state.cdt.rows}
                        size="sm"
                        onChange={(e) => this.fetch({
                          search: this.state.cdt.search,
                          limit: e.target.value,
                          order: this.state.cdt.order,
                          dir: this.state.cdt.dir,
                          page: this.state.cdt.page
                        })}
                        style={{ width: 70, marginInline: 5, display: 'inline-block' }}>
                        {this.state.cdt.rowsOptions.map((row, row_index) => (<option key={row_index} >{row}</option>))}
                      </Form.Select>
                      {t('Records')}
                    </div>
                  </Col>
                  <Col md={6} className='d-flex justify-content-end'>
                    <div className='cdt-search'>
                      <input type='text'
                        ref={this.searchFieldRef}
                        defaultValue={this.state.cdt.search}
                        onChange={(e) => this.fetch({
                          search: e.target.value,
                          limit: this.state.cdt.rows,
                          order: this.state.cdt.order,
                          dir: this.state.cdt.dir,
                          page: 0
                        })}
                        placeholder={t('Search...')} />
                      <button
                        onClick={() => {
                          this.fetch({
                            search: '',
                            limit: this.state.cdt.rows,
                            order: this.state.cdt.order,
                            dir: this.state.cdt.dir,
                            page: 0
                          });
                          this.searchFieldRef.current.value = '';
                        }}>
                        <FontAwesomeIcon
                          icon={this.state.cdt.search == '' ? faSearch : faXmark}
                          color={brand_colors[this.props.defaultTheme.theme_id].color18}
                        />
                      </button>
                    </div>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body style={{ paddingTop: 8 }}>
                <Row>
                  {this.state.cdt.fetching &&
                    <Col md={12} className='py-5 d-flex flex-column align-items-center'>
                      <Spinner as='span' animation='grow' size='sm' /> {t('Loading Data')}
                    </Col>
                  }
                  {!this.state.cdt.fetching && this.state.cdt.data.length === 0 &&
                    <Col md={12} className='py-5 d-flex flex-column align-items-center'>
                      {t('No Data Found')}
                    </Col>
                  }
                  {!this.state.cdt.fetching && this.state.cdt.data.length !== 0 &&
                    <Col md={12} className='pt-2'>
                      <div id="datatable-list" className='mb-3' style={{ width: 'initial' }}>
                        <Table responsive className='students-master-report'>
                          {renderTableHead(this)}
                          {renderTableBody(this)}
                        </Table>
                      </div>
                    </Col>
                  }
                </Row>
                <Pagination
                  pageCount={this.state.cdt.lastPage || 1}
                  forcePage={this.state.cdt.page}
                  callbackParams={{
                    search: this.state.cdt.search,
                    limit: this.state.cdt.rows,
                    order: this.state.cdt.order,
                    dir: this.state.cdt.dir,
                  }}
                  callback={this.fetch}
                />
              </Card.Body>
            </Card>
          </div>
        </div>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  selectedClass: state.selectedClass.data,
  defaultLanguage: state.language.defaultLanguage,
  defaultTheme: state.theme.defaultTheme,
});

export default connect(mapStateToProps, null)(StudentsMasterReport);
