import React, { Component } from 'react';
import { Container, Card, Row, Col, ButtonGroup, Button, DropdownButton, Dropdown, Table, Form, Spinner, Modal, Tabs, Tab, Badge } from 'react-bootstrap';
import { t } from '../../helpers/translation_helper';
import NavBar from '../_partials/NavBar/_NavBar';
import Header from '../_partials/Header/_Header';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faCheckCircle, faSearch, faUser, faXmark, faLayerGroup, faHandsHoldingChild, faPeopleGroup, faListCheck, faPrint, faBus, faInfoCircle, faUserShield, faEllipsis, faAngleRight, faAngleLeft, faPerson, faPersonDress, faMailForward } from '@fortawesome/free-solid-svg-icons';
import { brand_colors } from '../../helpers/brand_colors_helper';
import { Tooltip as Tooltiper } from 'react-tooltip';
import { NavLink } from 'react-router-dom';
import { faEye, faEyeSlash, faFileExcel, faFilePdf } from '@fortawesome/free-regular-svg-icons';
import { isTeacher } from '../../helpers/general_helpers';
import { getStudents } from '../../redux/slices/studentListSlice';
import age from '../../assets/images/student-list/age.png';
import timing from '../../assets/images/student-list/timings.png';
import pastoralCare from '../../assets/images/student-list/pastoral-care.png';
import onlineGreen from '../../assets/images/student-list/online-green.png';
import onlineRed from '../../assets/images/student-list/online-red.png';
import photoCameraGreen from '../../assets/images/student-list/photo-camera-green.png';
import photoCameraRed from '../../assets/images/student-list/photo-camera-red.png';
import moment from 'moment';
import ReactPaginate from 'react-paginate';
import Pagination from '../_partials/Pagination';
import { GET } from '../../api';
import { toast } from 'react-toastify';
const portalURL = process.env.REACT_APP_PORTAL_URL;

class StudentInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      student: null,
      searchExpanded: false,
      cdt: {
        order: { column: 'child_id', type: 'DESC' },
        rows: [10, 25, 50, 'All'],
      },
      showModal: false,
      tabs: {
        activeTab: 'profile',
        tabsData: [
          {
            id: 'profile',
            name: 'Profile',
          },
          {
            id: 'medical',
            name: 'Medical',
          },
          {
            id: 'contact',
            name: 'Contact',
          },
        ]
      },
      selectedSubject: this.props.selectedClass.academic_classmain_subject[0],
    }
    this.searchFieldRef = React.createRef();
  }

  componentDidMount() {
    this.getStudents({
      rows: this.props.studentListRows,
      page: 0,
      search: '',
      status: this.props.studentListStatus
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if ((prevProps.authData.centerData.center_id !== this.props.authData.centerData.center_id) || (this.props.selectedClass && prevProps.selectedClass.class.class_id !== this.props.selectedClass.class.class_id)) {
      this.getStudents({
        rows: this.props.studentListRows,
        page: 0,
        search: '',
        status: this.props.studentListStatus
      });
      this.setState({ searchExpanded: false });
      this.searchFieldRef.current.value = '';
    }
  }

  getStudents = (params) => {
    this.props.getStudents({
      child_status: params.status,
      search: params.search,
      limit: params.rows,
      page: params.page,
    });
  }

  getStudentAge = (student) => {
    let birthDate = moment(student.birth_date, "YYYY-MM-DD")
    let today = moment(new Date()),
      intervals = ['years', 'months', 'weeks', 'days'],
      out = [];
    intervals.forEach((interval) => {
      var diff = today.diff(birthDate, interval);
      birthDate.add(diff, interval);
      out.push(diff + interval.slice(0, 1));
    });
    return out.join(', ');
  }

  handleOpenModal = (student) => {
    this.setState({ showModal: true, student });
  }

  handleCloseModal = () => {
    this.setState({
      showModal: false,
      student: null
    });
  }

  mailCredentials = async (studentData) => {

    const { authData } = this.props;

    const res = await GET('children/send-parent-credentials', {
      params: {
        parent_id: studentData.parent_id,
        center_email: authData.centerData.email1,
        center_name: authData.centerData.center_name,
      },
    });

    if (res.data.type) {
      toast.warning(t('Credentials sent successfully!'));
    } else {
      toast.error(t(res.data.message));
    }
  }

  render() {
    return (
      <>
        <Card
          className='border-0'
          style={{ borderRadius: '20px' }}>
          <Card.Body>
            <Row>
              <Col md={12} className='d-flex flex-column'>
                <div className='d-flex flex-row align-items-center mb-2'>
                  <strong>{t("Information")}</strong>
                  <div className='expandable-search ms-2' style={{ width: this.state.searchExpanded ? 200 : 35 }}>
                    <input
                      ref={this.searchFieldRef}
                      defaultValue={this.props.studentListSearch}
                      onChange={(e) => this.getStudents({
                        rows: this.props.studentListRows,
                        page: 0,
                        search: e.target.value,
                        status: this.props.studentListStatus
                      })}
                      type='text'
                      style={{ width: this.state.searchExpanded ? 165 : 0, paddingLeft: this.state.searchExpanded ? 15 : 0, opacity: this.state.searchExpanded ? 1 : 0 }}
                      placeholder={t('Search Student...')} />
                    <button
                      data-tooltip-id="tooltip"
                      data-tooltip-content={t('Search Student')}
                      data-tooltip-place="top"
                      onClick={() => {
                        this.setState({ searchExpanded: !this.state.searchExpanded }, () => { });
                        this.props.studentListSearch != '' && this.state.searchExpanded && this.getStudents({
                          rows: this.props.studentListRows,
                          page: 0,
                          search: '',
                          status: this.props.studentListStatus
                        });
                        this.searchFieldRef.current.value = '';
                        !this.state.searchExpanded && this.searchFieldRef.current.focus();
                      }}>
                      <FontAwesomeIcon
                        icon={this.state.searchExpanded ? faXmark : faSearch}
                        color={brand_colors[this.props.defaultTheme.theme_id].color18}
                      />
                    </button>
                  </div>
                </div>
                <div className='d-flex flex-row align-items-center'>
                  <div>
                    {t('Show')}
                    <Form.Select
                      value={this.props.studentListRows}
                      size="sm"
                      onChange={(e) => this.getStudents({
                        rows: e.target.value,
                        page: 0,
                        search: this.props.studentListSearch,
                        status: this.props.studentListStatus
                      })}
                      style={{ width: 70, marginInline: 5, display: 'inline-block' }}>
                      {this.state.cdt.rows.map((row, row_index) => (<option key={row_index} >{row}</option>))}
                    </Form.Select>
                    {t('Records')}
                  </div>
                </div>
              </Col>
              {this.props.studentListFetching &&
                <Col md={12} className='py-5 d-flex flex-column align-items-center'>
                  <Spinner as='span' animation='grow' size='sm' /> {t('Loading Students')}
                </Col>
              }
              {!this.props.studentListFetching &&
                <Col md={12} className='pt-1 student-attendance-list'>
                  {this.props.studentListData.map((student, student_index) => (
                    <Row key={student_index}
                      className='student-attendance-list-item'>
                      <Col md={3} className='d-flex align-items-center'>
                        <div style={{
                          backgroundImage: `url(${(student.picture) ? portalURL + student.center_id + '/children/' + student.picture : 'https://via.placeholder.com/150x150?text=' + student.child_name[0].toUpperCase()})`,
                          width: 60,
                          height: 60,
                          backgroundSize: 'cover',
                          backgroundPosition: 'center center',
                          borderRadius: 30,
                          marginRight: 15
                        }}></div>
                        <Button
                          style={{ width: 31, paddingLeft: 0, paddingRight: 0 }}
                          onClick={() => this.mailCredentials(student)}
                          variant="warning" size='sm' className='me-3' title={t('Mail Credentials')}>
                          <FontAwesomeIcon icon={faMailForward} />
                        </Button>
                        {!student.modified ?
                          <div style={{ width: 15, height: 15, borderRadius: 7, backgroundColor: 'red', marginRight: 7 }}></div>
                          :
                          <div style={{ width: 15, height: 15, borderRadius: 7, backgroundColor: 'green', marginRight: 7 }}></div>
                        }
                        {student.child_name}
                      </Col>
                      <Col md={8}>
                        <div className='d-flex align-items-center' style={{ padding: 10, flex: 1 }}>
                          <div className='d-flex me-4' style={{ fontSize: 14 }}>
                            <img src={age} alt='Age' width={43} height='100%' style={{ marginRight: 10 }} />
                            <div>
                              <strong>{t('Age:')}</strong><br />
                              {this.getStudentAge(student)}
                            </div>
                          </div>
                          {/* <div className='d-flex me-4' style={{ fontSize: 14 }}>
                            <img src={timing} alt='Timing' width={43} height='100%' style={{ marginRight: 10 }} />
                            <div>
                              <strong>{t('Timings:')}</strong><br />
                              {student.session_name}
                            </div>
                          </div> */}
                          <div className='d-flex me-4' style={{ fontSize: 14 }}>
                            <img src={pastoralCare} alt='Pastoral Care' width={43} height='100%' style={{ marginRight: 10 }} />
                            <div>
                              <strong>{t('Parent:')}</strong><br />
                              {student.parent_name}
                            </div>
                          </div>
                          <div>
                            {student.gender == 'M'
                              ? <FontAwesomeIcon
                                className='me-3' alt='' width={30}
                                data-tooltip-id="tooltip"
                                data-tooltip-content={t('Gender: Male')}
                                data-tooltip-place="top"
                                icon={faPerson} color={brand_colors[this.props.defaultTheme.theme_id].color1} style={{ fontSize: 27, top: 6, position: 'relative' }} />
                              : <FontAwesomeIcon
                                className='me-3' alt='' width={30}
                                data-tooltip-id="tooltip"
                                data-tooltip-content={t('Gender: Female')}
                                data-tooltip-place="top"
                                icon={faPersonDress} color={brand_colors[this.props.defaultTheme.theme_id].color2} style={{ fontSize: 27, top: 6, position: 'relative' }} />
                            }
                            {student.regToday == 1 ?
                              <img src={onlineGreen} alt='' width={30} className='me-3'
                                data-tooltip-id="tooltip"
                                data-tooltip-content={t('Registered Today')}
                                data-tooltip-place="top" />
                              :
                              <img src={onlineRed} alt='' width={30} className='me-3'
                                data-tooltip-id="tooltip"
                                data-tooltip-content={t('Not Registered Today')}
                                data-tooltip-place="top" />
                            }
                            {student.take_pictures == 1 ?
                              <img src={photoCameraGreen} alt='' width={30} className='me-3'
                                data-tooltip-id="tooltip"
                                data-tooltip-content={t('Can Take Pictures')}
                                data-tooltip-place="top" />
                              :
                              <img src={photoCameraRed}
                                className='me-3' alt='' width={30}
                                data-tooltip-id="tooltip"
                                data-tooltip-content={t('Cannot Take Pictures')}
                                data-tooltip-place="top" />
                            }
                            {student.transport_name == 'Bus' &&
                              <FontAwesomeIcon
                                className='me-3' alt='' width={30}
                                data-tooltip-id="tooltip"
                                data-tooltip-content={t('Transport Type: Bus')}
                                data-tooltip-place="top"
                                icon={faBus} color={brand_colors[this.props.defaultTheme.theme_id].color12} style={{ fontSize: 27, top: 6, position: 'relative' }} />
                            }
                            {student.modified &&
                              <Badge bg="success">{t('Login: ') + moment(student.modified).format('DD-MM-YYYY')}</Badge>
                            }
                          </div>
                        </div>
                      </Col>
                      <Col md={1} className='d-flex justify-content-end'>
                        <button className='btn' onClick={() => this.handleOpenModal(student)}>
                          <FontAwesomeIcon icon={faInfoCircle} color={brand_colors[this.props.defaultTheme.theme_id].color4} style={{ padding: '0 5px', fontSize: 30 }} />
                        </button>
                      </Col>
                    </Row>
                  ))}
                </Col>
              }
            </Row>
            <Pagination
              pageCount={this.props.studentListLastPage}
              forcePage={this.props.studentListPage}
              callbackParams={{
                rows: this.props.studentListRows,
                status: this.props.studentListStatus,
                search: this.props.studentListSearch
              }}
              callback={this.getStudents}
            />
          </Card.Body>
        </Card>
        {this.state.student &&
          <Modal show={this.state.showModal} onHide={this.handleCloseModal} size="lg">
            <Modal.Header closeButton>
              <Modal.Title>{t('Information')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Card
                  className='border-0'
                  style={{ borderRadius: '20px' }}>
                  <Card.Body>
                    <div style={{ overflow: 'hidden', height: 150, width: 150, borderRadius: 90, position: 'relative', marginLeft: 'auto', marginRight: 'auto', marginBottom: 30 }}>
                      <img src={(this.state.student.picture) ? portalURL + this.state.student.center_id + '/children/' + this.state.student.picture : 'https://via.placeholder.com/300x150?text=Student' + this.state.student.child_name[0].toUpperCase()} alt={t('Preview')} style={{ objectFit: 'cover', height: '100%', width: '100%' }} />

                      {/* SEN */}
                      {this.state.student.child_sen == 1 && <div style={{ position: 'absolute', bottom: 0, right: 0, backgroundColor: brand_colors[this.props.defaultTheme.theme_id].color11, borderTopLeftRadius: 10, padding: 2 }}>
                        <FontAwesomeIcon icon={faUserShield} color={brand_colors[this.props.defaultTheme.theme_id].color8} />
                      </div>}

                    </div>
                    <Tabs
                      defaultActiveKey={this.state.tabs.activeTab}
                      className="mb-3"
                      justify
                    >
                      {this.state.tabs.tabsData.map((tab, tab_i) =>
                        <Tab key={tab_i} eventKey={tab.id} title={t(tab.name)}>
                          {tab.id === 'profile' &&
                            <Row>
                              <Col>
                                <Table className='mb-5'>
                                  <tbody>
                                    <tr>
                                      <th width='200'>
                                        {t('Name')}
                                      </th>
                                      <td>{this.state.student.child_name}</td>
                                    </tr>
                                    <tr>
                                      <th>
                                        {t('Date of Birth')}
                                      </th>
                                      <td>{this.state.student.birth_date}</td>
                                    </tr>
                                    <tr>
                                      <th>{t('Class')}</th>
                                      <td>{this.state.student.class_theme}</td>
                                    </tr>
                                    <tr>
                                      <th>{t('Email')}</th>
                                      <td>{this.state.student.email1}</td>
                                    </tr>
                                    <tr>
                                      <th>{t('Phone')}</th>
                                      <td>{this.state.student.phone1}</td>
                                    </tr>
                                  </tbody>
                                </Table>
                              </Col>
                              <Col>
                                <Table>
                                  <tbody>
                                    <tr>
                                      <th width='200'>
                                        {t(
                                          'Emergency Contact'
                                        )}
                                      </th>
                                      <td>
                                        {
                                          this.state.student.emergency_contact_name
                                        }
                                      </td>
                                    </tr>
                                    <tr>
                                      <th>
                                        {t(
                                          'Emergency Contact No.'
                                        )}
                                      </th>
                                      <td>
                                        {this.state.student.emergency_phone1}
                                      </td>
                                    </tr>
                                  </tbody>
                                </Table>
                              </Col>
                            </Row>
                          }
                          {tab.id === 'medical' &&
                            <Row>
                              <Col>
                                <Table className='mb-5'>
                                  <tbody>
                                    <tr>
                                      <th width='200'>
                                        {t('Medical Note')}
                                      </th>
                                      <td>{this.state.student.medical_note}</td>
                                    </tr>
                                  </tbody>
                                </Table>
                              </Col>
                            </Row>
                          }
                          {tab.id === 'contact' &&
                            <Row>
                              <Col>
                                <Table className='mb-5'>
                                  <tbody>
                                    <tr>
                                      <th width='200'>
                                        {t('Parent Name')}
                                      </th>
                                      <td>{this.state.student.parent_name}</td>
                                    </tr>
                                    <tr>
                                      <th width='200'>
                                        {t('Phone 1')}
                                      </th>
                                      <td>{this.state.student.phone1}</td>
                                    </tr>
                                    <tr>
                                      <th width='200'>
                                        {t('Email 1')}
                                      </th>
                                      <td>{this.state.student.email1}</td>
                                    </tr>
                                    <tr>
                                      <th width='200'>
                                        {t('Phone 2')}
                                      </th>
                                      <td>{this.state.student.phone2}</td>
                                    </tr>
                                    <tr>
                                      <th width='200'>
                                        {t('Email 2')}
                                      </th>
                                      <td>{this.state.student.email2}</td>
                                    </tr>
                                  </tbody>
                                </Table>
                              </Col>
                              <Col>
                                <Table>
                                  <tbody>
                                    <tr>
                                      <th width='200'>
                                        {t('Spouse Name')}
                                      </th>
                                      <td>{this.state.student.spouse_name}</td>
                                    </tr>
                                    <tr>
                                      <th width='200'>
                                        {t('Spouse Phone')}
                                      </th>
                                      <td>{this.state.student.spouse_phone}</td>
                                    </tr>
                                  </tbody>
                                </Table>
                              </Col>
                            </Row>
                          }
                        </Tab>
                      )}
                    </Tabs>
                  </Card.Body>
                </Card>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.handleCloseModal} disabled={this.state.working}>
                {t('Close')}
              </Button>
            </Modal.Footer>
          </Modal>
        }
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  selectedClass: state.selectedClass.data,
  defaultLanguage: state.language.defaultLanguage,
  defaultTheme: state.theme.defaultTheme,
  studentListFetching: state.studentList.fetching,
  studentListData: state.studentList.data,
  studentListSearch: state.studentList.query,
  studentListRows: state.studentList.rows,
  studentListOffset: state.studentList.offset,
  studentListStatus: state.studentList.status,
  studentListOrder: state.studentList.order,
  studentListDir: state.studentList.dir,
  studentListLastPage: state.studentList.last_page,
  studentListPage: state.studentList.page,
  studentListTotalRecords: state.studentList.total_records,
});

const mapDispatchToProps = () => ({
  getStudents
});

export default connect(mapStateToProps, mapDispatchToProps())(StudentInformation);
