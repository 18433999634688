import React, { Component } from 'react';
import { Container, Card, Table, Row, Col, Spinner, Image, Badge, Button, Form, Offcanvas, Accordion, Modal } from 'react-bootstrap';
import { t } from '../../helpers/translation_helper';
import NavBar from '../_partials/NavBar/_NavBar';
import Header from '../_partials/Header/_Header';
import { connect } from 'react-redux';
import axios from 'axios';
import { toast } from 'react-toastify';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight, faCalendarAlt, faCheck, faChevronDown, faChevronUp, faClock, faEdit, faExternalLink, faFileAlt, faGear, faPaperclip, faPlus, faSave, faSearch, faTimes, faTrashCan, } from '@fortawesome/free-solid-svg-icons';
import { Scrollbar } from 'react-scrollbars-custom';
import imgDescription from '../../assets/images/description.png';
import { brand_colors } from '../../helpers/brand_colors_helper';
import SweetAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';
import { handleFormErrors } from '../../helpers/form_helpers';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from '@fullcalendar/list';
import { DateRangePicker } from 'react-date-range';
import { GET } from '../../api';

const apiURL = process.env.REACT_APP_API_URL;

class Appointments extends Component {
    constructor(props) {
        super(props);
        this.state = {
            appointmentsData: [],
            newAppointmentModal: {
                show: false,
                toggleModal: () => this.setState({
                    newAppointmentModal: { ...this.state.newAppointmentModal, show: !this.state.newAppointmentModal.show },
                }),
                saving: false
            },
            dateRangePicker: {
                show: false,
                togglePicker: () => this.setState({ dateRangePicker: { ...this.state.dateRangePicker, show: !this.state.dateRangePicker.show } }),
                value: {
                    startDate: new Date(), endDate: new Date(), key: 'selection'
                }
            },
            fData: {
                appointment_days: [],
                appointment_slot_length: "30",
                appointment_slot_intervals: "0",
                appointment_from_time: '',
                appointment_to_time: '',
                generateSlotsData: []
            },
            appointmentModal: {
                show: false,
                toggleModal: (data = null) => this.setState({
                    appointmentModal: { ...this.state.appointmentModal, show: !this.state.appointmentModal.show, data },
                }),
                saving: false
            },
            // appointment_slot_from_time":"00:00","appointment_slot_to_time":"00:30"
        }

    }

    calendarData = async (startdate = this.startdate, enddate = this.enddate) => {
        this.startdate = startdate
        this.enddate = enddate
        const { authData } = this.props;
        const { auth_key, user_id, role_id } = authData.loginData;
        const { employee_id } = authData.userData;
        const { center_id } = authData.centerData;

        if (this.calendarDataReq) this.calendarDataReq.abort();
        this.calendarDataReq = new AbortController();
        try {
            const res = await GET('appointment/calendar-data', {
                params: {
                    startdate: moment(startdate).format('YYYY-MM-DD'),
                    enddate: moment(enddate).format('YYYY-MM-DD'),
                    employee_id,
                },
                signal: this.calendarDataReq.signal,
            });

            this.setState({
                appointmentsData: res.data.map((v) => {
                    let backgroundColor = brand_colors[this.props.defaultTheme.theme_id].color4
                    if (v.state == 'busy') {
                        backgroundColor = brand_colors[this.props.defaultTheme.theme_id].color11
                    } else if (v.state == 'other') {
                        backgroundColor = brand_colors[this.props.defaultTheme.theme_id].color16
                    }
                    return { ...v, groupId: v.raw.appointments.appointment_id, start: v.start_datetime, end: v.end_datetime, title: (v.state).toUpperCase(), backgroundColor: backgroundColor }
                })
            });
        } catch (err) {
            console.log('err', err)
            toast.error(t('Something went wrong while fetching data!'));

        }
    };

    setStateFData = (key, value) => {
        this.setState({
            fData: { ...this.state.fData, [key]: value },
            dateRangePicker: { ...this.state.dateRangePicker, show: false }
        })
    }

    handleDateClick = (arg) => { // bind with an arrow function
        this.state.newAppointmentModal.toggleModal()
    }

    generateSlots = () => {
        let fData = this.state.fData
        let appointment_slot_length = fData.appointment_slot_length
        let appointment_from_time = fData.appointment_from_time
        let appointment_to_time = fData.appointment_to_time
        let appointment_slot_intervals = fData.appointment_slot_intervals
        if (appointment_from_time == '') {
            toast.error(t('From time should not be empty!'));
            return;
        }
        if (appointment_to_time == '') {
            toast.error(t('To time should not be empty!'));
            return;
        }
        if (appointment_from_time > appointment_to_time) {
            toast.error(t('From time should be less than To time!'));
            return;
        }
        if (appointment_slot_length == "" || appointment_slot_length == 0) {
            toast.error(t('Slot interval should be defined!'));
            return;
        }

        let slots = []
        while (appointment_from_time < appointment_to_time) {
            let to_time = this.addMinutes(appointment_from_time, appointment_slot_length);
            slots.push({
                'appointment_slot_from_time': appointment_from_time,
                'appointment_slot_to_time': to_time,
            })
            appointment_from_time = this.addMinutes(to_time, appointment_slot_intervals);
        }

        this.setState({
            fData: { ...this.state.fData, ['generateSlotsData']: slots },
            dateRangePicker: { ...this.state.dateRangePicker, show: false }
        })
    }

    addMinutes = (time, minsToAdd) => {
        function D(J) { return (J < 10 ? 0 : '') + J; };
        var piece = time.split(':');
        var mins = piece[0] * 60 + +piece[1] + +minsToAdd;
        if (mins >= 1440) {
            return '23:59';
        } else {
            return D(mins % (24 * 60) / 60 | 0) + ':' + D(mins % 60);
        }
    }


    saveAppointmentSchedule = () => {
        let newAppointmentModal = this.state.newAppointmentModal
        if (newAppointmentModal.saving) {
            return
        }
        this.setState({ newAppointmentModal: { ...newAppointmentModal, saving: true } }, async () => {
            const { authData } = this.props;
            const { center_id, center_timezone } = authData.centerData;
            const { auth_key } = authData.loginData;
            const { employee_id } = authData.userData;

            let fData = this.state.fData
            let appointment_from_date = this.state.dateRangePicker.value.startDate
            let appointment_to_date = this.state.dateRangePicker.value.endDate
            let appointment_slot_length = fData.appointment_slot_length
            let appointment_slot_intervals = fData.appointment_slot_intervals
            let appointment_from_time = fData.appointment_from_time
            let appointment_to_time = fData.appointment_to_time
            let appointment_days = fData.appointment_days.map((v) => v.value)
            let generateSlotsData = fData.generateSlotsData
            if (appointment_days.length <= 0) {
                toast.error(t('Please select one or more days!'));
                this.setState({ newAppointmentModal: { ...newAppointmentModal, saving: false } })
                return;
            }
            if (appointment_from_time == '') {
                toast.error(t('From time should not be empty!'));
                this.setState({ newAppointmentModal: { ...newAppointmentModal, saving: false } })
                return;
            }
            if (appointment_to_time == '') {
                toast.error(t('To time should not be empty!'));
                this.setState({ newAppointmentModal: { ...newAppointmentModal, saving: false } })
                return;
            }
            if (appointment_from_time > appointment_to_time) {
                toast.error(t('From time should be less than To time!'));
                this.setState({ newAppointmentModal: { ...newAppointmentModal, saving: false } })
                return;
            }
            if (appointment_slot_length == "" || appointment_slot_length == 0) {
                toast.error(t('Slot interval should be defined!'));
                this.setState({ newAppointmentModal: { ...newAppointmentModal, saving: false } })
                return;
            }
            if (generateSlotsData.length <= 0) {
                toast.error(t('Please generate slots first!'));
                this.setState({ newAppointmentModal: { ...newAppointmentModal, saving: false } })
                return;
            }

            const pData = new FormData();
            pData.append('center_id', center_id)
            pData.append('employee_id', employee_id)
            pData.append('appointment_days', JSON.stringify(appointment_days))
            pData.append('appointment_from_time', appointment_from_time)
            pData.append('appointment_to_time', appointment_to_time)
            pData.append('generateSlotsData', JSON.stringify(generateSlotsData))
            pData.append('appointment_from_date', moment(appointment_from_date).format('YYYY-MM-DD'))
            pData.append('appointment_to_date', moment(appointment_to_date).format('YYYY-MM-DD'))
            pData.append('appointment_slot_intervals', appointment_slot_intervals)
            pData.append('appointment_slot_length', appointment_slot_length)

            if (this.saveAppointmentScheduleReq) this.saveAppointmentScheduleReq.abort();
            this.saveAppointmentScheduleReq = new AbortController();

            try {
                const res = await axios.post(
                    apiURL + 'appointment/save-appointment-schedule',
                    pData,
                    {
                        params: {
                            auth_key: auth_key,
                            appname: process.env.REACT_APP_NAME,
                        },
                        signal: this.saveAppointmentScheduleReq.signal,
                    }
                );

                if (res) {
                    let resData = res.data
                    if (resData.type) {
                        toast[resData.messageType](t(resData.message));
                        this.setState({
                            newAppointmentModal: { ...newAppointmentModal, saving: false, show: false },
                            dateRangePicker: {
                                ...this.state.dateRangePicker,
                                show: false,
                                value: {
                                    startDate: new Date(), endDate: new Date(), key: 'selection'
                                }
                            },
                            fData: {
                                appointment_days: [],
                                appointment_slot_intervals: "30",
                                appointment_slot_intervals: "0",
                                appointment_from_time: '',
                                appointment_to_time: '',
                                generateSlotsData: []
                            }
                        }, () => {
                            this.calendarData()
                        })
                    } else {
                        if (resData.data == null) {
                            toast[resData.messageType](t(resData.message));
                        } else {
                            handleFormErrors(resData.data)
                        }
                        this.setState({ newAppointmentModal: { ...newAppointmentModal, saving: false } })
                    }
                } else {
                    toast.error(t('Something went wrong while saving!'));
                    this.setState({ newAppointmentModal: { ...newAppointmentModal, saving: false } })
                }
            } catch (err) {
                console.log('err', err)
                if (axios.isCancel(err)) {
                    // 
                }
                toast.error(t('Something went wrong while saving!'));

                this.setState({ newAppointmentModal: { ...newAppointmentModal, saving: false } })
            }
        })
    }


    eventClick = (eventClickInfo) => {
        let eventData = eventClickInfo.event.extendedProps
        this.state.appointmentModal.toggleModal(eventData)
    }
    render() {
        const { appointmentsData, newAppointmentModal, fData, appointmentModal } = this.state
        const { appointment_slot_length, appointment_slot_intervals, generateSlotsData } = fData
        const { lang_orientation: dir, lang_flag } = this.props.defaultLanguage;

        return (<Container fluid>
            <div id='iedu-layout'>
                <NavBar />
                <div id='page-content'>
                    <Header lite={true} heading={t('Appointments')} backBtn={true} />
                    <div className='grey-section'>
                        <div style={{ height: '74vh' }}>
                            <FullCalendar
                                height={'100%'}
                                headerToolbar={{
                                    left: "today prev,next",
                                    center: "title",
                                    right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek",
                                }}
                                plugins={[
                                    dayGridPlugin,
                                    interactionPlugin,
                                    timeGridPlugin,
                                    listPlugin
                                ]}
                                initialView='timeGridWeek'
                                dateClick={this.handleDateClick}
                                events={appointmentsData}
                                eventClick={this.eventClick}
                                locale={dir == 1 ? 'ar-kw' : 'en'}
                                direction={dir == 1 ? 'rtl' : 'ltr'}
                                datesSet={(dateInfo) => { //INVOKE WHEN DATE SET CHANGED
                                    let dateStart = dateInfo.start
                                    let dateEnd = dateInfo.end
                                    this.calendarData(dateStart, dateEnd)
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <Offcanvas show={newAppointmentModal.show} style={{ height: '100%' }} placement='bottom' onHide={newAppointmentModal.toggleModal}>
                <Offcanvas.Header id='iedu-header' style={{ marginTop: '1rem', marginRight: '1rem', marginLeft: '1rem', marginBottom: 0 }}>
                    <div className='d-flex flex-row  align-items-center justify-content-center'>
                        <span className='d-flex align-items-center justify-content-center btn-back-white' onClick={newAppointmentModal.toggleModal}>
                            <FontAwesomeIcon icon={(dir == 1) ? faAngleRight : faAngleLeft} color={brand_colors[this.props.defaultTheme.theme_id].color9} />
                        </span>
                        <Offcanvas.Title>
                            <div className='d-flex flex-row align-items-center justify-content-center title-back-color8' >
                                <span style={{ fontSize: 20, margin: '0 10px' }}>{t('New Appointment Schedule')}</span>
                            </div>

                        </Offcanvas.Title>
                    </div>
                    <div className='pe-5'>
                        <Button variant='success' onClick={() => this.saveAppointmentSchedule()}>{t('Save')}</Button>
                    </div>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div
                        className={'d-flex flex-row grey-section'}
                        style={{
                            overflow: 'initial',
                            backgroundImage: 'url(https://academic.educore.io/static/media/pegboard-bg.9b84e4936acef29bbcd9.png)',
                            backgroundRepeat: 'repeat',
                            backgroundPosition: '10px 12px',
                            overflow: 'hidden',
                        }}
                    >
                        <div style={{ backgroundColor: brand_colors[this.props.defaultTheme.theme_id].color8, marginLeft: 10, marginRight: 10, borderRadius: '20px', width: '100%', height: '70vh' }}>
                            <div style={{ padding: '1.5rem' }}>
                                <Row className='align-items-end'>
                                    <Col>
                                        <div style={{
                                            borderRadius: '10px',
                                            backgroundColor: '#fff',
                                            display: 'flex',
                                            padding: '8px',
                                            position: 'relative',
                                            zIndex: '1',
                                            borderWidth: 1,
                                            borderColor: brand_colors[this.props.defaultTheme.theme_id].color10,
                                            borderStyle: 'solid'
                                        }}>
                                            <div className="filter filter-date" style={{
                                                flex: 1,
                                                position: 'relative',
                                                padding: '0 10px',
                                                cursor: 'pointer',
                                            }}>
                                                <div className='d-flex flex-row justify-content-between align-items-center' onClick={() => this.state.dateRangePicker.togglePicker()}>
                                                    <div>{moment(this.state.dateRangePicker.value.startDate).format('DD-MM-YYYY')} -- {moment(this.state.dateRangePicker.value.endDate).format('DD-MM-YYYY')}</div>
                                                    <div>
                                                        <FontAwesomeIcon
                                                            icon={(this.state.dateRangePicker.show) ? faChevronUp : faChevronDown}
                                                            color={brand_colors[this.props.defaultTheme.theme_id].color18}
                                                            className="filter-arrow"
                                                        />
                                                    </div>
                                                </div>
                                                {(this.state.dateRangePicker.show)
                                                    && <div style={{
                                                        minWidth: '350px',
                                                        boxSizing: 'border-box',
                                                        boxShadow: '1px 0 0 #e6e6e6, -1px 0 0 #e6e6e6, 0 1px 0 #e6e6e6, 0 -1px 0 #e6e6e6, 0 3px 13px rgb(0 0 0 / 8%)',
                                                        borderRadius: '5px',
                                                        position: 'absolute',
                                                        left: '0px',
                                                        top: '35px',
                                                        backgroundColor: '#fff',
                                                        display: 'none',
                                                        padding: '15px',
                                                        textAlign: 'center',
                                                        display: (this.state.dateRangePicker.show) ? 'initial' : 'none'
                                                    }}>
                                                        <DateRangePicker
                                                            ranges={[this.state.dateRangePicker.value]}
                                                            onChange={(date) => {
                                                                this.setState({ dateRangePicker: { ...this.state.dateRangePicker, value: { startDate: new Date(date.selection.startDate), endDate: new Date(date.selection.endDate), key: 'selection' } } })
                                                            }}

                                                            // className='filter-date-picker'
                                                            style={{ backgroundColor: '#fff' }}
                                                        />
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </Col>
                                    <Col>
                                        <Form.Group>
                                            <Form.Label>
                                                {t('From Time')}
                                            </Form.Label>
                                            <Form.Control type='time' onChange={(event) => this.setStateFData('appointment_from_time', event.target.value)} placeholder={t('From Time')} />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group>
                                            <Form.Label>
                                                {t('To Time')}
                                            </Form.Label>
                                            <Form.Control type='time' onChange={(event) => this.setStateFData('appointment_to_time', event.target.value)} placeholder={t('To Time')} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className='align-items-end mt-2'>
                                    <Col>
                                        <Select
                                            placeholder={'--' + t('DAY(S)') + '--'}
                                            isMulti
                                            options={['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'].map((v, i) => { return { label: v, value: v } })}
                                            onChange={(event) => this.setStateFData('appointment_days', event)}
                                            // value={thisAns}
                                            menuPortalTarget={document.body}
                                            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999, }), control: (base) => ({ ...base, }) }}
                                            formatOptionLabel={option => (
                                                <div>
                                                    <span style={{}}>{option.label}</span>
                                                </div>
                                            )}
                                        />
                                    </Col>
                                    <Col>
                                        <Form.Label>{t('SLOT INTERVAL ')} <strong>{appointment_slot_length}</strong> {t(' (MINS)')}</Form.Label>
                                        <Form.Range defaultValue={appointment_slot_length} onMouseUp={(event) => {
                                            this.setStateFData('appointment_slot_length', event.target.value)
                                        }} />
                                    </Col>
                                    <Col>
                                        <Form.Label>{t('BETWEEN SLOT INTERVAL ')} <strong>{appointment_slot_intervals}</strong> {t(' (MINS)')}</Form.Label>
                                        <Form.Range defaultValue={appointment_slot_intervals} onMouseUp={(event) => this.setStateFData('appointment_slot_intervals', event.target.value)} />
                                    </Col>
                                    <Col md={'auto'}>
                                        <Button onClick={() => this.generateSlots()}>{t('Generate slots')}</Button>
                                    </Col>
                                </Row>
                                <hr></hr>
                                <Row className='flex-wrap'>
                                    {generateSlotsData.map((v, i) => {
                                        return <Col key={i} md="auto">
                                            <div className='d-flex flex-column' style={{ backgroundColor: brand_colors[this.props.defaultTheme.theme_id].color21, padding: 10, borderRadius: 10 }}>
                                                <Badge bg='primary'>{v.appointment_slot_from_time}</Badge>
                                                <Badge bg='warning'>{v.appointment_slot_to_time}</Badge>
                                            </div>
                                        </Col>
                                    })}

                                </Row>

                            </div>
                        </div>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>

            <Modal size='lg' show={appointmentModal.show} onHide={appointmentModal.toggleModal}>
                {appointmentModal.show &&
                    <Modal.Header closeButton>
                        {appointmentModal.data.raw.appointment_bookings.length > 0
                            && <Modal.Title>{t('Appointment Details')}</Modal.Title>
                        }
                    </Modal.Header>
                }
                {appointmentModal.show &&
                    <Modal.Body>
                        {appointmentModal.data.raw.appointment_bookings.map((v, i) => {
                            return <div key={i} style={{ padding: '1.5rem' }}>
                                <Row>
                                    <Col md='auto' className='d-flex flex-column justify-content-center align-items-center'>
                                        <div style={{ fontWeight: 'bold', fontSize: 13, color: brand_colors[this.props.defaultTheme.theme_id].color9, opacity: 0.7, color: (v.appointment_booking_status == "booked") ? brand_colors[this.props.defaultTheme.theme_id].color11 : brand_colors[this.props.defaultTheme.theme_id].color16 }}>
                                            {/*CANCELED */}
                                            {v.appointment_booking_status == "canceled" && t("Appointment Canceled")}
                                            {/*BOOKED */}
                                            {v.appointment_booking_status == "booked" && t("Appointment Booked")}
                                        </div>
                                        <div style={{ fontWeight: 'bold', fontSize: 13, color: brand_colors[this.props.defaultTheme.theme_id].color9, opacity: 0.7 }}>
                                            {v.parent_name}
                                        </div>
                                    </Col>
                                    <Col md='auto'>
                                        <div className='d-flex flex-column justify-content-center align-items-center'>
                                            <div style={{ fontWeight: 'bold', fontSize: 13, color: brand_colors[this.props.defaultTheme.theme_id].color9, opacity: 0.7 }}>{moment(v.appointment_booked_date).format('DD-MM-YYYY')}</div>
                                            <div className='d-flex flex-row justify-content-center align-items-center' style={{ fontSize: 13, color: brand_colors[this.props.defaultTheme.theme_id].color9, opacity: 0.7 }}>
                                                <span style={{ fontWeight: 'bold', margin: '0 10px' }}>{v.appointment_booked_from_time}</span>
                                                {t("to")}
                                                <span style={{ fontWeight: 'bold', margin: '0 10px' }}>{v.appointment_booked_to_time}</span>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col>
                                        <div style={{ fontSize: 13, color: brand_colors[this.props.defaultTheme.theme_id].color9, opacity: 0.7 }}>
                                            {v.appointment_booking_description}
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        })


                        }
                    </Modal.Body>
                }
            </Modal>
        </Container >);
    }
}

const mapStateToProps = (state) => ({
    authData: state.auth.authData,
    defaultLanguage: state.language.defaultLanguage,
    selectedClass: state.selectedClass.data,
    children: state.children.list,
    defaultTheme: state.theme.defaultTheme,
});

export default connect(mapStateToProps, null)(Appointments);