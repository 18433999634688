
import React, { Component } from 'react';
import { Container, Card, Table, Row, Col, Spinner, Image, Badge, Button, Form, Offcanvas, Dropdown, Modal, Tabs, Tab } from 'react-bootstrap';
import { t } from '../../helpers/translation_helper';
import NavBar from '../_partials/NavBar/_NavBar';
import Header from '../_partials/Header/_Header';
import { connect } from 'react-redux';
import axios from 'axios';
import { toast } from 'react-toastify';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleLeft, faAngleRight, faAngleUp, faAnglesLeft, faAnglesRight, faCalendarAlt, faCheck, faCheckCircle, faCheckDouble, faClock, faEdit, faEllipsisV, faExternalLink, faFileAlt, faFilterCircleXmark, faGear, faGripVertical, faPaperclip, faPlus, faRefresh, faSearch, faTimes, faTrashAlt, faTrashCan, faXmark, } from '@fortawesome/free-solid-svg-icons';
import { Scrollbar } from 'react-scrollbars-custom';
import imgDescription from '../../assets/images/description.png';
import { brand_colors } from '../../helpers/brand_colors_helper';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Link } from 'react-router-dom';
import { faCircle, faThumbsDown, faThumbsUp } from '@fortawesome/free-regular-svg-icons';
import { handleFormErrors, readFile } from '../../helpers/form_helpers';
import { colorPalette } from '../../constants/colorsPalette';
import { getExtension, getUrlParam } from '../../helpers/general_helpers';
import HorizontalCard from '../../helpers/HorizontalCard';
import { GET, POST } from '../../api';
import Dragula from 'react-dragula'
var Chance = require('chance')
var chance = new Chance()

// API URL
const apiURL = process.env.REACT_APP_API_URL;
// Portal URL
const portalURL = process.env.REACT_APP_PORTAL_URL;

class TimetableDetailsGenerator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteModal: { show: false, id: 0, action: () => { }, title: null, desc: null },
      days: [
        { name: 'Sunday', active: true },
        { name: 'Monday', active: true },
        { name: 'Tuesday', active: true },
        { name: 'Wednesday', active: true },
        { name: 'Thursday', active: true },
        { name: 'Friday', active: true },
        { name: 'Saturday', active: true },
      ],
      timetable_period_timings: [],
      timetable: [],
      employee_timetable_availability: [],
      employees: {
        data: [],
        search: '',
        changeSearch: (search) => this.setState({ employees: { ...this.state.employees, search } })
      },
      timetableData: {
        classes: [],
        classOpen: [],
        agegroup: [],
        agegroup_classes_ids: [],
        toggleAgegroup: (agegroup_id) => this.setState({
          timetableData: {
            ...this.state.timetableData,
            agegroup_id,
            agegroup_classes_ids: this.state.timetableData.classes
              .filter(item => item.agegroup_id == agegroup_id)
              .map(item => item.class_id)
          }
        }, () => {
          if (this.drake) {
            this.drake.destroy()
            this.Dragula()
          }
        }),
        agegroup_id: null,
      },
      timetablePeriods: {
        tp_id: null
      },
      timetableDetails: [],
      classes_assignments: [],
      tab: 0,
      generateTT: false
    }
    this.timetableDetails = []
  }

  componentDidMount() {
    let getUrlParamData = getUrlParam()
    if (getUrlParamData.id) {
      let tp_id = getUrlParamData.id
      this.setState({ timetablePeriods: { tp_id: tp_id } }, () => {
        this.getTimetableDetails()
      })
    }
    this.checkWeekendDays()
  }

  isSticky = (e) => {
    const scrollTop = e.target.scrollTop;
    const header = document.querySelector('#make-sticky');
    scrollTop >= 290 ? header.classList.add('is-sticky') : header.classList.remove('is-sticky');
  };

  getTimetableDetails = async () => {
    let timetablePeriods = this.state.timetablePeriods
    if (!timetablePeriods.tp_id) {
      return null
    }
    try {
      const res = await GET('timetable/get-timetable-details', {
        params: {
          tp_id: timetablePeriods.tp_id
        },
      })
      if (res.status == 200) {
        let resData = res.data
        this.setState({
          timetable_period_timings: resData.timetable_period_timings,
          timetable: resData.timetable,
          employees: { ...this.state.employees, data: resData.employees, search: '' },
          employee_timetable_availability: resData.employee_timetable_availability
        }, () => this.getTimetable())
      }
    } catch (err) {
      console.log('err', err)
      toast.error(t('Something went wrong while fetching academic subjects!'))
    }
  }
  checkWeekendDays = () => {
    let weekend_days = this.props.authData.centerData.weekend_days
    if (weekend_days) {
      weekend_days = weekend_days.split(',')
    }
    let days = this.state.days.map((v, i) => {
      if (weekend_days.includes(v.name.toLowerCase())) {
        return { ...v, active: false }
      }
      return v
    })
    this.setState({ days })
  }
  getTimetable = async () => {
    let timetablePeriods = this.state.timetablePeriods
    if (!timetablePeriods.tp_id) {
      return null
    }
    try {
      const res = await GET('timetable/get-timetable2', {
        params: {
          tp_id: timetablePeriods.tp_id
        }
      })
      if (res.status == 200) {
        let resData = res.data

        resData.agegroup = resData.agegroup.map((v) => {
          let total_acs_sessions_per_week = v.academic_classmain_subject.reduce((total, currentValue) => {
            return (total + parseFloat(currentValue['acs_sessions_per_week']))
          }, 0)
          let total_periods = this.state.days.reduce((t, v) => {
            if (v.active) { return t + 1 }
            return t
          }, 0) * this.state.timetable_period_timings.length

          return { ...v, total_periods: total_periods, total_acs_sessions_per_week: total_acs_sessions_per_week }
        })

        let merge_timetableDetails = []
        resData.classes.map((v, i) => {
          merge_timetableDetails = [...merge_timetableDetails, ...v.timetable_details]
        })
        this.timetableDetails = merge_timetableDetails

        let classes_assignments = resData.classes_assignments

        this.setState({
          timetableData: { ...this.state.timetableData, ...resData },
          classes_assignments
        })
      }
    } catch (err) {
      console.log('err', err)
      toast.error(t('Something went wrong while fetching academic subjects!'))
    }
  }


  clickAndDrag = (selector, scroll_speed = 2, classOnEvent = 'grabbed_elem') => {
    const slider = document.querySelector(selector);
    this.isDown = false;
    this.startX = 0;
    this.scrollTop = 0;

    slider.addEventListener('mousedown', (e) => {
      e.preventDefault();
      this.isDown = true;
      slider.classList.add(classOnEvent);
      this.startX = e.pageY - slider.offsetTop;
      this.scrollTop = slider.scrollTop;

      // prevent default child behavior
      document.body.addEventListener('click', function (event) {
        if (slider.contains(event.target)) {
          event.preventDefault();
        }
      });
    });
    slider.addEventListener('mouseleave', () => {
      this.isDown = false;
      slider.classList.remove(classOnEvent);
    });
    slider.addEventListener('mouseup', () => {
      this.isDown = false;
      slider.classList.remove(classOnEvent);
    });
    slider.addEventListener('mousemove', (e) => {
      if (this.drake) {
        if (!this.isDown || !this.drake.dragging) return;
        e.preventDefault();
        const x = e.pageY - slider.offsetTop;
        const walk = (x - this.startX) * scroll_speed; //scroll-fast
        slider.scrollTop = this.scrollTop + walk;
      }
    });
  }


  Dragula = () => {
    const $this = this;

    let timetableDetails = this.timetableDetails
    console.log('INITIALIZE DRAGULA');
    if (document.getElementById('dragable-reels')) {
      this.drake = Dragula([document.getElementById('dragable-reels')].concat(Array.from(document.querySelectorAll('.dropable-target'))), {
        accepts: function (el, target, source, sibling) {
          let tt_id = target.getAttribute('tt_id')
          let tpt_id = target.getAttribute('tpt_id')
          let dayname = target.getAttribute('dayname')
          let findtimetableDetails = timetableDetails.find((v, i) => v.tt_id == tt_id && v.tpt_id == tpt_id && v.dayname == dayname);

          let class_id_target = target.getAttribute('class_id')
          let class_id_el = el.getAttribute('class_id')

          if (class_id_el && class_id_target !== class_id_el) {
            return;
          }
          // console.log('accept', tt_id, tpt_id, dayname, timetableDetails, findtimetableDetails);
          return source.classList[0] === 'dragable-reels' && !findtimetableDetails
        },
        copy: function (el, source) {
          return source.classList[0] === 'dragable-reels'
        },
        moves: function (el, container, handle, siblings) {
          // console.log('MOVES', el.classList[0] === 'dragable-reel');
          return el.classList[0] === 'dragable-reel'
        },

        removeOnSpill: true,
        mirrorContainer: document.getElementById('page-content')
      })

      this.drake.on('drop', function (el, target, source) {
        let acs_id = el.getAttribute('acs_id')
        let acs_name = el.getAttribute('acs_name')
        let tt_id = target.getAttribute('tt_id')
        let tpt_id = target.getAttribute('tpt_id')
        let dayname = target.getAttribute('dayname')
        let employee_id = el.getAttribute('employee_id')
        // console.log('target', target);
        // console.log('source', source);
        // console.log('el', el);


        if (source.classList[0] == 'dragable-reels' && target.classList[0] == 'dropable-target') {
          timetableDetails.push({
            tt_id: chance.unique(chance.integer, 1, { min: 99999999999, max: 999999999999 })[0],
            employee_id,
            tt_id,
            tpt_id,
            dayname: dayname,
            locked: 0,
            acs_id,
            acs_name
          })
          el.setAttribute('tt_id', tt_id)
          el.setAttribute('tpt_id', tpt_id)
          el.setAttribute('dayname', dayname)
          el.style.minWidth = "unset";
        }
      })

      $this.drake.on('remove', function (el, container, source) {
        // console.log('REMOVE el, container, source', el, container, source);
        let tt_id = el.getAttribute('tt_id')
        let tpt_id = el.getAttribute('tpt_id')
        let dayname = el.getAttribute('dayname')
        let findtimetableDetails = timetableDetails.findIndex((v, i) => v.tt_id == tt_id && v.tpt_id == tpt_id && v.dayname == dayname)
        // console.log('REMOVE ', tt_id, tpt_id, dayname, findtimetableDetails);
        timetableDetails.splice(findtimetableDetails, 1)
      })
    }
  }
  toggleTimetablePublish = async (params) => {
    if (this.saveTimetableDetailsClassReq) {
      this.saveTimetableDetailsClassReq.abort()
    }
    this.saveTimetableDetailsClassReq = new AbortController()
    const res = await GET(
      'timetable/toggle-timetable-publish',
      {
        params: {
          class_id: params.class_id,
          tt_id: params.tt_id,
          published: params.published
        },
        signal: this.saveTimetableDetailsClassReq.signal,
      }
    )
    if (!res.data.type) {
      if (res.data.data != null) {
        handleFormErrors(res.data.data)
      } else {
        toast.error(t('Something went wrong while saving!'))
      }
    } else {
      let timetableData = this.state.timetableData
      timetableData.classes = timetableData.classes.map((v, i) => {
        if (v.class_id == params.class_id) {
          return { ...v, published: params.published }
        }
        return v
      })
      this.setState({ timetableData })
      toast.success(t(res.data.message))
    }
  }
  saveTimetableDetailsClass = async (params) => {
    if (this.saveTimetableDetailsClassReq) {
      this.saveTimetableDetailsClassReq.abort()
    }
    this.saveTimetableDetailsClassReq = new AbortController()
    let this_timetableDetails = JSON.parse(JSON.stringify(this.timetableDetails))
    this_timetableDetails = this_timetableDetails.filter((v, i) => v.tt_id == params.tt_id)

    const fData = new FormData()
    fData.append('timetable_details', JSON.stringify(this_timetableDetails))
    fData.append('tt_id', params.tt_id)
    const res = await POST(
      'timetable/save-timetable-details-class',
      fData,
      {
        signal: this.saveTimetableDetailsClassReq.signal,
      }
    )
    if (!res.data.type) {
      if (res.data.data != null) {
        handleFormErrors(res.data.data)
      } else {
        toast.error(t('Something went wrong while saving!'))
      }
    } else {
      // this.setState({
      //     modalEmployees: {
      //         ...this.state.modalEmployees,
      //         show: false,
      //         saving: false,
      //         data: null
      //     },
      //     employees: employees
      // })
      toast.success(t(res.data.message))
    }
  }

  render() {
    console.log('%cTHIS.STATE', 'font-size:20px;color:orange;font-weight:bold;');
    console.log(this.state);
    const { defaultTheme } = this.props;
    const { days, timetable_period_timings, timetable, employee_timetable_availability, employees, timetableData, timetablePeriods } = this.state
    return (<Container fluid>
      <div id='iedu-layout'>
        <NavBar />
        <div id='page-content' onScroll={(e) => this.isSticky(e)}>
          <Header lite={true} heading={t('Timetable Details Generator')} backBtn={true} />

          <div className='grey-section'>
            <div className='grey-section-header'>
              <h5>
                <span className='me-1'>{t('Grades')}</span>
                <span className='mx-1' style={{ opacity: 0.6, fontWeight: 'normal' }}>|</span>
                <span className='mx-1' style={{ opacity: 0.8, fontSize: '1rem', fontWeight: 'normal' }}>{t('Please make sure that all sessions are less or equal to the periods')}</span>
              </h5>
              <div className='d-flex flex-row align-items-center px-1 justify-content-end' style={{ flex: 1, }}>
                {/* <Button variant='success' onClick={() => { }}>{t('Save')}</Button> */}
              </div>
            </div>
            <div>
              {/* GRADES */}
              <div style={{ overflowX: 'auto', overflowY: 'hidden' }}>
                <div className='d-flex flex-row'>
                  {timetableData.agegroup.map((v, i) => {
                    let data = {
                      allData: v,
                      name: v.agegroup_code,
                      text1: <div className='d-flex flex-column align-items-center'>
                        <Badge bg={(v.total_periods < v.total_acs_sessions_per_week) ? 'danger' : 'success'}>{('Subject Session: ') + v.total_acs_sessions_per_week + ', ' + ('Periods: ') + v.total_periods}</Badge>
                        <Badge bg={(v.total_periods < v.total_acs_sessions_per_week) ? 'danger' : 'success'}>{(v.total_periods < v.total_acs_sessions_per_week) && ('Subject Session are more than the Periods.')}</Badge>
                      </div>,

                      cardImage: require('../../assets/images/timetable.png'),
                      actions: [
                        { type: 'selection', name: 'Selection', iconName: null, iconColor: brand_colors[this.props.defaultTheme.theme_id].color4, onClick: () => timetableData.toggleAgegroup((timetableData.agegroup_id == v.agegroup_id) ? null : v.agegroup_id), state: timetableData.agegroup_id == v.agegroup_id },
                        {
                          type: 'generate', name: 'Generate Timetable', image: null, iconName: faRefresh, iconColor: brand_colors[this.props.defaultTheme.theme_id].color16, onClick: () => { }
                        },
                      ]
                    }
                    return <HorizontalCard data={data} key={i} />
                  })}
                </div>
              </div>
            </div>

            {this.state.generateTT ?
              <div>
                <Table>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>First Name</th>
                      <th>Last Name</th>
                      <th>Username</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>Mark</td>
                      <td>Otto</td>
                      <td>@mdo</td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>Jacob</td>
                      <td>Thornton</td>
                      <td>@fat</td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td colSpan={2}>Larry the Bird</td>
                      <td>@twitter</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
              :
              <div className='d-flex flex-row align-items-start justify-content-start' style={{ width: '100%' }}>
                <div className='mt-1' id='dragula-scrollable-container' style={{ width: '75%' }}>
                  {timetableData.classes.map((classData, i) => {
                    let classOpened = timetableData.classOpen.findIndex((classOpen) => classOpen == classData.class_id)
                    if (classData.agegroup_id == timetableData.agegroup_id) {
                      return <Card key={i} style={{ width: '100%' }}>
                        <Card.Header>
                          <div className='d-flex flex-row justify-content-between align-items-center'>
                            <div className='d-flex flex-row' style={{ fontSize: 18, fontWeight: '600' }}>
                              {classData.class_theme}
                              <div className='mx-1'>
                                {(classData.published == 1)
                                  ? <Badge bg="success">{t('Published')}</Badge>
                                  : <Badge bg="warning">{t('Not Published')}</Badge>
                                }
                              </div>
                            </div>
                            <div className='d-flex flex-row align-items-center'>
                              <FontAwesomeIcon icon={(classOpened != -1) ? faAngleUp : faAngleDown} className='mx-2 cursor-pointer' onClick={() => {
                                if (classOpened != -1) {
                                  timetableData.classOpen.splice(classOpened, 1)
                                } else {
                                  timetableData.classOpen.push(classData.class_id)
                                }
                                this.setState({ timetableData: timetableData })
                              }} />
                              {(classOpened != -1) && (classData.published == 0) && <Button className='mx-2' size='sm' variant='success' onClick={() => this.saveTimetableDetailsClass({ class_id: classData.class_id, tt_id: classData.tt_id })}>{t('Save')}</Button>}
                              {(classOpened != -1) &&
                                <Button className='mx-2' size='sm' variant='warning' onClick={() => this.toggleTimetablePublish({ class_id: classData.class_id, tt_id: classData.tt_id, published: (classData.published == 1) ? 0 : 1 })}>{(classData.published == 1) ? t('Un-Publish') : t('Publish')}</Button>
                              }
                            </div>
                          </div>
                        </Card.Header>
                        <Card.Body style={{ display: (classOpened != -1) ? 'block' : 'none' }}>
                          <Table bordered size="sm" responsive>
                            <thead>
                              <tr>
                                <th className='text-center' style={{ width: '10vw' }}>
                                  <div className='d-flex flex-column'>
                                    {t('Time')}
                                  </div>
                                </th>
                                {days.map((v, i) => {
                                  return <th className={(v.active) ? 'text-center table-success' : 'text-center table-danger'}>{(v.name).slice(0, 3)}</th>
                                })}
                              </tr>
                            </thead>
                            <tbody>
                              {timetable_period_timings.map((v, i) => {
                                return <tr>
                                  <th>
                                    <div className='d-flex flex-column align-items-center justify-content-center'>
                                      <Badge bg='success' className='my-1'>
                                        {v.time_start}
                                      </Badge>
                                      <Badge bg='warning' className='my-1'>
                                        {v.time_end}
                                      </Badge>
                                    </div>
                                  </th>
                                  {days.map((vv) => {
                                    return <td style={{
                                      minWidth: (vv.active) ? '12rem' : '6rem',
                                      position: 'relative',
                                    }} className={(vv.active) ? 'dropable-target' : 'no-drop table-danger'} dayname={vv.name} tpt_id={v.tpt_id} tt_id={classData.tt_id} class_id={classData.class_id}>
                                      <div style={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)'
                                      }}>
                                        <span style={{
                                          fontSize: 10,
                                          fontWeight: 700,
                                          backgroundColor: v.tp_type_color,
                                          display: 'inline-block',
                                          padding: 5,
                                          paddingInline: 10,
                                          borderRadius: 8,
                                          textAlign: 'center',
                                          color: colorPalette.findIndex(item => item.code == v.tp_type_color)?.is_text_white !== -1 ? '#fff' : '#000'
                                        }}>
                                          {v.tp_type_name}
                                        </span>
                                      </div>
                                      {classData.timetable_details.map((timetable_details_data, timetable_details_key) => {
                                        if (classData.tt_id == timetable_details_data.tt_id && v.tpt_id == timetable_details_data.tpt_id && timetable_details_data.dayname == vv.name) {
                                          let data = {
                                            allData: timetable_details_data,
                                            name: timetable_details_data.acs_name,
                                            code: timetable_details_data.acs_code,
                                            text1: 'Sessions Per Week: ',
                                            text2: timetable_details_data.acs_sessions_per_week,
                                            text3: 'Coefficient: ',
                                            text4: timetable_details_data.acs_coefficient,
                                            text5: 'Teacher: ',
                                            text6: timetable_details_data.employee_name,
                                            // cardImage: (vv.acs_image) ? portalURL + vv.center_id + '/academic_classmain_subject/' + vv.acs_image : require('../../assets/images/noimage.png'),
                                            actions: [
                                            ]
                                          }
                                          return <HorizontalCard data={data} key={timetable_details_key} size="sm" attributes={{ acs_id: timetable_details_data.acs_id, acs_name: timetable_details_data.acs_name, tt_id: timetable_details_data.tt_id, tpt_id: timetable_details_data.tpt_id, dayname: timetable_details_data.dayname, className: 'dragable-reel cursor-grab' }} />
                                        }
                                      })}
                                    </td>
                                  })}
                                </tr>
                              })}
                            </tbody>
                          </Table>
                        </Card.Body>
                      </Card>
                    }
                  })}
                </div>
                <div style={{ width: '25%', height: '95vh', paddingLeft: 5, paddingTop: 3 }}>
                  <Button
                    variant={this.state.tab ? 'outline-secondary' : 'primary'}
                    onClick={() => this.setState({ tab: 0 })}
                    className='me-2'>Subjects</Button>
                  <Button
                    variant={this.state.tab ? 'primary' : 'outline-secondary'}
                    onClick={() => this.setState({ tab: 1 })}>Subjects w/ Teacher</Button>
                  <div className='grey-section-header' style={{
                    padding: 0
                  }}>
                    <h5>
                      <span style={{ opacity: 0.8, fontSize: '1rem', fontWeight: 'normal' }}>{t('Drag & Drop subjects in respective Periods')}</span>
                    </h5>
                  </div>
                  <div id='make-sticky' style={{ backgroundColor: brand_colors[this.props.defaultTheme.theme_id].color7, overflowX: 'hidden', overflowY: 'auto', height: '95vh', borderLeft: 'solid 2px ' + brand_colors[this.props.defaultTheme.theme_id].color10 }}>
                    {/* SUBJECTS */}
                    {timetableData.agegroup_id &&
                      <div className='dragable-reels d-flex flex-column' id='dragable-reels' ref={this.Dragula}>
                        {!this.state.tab && timetableData.agegroup.map((v, i) => {
                          if (timetableData.agegroup_id == v.agegroup_id) {
                            return v.academic_classmain_subject.map((vv, ii) => {
                              let data = {
                                allData: vv,
                                name: vv.acs_name,
                                code: vv.acs_code,
                                text1: 'Sessions Per Week: ',
                                text2: vv.acs_sessions_per_week,
                                text3: 'Coefficient: ',
                                text4: vv.acs_coefficient,
                                // cardImage: (vv.acs_image) ? portalURL + vv.center_id + '/academic_classmain_subject/' + vv.acs_image : require('../../assets/images/noimage.png'),
                                actions: [
                                ]
                              }
                              return <HorizontalCard data={data} key={ii} size="sm" attributes={{ acs_id: vv.acs_id, acs_name: vv.acs_name, className: 'dragable-reel cursor-grab' }} />
                            })
                          }
                        })}
                        {this.state.tab && this.state.classes_assignments.map((item, index) => {
                          if (this.state.timetableData.agegroup_classes_ids.includes(item.class_id)) {
                            let data = {
                              allData: item,
                              name: item.acs_name,
                              code: item.acs_code,
                              text1: 'Sessions Per Week: ',
                              text2: item.acs_sessions_per_week,
                              text3: 'Coefficient: ',
                              text4: item.acs_coefficient,
                              text5: 'Teacher: ',
                              text6: item.employee_name,
                              text7: 'Class Name: ',
                              text8: item.class_theme,
                              // cardImage: (vv.acs_image) ? portalURL + vv.center_id + '/academic_classmain_subject/' + vv.acs_image : require('../../assets/images/noimage.png'),
                              actions: [
                              ]
                            }
                            return <HorizontalCard data={data} key={index} size="sm" attributes={{ acs_id: item.acs_id, acs_name: item.acs_name, class_id: item.class_id, employee_id: item.employee_id, className: 'dragable-reel cursor-grab' }} />
                          }
                        })}
                      </div>
                    }
                  </div>
                </div>
              </div>
            }


          </div>
        </div>
      </div>
    </Container >);
  }
}


const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  defaultLanguage: state.language.defaultLanguage,
  selectedClass: state.selectedClass.data,
  defaultTheme: state.theme.defaultTheme,
});

export default connect(mapStateToProps, null)(TimetableDetailsGenerator);
