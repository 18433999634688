import React, { Component } from 'react';
import { Container, Card, Row, Col, Form, Table, Badge, Button, ButtonGroup, Alert, Spinner } from 'react-bootstrap';
import { t } from '../../helpers/translation_helper';
import NavBar from '../_partials/NavBar/_NavBar';
import Header from '../_partials/Header/_Header';
import { connect } from 'react-redux';
import Select from 'react-select';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { GET, POST } from '../../api';
import { toast } from 'react-toastify';
import CalendarPreviewModal from '../_common/CalendarPreviewModal/_CalendarPreviewModal';
import { setCalendarPreview } from '../../redux/slices/calendarPreviewModalSlice';

// API URL
const apiURL = process.env.REACT_APP_API_URL;


class WeeklyClassPlan extends Component {

  types = ['classwork', 'homework', 'academic_activities', 'exams'];

  state = {
    working: false,
    fetching: false,
    week: [],
    weekData: [],
    classesData: {
      data: [],
      selected: []
    },
    isEdited: false
  }

  componentDidMount() {
    this.getClasses();
    this.generateWeek();
  }

  changeWeek = (direction) => {
    const { week } = this.state;
    if (direction === 'past') {
      this.generateWeek(moment(week[0]).subtract(6, 'days').format('YYYY-MM-DD'));
    } else if (direction === 'future') {
      this.generateWeek(moment(week[6]).add(1, 'days').format('YYYY-MM-DD'));
    } else {
      this.generateWeek();
    }
  }

  getClasses = async () => {
    if (typeof this.getClassesReq != typeof undefined) {
      this.getClassesReq.abort();
    }
    this.getClassesReq = new AbortController();
    const res = await GET('classes/get', {
      params: {
        agegroup_id: this.props.selectedClass.class.agegroup_id,
        limit: 100,
        offset: 0,
      },
      signal: this.getClassesReq.signal
    });
    let resData = res.data
    this.setState({
      classesData: {
        ...this.state.classesData, data: resData.data, selected: resData.data.map((v) => parseInt(v.class_id))
      }
    })
  }

  generateWeek = async (date = null) => {

    const { selectedClass, subject } = this.props;

    this.setState({ fetching: true, week: [], weekData: [] });

    if (!subject) {

      this.setState({ fetching: false });
      return;
    }


    if (!date) {
      date = new Date();
    } else {
      date = new Date(date);
    }
    const firstDay = new Date(
      date.setDate(date.getDate() - date.getDay()),
    );

    let week = [];
    let weekData = [];

    for (let i = 0; i <= 6; i++) {
      let first = firstDay.getDate() - firstDay.getDay() + i;
      let day = new Date(firstDay.setDate(first)).toISOString().slice(0, 10);
      week.push(day);
    }

    const startDate = week[0];
    const endDate = week[6];

    try {
      const res = await GET('center-calendar/get-weekly-class-plan', {
        params: {
          class_id: selectedClass.class.class_id,
          acs_id: subject.acs_id,
          startDate,
          endDate,
        }
      });

      week.map((day) => {
        const classwork_data = res.data.weekly_lesson_plan.find(item =>
          item.date == day && item.add_type == 'classwork'
        );
        const homework_data = res.data.weekly_lesson_plan.find(item =>
          item.date == day && item.add_type == 'homework'
        );
        const academic_activities_data = res.data.weekly_lesson_plan.find(item =>
          item.date == day && item.add_type == 'academic_activities'
        );
        const exams_data = res.data.weekly_lesson_plan.find(item =>
          item.date == day && item.add_type == 'exams'
        );

        weekData.push(
          {
            cc_id: (classwork_data) ? classwork_data.cc_id : 0,
            title: 'Classwork',
            add_type: 'classwork',
            date: day,
            body: classwork_data ? classwork_data.body : '',
            is_published: (classwork_data) ? classwork_data.is_published : null
          },
          {
            cc_id: (homework_data) ? homework_data.cc_id : 0,
            title: 'Homework',
            add_type: 'homework',
            date: day,
            body: homework_data ? homework_data.body : '',
            is_published: (homework_data) ? homework_data.is_published : null
          },
          {
            cc_id: (academic_activities_data) ? academic_activities_data.cc_id : 0,
            title: 'Academic Activities',
            add_type: 'academic_activities',
            date: day,
            body: academic_activities_data ? academic_activities_data.body : '',
            is_published: (academic_activities_data) ? academic_activities_data.is_published : null
          },
          {
            cc_id: (exams_data) ? exams_data.cc_id : 0,
            title: 'Exams',
            add_type: 'exams',
            date: day,
            body: exams_data ? exams_data.body : '',
            is_published: (exams_data) ? exams_data.is_published : null
          }
        );
      });

      this.setState({ week, weekData, fetching: false });
    } catch (err) {
      console.log('err', err)

      this.setState({ fetching: false });
    }

  }

  saveWeek = async (publish = 0) => {

    this.setState({ working: true });

    const { authData, selectedClass, subject } = this.props;
    const { week, weekData, classesData } = this.state;

    const filteredData = weekData.filter(item => item.body !== '');

    if (filteredData.length == 0) {
      this.setState({ working: false, isEdited: false });
      toast.info(t('No changes has been made!'));
      return;
    }

    const fData = new FormData();
    fData.append('class_id', selectedClass.class.class_id);
    fData.append('class_ids', JSON.stringify(classesData.selected.map((v) => parseInt(v))));
    fData.append('acs_id', subject.acs_id);
    fData.append('startDate', week[0]);
    fData.append('endDate', week[6]);
    fData.append('publish', publish ? 1 : 0)
    fData.append('CenterCalendarData', JSON.stringify(filteredData));

    try {
      const res = await POST('center-calendar/save-weekly-class-plan', fData);
      if (res.data.type) {
        toast.success(t('Planner updated successfully!'));
      }
      this.generateWeek()
      this.setState({ working: false, isEdited: false });
    } catch (err) {
      console.log('err', err)

      this.setState({ working: false, isEdited: false });
    }

  }

  checkWeekend = (week_item) => {
    const { weekend_days } = this.props.authData.centerData;
    let weekend_days_arr = weekend_days.split(',');
    return weekend_days_arr.includes(moment(week_item).format('dddd').toLocaleLowerCase());
  }

  unPublish = async (data) => {
    console.log('data', data);
    if (this.unPublishReq) {
      this.unPublishReq.abort();
    }
    this.unPublishReq = new AbortController();
    const res = await GET('center-calendar/unpublish-center-calendar', {
      params: {
        cc_id: data.cc_id,
      },
      signal: this.unPublishReq.signal
    });
    let resData = res.data
    if (resData.type) {
      let weekData = this.state.weekData
      weekData = weekData.map((v, i) => {
        if (v.cc_id == data.cc_id) {
          return { ...v, is_published: null }
        }
        return v
      })
      this.setState({ weekData });
    }
    toast[resData.messageType](t(resData.message));
  }

  render() {
    const classesData = this.state.classesData
    const { authData } = this.props;
    const { auth_key } = authData.loginData;
    const role_id = authData.loginData.role_id
    const employee_classplan_approve = authData.userData.employee_classplan_approve
    console.log('this.state', this.state);
    return (
      <Container fluid>
        <div id='iedu-layout'>
          <NavBar />
          <div id='page-content'>
            <Header lite={true} backBtn={true}
              heading={t('Weekly Class Plan')}
              showClassSelector={true}
              showSubjectSelector={true}
              classSelectorFunc={[this.generateWeek, this.getClasses]}
              subjectSelectorFunc={[this.generateWeek, this.getClasses]}
            />
            <div className='grey-section'>
              <Card
                className='border-0'
                style={{ borderRadius: '20px' }}>
                <Card.Body>
                  {
                    this.state.fetching &&
                    <Row>
                      <Col className='text-center py-5'>
                        <Spinner animation="grow" /><br />
                        {t('Generating Planner...')}
                      </Col>
                    </Row>
                  }
                  {!this.props.subject &&
                    <Row>
                      <Col md={12}>
                        <Alert className='mb-0 text-center' variant='warning'>{t('There is no subject in this class!')}</Alert>
                      </Col>
                    </Row>
                  }
                  {
                    this.state.fetching == false &&
                    this.state.selectedSubject != '' &&
                    this.props.subject &&
                    <Row>
                      <Col md={12}>
                        <Table>
                          <thead>
                            <tr>
                              <th colSpan={'5'}>
                                <div className='d-flex flex-row justify-content-between align-items-center'>
                                  <div className='d-flex flex-row align-items-start' style={{ fontSize: 12 }}>
                                    {classesData.data.map((v, i) => {
                                      let findClassIndex = classesData.selected.findIndex((vv) => vv == v.class_id)
                                      return <div className='me-3' key={i}>
                                        <Form.Check type="switch" label={v.class_theme} checked={(findClassIndex !== -1) ? true : false} disabled onChange={() => {
                                          if (findClassIndex !== -1) {
                                            classesData.selected.splice(findClassIndex, 1)
                                          } else {
                                            classesData.selected.push(v.class_id)
                                          }
                                          this.setState({ classesData })
                                        }} />
                                      </div>
                                    })}
                                  </div>
                                  <div className='d-flex flex-row justify-content-between align-items-center'>
                                    <Button variant={"info"} size='sm'
                                      className='me-2'
                                      style={{ color: '#fff' }}
                                      onClick={() => this.props.setCalendarPreview({
                                        showModal: true,
                                        type: 'week',
                                        data: this.state.week
                                      })}>
                                      {t('Preview Week')}
                                    </Button>
                                    {(parseInt(employee_classplan_approve) == 1 || parseInt(role_id) == 22)
                                      && <Button className='me-2' variant="success" size='sm' title={t('Save & Publish')}
                                        disabled={parseInt(employee_classplan_approve) != 1 || this.state.working} onClick={() => this.saveWeek(employee_classplan_approve)} >
                                        {t('Save & Publish')}
                                        {this.state.working && (<Spinner as='span' animation='grow' size='sm' role='status' aria-hidden='true' />
                                        )}
                                      </Button>
                                    }
                                    <Button variant={this.state.isEdited ? "warning btn-glow-red" : "warning"} size='sm'
                                      title={t('Save w/o Publish')}
                                      disabled={this.state.working}
                                      style={{
                                        position: 'relative'
                                      }}
                                      onClick={() => this.saveWeek(0)}>
                                      {t('Save')}
                                      {this.state.working && (<Spinner as='span' animation='grow' size='sm' role='status' aria-hidden='true' />
                                      )}
                                    </Button>
                                  </div>
                                </div>
                              </th>
                            </tr>
                            <tr>
                              <th valign='middle' width='10%' className='px-0'>
                                <Button
                                  disabled={this.state.isEdited}
                                  className='me-1'
                                  size='sm'
                                  title={t('Current Week')}
                                  onClick={() => this.changeWeek('current')}
                                  variant="dark">
                                  {t('Current')}
                                </Button>
                                <ButtonGroup size='sm'>
                                  <Button
                                    disabled={this.state.isEdited}
                                    title={t('Previous Week')}
                                    onClick={() => this.changeWeek('past')}
                                    variant="dark">
                                    <FontAwesomeIcon
                                      icon={faAngleLeft}
                                      color={'#ffffff'}
                                    />
                                  </Button>
                                  <Button
                                    disabled={this.state.isEdited}
                                    title={t('Next Week')}
                                    onClick={() => this.changeWeek('future')}
                                    variant="dark">
                                    <FontAwesomeIcon
                                      icon={faAngleRight}
                                      color={'#ffffff'}
                                    />
                                  </Button>
                                </ButtonGroup>
                              </th>
                              <th valign='middle' style={{ textAlign: 'center' }} width='22%'>{t('Classwork')}</th>
                              <th valign='middle' style={{ textAlign: 'center' }} width='23%'>{t('Homework')}</th>
                              <th valign='middle' style={{ textAlign: 'center' }} width='22%'>{t('Academic Activities')}</th>
                              <th valign='middle' style={{ textAlign: 'center', position: 'relative' }} width='23%'>{t('Exams')}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.weekData.length > 0 && this.state.week.map((week_item, week_index) => (
                              <tr key={week_index}>
                                <td valign='middle'
                                  className={this.checkWeekend(week_item) ? 'custom-bg-danger' : ''}
                                  style={{ textAlign: 'center' }}>
                                  {this.checkWeekend(week_item) &&
                                    <div style={{
                                      fontSize: 11,
                                      fontWeight: 700
                                    }} className='text-danger'>{t('WEEKEND')}</div>
                                  }
                                  <Badge bg="info">
                                    {moment(week_item).format('DD-MM-YYYY')}
                                  </Badge><br />
                                  <strong>{moment(week_item).format('dddd').toUpperCase()}</strong><br />
                                  <Button
                                    onClick={() => this.props.setCalendarPreview({
                                      showModal: true,
                                      type: 'day',
                                      data: week_item
                                    })}
                                    size='sm'
                                    style={{
                                      paddingTop: 0,
                                      paddingBottom: 0,
                                    }}
                                    variant="outline-info">{t('Preview')}</Button>
                                </td>
                                {this.types.map((type_item, type_index) => {
                                  const dataIndex = this.state.weekData.findIndex(item => item.date == week_item && item.add_type == type_item);
                                  return (
                                    <td key={type_index} style={{ paddingLeft: 0 }} className={this.checkWeekend(week_item) ? 'custom-bg-danger' : ''}>
                                      <Alert className='p-1 text-center mt-1 mb-1' style={{ fontSize: 12 }} variant={!this.state.weekData[dataIndex].is_published ? 'danger' : 'success'}>
                                        {!this.state.weekData[dataIndex].is_published
                                          ? 'Not Published'
                                          : 'Published, Data will not be updated unless Unpublished first.'}
                                        {this.state.weekData[dataIndex].is_published && (employee_classplan_approve == 1 || role_id == 22) && <Badge className='cursor-pointer ms-2' bg="warning" onClick={() => this.unPublish(this.state.weekData[dataIndex])}>{('UNPUBLISH')}</Badge>}
                                      </Alert>
                                      <CKEditor
                                        data={this.state.weekData[dataIndex].body}
                                        config={{
                                          ckfinder: {
                                            uploadUrl: apiURL + '/authentication/ckeditor-upload?auth_key=' + auth_key + '&center_id=' + this.props.authData.centerData.center_id
                                          },
                                          removePlugins: ['Title'],
                                          toolbar: {
                                            items: [
                                              'bold',
                                              'italic',
                                              'fontColor',
                                              'fontFamily',
                                              'bulletedList',
                                              'link',
                                              'uploadImage'
                                            ]
                                          },
                                          readOnly: true
                                        }}
                                        editor={Editor}
                                        onBlur={(
                                          event,
                                          editor
                                        ) => {
                                          // Array.from(editor.ui.componentFactory.names()).map(item => console.log(item));
                                          const data =
                                            editor.getData();
                                          let weekData = this.state.weekData;
                                          weekData[dataIndex].body = data;
                                        }}
                                        onChange={() => {
                                          this.setState({ isEdited: true });
                                        }}
                                      />
                                    </td>
                                  );
                                })
                                }
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </Col>
                    </Row>
                  }
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
        <CalendarPreviewModal />
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  defaultLanguage: state.language.defaultLanguage,
  selectedClass: state.selectedClass.data,
  subject: state.selectedClass.subject,
});

const mapDispatchToProps = () => ({
  setCalendarPreview,
});

export default connect(mapStateToProps, mapDispatchToProps())(WeeklyClassPlan);
