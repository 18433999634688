import React, { Component } from 'react';
import { Container, Card, Row, Col, Image, Spinner, ButtonGroup, Button, Offcanvas, Form, Badge, Breadcrumb, Modal, Tabs, Tab } from 'react-bootstrap';
import { t } from '../../helpers/translation_helper';
import NavBar from '../_partials/NavBar/_NavBar';
import Header from '../_partials/Header/_Header';
import { connect } from 'react-redux';
import moment from 'moment';
import axios from 'axios';
import { toast } from 'react-toastify';
import SweetAlert from 'react-bootstrap-sweetalert';
import ClassViewCard from './ClassViewCard';
import { DateRangePicker } from 'react-date-range';
import { faArrowAltCircleLeft, faCheck, faCircleCheck, faEdit, faFilePdf, faMicrophone, faPencil, faPlus, faSquare, faSquareCheck, faTimes, faTimesCircle, faTrashCan, faChevronUp, faChevronDown, faSearch, faArrowAltCircleRight, faAngleRight, faAngleLeft, faArrowRight, faRemove, faSliders, faStarHalfStroke, faTable, faMinus, faCheckCircle, faCircle, faTh, faBook, faTachometer, faQuestionCircle, faAward, faCalendar, faLock, faLockOpen } from '@fortawesome/free-solid-svg-icons';
import { brand_colors } from '../../helpers/brand_colors_helper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactMediaRecorder } from 'react-media-recorder';
import Select from 'react-select';
import { Chance } from 'chance';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import Flatpickr from 'react-flatpickr';
import imgDescription from '../../assets/images/description.png';
import { getExtension } from '../../helpers/general_helpers';
import { handleFormErrors } from '../../helpers/form_helpers';
import pdfAttachment from '../../assets/images/pdf.png';
import audioAttachment from '../../assets/images/audio.png';
import AddEditExam from '../_common/AddEditExam/_AddEditExam';
import { clone } from 'sortablejs';
import ExamGrades from './ExamGrades';
import QuestionsGrades from './QuestionsGrades';
import { GET, POST } from '../../api';
const chance = new Chance();
const apiURL = process.env.REACT_APP_API_URL;



const portalURL = process.env.REACT_APP_PORTAL_URL;

const defaultTimelineTypes = [
  {
    uid: chance.unique(chance.integer, 1, { min: 99999999999, max: 999999999999 })[0],
    name: "Assignment",
    ut_type: "Assignment",
    picture: require('../../assets/images/user-timeline/assignment.png'),
    active: true,
    show: true,
    classmain_types: [0, 1, 2, 3, 4],
  },
  {
    uid: chance.unique(chance.integer, 1, { min: 99999999999, max: 999999999999 })[0],
    name: "Exam",
    ut_type: "Exam",
    picture: require('../../assets/images/user-timeline/exam.png'),
    active: true,
    show: false,
    classmain_types: [0, 1, 2, 3, 4],
  },
  {
    uid: chance.unique(chance.integer, 1, { min: 99999999999, max: 999999999999 })[0],
    name: "Activity",
    ut_type: "Academic Activity",
    picture: require('../../assets/images/user-timeline/activity.png'),
    active: true,
    show: true,
    classmain_types: [0, 1, 2, 3, 4],
  },
  {
    uid: chance.unique(chance.integer, 1, { min: 99999999999, max: 999999999999 })[0],
    name: "Online Homework",
    ut_type: "Homework",
    picture: require('../../assets/images/user-timeline/newsletter.png'),
    active: true,
    show: true,
    classmain_types: [0, 1, 2, 3, 4],
  },
  {
    uid: chance.unique(chance.integer, 1, { min: 99999999999, max: 999999999999 })[0],
    name: "Agenda",
    ut_type: "Agenda",
    picture: require('../../assets/images/user-timeline/agenda.png'),
    active: true,
    show: true,
    classmain_types: [0, 1, 2, 3, 4],
  },
  {
    uid: chance.unique(chance.integer, 1, { min: 99999999999, max: 999999999999 })[0],
    name: "Academic Lesson",
    ut_type: "Academic Lesson",
    picture: require('../../assets/images/user-timeline/agenda.png'),
    active: true,
    show: true,
    classmain_types: [0, 1, 2, 3, 4],
  },
];

const formSections = [
  {
    uid: '1',
    active: true,
    completed: false,
    name: 'Tag Student',
    desc: null,
    include_ut_type: ['Notification', 'Event', 'Newsletter', 'Upload', 'Media', 'Daily Report', 'Note', 'Assignment', 'Online Class', 'Assessment', 'Video', 'Exam', 'Agenda', 'Resources', 'Incident Report', 'Exam Retake', 'Online Homework', 'Academic Activity'],
    data: [
      {
        uid: '1.1',
        active: false,
        completed: false,
        name: 'Class',
        desc: null,
        image: require('../../assets/images/lesson/lesson-focus.png'),
        include_ut_type: ['Notification', 'Event', 'Newsletter', 'Upload', 'Media', 'Daily Report', 'Note', 'Assignment', 'Online Class', 'Assessment', 'Video', 'Exam', 'Agenda', 'Resources', 'Incident Report', 'Exam Retake', 'Online Homework', 'Academic Activity'],
      },
      {
        uid: '1.2',
        active: false,
        completed: false,
        name: 'Students',
        desc: null,
        image: require('../../assets/images/lesson/lesson-focus.png'),
        include_ut_type: ['Notification', 'Event', 'Newsletter', 'Upload', 'Media', 'Daily Report', 'Note', 'Assignment', 'Online Class', 'Assessment', 'Video', 'Exam', 'Agenda', 'Resources', 'Incident Report', 'Exam Retake', 'Online Homework', 'Academic Activity'],
      },
    ]
  },
  {
    uid: '2',
    active: true,
    completed: false,
    name: 'Basic',
    desc: null,
    include_ut_type: ['Notification', 'Event', 'Newsletter', 'Upload', 'Media', 'Daily Report', 'Note', 'Assignment', 'Online Class', 'Assessment', 'Video', 'Exam', 'Agenda', 'Resources', 'Incident Report', 'Exam Retake', 'Poll', 'Survey', 'Online Homework', 'Academic Activity', 'Policy'],
    data: [

      {
        uid: '2.1',
        active: false,
        completed: false,
        name: 'Title',
        desc: null,
        image: require('../../assets/images/lesson/lesson-focus.png'),
        include_ut_type: ['Notification', 'Event', 'Newsletter', 'Upload', 'Media', 'Daily Report', 'Note', 'Assignment', 'Online Class', 'Assessment', 'Video', 'Exam', 'Agenda', 'Resources', 'Incident Report', 'Exam Retake', 'Poll', 'Survey', 'Online Homework', 'Academic Activity', 'Policy'],
      },
      {
        uid: '2.2',
        active: false,
        completed: false,
        name: 'Description',
        desc: null,
        image: require('../../assets/images/lesson/lesson-focus.png'),
        include_ut_type: ['Notification', 'Event', 'Newsletter', 'Upload', 'Media', 'Daily Report', 'Note', 'Assignment', 'Online Class', 'Video', 'Exam', 'Agenda', 'Resources', 'Incident Report', 'Exam Retake', 'Poll', 'Survey', 'Online Homework', 'Academic Activity', 'Policy'],
      },
      {
        uid: '2.3',
        active: false,
        completed: false,
        name: 'Attachment 1',
        desc: null,
        image: require('../../assets/images/lesson/lesson-focus.png'),
        include_ut_type: ['Notification', 'Event', 'Newsletter', 'Upload', 'Daily Report', 'Note', 'Assignment', 'Online Class', 'Exam', 'Resources', 'Incident Report', 'Exam Retake', 'Poll', 'Survey', 'Online Homework', 'Policy'],
      },
      {
        uid: '2.4',
        active: false,
        completed: false,
        name: 'Attachment 2',
        desc: null,
        image: require('../../assets/images/lesson/lesson-focus.png'),
        include_ut_type: ['Notification', 'Event', 'Upload', 'Daily Report', 'Note', 'Online Class', 'Exam', 'Resources', 'Incident Report', 'Exam Retake', 'Online Homework', 'Policy'],
      },
      {
        uid: '2.5',
        active: false,
        completed: false,
        name: 'URL',
        desc: null,
        image: require('../../assets/images/lesson/lesson-focus.png'),
        include_ut_type: ['Event', 'Note', 'Resources', 'Assignment', 'Online Class', 'Newsletter'],
      },
    ],
  },
  {
    uid: '3',
    active: false,
    completed: false,
    name: 'Media',
    desc: null,
    include_ut_type: ['Media', 'Assessment'],
    data: [
      {
        uid: '3.1',
        active: false,
        completed: false,
        name: 'Album',
        desc: null,
        image: require('../../assets/images/lesson/lesson-focus.png'),
        include_ut_type: ['Media'],
      },
      {
        uid: '3.2',
        active: false,
        completed: false,
        name: 'Multiple Attachments',
        desc: null,
        image: require('../../assets/images/lesson/lesson-focus.png'),
        include_ut_type: ['Media', 'Assessment'],
      },
    ],
  },
  {
    uid: '4',
    active: false,
    completed: false,
    name: 'Video',
    desc: null,
    include_ut_type: ['Video'],
    data: [
      {
        uid: '4.1',
        active: false,
        completed: false,
        name: 'Upload Video',
        desc: null,
        image: require('../../assets/images/lesson/lesson-focus.png'),
        include_ut_type: ['Video'],
      },
    ],
  },
  {
    uid: '5',
    active: false,
    completed: false,
    name: 'Event',
    desc: null,
    include_ut_type: ['Event'],
    data: [
      {
        uid: '5.1',
        active: false,
        completed: false,
        name: 'Event Fee',
        desc: null,
        image: require('../../assets/images/lesson/lesson-focus.png'),
        include_ut_type: ['Event'],
      },
      {
        uid: '5.2',
        active: false,
        completed: false,
        name: 'Start DateTime',
        desc: null,
        image: require('../../assets/images/lesson/lesson-focus.png'),
        include_ut_type: ['Event'],
      },
      {
        uid: '5.3',
        active: false,
        completed: false,
        name: 'End DateTime',
        desc: null,
        image: require('../../assets/images/lesson/lesson-focus.png'),
        include_ut_type: ['Event'],
      },
    ]
  },
  {
    uid: '6',
    active: false,
    completed: false,
    name: 'Assignment',
    desc: null,
    include_ut_type: ['Assignment'],
    data: [
      {
        uid: '6.1',
        active: false,
        completed: false,
        name: 'Due Date',
        desc: null,
        image: require('../../assets/images/lesson/lesson-focus.png'),
        include_ut_type: ['Assignment'],
      },
      {
        uid: '6.2',
        active: false,
        completed: false,
        name: 'Note 2',
        desc: null,
        image: require('../../assets/images/lesson/lesson-focus.png'),
        include_ut_type: ['Assignment'],
      },
      {
        uid: '6.3',
        active: false,
        completed: false,
        name: 'Subject',
        desc: null,
        image: require('../../assets/images/lesson/lesson-focus.png'),
        include_ut_type: ['Assignment'],
      },
      {
        uid: '6.4',
        active: false,
        completed: false,
        name: 'Record Audio',
        desc: null,
        image: require('../../assets/images/lesson/lesson-focus.png'),
        include_ut_type: ['Assignment'],
      },
    ]
  },
  {
    uid: '7',
    active: false,
    completed: false,
    name: 'Observation',
    desc: null,
    include_ut_type: ['Assessment'],
    data: [
      {
        uid: '7.1',
        active: false,
        completed: false,
        name: 'Observation',
        desc: null,
        image: require('../../assets/images/lesson/lesson-focus.png'),
        include_ut_type: ['Assessment'],
      },
      {
        uid: '7.2',
        active: false,
        completed: false,
        name: 'Next Steps',
        desc: null,
        image: require('../../assets/images/lesson/lesson-focus.png'),
        include_ut_type: ['Assessment'],
      },
      {
        uid: '7.3',
        active: false,
        completed: false,
        name: 'Objective',
        desc: null,
        image: require('../../assets/images/lesson/lesson-focus.png'),
        include_ut_type: ['Assessment'],
      },
    ]
  },
  {
    uid: '8',
    active: false,
    completed: false,
    name: 'Agenda',
    desc: null,
    include_ut_type: ['Agenda'],
    data: [
      {
        uid: '8.1',
        active: false,
        completed: false,
        name: 'Subject',
        desc: null,
        image: require('../../assets/images/lesson/lesson-focus.png'),
        include_ut_type: ['Agenda'],
      },
      {
        uid: '8.2',
        active: false,
        completed: false,
        name: 'Start DateTime',
        desc: null,
        image: require('../../assets/images/lesson/lesson-focus.png'),
        include_ut_type: ['Agenda'],
      },
    ]
  },
  {
    uid: '9',
    active: false,
    completed: false,
    name: 'Incident Report',
    desc: null,
    include_ut_type: ['Incident Report'],
    data: [
      {
        uid: '9.1',
        active: false,
        completed: false,
        name: 'Incident DateTime',
        desc: null,
        image: require('../../assets/images/lesson/lesson-focus.png'),
        include_ut_type: ['Incident Report'],
      },
      {
        uid: '9.2',
        active: false,
        completed: false,
        name: 'Taken to Hospital',
        desc: null,
        image: require('../../assets/images/lesson/lesson-focus.png'),
        include_ut_type: ['Incident Report'],
      },
      {
        uid: '9.3',
        active: false,
        completed: false,
        name: 'Taken Immediately/Date',
        desc: null,
        image: require('../../assets/images/lesson/lesson-focus.png'),
        include_ut_type: ['Incident Report'],
      },
      {
        uid: '9.4',
        active: false,
        completed: false,
        name: 'Hospital Name',
        desc: null,
        image: require('../../assets/images/lesson/lesson-focus.png'),
        include_ut_type: ['Incident Report'],
      },
      {
        uid: '9.5',
        active: false,
        completed: false,
        name: 'Follow Up',
        desc: null,
        image: require('../../assets/images/lesson/lesson-focus.png'),
        include_ut_type: ['Incident Report'],
      },
    ]
  },
  {
    uid: '10',
    active: false,
    completed: false,
    name: 'Poll',
    desc: null,
    include_ut_type: ['Poll'],
    data: [
      {
        uid: '10.1',
        active: false,
        completed: false,
        name: 'Start DateTime',
        desc: null,
        image: require('../../assets/images/lesson/lesson-focus.png'),
        include_ut_type: ['Poll'],
      },
      {
        uid: '10.2',
        active: false,
        completed: false,
        name: 'End DateTime',
        desc: null,
        image: require('../../assets/images/lesson/lesson-focus.png'),
        include_ut_type: ['Poll'],
      },
      {
        uid: '10.3',
        active: false,
        completed: false,
        name: 'Allow Resubmit',
        desc: null,
        image: require('../../assets/images/lesson/lesson-focus.png'),
        include_ut_type: ['Poll'],
      },
      {
        uid: '10.4',
        active: false,
        completed: false,
        name: 'Tag Users',
        desc: null,
        image: require('../../assets/images/lesson/lesson-focus.png'),
        include_ut_type: ['Poll'],
      },
      {
        uid: '10.5',
        active: false,
        completed: false,
        name: 'Options',
        desc: null,
        image: require('../../assets/images/lesson/lesson-focus.png'),
        include_ut_type: ['Poll'],
      },
    ]
  },
  {
    uid: '11',
    active: false,
    completed: false,
    name: 'Survey',
    desc: null,
    include_ut_type: ['Survey'],
    data: [
      {
        uid: '11.1',
        active: false,
        completed: false,
        name: 'Start DateTime',
        desc: null,
        image: require('../../assets/images/lesson/lesson-focus.png'),
        include_ut_type: ['Survey'],
      },
      {
        uid: '11.2',
        active: false,
        completed: false,
        name: 'End DateTime',
        desc: null,
        image: require('../../assets/images/lesson/lesson-focus.png'),
        include_ut_type: ['Survey'],
      },
      {
        uid: '11.3',
        active: false,
        completed: false,
        name: 'Allow Resubmit',
        desc: null,
        image: require('../../assets/images/lesson/lesson-focus.png'),
        include_ut_type: ['Survey'],
      },
      {
        uid: '11.4',
        active: false,
        completed: false,
        name: 'Tag Users',
        desc: null,
        image: require('../../assets/images/lesson/lesson-focus.png'),
        include_ut_type: ['Survey'],
      },
      {
        uid: '11.5',
        active: false,
        completed: false,
        name: 'Questions',
        desc: null,
        image: require('../../assets/images/lesson/lesson-focus.png'),
        include_ut_type: ['Survey'],
      },
    ]
  },
  {
    uid: '12',
    active: false,
    completed: false,
    name: 'Activity',
    desc: null,
    include_ut_type: ['Academic Activity'],
    data: [
      {
        uid: '12.1',
        active: false,
        completed: false,
        name: 'Activity',
        desc: null,
        image: require('../../assets/images/lesson/lesson-focus.png'),
        include_ut_type: ['Academic Activity'],
      },
      {
        uid: '12.2',
        active: false,
        completed: false,
        name: 'Start DateTime',
        desc: null,
        image: require('../../assets/images/lesson/lesson-focus.png'),
        include_ut_type: ['Academic Activity'],
      },
      {
        uid: '12.3',
        active: false,
        completed: false,
        name: 'End DateTime',
        desc: null,
        image: require('../../assets/images/lesson/lesson-focus.png'),
        include_ut_type: ['Academic Activity'],
      },
    ]
  },
  {
    uid: '13',
    active: false,
    completed: false,
    name: 'Policy',
    desc: null,
    include_ut_type: ['Policy'],
    data: [
      {
        uid: '13.1',
        active: false,
        completed: false,
        name: 'Tag Users',
        desc: null,
        image: require('../../assets/images/lesson/lesson-focus.png'),
        include_ut_type: ['Policy'],
      },
    ]
  },
]

let controller;
class ClassView extends Component {

  state = {
    working: false,
    utWorking: false,
    startDate: '',
    endDate: '',
    getUserTimelineData: {
      refreshing: false,
      finished: false,
      limit: 12,
      offset: 0,
      data: []
    },
    offset: 0,
    finishedData: false,
    loadMore: false,
    filter: {
      show: false,
      toggleFilter: (show) => this.setState({ filter: { ...this.state.filter, show: (this.state.filter.show == show) ? false : show } }),
      setData: (key, value) => {
        this.setState({ filter: { ...this.state.filter, [key]: value } })
      },
      selectedUT: defaultTimelineTypes.map((v, i) => { return { label: v.name, value: v.ut_type } }),
      selectedStudent: null,
      selectedDateRange: { startDate: moment(new Date()).subtract(10, 'd')._d, endDate: new Date(), key: 'selection' },
      keyword: '',
      unpublished: false,
      allStudents: [],
    },
    allStudents: [],
    delAlert: false,
    delID: null,
    unlockAlert: false,
    allAcademicActivities: [],
    allTerms: [],
    userTimelineModal: {
      show: false,
      toggleModal: (user_timeline = {}) => this.setState({
        userTimelineModal: { ...this.state.userTimelineModal, show: (user_timeline.name) ? true : false, user_timeline },
      }, () => {
        if (user_timeline.name) {
          if (user_timeline.ut_type == 'Academic Activity') {
            // this.getAcademicActivities()
          }
          this.resetUserTimelineForm()
        }
      }),
      user_timeline: {},
      saving: false
    },
    academicLessonAttributes: [],
    assessmentMenuData: {},
    user_timeline: {
      ut_id: null,
      ut_title: '',
      ut_description: '',
      ut_url: '',
      ut_pintotop: 0,
      ut_publish_on: null,
      ut_publish_status: 1,
      ut_type: null,
      utc_type: 'Teacher Parent',
      class_ids: {},
      ut_type_table_id: null,
      ut_type_table: null,
    },
    user_timeline_metadata: {
      academic_activity_ut_id: null,
      description2: '',
    },
    user_timeline_media: [],//utm_name, utm_url
    assess_aspect: [],//assaspect_id, center_id, assesslog_id, lrnaspect_id, agegroup_id, assagegroup_value, assagegroup_value_num
    assess_curr: [],//asscurr_id, assesslog_id, curriculum_id, center_id, curr_aspect_id, curr_agegroup_id, asscurr_value, asscurr_value_num,
    formSections,
    activityData: null,
    activityModal: {
      show: false,
      modalTitle: 'Activity',
      closeModal: () => this.setState({ activityModal: { ...this.state.activityModal, show: false } }),
      toggleModal: (data = null) => this.setState({
        activityModal: { ...this.state.activityModal, show: !this.state.activityModal.show, }, formSectionActive: {
          parent: '1',
          child: '1.1',
        }
      }, () => {
        if (this.state.activityModal.show) {
          this.activityData(data)
          let formSectionsActivity = this.state.formSectionsActivity
          formSectionsActivity = formSectionsActivity.map((v, i) => {
            if (v.uid == 2) {
              v.data = data.user_timeline_tagged.map((vv, ii) => {
                return {
                  uid: '2_' + (ii + 1),
                  active: false,
                  completed: false,
                  name: vv.child_name,
                  image: portalURL + vv.center_id + '/children/' + vv.picture,
                  data: vv,
                }
              })
            }
            return v
          })
          this.setState({
            formSectionsActivity: formSectionsActivity
          }, () => {
            this.getActivityResponses({ ut_id: data.ut_id, user_timeline_tagged: data.user_timeline_tagged })
          })
        }
      }),
      showModal: () => this.setState({ activityModal: { ...this.state.activityModal, show: true } }),
    },
    formSectionsActivity: [
      {
        uid: '1',
        active: true,
        completed: true,
        name: 'Activity Details',
        desc: null,
        data: [
          {
            uid: '1.1',
            active: false,
            completed: true,
            name: 'Details',
            desc: null,
            image: require('../../assets/images/lesson/lesson-focus.png'),
          },
        ]
      },
      {
        uid: '2',
        active: true,
        completed: false,
        name: 'Tagged',
        desc: null,
        data: []
      }
    ],
    examData: null,
    studentExamData: null,
    studentExamDataAnswers: null,
    examModal: {
      show: false,
      modalTitle: 'Exam',
      closeModal: () => this.setState({ examModal: { ...this.state.examModal, show: false } }),
      toggleModal: (data = null) => this.setState({
        examModal: { ...this.state.examModal, show: !this.state.examModal.show, }, formSectionActive: {
          parent: '1',
          child: '1.1',
        }
      }, () => {
        if (this.state.examModal.show) {
          this.examData(data)
          let formSectionsExam = this.state.formSectionsExam
          formSectionsExam = formSectionsExam.map((v, i) => {
            if (v.uid == 2) {
              v.data = data.user_timeline_tagged.map((vv, ii) => {
                return {
                  uid: '2_' + (ii + 1),
                  active: false,
                  completed: false,
                  name: vv.child_name,
                  image: portalURL + vv.center_id + '/children/' + vv.picture,
                  data: vv,
                }
              })
            }
            return v
          })
          this.setState({
            formSectionsExam: formSectionsExam
          }, () => {
            this.getExam(data)
          })
        }
      }),
      showModal: () => this.setState({ examModal: { ...this.state.examModal, show: true } }),
    },
    formSectionsExam: [
      {
        uid: '1',
        active: true,
        completed: true,
        name: 'Exam Details',
        desc: null,
        data: [
          {
            uid: '1.1',
            active: false,
            completed: true,
            name: 'Details',
            desc: null,
            image: require('../../assets/images/lesson/lesson-focus.png'),
          },
        ]
      },
      {
        uid: '2',
        active: true,
        completed: false,
        name: 'Students',
        desc: null,
        data: []
      }
    ],
    graphModal: {
      show: false,
      modalTitle: t('Exam Graph'),
      toggleModal: (exam_id) => {
        this.setState({
          graphModal: {
            ...this.state.graphModal,
            show: !this.state.graphModal.show
          }
        }, () => {
          const { show } = this.state.graphModal;
          if (show) {
            this.getGraphData(exam_id);
          }
        })
      }
    },
    graphData: null
  };

  constructor(props) {
    super(props);
    this.UTList = React.createRef();
    this.filePicker = React.createRef();
    this.ut_title_Ref = React.createRef()
    this.ut_description_Ref = React.createRef()
    this.description2_Ref = React.createRef()
    this.examsgrade_comment = React.createRef()
    this.AddEditExam = React.createRef();
  }

  componentDidMount() {
    this.getUserTimeline();
    this.getAcademicActivities();
    this.getLessonAttributes();
    this.getAssessmentMenu();
  }

  getAssessmentMenu = () => {
    if (typeof this.getAssessmentMenuRequest != typeof undefined) {
      this.getAssessmentMenuRequest.abort();
    }

    this.getAssessmentMenuRequest = new AbortController();

    if (typeof this.getAllCurriculumRequest != typeof undefined) {
      this.getAllCurriculumRequest.abort();
    }

    this.getAllCurriculumRequest = new AbortController();

    axios
      .all([
        GET('assesslog/get-assessment-menu', {
          signal: this.getAssessmentMenuRequest.signal,
        }),
        GET('assesslog/get-all-curriculum', {
          signal: this.getAllCurriculumRequest.signal,
        }),
      ])
      .then(
        axios.spread((assessmentMenuData, getAllCurriculumData) => {
          this.setState({ assessmentMenuData: { ...assessmentMenuData.data, allCurriculum: getAllCurriculumData.data } })
        }))
      .catch((err) => {
        if (axios.isCancel(err)) {

        } else {
          toast.error(
            'Something went wrong while fetching chats and contacts!'
          );

        }
      });
  }

  getUserTimeline = () => {
    if (this.state.getUserTimelineData.refreshing) {
      return false
    }
    if (this.state.getUserTimelineData.finished) {
      return false
    }

    this.setState({ getUserTimelineData: { ...this.state.getUserTimelineData, refreshing: true } }, () => {


      if (this.getUserTimelineRequest) {
        this.getUserTimelineRequest.abort()
      }
      this.getUserTimelineRequest = new AbortController();

      let userTimelineDataLen = this.state.getUserTimelineData.data.length

      let fData = new FormData()
      fData.append('selectedTimelineTypes', JSON.stringify(this.state.filter.selectedUT.map((v, i) => { return { ut_type: v.value, active: true } })))
      fData.append('offset', (userTimelineDataLen > 0) ? this.state.getUserTimelineData.offset : 0)
      fData.append('limit', this.state.getUserTimelineData.limit)
      fData.append('user_id', this.props.authData.loginData.user_id)
      fData.append('center_id', this.props.authData.centerData.center_id)
      fData.append('center_timezone', this.props.authData.centerData.center_timezone)
      // this.props.authData.classes.map((v, i) => {
      //   fData.append('class_id[]', v.class.class_id)
      // })
      if (this.props.selectedClass) {
        fData.append('class_id', this.props.selectedClass.class.class_id);
      }
      if (this.props.selectedSubject) {
        fData.append('acs_id', this.props.selectedSubject.acs_id);
      }

      fData.append('ut_id', (userTimelineDataLen > 0) ? this.state.getUserTimelineData.data[userTimelineDataLen - 1].ut_id : 0)
      fData.append('activeFilterDateFrom', (this.state.filter.selectedDateRange.startDate) ? moment(this.state.filter.selectedDateRange.startDate).format('YYYY-MM-DD') + ' 00:00:00' : null)
      fData.append('activeFilterDateTo', (this.state.filter.selectedDateRange.endDate) ? moment(this.state.filter.selectedDateRange.endDate).format('YYYY-MM-DD') + ' 23:59:59' : null)


      POST('user-timeline', fData, {
        signal: this.getUserTimelineRequest.signal,
      })
        .then((res) => {
          // 
          if (res) {
            let resData = res.data.data
            if (resData.length <= 0) {
              this.setState({ getUserTimelineData: { ...this.state.getUserTimelineData, refreshing: false, finished: true, offset: this.state.getUserTimelineData.offset + this.state.getUserTimelineData.limit } })
              return
            }
            this.setState({ getUserTimelineData: { ...this.state.getUserTimelineData, data: this.state.getUserTimelineData.data.concat(resData), refreshing: false, finished: false, offset: this.state.getUserTimelineData.offset + this.state.getUserTimelineData.limit } })
          }
        })

    })

  }

  getLessonAttributes = async () => {
    const { authData } = this.props;
    const { center_id } = authData.centerData;
    const { auth_key } = authData.loginData;
    try {
      const res = await GET('academic-subject-lessons/get-lesson-attributes');

      if (res.status == 200) {
        this.setState({ academicLessonAttributes: res.data, filter: { ...this.state.filter, all_lesson_focus: res.data.filter((v, i) => (v.ala_type == 'lesson_focus') && v.ala_parent_id == 0) } });
      }

    } catch (err) {
      console.log('err', err)
      toast.error(
        t('Something went wrong while fetching Lesson Attributes!')
      );

    }
  };

  getAcademicActivities = () => {
    if (typeof this.getAcademicActivitiesReq != typeof undefined) {
      this.getAcademicActivitiesReq.abort();
    }

    this.getAcademicActivitiesReq = new AbortController();
    GET('academic-activities/get-academic-activities', {
      signal: this.getAcademicActivitiesReq.signal,
    })
      .then((res) => {
        // 
        this.setState({ allAcademicActivities: res.data })
      })
      .catch((err) => {
        if (axios.isCancel(err)) {

        } else {
          toast.error(
            'Something went wrong while fetching chats and contacts!'
          );

        }
      });
  }

  getTerms = () => {
    if (typeof this.getTermsRequest != typeof undefined) {
      this.getTermsRequest.abort();
    }

    this.getTermsRequest = new AbortController();
    GET('user-timeline/get-terms', {
      signal: this.getTermsRequest.signal,
    })
      .then((res) => {
        // 
        let selectedTerm = res.data.find((v) => (v.term_active == 1))
        this.setState({ allTerms: res.data, selectedTerm: { label: selectedTerm.term_name, value: selectedTerm.term_id, active: (selectedTerm.term_active == 1) ? true : false } })
      })
      .catch((err) => {
        if (axios.isCancel(err)) {

        } else {
          toast.error(
            'Something went wrong while fetching chats and contacts!'
          );

        }
      });
  }

  setStateUserTimeline = (field, value) => {
    if (this.setStateTimer) {
      clearTimeout(this.setStateTimer)
    }
    this.setStateTimer = setTimeout(() => {
      this.setState({ user_timeline: { ...this.state.user_timeline, [field]: value } }, () => {
        if (field == 'class_ids') {
          // FETCH CHILDREN OF THIS CLASS
          this.getClassesChildren((this.state.user_timeline.class_ids.value) ? [this.state.user_timeline.class_ids] : [], 'newTimeline')

          this.setState({ user_timeline_tagged: [] })
          // FETCH ALBUMS IN CASE OF CHANGE CLASS AND UT_TYPE = MEDIA
          if (this.state.userTimelineModal.user_timeline.ut_type == 'Media') {
            this.getAlbums(this.state.user_timeline.class_ids.value)
          }
          else if (this.state.userTimelineModal.user_timeline.ut_type == 'Assessment') {
            let agegroupIndex = this.state.assessmentMenuData.agegroup.findIndex((v, i) => value.agegroup_id == v.agegroup_id)
            // 
            this.setState({ assessmentMenu: { ...this.state.assessmentMenu, agegroupIndex: agegroupIndex } })
          }
        }
        this.checkCompletedFormSections()
      });
    }, 100);

  };

  setStateUserTimelineMetadata = (field, value) => {
    this.setState({ user_timeline_metadata: { ...this.state.user_timeline_metadata, [field]: value } }, () => {
      this.checkCompletedFormSections()
    });
  };

  /**
   * Preview Upload File
   * @returns
   */
  readFile = (selectedFile) => {
    return new Promise(function (resolve, reject) {
      let reader = new FileReader();
      const allowedFiles = ['application/pdf', 'image/jpeg', 'image/jpg', 'image/png', 'audio/x-m4a', 'audio/mp3', 'audio/mp4'];
      const allowedFilesDisplay = ['PDF', 'Image'];
      const allowedSize = 5000000;

      if (selectedFile.size) {
        // 
        if (selectedFile.size >= allowedSize) {
          toast.error(t('Max file size allowed is ' + allowedSize / 1000000 + ' MB!'));
          resolve({ type: 'error' });
          return;
        } else if (!allowedFiles.includes(selectedFile.type)) {
          toast.error(t('Allowed file type are ' + allowedFilesDisplay.toString()));
          resolve({ type: 'error' });
          return;
        } else {
          if (selectedFile.type.includes('image')) {
            reader.readAsDataURL(selectedFile);
          } else if (selectedFile.type.includes('audio')) {
            reader.readAsArrayBuffer(selectedFile);
          } else {
            reader.readAsText(selectedFile);
          }
        }

        reader.onload = async (readerEvent) => {
          resolve({ type: 'success', data: selectedFile, url: readerEvent.target.result });
        };
        reader.onerror = (error) => {
          resolve({ type: 'error' });
        };
      } else {
        resolve({ type: 'error' });
      }
    });
  };
  addAttachment = (e) => {
    // 
    const selectedFiles = e.target.files;
    let allFiles = [];

    for (let i = 0; i < selectedFiles.length; i++) {
      allFiles.push(this.readFile(selectedFiles[i]));
    }

    Promise.all(allFiles).then((values) => {
      let attachments = e.target.multiple ? this.state[e.target.name] : null;
      let selectedFiles = values;
      selectedFiles.map((v, i) => {
        if (v.data.type) {
          if (e.target.multiple) {
            attachments.push({ url: v.url, data: v.data });
          } else {
            attachments = { url: v.url, data: v.data };
          }
        }
      });
      // 
      if (e.target.name == 'survey_q_opt_attachment') {
        let SurveyQuestionOptions = this.state.SurveyQuestionOptions
        SurveyQuestionOptions[e.target.attributes.survey_question_option_index.value].survey_q_opt_attachment = attachments
        this.setState({ SurveyQuestionOptions });
      } else {

        this.setState({ [e.target.name]: attachments, dragAreaColor: { ...this.state.dragAreaColor, [e.target.name]: brand_colors[this.props.defaultTheme.theme_id].color18 } }, () => this.checkCompletedFormSections());
      }
    });
  };

  extractClassSubjects = () => {
    let class_ids = this.state.user_timeline.class_ids
    // 
    if (class_ids.value) {
      let classData = this.props.authData.classes.find((v, i) => v.class.class_id == class_ids.value)
      if (classData != undefined) {
        return classData.class_material.map((v) => {
          return { value: v.classmaterial_id, label: v.classmaterial_name, picture: null, center_id: v.center_id }
        })
      }
      return []
    }
    return []
  }

  saveUserTimeline = async () => {

    this.setState({
      userTimelineModal: {
        ...this.state.userTimelineModal,
        saving: true
      }
    })
    if (this.state.userTimelineModal.saving) {
      return
    }

    let user_timeline = JSON.parse(JSON.stringify(this.state.user_timeline))
    let user_timeline_metadata = JSON.parse(JSON.stringify(this.state.user_timeline_metadata))
    let user_timeline_tagged = this.state.user_timeline_tagged
    let userTimelineModal = this.state.userTimelineModal


    let { ut_attachment_1, ut_attachment_2, user_timeline_media } = this.state

    user_timeline.class_ids = [user_timeline.class_ids.value]
    if (user_timeline_metadata.album_id) {
      user_timeline_metadata.album_id = user_timeline_metadata.album_id.value
    }
    user_timeline.ut_type = userTimelineModal.user_timeline.ut_type
    user_timeline_metadata.roles = user_timeline_metadata.roles.map((v, i) => v.value)
    user_timeline_metadata.term_ids = user_timeline_metadata.term_ids.map((v, i) => v.value)
    user_timeline_metadata.class_ids = user_timeline_metadata.class_ids.map((v, i) => v.value)
    user_timeline_metadata.start_datetime = (user_timeline_metadata.start_datetime) ? moment(user_timeline_metadata.start_datetime).format('YYYY-MM-DD HH:mm:ss') : null
    user_timeline_metadata.end_datetime = (user_timeline_metadata.end_datetime) ? moment(user_timeline_metadata.end_datetime).format('YYYY-MM-DD HH:mm:ss') : null
    user_timeline_metadata.taken_later_date = (user_timeline_metadata.taken_later_date) ? moment(user_timeline_metadata.taken_later_date).format('YYYY-MM-DD') : null
    user_timeline_metadata.classmaterial_id = (user_timeline_metadata.classmaterial_id) ? user_timeline_metadata.classmaterial_id.value : null
    user_timeline.ut_type_table_id = (user_timeline.ut_type_table_id) ? user_timeline.ut_type_table_id.value : null

    let fData = new FormData()
    fData.append('center_id', this.props.authData.centerData.center_id)
    fData.append('class_id', user_timeline.class_ids[0])
    fData.append('user_timeline', JSON.stringify(user_timeline))
    fData.append('user_timeline_metadata', JSON.stringify(user_timeline_metadata))
    fData.append('user_timeline_tagged', JSON.stringify(user_timeline_tagged.map((v, i) => { return { child_id: v.value, parent_id: v.parent_id, child_name: v.label } })))
    fData.append('ut_id', user_timeline.ut_id)
    fData.append('ut_type', userTimelineModal.user_timeline.ut_type)
    fData.append('user_id', this.props.authData.loginData.user_id)
    fData.append('center_timezone', this.props.authData.centerData.center_timezone)
    fData.append('employee_id', this.props.authData.userData.employee_id)
    fData.append('employee_photo_approve', this.props.authData.userData.employee_photo_approve)
    if (ut_attachment_1) {
      fData.append('ut_attachment_1', ut_attachment_1.data)
    }
    if (ut_attachment_2) {
      if (userTimelineModal.user_timeline.ut_type == 'Assignment') {
        // AUDIO
        fData.append('ut_attachment_2', await this.readAudioFileData(ut_attachment_2))
      } else {
        fData.append('ut_attachment_2', ut_attachment_2.data)
      }
    }
    if (user_timeline_media.length > 0) {
      user_timeline_media.map((v, i) => {
        if (v.data) {
          fData.append('user_timeline_media[]', v.data)
        }
      })
    }

    if (userTimelineModal.user_timeline.ut_type == 'Assessment') {
      fData.append('assess_aspect', JSON.stringify(this.state.assess_aspect))
      fData.append('assess_curr', JSON.stringify(this.state.assess_curr))
    }
    if (userTimelineModal.user_timeline.ut_type == 'Poll') {
      fData.append('PollOptions', JSON.stringify(this.state.PollOptions))
    }
    if (userTimelineModal.user_timeline.ut_type == 'Survey') {
      let SurveyQuestions = []
      this.state.SurveyQuestions.map((v, i) => {
        v['SurveyQuestionOptions'] = []
        let no = 0
        this.state.SurveyQuestionOptions.map((vv, ii) => {
          if (vv.survey_q_id == v.survey_q_id) {
            if (vv.survey_q_opt_attachment) {
              fData.append('survey_q_opt_attachment[' + i + '][' + no + ']', vv.survey_q_opt_attachment.data)
            }
            v['SurveyQuestionOptions'].push({
              survey_q_opt_id: vv.survey_q_opt_id,
              survey_q_opt_desc: vv.survey_q_opt_desc,
              survey_q_id: vv.survey_q_id,
            })
            no++
          }
        })
        SurveyQuestions.push(v)
      })
      fData.append('SurveyQuestions', JSON.stringify(SurveyQuestions))
    }


    // 
    // return
    if (this.saveUserTimelineReq != undefined) {
      this.saveUserTimelineReq.abort();
    }
    this.saveUserTimelineReq = new AbortController();

    let URL = 'user-timeline/save-user-timeline'
    if (userTimelineModal.user_timeline.ut_type == 'Assessment') {
      URL = 'assesslog/save-assesslog'
    }
    else if (userTimelineModal.user_timeline.ut_type == 'Policy') {
      URL = 'center/save-center-policies'
    }

    axios.post(apiURL + URL, fData, {
      params: {
        auth_key: this.props.authData.loginData.auth_key,
        appname: process.env.REACT_APP_NAME,
      },
      signal: this.saveUserTimelineReq.signal,
    })
      .then((res) => {
        // 
        if (res.data.type) {


          // RESET FROM
          this.setState({
            userTimelineModal: {
              ...this.state.userTimelineModal,
              show: false,
              user_timeline: {},
              saving: false
            },
            assessmentMenu: {
              ...this.state.assessmentMenu,
              progassIndex: 0,
              learnareaIndex: 0,
              lrnaspectIndex: 0,
              agegroupIndex: 0,
            },
            formSectionActive: {
              parent: '1',
              child: '1.1',
            },
            formSections: formSections,
            user_timeline: {
              ut_id: null,
              ut_title: '',
              ut_description: '',
              ut_url: '',
              ut_pintotop: 0,
              ut_publish_on: null,
              ut_publish_status: 1,
              ut_type: null,
              utc_type: 'Teacher Parent',
              class_ids: {},
              ut_type_table_id: null,
              ut_type_table: null,
            },
            ut_attachment_1: null,
            ut_attachment_2: null,
            user_timeline_tagged: [],
            user_timeline_metadata: {
              start_datetime: null,
              end_datetime: null,
              fees: '',
              album_id: '',
              classmaterial_id: '',
              description2: '',
              assesslog_next_steps: '',
              taken_to_hospital: false,
              taken_to_hospital_name: '',
              taken_immediately: false,
              taken_later_date: '',
              allow_resubmit: false,
              roles: [],
              class_ids: [],
              term_ids: [],
            },
            user_timeline_media: [],
            assess_aspect: [],
            assess_curr: [],
            PollOptions: [],
            SurveyQuestions: [],
            SurveyQuestionOptions: [],
            getUserTimelineData: { ...this.state.getUserTimelineData, data: [], refreshing: false, finished: false, offset: 0 }
          }, () => this.getUserTimeline())

          toast.success(t(res.data.message));

        } else {
          this.setState({
            userTimelineModal: {
              ...this.state.userTimelineModal,
              saving: false
            }
          })
          if (res.data.data != null) {
            handleFormErrors(res.data.data);
          } else {
            toast.error(t(res.data.message));
          }
        }
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          // 
        } else {
          toast.error(
            'Something went wrong while saving ' + userTimelineModal.user_timeline.name
          );

        }
        this.setState({
          userTimelineModal: {
            ...this.state.userTimelineModal,
            saving: false
          }
        })
      });

  }

  resetUserTimelineForm = () => {
    this.setState({
      formSectionActive: {
        parent: '1',
        child: '1.1',
      },
      formSections,
      user_timeline: {
        ut_id: null,
        ut_title: '',
        ut_description: '',
        ut_url: '',
        ut_pintotop: 0,
        ut_publish_on: null,
        ut_publish_status: 1,
        ut_type: null,
        utc_type: 'Teacher Parent',
        class_ids: {},
        ut_type_table_id: null,
        ut_type_table: null,
      },
      ut_attachment_1: null,
      ut_attachment_2: null,
      user_timeline_tagged: [],
      user_timeline_metadata: {
        start_datetime: null,
        end_datetime: null,
        fees: '',
        album_id: '',
        classmaterial_id: '',
        description2: '',
        assesslog_next_steps: '',
        taken_to_hospital: false,
        taken_to_hospital_name: '',
        taken_immediately: false,
        taken_later_date: '',
        allow_resubmit: false,
        roles: [],
        class_ids: [],
        term_ids: [],
      },
      user_timeline_media: [],
      assess_aspect: [],
      assess_curr: [],
      PollOptions: [],
      SurveyQuestions: [],
      SurveyQuestionOptions: [],
    })
  }

  editUserTimeline = (ut_id) => {
    // 

    if (this.editUserTimelineReq) {
      this.editUserTimelineReq.abort();
    }

    this.editUserTimelineReq = new AbortController();
    GET('user-timeline/edit-user-timeline', {
      params: {
        ut_id: ut_id,
      },
      signal: this.editUserTimelineReq.signal,
    })
      .then((res) => {
        // 
        if (res.data) {

          let user_timeline = res.data.user_timeline
          let user_timeline_metadata = res.data.user_timeline_metadata
          let user_timeline_tagged = res.data.user_timeline_tagged
          let user_timeline_tagged_users = res.data.user_timeline_tagged_users
          let class_id = JSON.parse(user_timeline.class_ids)[0]
          let PollOptions = []
          let SurveyQuestions = []
          let SurveyQuestionOptions = []
          let assess_aspect = []
          let assess_curr = []
          let agegroupIndex = 0

          let class_ids = this.props.authData.classes.find((v, i) => {
            if (class_id == v.class.class_id) {
              return { label: v.class.class_theme, value: v.class.class_id, picture: v.class.class_logo, center_id: v.class.center_id, agegroup_id: v.class.agegroup_id }
            }
          })

          if (user_timeline.ut_type == 'Academic Activity') {
            let selectedActivity = this.state.allAcademicActivities.map((v, i) => {
              if (v.aa_id == user_timeline.ut_type_table_id && user_timeline.ut_type_table == 'academic_activities') {
                return { label: v.aa_name, value: v.aa_id, center_id: v.center_id, picture: v.aa_image }
              }
            })
            user_timeline.ut_type_table_id = selectedActivity
          }
          else if (user_timeline.ut_type == 'Assignment' || user_timeline.ut_type == 'Agenda') {
            let subjects = []
            let classData = this.props.authData.classes.find((v, i) => v.class.class_id == class_id)
            if (classData != undefined) {
              subjects = classData.class_material.map((v) => {
                return { value: v.classmaterial_id, label: v.classmaterial_name, picture: null, center_id: v.center_id }
              })
            }
            if (user_timeline_metadata.classmaterial_id) {
              user_timeline_metadata.classmaterial_id = subjects.find((v, i) => v.value == user_timeline_metadata.classmaterial_id)
            }
          }
          else if (user_timeline.ut_type == 'Media') {
            this.getAlbums(class_id)
            if (user_timeline_metadata.album_id) {
              user_timeline_metadata.album_id = { label: user_timeline_metadata.album.album_name, value: user_timeline_metadata.album.album_id, picture: user_timeline_metadata.album.album_picture, center_id: user_timeline_metadata.album.center_id }
            }
          }
          else if (user_timeline.ut_type == 'Incident Report') {
            user_timeline_metadata.taken_to_hospital = (user_timeline.nursereport.taken_to_hospital == 1) ? true : false;
            user_timeline_metadata.taken_to_hospital_name = user_timeline.nursereport.taken_to_hospital_name;
            user_timeline_metadata.taken_immediately = (user_timeline.nursereport.taken_immediately == 1) ? true : false;
            user_timeline_metadata.taken_later_date = (user_timeline.nursereport.taken_later_date) ? moment(user_timeline.nursereport.taken_later_date) : null;
          }
          else if (user_timeline.ut_type == 'Assessment') {
            assess_aspect = user_timeline.assess_aspect
            assess_curr = user_timeline.assess_curr
            agegroupIndex = this.state.assessmentMenuData.agegroup.findIndex((v, i) => class_ids.agegroup_id == v.agegroup_id)
          }
          else if (user_timeline.ut_type == 'Poll') {
            user_timeline_metadata.allow_resubmit = (user_timeline.poll.poll_allow_resubmit == 1) ? true : false
            PollOptions = user_timeline.poll.poll_options

            let roles = []
            if (user_timeline_tagged.length > 0) {
              roles.push({ value: 6, label: 'Parent/Student' })
            }
            if (user_timeline_tagged_users.length > 0) {
              if (user_timeline_tagged_users.find((v) => v.role_id == 3)) {
                roles.push({ value: 3, label: 'Teachers' })
              }
              if (user_timeline_tagged_users.find((v) => v.role_id == 15)) {
                roles.push({ value: 15, label: 'Curriculum Coordinator' })
              }
              if (user_timeline_tagged_users.find((v) => v.role_id == 4)) {
                roles.push({ value: 4, label: 'Assistant' })
              }
            }
            user_timeline_metadata.roles = roles
            user_timeline_metadata.class_ids = []
            JSON.parse(user_timeline.class_ids).map((v, i) => {
              let findClass = this.props.authData.classes.find((vv, ii) => vv.class.class_id == v)
              if (findClass) {
                user_timeline_metadata.class_ids.push({ label: findClass.class.class_theme, value: findClass.class.class_id, picture: findClass.class.class_logo, center_id: findClass.class.center_id, agegroup_id: findClass.class.agegroup_id })
              }
            })
          }
          else if (user_timeline.ut_type == 'Survey') {
            user_timeline_metadata.allow_resubmit = (user_timeline.survey.survey_allow_resubmit
              == 1) ? true : false

            SurveyQuestions = user_timeline.survey.survey_questions.map((v, i) => {
              v.survey_q_comment_allow = (v.survey_q_comment_allow == 1) ? true : false
              v.survey_q_answers = (v.survey_q_answers != '' && v.survey_q_answers != 'null' && v.survey_q_answers != null) ? JSON.parse(v.survey_q_answers) : []
              return v
            })
            SurveyQuestionOptions = user_timeline.survey.survey_question_options.map((v, i) => {
              if (v.survey_q_opt_attachment) {
                v.survey_q_opt_attachment = { data: { type: getExtension(v.survey_q_opt_attachment) }, url: portalURL + user_timeline.center_id + '/survey_question_options/' + v.survey_q_opt_attachment }
              }
              return v
            })

            let roles = []
            if (user_timeline_tagged.length > 0) {
              roles.push({ value: 6, label: 'Parent/Student' })
            }
            if (user_timeline_tagged_users.length > 0) {
              if (user_timeline_tagged_users.find((v) => v.role_id == 3)) {
                roles.push({ value: 3, label: 'Teachers' })
              }
              if (user_timeline_tagged_users.find((v) => v.role_id == 15)) {
                roles.push({ value: 15, label: 'Curriculum Coordinator' })
              }
              if (user_timeline_tagged_users.find((v) => v.role_id == 4)) {
                roles.push({ value: 4, label: 'Assistant' })
              }
            }

            user_timeline_metadata.roles = roles
            user_timeline_metadata.class_ids = []
            JSON.parse(user_timeline.class_ids).map((v, i) => {
              let findClass = this.props.authData.classes.find((vv, ii) => vv.class.class_id == v)
              if (findClass) {
                user_timeline_metadata.class_ids.push({ label: findClass.class.class_theme, value: findClass.class.class_id, picture: findClass.class.class_logo, center_id: findClass.class.center_id, agegroup_id: findClass.class.agegroup_id })
              }
            })
          }

          if (user_timeline.ut_type == 'Poll' || user_timeline.ut_type == 'Survey') {
            user_timeline.class_ids = {}
          } else {
            user_timeline.class_ids = { label: class_ids.class.class_theme, value: class_ids.class.class_id, picture: class_ids.class.class_logo, center_id: class_ids.class.center_id, agegroup_id: class_ids.class.agegroup_id }
          }

          if (user_timeline_metadata) {

            user_timeline_metadata.start_datetime = (user_timeline_metadata.start_datetime) ? moment(user_timeline_metadata.start_datetime, "YYYY-MM-DD HH:mm:ss") : null
            user_timeline_metadata.end_datetime = (user_timeline_metadata.end_datetime) ? moment(user_timeline_metadata.end_datetime, "YYYY-MM-DD HH:mm:ss") : null
            user_timeline.ut_publish_status = (user_timeline.ut_publish_status == 1) ? true : false

          }

          // POPULATE FROM
          this.setState({
            userTimelineModal: {
              ...this.state.userTimelineModal,
              show: true,
              user_timeline: defaultTimelineTypes.find((v, i) => v.ut_type == user_timeline.ut_type)
            },
            assessmentMenu: {
              ...this.state.assessmentMenu,
              progassIndex: 0,
              learnareaIndex: 0,
              lrnaspectIndex: 0,
              agegroupIndex: agegroupIndex,
            },
            formSectionActive: {
              parent: '1',
              child: '1.1',
            },
            user_timeline: {
              ut_id: null,
              ut_title: '',
              ut_description: '',
              ut_url: '',
              ut_pintotop: 0,
              ut_publish_on: null,
              ut_publish_status: 1,
              ut_type: null,
              utc_type: 'Teacher Parent',
              class_ids: {},
              ut_type_table_id: null,
              ut_type_table: null,
              ...user_timeline,
              // class_ids: class_ids[0],
            },
            ut_attachment_1: (user_timeline.ut_attachment_1) ? { url: portalURL + user_timeline.center_id + '/user_timeline/' + user_timeline.ut_attachment_1, data: { type: getExtension(user_timeline.ut_attachment_1) } } : null,
            ut_attachment_2: (user_timeline.ut_attachment_2) ? { url: portalURL + user_timeline.center_id + '/user_timeline/' + user_timeline.ut_attachment_2, data: { type: getExtension(user_timeline.ut_attachment_2) } } : null,
            user_timeline_tagged: user_timeline_tagged.map((v, i) => { return { label: v.child_name, value: v.child_id, picture: v.picture, center_id: v.center_id, parent_id: v.parent_id } }),
            user_timeline_metadata: {
              start_datetime: null,
              end_datetime: null,
              fees: '',
              album_id: '',
              classmaterial_id: '',
              description2: '',
              assesslog_next_steps: '',
              taken_to_hospital: false,
              taken_to_hospital_name: '',
              taken_immediately: false,
              taken_later_date: '',
              allow_resubmit: false,
              roles: [],
              class_ids: [],
              term_ids: [],
              ...user_timeline_metadata
            },
            user_timeline_media: res.data.user_timeline_media.map((v, i) => { return { data: { type: 'image' }, url: portalURL + v.center_id + '/user_timeline_media/' + v.utm_name, utm_id: v.utm_id } }),
            assess_aspect: assess_aspect,
            assess_curr: assess_curr,
            PollOptions: PollOptions,
            SurveyQuestions: SurveyQuestions,
            SurveyQuestionOptions: SurveyQuestionOptions,
          }, () => this.checkCompletedFormSections())
        }
      })
      .catch((err) => {
        if (axios.isCancel(err)) {

        } else {
          toast.error(
            'Something went wrong while fetching!'
          );

        }
      });


  }

  getClassesChildren = (classes, actionType) => {
    if (classes.length <= 0) {
      if (actionType == 'filter') {
        this.setState({ filter: { ...this.state.filter, allStudents: [] } })
      }
      else if (actionType == 'newTimeline') {
        this.setState({ allStudents: [] })
      }
      return
    } else if (actionType == 'newTimeline' && this.state.selectedTerm.value == undefined) {
      this.setState({ allStudents: [] })
    }
    const fData = new FormData()
    fData.append('center_id', this.props.authData.centerData.center_id)
    fData.append('class_ids', JSON.stringify(classes.map((v, i) => v.value)))
    if (actionType == 'newTimeline') {
      fData.append('term_id', this.state.selectedTerm.value)
    }
    if (typeof this.getClassesChildrenRequest != typeof undefined) {
      this.getClassesChildrenRequest.abort();
    }

    this.getClassesChildrenRequest = new AbortController();
    axios.post(apiURL + 'children/get-classes-children', fData, {
      params: {
        auth_key: this.props.authData.loginData.auth_key,
        appname: process.env.REACT_APP_NAME,
      },
      signal: this.getClassesChildrenRequest.signal,
    })
      .then((res) => {
        // 
        if (actionType == 'filter') {
          this.setState({ filter: { ...this.state.filter, allStudents: res.data } })
        }
        else if (actionType == 'newTimeline') {
          this.setState({ allStudents: res.data })
        }
      })
      .catch((err) => {
        if (axios.isCancel(err)) {

        } else {
          toast.error(
            'Something went wrong while fetching chats and contacts!'
          );

        }
      });
  }


  renderAssessmentList = () => {

    let render_assess_curr = this.state.activityData.academic_activities.curriculums
    let render_assess_aspect = []
    render_assess_curr.map((v, i) => {
      let findAssessAspect = render_assess_aspect.find((vv, ii) => vv.lrnaspect_id == v.lrnaspect_id && vv.agegroup_id == v.agegroup_id)
      if (findAssessAspect == undefined) {
        render_assess_aspect.push({
          lrnaspect_id: v.lrnaspect_id,
          agegroup_id: v.agegroup_id,
        })
      }
    })

    let assessmentMenuData = this.state.assessmentMenuData

    return <div>
      {render_assess_aspect.map((v, i) => {
        let activeLrnaspectData = assessmentMenuData.lrnaspect.find((vv, ii) => vv.lrnaspect_id == v.lrnaspect_id)
        let activeAgegroupData = assessmentMenuData.agegroup.find((vv, ii) => vv.agegroup_id == v.agegroup_id)
        let activelLearnareaData = assessmentMenuData.learnarea.find((vv, ii) => vv.learnarea_id == activeLrnaspectData.learnarea_id)
        let activelProgassData = assessmentMenuData.progass.find((vv, ii) => vv.progass_id == activelLearnareaData.progass_id)
        let assessmentFind = assessmentMenuData.assessment.find((vv, ii) => vv.assmt_id == v.assagegroup_value)

        return <div key={i}>
          <div style={{ margin: '5px 0 5px 0', backgroundColor: brand_colors[this.props.defaultTheme.theme_id].color19, padding: '5px 10px 5px 10px' }}>

            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', justifyContent: 'space-between', }}>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', }}>
                <div>
                  <span style={{ fontSize: 14, fontWeight: 'bold', color: brand_colors[this.props.defaultTheme.theme_id].color1 }}>{activelProgassData.progass_code}</span>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <div style={{ margin: '0 10px' }}>
                    <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: 12, color: brand_colors[this.props.defaultTheme.theme_id].color4 }} />
                  </div>
                  <span style={{ fontSize: 14, fontWeight: 'bold', color: activeAgegroupData.agegroup_color }}>{activeAgegroupData.agegroup_code}</span>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <div style={{ margin: '0 10px' }}>
                    <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: 12, color: brand_colors[this.props.defaultTheme.theme_id].color4 }} />
                  </div>
                  <span style={{ fontSize: 14, fontWeight: 'bold', color: activelLearnareaData.learnarea_color }}>{activelLearnareaData.learnarea_code}</span>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <div style={{ margin: '0 10px' }}>
                    <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: 12, color: brand_colors[this.props.defaultTheme.theme_id].color4 }} />
                  </div>
                  <span style={{ fontSize: 14, fontWeight: 'bold', color: brand_colors[this.props.defaultTheme.theme_id].color1 }}>{activeLrnaspectData.lrnaspect_code}</span>
                </div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>
                {(assessmentFind) ? <span style={{ fontSize: 14, color: (assessmentFind.assmt_color) ? assessmentFind.assmt_color : brand_colors[this.props.defaultTheme.theme_id].color1, fontWeight: 'bold' }}>{assessmentFind.assmt_code}</span> : null}
              </div>
            </div>
          </div>
          <div>
            {render_assess_curr.map((vv, ii) => {
              if (vv.lrnaspect_id == activeLrnaspectData.lrnaspect_id && vv.agegroup_id
                == activeAgegroupData.agegroup_id) {

                let find_assess_curr = this.state.assess_curr.find((vvv, iii) => vvv.curriculum_id == vv.curriculum_id)
                let assessmentFind2 = null
                if (find_assess_curr) {
                  assessmentFind2 = assessmentMenuData.assessment.find((vvv, ii) => vvv.assmt_id == find_assess_curr.asscurr_value)
                }

                // 
                return <div key={ii} style={{ paddingVertical: 5, position: 'relative', padding: '5px 10px 5px 10px', borderBottom: '1px solid ' + brand_colors[this.props.defaultTheme.theme_id].color18 }}>

                  <div style={{ alignItems: 'center', justifyContent: 'space-between', display: 'flex', flexDirection: 'row' }}>
                    <Col md="4" style={{}}>
                      {/* <span style={{ fontSize: 14, color: (assessmentFind2.assmt_color) ? assessmentFind2.assmt_color : color1, fontWeight: 'bold' }}>{assessmentFind2.assmt_code}</span> */}
                      <Form.Group>
                        <Select
                          options={assessmentMenuData.assessment.map((v, i) => { return { label: v.assmt_code, value: v.assmt_id, assmt_color: v.assmt_color } })}
                          onChange={(event) => {
                            this.selectAssessment(event.value, vv.curriculum_id, activeLrnaspectData.lrnaspect_id, activeAgegroupData.agegroup_id, activelLearnareaData.learnarea_id, activelProgassData.progass_id)
                          }}
                          value={assessmentFind2 ? { label: assessmentFind2.assmt_code, value: assessmentFind2.assmt_id, assmt_color: assessmentFind2.assmt_color } : ''}
                          menuPortalTarget={document.body}
                          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999, }), control: (base) => ({ ...base, minHeight: 30, height: 30 }) }}
                          formatOptionLabel={option => (
                            <div>
                              <span style={{ color: option.assmt_color, fontSize: 14 }}>{option.label}</span>
                            </div>
                          )}
                        />
                      </Form.Group>
                    </Col>
                    <Col style={{ textAlign: 'right' }}>
                      <span style={{ fontSize: 14, color: brand_colors[this.props.defaultTheme.theme_id].color1, fontWeight: 'bold', opacity: 0.8 }}>#{vv.curriculum_ref_num}</span>
                    </Col>
                  </div>
                  <div style={{}}>
                    <span style={{ fontSize: 14, color: brand_colors[this.props.defaultTheme.theme_id].color9, opacity: 0.8 }}>{vv.curriculum_code}</span>
                  </div>
                </div>
              }
            })}
          </div >
        </div >
      })}
    </div >
  }

  showDelConfirm = (id) => {
    this.setState({
      delID: id,
      delAlert: true
    });
  }


  // deleteUserTimeline = () => {
  //   const { delID } = this.state;
  //   alert('UT ID: ' + delID + '\n⚠️ Delete API yet to be configured!')
  //   this.setState({ delAlert: false });
  // }
  deleteUserTimeline = (ut) => {
    let role_id = this.props.authData.loginData.role_id
    let user_id = this.props.authData.loginData.user_id
    if ([1, 8, 12, 5, 22].includes(role_id) || ut.created_by == user_id) {
      if (![''].includes(ut.ut_type)) {

        this.setState({
          deleteModal: {
            ...this.state.deleteModal, show: true, id: ut.ut_id, title: 'Are you sure?', desc: 'It will remove all the related things to this posts also!', action: async () => {
              if (this.deleteUserTimelineReq) {
                this.deleteUserTimelineReq.abort()
              }
              this.deleteUserTimelineReq = new AbortController()
              let res = await GET('user-timeline/delete-user-timeline', {
                params: { ut_id: ut.ut_id },
                signal: this.deleteUserTimelineReq.signal
              })
              let resData = res.data
              let getUserTimelineData = JSON.parse(JSON.stringify(this.state.getUserTimelineData))
              if (resData.type) {
                getUserTimelineData.data = getUserTimelineData.data.filter((v, i) => v.ut_id != ut.ut_id)
                getUserTimelineData.time = moment()
              }
              this.setState({ getUserTimelineData, deleteModal: { ...this.state.deleteModal, show: false } })
              toast[resData.messageType](t(resData.message));
            }
          }
        })

      } else {
        toast.error(t('Delete not allowed for this Post contact admin!'));
      }
    } else {
      toast.error(t('Delete not allowed for you, contact admin!'));
    }
  }

  renderForm = () => {
    const { user_timeline, user_timeline_metadata, user_timeline_media, user_timeline_tagged, ut_attachment_1, ut_attachment_2 } = this.state
    const dir = this.props.defaultLanguage.lang_orientation
    const { userTimelineModal, formSections, formSectionActive } = this.state
    const { assessmentMenu, assessmentMenuData } = this.state
    let activeUserTimeline = userTimelineModal.user_timeline

    {/* NEW TIMELINE */ }
    return <Offcanvas show={userTimelineModal.show} style={{ height: '100%' }} placement='bottom' onHide={userTimelineModal.toggleModal}>
      <Offcanvas.Header id='iedu-header' style={{ marginTop: '1rem', marginRight: '1rem', marginLeft: '1rem', marginBottom: 0 }}>
        <div className='d-flex flex-row  align-items-center justify-content-center'>
          <span className='d-flex align-items-center justify-content-center btn-back-white' onClick={userTimelineModal.toggleModal}>
            <FontAwesomeIcon icon={(dir == 1) ? faAngleRight : faAngleLeft} color={brand_colors[this.props.defaultTheme.theme_id].color9} />
          </span>
          <Offcanvas.Title>
            <div className='d-flex flex-row align-items-center justify-content-center title-back-color8' >
              <div className='avatar'>
                <img src={userTimelineModal.user_timeline.picture} width="38" height="38" />
              </div>
              <span style={{ fontSize: 20, margin: '0 10px' }}>{userTimelineModal.user_timeline.name}</span>
            </div>
          </Offcanvas.Title>
        </div>
        <div className='custom-styled-form'>
          <div className='d-flex flex-row justify-content-between align-items-end'>
            {/* Publish Later */}
            {this.state.userTimelineModal.user_timeline.ut_type == 'Assessment' &&
              <div style={{ margin: '0 20px', backgroundColor: brand_colors[this.props.defaultTheme.theme_id].color8, textAlign: 'center', padding: '5px 10px', borderRadius: 20 }}>
                <span style={{ fontSize: 13, color: brand_colors[this.props.defaultTheme.theme_id].color9, opacity: 0.7, fontWeight: 'bold' }}>
                  {t('Share with parent')}
                </span>
                <Form.Check
                  type="switch"
                  value={1}
                  checked={user_timeline.ut_publish_status}
                  onChange={(event) => {
                    // 

                    this.setState({ user_timeline: { ...this.state.user_timeline, ['ut_publish_on']: (event.target.checked) ? null : moment().add(10, 'years').toDate(), ut_publish_status: event.target.checked } })
                  }}
                />
              </div>
            }
            {this.state.userTimelineModal.user_timeline.ut_type != 'Assessment' &&
              <>
                <div style={{ margin: '0 20px', backgroundColor: brand_colors[this.props.defaultTheme.theme_id].color8, textAlign: 'center', padding: '5px 10px', borderRadius: 20 }}>
                  <span style={{ fontSize: 13, color: brand_colors[this.props.defaultTheme.theme_id].color9, opacity: 0.7, fontWeight: 'bold' }}>
                    {t('Share Now')}
                  </span>
                  <Form.Check
                    type="switch"
                    value={1}
                    checked={(user_timeline.ut_publish_status)}
                    onChange={(event) => {
                      // 

                      this.setState({ user_timeline: { ...this.state.user_timeline, ['ut_publish_on']: (event.target.checked) ? null : moment().add(1, 'days').toDate(), ut_publish_status: event.target.checked } })
                    }}
                  />
                </div>
                <div style={{ margin: '0 10px' }}>
                  <Form.Group>
                    <Form.Label>
                      <img src={imgDescription} />
                      {t('Publish Later')}
                    </Form.Label>
                    <Flatpickr
                      className='form-control'
                      data-enable-time
                      value={(user_timeline.ut_publish_on) ? moment(user_timeline.ut_publish_on).format('DD-MM-YYYY HH:mm:ss') : null}
                      // value={(user_timeline.ut_publish_on) ? moment(user_timeline.ut_publish_on).format('DD-MM-YYYY HH:mm:ss') : null}
                      onClose={([date]) => {
                        // 
                        if (date) {
                          this.setState({ user_timeline: { ...this.state.user_timeline, ['ut_publish_on']: date, ut_publish_status: 0 } })
                        } else {
                          this.setState({ user_timeline: { ...this.state.user_timeline, ['ut_publish_on']: null, ut_publish_status: 1 } })
                        }
                      }}
                      options={{
                        dateFormat: 'd-m-Y H:i:S'
                      }}
                    />
                  </Form.Group>
                </div>
              </>
            }

            <Button disabled={this.state.userTimelineModal.saving} variant={user_timeline.ut_publish_status ? 'success' : 'warning'} onClick={() => setTimeout(() => {
              this.saveUserTimeline()
            }, 300)}>
              {(user_timeline.ut_publish_status) ? t('Publish Now') : t('Publish Later')} {this.state.userTimelineModal.saving ? <Spinner as='span' animation='grow' size='sm' role='status' aria-hidden='true' /> : ''}
            </Button>
          </div>
        </div>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <div
          className={'d-flex flex-row grey-section'}
          style={{
            overflow: 'initial',
            backgroundImage: 'url(https://academic.educore.io/static/media/pegboard-bg.9b84e4936acef29bbcd9.png)',
            backgroundRepeat: 'repeat',
            backgroundPosition: '10px 12px',
            overflow: 'hidden',
          }}
        >
          <div style={{ backgroundColor: brand_colors[this.props.defaultTheme.theme_id].color8, marginLeft: 10, marginRight: 10, borderRadius: '20px', width: '250px', height: '100%', top: '5px', position: 'sticky' }}>
            <div style={{ paddingRight: '1.5rem', paddingBottom: '1.5rem', paddingTop: '1rem' }}>
              {formSections.map((v, i) => {

                let allowRender = false
                if (v.include_ut_type.includes(activeUserTimeline.ut_type) || v.include_ut_type.includes('*')) {
                  allowRender = true
                }
                if (!allowRender) {
                  return null
                }

                return (
                  <div key={i}>
                    <div className='d-flex flex-row align-items-center' style={{ cursor: 'pointer', borderRadius: '20px', paddingLeft: '5px', paddingRight: '5px', zIndex: 1, position: 'relative' }} onClick={() => this.changeToFromSection(v.uid, v.uid + '.1')}>
                      <span style={{ fontSize: 18, fontWeight: '600', color: brand_colors[this.props.defaultTheme.theme_id].color9, opacity: 0.7 }}>{v.name}</span>
                    </div>

                    <div style={{ paddingLeft: '1rem', borderLeft: 'solid 1px color18', marginLeft: '1.5rem' }}>
                      {v.data.map((vv, ii) => {

                        let allowRender2 = false
                        if (vv.include_ut_type.includes(activeUserTimeline.ut_type) || vv.include_ut_type.includes('*')) {
                          allowRender2 = true
                        }
                        if (!allowRender2) {
                          return null
                        }

                        return (
                          <div key={ii} className='d-flex flex-row align-items-center' style={{ marginBottom: 5, marginTop: 2, cursor: 'pointer' }} onClick={() => this.changeToFromSection(v.uid, vv.uid)}>
                            <FontAwesomeIcon icon={vv.completed ? faCheckCircle : faCircle} color={vv.completed ? brand_colors[this.props.defaultTheme.theme_id].color4 : brand_colors[this.props.defaultTheme.theme_id].color18} style={{ fontSize: 12, margin: '0 5px' }} />
                            <span style={{ fontSize: 14, color: vv.completed ? brand_colors[this.props.defaultTheme.theme_id].color4 : brand_colors[this.props.defaultTheme.theme_id].color18 }}>{vv.name}</span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <Col className='custom-styled-form hide-scrollbar' style={{ backgroundColor: brand_colors[this.props.defaultTheme.theme_id].color8, marginLeft: 10, marginRight: 10, borderRadius: '20px', padding: '1rem', overflow: 'auto', height: '70vh' }}>

            {/* SECTION 1 */}
            {['Notification', 'Event', 'Newsletter', 'Upload', 'Media', 'Daily Report', 'Note', 'Assignment', 'Online Class', 'Assessment', 'Video', 'Exam', 'Agenda', 'Resources', 'Incident Report', 'Exam Retake', 'Online Homework', 'Academic Activity'].includes(activeUserTimeline.ut_type) && (
              <div id='form_section_1'>
                <div className={'d-flex flex-row align-items-center justify-content-between section-heading'}>
                  <span className='heading'>{'Tag Students'}</span>
                </div>
                <Row>
                  <Col md="4">
                    {/* CLASS */}
                    <div id='form_section_1.1'>
                      <Form.Group className='mb-3'>
                        <Form.Label>{t('Class')}</Form.Label>
                        <Select
                          options={this.props.authData.classes.map((v, i) => { return { label: v.class.class_theme, value: v.class.class_id, picture: v.class.class_logo, center_id: v.class.center_id, agegroup_id: v.class.agegroup_id } })}
                          onChange={(event) => {
                            this.setStateUserTimeline('class_ids', event)
                          }}
                          defaultValue={user_timeline.class_ids}
                          menuPortalTarget={document.body}
                          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                          formatOptionLabel={option => (
                            <div>
                              {option.picture ? <img src={portalURL + option.center_id + '/classes/' + option.picture} style={{ width: 40, height: 40, objectFit: 'cover', marginRight: 5 }} /> : ''}
                              <span style={{ fontSize: 18 }}>{option.label}</span>
                            </div>
                          )}
                        />
                      </Form.Group>
                    </div>
                    {/* Term */}
                    <div>
                      <Form.Group className='mb-3'>
                        <Form.Label>{t('Term')}</Form.Label>
                        <Select
                          options={this.state.allTerms.map((v, i) => { return { label: v.term_name, value: v.term_id, active: (v.term_active == 1) ? true : false } })}
                          onChange={(event) =>
                            this.setState({ selectedTerm: event }, () => {
                              this.getClassesChildren((this.state.user_timeline.class_ids.value) ? [this.state.user_timeline.class_ids] : [], 'newTimeline')
                            })
                          }
                          defaultValue={this.state.selectedTerm}
                          menuPortalTarget={document.body}
                          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                          formatOptionLabel={option => (
                            <div>
                              <span style={{ fontSize: 20 }}>
                                {option.label}
                                {option.active &&
                                  <Badge bg='success'>Active</Badge>
                                }
                              </span>
                            </div>
                          )}
                        />
                      </Form.Group>
                    </div>
                  </Col>
                  <Col >

                    {/* STUDENTS */}
                    <div id='form_section_1.2'>
                      <Form.Group className='mb-3'>
                        <Form.Label>{t('Tag Students')} ({user_timeline_tagged.length})</Form.Label>
                        <ButtonGroup size="sm" className='mb-1' style={{ left: 7, top: 8 }}>
                          <Button variant="primary" onClick={() => {
                            let user_timeline_tagged_new = this.state.allStudents.map((v, i) => { return { label: v.child_name, value: v.child_id, picture: v.picture, center_id: v.center_id, parent_id: v.parent_id } })
                            this.ref_form_section_1_2.current.setValue(user_timeline_tagged_new)
                            this.setState({ user_timeline_tagged: user_timeline_tagged_new }, () => {
                              this.checkCompletedFormSections()
                            })
                          }}>Select All</Button>
                          <Button variant="warning" onClick={() => {
                            this.ref_form_section_1_2.current.setValue([])
                            this.setState({ user_timeline_tagged: [] }, () => this.checkCompletedFormSections())
                          }}>De-Select All</Button>
                        </ButtonGroup>
                        <Select
                          ref={this.ref_form_section_1_2}
                          isMulti={true}
                          closeMenuOnSelect={false}
                          options={this.state.allStudents.map((v, i) => { return { label: v.child_name, value: v.child_id, picture: v.picture, center_id: v.center_id, parent_id: v.parent_id } })}
                          onChange={(event) => {
                            // 
                            if (this.setStateTimer) {
                              clearTimeout(this.setStateTimer)
                            }
                            this.setStateTimer = setTimeout(() => {
                              this.setState({ user_timeline_tagged: event }, () => {
                                if (activeUserTimeline.ut_type == 'Assessment') {
                                  this.getLastOverallAssessmentAnswers()
                                  this.getLastAssessmentAnswers()
                                }
                                this.checkCompletedFormSections()
                              })
                            }, 2000);
                          }
                          }
                          defaultValue={user_timeline_tagged}
                          menuPortalTarget={document.body}
                          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                          formatOptionLabel={option => (
                            <div>
                              {option.picture ? <img src={portalURL + option.center_id + '/children/' + option.picture} style={{ width: 30, height: 30, objectFit: 'cover', marginRight: 5, borderRadius: 10 }} /> : ''}
                              <span style={{ fontSize: 16 }}>{option.label}</span>
                            </div>
                          )}
                        />
                      </Form.Group>
                    </div>
                  </Col>
                </Row>
              </div>
            )}
            {/* SECTION 2 */}
            {['Notification', 'Event', 'Newsletter', 'Upload', 'Media', 'Daily Report', 'Note', 'Assignment', 'Online Class', 'Assessment', 'Video', 'Exam', 'Agenda', 'Resources', 'Incident Report', 'Exam Retake', 'Poll', 'Survey', 'Online Homework', 'Academic Activity'].includes(activeUserTimeline.ut_type) && (
              <div id='form_section_2'>
                <div className={'d-flex flex-row align-items-center justify-content-between section-heading'}>
                  <span className='heading'>{'Form'}</span>
                </div>
                <Row>
                  <Col>
                    {/* ut_title */}
                    {['Notification', 'Event', 'Newsletter', 'Upload', 'Media', 'Daily Report', 'Note', 'Assignment', 'Online Class', 'Assessment', 'Video', 'Exam', 'Agenda', 'Resources', 'Incident Report', 'Exam Retake', 'Poll', 'Survey', 'Online Homework', 'Academic Activity'].includes(activeUserTimeline.ut_type) && (
                      <div id='form_section_2.1'>
                        <Form.Group className='mb-3'>
                          <Form.Label>
                            <img src={imgDescription} />
                            {t('Title')}
                          </Form.Label>
                          <Form.Control type='text' defaultValue={user_timeline.ut_title} onBlur={(event) => this.setStateUserTimeline('ut_title', event.target.value)} />
                        </Form.Group>
                      </div>
                    )}

                    {/* ut_description */}
                    {['Notification', 'Event', 'Newsletter', 'Upload', 'Media', 'Daily Report', 'Note', 'Assignment', 'Online Class', 'Video', 'Exam', 'Agenda', 'Resources', 'Incident Report', 'Exam Retake', 'Poll', 'Survey', 'Online Homework', 'Academic Activity'].includes(activeUserTimeline.ut_type) && (
                      <div md={12} id='form_section_2.2'>
                        <Form.Group className='mb-3'>
                          <Form.Label>
                            <img src={imgDescription} />
                            {t('Description')}
                          </Form.Label>
                          < Form.Control as="textarea" rows={3} defaultValue={user_timeline.ut_description} onBlur={(event) => this.setStateUserTimeline('ut_description', event.target.value)} />
                          {/* <CKEditor style={{ height: 140 }} editor={Editor} data={user_timeline.ut_description} onBlur={(event, editor) => this.setStateUserTimeline('ut_description', editor.getData())} /> */}
                        </Form.Group>
                      </div>
                    )}

                    {/* ut_url */}
                    {['Event', 'Note', 'Resources', 'Assignment', 'Online Class', 'Newsletter'].includes(activeUserTimeline.ut_type) &&
                      <div id='form_section_2.5'>
                        <Form.Group className='mb-3'>
                          <Form.Label>
                            <img src={imgDescription} />
                            {t('URL')}
                          </Form.Label>
                          <Form.Control type='text' defaultValue={user_timeline.ut_url} onBlur={(event) => this.setStateUserTimeline('ut_url', event.target.value)} />
                        </Form.Group>
                      </div>
                    }
                  </Col>
                  <Col md='4'>
                    {/* ut_attachment_1 */}
                    {['Notification', 'Event', 'Newsletter', 'Upload', 'Daily Report', 'Note', 'Assignment', 'Online Class', 'Exam', 'Resources', 'Incident Report', 'Exam Retake', 'Poll', 'Survey', 'Online Homework'].includes(activeUserTimeline.ut_type) &&
                      <div style={{ flexDirection: 'column' }} className='pic-prev d-flex justify-content-center mb-3' id='form_section_2.3'>
                        <div style={{ height: 175, padding: 10, overflow: 'hidden', borderStyle: 'dashed', borderColor: this.state.dragAreaColor.ut_attachment_1, borderWidth: 2, borderRadius: 20, position: 'relative' }} className='d-flex justify-content-center align-items-center'
                          onDragEnter={(e) => this.handleDragFileArea(e, 'ut_attachment_1')} onDragLeave={(e) => this.handleDragFileArea(e, 'ut_attachment_1')} onDragOver={(e) => this.handleDragFileArea(e, 'ut_attachment_1')} onDrop={(e) => {
                            // 
                            e.preventDefault();
                            e.stopPropagation();
                            if (e.dataTransfer.files && e.dataTransfer.files[0]) {
                              this.addAttachment({ target: { files: e.dataTransfer.files, multiple: false, name: 'ut_attachment_1' } })
                            }
                          }}>
                          {ut_attachment_1 && (!ut_attachment_1.data.type.includes('pdf')) && (
                            <img src={ut_attachment_1.url} alt={t('Preview')} style={{ height: '100%' }} />
                          )}
                          {ut_attachment_1 && (ut_attachment_1.data.type.includes('pdf')) && (
                            <img src={require('../../assets/images/pdf.png')} alt={t('PDF')} style={{ height: '100%' }} />
                          )}
                          {ut_attachment_1 == null &&
                            <button onClick={() => this.ut_attachment_1_ref.current.click()} style={{ background: 'none', border: 0, flex: 1 }}>
                              {/* <FontAwesomeIcon icon={faPlus} style={{ fontSize: 30, color: color18 }} /> */}
                              <span style={{ fontSize: 20, fontWeight: 'bold', color: brand_colors[this.props.defaultTheme.theme_id].color18 }}>{t('Custom Icon')}</span>
                            </button>
                          }

                          {ut_attachment_1 && (
                            <FontAwesomeIcon
                              icon={faTrashCan}
                              style={{ fontSize: 14, color: brand_colors[this.props.defaultTheme.theme_id].color11, position: 'absolute', bottom: 6, right: 6, cursor: 'pointer', backgroundColor: brand_colors[this.props.defaultTheme.theme_id].color8, borderRadius: 100, padding: 3 }}
                              onClick={() => this.deleteUserTimelineAttachment('ut_attachment_1')}
                            />
                          )}
                        </div>
                        <Form.Control className='d-none' ref={this.ut_attachment_1_ref} type='file' onChange={this.addAttachment} name='ut_attachment_1' />
                      </div>
                    }
                    {/* ut_attachment_2 */}
                    {['Notification', 'Event', 'Upload', 'Daily Report', 'Note', 'Online Class', 'Exam', 'Resources', 'Incident Report', 'Exam Retake', 'Online Homework'].includes(activeUserTimeline.ut_type) &&
                      <div style={{ flexDirection: 'column' }} className='pic-prev d-flex justify-content-center' id='form_section_2.4'>
                        <div style={{ height: 175, padding: 10, overflow: 'hidden', borderStyle: 'dashed', borderColor: this.state.dragAreaColor.ut_attachment_2, borderWidth: 2, borderRadius: 20, position: 'relative' }} className='d-flex justify-content-center align-items-center' onDragEnter={(e) => this.handleDragFileArea(e, 'ut_attachment_2')} onDragLeave={(e) => this.handleDragFileArea(e, 'ut_attachment_2')} onDragOver={(e) => this.handleDragFileArea(e, 'ut_attachment_2')} onDrop={(e) => {
                          // 
                          e.preventDefault();
                          e.stopPropagation();
                          if (e.dataTransfer.files && e.dataTransfer.files[0]) {
                            this.addAttachment({ target: { files: e.dataTransfer.files, multiple: false, name: 'ut_attachment_2' } })
                          }
                        }}>
                          {ut_attachment_2 && (!ut_attachment_2.data.type.includes('pdf')) && (
                            <img src={ut_attachment_2.url} alt={t('Preview')} style={{ height: '100%' }} />
                          )}
                          {ut_attachment_2 && (ut_attachment_2.data.type.includes('pdf')) && (
                            <img src={require('../../assets/images/pdf.png')} alt={t('PDF')} style={{ height: '100%' }} />
                          )}
                          {ut_attachment_2 == null &&
                            (
                              <button onClick={() => this.ut_attachment_2_ref.current.click()} style={{ background: 'none', border: 0, flex: 1 }}>
                                {/* <FontAwesomeIcon icon={faPlus} style={{ fontSize: 30, color: color18 }} /> */}
                                <span style={{ fontSize: 20, fontWeight: 'bold', color: brand_colors[this.props.defaultTheme.theme_id].color18 }}>{t('Attachment')}</span>
                              </button>
                            )
                          }
                          {ut_attachment_2 && (
                            <FontAwesomeIcon
                              icon={faTrashCan}
                              style={{ fontSize: 14, color: brand_colors[this.props.defaultTheme.theme_id].color11, position: 'absolute', bottom: 6, right: 6, cursor: 'pointer', backgroundColor: brand_colors[this.props.defaultTheme.theme_id].color8, borderRadius: 100, padding: 3 }}
                              onClick={() => this.deleteUserTimelineAttachment('ut_attachment_2')}
                            />
                          )}
                        </div>
                        <Form.Control className='d-none' ref={this.ut_attachment_2_ref} type='file' onChange={this.addAttachment} name='ut_attachment_2' />
                      </div>
                    }
                  </Col>
                </Row>
              </div>
            )}
            {/* SECTION 3 */}
            {['Media', 'Assessment'].includes(activeUserTimeline.ut_type) && (<>
              <div className={'d-flex flex-row align-items-center justify-content-between section-heading'} id='form_section_3'>
                <span className='heading'>{'Media'}</span>
              </div>
              {['Media'].includes(activeUserTimeline.ut_type) && (
                <Row>
                  <Col md="4">
                    {/* ALBUM */}
                    <div id='form_section_3.1'>
                      <Form.Group className='mb-3'>
                        <Form.Label>{t('Album')}</Form.Label>
                        <Select
                          options={this.state.allAlbums.map((v, i) => { return { label: v.album_name, value: v.album_id, picture: v.album_picture, center_id: v.center_id } })}
                          onChange={(event) => {
                            this.setStateUserTimelineMetadata('album_id', event)
                          }}
                          value={user_timeline_metadata.album_id}
                          menuPortalTarget={document.body}
                          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                          formatOptionLabel={option => (
                            <div>
                              {option.picture ? <img src={portalURL + option.center_id + '/albums/' + option.picture} style={{ width: 50, height: 50, objectFit: 'cover', marginRight: 5 }} /> : ''}
                              <span style={{ fontSize: 20 }}>{option.label}</span>
                            </div>
                          )}
                        />
                      </Form.Group>
                    </div>
                  </Col>
                </Row>
              )}
              <Row>
                {/* ADD ATTACHMENT */}
                <Col md='3' className='mb-1' id='form_section_3.2'>
                  <div style={{ flexDirection: 'column' }} className='pic-prev d-flex justify-content-center'>
                    <div style={{ height: 100, display: 'flex', overflow: 'hidden', borderStyle: 'dashed', borderColor: this.state.dragAreaColor.user_timeline_media, borderWidth: 2, borderRadius: 20 }} onDragEnter={(e) => this.handleDragFileArea(e, 'user_timeline_media')} onDragLeave={(e) => this.handleDragFileArea(e, 'user_timeline_media')} onDragOver={(e) => this.handleDragFileArea(e, 'user_timeline_media')} onDrop={(e) => {
                      // 
                      e.preventDefault();
                      e.stopPropagation();
                      if (e.dataTransfer.files && e.dataTransfer.files[0]) {
                        this.addAttachment({ target: { files: e.dataTransfer.files, multiple: true, name: 'user_timeline_media' } })
                      }
                    }}>
                      <button onClick={() => this.filePicker.current.click()} style={{ background: 'none', border: 0, flex: 1 }}>
                        <FontAwesomeIcon icon={faPlus} style={{ fontSize: 30, color: brand_colors[this.props.defaultTheme.theme_id].color18 }} />
                      </button>
                    </div>
                    <Form.Control className='d-none' ref={this.filePicker} type='file' multiple onChange={this.addAttachment} name='user_timeline_media' />
                  </div>
                </Col>
                {/* ALL ATTACHMENTS */}
                {user_timeline_media.map((v, i) => {
                  return (
                    <Col key={i} md='3' className='mb-1'>
                      <div style={{ position: 'relative', borderRadius: 5, borderStyle: 'solid', borderWidth: 1, borderColor: '#ced4da', height: 100, padding: 10 }} className='d-flex justify-content-center align-items-center'>
                        {/* IMAGE */}
                        {v.data.type.includes('image') && (
                          <>
                            <img src={v.url} alt={t('Preview')} style={{ height: '100%' }} />
                          </>
                        )}
                        <button
                          onClick={() => {
                            this.deleteUserTimelineMedia(v, i)
                          }}
                          style={{ background: 'none', border: 0 }}
                        >
                          <FontAwesomeIcon icon={faTrashCan} style={{ fontSize: 12, color: brand_colors[this.props.defaultTheme.theme_id].color11, position: 'absolute', bottom: 5, right: 5, cursor: 'pointer' }} />
                        </button>
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </>
            )}
            {/* SECTION 4 */}
            {['Video'].includes(activeUserTimeline.ut_type) && (<>
              <div className={'d-flex flex-row align-items-center justify-content-between section-heading'} id='form_section_4'>
                <span className='heading'>{'Video'}</span>
              </div>
            </>)}
            {/* SECTION 5 */}
            {['Event'].includes(activeUserTimeline.ut_type) && (<>
              <div className={'d-flex flex-row align-items-center justify-content-between section-heading'} id='form_section_5'>
                <span className='heading'>{'Event'}</span>
              </div>
              <Row>
                <Col md="6" id='form_section_5.1'>
                  <div id='form_section_1.3'>
                    <Form.Group className='mb-3'>
                      <Form.Label>
                        <img src={imgDescription} />
                        {t('Fees')}
                      </Form.Label>
                      <Form.Control type='text' defaultValue={user_timeline_metadata.fees} onBlur={(event) => this.setStateUserTimelineMetadata('fees', event.target.value)} />
                    </Form.Group>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col id='form_section_5.2'>
                  {/* START DATETIME */}
                  <Form.Group className='mb-3'>
                    <Form.Label>
                      <img src={imgDescription} />
                      {t('Start DateTime')}
                    </Form.Label>
                    <Flatpickr
                      className='form-control'
                      data-enable-time
                      defaultValue={(user_timeline_metadata.start_datetime) ? moment(user_timeline_metadata.start_datetime).format('DD-MM-YYYY HH:mm:ss') : null}
                      // value={(user_timeline_metadata.end_datetime) ? moment(user_timeline_metadata.end_datetime).format('DD-MM-YYYY HH:mm:ss') : null}
                      onClose={([date]) => {
                        this.setStateUserTimelineMetadata('start_datetime', (date) ? date : null)
                      }}
                      options={{
                        dateFormat: 'd-m-Y H:i:S',
                        time_24hr: true
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col id='form_section_5.3'>
                  {/* END DATETIME */}
                  <Form.Group className='mb-3'>
                    <Form.Label>
                      <img src={imgDescription} />
                      {t('End DateTime')}
                    </Form.Label>
                    <Flatpickr
                      className='form-control'
                      data-enable-time
                      defaultValue={(user_timeline_metadata.end_datetime) ? moment(user_timeline_metadata.end_datetime).format('DD-MM-YYYY HH:mm:ss') : null}
                      // value={(user_timeline_metadata.end_datetime) ? moment(user_timeline_metadata.end_datetime).format('DD-MM-YYYY HH:mm:ss') : null}
                      onClose={([date]) => {
                        this.setStateUserTimelineMetadata('end_datetime', (date) ? date : null)
                      }}
                      options={{
                        dateFormat: 'd-m-Y H:i:S',
                        time_24hr: true
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </>
            )}
            {/* SECTION 6 */}
            {['Assignment'].includes(activeUserTimeline.ut_type) && (<>
              <div className={'d-flex flex-row align-items-center justify-content-between section-heading'} id='form_section_6'>
                <span className='heading'>{'Assignment'}</span>
              </div>
              <Row id='form_section_6.1'>
                <Col md="6">
                  {/* DUE DATETIME */}
                  <Form.Group className='mb-3'>
                    <Form.Label>
                      <img src={imgDescription} />
                      {t('Due Date')}
                    </Form.Label>
                    <Flatpickr
                      className='form-control'
                      defaultValue={(user_timeline_metadata.end_datetime) ? moment(user_timeline_metadata.end_datetime).format('DD-MM-YYYY') : null}
                      // value={(user_timeline_metadata.end_datetime) ? moment(user_timeline_metadata.end_datetime).format('DD-MM-YYYY') : null}
                      onClose={([date]) => this.setStateUserTimelineMetadata('end_datetime', date)}
                      options={{
                        dateFormat: 'd-m-Y',
                        time_24hr: true
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                {/* description2 */}
                <div md={12} id='form_section_1.4'>
                  <Form.Group className='mb-3'>
                    <Form.Label>
                      <img src={imgDescription} />
                      {t('Note')}
                    </Form.Label>
                    <Form.Control as="textarea" rows={3} defaultValue={user_timeline_metadata.description2} onBlur={(event) => this.setStateUserTimelineMetadata('description2', event.target.value)} />
                  </Form.Group>
                </div>
                <Col md="4">
                  {/* SUBJECT */}
                  <div id='form_section_6.3'>
                    <Form.Group className='mb-3'>
                      <Form.Label>{t('Subject')}</Form.Label>
                      <Select
                        options={this.extractClassSubjects()}
                        onChange={(event) => {
                          this.setStateUserTimelineMetadata('classmaterial_id', event)
                        }}
                        value={user_timeline_metadata.classmaterial_id}
                        menuPortalTarget={document.body}
                        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                        formatOptionLabel={option => (
                          <div>
                            {option.picture ? <img src={portalURL + option.center_id + '/academic_classmain_subject/' + option.picture} style={{ width: 30, height: 30, objectFit: 'cover', marginRight: 5 }} /> : ''}
                            <span style={{ fontSize: 16 }}>{option.label}</span>
                          </div>
                        )}
                      />
                    </Form.Group>
                  </div>
                </Col>
              </Row>
              {/* AUDIO ut_attachment_2 */}
              <div id='form_section_6.4' style={{ marginTop: '2rem' }}>
                <ReactMediaRecorder
                  audio={true}
                  askPermissionOnMount={true}
                  render={({ status, startRecording, stopRecording, mediaBlobUrl, error, clearBlobUrl }) => {
                    // 

                    if (['permission_denied'].includes(error)) {
                      // toast.info(t('Acquiring media permission, Please allow permission to record audio!'), {
                      //   autoClose: 1500,
                      // });
                      return <div
                        className='d-flex flex-column'
                        style={{
                          padding: '0.375rem 0.75rem',
                          fontSize: '1rem',
                          fontWeight: '400',
                          lineHeight: '1.5',
                          color: brand_colors[this.props.defaultTheme.theme_id].color11,
                          backgroundColor: '#fff',
                          border: '1px solid #ced4da',
                          borderRadius: '0.375rem',
                        }}
                      >
                        {('Permission denied to record audio, Please allow to start Recording')}
                      </div>
                    } else if (status == 'recording') {
                      toast.success(t('Audio Recording has been started!'));
                    }
                    if (mediaBlobUrl && mediaBlobUrl != ut_attachment_2) {
                      this.setState({ ut_attachment_2: mediaBlobUrl });
                    }

                    return (
                      <div
                        className='d-flex flex-column'
                        style={{
                          padding: '0.375rem 0.75rem',
                          fontSize: '1rem',
                          fontWeight: '400',
                          lineHeight: '1.5',
                          color: '#212529',
                          backgroundColor: '#fff',
                          border: '1px solid #ced4da',
                          borderRadius: '0.375rem',
                        }}
                      >
                        <div
                          style={{
                            position: 'relative',
                            top: '-15px',
                            display: 'flex',
                            alignSelf: 'flex-start',
                            backgroundColor: brand_colors[this.props.defaultTheme.theme_id].color8,
                          }}
                        >
                          <button className='btn btn-sm btn-outline-primary' style={{ marginRight: '1rem' }} onClick={startRecording} disabled={status == 'recording' ? true : false}>
                            {status == 'recording' ? (
                              <span>
                                <Spinner as='span' animation='grow' size='sm' role='status' aria-hidden='true' variant='danger' /> Recording
                              </span>
                            ) : (
                              'Start Recording'
                            )}
                          </button>
                          <FontAwesomeIcon icon={faMicrophone} style={{ fontSize: 26, color: brand_colors[this.props.defaultTheme.theme_id].color18 }} />
                          <button className='btn btn-sm btn-outline-danger' style={{ marginLeft: '1rem' }} onClick={stopRecording} disabled={status == 'recording' ? false : true}>
                            Stop Recording
                          </button>

                          {ut_attachment_2 &&
                            <button className='btn btn-sm btn-outline-danger' style={{ marginLeft: '1rem' }} onClick={() => this.deleteUserTimelineAttachment('ut_attachment_2', clearBlobUrl)}>
                              <FontAwesomeIcon icon={faTrashCan} style={{}} />
                            </button>
                          }

                        </div>
                        {ut_attachment_2 && <audio src={mediaBlobUrl} controls />}
                      </div>
                    );
                  }}
                />
              </div>
            </>)}
            {/* SECTION 7 */}
            {['Assessment'].includes(activeUserTimeline.ut_type) && (<>
              <div className={'d-flex flex-row align-items-center justify-content-between section-heading'} id='form_section_7'>
                <span className='heading'>{t('Observation')}</span>
              </div>
              <Row>
                {/* Observation */}
                <Col id='form_section_7.1'>
                  <Form.Group className="mb-3">
                    <Form.Label>{t('Observation')}</Form.Label>
                    <Form.Control as="textarea" rows={3} defaultValue={user_timeline.ut_description} onBlur={(event, editor) => this.setStateUserTimeline('ut_description', event.target.value)} />
                  </Form.Group>
                </Col>
                {/* Next Steps */}
                <Col id='form_section_7.2'>
                  <Form.Group className="mb-3">
                    <Form.Label>{t('Next Steps')}</Form.Label>
                    <Form.Control as="textarea" rows={3} defaultValue={user_timeline_metadata.description2} onBlur={(event, editor) => this.setStateUserTimelineMetadata('description2', event.target.value)} />
                  </Form.Group>
                </Col>
              </Row>

              <Row id='form_section_7.3'>
                {/* NEW OBSERVATION */}
                <Col md="12">
                  <ButtonGroup className='mb-1'>
                    <Button variant={(this.state.activeObsTab == 'Observation') ? 'primary' : 'light'} onClick={() => this.setState({ activeObsTab: 'Observation' })}>
                      {'Observation'}
                    </Button>
                    <Button variant={(this.state.activeObsTab == 'SelectedObservation') ? 'primary' : 'light'} onClick={() => this.setState({ activeObsTab: 'SelectedObservation' })}>
                      {'Selected Observation'}
                    </Button>
                  </ButtonGroup>
                </Col>
              </Row>
              {(this.state.activeObsTab == 'Observation')
                ? <Row>
                  <Col>
                    <div>
                      <div className="my-1">
                        <div className="p-2 border" style={{ borderRadius: 20 }}>
                          <div className='mb-1' id="div_progass">
                            <ButtonGroup size='lg'>
                              {assessmentMenuData.progass && assessmentMenuData.progass.map((v, i) => {
                                return <Button key={i} variant={(assessmentMenu.progassIndex == i) ? "success" : "light"} progass_id={v.progass_id} rowdata='' title={v.progass_desc} onClick={() => this.state.assessmentMenu.changeValue('progassIndex', i)}>
                                  {v.progass_code}
                                </Button>
                              })}
                            </ButtonGroup>
                          </div>
                          <div className='mb-1' id="div_learnarea">
                            <ButtonGroup size='lg'>
                              {assessmentMenuData.learnarea && assessmentMenuData.learnarea.map((v, i) => {
                                if (assessmentMenuData.progass[assessmentMenu.progassIndex].progass_id != v.progass_id) {
                                  return null
                                }
                                return <Button key={i} variant={(assessmentMenu.learnareaIndex == i) ? "success" : "light"} learnarea_id={v.learnarea_id} rowdata='' title={v.learnarea_desc} style={{ color: (assessmentMenu.learnareaIndex == i) ? brand_colors[this.props.defaultTheme.theme_id].color8 : v.learnarea_color }} onClick={() => this.state.assessmentMenu.changeValue('learnareaIndex', i)}>
                                  {v.learnarea_code}
                                </Button>
                              })}
                            </ButtonGroup>
                          </div>
                          <div className='mb-1' id="div_lrnaspect">
                            <ButtonGroup size='lg'>
                              {assessmentMenuData.lrnaspect && assessmentMenuData.lrnaspect.map((v, i) => {
                                if (assessmentMenuData.learnarea[assessmentMenu.learnareaIndex].learnarea_id != v.learnarea_id) {
                                  return null
                                }
                                return <Button key={i} variant={(assessmentMenu.lrnaspectIndex == i) ? "success" : "light"} lrnaspect_id={v.lrnaspect_id} rowdata='' title={v.lrnaspect_desc} onClick={() => this.state.assessmentMenu.changeValue('lrnaspectIndex', i)}>
                                  {v.lrnaspect_code}
                                </Button>
                              })}
                            </ButtonGroup>
                          </div>
                          <div className='mb-1' id="div_agegroup">
                            <ButtonGroup size='lg'>
                              {assessmentMenuData.agegroup && assessmentMenuData.agegroup.map((v, i) => {
                                if (assessmentMenuData.progass[assessmentMenu.progassIndex].progass_id != v.progass_id) {
                                  return null
                                }
                                return <Button key={i} variant={(assessmentMenu.agegroupIndex == i) ? "success" : "light"} agegroup_id={v.agegroup_id} rowdata='' title={v.agegroup_code} style={{ color: (assessmentMenu.agegroupIndex == i) ? brand_colors[this.props.defaultTheme.theme_id].color8 : v.agegroup_color }} onClick={() => this.state.assessmentMenu.changeValue('agegroupIndex', i)}>
                                  {v.agegroup_code}
                                </Button>
                              })}
                            </ButtonGroup>
                          </div>
                          <div id="div_assementMenu_navigation"></div>
                          <Breadcrumb style={{ margin: '10px 0', fontWeight: 'bold' }}>
                            {assessmentMenuData.progass &&
                              <Breadcrumb.Item active>{assessmentMenuData.progass[assessmentMenu.progassIndex].progass_desc}</Breadcrumb.Item>
                            }
                            {assessmentMenuData.learnarea &&
                              <Breadcrumb.Item active style={{ color: assessmentMenuData.learnarea[assessmentMenu.learnareaIndex].learnarea_color }}>{assessmentMenuData.learnarea[assessmentMenu.learnareaIndex].learnarea_desc}</Breadcrumb.Item>
                            }
                            {assessmentMenuData.lrnaspect &&
                              <Breadcrumb.Item active>{assessmentMenuData.lrnaspect[assessmentMenu.lrnaspectIndex].lrnaspect_desc}</Breadcrumb.Item>
                            }
                            {assessmentMenuData.agegroup &&
                              <Breadcrumb.Item active style={{ color: assessmentMenuData.agegroup[assessmentMenu.agegroupIndex].agegroup_color }}>{assessmentMenuData.agegroup[assessmentMenu.agegroupIndex].agegroup_code}</Breadcrumb.Item>
                            }
                            {assessmentMenuData.assessment && this.renderAssessAspectOptions()}
                          </Breadcrumb>
                          <div>
                            <table className="table table-sm table-striped" id="table_curriculum">
                              <thead>
                                <tr>
                                  <th>Attainments</th>
                                  <th>Curriculum</th>
                                </tr>
                              </thead>
                              <tbody>
                                {assessmentMenuData.allCurriculum && assessmentMenuData.allCurriculum.map((v, i) => {
                                  if (assessmentMenuData.lrnaspect[assessmentMenu.lrnaspectIndex].lrnaspect_id != v.lrnaspect_id || assessmentMenuData.agegroup[assessmentMenu.agegroupIndex].agegroup_id != v.agegroup_id) {
                                    return null
                                  }
                                  let lastAns = []
                                  if (assessmentMenuData.lastAssessmentAnswers) {

                                    let findCurr = assessmentMenuData.lastAssessmentAnswers.find((vv, ii) => vv.curriculum_id == v.curriculum_id)
                                    if (findCurr) {
                                      lastAns = findCurr.assess_curr
                                    }
                                  }

                                  let thisAns = {}
                                  let selectedVal = this.state.assess_curr.find((vv, ii) => (vv.curr_aspect_id == assessmentMenuData.lrnaspect[assessmentMenu.lrnaspectIndex].lrnaspect_id && vv.curr_agegroup_id == assessmentMenuData.agegroup[assessmentMenu.agegroupIndex].agegroup_id && vv.curriculum_id == v.curriculum_id))
                                  if (selectedVal) {
                                    let findAss = assessmentMenuData.assessment.find((vv, ii) => vv.assmt_id == selectedVal.asscurr_value)
                                    if (findAss) {
                                      thisAns = { label: findAss.assmt_code, value: findAss.assmt_id, assmt_color: findAss.assmt_color }
                                    }
                                  }

                                  return (<tr key={i}>
                                    <td style={{ minWidth: 200, maxWidth: 200, width: 200 }}>
                                      {lastAns.length > 0 && <Badge bg="success" className='cursor-pointer mb-1'>Last: {lastAns[0].assmt_code} ({moment(lastAns[0].assesslog_date).format('DD-MM-YYYY')})</Badge>}
                                      <Form.Group>
                                        <Select
                                          options={assessmentMenuData.assessment.map((v, i) => { return { label: v.assmt_code, value: v.assmt_id, assmt_color: v.assmt_color } })}
                                          onChange={(event) => {
                                            this.selectAssessment(event.value, 'selectedCurriculum', v.curriculum_id)
                                          }}
                                          value={thisAns}
                                          menuPortalTarget={document.body}
                                          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999, }), control: (base) => ({ ...base, minHeight: 30, height: 30 }) }}
                                          formatOptionLabel={option => (
                                            <div>
                                              <span style={{ color: option.assmt_color, fontSize: 14 }}>{option.label}</span>
                                            </div>
                                          )}
                                        />
                                      </Form.Group>
                                    </td>
                                    <td>{v.curriculum_code}</td>
                                  </tr>)
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
                : <Row>
                  <Col>
                    {this.renderAssessmentList()}
                  </Col>
                </Row>}


            </>)}
            {/* SECTION 8 */}
            {['Agenda'].includes(activeUserTimeline.ut_type) && (<>
              <div className={'d-flex flex-row align-items-center justify-content-between section-heading'} id='form_section_8'>
                <span className='heading'>{'Agenda'}</span>
              </div>
              <Row>
                {/* SUBJECT */}
                <Col id='form_section_8.1'>
                  <Form.Group className='mb-3'>
                    <Form.Label>{t('Subject')}</Form.Label>
                    <Select
                      options={this.extractClassSubjects()}
                      onChange={(event) => {
                        this.setStateUserTimelineMetadata('classmaterial_id', event)
                      }}
                      value={user_timeline_metadata.classmaterial_id}
                      menuPortalTarget={document.body}
                      styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                      formatOptionLabel={option => (
                        <div>
                          {option.picture ? <img src={portalURL + option.center_id + '/academic_classmain_subject/' + option.picture} style={{ width: 30, height: 30, objectFit: 'cover', marginRight: 5 }} /> : ''}
                          <span style={{ fontSize: 16 }}>{option.label}</span>
                        </div>
                      )}
                    />
                  </Form.Group>
                </Col>
                <Col id='form_section_8.2'>
                  {/* START DATETIME */}
                  <Form.Group className='mb-3'>
                    <Form.Label>
                      <img src={imgDescription} />
                      {t('Start DateTime')}
                    </Form.Label>
                    <Flatpickr
                      className='form-control'
                      data-enable-time
                      defaultValue={(user_timeline_metadata.start_datetime) ? moment(user_timeline_metadata.start_datetime).format('DD-MM-YYYY HH:mm:ss') : null}
                      // value={(user_timeline_metadata.start_datetime) ? moment(user_timeline_metadata.start_datetime).format('DD-MM-YYYY HH:mm:ss') : null}
                      onClose={([date]) => this.setStateUserTimelineMetadata('start_datetime', date)}
                      options={{
                        dateFormat: 'd-m-Y H:i:S',
                        time_24hr: true
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </>)}
            {/* SECTION 9 */}
            {['Incident Report'].includes(activeUserTimeline.ut_type) && (<>
              <div className={'d-flex flex-row align-items-center justify-content-between section-heading'} id='form_section_9'>
                <span className='heading'>{'Incident Report'}</span>
              </div>
              <Row>
                {/* INCIDENT DATETIME */}
                <Col id='form_section_9.1'>
                  <Form.Group className='mb-3'>
                    <Form.Label>
                      <img src={imgDescription} />
                      {t('Incident DateTime')}
                    </Form.Label>
                    <Flatpickr
                      className='form-control'
                      data-enable-time
                      defaultValue={(user_timeline_metadata.start_datetime) ? moment(user_timeline_metadata.start_datetime).format('DD-MM-YYYY HH:mm:ss') : null}
                      // value={(user_timeline_metadata.start_datetime) ? moment(user_timeline_metadata.start_datetime).format('DD-MM-YYYY HH:mm:ss') : null}
                      onClose={([date]) => this.setStateUserTimelineMetadata('start_datetime', date)}
                      options={{
                        dateFormat: 'd-m-Y H:i:S',
                        time_24hr: true
                      }}
                    />
                  </Form.Group>
                </Col>
                {/* Taken to Hospital */}
                <Col md="2" className='text-center' id='form_section_9.2'>
                  <Form.Label>
                    <img src={imgDescription} />
                    {t('Taken to Hospital')}
                  </Form.Label>
                  <Form.Check
                    type="switch"
                    style={{ marginTop: 10 }}
                    value={1}
                    checked={user_timeline_metadata.taken_to_hospital}
                    onChange={(event) => this.setStateUserTimelineMetadata('taken_to_hospital', (event.target.checked) ? true : false)}
                  />
                </Col>
                {/* Taken Immediately/Date */}
                <Col md="2" className='text-center' id='form_section_9.3'>
                  <Form.Label>
                    <img src={imgDescription} />
                    {t('Taken Immediately')}
                  </Form.Label>
                  <Form.Check
                    type="switch"
                    style={{ marginTop: 10 }}
                    value={1}
                    checked={user_timeline_metadata.taken_immediately}
                    onChange={(event) => this.setStateUserTimelineMetadata('taken_immediately', (event.target.checked) ? true : false)}
                  />
                </Col>
                {/* Taken Date */}
                <Col>
                  <Form.Group className='mb-3'>
                    <Form.Label>
                      <img src={imgDescription} />
                      {t('Taken Date')}
                    </Form.Label>
                    <Flatpickr
                      className='form-control'
                      defaultValue={(user_timeline_metadata.start_datetime) ? moment(user_timeline_metadata.start_datetime).format('DD-MM-YYYY') : null}
                      // value={(user_timeline_metadata.start_datetime) ? moment(user_timeline_metadata.start_datetime).format('DD-MM-YYYY') : null}
                      onClose={([date]) => this.setStateUserTimelineMetadata('taken_later_date', (date) ? date : null)}
                      options={{
                        dateFormat: 'd-m-Y',
                        time_24hr: true
                      }}
                      disabled={(user_timeline_metadata.taken_to_hospital && !user_timeline_metadata.taken_immediately) ? false : true}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                {/* Hospital Name */}
                <Col md="6" id='form_section_9.4'>
                  <Form.Group className='mb-3'>
                    <Form.Label>
                      <img src={imgDescription} />
                      {t('Hospital Name')}
                    </Form.Label>
                    <Form.Control type='text' defaultValue={user_timeline_metadata.taken_to_hospital_name} onBlur={(event) => this.setStateUserTimelineMetadata('taken_to_hospital_name', event.target.value)} disabled={(user_timeline_metadata.taken_to_hospital) ? false : true}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                {/* description2 */}
                <div md={12} id='form_section_9.5'>
                  <Form.Group className='mb-3'>
                    <Form.Label>
                      <img src={imgDescription} />
                      {t('Follow Up')}
                    </Form.Label>
                    < Form.Control as="textarea" rows={3} defaultValue={user_timeline_metadata.description2} onBlur={(event) => this.setStateUserTimelineMetadata('description2', event.target.value)} />
                  </Form.Group>
                </div>
              </Row>

            </>)}
            {/* SECTION 10 */}
            {['Poll'].includes(activeUserTimeline.ut_type) && (<>
              <div className={'d-flex flex-row align-items-center justify-content-between section-heading'} id='form_section_10'>
                <span className='heading'>{'Poll'}</span>
              </div>
              <Row>
                {/* SUBJECT */}
                <Col id='form_section_10.1'>
                  {/* START DATETIME */}
                  <Form.Group className='mb-3'>
                    <Form.Label>
                      <img src={imgDescription} />
                      {t('Start DateTime')}
                    </Form.Label>
                    <Flatpickr
                      className='form-control'
                      data-enable-time
                      defaultValue={(user_timeline_metadata.start_datetime) ? moment(user_timeline_metadata.start_datetime).format('DD-MM-YYYY HH:mm:ss') : null}
                      // value={(user_timeline_metadata.start_datetime) ? moment(user_timeline_metadata.start_datetime).format('DD-MM-YYYY HH:mm:ss') : null}
                      onClose={([date]) => this.setStateUserTimelineMetadata('start_datetime', (date) ? date : null)}
                      options={{
                        dateFormat: 'd-m-Y H:i:S',
                        time_24hr: true
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col id='form_section_10.2'>
                  {/* END DATETIME */}
                  <Form.Group className='mb-3'>
                    <Form.Label>
                      <img src={imgDescription} />
                      {t('End DateTime')}
                    </Form.Label>
                    <Flatpickr
                      className='form-control'
                      data-enable-time
                      defaultValue={(user_timeline_metadata.end_datetime) ? moment(user_timeline_metadata.end_datetime).format('DD-MM-YYYY HH:mm:ss') : null}
                      // value={(user_timeline_metadata.end_datetime) ? moment(user_timeline_metadata.end_datetime).format('DD-MM-YYYY HH:mm:ss') : null}
                      onClose={([date]) => this.setStateUserTimelineMetadata('end_datetime', (date) ? date : null)}
                      options={{
                        dateFormat: 'd-m-Y H:i:S',
                        time_24hr: true
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col md="2" className='text-center' id='form_section_10.3'>
                  <Form.Label>
                    <img src={imgDescription} />
                    {t('Allow Resubmit')}
                  </Form.Label>
                  <Form.Check
                    type="switch"
                    style={{ marginTop: 10 }}
                    value={1}
                    checked={user_timeline_metadata.allow_resubmit}
                    onChange={(event) => this.setStateUserTimelineMetadata('allow_resubmit', (event.target.checked) ? true : false)}
                  />
                </Col>
              </Row>

              <div className={'d-flex flex-row align-items-center justify-content-between section-heading'}>
                <span className='heading'>{'Tag Users'}</span>
              </div>
              <Row id='form_section_10.4'>
                <Col md="12">
                  <Form.Group className='mb-3'>
                    <Form.Label>{t('Roles')}</Form.Label>
                    <Select
                      isMulti={true}
                      closeMenuOnSelect={false}
                      options={[{ value: 6, label: 'Parent/Student' }, { value: 3, label: 'Teachers' }, { value: 15, label: 'Curriculum Coordinator' }, { value: 4, label: 'Assistant' }]}
                      onChange={(event) => this.setStateUserTimelineMetadata('roles', event)}
                      value={user_timeline_metadata.roles}
                      menuPortalTarget={document.body}
                      styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                    />
                  </Form.Group>
                </Col>
              </Row>
              {/* WHEN PARENT/STUDENT IS SELECTED */}
              {user_timeline_metadata.roles.find((v, i) => v.value == 6) &&
                <div>
                  <Row>
                    <Col>
                      {/* CLASS */}
                      <Form.Group className='mb-3'>
                        <Form.Label>{t('Class')}</Form.Label>
                        <Select
                          isMulti={true}
                          options={this.props.authData.classes.map((v, i) => { return { label: v.class.class_theme, value: v.class.class_id, picture: v.class.class_logo, center_id: v.class.center_id, agegroup_id: v.class.agegroup_id } })}
                          onChange={(event) => {
                            this.setStateUserTimelineMetadata('class_ids', event)
                          }}
                          value={user_timeline_metadata.class_ids}
                          menuPortalTarget={document.body}
                          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                          formatOptionLabel={option => (
                            <div>
                              {option.picture ? <img src={portalURL + option.center_id + '/classes/' + option.picture} style={{ width: 40, height: 40, objectFit: 'cover', marginRight: 5 }} /> : ''}
                              <span style={{ fontSize: 18 }}>{option.label}</span>
                            </div>
                          )}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      {/* Term */}
                      <Form.Group className='mb-3'>
                        <Form.Label>{t('Term')}</Form.Label>
                        <Select
                          isMulti={true}
                          options={this.state.allTerms.map((v, i) => { return { label: v.term_name, value: v.term_id, active: (v.term_active == 1) ? true : false } })}
                          onChange={(event) =>
                            this.setStateUserTimelineMetadata('term_ids', event)
                          }
                          value={user_timeline_metadata.term_ids}
                          menuPortalTarget={document.body}
                          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                          formatOptionLabel={option => (
                            <div>
                              <span style={{ fontSize: 20 }}>
                                {option.label}
                                {option.active &&
                                  <Badge bg='success'>Active</Badge>
                                }
                              </span>
                            </div>
                          )}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </div>
              }
              {/* OPTIONS */}
              <div className={'d-flex flex-row align-items-center justify-content-between section-heading'} id='form_section_10.5'>
                <span className='heading'>{'Options'}</span>
                <div>
                  <Button variant='primary' className='mb-1' onClick={() => {
                    let PollOptions = this.state.PollOptions
                    PollOptions.push({
                      poll_opt_id: chance.unique(chance.integer, 1, { min: 99999999999, max: 999999999999 })[0],
                      poll_opt_name: ''
                    })
                    this.setState({ PollOptions: PollOptions })
                  }}>
                    <FontAwesomeIcon icon={faPlus} style={{}} />
                  </Button>
                </div>
              </div>
              <Row>
                {this.state.PollOptions.map((v, i) => {
                  return <Col md="3" key={i}>
                    <Form.Group className='mb-3'>
                      <Form.Label >
                        <FontAwesomeIcon icon={faTrashCan} style={{
                          fontSize: '14px',
                          color: brand_colors[this.props.defaultTheme.theme_id].color11,
                          marginRight: 10,
                          cursor: 'pointer'
                        }}
                          onClick={() => {
                            let PollOptions = this.state.PollOptions
                            PollOptions.splice(i, 1)
                            this.setState({ PollOptions: PollOptions })
                          }}
                        />
                        {t('Option') + ' ' + (i + 1)}
                      </Form.Label>
                      <Form.Control type='text' defaultValue={v.poll_opt_name} onBlur={(event) => {
                        let PollOptions = this.state.PollOptions
                        PollOptions[i].poll_opt_name = event.target.value
                        this.setState({ PollOptions: PollOptions })
                      }} />
                    </Form.Group>
                  </Col>
                })}
              </Row>
            </>)}

            {/* SECTION 11 */}
            {['Survey'].includes(activeUserTimeline.ut_type) && (<>
              <div className={'d-flex flex-row align-items-center justify-content-between section-heading'} id='form_section_11'>
                <span className='heading'>{'Survey'}</span>
              </div>
              <Row>
                {/* SUBJECT */}
                <Col id='form_section_11.1'>
                  {/* START DATETIME */}
                  <Form.Group className='mb-3'>
                    <Form.Label>
                      <img src={imgDescription} />
                      {t('Start DateTime')}
                    </Form.Label>
                    <Flatpickr
                      className='form-control'
                      data-enable-time
                      defaultValue={(user_timeline_metadata.start_datetime) ? moment(user_timeline_metadata.start_datetime).format('DD-MM-YYYY HH:mm:ss') : null}
                      // value={(user_timeline_metadata.start_datetime) ? moment(user_timeline_metadata.start_datetime).format('DD-MM-YYYY HH:mm:ss') : null}
                      onClose={([date]) => this.setStateUserTimelineMetadata('start_datetime', (date) ? date : null)}
                      options={{
                        dateFormat: 'd-m-Y H:i:S',
                        time_24hr: true
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col id='form_section_11.2'>
                  {/* END DATETIME */}
                  <Form.Group className='mb-3'>
                    <Form.Label>
                      <img src={imgDescription} />
                      {t('End DateTime')}
                    </Form.Label>
                    <Flatpickr
                      className='form-control'
                      data-enable-time
                      defaultValue={(user_timeline_metadata.end_datetime) ? moment(user_timeline_metadata.end_datetime).format('DD-MM-YYYY HH:mm:ss') : null}
                      // value={(user_timeline_metadata.end_datetime) ? moment(user_timeline_metadata.end_datetime).format('DD-MM-YYYY HH:mm:ss') : null}
                      onClose={([date]) => this.setStateUserTimelineMetadata('end_datetime', (date) ? date : null)}
                      options={{
                        dateFormat: 'd-m-Y H:i:S',
                        time_24hr: true
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col md="2" className='text-center' id='form_section_11.3'>
                  <Form.Label>
                    <img src={imgDescription} />
                    {t('Allow Resubmit')}
                  </Form.Label>
                  <Form.Check
                    type="switch"
                    style={{ marginTop: 10 }}
                    value={1}
                    checked={user_timeline_metadata.allow_resubmit}
                    onChange={(event) => this.setStateUserTimelineMetadata('allow_resubmit', (event.target.checked) ? true : false)}
                  />
                </Col>
              </Row>

              <div className={'d-flex flex-row align-items-center justify-content-between section-heading'}>
                <span className='heading'>{'Tag Users'}</span>
              </div>
              <Row id='form_section_11.4'>
                <Col md="12">
                  <Form.Group className='mb-3'>
                    <Form.Label>{t('Roles')}</Form.Label>
                    <Select
                      isMulti={true}
                      closeMenuOnSelect={false}
                      options={[{ value: 6, label: 'Parent/Student' }, { value: 3, label: 'Teachers' }, { value: 15, label: 'Curriculum Coordinator' }, { value: 4, label: 'Assistant' }]}
                      onChange={(event) => this.setStateUserTimelineMetadata('roles', event)}
                      value={user_timeline_metadata.roles}
                      menuPortalTarget={document.body}
                      styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                    />
                  </Form.Group>
                </Col>
              </Row>
              {/* WHEN PARENT/STUDENT IS SELECTED */}
              {user_timeline_metadata.roles.find((v, i) => v.value == 6) &&
                <div>
                  <Row>
                    <Col>
                      {/* CLASS */}
                      <Form.Group className='mb-3'>
                        <Form.Label>{t('Class')}</Form.Label>
                        <Select
                          isMulti={true}
                          options={this.props.authData.classes.map((v, i) => { return { label: v.class.class_theme, value: v.class.class_id, picture: v.class.class_logo, center_id: v.class.center_id, agegroup_id: v.class.agegroup_id } })}
                          onChange={(event) => {
                            this.setStateUserTimelineMetadata('class_ids', event)
                          }}
                          value={user_timeline_metadata.class_ids}
                          menuPortalTarget={document.body}
                          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                          formatOptionLabel={option => (
                            <div>
                              {option.picture ? <img src={portalURL + option.center_id + '/classes/' + option.picture} style={{ width: 40, height: 40, objectFit: 'cover', marginRight: 5 }} /> : ''}
                              <span style={{ fontSize: 18 }}>{option.label}</span>
                            </div>
                          )}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      {/* Term */}
                      <Form.Group className='mb-3'>
                        <Form.Label>{t('Term')}</Form.Label>
                        <Select
                          isMulti={true}
                          options={this.state.allTerms.map((v, i) => { return { label: v.term_name, value: v.term_id, active: (v.term_active == 1) ? true : false } })}
                          onChange={(event) =>
                            this.setStateUserTimelineMetadata('term_ids', event)
                          }
                          value={user_timeline_metadata.term_ids}
                          menuPortalTarget={document.body}
                          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                          formatOptionLabel={option => (
                            <div>
                              <span style={{ fontSize: 20 }}>
                                {option.label}
                                {option.active &&
                                  <Badge bg='success'>Active</Badge>
                                }
                              </span>
                            </div>
                          )}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </div>
              }
              {/* Questions */}
              <div className={'d-flex flex-row align-items-center justify-content-between section-heading'} id='form_section_11.5'>
                <span className='heading'>{'Questions'}</span>
                <div>
                  {this.state.surveyQuestionTypes.map((v, i) => {
                    return <Button key={i} variant='primary' className='mb-1' title={v.name} style={{ margin: '0 5px 0 5px' }} onClick={() => {
                      let SurveyQuestions = this.state.SurveyQuestions
                      let SurveyQuestionOptions = this.state.SurveyQuestionOptions
                      let survey_q_id = chance.unique(chance.integer, 1, { min: 99999999999, max: 999999999999 })[0]
                      let newOption = {
                        survey_q_id: survey_q_id,
                        survey_q_description: '',
                        eqtype_id: v.value,
                        survey_chart_type: '',
                        survey_q_comment_allow: '',
                        survey_q_comment_label: 'Commment',
                        survey_q_answers: [],
                      }
                      SurveyQuestions.push(newOption)
                      if (v.value == 'TF') {
                        SurveyQuestionOptions.push({
                          survey_q_opt_id: chance.unique(chance.integer, 1, { min: 99999999999, max: 999999999999 })[0],
                          survey_q_opt_desc: 'TRUE',
                          survey_q_opt_attachment: null,
                          survey_q_id: survey_q_id,
                          new: true
                        })
                        SurveyQuestionOptions.push({
                          survey_q_opt_id: chance.unique(chance.integer, 1, { min: 99999999999, max: 999999999999 })[0],
                          survey_q_opt_desc: 'FALSE',
                          survey_q_opt_attachment: null,
                          survey_q_id: survey_q_id,
                          new: true
                        })
                      }
                      else if (v.value == 'SCALE' || v.value == 'RATING') {
                        SurveyQuestionOptions.push({
                          survey_q_opt_id: chance.unique(chance.integer, 1, { min: 99999999999, max: 999999999999 })[0],
                          survey_q_opt_desc: "0",
                          survey_q_opt_attachment: null,
                          survey_q_id: survey_q_id,
                          new: true
                        })
                        SurveyQuestionOptions.push({
                          survey_q_opt_id: chance.unique(chance.integer, 1, { min: 99999999999, max: 999999999999 })[0],
                          survey_q_opt_desc: 1,
                          survey_q_opt_attachment: null,
                          survey_q_id: survey_q_id,
                          new: true
                        })
                        SurveyQuestionOptions.push({
                          survey_q_opt_id: chance.unique(chance.integer, 1, { min: 99999999999, max: 999999999999 })[0],
                          survey_q_opt_desc: "2",
                          survey_q_opt_attachment: null,
                          survey_q_id: survey_q_id,
                          new: true
                        })
                        SurveyQuestionOptions.push({
                          survey_q_opt_id: chance.unique(chance.integer, 1, { min: 99999999999, max: 999999999999 })[0],
                          survey_q_opt_desc: "3",
                          survey_q_opt_attachment: null,
                          survey_q_id: survey_q_id,
                          new: true
                        })
                        SurveyQuestionOptions.push({
                          survey_q_opt_id: chance.unique(chance.integer, 1, { min: 99999999999, max: 999999999999 })[0],
                          survey_q_opt_desc: "4",
                          survey_q_opt_attachment: null,
                          survey_q_id: survey_q_id,
                          new: true
                        })
                        SurveyQuestionOptions.push({
                          survey_q_opt_id: chance.unique(chance.integer, 1, { min: 99999999999, max: 999999999999 })[0],
                          survey_q_opt_desc: "5",
                          survey_q_opt_attachment: null,
                          survey_q_id: survey_q_id,
                          new: true
                        })
                      }

                      this.setState({ SurveyQuestions: SurveyQuestions, SurveyQuestionOptions })
                    }}>
                      <FontAwesomeIcon icon={v.icon} style={{}} />
                    </Button>
                  })}
                </div>
              </div>
              <Row>
                {this.state.SurveyQuestions.map((v, i) => {
                  return this.renderSurveyQuestions(v, i)
                })}
              </Row>
            </>)}

            {/* SECTION 12 */}
            {['Academic Activity'].includes(activeUserTimeline.ut_type) && (<>
              <div className={'d-flex flex-row align-items-center justify-content-between section-heading'} id='form_section_12'>
                <span className='heading'>{'Assign Activity'}</span>
              </div>
              <Row>
                {/* Activity */}
                <Col id='form_section_12.1'>
                  <Form.Group className='mb-3'>
                    <Form.Label>
                      <img src={imgDescription} />
                      {t('Activity')}
                    </Form.Label>
                    <Select
                      options={this.state.allAcademicActivities.map((v, i) => { return { label: v.aa_name, value: v.aa_id, center_id: v.center_id, picture: v.aa_image } })}
                      onChange={(event) => this.setState({ user_timeline: { ...user_timeline, ut_type_table_id: (event) ? event : null, ut_type_table: (event) ? 'academic_activities' : null } })}
                      value={user_timeline.ut_type_table_id}
                      menuPortalTarget={document.body}
                      styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                      formatOptionLabel={option => (
                        <div>
                          {option.picture ? <img src={portalURL + option.center_id + '/academic_activities/' + option.picture} style={{ width: 30, height: 30, objectFit: 'cover', marginRight: 5 }} /> : ''}
                          <span style={{ fontSize: 16 }}>{option.label}</span>
                        </div>
                      )}
                    />
                  </Form.Group>
                </Col>
                <Col id='form_section_12.2'>
                  {/* START DATETIME */}
                  <Form.Group className='mb-3'>
                    <Form.Label>
                      <img src={imgDescription} />
                      {t('Start DateTime')}
                    </Form.Label>
                    <Flatpickr
                      className='form-control'
                      data-enable-time
                      defaultValue={(user_timeline_metadata.start_datetime) ? moment(user_timeline_metadata.start_datetime).format('DD-MM-YYYY HH:mm:ss') : null}
                      // value={(user_timeline_metadata.start_datetime) ? moment(user_timeline_metadata.start_datetime).format('DD-MM-YYYY HH:mm:ss') : null}
                      onClose={([date]) => this.setStateUserTimelineMetadata('start_datetime', (date) ? date : null)}
                      options={{
                        dateFormat: 'd-m-Y H:i:S',
                        time_24hr: true
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col id='form_section_12.3'>
                  {/* END DATETIME */}
                  <Form.Group className='mb-3'>
                    <Form.Label>
                      <img src={imgDescription} />
                      {t('End DateTime')}
                    </Form.Label>
                    <Flatpickr
                      className='form-control'
                      data-enable-time
                      defaultValue={(user_timeline_metadata.end_datetime) ? moment(user_timeline_metadata.end_datetime).format('DD-MM-YYYY HH:mm:ss') : null}
                      // value={(user_timeline_metadata.end_datetime) ? moment(user_timeline_metadata.end_datetime).format('DD-MM-YYYY HH:mm:ss') : null}
                      onClose={([date]) => this.setStateUserTimelineMetadata('end_datetime', (date) ? date : null)}
                      options={{
                        dateFormat: 'd-m-Y H:i:S',
                        time_24hr: true
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>

            </>)}



            {/* SECTION 13 */}
            {['Policy'].includes(activeUserTimeline.ut_type) && (
              <div id='form_section_2'>
                <div className={'d-flex flex-row align-items-center justify-content-between section-heading'}>
                  <span className='heading'>{'Policy'}</span>
                </div>
                <Row>
                  <Col>
                    {/* ut_title */}
                    {['Policy'].includes(activeUserTimeline.ut_type) && (
                      <div id='form_section_2.1'>
                        <Form.Group className='mb-3'>
                          <Form.Label>
                            <img src={imgDescription} />
                            {t('Title')}
                          </Form.Label>
                          <Form.Control type='text' defaultValue={user_timeline.ut_title} onBlur={(event) => this.setStateUserTimeline('ut_title', event.target.value)} />
                        </Form.Group>
                      </div>
                    )}

                    {/* ut_description */}
                    {['Policy'].includes(activeUserTimeline.ut_type) && (
                      <div md={12} id='form_section_2.2'>
                        <Form.Group className='mb-3'>
                          <Form.Label>
                            <img src={imgDescription} />
                            {t('Description')}
                          </Form.Label>
                          {/* < Form.Control as="textarea" rows={3} defaultValue={user_timeline.ut_description} onBlur={(event) => this.setStateUserTimeline('ut_description', event.target.value)} /> */}
                          <CKEditor style={{ height: 140 }} editor={Editor} data={user_timeline.ut_description} onBlur={(event, editor) => this.setStateUserTimeline('ut_description', editor.getData())} />
                        </Form.Group>
                      </div>
                    )}
                  </Col>
                  <Col md='4'>
                    {/* ut_attachment_1 */}
                    {['Policy'].includes(activeUserTimeline.ut_type) &&
                      <div style={{ flexDirection: 'column' }} className='pic-prev d-flex justify-content-center mb-3' id='form_section_2.3'>
                        <div style={{ height: 175, padding: 10, overflow: 'hidden', borderStyle: 'dashed', borderColor: this.state.dragAreaColor.ut_attachment_1, borderWidth: 2, borderRadius: 20, position: 'relative' }} className='d-flex justify-content-center align-items-center'
                          onDragEnter={(e) => this.handleDragFileArea(e, 'ut_attachment_1')} onDragLeave={(e) => this.handleDragFileArea(e, 'ut_attachment_1')} onDragOver={(e) => this.handleDragFileArea(e, 'ut_attachment_1')} onDrop={(e) => {
                            // 
                            e.preventDefault();
                            e.stopPropagation();
                            if (e.dataTransfer.files && e.dataTransfer.files[0]) {
                              this.addAttachment({ target: { files: e.dataTransfer.files, multiple: false, name: 'ut_attachment_1' } })
                            }
                          }}>
                          {ut_attachment_1 && (!ut_attachment_1.data.type.includes('pdf')) && (
                            <img src={ut_attachment_1.url} alt={t('Preview')} style={{ height: '100%' }} />
                          )}
                          {ut_attachment_1 && (ut_attachment_1.data.type.includes('pdf')) && (
                            <img src={require('../../assets/images/pdf.png')} alt={t('PDF')} style={{ height: '100%' }} />
                          )}
                          {ut_attachment_1 == null &&
                            <button onClick={() => this.ut_attachment_1_ref.current.click()} style={{ background: 'none', border: 0, flex: 1 }}>
                              {/* <FontAwesomeIcon icon={faPlus} style={{ fontSize: 30, color: color18 }} /> */}
                              <span style={{ fontSize: 20, fontWeight: 'bold', color: brand_colors[this.props.defaultTheme.theme_id].color18 }}>{t('Custom Icon')}</span>
                            </button>
                          }

                          {ut_attachment_1 && (
                            <FontAwesomeIcon
                              icon={faTrashCan}
                              style={{ fontSize: 14, color: brand_colors[this.props.defaultTheme.theme_id].color11, position: 'absolute', bottom: 6, right: 6, cursor: 'pointer', backgroundColor: brand_colors[this.props.defaultTheme.theme_id].color8, borderRadius: 100, padding: 3 }}
                              onClick={() => this.deleteUserTimelineAttachment('ut_attachment_1')}
                            />
                          )}
                        </div>
                        <Form.Control className='d-none' ref={this.ut_attachment_1_ref} type='file' onChange={this.addAttachment} name='ut_attachment_1' />
                      </div>
                    }
                    {/* ut_attachment_2 */}
                    {['Policy'].includes(activeUserTimeline.ut_type) &&
                      <div style={{ flexDirection: 'column' }} className='pic-prev d-flex justify-content-center' id='form_section_2.4'>
                        <div style={{ height: 175, padding: 10, overflow: 'hidden', borderStyle: 'dashed', borderColor: this.state.dragAreaColor.ut_attachment_2, borderWidth: 2, borderRadius: 20, position: 'relative' }} className='d-flex justify-content-center align-items-center' onDragEnter={(e) => this.handleDragFileArea(e, 'ut_attachment_2')} onDragLeave={(e) => this.handleDragFileArea(e, 'ut_attachment_2')} onDragOver={(e) => this.handleDragFileArea(e, 'ut_attachment_2')} onDrop={(e) => {
                          // 
                          e.preventDefault();
                          e.stopPropagation();
                          if (e.dataTransfer.files && e.dataTransfer.files[0]) {
                            this.addAttachment({ target: { files: e.dataTransfer.files, multiple: false, name: 'ut_attachment_2' } })
                          }
                        }}>
                          {ut_attachment_2 && (!ut_attachment_2.data.type.includes('pdf')) && (
                            <img src={ut_attachment_2.url} alt={t('Preview')} style={{ height: '100%' }} />
                          )}
                          {ut_attachment_2 && (ut_attachment_2.data.type.includes('pdf')) && (
                            <img src={require('../../assets/images/pdf.png')} alt={t('PDF')} style={{ height: '100%' }} />
                          )}
                          {ut_attachment_2 == null &&
                            (
                              <button onClick={() => this.ut_attachment_2_ref.current.click()} style={{ background: 'none', border: 0, flex: 1 }}>
                                {/* <FontAwesomeIcon icon={faPlus} style={{ fontSize: 30, color: color18 }} /> */}
                                <span style={{ fontSize: 20, fontWeight: 'bold', color: brand_colors[this.props.defaultTheme.theme_id].color18 }}>{t('Attachment')}</span>
                              </button>
                            )
                          }
                          {ut_attachment_2 && (
                            <FontAwesomeIcon
                              icon={faTrashCan}
                              style={{ fontSize: 14, color: brand_colors[this.props.defaultTheme.theme_id].color11, position: 'absolute', bottom: 6, right: 6, cursor: 'pointer', backgroundColor: brand_colors[this.props.defaultTheme.theme_id].color8, borderRadius: 100, padding: 3 }}
                              onClick={() => this.deleteUserTimelineAttachment('ut_attachment_2')}
                            />
                          )}
                        </div>
                        <Form.Control className='d-none' ref={this.ut_attachment_2_ref} type='file' onChange={this.addAttachment} name='ut_attachment_2' />
                      </div>
                    }
                  </Col>
                </Row>
                <div className={'d-flex flex-row align-items-center justify-content-between section-heading'}>
                  <span className='heading'>{'Tag Users'}</span>
                </div>
                <Row id='form_section_13.1'>
                  <Col md="12">
                    <Form.Group className='mb-3'>
                      <Form.Label>{t('Roles')}</Form.Label>
                      <Select
                        isMulti={true}
                        closeMenuOnSelect={false}
                        options={[{ value: 'flag_students', label: 'Students' }, { value: 'flag_parents', label: 'Parents' }, { value: 'flag_users', label: 'Users' }]}
                        onChange={(event) => this.setStateUserTimelineMetadata('roles', event)}
                        value={user_timeline_metadata.roles}
                        menuPortalTarget={document.body}
                        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </div>
            )}

            {/* EXTRA PADDING FROM BOTTOM */}
            <div style={{ height: 400 }}></div>
          </Col>
        </div>
      </Offcanvas.Body >
    </Offcanvas >
  }

  // MARK FORM SECTIONS AS COMPLETE AFTER VERIFYING THE REQUIRED FIELDS DATA
  checkCompletedFormSections = () => {
    let { user_timeline, user_timeline_metadata, user_timeline_tagged, user_timeline_media, PollOptions, SurveyQuestions, SurveyQuestionOptions, ut_attachment_1, ut_attachment_2, assess_aspect, assess_curr } = this.state;

    if (this.checkCompletedFormSectionsTimer) {
      clearTimeout(this.checkCompletedFormSectionsTimer)
    }
    this.checkCompletedFormSectionsTimer = setTimeout(() => {
      let formSections = this.state.formSections;
      formSections = formSections.map((v, i) => {
        let data = v.data;
        let sectionComplete = true;
        data = data.map((vv, ii) => {
          if (vv.uid == '1.1') {
            let subSectionComplete = true;
            if (user_timeline.class_ids && user_timeline.class_ids.value) {
              subSectionComplete = true;
            } else {
              subSectionComplete = false;
              sectionComplete = false;
            }
            return { ...vv, completed: subSectionComplete };
          } else if (vv.uid == '1.2') {
            let subSectionComplete = true;
            if (user_timeline_tagged.length > 0) {
              subSectionComplete = true;
            } else {
              subSectionComplete = false;
              sectionComplete = false;
            }
            return { ...vv, completed: subSectionComplete };
          } else if (vv.uid == '2.1') {
            let subSectionComplete = true;
            if (user_timeline.ut_title) {
              subSectionComplete = true;
            } else {
              subSectionComplete = false;
              sectionComplete = false;
            }
            return { ...vv, completed: subSectionComplete };
          } else if (vv.uid == '2.2' || vv.uid == '7.1') {
            let subSectionComplete = true;
            if (user_timeline.ut_description) {
              subSectionComplete = true;
            } else {
              subSectionComplete = false;
              sectionComplete = false;
            }
            return { ...vv, completed: subSectionComplete };
          } else if (vv.uid == '2.3') {
            let subSectionComplete = true;
            if (ut_attachment_1) {
              subSectionComplete = true;
            } else {
              subSectionComplete = false;
              sectionComplete = false;
            }
            return { ...vv, completed: subSectionComplete };
          } else if (vv.uid == '2.4' || vv.uid == '6.4') {
            let subSectionComplete = true;
            if (ut_attachment_2) {
              subSectionComplete = true;
            } else {
              subSectionComplete = false;
              sectionComplete = false;
            }
            return { ...vv, completed: subSectionComplete };
          } else if (vv.uid == '2.5') {
            let subSectionComplete = true;
            if (user_timeline.ut_url) {
              subSectionComplete = true;
            } else {
              subSectionComplete = false;
              sectionComplete = false;
            }
            return { ...vv, completed: subSectionComplete };
          } else if (vv.uid == '5.1') {
            let subSectionComplete = true;
            if (user_timeline_metadata.fees) {
              subSectionComplete = true;
            } else {
              subSectionComplete = false;
              sectionComplete = false;
            }
            return { ...vv, completed: subSectionComplete };
          } else if (vv.uid == '5.2' || vv.uid == '9.1' || vv.uid == '8.2' || vv.uid == '12.2' || vv.uid == '10.1' || vv.uid == '11.1') {
            let subSectionComplete = true;
            if (user_timeline_metadata.start_datetime) {
              subSectionComplete = true;
            } else {
              subSectionComplete = false;
              sectionComplete = false;
            }
            return { ...vv, completed: subSectionComplete };
          } else if (vv.uid == '5.3' || vv.uid == '6.1' || vv.uid == '12.3' || vv.uid == '10.2' || vv.uid == '11.2') {
            let subSectionComplete = true;
            if (user_timeline_metadata.end_datetime) {
              subSectionComplete = true;
            } else {
              subSectionComplete = false;
              sectionComplete = false;
            }
            return { ...vv, completed: subSectionComplete };
          } else if (vv.uid == '3.1') {
            let subSectionComplete = true;
            if (user_timeline_metadata.album_id) {
              subSectionComplete = true;
            } else {
              subSectionComplete = false;
              sectionComplete = false;
            }
            return { ...vv, completed: subSectionComplete };
          } else if (vv.uid == '3.2') {
            let subSectionComplete = true;
            if (user_timeline_media.length > 0) {
              subSectionComplete = true;
            } else {
              subSectionComplete = false;
              sectionComplete = false;
            }
            return { ...vv, completed: subSectionComplete };
          } else if (vv.uid == '9.2') {
            let subSectionComplete = true;
            if (user_timeline_metadata.taken_to_hospital) {
              subSectionComplete = true;
            } else {
              subSectionComplete = false;
              sectionComplete = false;
            }
            return { ...vv, completed: subSectionComplete };
          } else if (vv.uid == '9.3') {
            let subSectionComplete = true;
            if (user_timeline_metadata.taken_immediately) {
              subSectionComplete = true;
            } else {
              subSectionComplete = false;
              sectionComplete = false;
            }
            return { ...vv, completed: subSectionComplete };
          } else if (vv.uid == '9.4') {
            let subSectionComplete = true;
            if (user_timeline_metadata.taken_to_hospital_name) {
              subSectionComplete = true;
            } else {
              subSectionComplete = false;
              sectionComplete = false;
            }
            return { ...vv, completed: subSectionComplete };
          } else if (vv.uid == '9.5' || vv.uid == '7.2' || vv.uid == '6.2') {
            let subSectionComplete = true;
            if (user_timeline_metadata.description2) {
              subSectionComplete = true;
            } else {
              subSectionComplete = false;
              sectionComplete = false;
            }
            return { ...vv, completed: subSectionComplete };
          } else if (vv.uid == '7.3') {
            let subSectionComplete = true;
            if (assess_aspect.length > 0 || assess_curr.length > 0) {
              subSectionComplete = true;
            } else {
              subSectionComplete = false;
              sectionComplete = false;
            }
            return { ...vv, completed: subSectionComplete };
          } else if (vv.uid == '8.1' || vv.uid == '6.3') {
            let subSectionComplete = true;
            if (user_timeline_metadata.classmaterial_id) {
              subSectionComplete = true;
            } else {
              subSectionComplete = false;
              sectionComplete = false;
            }
            return { ...vv, completed: subSectionComplete };
          } else if (vv.uid == '12.1') {
            let subSectionComplete = true;
            if (user_timeline.ut_type_table_id) {
              subSectionComplete = true;
            } else {
              subSectionComplete = false;
              sectionComplete = false;
            }
            return { ...vv, completed: subSectionComplete };
          } else if (vv.uid == '10.3' || vv.uid == '11.3') {
            let subSectionComplete = true;
            if (user_timeline_metadata.allow_resubmit) {
              subSectionComplete = true;
            } else {
              subSectionComplete = false;
              sectionComplete = false;
            }
            return { ...vv, completed: subSectionComplete };
          }
          else if (vv.uid == '10.4' || vv.uid == '11.4' || vv.uid == '13.1') {
            let subSectionComplete = true;
            if (user_timeline_metadata.roles.length > 0) {
              subSectionComplete = true;
            } else {
              subSectionComplete = false;
              sectionComplete = false;
            }
            return { ...vv, completed: subSectionComplete };
          }
          else if (vv.uid == '10.5') {
            let subSectionComplete = true;
            if (PollOptions.length > 0) {
              subSectionComplete = true;
            } else {
              subSectionComplete = false;
              sectionComplete = false;
            }
            return { ...vv, completed: subSectionComplete };
          }
          else if (vv.uid == '11.5') {
            let subSectionComplete = true;
            if (SurveyQuestions.length > 0) {
              subSectionComplete = true;
            } else {
              subSectionComplete = false;
              sectionComplete = false;
            }
            return { ...vv, completed: subSectionComplete };
          }
          else {
            sectionComplete = false;
            return { ...vv };
          }
        });
        return { ...v, data, completed: sectionComplete };
      });
      this.setState({ formSections: formSections });
    }, 1000);

  };

  activityData = async (data) => {
    const { authData } = this.props;
    const { auth_key } = authData.loginData;
    const { center_id } = authData.centerData;
    try {
      const res = await GET('academic-activities/edit-data', {
        params: {
          aa_id: data.academic_activities.aa_id,
        },
      });
      if (res.status == 200) {
        this.setState({
          activityData: { ...res.data, user_timeline: data }
        });
      }
    } catch (err) {
      console.log('err', err)
      toast.error(t('Something went wrong while fetching total coefficient!'));

    }
  };

  changeToFromSection = (parent, child) => {
    this.setState(
      {
        formSectionActive: { parent: parent, child: child },
      },
      () => {
        if (parent == 2) {
          let thisChild = this.state.formSectionsActivity[1].data.find((v, i) => v.uid == child)


          setTimeout(() => {
            // console.log(this.ut_title_Ref)
            // console.log(this.ut_description_Ref)
            // console.log(this.description2_Ref)

            this.ut_title_Ref.current.value = (thisChild.filledData) ? thisChild.filledData.ut_title : ''
            this.ut_description_Ref.current.value = (thisChild.filledData) ? thisChild.filledData.ut_description : ''
            this.description2_Ref.current.value = (thisChild.filledData) ? thisChild.filledData.description2 : ''
          }, 300);

          if (thisChild.filledData) {
            this.setState({
              user_timeline: {
                ut_id: thisChild.filledData.ut_id,
                ut_title: thisChild.filledData.ut_title,
                ut_description: thisChild.filledData.ut_description,
                ut_url: thisChild.filledData.ut_url,
                ut_pintotop: thisChild.filledData.ut_pintotop,
                ut_publish_on: thisChild.filledData.ut_publish_on,
                ut_publish_status: thisChild.filledData.ut_publish_status,
                ut_type: 'Assessment',
                utc_type: 'Teacher Parent',
                class_ids: thisChild.filledData.class_ids,
                ut_type_table_id: thisChild.filledData.ut_type_table_id,
                ut_type_table: thisChild.filledData.ut_type_table,
              },
              user_timeline_metadata: {
                academic_activity_ut_id: thisChild.filledData.academic_activity_ut_id,
                description2: thisChild.filledData.description2,
              },
              user_timeline_media: thisChild.filledData.user_timeline_media.map((v) => {
                return { data: { type: "image" }, url: portalURL + v.center_id + '/user_timeline_media/' + v.utm_name, utm_id: v.utm_id }
              }),
              assess_aspect: thisChild.filledData.assess_aspect,
              assess_curr: thisChild.filledData.assess_curr,
            })
          } else {
            this.setState({
              user_timeline: {
                ut_id: null,
                ut_title: '',
                ut_description: '',
                ut_url: '',
                ut_pintotop: 0,
                ut_publish_on: null,
                ut_publish_status: 1,
                ut_type: 'Assessment',
                utc_type: 'Teacher Parent',
                class_ids: {},
                ut_type_table_id: null,
                ut_type_table: null,
              },
              user_timeline_metadata: {
                academic_activity_ut_id: null,
                description2: '',
              },
              user_timeline_media: [],
              assess_aspect: [],
              assess_curr: [],
            })
          }

        }
        setTimeout(() => {
          const element = document.getElementById('form_section_' + child);
          if (element) {
            // 👇 Will scroll smoothly to the top of the next section
            element.scrollIntoView({ behavior: 'smooth' });
          }
        }, 300);
      }
    );
  };

  renderActivity = () => {
    const { activityModal, formSectionsActivity, formSectionActive, working, activityData, academicLessonAttributes, user_timeline, user_timeline_metadata, assess_aspect, assess_curr, user_timeline_media } = this.state

    if (formSectionActive.parent == '1') {
      const transdisciplinary_theme = activityData.academic_activity_resources.find((v, i) => v.aar_type == 'transdisciplinary_theme')
      return (<>
        <Row>
          <Col md="8">
            <div style={{ fontSize: 16, fontWeight: 'bold', color: brand_colors[this.props.defaultTheme.theme_id].color9, opacity: 0.7 }}>
              {activityData.user_timeline.ut_title}
            </div>
            <div style={{ fontSize: 13, color: brand_colors[this.props.defaultTheme.theme_id].color9, opacity: 0.7 }}>
              {activityData.user_timeline.ut_description}
            </div>
          </Col>
          <Col>
            <div style={{ fontSize: 12, color: brand_colors[this.props.defaultTheme.theme_id].color9, opacity: 0.7 }}><strong style={{ marginRight: 5, color: brand_colors[this.props.defaultTheme.theme_id].color4 }}>Start:</strong>{moment(activityData.user_timeline.start_datetime).format('DD-MM-YYYY HH:mm:ss')}</div>
            <div style={{ fontSize: 12, color: brand_colors[this.props.defaultTheme.theme_id].color9, opacity: 0.7 }}><strong style={{ marginRight: 5, color: brand_colors[this.props.defaultTheme.theme_id].color11 }}>End:</strong>{moment(activityData.user_timeline.end_datetime).format('DD-MM-YYYY HH:mm:ss')}</div>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md={3}>
            <div style={{ padding: '10px 10px 10px 0' }}>
              <img src={(activityData.academic_activities.aa_image) ? portalURL + activityData.academic_activities.center_id + '/academic_activities/' + activityData.academic_activities.aa_image : 'https://via.placeholder.com/300x150?text=No+Thumbnail'} alt={t('Preview')} style={{ width: '100%' }} />
            </div>
          </Col>
          <Col>
            <div className={'d-flex flex-column align-items-center'}>
              <div className={'d-flex flex-row align-items-center'}>
                <span style={{ fontSize: 16, color: brand_colors[this.props.defaultTheme.theme_id].color9, opacity: 0.8 }}>{activityData.academic_activities.aa_name}</span>
              </div>
              <div style={{ fontSize: 14, color: brand_colors[this.props.defaultTheme.theme_id].color9, opacity: 0.7 }}>{activityData.academic_activities.aa_desc}</div>
            </div>
          </Col>
          <Col md={3}>
            <div className='div-box-theme' style={{ marginRight: 0 }}>
              {transdisciplinary_theme && academicLessonAttributes.map((vvv, iii) => {
                return (vvv.ala_id == transdisciplinary_theme.aar_type_id && vvv.ala_type == 'lesson_focus') &&
                  <div className='d-flex flex-row align-items-center' key={iii}>
                    <div className='div_image'>
                      <img src={portalURL + vvv.center_id + '/academic_lesson_attributes/' + vvv.ala_description_2} className="image" />
                    </div>
                    <div className='heading'>{vvv.ala_title}</div>
                  </div>

              })}

              <div className='d-flex flex-row flex-wrap' style={{ marginTop: '10px' }}>
                {transdisciplinary_theme && academicLessonAttributes.map((vvv, iii) => {
                  let academicSubjectLessonResourcesFind = activityData.academic_activity_resources
                    .find((vvvv, iiii) => {
                      return (vvvv.aar_type == 'transdisciplinary_theme_focus' && vvvv.aar_id == vvv.ala_id)
                    })
                  return (vvv.ala_type == 'lesson_focus' && academicSubjectLessonResourcesFind)
                    ? (<div key={iii} className="d-flex flex-row justify-content-center">
                      <FontAwesomeIcon icon={faCircle} color={brand_colors[this.props.defaultTheme.theme_id].color18} style={{ fontSize: 12, padding: '8px' }} />
                      <span className='title'>{vvv.ala_title}</span>
                    </div>) : null
                })}
              </div>
            </div>
            <div style={{ marginTop: 10 }}>
              {activityData.academic_activities.aa_audio && <audio src={activityData.academic_activities.aa_audio} controls style={{ width: '100%' }} />}
            </div>
          </Col>
        </Row>
        <Row>
          {/* ALL ATTACHMENTS */}
          {activityData.academic_activity_attachments.map((v, i) => {
            return (
              <Col key={i} md='3' className='mb-1'>
                <div style={{ position: 'relative', borderRadius: 5, borderStyle: 'solid', borderWidth: 1, borderColor: '#ced4da', height: 100, padding: 10 }} className='d-flex justify-content-center align-items-center'>
                  {/* IMAGE */}
                  {v.aa_attach_type.includes('image') && (
                    <>
                      <img src={portalURL + v.center_id + '/academic_activity_attachments/' + v.aa_attach_name} alt={t('Preview')} style={{ height: '100%' }} />
                    </>
                  )}
                  {/* AUDIO */}
                  {v.aa_attach_type.includes('audio') && (
                    <>
                      <img src={audioAttachment} alt={t('Preview')} style={{ height: '100%' }} />
                    </>
                  )}
                  {/* PDF */}
                  {v.aa_attach_type.includes('application') && (
                    <>
                      <img src={pdfAttachment} alt={t('Preview')} style={{ height: '100%' }} />
                    </>
                  )}
                </div>
              </Col>
            );
          })}
        </Row>

        <Row>
          <Col>
            <div className={'d-flex flex-row align-items-center justify-content-between section-heading'} id='form_section_1.7'>
              <span className='heading'>{'Skills'}</span>
            </div>
            <div className='d-flex flex-row flex-wrap' style={{ marginTop: '10px' }}>
              {activityData.academic_activity_resources.map((v, i) => {
                return v.aar_type == 'skill' ? (
                  <div key={i} className='d-flex flex-row justify-content-center align-items-center listgroup'>
                    <span className='title'>{v.aar_title}</span>
                  </div>
                ) : null;
              })}
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className={'d-flex flex-row align-items-center justify-content-between section-heading'} id='form_section_1.7'>
              <span className='heading'>{'Goals & Success Criteria'}</span>
            </div>
            <div className='d-flex flex-row flex-wrap' style={{ marginTop: '10px' }}>
              {activityData.academic_activity_resources.map((v, i) => {
                return v.aar_type == 'success' ? (
                  <div key={i} className='d-flex flex-row justify-content-center align-items-center listgroup'>
                    <span className='title'>{v.aar_title}</span>
                  </div>
                ) : null;
              })}
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className={'d-flex flex-row align-items-center justify-content-between section-heading'} id='form_section_1.7'>
              <span className='heading'>{'Objectives'}</span>
            </div>
            <div className='d-flex flex-row flex-wrap' style={{ marginTop: '10px' }}>
              {activityData.academic_activities.curriculums.map((v, i) => {
                return (
                  <div key={i} className='d-flex flex-row justify-content-center align-items-center listgroup'>
                    <span className='title'>{v.curriculum_code}</span>
                  </div>
                )
              })}
            </div>
          </Col>
        </Row>
      </>)
    }
    else if (formSectionActive.parent == '2') {

      let thisChild = null
      formSectionsActivity.map((v, i) => {
        if (v.uid == formSectionActive.parent) {
          thisChild = v.data.find((vv, ii) => vv.uid == formSectionActive.child)
        }
      })

      // 

      return (<>
        <div className={'d-flex flex-row align-items-center justify-content-between section-heading'}>
          <div className='d-flex flex-row align-items-end justify-content-center mb-1'>
            <div style={{ position: 'relative', borderRadius: 5, borderStyle: 'solid', borderWidth: 1, borderColor: '#ced4da', height: 60, padding: 10, marginRight: 10 }} className='d-flex justify-content-center align-items-center'>
              <img src={thisChild.image} alt={t('Preview')} style={{ height: '100%' }} />
            </div>
            <span className='heading'>{thisChild.name}</span>
          </div>
          <div>
            {activityData.academic_activity_questions.length > 0 &&
              <Button variant='warning'>Show Activity</Button>
            }
            <Button variant='warning' onClick={() => setTimeout(() => {
              this.saveUserTimelineActivity()
            }, 300)}>{(this.state.utWorking) ? <Spinner as='span' animation='grow' size='sm' role='status' aria-hidden='true' /> : 'Update'}</Button>
          </div>
        </div>
        <Row>
          <Col md={6}>
            <Form.Group className='mb-3'>
              <Form.Label>
                <img src={imgDescription} />
                {t('Title')}
              </Form.Label>
              <Form.Control type='text' defaultValue={user_timeline.ut_title} onBlur={(event) => this.setStateUserTimeline('ut_title', event.target.value)} ref={this.ut_title_Ref} />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          {/* Observation */}
          <Col>
            <Form.Group className="mb-3">
              <Form.Label>{t('Observation')}</Form.Label>
              <Form.Control as="textarea" rows={3} defaultValue={user_timeline.ut_description} onBlur={(event, editor) => this.setStateUserTimeline('ut_description', event.target.value)} ref={this.ut_description_Ref} />
            </Form.Group>
          </Col>
          {/* Next Steps */}
          <Col >
            <Form.Group className="mb-3">
              <Form.Label>{t('Next Steps')}</Form.Label>
              <Form.Control as="textarea" rows={3} defaultValue={user_timeline_metadata.description2} onBlur={(event, editor) => this.setStateUserTimelineMetadata('description2', event.target.value)} ref={this.description2_Ref} />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          {/* ADD ATTACHMENT */}
          <Col md='3' className='mb-1' id='form_section_3.2'>
            <div style={{ flexDirection: 'column' }} className='pic-prev d-flex justify-content-center'>
              <div style={{ height: 100, display: 'flex', overflow: 'hidden', borderStyle: 'dashed', borderColor: brand_colors[this.props.defaultTheme.theme_id].color18, borderWidth: 2, borderRadius: 20 }}>
                <button onClick={() => this.filePicker.current.click()} style={{ background: 'none', border: 0, flex: 1 }}>
                  <FontAwesomeIcon icon={faPlus} style={{ fontSize: 30, color: brand_colors[this.props.defaultTheme.theme_id].color18 }} />
                </button>
              </div>
              <Form.Control className='d-none' ref={this.filePicker} type='file' multiple onChange={this.addAttachment} name='user_timeline_media' />
            </div>
          </Col>
          {/* ALL ATTACHMENTS */}
          {user_timeline_media.map((v, i) => {
            return (
              <Col key={i} md='3' className='mb-1'>
                <div style={{ position: 'relative', borderRadius: 5, borderStyle: 'solid', borderWidth: 1, borderColor: '#ced4da', height: 100, padding: 10 }} className='d-flex justify-content-center align-items-center'>
                  {/* IMAGE */}
                  {v.data.type.includes('image') && (
                    <>
                      <img src={v.url} alt={t('Preview')} style={{ height: '100%' }} />
                    </>
                  )}
                  <button
                    onClick={() => this.deleteAssignedActivityMedia(v, i)}
                    style={{ background: 'none', border: 0 }}
                  >
                    <FontAwesomeIcon icon={faTrashCan} style={{ fontSize: 12, color: brand_colors[this.props.defaultTheme.theme_id].color11, position: 'absolute', bottom: 5, right: 5, cursor: 'pointer' }} />
                  </button>
                </div>
              </Col>
            );
          })}
        </Row>
        <div className={'d-flex flex-row align-items-center justify-content-between section-heading'}>
          <span className='heading'>{t('Observation')}</span>
        </div>

        <Row>
          <Col>
            {this.renderAssessmentList()}
          </Col>
        </Row>
        <div style={{ height: 400 }}></div>
      </>
      )
    }
  }

  getActivityResponses = (data) => {
    const { authData } = this.props;
    const { center_id } = authData.centerData;
    const { auth_key } = authData.loginData;
    let fData = new FormData();
    fData.append('center_id', center_id)
    fData.append('ut_id', data.ut_id)
    fData.append('user_timeline_tagged', JSON.stringify(data.user_timeline_tagged))
    try {
      axios.post(
        apiURL + 'assesslog/get-academic-activity-responses',
        fData,
        {
          params: {
            auth_key,
            appname: process.env.REACT_APP_NAME,
          },
        }
      ).then((res) => {
        // 
        if (res.status == 200) {
          let formSectionsActivity = this.state.formSectionsActivity
          formSectionsActivity = formSectionsActivity.map((vv, ii) => {
            if (vv.uid == '2') {
              vv.data = vv.data.map((vvv, iii) => {
                let findData = res.data.find((v, i) => v.utt_id == vvv.data.utt_id)
                if (findData) {
                  vvv['completed'] = true
                  vvv['filledData'] = findData
                }
                return vvv
              })
            }
            return vv
          })
          this.setState({ formSectionsActivity: formSectionsActivity, utWorking: false })
        }
      })
    } catch (err) {
      console.log('err', err)
      toast.error(
        t('Something went wrong while fetching Lesson Attributes!')
      );

    }
  }

  showExam = (parent, child) => {

    const { formSectionsExam } = this.state;

    const indexOfChild = formSectionsExam[1].data.findIndex((item) => item.uid == child);


    let formSectionsExam_LOCK_UPDATED = formSectionsExam;

    if (formSectionsExam[1].data[indexOfChild].exam_attempt_id) {
      formSectionsExam[1].data[indexOfChild].is_locked = true;
      formSectionsExam_LOCK_UPDATED = [{ ...formSectionsExam[0] }, { ...formSectionsExam[1] }];
    }

    this.setState(
      {
        formSectionActive: { parent: parent, child: child },
        studentExamData: null,
        studentExamDataAnswers: null,
        formSectionsExam: formSectionsExam_LOCK_UPDATED
      },
      () => {
        if (parent == 2) {

          const { examData } = this.state;

          let thisChild = this.state.formSectionsExam[1].data.find((v, i) => v.uid == child)
          // 
          // 

          const { authData } = this.props;
          const { center_id, center_timezone } = authData.centerData;
          const { auth_key } = authData.loginData;
          let fData = new FormData();
          fData.append('center_id', center_id);
          fData.append('center_timezone', center_timezone);
          fData.append('exams_id', examData.exams.exams_id);
          fData.append('child_id', thisChild.data.child_id);
          fData.append('parent_id', thisChild.data.parent_id);
          fData.append('class_id', thisChild.data.class_id);

          try {
            axios.post(
              apiURL + 'examcorrection/get-exam-details',
              fData,
              {
                params: {
                  auth_key,
                  appname: process.env.REACT_APP_NAME,
                },
              }
            ).then((res) => {


              if (res.status) {




                this.setState({
                  studentExamData: res.data.data,
                  studentExamDataAnswers: this.verifyAnswers(res.data.data)
                });
              }

            });
          } catch (err) {
            console.log('err', err)
            toast.error(
              t('Something went wrong while fetching student exam!')
            );

          }


        }
        setTimeout(() => {
          const element = document.getElementById('form_section_' + child);
          if (element) {
            // 👇 Will scroll smoothly to the top of the next section
            element.scrollIntoView({ behavior: 'smooth' });
          }
        }, 300);
      }
    );
  };

  verifyAnswers = (studentExamData) => {
    const { authData } = this.props;
    const { center_id, center_timezone } = authData.centerData;
    const { examData } = this.state;
    const { formSectionsExam, formSectionActive } = this.state;
    const studentExamDataAnswers = [];

    let thisChild = null
    formSectionsExam.map((v, i) => {
      if (v.uid == formSectionActive.parent) {
        thisChild = v.data.find((vv, ii) => vv.uid == formSectionActive.child)
      }
    })




    const commonDataSet = {
      center_id: center_id,
      child_id: thisChild.data.child_id,
      parent_id: thisChild.data.parent_id,
      class_id: thisChild.data.class_id,
      exams_id: examData.exams.exams_id,
    };


    if (studentExamData) {
      studentExamData.Examsquest.map((quest, quest_i) => {


        if (quest.examsquesttype.eqtype_id != '9') {

          if (studentExamData.ExamAnswers && studentExamData.ExamAnswers.length > 0) {

            const foundAnswer = studentExamData.ExamAnswers.find(item => item.examsquest_id == quest.examsquest_id);



            if (foundAnswer.is_correct == 'correct') {
              studentExamDataAnswers.push({
                ...commonDataSet,
                exam_ans_id: foundAnswer.exam_ans_id,
                examsquest_id: quest.examsquest_id,
                answer: null,
                attachment: null,
                examsquest_duration: "0",
                is_time_over: "0",
                answer_grade: foundAnswer.answer_grade,
                is_correct: "correct",
                checked_by_employee: null,
                checked_by_datetime: null,
                confirmed_answer_grade: foundAnswer.answer_grade,
                examAns: foundAnswer.examAns,
              });
            } else if (foundAnswer.is_correct == 'wrong') {
              studentExamDataAnswers.push({
                ...commonDataSet,
                exam_ans_id: foundAnswer.exam_ans_id,
                examsquest_id: quest.examsquest_id,
                answer: null,
                attachment: null,
                examsquest_duration: "0",
                is_time_over: "0",
                answer_grade: foundAnswer.answer_grade,
                is_correct: "wrong",
                checked_by_employee: null,
                checked_by_datetime: null,
                confirmed_answer_grade: foundAnswer.confirmed_answer_grade,
                examAns: foundAnswer.examAns,
              });
            } else if (foundAnswer.is_correct == 'partial correct') {
              studentExamDataAnswers.push({
                ...commonDataSet,
                exam_ans_id: foundAnswer.exam_ans_id,
                examsquest_id: quest.examsquest_id,
                answer: null,
                attachment: null,
                examsquest_duration: "0",
                is_time_over: "0",
                answer_grade: foundAnswer.answer_grade,
                is_correct: "partial correct",
                checked_by_employee: null,
                checked_by_datetime: null,
                confirmed_answer_grade: foundAnswer.confirmed_answer_grade,
                examAns: foundAnswer.examAns,
              });
            } else if (foundAnswer.is_correct == '') {
              studentExamDataAnswers.push({
                ...commonDataSet,
                exam_ans_id: foundAnswer.exam_ans_id,
                examsquest_id: quest.examsquest_id,
                answer: null,
                attachment: null,
                examsquest_duration: "0",
                is_time_over: "0",
                answer_grade: foundAnswer.answer_grade,
                is_correct: "",
                checked_by_employee: null,
                checked_by_datetime: null,
                confirmed_answer_grade: foundAnswer.confirmed_answer_grade,
                examAns: foundAnswer.examAns,
              });
            }


          } else {

            studentExamDataAnswers.push({
              ...commonDataSet,
              exam_ans_id: 0,
              examsquest_id: quest.examsquest_id,
              answer: null,
              attachment: null,
              examsquest_duration: "0",
              is_time_over: "0",
              answer_grade: "0",
              is_correct: "",
              checked_by_employee: null,
              checked_by_datetime: null,
              confirmed_answer_grade: "0",
              examAns: [],
            });

          }
        }

      });
    }



    return studentExamDataAnswers;


  }

  renderExam = () => {
    const { examModal, formSectionsExam, formSectionActive, working, examData } = this.state;

    if (formSectionActive.parent == '1') {

      const exam = examData.exams;
      // 

      return (<>
        <Row>
          <Col>
            <h5>{exam.exams_name}</h5>
          </Col>
          <Col className='text-end'>
            <p className='mb-1'>
              <FontAwesomeIcon
                icon={faCalendar}
                className='me-1'
              />{' '}
              {moment(
                exam.exams_date,
                'YYYY-MM-DD'
              ).format('dddd, MMMM Do YYYY')}
            </p>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <p className='mb-1'>
              <FontAwesomeIcon
                icon={faTh}
                className='me-1'
              />{' '}
              {exam.examstype_name} (
              {exam.examstypegrp_name})
            </p>
          </Col>
          <Col className='text-center'>
            <p className='mb-1'>
              <FontAwesomeIcon
                icon={faBook}
                className='me-1'
                color={brand_colors[this.props.defaultTheme.theme_id].color4}
              />{' '}
              {exam.acs_name}
            </p>
          </Col>
          <Col className='text-center'>
            <p className='mb-1'>
              <FontAwesomeIcon
                icon={faTachometer}
                className='me-1'
              />{' '}
              <span
                style={{
                  color: exam
                    .eqdifficulty_color,
                }}>
                {exam.eqdifficulty_name}
              </span>
            </p>
          </Col>
          <Col className='text-center'>
            <p className='mb-1'>
              <FontAwesomeIcon
                icon={faQuestionCircle}
                className='me-1'
              />{' '}
              {exam.total_questions}
            </p>
          </Col>
          <Col className='text-end'>
            <p className='mb-1'>
              <FontAwesomeIcon
                icon={faAward}
                className='me-1'
              />{' '}
              {exam.exams_grade} ({t('Total Grade')}
              )
            </p>
          </Col>
        </Row></>)
    }
    else if (formSectionActive.parent == '2') {

      let thisChild = null
      formSectionsExam.map((v, i) => {
        if (v.uid == formSectionActive.parent) {
          thisChild = v.data.find((vv, ii) => vv.uid == formSectionActive.child)
        }
      })

      let examsgrade_comment = '';

      const { studentExamData, studentExamDataAnswers, working } = this.state;

      if (studentExamData) {
        const { Examsgrade } = studentExamData;
        examsgrade_comment = Examsgrade.examsgrade_comment;
      }



      // 

      const { current_datetime, exams } = examData;
      const { exams_date, close_time } = exams;
      const start = moment(exams_date + ' ' + close_time + ':00');
      const end = moment(current_datetime);
      const diff = start.diff(end, 'seconds');

      const is_correct_opts = [
        { value: '', label: '--Select--' },
        { value: 'correct', label: 'correct' },
        { value: 'wrong', label: 'wrong' },
        { value: 'partial correct', label: 'partial correct' },
      ];

      // 
      // 

      return (<div>
        <Row className='mb-3'>
          <Col><Form.Control ref={this.examsgrade_comment} defaultValue={examsgrade_comment} type="text" placeholder={t('Teacher comment...')} /></Col>
          <Col md={2} style={{ textAlign: 'right' }}>
            <div className="d-grid"><Button variant="warning" onClick={() => this.saveExamCorrections()} disabled={diff >= 0}>{working ? <Spinner as='span' animation='grow' size='sm' /> : t('Save Correction')}</Button></div></Col>
        </Row>
        {studentExamData && studentExamData.Examsquest.map((quest, quest_i) => {

          if (quest.examsquesttype.eqtype_id != '9') {

            const foundVerifiedAnswer = studentExamDataAnswers.find(item => item.examsquest_id == quest.examsquest_id);
            const foundVerifiedAnswerIndex = studentExamDataAnswers.findIndex(item => item.examsquest_id == quest.examsquest_id);




            const is_correct = foundVerifiedAnswer.is_correct;

            return (
              <Card
                key={quest_i}
                className='mb-3 q-card'>
                <Card.Body>
                  <div className='q-card-inner'>
                    <div>
                      <Badge bg="dark">
                        {
                          quest
                            .examsquesttype
                            .eqtype_name
                        }
                      </Badge>{' '}
                      <Badge bg="primary">
                        {
                          quest
                            .eqtype_id
                            .label
                        }
                      </Badge>{' '}
                      <Badge bg="info">
                        {t(
                          'Duration: '
                        ) +
                          quest.examsquest_duration + ' ' + t('Minutes')
                        }
                      </Badge>
                      {' '}
                      <Badge bg="danger">
                        {t(
                          'Difficulty: '
                        ) +
                          quest.eqdifficulty_name
                        }
                      </Badge>
                    </div>
                    <h5>
                      <strong>
                        <small>
                          {quest.examsquest_desc1.replace(
                            /(<([^>]+)>)/gi,
                            ' '
                          )}
                        </small>
                      </strong>
                    </h5>
                    <div className='quest-answers'>
                      {quest.examsquestoptions.map((item2, index) => {

                        let badgeClass = ''

                        if (item2.is_checked == 1 && foundVerifiedAnswer && is_correct == "") {
                          badgeClass = 'badge badge-light-primary'
                        }
                        if (item2.is_checked == 1 && foundVerifiedAnswer && is_correct == "correct") {
                          badgeClass = 'badge badge-light-success'
                        }
                        if (item2.is_checked == 1 && foundVerifiedAnswer && is_correct == "wrong") {
                          badgeClass = 'badge badge-light-danger'
                        }

                        return (
                          <div key={index}>
                            <div><span className={badgeClass} style={{ fontSize: 16 }}>{index + 1} : {quest
                              .eqtype_id.value == 8 ? item2.option_value : item2.option_text}</span></div>
                          </div>
                        )
                      }
                      )}
                    </div>
                    <hr />
                    <Row>
                      <Col><span className="badge badge-light-danger me-2">Wrong picked answer</span>
                        <span className="badge badge-light-success me-2">Correct picked answer</span>
                        <span className="badge badge-light-primary">Correct answer</span></Col>
                    </Row>
                    <hr />
                    <Row>
                      <Col>
                        <Badge bg="primary" style={{ fontSize: 16, display: 'block', height: 37, lineHeight: '24px' }}>
                          {t(
                            'Confirm Grade'
                          )}
                        </Badge>
                      </Col>
                      <Col>
                        <Badge bg="warning" style={{ fontSize: 16, display: 'block', height: 37, lineHeight: '24px' }}>
                          {t(
                            'Total Marks: '
                          ) +
                            quest.examsquest_grade
                          }
                        </Badge>
                      </Col>
                      <Col>
                        <Badge bg="info" style={{ fontSize: 16, display: 'block', height: 37, lineHeight: '24px' }}>
                          {foundVerifiedAnswer.confirmed_answer_grade}
                        </Badge>
                      </Col>
                      <Col>
                        <Select onChange={this.updateVerifiedAnswer(foundVerifiedAnswerIndex, 'correction')} value={{ value: foundVerifiedAnswer.is_correct, label: foundVerifiedAnswer.is_correct ? foundVerifiedAnswer.is_correct : '--Select--' }} options={is_correct_opts}>
                        </Select>
                      </Col>
                      <Col>
                        <Form.Control className='examgrades' type="number" defaultValue={foundVerifiedAnswer.confirmed_answer_grade} onChange={this.updateVerifiedAnswer(foundVerifiedAnswerIndex, 'grades', quest.examsquest_grade)} />
                      </Col>
                    </Row>
                  </div>
                </Card.Body>
              </Card>
            )
          }
        }
        )}
      </div>);
    }
  }


  examData = async (data) => {
    this.setState({
      examData: data
    });
  };


  getExam = (data) => {
    const { authData, selectedClass } = this.props;
    const { center_id } = authData.centerData;
    const { auth_key } = authData.loginData;

    try {
      GET('exams/get-exam-single',
        {
          params: {
            exams_id: data.exams.exams_id,
            class_id: (selectedClass) ? selectedClass.class.class_id : null
          },
        }
      ).then((res) => {
        // 
        if (res.status == 200) {
          let formSectionsExam = this.state.formSectionsExam
          formSectionsExam = formSectionsExam.map((vv, ii) => {
            if (vv.uid == '2') {
              vv.data = vv.data.map((vvv, iii) => {
                let foundData = res.data.find((v, i) => v.child_id == vvv.data.child_id);
                vvv['completed'] = foundData.exam_attempt_id ? true : false;
                vvv['is_locked'] = foundData.is_locked == 1 ? true : false;
                vvv['exam_attempt_id'] = foundData.exam_attempt_id;
                vvv['filledData'] = true;
                return vvv
              })
            }
            return vv
          })
          this.setState({ formSectionsExam: formSectionsExam, utWorking: false })
        }
      })
    } catch (err) {
      console.log('err', err)
      toast.error(
        t('Something went wrong while fetching exam details!')
      );

    }
  }

  updateVerifiedAnswer = (index, type, grade) => (obj) => {
    const { studentExamDataAnswers } = this.state;



    if (type == 'correction') {
      studentExamDataAnswers[index].is_correct = obj.value;
      this.setState({ studentExamDataAnswers });
    }

    if (type == 'grades') {
      studentExamDataAnswers[index].confirmed_answer_grade = obj.target.value;
      this.setState({ studentExamDataAnswers });
    }

  }

  unlockExamConfirm = (exam_attempt_id, uid) => {
    this.setState({
      unlockAlert: true,
      exam_unlock_data: { exam_attempt_id, uid }
    });
  }

  unlockExam = () => {

    const { exam_unlock_data, formSectionsExam } = this.state;

    const { authData } = this.props;
    const { center_id } = authData.centerData;
    const { auth_key } = authData.loginData;
    let fData = new FormData();
    fData.append('is_locked ', 0)
    fData.append('exam_attempt_id', exam_unlock_data.exam_attempt_id)
    try {
      axios.post(
        apiURL + 'examcorrection/exam-lock',
        fData,
        {
          params: {
            auth_key,
            appname: process.env.REACT_APP_NAME,
          },
        }
      ).then((res) => {
        // 

        if (res.status == 200 && res.data.type) {

          const indexOfChild = formSectionsExam[1].data.findIndex((item) => item.uid == exam_unlock_data.uid);
          formSectionsExam[1].data[indexOfChild].is_locked = false;
          let formSectionsExam_LOCK_UPDATED = [{ ...formSectionsExam[0] }, { ...formSectionsExam[1] }];
          this.setState({ formSectionsExam: formSectionsExam_LOCK_UPDATED });

        }
      });
    } catch (err) {
      console.log('err', err)
      toast.error(
        t('Something went wrong while unlocking exam!')
      );

    }


    this.setState({
      unlockAlert: false,
      exam_attempt_id: 0
    });
  }

  saveExamCorrections = () => {

    this.setState({ working: true });

    const { authData } = this.props;
    const { auth_key } = authData.loginData;
    const { center_id, center_timezone } = authData.centerData;
    const { examData, studentExamDataAnswers, formSectionsExam, formSectionActive } = this.state;


    let thisChild = null
    formSectionsExam.map((v, i) => {
      if (v.uid == formSectionActive.parent) {
        thisChild = v.data.find((vv, ii) => vv.uid == formSectionActive.child)
      }
    });

    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // console.log('newExamAnswers', JSON.stringify(studentExamDataAnswers));

    let fData = new FormData();
    fData.append('center_id', center_id);
    fData.append('center_timezone', center_timezone);
    fData.append('exams_id', examData.exams.exams_id);
    fData.append('child_id', thisChild.data.child_id);
    fData.append('parent_id', thisChild.data.parent_id);
    fData.append('class_id', thisChild.data.class_id);
    fData.append('employee_id', authData.userData.employee_id);
    fData.append('examsgrade_comment', this.examsgrade_comment.current.value);
    fData.append('newExamAnswers', JSON.stringify(studentExamDataAnswers));

    try {
      axios.post(
        apiURL + 'examcorrection/save-exam-attempt-correction',
        fData,
        {
          params: {
            auth_key,
            appname: process.env.REACT_APP_NAME,
          },
        }
      ).then((res) => {

        this.setState({ working: false });
        toast.success(res.data.message);
      });
    } catch (err) {
      console.log('err', err)
      toast.error(
        t('Something went wrong while saving exam corrections!')
      );
      this.setState({ working: false });

    }

  }

  getGraphData = async (exams_id) => {

    this.setState({ graphData: null });

    const { authData } = this.props;
    const { center_id } = authData.centerData;
    const { auth_key } = authData.loginData;
    try {
      const res = await GET('exams/exam-analysis',
        {
          params: {
            exams_id,
          },
        }
      );

      if (res.status == 200) {
        this.setState({ graphData: res.data.data });
      }

    } catch (err) {
      console.log('err', err)
      toast.error(
        t('Something went wrong while fetching Exam Graph Data!')
      );

    }
  }


  resetFiltersUserTimeline = () => {
    this.setState(
      {
        getUserTimelineData: { ...this.state.getUserTimelineData, data: [], refreshing: false, finished: false, offset: 0 },
        filter: { ...this.state.filter, show: false }
      },
      () => this.getUserTimeline()
    )
  }

  render() {

    // Language Direction
    const { lang_orientation: dir } = this.props.defaultLanguage;

    const { getUserTimelineData, delAlert, activityModal, activityData, formSectionsActivity, examModal, formSectionsExam, examData, unlockAlert, graphModal, graphData } = this.state;

    return (
      <Container fluid>
        <div id='iedu-layout'>
          <NavBar />
          <div id='page-content'>
            <Header lite={true} heading={t('Tasks List')} backBtn={true} showClassSelector={true} classSelectorFunc={[this.resetFiltersUserTimeline]} showSubjectSelector={true} subjectSelectorFunc={[this.resetFiltersUserTimeline]} />
            <div className='grey-section'>

              {/* FILTER BAR */}
              <div className="filter-bar class-view-filter-bar">
                {/* DATE */}
                <div className="filter filter-date" >
                  <div className="filter-trigger" onClick={() => this.state.filter.toggleFilter('date_range_picker')}>
                    <FontAwesomeIcon
                      icon={(this.state.filter.show == 'date_range_picker') ? faChevronUp : faChevronDown}
                      color={brand_colors[this.props.defaultTheme.theme_id].color18}
                      className="filter-arrow"

                    />
                    <strong className="filter-title">{t('Date Range')}</strong>
                    <span className="selected-types">{moment(this.state.filter.selectedDateRange.startDate).format('DD-MM-YYYY')} -- {moment(this.state.filter.selectedDateRange.endDate).format('DD-MM-YYYY')}</span>
                  </div>
                  {(this.state.filter.show == 'date_range_picker')
                    && <div className='filter-box' style={{ display: (this.state.filter.show == 'date_range_picker') ? 'initial' : 'none' }}>
                      <DateRangePicker
                        ranges={[this.state.filter.selectedDateRange]}
                        onChange={(date) => {

                          this.state.filter.setData('selectedDateRange', { startDate: new Date(date.selection.startDate), endDate: new Date(date.selection.endDate), key: 'selection' })
                        }}
                        // className='filter-date-picker'
                        style={{ backgroundColor: '#fff' }}
                      />
                    </div>
                  }
                </div>
                {/* UT TYPES */}
                <div className="filter filter-types">
                  <div className="filter-trigger" onClick={() => this.state.filter.toggleFilter('ut_types')}>
                    <FontAwesomeIcon
                      icon={(this.state.filter.show == 'ut_types') ? faChevronUp : faChevronDown}
                      color={brand_colors[this.props.defaultTheme.theme_id].color18}
                      className="filter-arrow"
                    />
                    <strong className="filter-title">{t('Types')}</strong>
                    <span className="selected-types"><span>{(this.state.filter.selectedUT.length > 0) ? this.state.filter.selectedUT.length + ' Selected' : 'Select'}</span></span>
                  </div>
                  <div className="filter-box" style={{ display: (this.state.filter.show == 'ut_types') ? 'initial' : 'none' }}>
                    <ButtonGroup size="sm" className='mb-1'>
                      <Button variant="primary" onClick={() => this.state.filter.setData('selectedUT', defaultTimelineTypes.map((v, i) => { return { label: v.name, value: v.ut_type } }))}>Select All</Button>
                      <Button variant="warning" onClick={() => this.state.filter.setData('selectedUT', [])}>De-Select All</Button>
                    </ButtonGroup>
                    <Select
                      isMulti={true}
                      closeMenuOnSelect={false}
                      options={defaultTimelineTypes.map((v, i) => { return { label: v.name, value: v.ut_type } })}
                      onChange={(event) => this.state.filter.setData('selectedUT', event)}
                      value={this.state.filter.selectedUT}
                      menuPortalTarget={document.body}
                      styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                    />
                  </div>
                </div>
                {/* BTN SEARCH */}
                <div className="filter-btn d-flex align-items-center" style={{ marginLeft: 10 }}>
                  <button type="button" className="btn btn-primary" id="btn_filter_modal_apply" style={{ width: 50, height: 50, textAlign: 'center' }} onClick={() => {
                    this.setState({ getUserTimelineData: { ...this.state.getUserTimelineData, data: [], refreshing: false, finished: false, offset: 0 }, filter: { ...this.state.filter, show: false } }, () =>
                      this.getUserTimeline()
                    )
                  }}>
                    {this.state.getUserTimelineData.refreshing ? (
                      <Spinner size='sm' animation='grow' variant='light' />

                    ) : <FontAwesomeIcon
                      icon={faSearch}
                      color={brand_colors[this.props.defaultTheme.theme_id].color8}
                      style={{ fontSize: '24px', }}
                    />}
                  </button>
                </div>
              </div>


              <Row>
                <Col md={8} className='class-view-timeline'>
                  {getUserTimelineData.data.map((ut, utIndex) => (
                    <ClassViewCard
                      key={utIndex}
                      data={ut}
                      authData={this.props.authData}
                      dir={dir}
                      editUserTimeline={this.editUserTimeline}
                      showDelConfirm={this.showDelConfirm}
                      deleteUserTimeline={this.deleteUserTimeline}
                      viewActivity={this.state.activityModal.toggleModal}
                      viewExam={this.state.examModal.toggleModal}
                      showGraph={graphModal.toggleModal}
                      defaultTheme={this.props.defaultTheme}
                    />
                  ))}
                  {this.state.getUserTimelineData.data.length > 0
                    ? (<div className="d-grid gap-2">
                      <Button variant="dark" style={{ borderRadius: 20 }} onClick={() => this.getUserTimeline()} disabled={(this.state.getUserTimelineData.data.length > 0 && this.state.getUserTimelineData.finished)}>
                        {this.state.getUserTimelineData.refreshing ? (
                          <Spinner size='sm' animation='grow' variant='light' />
                        ) : (
                          <>
                            {(this.state.getUserTimelineData.data.length > 0 && this.state.getUserTimelineData.finished) ? t('No more posts found!') : t('Load More')}
                          </>
                        )}
                      </Button>
                    </div>)
                    : (<div className="d-grid gap-2">
                      <Button variant="dark" style={{ borderRadius: 20 }} disabled={true}>
                        {this.state.getUserTimelineData.refreshing
                          ? (<Spinner size='sm' animation='grow' variant='light' />)
                          : t('No posts found!')
                        }
                      </Button>
                    </div>)}
                </Col>
              </Row>
            </div>
          </div>
        </div>
        {this.renderForm()}
        {activityModal.show &&
          <Offcanvas show={activityModal.show} onHide={activityModal.closeModal} style={{ height: '100%' }} placement='bottom'>
            <Offcanvas.Header id='iedu-header' style={{ marginTop: '1rem', marginRight: '1rem', marginLeft: '1rem', marginBottom: 0 }}>
              <div className='d-flex flex-row'>
                <span className='header-back-btn' onClick={activityModal.closeModal} style={{ marginRight: 10 }}>
                  <FontAwesomeIcon icon={faArrowAltCircleLeft} color={brand_colors[this.props.defaultTheme.theme_id].color9} />
                </span>
                <Offcanvas.Title>{t(activityModal.modalTitle)}</Offcanvas.Title>
              </div>
              <div>

              </div>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <div
                className={'d-flex flex-row grey-section'}
                style={{
                  overflow: 'initial',
                  backgroundImage: 'url(https://academic.educore.io/static/media/pegboard-bg.9b84e4936acef29bbcd9.png)',
                  backgroundRepeat: 'repeat',
                  backgroundPosition: '10px 12px',
                  overflow: 'hidden',
                }}
              >
                <div style={{ backgroundColor: brand_colors[this.props.defaultTheme.theme_id].color8, marginLeft: 10, marginRight: 10, borderRadius: '20px', width: '250px', height: '100%', top: '5px', position: 'sticky' }}>
                  <div style={{ paddingRight: '1.5rem', paddingBottom: '1.5rem', paddingTop: '1rem' }}>
                    {formSectionsActivity.map((v, i) => {
                      return (
                        <div key={i}>
                          <div className='d-flex flex-row align-items-center' style={{ cursor: 'pointer', borderRadius: '20px', paddingLeft: '5px', paddingRight: '5px', backgroundColor: brand_colors[this.props.defaultTheme.theme_id].color8, zIndex: 1, position: 'relative' }}>
                            <span style={{ fontSize: 18, fontWeight: '600', color: brand_colors[this.props.defaultTheme.theme_id].color9, opacity: 0.7 }}>{v.name}</span>
                          </div>

                          <div style={{ paddingLeft: '1rem', borderLeft: 'solid 1px color18', marginLeft: '1.5rem' }}>
                            {v.data.map((vv, ii) => {
                              return (
                                <div key={ii} className='d-flex flex-row align-items-center' style={{ marginBottom: 5, marginTop: 2, cursor: 'pointer' }} onClick={() => this.changeToFromSection(v.uid, vv.uid)}>
                                  <FontAwesomeIcon icon={vv.completed ? faCheckCircle : faCircle} color={vv.completed ? brand_colors[this.props.defaultTheme.theme_id].color4 : brand_colors[this.props.defaultTheme.theme_id].color18} style={{ fontSize: 12, marginRight: 5 }} />
                                  <span style={{ fontSize: 14, color: vv.completed ? brand_colors[this.props.defaultTheme.theme_id].color4 : brand_colors[this.props.defaultTheme.theme_id].color18 }}>{vv.name}</span>
                                </div>
                              )
                            })}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <Col className='custom-styled-form hide-scrollbar' style={{ backgroundColor: brand_colors[this.props.defaultTheme.theme_id].color8, marginLeft: 10, marginRight: 10, borderRadius: '20px', padding: '1rem', overflow: 'auto', height: '75vh' }}>
                  {activityData &&
                    this.renderActivity()
                  }
                </Col>
              </div>
            </Offcanvas.Body>
          </Offcanvas>
        }
        {examModal.show &&
          <Offcanvas show={examModal.show} onHide={examModal.closeModal} style={{ height: '100%' }} placement='bottom'>
            <Offcanvas.Header id='iedu-header' style={{ marginTop: '1rem', marginRight: '1rem', marginLeft: '1rem', marginBottom: 0 }}>
              <div className='d-flex flex-row'>
                <span className='header-back-btn' onClick={examModal.closeModal} style={{ marginRight: 10 }}>
                  <FontAwesomeIcon icon={faArrowAltCircleLeft} color={brand_colors[this.props.defaultTheme.theme_id].color9} />
                </span>
                <Offcanvas.Title>{t(examModal.modalTitle)}</Offcanvas.Title>
              </div>
              <div>

              </div>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <div
                className={'d-flex flex-row grey-section'}
                style={{
                  overflow: 'initial',
                  backgroundImage: 'url(https://academic.educore.io/static/media/pegboard-bg.9b84e4936acef29bbcd9.png)',
                  backgroundRepeat: 'repeat',
                  backgroundPosition: '10px 12px',
                  overflow: 'hidden',
                }}
              >
                <div style={{ backgroundColor: brand_colors[this.props.defaultTheme.theme_id].color8, marginLeft: 10, marginRight: 10, borderRadius: '20px', width: '250px', height: '100%', top: '5px', position: 'sticky' }}>
                  <div style={{ paddingRight: '1.5rem', paddingBottom: '1.5rem', paddingTop: '1rem' }}>
                    {formSectionsExam.map((v, i) => {
                      return (
                        <div key={i}>
                          <div className='d-flex flex-row align-items-center' style={{ cursor: 'pointer', borderRadius: '20px', paddingLeft: '5px', paddingRight: '5px', backgroundColor: brand_colors[this.props.defaultTheme.theme_id].color8, zIndex: 1, position: 'relative' }}>
                            <span style={{ fontSize: 18, fontWeight: '600', color: brand_colors[this.props.defaultTheme.theme_id].color9, opacity: 0.7 }}>{v.name}</span>
                          </div>

                          <div style={{ paddingLeft: '1rem', borderLeft: 'solid 1px color18', marginLeft: '1.5rem' }}>
                            {v.data.map((vv, ii) => {
                              return (
                                <Row key={ii} className='d-flex flex-row align-items-center' style={{ marginBottom: 5, marginTop: 2 }}>
                                  <Col>
                                    <div onClick={() => vv.is_locked ? this.unlockExamConfirm(vv.exam_attempt_id, vv.uid) : this.showExam(v.uid, vv.uid)} style={{ cursor: 'pointer' }}>
                                      <FontAwesomeIcon icon={vv.completed ? faCheckCircle : faCircle} color={vv.completed ? brand_colors[this.props.defaultTheme.theme_id].color4 : brand_colors[this.props.defaultTheme.theme_id].color18} style={{ fontSize: 12, marginRight: 5 }} />
                                      <span style={{ fontSize: 14, color: vv.completed ? brand_colors[this.props.defaultTheme.theme_id].color4 : brand_colors[this.props.defaultTheme.theme_id].color18 }}>{vv.name}</span>
                                    </div>
                                  </Col>
                                  {v.uid != 1 &&
                                    <Col md={1} className='text-center p-0'><FontAwesomeIcon icon={vv.is_locked ? faLock : faLockOpen} color={vv.is_locked ? brand_colors[this.props.defaultTheme.theme_id].color11 : brand_colors[this.props.defaultTheme.theme_id].color4} style={{ fontSize: 12, marginRight: 5, cursor: vv.is_locked ? 'pointer' : 'default' }} onClick={() => vv.is_locked ? this.unlockExamConfirm(vv.exam_attempt_id, vv.uid) : console.log('do nothing')} /></Col>
                                  }
                                </Row>
                              )
                            })}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <Col className='custom-styled-form hide-scrollbar' style={{ backgroundColor: brand_colors[this.props.defaultTheme.theme_id].color8, marginLeft: 10, marginRight: 10, borderRadius: '20px', padding: '1rem', overflow: 'auto', height: '75vh' }}>
                  {examData &&
                    this.renderExam()
                  }
                </Col>
              </div>
            </Offcanvas.Body>
          </Offcanvas>
        }
        <SweetAlert
          show={delAlert}
          warning
          showCancel
          confirmBtnText={t('Yes, delete it!')}
          confirmBtnBsStyle='danger'
          title={t('Are you sure?')}
          onConfirm={() => this.deleteUserTimeline()}
          onCancel={() =>
            this.setState({ delAlert: false, delID: null })
          }
          focusCancelBtn>
          {t('You will not be able to recover this post!')}
        </SweetAlert>
        <SweetAlert
          show={unlockAlert}
          warning
          showCancel
          confirmBtnText={t('Yes, unlock it!')}
          confirmBtnBsStyle='danger'
          title={t('Exam is locked!')}
          onConfirm={() => this.unlockExam()}
          onCancel={() =>
            this.setState({ unlockAlert: false })
          }
          focusCancelBtn>
          {t('You want to unlock this exam!')}
        </SweetAlert>
        <Modal
          className='class-view-graph-modal'
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={graphModal.show}
          onHide={graphModal.toggleModal}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              {graphModal.modalTitle}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Tabs
              defaultActiveKey="exam-grades"
              id="fill-tab-example"
              className="mb-3"
              fill
            >
              <Tab eventKey="exam-grades" title={t('Exam Grades')}>
                <ExamGrades graphData={graphData} />
              </Tab>
              <Tab eventKey="questions-grades" title={t('Questions Grades')}>
                <QuestionsGrades graphData={graphData} />
              </Tab>
            </Tabs>
          </Modal.Body>
        </Modal>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  defaultLanguage: state.language.defaultLanguage,
  defaultTheme: state.theme.defaultTheme,
  selectedClass: state.selectedClass.data,
  selectedSubject: state.selectedClass.subject,

});

export default connect(mapStateToProps, null)(ClassView);
