import React, { Component } from 'react';
import { Row, Col, Button, Spinner, Modal, Form, Table } from 'react-bootstrap';
import { t } from '../../../helpers/translation_helper';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faPlus } from '@fortawesome/free-solid-svg-icons';
import { GET, POST } from '../../../api';
import { setEvalTopic } from '../../../redux/slices/evaluationSlice';
import { toast } from 'react-toastify';
import { handleFormErrors } from '../../../helpers/form_helpers';
import { Scrollbar } from 'react-scrollbars-custom';
import { brand_colors } from '../../../helpers/brand_colors_helper';

class SubTopics extends Component {

  defaultFormFields = {
    appraisaltopicsub_id: 0,
    appraisaltopicsub_code: '',
    max_grade: '',
    appraisaltopicsub_desc: '',
    appraisaltopicsub_desc2: '',
  }

  state = {
    ...this.defaultFormFields,
    subTopics: [],
    working: false,
    isEditing: false,
    showFormModal: false,
  }

  componentDidUpdate(prevProps) {
    if (this.props.evalCat && prevProps.evalCat !== this.props.evalCat) {
      this.getSubTopics();
    }

    if (this.props.evalTopic && prevProps.evalTopic !== this.props.evalTopic) {
      this.getSubTopics();
    }

    if ((!this.props.evalCat && prevProps.evalCat !== this.props.evalCat)) {
      this.setState({ subTopics: [] });
    }

    if (!this.props.evalTopic && prevProps.evalTopic && !this.props.evalTopic) {
      this.getSubTopics();
    }

  }

  getSubTopics = async () => {

    this.setState({ subTopics: null });

    try {
      const res = await GET('evaluation-setup/get-sub-topics', {
        params: {
          appraisaltopic_id: this.props.evalTopic,
          appraisalcat_id: this.props.evalCat
        }
      });

      if (res.status == 200) {
        this.setState({
          subTopics: res.data
        });
      }

    } catch (err) {
      console.log(err);
    }
  }

  handleOpenFormModal = () => {
    this.setState({
      showFormModal: true
    });
  }

  handleCloseFormModal = () => {
    this.setState({
      showFormModal: false,
      isEditing: false,
      ...this.defaultFormFields
    });
  }

  saveUpdate = async () => {

    const { authData } = this.props;
    const { center_id } = authData.centerData;

    this.setState({ working: true });

    const {
      appraisaltopicsub_id,
      appraisaltopicsub_code,
      max_grade,
      appraisaltopicsub_desc,
      appraisaltopicsub_desc2,
    } = this.state;

    const fData = new FormData();
    fData.append('center_id', center_id);
    fData.append('evalSubTopicData', JSON.stringify({
      appraisaltopicsub_id,
      appraisaltopic_id: this.props.evalTopic,
      appraisaltopicsub_code,
      max_grade,
      appraisaltopicsub_desc,
      appraisaltopicsub_desc2,
    }));

    try {
      const res = await POST('evaluation-setup/save-sub-topic', fData);
      if (res.data.type) {
        this.setState({ working: false });
        toast.success(t(res.data.message));
        this.getSubTopics();
        this.handleCloseFormModal();
      } else {
        // toast.error(t(res.data.message));
        handleFormErrors(res.data.data)
        this.setState({ working: false });
      }
    } catch (err) {
      console.log('err', err)
      toast.error(t('Something went wrong!'));

      this.setState({ working: false });
    }

  }

  edit = (data) => {
    this.setState({
      isEditing: true,
      appraisaltopicsub_id: data.appraisaltopicsub_id,
      appraisaltopicsub_code: data.appraisaltopicsub_code,
      max_grade: data.max_grade,
      appraisaltopicsub_desc: data.appraisaltopicsub_desc,
      appraisaltopicsub_desc2: data.appraisaltopicsub_desc2,
    });
    this.handleOpenFormModal();
  }

  delete = async (data) => {

    try {
      const res = await GET('evaluation-setup/delete-group', {
        params: {
          appraisalgrp_id: data.appraisalgrp_id
        }
      });
      if (res.data.type) {
        this.setState({
          groups: this.state.groups.filter(item => item.appraisalgrp_id !== data.appraisalgrp_id)
        });
        toast.success(t(res.data.message));
        this.props.setEvalGroup(null);
      } else {
        toast.error(t(res.data.message));
      }
    } catch (err) {
      console.log(err);
      toast.error(t('Something went wrong!'));
    }
  }

  render() {
    console.log('subTopics', this.state);
    return (
      <div>
        <div className='grey-section-header px-0'>
          <h5><span className="me-1">{t('Sub-Topics')}</span>
            <span className="mx-1" style={{ opacity: 0.6, fontWeight: "normal" }}>|</span>
            {/* <span className="mx-1" style={{
              opacity: 0.8,
              fontSize: '1rem',
              fontWeight: 'normal'
            }}>{t('Please select a Topic')}</span> */}
          </h5>
          <Button variant="success" size="sm" title={t('Add Sub-Topic')}
            disabled={!this.props.evalTopic}
            onClick={this.handleOpenFormModal}>
            <FontAwesomeIcon icon={faPlus} /> {t('Sub-Topic')}
          </Button>
        </div>
        <div style={{ minHeight: 300 }}>
          {this.state.subTopics == null &&
            <div className='text-center p-5'
              style={{
                flex: 1
              }}>
              <Spinner animation="grow" size="sm" className='me-2' />{t('Loading')}
            </div>
          }
          {this.state.subTopics && this.state.subTopics.length == 0 &&
            <div className='text-center p-5'
              style={{
                flex: 1
              }}>
              {t('No Data Found')}
            </div>
          }
          {this.state.subTopics &&
            this.state.subTopics.map((subTopic, subTopic_i) => (
              <div key={subTopic_i} className='scroll-list-card'>
                <div className='d-flex flex-row justify-content-between align-items-center p-0 m-0'>
                  <div className='me-2'>
                    <FontAwesomeIcon
                      title={t('Edit Sub-Topic')}
                      onClick={() => this.edit(subTopic)}
                      style={{
                        cursor: 'pointer'
                      }}
                      icon={faPenToSquare} color={brand_colors[this.props.defaultTheme.theme_id].color16} className='ms-1' />
                  </div>
                  <div className='me-2'>
                    <div style={{ fontSize: 14, color: brand_colors[this.props.defaultTheme.theme_id].color9, opacity: 0.8, fontWeight: '600' }}>
                      {subTopic.appraisaltopicsub_code}
                    </div>
                    <div style={{ fontSize: 14, color: brand_colors[this.props.defaultTheme.theme_id].color9, opacity: 0.8 }}>
                      {subTopic.appraisaltopicsub_desc}
                    </div>
                    <div style={{ fontSize: 14, color: brand_colors[this.props.defaultTheme.theme_id].color9, opacity: 0.8 }}>
                      {subTopic.appraisaltopicsub_desc2}
                    </div>
                  </div>
                </div>
                <div className='me-2'>
                  {t('Max Grade:')}{' '}<strong>{subTopic.max_grade}</strong>
                </div>
              </div>
            ))
          }
        </div>
        <Modal
          size={'md'}
          show={this.state.showFormModal}
          onHide={this.handleCloseFormModal}>
          <Modal.Header closeButton>
            <Modal.Title>{this.state.isEditing ? t('Edit') : t('Add')} {t('Sub-Topic')}</Modal.Title>
          </Modal.Header>
          <Modal.Body className='custom-styled-form'>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>{t('Sub-Topic')}</Form.Label>
                  <Form.Control type="text"
                    defaultValue={this.state.appraisaltopicsub_code}
                    onChange={(e) => this.setState({ appraisaltopicsub_code: e.target.value })} />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>{t('Max Grade')}</Form.Label>
                  <Form.Control type="text"
                    defaultValue={this.state.max_grade}
                    onChange={(e) => this.setState({ max_grade: e.target.value })} />
                </Form.Group>
              </Col>
              <Col md={12}>
                <Form.Group className="mb-3">
                  <Form.Label>{t('Description')}</Form.Label>
                  <Form.Control type="text"
                    defaultValue={this.state.appraisaltopicsub_desc}
                    onChange={(e) => this.setState({ appraisaltopicsub_desc: e.target.value })} />
                </Form.Group>
              </Col>
              <Col md={12}>
                <Form.Group className="mb-3">
                  <Form.Label>{t('Description 2')}</Form.Label>
                  <Form.Control type="text"
                    defaultValue={this.state.appraisaltopicsub_desc2}
                    onChange={(e) => this.setState({ appraisaltopicsub_desc2: e.target.value })} />
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleCloseFormModal}>
              {t('Close')}
            </Button>
            <Button disabled={this.state.working} variant='success' onClick={() => this.saveUpdate()}>
              {t('Save')} {this.state.working ? <Spinner as='span' animation='grow' size='sm' role='status' aria-hidden='true' /> : ''}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  defaultLanguage: state.language.defaultLanguage,
  evalGroup: state.evaluation.group,
  evalCat: state.evaluation.cat,
  evalTopic: state.evaluation.topic,
  defaultTheme: state.theme.defaultTheme,
});

const mapDispatchToProps = () => ({
  setEvalTopic
});

export default connect(mapStateToProps, mapDispatchToProps())(SubTopics);
