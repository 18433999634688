import { configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import logger from 'redux-logger'
import thunk from 'redux-thunk'
import { combineReducers } from 'redux';

import loginReducer from './slices/authSlice';
import selectedClassReducer from './slices/selectedClassSlice';
import langReducer from './slices/langSlice';
import childrenReducer from './slices/childrenSlice';
import lessonPlannerReducer from './slices/lessonPlannerSlice';
import themeReducer from './slices/themeSlice';
import preferencesReducer from './slices/preferencesSlice';
import chatsReducer from './slices/chatsSlice';
import updateSchoolModalReducer from './slices/updateSchoolModalSlice';
import addEditClassModalReducer from './slices/addEditClassModalSlice';
import addEditParentModalReducer from './slices/addEditParentModalSlice';
import addEditStudentModalReducer from './slices/addEditStudentModalSlice';
import addEditEmployeeModalReducer from './slices/addEditEmployeeModalSlice';
import addEditRegistrationSimpleModalReducer from './slices/addEditRegistrationSimpleModalSlice';
import addEditRegistrationAdvanceModalReducer from './slices/addEditRegistrationAdvanceModalSlice';
import addEditInvoiceReducer from './slices/addEditInvoiceSlice';
import addEditReceiptModalReducer from './slices/addEditReceiptModalSlice';
import studentListReducer from './slices/studentListSlice';
import parentsListReducer from './slices/parentsListSlice';
import employeesListReducer from './slices/employeesListSlice';
import examGradesModalReducer from './slices/examGradesModalSlice';
import examCopyModalReducer from './slices/examCopyModalSlice';
import userTimelineListModalReducer from './slices/userTimelineListModalSlice';
import userTimelineCommentsModalReducer from './slices/userTimelineCommentsModalSlice';
import bannersModalReducer from './slices/bannersModalSlice';
import mediaApprovalModalReducer from './slices/mediaApprovalModalSlice';
import evaluationReducer from './slices/evaluationSlice';
import calendarPreviewModalReducer from './slices/calendarPreviewModalSlice';
import addEditPaymentVoucherModalReducer from "./slices/addEditPaymentVoucherModalSlice";
import addEditPurchasesModalReducer from "./slices/addEditPurchasesModalSlice";
import navigationReducer from "./slices/navigationSlice";
import accountsSetupReducer from './slices/accountsSetupSlice';
import generalSlice from './slices/generalSlice';

const rootReducer = combineReducers({
  auth: loginReducer,
  selectedClass: selectedClassReducer,
  language: langReducer,
  children: childrenReducer,
  lessonPlanner: lessonPlannerReducer,
  theme: themeReducer,
  preferences: preferencesReducer,
  chats: chatsReducer,
  updateSchoolModal: updateSchoolModalReducer,
  addEditClassModal: addEditClassModalReducer,
  addEditParentModal: addEditParentModalReducer,
  addEditStudentModal: addEditStudentModalReducer,
  addEditEmployeeModal: addEditEmployeeModalReducer,
  addEditRegistrationSimpleModal: addEditRegistrationSimpleModalReducer,
  addEditReceiptModal: addEditReceiptModalReducer,
  addEditRegistrationAdvanceModal: addEditRegistrationAdvanceModalReducer,
  addEditInvoice: addEditInvoiceReducer,
  studentList: studentListReducer,
  parentsList: parentsListReducer,
  employeesList: employeesListReducer,
  examGradesModal: examGradesModalReducer,
  examCopyModal: examCopyModalReducer,
  calendarPreviewModal: calendarPreviewModalReducer,
  userTimelineListModal: userTimelineListModalReducer,
  userTimelineCommentsModal: userTimelineCommentsModalReducer,
  bannersModal: bannersModalReducer,
  mediaApprovalModal: mediaApprovalModalReducer,
  evaluation: evaluationReducer,
  addEditPaymentVoucherModal: addEditPaymentVoucherModalReducer,
  addEditPurchasesModal: addEditPurchasesModalReducer,
  navigation: navigationReducer,
  accountsSetup: accountsSetupReducer,
  generalData: generalSlice,
});

const persistConfig = {
  key: 'academic-ieducore-com',
  storage,
  whitelist: ['auth', 'selectedClass', 'language', 'theme', 'preferences']//put name of reducers you want to put in storage
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

/**
 * Store
 */
export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: [logger, thunk]
});
export const persistor = persistStore(store)
