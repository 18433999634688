import React, { Component } from 'react';
import { Container, Card, Row, Col, ButtonGroup, Button, DropdownButton, Dropdown, Form, Modal, Spinner, Table, InputGroup, Badge } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faCheckCircle, faSearch, faUser, faXmark, faLayerGroup, faHandsHoldingChild, faPeopleGroup, faListCheck, faPrint, faPencil, faTrash, faCheckDouble, faSortDown, faSortUp, faThumbsUp, faThumbsDown, faL, faChevronUp, faChevronDown, faTimes } from '@fortawesome/free-solid-svg-icons';
import { faCalendar, faEye, faEyeSlash, faFileExcel, faFilePdf } from '@fortawesome/free-regular-svg-icons';
import { t } from '../../helpers/translation_helper';
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import Select from 'react-select';
import { connect } from 'react-redux';
import moment from 'moment';
import { brand_colors } from '../../helpers/brand_colors_helper';
import { GET, POST } from '../../api';
import { toast } from 'react-toastify';
import { handleFormErrors } from '../../helpers/form_helpers';
import { DateRangePicker } from 'react-date-range';
import axios from 'axios';
import Pagination from '../_partials/Pagination';
import SweetAlert from 'react-bootstrap-sweetalert';
import { changeColorOpacity, DDL_ExamstypegrpTypes, DDL_ExamstypegrpTypes2 } from '../../helpers/general_helpers';
import { renderTableBody, renderTableHead } from '../../helpers/table_helper';
var Chance = require('chance');
var chance = new Chance();
let cancelToken;

class ExamGroups extends Component {

  searchFieldRef = React.createRef();

  defaultFormFields = {
    examstypegrp_id: 0,
    examstypegrp_name: '',
    examstypegrp_type: 'Exam',
    examstypegrp_type2: 'Internal',
  }

  state = {
    ...this.defaultFormFields,
    DDL_examstypegrp_types: DDL_ExamstypegrpTypes(),
    DDL_examstypegrp_types2: DDL_ExamstypegrpTypes2(),
    deleteModal: { show: false, id: 0, action: () => { } },
    working: false,
    isEditing: false,
    showFormModal: false,
    cdt: {
      fetching: false,
      search: '',
      rows: 5,
      offset: 0,
      order: 'examstypegrp.examstypegrp_id',
      dir: 'DESC',
      lastPage: 0,
      page: 0,
      totalRecords: 0,
      rowsOptions: [5, 10, 25, 50, 'All'],
      columns: [
        {
          id: 'examstypegrp.examstypegrp_id',
          column: 'examstypegrp_id',
          name: 'Actions',
          visibility: true
        },
        {
          id: 'examstypegrp.examstypegrp_name',
          column: 'examstypegrp_name',
          name: 'Name',
          visibility: true
        },
        {
          id: 'examstypegrp.examstypegrp_type',
          column: 'examstypegrp_type',
          name: 'Type',
          visibility: true
        },
        {
          id: 'examstypegrp.examstypegrp_type2',
          column: 'examstypegrp_type2',
          name: 'Type2',
          visibility: true
        },
      ],
      data: [],
    },
  }

  componentDidMount() {
    const {
      search,
      rows: limit,
      order,
      dir
    } = this.state.cdt;
    this.fetch({
      search, limit, order, dir, page: 0
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.authData.centerData.center_id != this.props.authData.centerData.center_id) {
      const {
        search,
        rows: limit,
        order,
        dir
      } = this.state.cdt;
      this.fetch({
        search, limit, order, dir, page: 0
      });
    }
  }

  fetch = async (params) => {

    this.setState({
      cdt: {
        ...this.state.cdt,
        fetching: true,
      }
    });

    if (cancelToken) {
      cancelToken.abort();
    }
    cancelToken = new AbortController();

    try {
      const res = await GET('exam-groups-types/get', {
        signal: cancelToken.signal,
        params: {
          search: params.search,
          order: params.order,
          dir: params.dir,
          limit: params.limit,
          offset: parseInt(params.page) * parseInt(params.limit)
        },
      });

      if (res.status === 200) {
        this.setState({
          cdt: {
            ...this.state.cdt,
            fetching: false,
            search: params.search,
            rows: parseInt(params.limit),
            offset: parseInt(params.page) * parseInt(params.limit),
            order: params.order,
            dir: params.dir,
            page: params.page,
            totalRecords: parseInt(res.data.total_count_filtered),
            lastPage: Math.ceil(parseInt(res.data.total_count_filtered) / parseInt(params.limit)),
            data: res.data.data.map((item) => {
              return {
                uid: chance.guid(),
                ...item,
                examstypegrp_type: { value: item.examstypegrp_type, label: item.examstypegrp_type },
                examstypegrp_type2: { value: item.examstypegrp_type2, label: item.examstypegrp_type2 }
              }
            }),
          }
        });
      }

    } catch (err) {
      console.log('err', err)
      this.setState({
        cdt: {
          ...this.state.cdt,
          fetching: false,
        }
      });

    }
  }

  handleOpenFormModal = () => {
    this.setState({
      showFormModal: true
    });
  }

  handleCloseFormModal = () => {
    this.setState({
      showFormModal: false,
      isEditing: false,
      ...this.defaultFormFields
    });
  }

  showHideColumn = (column_index) => {
    const columns = this.state.cdt.columns;
    columns[column_index].visibility = !columns[column_index].visibility;
    this.setState({ ctd: { ...this.state.ctd, columns } })
  }

  exportExcel = async () => {

    const excelData = this.state.cdt.data.map((item) => {
      const row = {};
      this.state.cdt.columns
        .filter((column) => column.visibility)
        .map((column) => {
          if (column.name !== 'Actions') {
            row[column.name] = item[column.column];
          }
        });
      return row;
    });

    const fileName = 'Exam Group Export ' + moment().format('DD-MM-YYYY');
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const ws = XLSX.utils.json_to_sheet(excelData);

    const cellStyle = {
      font: {
        bold: true,
      },
    }

    Array.from('A').map((letter) => {
      ws[letter + "1"].s = cellStyle;
    });

    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });

    FileSaver.saveAs(data, fileName + fileExtension);
  }

  renderCell = (column_data, column_id, column_index, dataObj) => {
    const { authData } = this.props;
    const { center_id } = authData.centerData;

    let tdData;

    // --------------------------------------------
    if (column_id == 'examstypegrp_id') { // Action Buttons
      tdData = <>
        <Button
          onClick={() => this.delete(dataObj)}
          variant="danger" size='sm' className='me-1'>
          <FontAwesomeIcon icon={faTrash} />
        </Button>
        <Button
          onClick={() => this.edit(dataObj)}
          variant="warning" size='sm' className='me-1'>
          <FontAwesomeIcon icon={faPencil} />
        </Button>
      </>;
      // ------------------------------------------------------------------------
    } else if (column_id == 'examstypegrp_type') {
      let find_type = this.state.DDL_examstypegrp_types.find((v) => v.value == column_data.value)
      tdData = <span style={{ fontSize: 12, fontWeight: 'bold', backgroundColor: (find_type) ? changeColorOpacity(find_type.color, 0.2) : '#fff', color: (find_type) ? find_type.color : '#000', borderRadius: 7, padding: '0.1rem 0.5rem' }}>{column_data.value}</span>
    } else if (column_id == 'examstypegrp_type2') {
      let find_type = this.state.DDL_examstypegrp_types2.find((v) => v.value == column_data.value)
      tdData = <span style={{ fontSize: 12, fontWeight: 'bold', backgroundColor: (find_type) ? changeColorOpacity(find_type.color, 0.2) : '#fff', color: (find_type) ? find_type.color : '#000', borderRadius: 7, padding: '0.1rem 0.5rem' }}>{column_data.value}</span>
    } else {
      tdData = column_data;
    }


    return <td key={column_index}>{tdData}</td>;
  }

  saveUpdate = async () => {

    const { authData } = this.props;
    const { center_id } = authData.centerData;

    this.setState({ working: true });

    const {
      examstypegrp_id,
      examstypegrp_name,
      examstypegrp_type,
      examstypegrp_type2,
    } = this.state;

    const fData = new FormData();
    fData.append('center_id', center_id);
    fData.append('examstypegrpData', JSON.stringify({
      examstypegrp_id,
      examstypegrp_name,
      examstypegrp_type: examstypegrp_type.value,
      examstypegrp_type2: examstypegrp_type2.value
    }));

    try {
      const res = await POST('exam-groups-types/save', fData);
      if (res.data.type) {
        this.setState({ working: false });
        toast.success(t(res.data.message));
        this.fetch({
          search: '',
          limit: this.state.cdt.rows,
          order: this.state.cdt.order,
          dir: this.state.cdt.dir,
          page: 0,
        });
        this.handleCloseFormModal();
      } else {
        // toast.error(t(res.data.message));
        handleFormErrors(res.data.data)
        this.setState({ working: false });
      }
    } catch (err) {
      console.log('err', err)
      toast.error(t('Something went wrong!'));

      this.setState({ working: false });
    }

  }

  edit = (data) => {
    this.setState({
      isEditing: true,
      examstypegrp_id: data.examstypegrp_id,
      examstypegrp_name: data.examstypegrp_name,
      examstypegrp_type: data.examstypegrp_type,
      examstypegrp_type2: data.examstypegrp_type2,
    });
    this.handleOpenFormModal();
  }

  delete = async (data) => {
    this.setState({
      deleteModal: {
        ...this.state.deleteModal, show: true, id: data, action: async () => {
          const res = await GET('exam-groups-types/delete', {
            params: {
              examstypegrp_id: data.examstypegrp_id
            }
          });
          if (res) {
            let resData = res.data
            if (resData.type) {
              this.setState({
                cdt: {
                  ...this.state.cdt,
                  data: this.state.cdt.data.filter(item => item.uid !== data.uid)
                }, deleteModal: {
                  ...this.state.deleteModal, show: false, id: null
                }
              });
            } else {
              this.setState({
                deleteModal: {
                  ...this.state.deleteModal, show: false, id: null
                }
              });
            }
            toast[resData.messageType](t(resData.message));
          }
        }
      }
    })
  }

  render() {
    const { cdt } = this.state
    return (
      <>
        <div className='shadow-box mt-1' style={{
          marginInline: 5
        }}>
          <Card
            className='border-0'
            style={{ borderRadius: '20px' }}>
            <Card.Header style={{
              backgroundColor: '#fff',
              borderTopLeftRadius: '20px',
              borderTopRightRadius: '20px',
              fontWeight: '700'
            }}>
              <Row>
                <Col md={6} className='d-flex align-items-center'>
                  {t('Groups')}
                </Col>
                <Col md={6} className='d-flex justify-content-end'>
                  <div className='cdt-search'>
                    <input type='text'
                      ref={this.searchFieldRef}
                      defaultValue={cdt.search}
                      onChange={(e) => this.fetch({
                        search: e.target.value,
                        limit: cdt.rows,
                        order: cdt.order,
                        dir: cdt.dir,
                        page: 0
                      })}
                      placeholder={t('Search...')} />
                    <button
                      onClick={() => {
                        this.fetch({
                          search: '',
                          limit: cdt.rows,
                          order: cdt.order,
                          dir: cdt.dir,
                          page: 0
                        });
                        this.searchFieldRef.current.value = '';
                      }}>
                      <FontAwesomeIcon
                        icon={cdt.search == '' ? faSearch : faXmark}
                        color={brand_colors[this.props.defaultTheme.theme_id].color18}
                      />
                    </button>
                  </div>
                  <button
                    onClick={this.handleOpenFormModal}
                    className='btn btn-success btn-sm ms-3'><FontAwesomeIcon icon={faPlus} color={brand_colors[this.props.defaultTheme.theme_id].color8} style={{ fontSize: 12 }} /></button>
                </Col>
              </Row>
            </Card.Header>
            <Card.Body style={{ paddingTop: 8 }}>
              <Row>
                <Col md={6}>
                  <ButtonGroup size='sm' className='me-3 float-left'>
                    {/* <Button><FontAwesomeIcon icon={faFilePdf} /> {t('PDF')}</Button> */}
                    <Button onClick={() => this.exportExcel()}>
                      <FontAwesomeIcon icon={faFileExcel} /> {t('Excel')}
                    </Button>
                    {/* <Button><FontAwesomeIcon icon={faPrint} /> {t('Print')}</Button> */}
                    <DropdownButton
                      autoClose={'outside'}
                      size='sm'
                      as={ButtonGroup}
                      title={t('Column Visibility')}>
                      {cdt.columns.map((column, column_index) => (
                        <Dropdown.Item key={column_index} className={column.visibility ? 'column-name-item' : 'column-name-item active'} onClick={() => this.showHideColumn(column_index)}>
                          <FontAwesomeIcon icon={column.visibility ? faEye : faEyeSlash} /> {column.name}
                        </Dropdown.Item>
                      ))}
                    </DropdownButton>
                  </ButtonGroup>
                </Col>
                <Col md={6} className='d-flex justify-content-end'>
                  <div style={{ display: 'inline-block' }}>
                    {t('Show')}
                    <Form.Select
                      value={cdt.rows}
                      size="sm"
                      onChange={(e) => this.fetch({
                        search: cdt.search,
                        limit: e.target.value,
                        order: cdt.order,
                        dir: cdt.dir,
                        page: cdt.page
                      })}
                      style={{ width: 70, marginInline: 5, display: 'inline-block' }}>
                      {cdt.rowsOptions.map((row, row_index) => (<option key={row_index} >{row}</option>))}
                    </Form.Select>
                    {t('Records')}
                  </div>
                </Col>
              </Row>
              <Row>
                {cdt.fetching &&
                  <Col md={12} className='py-5 d-flex flex-column align-items-center'>
                    <Spinner as='span' animation='grow' size='sm' /> {t('Loading Data')}
                  </Col>
                }
                {!cdt.fetching && cdt.data.length === 0 &&
                  <Col md={12} className='py-5 d-flex flex-column align-items-center'>
                    {t('No Data Found')}
                  </Col>
                }
                {!cdt.fetching && cdt.data.length !== 0 &&
                  <Col md={12} className='pt-2'>
                    <div id="datatable-list" className='mb-3' style={{ width: 'initial' }}>
                      <Table responsive>
                        {renderTableHead(this)}
                        {renderTableBody(this)}
                      </Table>
                    </div>
                  </Col>
                }
              </Row>
              <Pagination
                pageCount={cdt.lastPage || 1}
                forcePage={cdt.page}
                callbackParams={{
                  search: cdt.search,
                  limit: cdt.rows,
                  order: cdt.order,
                  dir: cdt.dir,
                }}
                callback={this.fetch}
              />
            </Card.Body>
          </Card>
        </div>
        <Modal
          size={'sm'}
          show={this.state.showFormModal}
          onHide={this.handleCloseFormModal}>
          <Modal.Header closeButton>
            <Modal.Title>{this.state.isEditing ? t('Edit') : t('Add')} {t('Exam Group')}</Modal.Title>
          </Modal.Header>
          <Modal.Body className='custom-styled-form'>
            <Form.Group className="mb-3">
              <Form.Label>{t('Name')}</Form.Label>
              <Form.Control type="text"
                defaultValue={this.state.examstypegrp_name}
                onChange={(e) => this.setState({ examstypegrp_name: e.target.value })} />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>{t('Type')}</Form.Label>
              <Select
                placeholder={t('-- Select --')}
                options={this.state.DDL_examstypegrp_types}
                onChange={(obj) => this.setState({ examstypegrp_type: obj })}
                value={this.state.examstypegrp_type}
                menuPortalTarget={document.body}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 99999999999999 }) }}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>{t('Type2')}</Form.Label>
              <Select
                placeholder={t('-- Select --')}
                options={this.state.DDL_examstypegrp_types2}
                onChange={(obj) => this.setState({ examstypegrp_type2: obj })}
                value={this.state.examstypegrp_type2}
                menuPortalTarget={document.body}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 99999999999999 }) }}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleCloseFormModal}>
              Close
            </Button>
            <Button disabled={this.state.working} variant='success' onClick={() => this.saveUpdate()}>
              {t('Save')} {this.state.working ? <Spinner as='span' animation='grow' size='sm' role='status' aria-hidden='true' /> : ''}
            </Button>
          </Modal.Footer>
        </Modal>
        <SweetAlert show={this.state.deleteModal.show} warning showCancel confirmBtnText={t('Yes, delete it!')} confirmBtnBsStyle='danger' title={t('Are you sure?')} onConfirm={this.state.deleteModal.action} onCancel={() => this.setState({ deleteModal: { ...this.state.deleteModal, show: false, id: 0, action: () => { } } })} focusCancelBtn>
          {t('Confirm Delete!')}
        </SweetAlert>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  defaultLanguage: state.language.defaultLanguage,
  defaultTheme: state.theme.defaultTheme,
});

export default connect(mapStateToProps, null)(ExamGroups);
