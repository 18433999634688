import React, { Component } from 'react'
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title, } from 'chart.js'
import { Doughnut, Bar } from 'react-chartjs-2'

import { Card, Col, Image, Row, Form, Button, Spinner, Modal, Badge, ButtonGroup, Nav, NavItem, NavLink, OverlayTrigger, Popover, } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBell, faShareAlt, faThumbsUp, faComment, faCalendarAlt, faStop, faImage, faPoll, faTasks, faListAlt, faVideo, faDesktop, faFileAlt, faClock, faBook, faTh, faTachometer, faQuestionCircle, faAward, faCalendar, faLink, faPaperclip, faChevronLeft, faChevronRight, faTimesCircle, faEye, faHeart, faEyeSlash, faArrowAltCircleRight, faArrowAltCircleLeft, faEllipsisV, faEdit, faTrash, faArrowUpRightFromSquare, faMoneyBill, faDotCircle, faNotesMedical, faChartArea, faTimes, faAngleLeft, faAngleRight, faStar, faCheck, faThermometerEmpty, faUser, faClipboardCheck, faPhone, faAt, faPrint, faAngleDown, faD, faThumbTack, faPaperPlane, faThumbsDown, } from '@fortawesome/free-solid-svg-icons'
import ReactPlayer from 'react-player'
import moment from 'moment'
import ImageViewer from 'react-simple-image-viewer'
import Slider from 'react-slick'
import ReactStars from 'react-rating-stars-component'
import { toast } from 'react-toastify'
import axios from 'axios'
import { t } from '../../helpers/translation_helper'
import { brand_colors, generateColor } from '../../helpers/brand_colors_helper'
import CommentItem from './CommentItem'
import { faCheckCircle, faFileExcel, faFileLines, faNewspaper, faNoteSticky } from '@fortawesome/free-regular-svg-icons'
import { Chance } from 'chance'
import { handleFormErrors } from '../../helpers/form_helpers'
import * as V from 'victory'
import ReactToPrint from 'react-to-print';
import { GET, POST } from '../../api'
import XLSX from 'sheetjs-style';
import * as FileSaver from 'file-saver';
import SweetAlert from 'react-bootstrap-sweetalert';
import GetIcon from '../../helpers/icon_helper'
import { changeColorOpacity } from '../../helpers/general_helpers'

const chance = new Chance()

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

// API URL
const apiURL = process.env.REACT_APP_API_URL

// Portal URL
const portalURL = process.env.REACT_APP_PORTAL_URL
const appURL = process.env.REACT_APP_URL

/**
 * Timeline Card Component
 */
class TimelineCard extends Component {

  constructor(props) {
    super()
    // State
    this.dailyReport = [
      {
        mood_visible: {
          label: 'Mood',
          icon: require('../../assets/images/daily-report/bear-happy.png'),
          checked: true,
          blocked: false,

        },
        food_visible: {
          label: 'Food',
          icon: require('../../assets/images/daily-report/lunch-less-pink.png'),
          checked: true,
          blocked: false,
        },
        water_visible: {
          label: 'Water',
          icon: require('../../assets/images/daily-report/water-less.png'),
          checked: true,
          blocked: false,
        },
        juice_visible: {
          label: 'Juice',
          icon: require('../../assets/images/daily-report/juice-yellow-all.png'),
          checked: true,
          blocked: false,
        },
        need_diapers_visible: {
          label: 'Need Diapers',
          icon: require('../../assets/images/daily-report/need-diaper.png'),
          checked: false,
          blocked: false,
        },
        need_clothes_visible: {
          label: 'Need Clothes',
          icon: require('../../assets/images/daily-report/clothes.png'),
          checked: false,
          blocked: false,
        },
        temperature_visible: {
          label: 'Take Temperature',
          icon: require('../../assets/images/daily-report/temperature.png'),
          checked: false,
          blocked: false,
        },
        nap_visible: {
          label: 'Nap',
          icon: require('../../assets/images/daily-report/nap.png'),
          checked: true,
          blocked: false,
        },
        potty_training_visible: {
          label: 'Potty Training',
          icon: require('../../assets/images/daily-report/potty-training.png'),
          checked: false,
          blocked: false,
        },
        toilet_visits_visible: {
          label: 'Toilets Visits',
          icon: require('../../assets/images/daily-report/toilet-visits.png'),
          checked: false,
          blocked: false,
        },
      },
      {
        bf_visible: {
          label: 'Breakfast',
          icon: require('../../assets/images/daily-report/breakfast-more-green.png'),
          checked: true,
          blocked: false,
        },
        bf_comment_visible: {
          label: 'Comment',
          icon: require('../../assets/images/daily-report/comment.png'),
          checked: false,
          blocked: false,
        },
        bt_visible: {
          label: 'Time',
          icon: require('../../assets/images/daily-report/time.png'),
          checked: false,
          blocked: false,
        },
      },
      {
        snack1_visible: {
          label: 'Snack 1',
          icon: require('../../assets/images/daily-report/snack.png'),
          checked: true,
          blocked: false,
        },
        snack1_comment_visible: {
          label: 'Comment',
          icon: require('../../assets/images/daily-report/comment.png'),
          checked: false,
          blocked: false,
        },
        sn1t_visible: {
          label: 'Time',
          icon: require('../../assets/images/daily-report/time.png'),
          checked: false,
          blocked: false,
        },
      },
      {
        snack2_visible: {
          label: 'PM Snack',
          icon: require('../../assets/images/daily-report/snack2.png'),
          checked: false,
          blocked: false,
        },
        snack2_comment_visible: {
          label: 'Comment',
          icon: require('../../assets/images/daily-report/comment.png'),
          checked: false,
          blocked: true,
        },
        sn2t_visible: {
          label: 'Time',
          icon: require('../../assets/images/daily-report/time.png'),
          checked: false,
          blocked: true,
        },
      },
      {
        lunch_visible: {
          label: 'Lunch',
          icon: require('../../assets/images/daily-report/lunch-more-pink.png'),
          checked: true,
          blocked: false,
        },
        lunch_comment_visible: {
          label: 'Comment',
          icon: require('../../assets/images/daily-report/comment.png'),
          checked: false,
          blocked: false,
        },
        luncht_visible: {
          label: 'Time',
          icon: require('../../assets/images/daily-report/time.png'),
          checked: false,
          blocked: false,
        },
      },
      {
        hygiene_visible: {
          label: 'Hygiene',
          icon: require('../../assets/images/daily-report/hygiene-peepoop.png'),
          checked: true,
          blocked: false,
        },
        hgnt_visible: {
          label: 'Time',
          icon: require('../../assets/images/daily-report/time.png'),
          checked: false,
          blocked: false,
        },
      },
      {
        milk_visible: {
          label: 'Milk',
          icon: require('../../assets/images/daily-report/milk-multi.png'),
          checked: true,
          blocked: false,
        },
        milk_ml_visible: {
          label: 'ML',
          icon: require('../../assets/images/daily-report/milk-multi.png'),
          checked: false,
          blocked: false,
        },
        milkt_visible: {
          label: 'Time',
          icon: require('../../assets/images/daily-report/time.png'),
          checked: false,
          blocked: false,
        },
      },
    ]

    this.defaultTimelineTypes = {
      "Notification": {
        uid: chance.unique(chance.integer, 1, { min: 99999999999, max: 999999999999 })[0],
        name: "Notification",
        picture: require('../../assets/images/user-timeline/notification.png'),
        active: true,
        classmain_types: [0, 1, 2, 3, 4],
      },
      "Event": {
        uid: chance.unique(chance.integer, 1, { min: 99999999999, max: 999999999999 })[0],
        name: "Event",
        picture: require('../../assets/images/user-timeline/event.png'),
        active: true,
        classmain_types: [0, 1, 2, 3, 4],
      },
      "Newsletter": {
        uid: chance.unique(chance.integer, 1, { min: 99999999999, max: 999999999999 })[0],
        name: "Newsletter",
        picture: require('../../assets/images/user-timeline/newsletter.png'),
        active: true,
        classmain_types: [0, 1, 2, 3, 4],
      },
      "Media": {
        uid: chance.unique(chance.integer, 1, { min: 99999999999, max: 999999999999 })[0],
        name: "Media",
        picture: require('../../assets/images/user-timeline/photos.png'),
        active: true,
        classmain_types: [0, 1, 2, 3, 4],
      },
      "Video": {
        uid: chance.unique(chance.integer, 1, { min: 99999999999, max: 999999999999 })[0],
        name: "Video",
        picture: require('../../assets/images/user-timeline/videos.png'),
        active: true,
        classmain_types: [0, 1, 2, 3, 4],
      },
      "Note": {
        uid: chance.unique(chance.integer, 1, { min: 99999999999, max: 999999999999 })[0],
        name: "Note",
        picture: require('../../assets/images/user-timeline/attachment-pink.png'),
        active: true,
        classmain_types: [0, 1, 2, 3, 4],
      },
      "Online Class": {
        uid: chance.unique(chance.integer, 1, { min: 99999999999, max: 999999999999 })[0],
        name: "Online Class",
        picture: require('../../assets/images/user-timeline/online-class.png'),
        active: true,
        classmain_types: [0, 1, 2, 3, 4],
      },
      "Resources": {
        uid: chance.unique(chance.integer, 1, { min: 99999999999, max: 999999999999 })[0],
        name: "Resources",
        picture: require('../../assets/images/user-timeline/download.png'),
        active: true,
        classmain_types: [0, 1, 2, 3, 4],
      },
      "Policy": {
        uid: chance.unique(chance.integer, 1, { min: 99999999999, max: 999999999999 })[0],
        name: "Policy",
        picture: require('../../assets/images/user-timeline/policy.png'),
        active: true,
        classmain_types: [0, 1, 2, 3, 4],
      },
      "Incident Report": {
        uid: chance.unique(chance.integer, 1, { min: 99999999999, max: 999999999999 })[0],
        name: "Incident Report",
        picture: require('../../assets/images/user-timeline/nurse-2.png'),
        active: true,
        classmain_types: [0, 1, 2, 3, 4],
      },
      "Poll": {
        uid: chance.unique(chance.integer, 1, { min: 99999999999, max: 999999999999 })[0],
        name: "Poll",
        picture: require('../../assets/images/user-timeline/poll.png'),
        active: true,
        classmain_types: [0, 1, 2, 3, 4],
      },
      "Survey": {
        uid: chance.unique(chance.integer, 1, { min: 99999999999, max: 999999999999 })[0],
        name: "Survey",
        picture: require('../../assets/images/user-timeline/survey.png'),
        active: true,
        classmain_types: [0, 1, 2, 3, 4],
      },
      "Assessment": {
        uid: chance.unique(chance.integer, 1, { min: 99999999999, max: 999999999999 })[0],
        name: "Observation",
        picture: require('../../assets/images/user-timeline/grade.png'),
        active: true,
        classmain_types: [0, 1, 2, 3, 4],
      },
      "CheckList": {
        uid: chance.unique(chance.integer, 1, { min: 99999999999, max: 999999999999 })[0],
        name: "Monthly Observation",
        picture: require('../../assets/images/user-timeline/grade.png'),
        active: true,
        classmain_types: [0, 1, 2, 3, 4],
      },
      "Agenda": {
        uid: chance.unique(chance.integer, 1, { min: 99999999999, max: 999999999999 })[0],
        name: "Agenda",
        picture: require('../../assets/images/user-timeline/agenda.png'),
        active: true,
        classmain_types: [0, 1, 2, 3, 4],
      },
      "Assignment": {
        uid: chance.unique(chance.integer, 1, { min: 99999999999, max: 999999999999 })[0],
        name: "Assignment",
        picture: require('../../assets/images/user-timeline/assignment.png'),
        active: true,
        classmain_types: [0, 1, 2, 3, 4],
      },
      "Daily Report": {
        uid: chance.unique(chance.integer, 1, { min: 99999999999, max: 999999999999 })[0],
        name: "Daily Report",
        picture: require('../../assets/images/user-timeline/daily-report.png'),
        active: true,
        classmain_types: [0, 1, 2, 3, 4],
      },
      "Exam": {
        uid: chance.unique(chance.integer, 1, { min: 99999999999, max: 999999999999 })[0],
        name: "Exam",
        picture: require('../../assets/images/user-timeline/exam.png'),
        active: true,
        classmain_types: [0, 1, 2, 3, 4],
      },
      "Online Homework": {
        uid: chance.unique(chance.integer, 1, { min: 99999999999, max: 999999999999 })[0],
        name: "Homework",
        picture: require('../../assets/images/user-timeline/exam.png'),
        active: true,
        classmain_types: [0, 1, 2, 3, 4],
      },
      "Academic Activity": {
        uid: chance.unique(chance.integer, 1, { min: 99999999999, max: 999999999999 })[0],
        name: "Activity",
        picture: require('../../assets/images/user-timeline/activity.png'),
        active: true,
        classmain_types: [0, 1, 2, 3, 4],
      },
      "Daily Activity": {
        uid: chance.unique(chance.integer, 1, { min: 99999999999, max: 999999999999 })[0],
        name: "Daily Report",
        picture: require('../../assets/images/user-timeline/daily-report.png'),
        active: true,
        classmain_types: [0, 1, 2, 3, 4],
      },
      "BMI": {
        uid: chance.unique(chance.integer, 1, { min: 99999999999, max: 999999999999 })[0],
        name: "BMI",
        picture: require('../../assets/images/user-timeline/profile.png'),
        active: true,
        classmain_types: [0, 1, 2, 3, 4],
      },
      "Medication Consent": {
        uid: chance.unique(chance.integer, 1, { min: 99999999999, max: 999999999999 })[0],
        name: "Medication Consent",
        picture: require('../../assets/images/user-timeline/nurse-2.png'),
        active: true,
        classmain_types: [0, 1, 2, 3, 4],
      },
      "Medicine Dose": {
        uid: chance.unique(chance.integer, 1, { min: 99999999999, max: 999999999999 })[0],
        name: "Medicine Dose",
        picture: require('../../assets/images/user-timeline/nurse-2.png'),
        active: true,
        classmain_types: [0, 1, 2, 3, 4],
      },
      "Upload": {
        uid: chance.unique(chance.integer, 1, { min: 99999999999, max: 999999999999 })[0],
        name: "Upload",
        picture: require('../../assets/images/user-timeline/mydocument.png'),
        active: true,
        classmain_types: [0, 1, 2, 3, 4],
      },
      "Exam Retake": {
        uid: chance.unique(chance.integer, 1, { min: 99999999999, max: 999999999999 })[0],
        name: "Exam Retake",
        picture: require('../../assets/images/user-timeline/mydocument.png'),
        active: true,
        classmain_types: [0, 1, 2, 3, 4],
      },
      "Academic Lesson": {
        uid: chance.unique(chance.integer, 1, { min: 99999999999, max: 999999999999 })[0],
        name: "Academic Lesson",
        picture: require('../../assets/images/user-timeline/journal.png'),
        active: true,
        classmain_types: [0, 1, 2, 3, 4],
      },
      "Pastoral Care": {
        uid: chance.unique(chance.integer, 1, { min: 99999999999, max: 999999999999 })[0],
        name: "Pastoral Care",
        picture: require('../../assets/images/user-timeline/nurse-2.png'),
        active: true,
        classmain_types: [0, 1, 2, 3, 4],
      },
      "Result": {
        uid: chance.unique(chance.integer, 1, { min: 99999999999, max: 999999999999 })[0],
        name: "Result",
        picture: require('../../assets/images/user-timeline/grade.png'),
        active: true,
        classmain_types: [0, 1, 2, 3, 4],
      },
      "Registration": {
        uid: chance.unique(chance.integer, 1, { min: 99999999999, max: 999999999999 })[0],
        name: "Registration",
        picture: require('../../assets/images/user-timeline/journal.png'),
        active: true,
        classmain_types: [0, 1, 2, 3, 4],
      },
      "Engage": {
        uid: chance.unique(chance.integer, 1, { min: 99999999999, max: 999999999999 })[0],
        name: "Engage",
        picture: require('../../assets/images/user-timeline/notification.png'),
        active: true,
        classmain_types: [0, 1, 2, 3, 4],
      },
    }

    this.state = {
      deleteModal: { show: false, id: 0, action: () => { }, title: null, desc: null, btnText: null },
      currentImageViewerIndex: 0,
      isViewerOpen: false,
      images: [],
      liked: 0,
      likesModal: false,
      commentsModal: false,
      seenModal: false,
      eventModal: false,
      comment: '',
      commentSending: false,
      attachment: null,
      comments: null,
      commentsFinished: false,
      commentDelAlert: false,
      commentDelID: 0,
      utc_is_root: 1,
      utc_root_id: 0,
      editBtnModal: {
        show: false,
        toggleModal: () => this.setState({ editBtnModal: { ...this.state.editBtnModal, show: !this.state.editBtnModal.show } })
      },
      utInfoModal: {
        show: false,
        toggleModal: () => this.setState({ utInfoModal: { ...this.state.utInfoModal, show: !this.state.utInfoModal.show } })
      },
      utClassesModal: {
        show: false,
        toggleModal: () => this.setState({ utClassesModal: { ...this.state.utClassesModal, show: !this.state.utClassesModal.show } })
      },
      resultsModal: {
        show: false,
        toggleModal: (ut_id = null) => this.setState({ resultsModal: { ...this.state.resultsModal, show: (ut_id) ? true : false, activeTab: 0 } }, () => {
          if (ut_id) {
            this.getPollSurveyResults(ut_id)
          }
        }),
        data: null,
        activeTab: 0,
        toggleTab: (activeTab) => this.setState({ resultsModal: { ...this.state.resultsModal, activeTab } })
      },
      approveMediaModal: {
        show: false,
        toggleModal: () => this.setState({ approveMediaModal: { ...this.state.approveMediaModal, show: !this.state.approveMediaModal.show } })
      },
      currentSurveyQuestion: 0,
      modalDailyReport: {
        show: false,
        title: '',
        toggle: (title = 'Daily Report', data) => this.setState({ modalDailyReport: { ...this.state.modalDailyReport, show: !this.state.modalDailyReport.show, title, data, } }, () => {
          if (this.state.modalDailyReport.show) {
            this.setState(
              {
                utc_is_root: 1,
                utc_root_id: 0,
                utp: data,
                comments: null,
                commentsFinished: false
              },
              () => this.getComments()
            )
          }
        }),
        data: null,
      },
      user_timeline_media: props.data.user_timeline_media
    }

    /**
     * Constructor
     */
    this.commentAttach = React.createRef()
    this.PollSurveyPrint = React.createRef()
  }

  componentDidMount() {
    this.setState({ liked: this.props.data.utt_liked })
  }

  renderDailyReport = (ut_data) => {
    const data = (ut_data) ? ut_data : this.state.modalDailyReport.data
    const dreportlog = data.dreportlog
    const dreportgeneral = data.dreportgeneral
    const childData = data.user_timeline_tagged[0]
    if (childData == undefined) {
      return null
    }

    let briefDreport = []
    if (dreportgeneral.mood_visible == 1) {
      if (dreportlog.mood_morning_value == null || dreportlog.mood_morning_value == 0) {
        briefDreport.push({ name: 'Mood', completed: false })
      } else {
        briefDreport.push({ name: 'Mood', completed: true })
      }
    }
    // FOOD
    if (dreportgeneral.bf_visible == 1) {
      if (dreportlog.food_breakfast_value == null || dreportlog.food_breakfast_value == 0) {
        briefDreport.push({ name: 'Breakfast', completed: false })
      } else {
        briefDreport.push({ name: 'Breakfast', completed: true })
      }
      if (dreportgeneral.bt_visible == 1) {
        if (dreportlog.breakfast_time == null) {
          briefDreport.push({ name: 'Breakfast Time', completed: false })
        } else {
          briefDreport.push({ name: 'Breakfast Time', completed: true })
        }
      }
      if (dreportgeneral.bf_comment_visible == 1) {
        if (dreportlog.breakfast_comment == null) {
          briefDreport.push({ name: 'Breakfast Comment', completed: false })
        } else {
          briefDreport.push({ name: 'Breakfast Comment', completed: true })
        }
      }
      if (dreportgeneral.snack1_visible == 1) {
        if (dreportlog.food_snack_value == null || dreportlog.food_snack_value == 0) {
          briefDreport.push({ name: 'AM Snack', completed: false })
        } else {
          briefDreport.push({ name: 'AM Snack', completed: true })
        }
      }
      if (dreportgeneral.sn1t_visible == 1) {
        if (dreportlog.snack1_time == null) {
          briefDreport.push({ name: 'AM Snack Time', completed: false })
        } else {
          briefDreport.push({ name: 'AM Snack Time', completed: true })
        }
      }

      if (dreportgeneral.snack1_comment_visible == 1) {
        if (dreportlog.snack1_comment == null) {
          briefDreport.push({ name: 'AM Snack Comment', completed: false })
        } else {
          briefDreport.push({ name: 'AM Snack Comment', completed: true })
        }
      }
      if (dreportgeneral.lunch_visible == 1) {
        if (dreportlog.food_lunch_value == null || dreportlog.food_lunch_value == 0) {
          briefDreport.push({ name: 'Lunch', completed: false })
        } else {
          briefDreport.push({ name: 'Lunch', completed: true })
        }
      }
      if (dreportgeneral.lunch_comment_visible == 1) {
        if (dreportlog.lunch_comment == null) {
          briefDreport.push({ name: 'Lunch Comment', completed: false })
        } else {
          briefDreport.push({ name: 'Lunch Comment', completed: true })
        }
      }
      if (dreportgeneral.luncht_visible == 1) {
        if (dreportlog.lunch_time == null) {
          briefDreport.push({ name: 'Lunch Time', completed: false })
        } else {
          briefDreport.push({ name: 'Lunch Time', completed: true })
        }
      }

      if (dreportgeneral.snack2_visible == 1) {
        if (dreportlog.food_snack2_value == null || dreportlog.food_snack2_value == 0) {
          briefDreport.push({ name: 'PM Snack', completed: false })
        } else {
          briefDreport.push({ name: 'PM Snack', completed: true })
        }
      }

      if (dreportgeneral.sn2t_visible == 1) {
        if (dreportlog.snack2_time == null) {
          briefDreport.push({ name: 'PM Snack Time', completed: false })
        } else {
          briefDreport.push({ name: 'PM Snack Time', completed: true })
        }
      }
      if (dreportgeneral.snack2_comment_visible == 1) {
        if (dreportlog.snack2_comment == null) {
          briefDreport.push({ name: 'PM Snack Comment', completed: false })
        } else {
          briefDreport.push({ name: 'PM Snack Comment', completed: true })
        }
      }
    }

    if (dreportgeneral.water_visible == 1) {
      if (dreportlog.water_value == null || dreportlog.water_value == 0) {
        briefDreport.push({ name: 'Water', completed: false })
      } else {
        briefDreport.push({ name: 'Water', completed: true })
      }
    }
    if (dreportgeneral.juice_visible == 1) {
      if (dreportlog.juice_value == null || dreportlog.juice_value == 0) {
        briefDreport.push({ name: 'Juice', completed: false })
      } else {
        briefDreport.push({ name: 'Juice', completed: true })
      }
    }

    if (dreportgeneral.milk_visible == 1) {
      if (dreportlog.Milk == false) {
        briefDreport.push({ name: 'Milk', completed: false })
      } else {
        briefDreport.push({ name: 'Milk', completed: true })
      }
    }

    if (dreportgeneral.nap_visible == 1) {
      if (dreportlog.Nap == false) {
        briefDreport.push({ name: 'Nap', completed: false })
      } else {
        briefDreport.push({ name: 'Nap', completed: true })
      }
    }
    if (dreportgeneral.hygiene_visible == 1) {
      if (dreportlog.Hygiene == false) {
        briefDreport.push({ name: 'Hygiene', completed: false })
      } else {
        briefDreport.push({ name: 'Hygiene', completed: true })
      }
    }
    let briefDreportHalf = briefDreport.length / 2
    {/* 
        milk_ml_visible
        milkt_visible
        hgnt_visible
        MEDICINE IS HIDDEN  
        */}
    {/* MISSING REPORT ITEMS */ }
    return (<div className='d-flex flex-row' style={{ borderStyle: 'solid', borderWidth: 1, borderColor: brand_colors[this.props.defaultTheme.theme_id].color4, borderRadius: 20, margin: 20, padding: 10 }}>
      <div style={{ flex: 1, paddingVertical: 15, paddingLeft: 20 }}>
        {briefDreport.map((v, i) =>
          ((i + 1) <= briefDreportHalf + 2)
            ?
            <div key={i} className='d-flex flex-row align-items-center'>
              <div style={{ marginRight: 10 }}>
                {(v.completed) ? <FontAwesomeIcon icon={faCheckCircle} style={{ fontSize: 20, color: brand_colors[this.props.defaultTheme.theme_id].color4 }} /> : <FontAwesomeIcon icon={faTimes} style={{ fontSize: 20, color: brand_colors[this.props.defaultTheme.theme_id].color11, opacity: 0.7 }} />}
              </div>
              <div className='d-flex flex-row flex-wrap' style={{ flex: 1 }}>
                <div style={{ fontSize: 14, color: brand_colors[this.props.defaultTheme.theme_id].color9, opacity: 0.7, fontWeight: 'bold', }}>{t(v.name)}</div>
              </div>
            </div>
            : null
        )}
      </div>
      <div className='d-flex flex-column align-items-start justify-content-between' style={{ flex: 1, }}>
        <div className='d-flex flex-column align-items-start justify-content-start' style={{ flex: 1, paddingTop: 15, paddingLeft: 10 }}>
          {briefDreport.map((v, i) => {
            if ((i + 1) > (briefDreportHalf + 2)) {
              return <div className='d-flex flex-row align-items-center' key={i}>
                <div style={{ marginRight: 10 }}>
                  {(v.completed) ? <FontAwesomeIcon icon={faCheckCircle} style={{ fontSize: 20, color: brand_colors[this.props.defaultTheme.theme_id].color4 }} /> : <FontAwesomeIcon icon={faTimes} style={{ fontSize: 20, color: brand_colors[this.props.defaultTheme.theme_id].color11, opacity: 0.7 }} />}
                </div>
                <div className='d-flex flex-row flex-wrap' style={{ flex: 1 }}>
                  <div style={{ fontSize: 14, color: brand_colors[this.props.defaultTheme.theme_id].color9, opacity: 0.7, fontWeight: 'bold', }}>{t(v.name)}</div>
                </div>
              </div>
            }
          })}
        </div>
      </div>
    </div>
    )
  }
  renderDailyReport2 = (ut_data) => {
    const rowdata = (ut_data) ? ut_data : this.state.modalDailyReport.data

    console.log("rowdata", rowdata)
    let dreportgeneral = rowdata.dreportgeneral
    let dreportlog = rowdata.dreportlog
    let reportRenderData = []


    if (dreportgeneral.mood_visible == 1) {
      reportRenderData.push({
        renderType: "Single",
        renderTitle: "Mood",
        saveColumn: "mood_morning_value",
        saveCommentColumn: "",
        saveTimeColumn: "",
        renderTime: false,
        renderComment: false,
        renderElements: [
          {
            name: "Happy",
            value: 1,
            picture: require('../../assets/images/daily-report/bear-happy.png'),
          },
          {
            name: "Settled",
            value: 2,
            picture: require('../../assets/images/daily-report/bear-settled.png'),
          },
          {
            name: "Sad",
            value: 3,
            picture: require('../../assets/images/daily-report/bear-sad.png'),
          },
        ],
      })
    }
    if (dreportgeneral.food_visible == 1) {
      if (dreportgeneral.bf_visible == 1) {
        reportRenderData.push({
          renderType: "Single",
          renderTitle: "Breakfast",
          saveColumn: "food_breakfast_value",
          saveCommentColumn: "breakfast_comment",
          saveTimeColumn: "breakfast_time",
          renderTime: dreportgeneral.bt_visible == 1 ? true : false,
          renderComment: dreportgeneral.bf_comment_visible == 1 ? true : false,
          renderElements: [
            {
              name: "Little",
              value: 1,
              picture:
                require('../../assets/images/daily-report/breakfast-less-green.png'),
            },
            {
              name: "Most",
              value: 2,
              picture:
                require('../../assets/images/daily-report/breakfast-moderate-green.png'),
            },
            {
              name: "All",
              value: 3,
              picture:
                require('../../assets/images/daily-report/breakfast-more-green.png'),
            },
          ],
        })
      }
      if (dreportgeneral.snack1_visible == 1) {
        reportRenderData.push({
          renderType: "Single",
          renderTitle: "Snack",
          saveColumn: "food_snack_value",
          saveCommentColumn: "snack1_comment",
          saveTimeColumn: "snack1_time",
          renderTime: dreportgeneral.sn1t_visible == 1 ? true : false,
          renderComment:
            dreportgeneral.snack1_comment_visible == 1 ? true : false,
          renderElements: [
            {
              name: "Little",
              value: 1,
              picture: require('../../assets/images/daily-report/snack-less.png'),
            },
            {
              name: "Most",
              value: 2,
              picture: require('../../assets/images/daily-report/snack-moderate.png'),
            },
            {
              name: "All",
              value: 3,
              picture: require('../../assets/images/daily-report/snack-all.png'),
            },
          ],
        })
      }
      if (dreportgeneral.lunch_visible == 1) {
        reportRenderData.push({
          renderType: "Single",
          renderTitle: "Lunch",
          saveColumn: "food_lunch_value",
          saveCommentColumn: "lunch_comment",
          saveTimeColumn: "lunch_time",
          renderTime: dreportgeneral.luncht_visible == 1 ? true : false,
          renderComment:
            dreportgeneral.lunch_comment_visible == 1 ? true : false,
          renderElements: [
            {
              name: "Little",
              value: 1,
              picture: require('../../assets/images/daily-report/lunch-less-pink.png'),
            },
            {
              name: "Most",
              value: 2,
              picture:
                require('../../assets/images/daily-report/lunch-moderate-pink.png'),
            },
            {
              name: "All",
              value: 3,
              picture: require('../../assets/images/daily-report/lunch-more-pink.png'),
            },
          ],
        })
      }
      if (dreportgeneral.snack2_visible == 1) {
        reportRenderData.push({
          renderType: "Single",
          renderTitle: "Snack",
          saveColumn: "food_snack2_value",
          saveCommentColumn: "snack2_comment",
          saveTimeColumn: "snack2_time",
          renderTime: dreportgeneral.sn2t_visible == 1 ? true : false,
          renderComment:
            dreportgeneral.snack2_comment_visible == 1 ? true : false,
          renderElements: [
            {
              name: "Little",
              value: 1,
              picture: require('../../assets/images/daily-report/snack2-less.png'),
            },
            {
              name: "Most",
              value: 2,
              picture: require('../../assets/images/daily-report/snack2-moderate.png'),
            },
            {
              name: "All",
              value: 3,
              picture: require('../../assets/images/daily-report/snack2-all.png'),
            },
          ],
        })
      }
    }
    if (dreportgeneral.milk_visible == 1) {
      reportRenderData.push({
        renderType: "Multiple",
        renderTitle: "Milk",
        saveColumn: "dreportlog_details",
        picture: require('../../assets/images/daily-report/milk-multi.png'),
        render1: {
          name: "Quantity",
          iconType: "MaterialCommunityIcons",
          iconName: "weight",
          active: dreportgeneral.milk_ml_visible == 1 ? true : false,
        },
        render2: {
          name: "Time",
          iconType: "Feather",
          iconName: "clock",
          active: dreportgeneral.milkt_visible == 1 ? true : false,
        },
      })
    }
    if (dreportgeneral.water_visible == 1) {
      reportRenderData.push({
        renderType: "Single",
        renderTitle: "Water",
        saveColumn: "water_value",
        saveCommentColumn: "",
        saveTimeColumn: "water_value",
        renderTime: false,
        renderComment: false,
        renderElements: [
          {
            name: "Little",
            value: 1,
            picture: require('../../assets/images/daily-report/water-less.png'),
          },
          {
            name: "Most",
            value: 2,
            picture: require('../../assets/images/daily-report/water-moderate.png'),
          },
          {
            name: "All",
            value: 3,
            picture: require('../../assets/images/daily-report/water-more.png'),
          },
        ],
      })
    }
    if (dreportgeneral.juice_visible == 1) {
      reportRenderData.push({
        renderType: "Single",
        renderTitle: "Juice",
        saveColumn: "juice_value",
        saveCommentColumn: "",
        saveTimeColumn: "juice_value",
        renderTime: false,
        renderElements: [
          {
            name: "Little",
            value: 1,
            picture: require('../../assets/images/daily-report/juice-yellow-less.png'),
          },
          {
            name: "Most",
            value: 2,
            picture: require('../../assets/images/daily-report/juice-yellow-most.png'),
          },
          {
            name: "All",
            value: 3,
            picture: require('../../assets/images/daily-report/juice-yellow-all.png'),
          },
        ],
      })
    }
    if (dreportgeneral.hygiene_visible == 1) {
      reportRenderData.push({
        renderType: "Multiple",
        renderTitle: "Hygiene",
        saveColumn: "dreportlog_details",
        picture: require('../../assets/images/daily-report/hygiene-peepoop.png'),
        render1: {
          name: "State",
          iconType: "FontAwesome5",
          iconName: "poop",
          active: 1,
        },
        render2: {
          name: "Time",
          iconType: "Feather",
          iconName: "clock",
          active: dreportgeneral.hgnt_visible == 1 ? true : false,
        },
      })
    }
    if (dreportgeneral.nap_visible == 1) {
      reportRenderData.push({
        renderType: "Multiple",
        renderTitle: "Nap",
        saveColumn: "dreportlog_details",
        picture: require('../../assets/images/daily-report/nap.png'),
        render1: {
          name: "Time",
          iconType: "Feather",
          iconName: "clock",
          active: true,
        },
        render2: {
          name: "Time",
          iconType: "Feather",
          iconName: "clock",
          active: true,
        },
      })
    }
    if (dreportgeneral.potty_training_visible == 1) {
      reportRenderData.push({
        renderType: "Multiple",
        renderTitle: "Potty Training",
        saveColumn: "dreportlog_details",
        picture: require('../../assets/images/daily-report/potty-training.png'),
        render1: {
          name: "State",
          iconType: "FontAwesome5",
          iconName: "poop",
          active: 1,
        },
        render2: {
          name: "Time",
          iconType: "Feather",
          iconName: "clock",
          active: true,
        },
      })
    }
    if (dreportgeneral.toilet_visits_visible == 1) {
      reportRenderData.push({
        renderType: "Multiple",
        renderTitle: "Toilet Visits",
        saveColumn: "dreportlog_details",
        picture: require('../../assets/images/daily-report/toilet-visits.png'),
        render1: {
          name: "State",
          iconType: "FontAwesome5",
          iconName: "poop",
          active: 1,
        },
        render2: {
          name: "Time",
          iconType: "Feather",
          iconName: "clock",
          active: true,
        },
      })
    }
    if (dreportgeneral.temperature_visible == 1) {
      reportRenderData.push({
        renderType: "Multiple",
        renderTitle: "Temperature",
        saveColumn: "dreportlog_details",
        picture: require('../../assets/images/daily-report/temperature.png'),
        render1: {
          name: "State",
          iconType: "FontAwesome5",
          iconName: faThermometerEmpty,
          active: 1,
        },
        render2: {
          name: "Time",
          iconType: "Feather",
          iconName: "clock",
          active: true,
        },
      })
    }
    if (dreportgeneral.need_diapers_visible == 1) {
      reportRenderData.push({
        renderType: "RadioBox",
        renderTitle: "Need Diapers",
        saveColumn: "need_diapers",
        renderElements: [{ name: 'Yes', value: 1, iconName: faCheck }, { name: 'No', value: 0, iconName: faTimes }]
      })
    }
    if (dreportgeneral.need_clothes_visible == 1) {
      reportRenderData.push({
        renderType: "RadioBox",
        renderTitle: "Need Clothes",
        saveColumn: "need_clothes",
        renderElements: [{ name: 'Yes', value: 1, iconName: faCheck }, { name: 'No', value: 0, iconName: faTimes }]
      })
    }

    reportRenderData.push({
      renderType: "Multiple",
      renderTitle: "Medicine",
      saveColumn: "dreportlog_details",
      picture: require('../../assets/images/daily-report/medicine-pink.png'),
      render1: {
        name: "Name",
        iconType: "Ionicons",
        iconName: "medkit-outline",
        active: true,
      },
      render2: {
        name: "Time",
        iconType: "Feather",
        iconName: "clock",
        active: true,
      },
    })

    console.log("reportRenderData", reportRenderData, dreportlog)
    let HTML = ""
    reportRenderData.map((v, i) => {
      let isRenderData = false
      if (dreportlog[v.renderTitle]) {
        isRenderData = dreportlog[v.renderTitle]
      }
      console.log("reportRenderData", v, isRenderData)
      if (v.renderType == "Single") {
        if (
          (v.renderComment &&
            dreportlog[v.saveCommentColumn] != "" &&
            dreportlog[v.saveCommentColumn] != null) ||
          (v.renderTime &&
            dreportlog[v.saveTimeColumn] != 0 &&
            dreportlog[v.saveTimeColumn] != undefined &&
            dreportlog[v.saveTimeColumn] != null) ||
          (dreportlog[v.saveColumn] != 0 &&
            dreportlog[v.saveColumn] != undefined &&
            dreportlog[v.saveColumn] != null)
        ) {
          HTML += `<div class="p-1 mb-2 border-bottom">`
          HTML += `<div class="mb-1">
                              <span style="font-size:18px">${v.renderTitle}</span>
                              <strong>${dreportlog[v.saveTimeColumn] ? " @ " + moment(dreportlog[v.saveTimeColumn], "HH:mm").format("hh:mm A") : ""}</strong>
                          </div>`
          if (dreportlog[v.saveColumn] != 0 && dreportlog[v.saveColumn] != undefined && dreportlog[v.saveColumn] != null) {
            HTML += `<div class="d-flex">`
            v.renderElements.map((vv, ii) => {
              HTML += `<div class="p-1 text-center ${dreportlog[v.saveColumn] == vv.value ? "border" : ""}" style="border-color: #00d045 !important">
                            <img style="height: 100px; width: 100px" src="${vv.picture}"/>
                            <p class="mb-0 mt-1" style="font-size:14px font-weight:bold">${vv.name}</p>
                        </div>`
            })
            HTML += `</div>`
          }

          if (
            v.renderComment &&
            dreportlog[v.saveCommentColumn] != "" &&
            dreportlog[v.saveCommentColumn] != null
          ) {
            HTML += `<div>${dreportlog[v.saveCommentColumn]}</div>`
          }
          HTML += `</div>`
        }
      }
      else if (v.renderType == "Multiple" && v.renderTitle == "Milk" && (v.render1.active || v.render2.active) && isRenderData) {
        HTML += `<div class="p-1 mb-2 border-bottom">
                          <div>
                              <span style="font-size:18px">${v.renderTitle}</span>
                          </div>
                      `
        if (isRenderData) {
          HTML += `<div class="d-flex">`
          isRenderData.map((vv, ii) => {
            HTML += `<div class="p-1 text-center d-flex flex-column me-2">
                      <img style="height: 100px; width: 100px" src="${v.picture}"/>
                      ${v.render1.active ? vv.dreport_log_detail_desc ? vv.dreport_log_detail_desc + " ml" : "-" : ""}
                      ${v.render2.active ? vv.dreport_log_detail_time1 ? " @ " + moment(vv.dreport_log_detail_time1, "HH:mm").format("hh:mm A") : "-" : ""}
                    </div>`
          })
          HTML += `</div>`
        }
        HTML += `</div>`
      }
      else if (v.renderType == "Multiple" && v.renderTitle == "Hygiene" && (v.render1.active || v.render2.active) && isRenderData) {
        HTML += `<div class="p-1 mb-2 border-bottom">
                          <div>
                              <span style="font-size:18px">${v.renderTitle}</span>
                          </div>
                      `
        if (isRenderData) {
          HTML += `<div class="d-flex">`
          isRenderData.map((vv, ii) => {
            HTML += `<div class="p-1 text-center d-flex flex-column">`
            if (v.render1.active) {
              if (vv.dreport_log_detail_desc == "Solid") {
                HTML += `<img style="height: 100px; width: 100px" src="${require('../../assets/images/daily-report/hygiene-poop.png')}" />`
              } else if (vv.dreport_log_detail_desc == "Wet") {
                HTML += `<img style="height: 100px; width: 100px" src="${require('../../assets/images/daily-report/hygiene-pee.png')}" />`
              }
            }
            if (v.render1.active) {
              HTML += `<p class="mt-1 mb-0">${vv.dreport_log_detail_time1 ? " @ " + moment(vv.dreport_log_detail_time1, "HH:mm").format("hh:mm A") : ""}</p>`
            }
            if (v.render2.active) {
              HTML += `<p class="mt-1 mb-0">${vv.dreport_log_detail_time2
                ? " @ " +
                moment(vv.dreport_log_detail_time2, "HH:mm").format("hh:mm A")
                : ""
                }</p>`
            }
            HTML += `</div>`
          })
          HTML += `</div>`
        }
        HTML += `</div>`
      }
      else if (v.renderType == "Multiple" && ['Medicine', 'Nap', 'Potty Training', 'Toilet Visits', 'Temperature'].includes(v.renderTitle) && isRenderData) {
        HTML += `<div class="p-1 mb-2 border-bottom">
                    <div>
                        <span style="font-size:18px">${v.renderTitle}</span>
                    </div>`
        if (isRenderData) {
          HTML += `<div class="d-flex">`
          isRenderData.map((vv, ii) => {
            HTML += `<div class="p-1 d-flex flex-column me-2">
                      <img style="height: 100px; width: 100px" src="${v.picture}"/>`

            if (v.render1.active) {
              if (vv.dreport_log_detail_desc) {
                HTML += `${vv.dreport_log_detail_desc}`
              } else {
                HTML += `-`
              }
            }
            if (v.render2.active) {
              HTML += `${vv.dreport_log_detail_time1 ? " @ " + moment(vv.dreport_log_detail_time1, "HH:mm").format("hh:mm A") : ""}`
            }
            HTML += `</div>`
          })
          HTML += `</div>`
        }
        HTML += `</div>`
      }
      else if (v.renderType == "RadioBox") {
        if (dreportlog[v.saveColumn] != undefined && dreportlog[v.saveColumn] != null) {
          HTML += `<div class="p-1 mb-2 border-bottom">`
          HTML += `<div class="mb-1">
                      <span style="font-size:18px">${v.renderTitle}</span>
                    </div>`
          if (dreportlog[v.saveColumn] != undefined && dreportlog[v.saveColumn] != null) {
            HTML += `<div class="d-flex">
                      <div class="p-1">
                          <p class="mb-0 mt-1" style="font-size:14px font-weight:bold">${(dreportlog[v.saveColumn] == 1) ? 'YES' : 'NO'}</p>
                      </div>
                    </div>`
          }
          HTML += `</div>`
        }
      }
    })


    return HTML
  }

  /**
   * Handle Modals
   */
  handleLikesModal = () => {
    const { likesModal } = this.state
    this.setState({
      likesModal: !likesModal,
    })
  }
  handleCommentsModal = () => {
    const { commentsModal } = this.state
    this.setState({
      commentsModal: !commentsModal,
      utc_is_root: 1,
      utc_root_id: 0,
    })
  }
  handleSeenModal = () => {
    const { seenModal } = this.state
    this.setState({
      seenModal: !seenModal,
    })
  }


  /**
   * Open Image Viewer
   * @param {number} index Index of the image
   * @param {number} center_id ID of the center
   * @param {string[]} images Array of images
   */
  openImageViewer = (index, center_id, images, folder, file_key) => {

    const images_arr = images.map((item) => {
      return (item.utm_url)
        ? item.utm_url
        : (item.utm_folder)
          ? portalURL + item.center_id + '/' + item.utm_folder + '/' + item.utm_name
          : portalURL + item.center_id + '/user_timeline_media/' + item.utm_name
      // return (
      //   portalURL + center_id + '/' + folder + '/' + item[file_key]
      // )
    })

    this.setState({
      currentImageViewerIndex: index,
      isViewerOpen: true,
      images: images_arr,
    })
  }

  openSingleImageViewer = (index, image) => {
    const images_arr = [image]
    this.setState({
      currentImageViewerIndex: 0,
      isViewerOpen: true,
      images: images_arr,
    })
  }

  /**
   * Close Image Viewer
   */
  closeImageViewer = () => {
    this.setState({
      currentImageViewerIndex: 0,
      isViewerOpen: false,
      images: [],
    })
  }

  /**
   * Get Likes
   */
  getLikes = async () => {
    const { utp } = this.state

    // const { data, authData } = this.props
    // const { liked } = this.state
    // const newLiked = liked == 0 ? 1 : 0

    // this.setState({
    //     liked: newLiked,
    // })

    // try {
    //     const fData = new FormData()
    //     fData.append('utt_id', data.utt_id)
    //     fData.append('utt_liked', newLiked)

    //     const req = await axios.post(
    //         apiURL + 'user-timeline/update-reaction',
    //         fData,
    //         {
    //             params: {
    //                 auth_key: authData.authKey,
    //             },
    //         }
    //     )

    //     if (req.status === 200) {
    //         // 
    //     }
    // } catch (err) {
    //     
    // }
  }

  /**
   * Get Comments
   * @param {Object} data
   */
  getComments = async () => {
    // this.setState({ comments: null })

    const { authData } = this.props
    const { center_id, center_timezone } = authData.centerData
    const { auth_key, user_id } = authData.loginData
    const { utc_root_id, utp } = this.state

    const params = {
      ut_id: utp.ut_id,
      utc_id: (this.state.comments) ? this.state.comments[0].utc_id : 0,
      user_id,
      utc_root_id: utc_root_id,
      center_timezone,
    }
    try {
      const res = await GET('user-timeline/get-user-timeline-comments',
        {
          params,
        }
      )
      this.setState({
        comments: (this.state.comments) ? (res.data.reverse()).concat(this.state.comments) : res.data.reverse(),
        commentsFinished: (res.data.length <= 0) ? true : false
      })
    } catch (err) {
      console.log('err', err)
      toast.error(t('Something went wrong while fetching comments!'))
    }
  }

  /**
   * Send Comment
   * @param {Object} data
   * @returns
   */
  sendComment = async () => {
    this.setState({ commentSending: true })
    const { auth_key, user_id, full_name } = this.props.authData.loginData
    const { center_id, center_timezone } = this.props.authData.centerData
    const { comment, attachment, utc_is_root, utc_root_id, utp } =
      this.state

    if (!comment) {
      toast.error(t('Cannot send empty comment'))
      this.setState({ commentSending: false })
      return
    }

    const fData = new FormData()
    fData.append('utc_id', 0); // This needs to be set later when we build the edit comment functionality
    fData.append('ut_id', utp.ut_id)
    fData.append('utt_id', utp.utt_id)
    fData.append('utc_is_root', utc_is_root)
    fData.append('utc_root_id', utc_root_id)
    fData.append('center_id', center_id)
    fData.append('center_timezone', center_timezone)
    fData.append('user_id', user_id)
    fData.append('utc_comment', comment)
    if (attachment !== null) {
      fData.append('utc_attachment', attachment);
    } else {
      fData.append('utc_attachment', null);
    }

    try {
      const res = await axios.post(
        apiURL + 'user-timeline/save-user-timeline-comment',
        fData,
        {
          params: {
            auth_key: auth_key,
            appname: process.env.REACT_APP_NAME,
          },
        }
      )

      if (!res.data.type) {
        toast.error(res.data.message)
      } else {
        this.setState({
          comment: '',
          attachment: null,
          comments: (this.state.comments) ? this.state.comments.concat(res.data.data) : [res.data.data],
        })
        // this.getComments()

        // //  Send FCM
        // this.sendFCM(
        //     currentChat.user_id,
        //     `New comment from ${full_name}`,
        //     res.data.firebaseData.body,
        //     res.data.firebaseData.data,
        //     auth_key
        // )
      }

      this.setState({ commentSending: false })
    } catch (err) {
      console.log('err', err)
      toast.error(t('Something went wrong while sending comment!'))

      this.setState({ commentSending: false })
    }
  }
  confirmDeleteComment = (commentDelID) => {
    this.setState({ commentDelAlert: true, commentDelID });
  }
  cancelDeleteComment = () => {
    this.setState({ commentDelAlert: false, commentDelID: 0 });
  }
  deleteComment = async () => {
    const { commentDelID, comments } = this.state;

    const fData = new FormData();
    fData.append('utc_id', commentDelID);

    try {
      const res = await POST('user-timeline/delete-user-timeline-comment', fData);
      console.log(res);
    } catch (err) {
      console.log(err);
    }
    this.setState({
      comments: comments.filter(item => item.utc_id != commentDelID),
      commentDelAlert: false,
      commentDelID: 0
    });
  }


  /**
   * Set Comment Attachment
   */
  setCommentAttach = () => {
    this.commentAttach.current.click()
  }

  /**
   * Upload File Handler
   */
  uploadFile = () => {
    const file = document.querySelector('#comment-attach').files[0]

    if (file.type == 'image/jpeg' || file.type == 'image/png') {
      this.setState({
        attachment: file,
      })
    } else {
      toast.error(t('Only images are allowed in attachment!'))
    }
  }

  /**
   * Open Conversation
   * @param {Number} utc_id
   */
  openConversation = (utc_id) => {
    this.setState(
      {
        utc_is_root: 0,
        utc_root_id: utc_id,
        comments: null,
        commentsFinished: false
      },
      () => this.getComments()
    )
  }

  /**
   * Close Conversation
   */
  closeConversation = () => {
    this.setState(
      {
        utc_is_root: 1,
        utc_root_id: 0,
        comments: null,
        commentsFinished: false
      },
      () => this.getComments()
    )
  }

  /**
   * Render Likes
   * @param {Array} tagged
   * @returns
   */
  renderLikes = (tagged) => {
    let anyLikes = tagged.find((child, index) => child.utt_liked != 0)
    if (anyLikes) {
      return tagged.map((child, index) => {
        if (child.utt_liked !== 0) {
          return (
            <div className='tagged-child' key={index}>
              <div
                className='tagged-child-pic'
                style={{
                  borderWidth: 2,
                  borderColor: brand_colors[this.props.defaultTheme.theme_id].color1,
                  borderStyle: 'solid',
                  backgroundImage:
                    !child.picture
                      ? 'url(' + portalURL + child.center_id + '/children/' + child.picture + ')'
                      : 'url(https://via.placeholder.com/80?text=No+Thumb)',
                  width: 50,
                  height: 50,
                  backgroundPosition: 'center center',
                  backgroundSize: 'cover',
                  borderRadius: 25,
                }}></div>
              {child.child_name}
              <Image src={require('../../assets/images/reactions/' + child.utt_liked + '.png')} className={'reaction'} />
              {/* <FontAwesomeIcon
                                icon={faHeart}
                                color={color1}
                            /> */}
            </div>
          )
        }
      })
    } else {
      return t('No Like yet!')
    }
  }

  /**
   * Count Seen
   * @param {Array} tagged
   * @returns
   */
  countSeen = (tagged) => {
    return tagged.filter((item) => {
      return item.utt_seen
    }).length
  }

  eventConfirmed = (tagged) => {
    return tagged.filter((item) => {
      return item.utt_confirmed_condition == 'Yes'
    }).length
  }

  countLikes = (tagged) => {
    return tagged.filter((item) => {
      return item.utt_liked !== 0
    }).length
  }

  /**
   * Render Seen
   * @param {Array} tagged
   * @returns
   */
  renderSeen = (tagged) => {

    if (tagged.length > 0) {

      let classList = [];

      tagged.map(item => {
        if (!classList.includes(item.class_theme)) {
          classList.push(item.class_theme);
        }
      });

      classList.sort();

      return classList.map(classTheme => {

        let taggedStudents = tagged
          .filter(child => child.class_theme == classTheme)
          .map((child, index) => {
            return (
              <div className='tagged-child' key={index}>
                <div
                  className='tagged-child-pic'
                  style={{
                    borderWidth: 2,
                    borderColor: brand_colors[this.props.defaultTheme.theme_id].color1,
                    borderStyle: 'solid',
                    backgroundImage: (child.picture) ? 'url(' + portalURL + child.center_id + '/children/' + child.picture + ')' : 'url(https://via.placeholder.com/80?text=No+Thumb)',
                    width: '60px',
                    height: '60px',
                    backgroundPosition: 'center center',
                    backgroundSize: 'cover',
                    borderRadius: 25,
                    position: 'relative'
                  }}>

                  {child.utt_seen
                    ? (<FontAwesomeIcon className='icon-seen' title={t('Seen @ ') + child.utt_seen} icon={faEye} color={brand_colors[this.props.defaultTheme.theme_id].color4} />)
                    : (<FontAwesomeIcon className='icon-seen' title={t('Not Seen')} icon={faEyeSlash} color='rgb(220, 53, 69)' />)
                  }
                </div>
                <div>{child.child_name}</div>
                {(child.utt_seen)
                  ? <Badge bg="success" style={{ marginTop: 4 }}>
                    {moment(child.utt_seen).format('DD-MM-YYYY')}
                    <div>{moment(child.utt_seen).format('hh:mm A')}</div>
                  </Badge>
                  : null
                }
              </div>
            )
          });

        return <div>
          <div className='text-center'>
            <strong>{classTheme}</strong>
          </div>
          <hr />
          <div className="tagged-students mb-4">{taggedStudents}</div>
        </div>;
      });
    } else {
      return t('No Student Tagged!')
    }
  }

  exportExcel_renderEventConfirmed = async (tagged) => {
    const excelData = tagged.map((v) => {
      if (v.utt_confirmed_condition == 'Yes') {
        return { 'Student': v.child_name, 'Confirmed': 'Yes @ ' + v.utt_confirmed, 'Parent': v.parent_name, 'Phone': v.phone1, 'Email': v.email1 };
      } else if (v.utt_confirmed_condition == 'No') {
        return { 'Student': v.child_name, 'Confirmed': 'No @ ' + v.utt_confirmed, 'Parent': v.parent_name, 'Phone': v.phone1, 'Email': v.email1 };
      } else if (v.utt_confirmed_condition == 'Maybe') {
        return { 'Student': v.child_name, 'Confirmed': 'Maybe @ ' + v.utt_confirmed, 'Parent': v.parent_name, 'Phone': v.phone1, 'Email': v.email1 };
      } else if (!v.utt_confirmed) {
        return { 'Student': v.child_name, 'Confirmed': 'Pending', 'Parent': v.parent_name, 'Phone': v.phone1, 'Email': v.email1 };
      }
    });

    const fileName = 'Confirmation Report ' + moment().format('DD-MM-YYYY HH:mm:ss');
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const ws = XLSX.utils.json_to_sheet(excelData);

    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });

    FileSaver.saveAs(data, fileName + fileExtension);
  }

  exportExcel_Survey = async (tdata) => {
    if (this.getPollSurveyResultsReq) {
      this.getPollSurveyResultsReq.abort()
    }

    this.getPollSurveyResultsReq = new AbortController()
    GET('poll-survey-results/get-results-excel-format', {
      params: {
        ut_id: tdata.ut_id,
      },
      signal: this.getPollSurveyResultsReq.signal,
    })
      .then((res) => {
        let resData = res.data
        const excelData = resData.user_timeline_tagged.map((v) => {
          let rdata = {}

          // rdata['ID'] = ''
          rdata['Student ID'] = v.child_code
          // rdata['Family ID'] = ''
          rdata['Student Name'] = v.child_name
          rdata['Nursery Name'] = v.center_name
          rdata['Gr/Yr Level'] = v.agegroup_code
          rdata['Section'] = v.class_theme
          rdata['Age group'] = v.agegroup_from + ' ' + v.agegroup_to
          // rdata['Teacher'] = ''
          rdata['Nationality'] = v.name_en

          resData.survey_questions.map((vv, ii) => {
            let survey_question_options = vv.survey_question_options
            let survey_answers = vv.survey_answers.find((vvv, iii) => vvv.user_id == v.user_id)

            rdata['Q' + (ii + 1)] = ''
            rdata['Q' + (ii + 1) + ' - Comment'] = ''
            if (survey_answers) {
              if (vv.eqtype_id == 'MCQ' || vv.eqtype_id == 'MRQ' || vv.eqtype_id == 'TF' || vv.eqtype_id == 'SCALE' || vv.eqtype_id == 'RATING') {
                survey_question_options.map((vvv, iii) => {
                  if (JSON.parse(survey_answers.survey_q_opt_ids).includes(String(vvv.survey_q_opt_id)) || JSON.parse(survey_answers.survey_q_opt_ids).includes((vvv.survey_q_opt_id))) {
                    rdata['Q' + (ii + 1)] = rdata['Q' + (ii + 1)] + '' + vvv.survey_q_opt_desc + ', '
                  }
                })
              }
              else if (vv.eqtype_id == 'COMMENTS') {
                let survey_q_opt_ids = JSON.parse(survey_answers['survey_q_opt_ids'])
                rdata['Q' + (ii + 1)] = rdata['Q' + (ii + 1)] + '' + survey_q_opt_ids.join(', ') + ', '
              }
              else if (vv.eqtype_id == 'MATRIX') {
                let survey_q_answers = JSON.parse(vv.survey_q_answers)
                let survey_q_opt_ids = JSON.parse(survey_answers['survey_q_opt_ids'])
                survey_question_options.map((vvv, iii) => {
                  survey_q_answers.map(($value3, $key3) => {
                    survey_q_opt_ids.map(($value5, $key5) => {
                      if ($value5['survey_q_opt_id'] == vvv['survey_q_opt_id'] && $value5['survey_q_answer'] == $value3) {
                        rdata['Q' + (ii + 1)] = rdata['Q' + (ii + 1)] + '' + $value3 + ', '
                      }
                    })
                  })
                })
              }
              // rdata['Q' + (ii + 1)] = rdata['Q' + (ii + 1)] + 'COMMENT:' + survey_answers.survey_ans_comment
              if (survey_answers.survey_ans_comment) {
                rdata['Q' + (ii + 1) + ' - Comment'] = survey_answers.survey_ans_comment
              }
            }
          })

          return rdata
        })


        const fileName = 'Survey Report ' + moment().format('DD-MM-YYYY HH:mm:ss');
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
        const ws = XLSX.utils.json_to_sheet(excelData);

        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });

        FileSaver.saveAs(data, fileName + fileExtension);
      })
  }


  renderEventConfirmedChild = (child, index) => {
    return <div className='d-flex flex-column mb-2 border-bottom' style={{ fontSize: 12 }}>
      <div className='d-flex flex-row'>
        <div className='me-2' style={{ width: '30px', height: '30px', borderRadius: '100%', overflow: 'hidden' }}>
          <Image src={(child.picture) ? portalURL + child.center_id + '/children/' + child.picture : require('../../assets/images/noimage.png')} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
        </div>
        <div className='me-1' style={{ fontWeight: '600' }}>
          <div>{child.child_name}</div>
          <div style={{ opacity: 0.8 }}>{child.parent_name}</div>
        </div>
      </div>
      <div className='d-flex flex-row align-items-center'>
        <FontAwesomeIcon icon={faPhone} className='me-1' />
        {child.phone1}
      </div>
      <div className='d-flex flex-row align-items-center'>
        <FontAwesomeIcon icon={faAt} className='me-1' />
        {child.email1}
      </div>
      {(child.utt_confirmed) &&
        <div>
          <strong>@ </strong> {child.utt_confirmed}
        </div>
      }
      {(child.utt_confirmed_comment) &&
        <div>
          <strong>{t('Comment')}: </strong> {child.utt_confirmed_comment}
        </div>
      }
    </div>
  }
  renderEventConfirmed = (tagged) => {
    return <div className='d-flex flex-row p-1'>
      <div className='d-flex flex-column' style={{ flex: 1, padding: '0 5px 0 5px' }}>
        <div style={{ fontWeight: '600', fontSize: 20, color: brand_colors[this.props.defaultTheme.theme_id].color4 }} className='mb-3 text-center'>{t('Yes')}</div>
        {tagged.map((child, index) => {
          if (child.utt_confirmed_condition == 'Yes') {
            return this.renderEventConfirmedChild(child, index)
          }
        })}
      </div>
      <div className='d-flex flex-column' style={{ flex: 1, padding: '0 5px 0 5px' }}>
        <div style={{ fontWeight: '600', fontSize: 20, color: brand_colors[this.props.defaultTheme.theme_id].color11 }} className='mb-3 text-center'>{t('No')}</div>
        {tagged.map((child, index) => {
          if (child.utt_confirmed_condition == 'No') {
            return this.renderEventConfirmedChild(child, index)
          }
        })}
      </div>
      {(this.props.data.ut_type == 'Event') &&
        <div className='d-flex flex-column' style={{ flex: 1, padding: '0 5px 0 5px' }}>
          <div style={{ fontWeight: '600', fontSize: 20, color: brand_colors[this.props.defaultTheme.theme_id].color16 }} className='mb-3 text-center'>{t('Maybe')}</div>
          {tagged.map((child, index) => {
            if (child.utt_confirmed_condition == 'Maybe') {
              return this.renderEventConfirmedChild(child, index)
            }
          })}
        </div>
      }
      <div className='d-flex flex-column' style={{ flex: 1, padding: '0 5px 0 5px' }}>
        <div style={{ fontWeight: '600', fontSize: 20, color: brand_colors[this.props.defaultTheme.theme_id].color18 }} className='mb-3 text-center'>{t('Pending')}</div>
        {tagged.map((child, index) => {
          if (!child.utt_confirmed) {
            return this.renderEventConfirmedChild(child, index)
          }
        })}
      </div>
    </div>
  }


  getPollSurveyResults = (ut_id) => {
    if (this.getPollSurveyResultsReq) {
      this.getPollSurveyResultsReq.abort()
    }

    this.getPollSurveyResultsReq = new AbortController()
    GET('poll-survey-results/get-results', {
      params: {
        ut_id: ut_id,
      },
      signal: this.getPollSurveyResultsReq.signal,
    })
      .then((res) => {


        if (res.data.ut_type == 'Poll') {
          let poll_options = res.data.poll_options
          let poll_option_selected = res.data.poll_option_selected
          let labels = []
          let data = []
          let colors = []
          let poll_options_new = []
          poll_options.map((vv, ii) => {
            let color = generateColor(5)
            labels.push(vv.poll_opt_name)
            colors.push(color)
            vv['color'] = color
            poll_options_new.push(vv)
            let count = 0
            poll_option_selected.map((v, i) => {
              if (vv.poll_opt_id == v.poll_opt_id) {
                count++
              }
            })
            data.push(count)
          })

          this.setState({
            resultsModal: {
              ...this.state.resultsModal, data: {
                ...res.data, poll_options: poll_options_new,
                graphData: {
                  labels,
                  data,
                  colors
                }
              }
            }
          })
        }
        else if (res.data.ut_type == 'Survey') {
          let graphData = []
          let survey_questions = res.data.survey_questions
          let total_tagged = parseInt(res.data.total_user_timeline_tagged_users) + parseInt(res.data.total_user_timeline_tagged)

          survey_questions.map((v, i) => {
            let survey_answers = v.survey_answers
            let survey_question_options = v.survey_question_options
            let total_tagged_answered = survey_answers.length

            if (v.eqtype_id == 'MCQ' || v.eqtype_id == 'MRQ' || v.eqtype_id == 'TF' || v.eqtype_id == 'SCALE' || v.eqtype_id == 'RATING') {
              let total_tagged_answered = survey_answers.length
              let chart_labels = []
              let chart_data = []
              let chart_colors = []
              survey_question_options.map((vv, ii) => {
                //survey_q_opt_id
                chart_labels.push(vv.survey_q_opt_desc)
                let selected = survey_answers.filter((vvv, iii) => {
                  if (JSON.parse(vvv.survey_q_opt_ids).includes(String(vv.survey_q_opt_id)) || JSON.parse(vvv.survey_q_opt_ids).includes((vv.survey_q_opt_id))) {
                    return true
                  }
                  return false
                })
                chart_data.push({ x: vv.survey_q_opt_desc, y: selected.length })

                let percent_answered = ((selected.length * 100) / total_tagged_answered).toFixed(2)
                let this_color = generateColor(6)
                chart_colors.push(this_color)
              })
              graphData.push({
                survey_q_id: v.survey_q_id,
                survey_q_description: v.survey_q_description,
                eqtype_id: v.eqtype_id,
                survey_q_comment_allow: v.survey_q_comment_allow,
                survey_q_comment_label: v.survey_q_comment_label,
                total_tagged_answered: total_tagged_answered,
                total_tagged: total_tagged,
                total_answered_percent: (total_tagged > 0) ? ((total_tagged_answered * 100) / total_tagged).toFixed(2) : 0,
                chart_labels,
                chart_data,
                chart_colors
              })
            }
            else if (v.eqtype_id == 'COMMENTS') {
              graphData.push({
                survey_q_id: v.survey_q_id,
                survey_q_description: v.survey_q_description,
                eqtype_id: v.eqtype_id,
                survey_q_comment_allow: v.survey_q_comment_allow,
                survey_q_comment_label: v.survey_q_comment_label,
                total_tagged_answered: total_tagged_answered,
                total_tagged: total_tagged,
                total_answered_percent: (total_tagged > 0) ? ((total_tagged_answered * 100) / total_tagged).toFixed(2) : 0,
                survey_answers,
                survey_question_options
              })
            }
            else if (v.eqtype_id == 'MATRIX') {
              let survey_question_options = v.survey_question_options
              let survey_q_answers = JSON.parse(v.survey_q_answers)
              let chart_data = []
              survey_question_options.map((vv, ii) => {
                let survey_q_answers_percent = []
                survey_q_answers.map(($value3, $key3) => {
                  let total_tagged_answered2 = 0
                  survey_answers.map(($value4, $key4) => {

                    if ($value4['survey_q_id'] == v['survey_q_id']) {
                      let survey_q_opt_ids = JSON.parse($value4['survey_q_opt_ids'])
                      survey_q_opt_ids.map(($value5, $key5) => {
                        if ($value5['survey_q_opt_id'] == vv['survey_q_opt_id'] && $value5['survey_q_answer'] == $value3) {
                          total_tagged_answered2++
                        }
                      })
                    }
                  })
                  survey_q_answers_percent.push({
                    'x': $value3,
                    'y': total_tagged_answered2
                  })
                })
                chart_data.push({
                  label: vv.survey_q_opt_desc,
                  data: survey_q_answers_percent,
                })
              })

              graphData.push({
                survey_q_id: v.survey_q_id,
                survey_q_description: v.survey_q_description,
                eqtype_id: v.eqtype_id,
                survey_q_comment_allow: v.survey_q_comment_allow,
                survey_q_comment_label: v.survey_q_comment_label,
                total_tagged_answered: total_tagged_answered,
                total_tagged: total_tagged,
                total_answered_percent: (total_tagged > 0) ? ((total_tagged_answered * 100) / total_tagged).toFixed(2) : 0,
                chart_data
              })
            }
          })

          this.setState({
            resultsModal: {
              ...this.state.resultsModal, data: {
                ...res.data,
                graphData
              }
            }
          }, () => {

          })
        }

      })
      .catch((err) => {
        if (axios.isCancel(err)) {

        } else {
          toast.error(
            'Something went wrong while fetching chats and contacts!'
          )

        }
      })
  }


  renderDescription = (data) => {
    if (data.length > 100 && this.state.readMore != true) {
      return <>
        <div dangerouslySetInnerHTML={{ __html: data.slice(0, 100) }}></div>
        <Badge bg="dark" className='cursor-pointer' onClick={() => this.setState({ readMore: true })}>Read More...</Badge>
      </>
    } else {
      return <>
        <div dangerouslySetInnerHTML={{ __html: data }}></div>
        {data.length > 100 && <Badge bg="dark" className='cursor-pointer' onClick={() => this.setState({ readMore: false })}>Read Less...</Badge>}
      </>
    }
  }

  deleteUserTimelineMedia = (v, i) => {
    let user_timeline_media = this.state.user_timeline_media
    if (v.utm_id) {
      this.setState({
        deleteModal: {
          ...this.state.deleteModal, show: true, action: () => {
            // DELETE FILE CALL 
            try {
              GET('user-timeline/delete-user-timeline-media', {
                params: {
                  key: v.utm_id,
                  field: 'utm_name',
                },
              }).then((res) => {
                if (res) {
                  user_timeline_media.splice(i, 1)
                  this.setState({
                    user_timeline_media, deleteModal: {
                      ...this.state.deleteModal, show: false
                    },
                  });
                } else {
                  toast.error(t('Something went wrong while deleting!'));
                }
              })
            } catch (err) {
              console.log('err', err)
              toast.error(t('Something went wrong while deleting!'));

            }
          }
        }
      })
    }
  }

  toggleApproveUserTimelineMedia = (approveAll = false, publishNow = false) => {
    let user_timeline_media = this.state.user_timeline_media
    if (approveAll) {
      user_timeline_media = user_timeline_media.map((v, i) => {
        return { ...v, utm_approve: 1 }
      })
    }
    this.props.toggleApproveUserTimelineMedia(this.props.data.ut_id, user_timeline_media, approveAll, publishNow, (res) => {
      if (res.type) {
        if (approveAll) {
          this.setState({ user_timeline_media: user_timeline_media })
        }
      }
    })
  }

  /**
   * Render Component
   * @returns {HTMLElement}
   */
  render() {
    console.log('TIMELINECARDsthis.state', this.state, this.props)
    // State
    const { isViewerOpen, currentImageViewerIndex, images, likesModal, commentsModal, seenModal, comment, commentSending, attachment, comments, utc_root_id, modalDailyReport, eventModal, approveMediaModal, user_timeline_media } = this.state

    // Props
    const { data, authData, dir, children, showClass, allowEdit, defaultTheme } = this.props

    //  Video
    let video_url
    if (data.ut_type == 'Video') {
      if (user_timeline_media.length > 0 && user_timeline_media[0].utm_url !== null) {
        video_url = user_timeline_media[0].utm_url.replace(
          'videos',
          'video'
        )
      }
    }


    return (
      <Card className='mb-3 border-0 timeline-card'>
        <Card.Body>
          <Row>
            <Col>
              <div className='card-type'>
                <div className='card-icon'>
                  <Image src={this.defaultTimelineTypes[data.ut_type]?.picture} style={{ width: '100%', height: '100%' }} />
                </div>
                <div>
                  <div>{t(this.defaultTimelineTypes[data.ut_type]?.name)}</div>
                  <div className="badge badge-light-info mb-1" style={{ whiteSpace: 'pre-wrap' }}>{data.center_name}</div>
                  {showClass &&
                    <div className='card-share' style={{ position: 'relative' }}>
                      <span style={{ marginRight: 5, fontSize: 10, fontWeight: 'bold' }} className='cursor-pointer badge badge-light-success' onClick={this.state.utClassesModal.toggleModal}>
                        {t('Tagged Classes') + ' : ' + data.classes.length}
                      </span>
                      {this.state.utClassesModal.show &&
                        <div className='btn_user_timeline' style={{ position: 'absolute', zIndex: 1, top: 0, left: 0, width: '200px' }} onClick={this.state.utClassesModal.toggleModal}>
                          <div className='d-flex flex-row align-items-center cursor-pointer flex-wrap'>
                            {data.classes.map((v, i) => {
                              return <span className='badge badge-light-success' key={i} style={{ margin: '0 2px 2px 2px', fontSize: 10 }}>{v.class_theme}</span>
                            })}
                          </div>
                        </div>
                      }
                    </div>
                  }
                </div>

              </div>
            </Col>
            <div className='d-flex justify-content-end align-items-start' style={{ width: '171px' }}>
              {/* <a href='#' className='card-share'>
                                <FontAwesomeIcon icon={faShareAlt} /> Share
                            </a> */}
              <div>
                {(data.ut_publish_status == 1)
                  ? <div className='card-share' title={'Published @ ' + moment(data.updated_on).format('DD-MM-YYYY HH:mm:ss')}>
                    <span style={{ marginLeft: 5, marginRight: 5 }}><FontAwesomeIcon icon={faClock} color={brand_colors[this.props.defaultTheme.theme_id].color4} /></span>
                    {moment(data.updated_on).from(moment(data.current_datetime))}
                  </div>
                  : <div className='card-share' title={(data.ut_type == 'Assessment' || data.ut_type == 'CheckList' || data.ut_type == 'Daily Report') ? 'Not Shared' : (data.ut_publish_on) ? 'Published @ ' + moment(data.ut_publish_on).format('DD-MM-YYYY HH:mm:ss') : 'Not Published'}>
                    <span style={{ marginLeft: 5, marginRight: 5 }}><FontAwesomeIcon icon={faClock} color={brand_colors[this.props.defaultTheme.theme_id].color11} /></span>
                    {(data.ut_type == 'Assessment' || data.ut_type == 'Daily Report' || data.ut_type == 'CheckList') ? 'Not Shared' : (data.ut_publish_on) ? moment(data.ut_publish_on).from(moment(data.current_datetime)) : 'Not Published'}
                  </div>
                }
                <div className='card-share' style={{ position: 'relative' }}>
                  <span style={{ marginLeft: 5, marginRight: 5 }} className='cursor-pointer' onClick={this.state.utInfoModal.toggleModal}>
                    <FontAwesomeIcon className='me-2' icon={faUser} color={(data.created_by != this.props.authData.loginData.user_id) ? brand_colors[this.props.defaultTheme.theme_id].color16 : brand_colors[this.props.defaultTheme.theme_id].color4} />
                    {data.created_by_name}
                  </span>
                  {this.state.utInfoModal.show &&
                    <div className='btn_user_timeline' style={{ position: 'absolute', zIndex: 1, top: 0, right: '17px', width: '200px' }} onClick={this.state.utInfoModal.toggleModal}>
                      <div className='d-flex flex-row align-items-center'>
                        <div className='me-2' style={{ fontWeight: '600' }}>{t('Created by:')}</div>
                        <div>{data.created_by_name}</div>
                      </div>
                      <div className='d-flex flex-row align-items-center'>
                        <div className='me-2' style={{ fontWeight: '600' }}>{t('Created on:')}</div>
                        <div>{moment(data.created_on).format('DD-MM-YYYY HH:mm:ss')}</div>
                      </div>
                      {data.updated_by_name &&
                        <div className='d-flex flex-row align-items-center'>
                          <div className='me-2' style={{ fontWeight: '600' }}>{t('Updated by:')}</div>
                          <div>{data.updated_by_name}</div>
                        </div>
                      }
                      {data.updated_on &&
                        <div className='d-flex flex-row align-items-center'>
                          <div className='me-2' style={{ fontWeight: '600' }}>{t('Updated on:')}</div>
                          <div>{moment(data.updated_on).format('DD-MM-YYYY HH:mm:ss')}</div>
                        </div>
                      }
                    </div>
                  }
                </div>
                {(parseInt(data.ut_pintotop) == 1) &&
                  <div className='card-share' title={t('This post is pinned to top, It appears on top for parents')}>
                    <span style={{ marginLeft: 5, marginRight: 5 }}><FontAwesomeIcon icon={faThumbTack} color={brand_colors[this.props.defaultTheme.theme_id].color4} /></span>
                    {t('Pinned')}
                  </div>
                }

              </div>


              {/* EDIT BTNS */}
              {allowEdit && ([1, 8, 12, 22].includes(this.props.authData.loginData.role_id) || (data.created_by == this.props.authData.loginData.user_id)) && <div className='cursor-pointer' style={{ paddingLeft: 10, paddingRight: 5, position: 'relative' }}>
                <Button variant='outline-secondary' size="sm" onClick={this.state.editBtnModal.toggleModal}>
                  <FontAwesomeIcon icon={faEllipsisV} />
                </Button>
                {this.state.editBtnModal.show &&
                  <div className='btn_user_timeline' style={{ position: 'absolute', zIndex: 1, top: 0, right: '17px' }}>
                    <ButtonGroup size="sm">
                      {!['Daily Report'].includes(data.ut_type) &&
                        <Button variant='outline-warning' title={t('Edit')} onClick={() => {
                          this.props.editUserTimeline(data.ut_id)
                          this.state.editBtnModal.toggleModal()
                        }}>
                          <FontAwesomeIcon icon={faEdit} />
                        </Button>
                      }
                      <Button variant='outline-danger' title={t('Delete')} onClick={() => {
                        this.props.deleteUserTimeline(data)
                        this.state.editBtnModal.toggleModal()
                      }}>
                        <FontAwesomeIcon icon={faTrash} />
                      </Button>
                      <Button variant='outline-success' title={t('Toggle Pin to Top')} onClick={() => {
                        this.props.togglePinToTop(data)
                        this.state.editBtnModal.toggleModal()
                      }}>
                        <FontAwesomeIcon icon={faThumbTack} />
                      </Button>
                      <Button variant='outline-success' title={t('Approve Media')} onClick={() => {
                        approveMediaModal.toggleModal()
                      }}>
                        <GetIcon icon={'PiImagesSquareBold'} style={{ fontSize: 20 }} />
                      </Button>
                    </ButtonGroup>
                  </div>
                }
              </div>}
            </div>
          </Row>
          {(data.ut_type === 'Assessment' || data.ut_type === 'CheckList') && data.user_timeline_tagged.length > 0 &&
            <Row style={{ margin: '5px 0' }}>
              <Col className='d-flex justify-content-center align-items-center flex-row'>
                <div className='tagged-child-pic' style={{
                  borderWidth: 2, borderColor: brand_colors[this.props.defaultTheme.theme_id].color1, borderStyle: 'solid', width: 50, height: 50, backgroundPosition: 'center center', backgroundSize: 'cover', borderRadius: 25,
                  backgroundImage:
                    (data.user_timeline_tagged.length > 0 && data.user_timeline_tagged[0].picture &&
                      data.user_timeline_tagged[0].picture !== null)
                      ? 'url(' + portalURL + data.user_timeline_tagged[0].center_id + '/children/' + data.user_timeline_tagged[0].picture + ')'
                      : 'url(https://via.placeholder.com/80?text=Student)',

                }}></div>
                <span style={{ color: brand_colors[this.props.defaultTheme.theme_id].color9, fontSize: 14, fontWeight: 'bold', margin: '0 10px', opacity: 0.7 }}>{data.user_timeline_tagged[0].child_name}</span>
              </Col>
            </Row>
          }

          {((data.ut_type !== 'Media') &&
            ((data.ut_attachment_1) || (data.ut_attachment_2))) && (
              <Row className='my-2'>
                {((data.ut_attachment_1) &&
                  !['pdf', 'wav', 'mpeg', 'm4a', 'mp3', 'ogg'].includes((data.ut_attachment_1.split('.').pop()).toLowerCase())) &&
                  <Col className='d-flex justify-content-center' onClick={() =>
                    this.openSingleImageViewer(
                      0,
                      (data.ut_folder) ? portalURL + data.center_id + '/' + data.ut_folder + '/' + data.ut_attachment_1 : portalURL + data.center_id + '/user_timeline/' + data.ut_attachment_1
                    )
                  }>
                    <Image
                      // thumbnail={true}
                      className='cursor-pointer img-fit-thumb'
                      src={(data.ut_folder) ? portalURL + data.center_id + '/' + data.ut_folder + '/' + data.ut_attachment_1 : portalURL + data.center_id + '/user_timeline/' + data.ut_attachment_1}
                    />
                  </Col>
                }
                {((data.ut_attachment_2) &&
                  !['pdf', 'wav', 'mpeg', 'm4a', 'mp3', 'ogg'].includes((data.ut_attachment_2.split('.').pop()).toLowerCase())) &&
                  <Col className='d-flex justify-content-center' onClick={() =>
                    this.openSingleImageViewer(
                      0,
                      (data.ut_folder) ? portalURL + data.center_id + '/' + data.ut_folder + '/' + data.ut_attachment_2 : portalURL + data.center_id + '/user_timeline/' + data.ut_attachment_2
                    )
                  }>
                    <Image
                      // thumbnail={true}
                      className='cursor-pointer img-fit-thumb'
                      src={(data.ut_folder) ? portalURL + data.center_id + '/' + data.ut_folder + '/' + data.ut_attachment_2 : portalURL + data.center_id + '/user_timeline/' + data.ut_attachment_2}
                    />
                  </Col>
                }

              </Row>
            )}
          {(data.ut_type == 'Media' || data.ut_type == 'Assessment' || data.ut_type == 'Engage' || data.ut_type == 'CheckList') &&
            (user_timeline_media.length > 0) && (
              <Row>
                <Col className='my-2 d-flex justify-content-center'>
                  <Row>
                    <Col
                      className='lb-thumb'
                      onClick={() =>
                        this.openImageViewer(
                          0,
                          data.center_id,
                          user_timeline_media,
                          'user_timeline_media',
                          'utm_name'
                        )
                      }>
                      <Image
                        // thumbnail={true}
                        className='img-fit-thumb'
                        src={
                          (user_timeline_media[0].utm_url)
                            ? user_timeline_media[0].utm_url
                            : (user_timeline_media[0].utm_folder)
                              ? portalURL + data.center_id + '/' + user_timeline_media[0].utm_folder + '/' + user_timeline_media[0].utm_name
                              : portalURL + data.center_id + '/user_timeline_media/' + user_timeline_media[0].utm_name
                        }
                      />
                    </Col>
                    {user_timeline_media.length >
                      1 && (
                        <Col
                          className='ps-1 position-relative lb-thumb'
                          onClick={() =>
                            this.openImageViewer(
                              1,
                              data.center_id,
                              user_timeline_media,
                              'user_timeline_media',
                              'utm_name'
                            )
                          }>
                          <Image
                            // thumbnail={true}
                            className='img-fit-thumb'
                            src={
                              (user_timeline_media[1].utm_url)
                                ? user_timeline_media[1].utm_url
                                : (user_timeline_media[1].utm_folder)
                                  ? portalURL + data.center_id + '/' + user_timeline_media[1].utm_folder + '/' + user_timeline_media[1].utm_name
                                  : portalURL + data.center_id + '/user_timeline_media/' + user_timeline_media[1].utm_name
                            }
                          />
                          {user_timeline_media
                            .length > 2 && (
                              <div className='more-images'>
                                {data
                                  .user_timeline_media
                                  .length - 2}
                                +
                              </div>
                            )}
                        </Col>
                      )}
                  </Row>

                </Col>
              </Row>
            )}
          {data.ut_type == 'Video' && (
            <Row>
              <Col className='my-2 p-0' style={{ backgroundColor: brand_colors[this.props.defaultTheme.theme_id].color19 }}>
                <ReactPlayer
                  url={(user_timeline_media.length > 0 && user_timeline_media[0].utm_folder == 'educorepublic') ? user_timeline_media[0].utm_url : `https://vimeo.com${video_url}`}
                  controls={true}
                  width='100%'
                // height='auto'
                />
              </Col>
            </Row>
          )}

          <Row>
            <Col className='card-title'>{data.ut_title}</Col>
          </Row>
          <Row>
            <Col className='card-desc'>

              {data.ut_description && <div className='mb-1'>{this.renderDescription(data.ut_description)}</div>}

              {data.ut_type == 'Daily Report' && this.renderDailyReport(data)}

              {data.fees &&
                <p className='mb-1'>
                  <FontAwesomeIcon
                    icon={faMoneyBill}
                    className='me-1'
                    color={brand_colors[this.props.defaultTheme.theme_id].color4}
                    title={t("Fees")}
                  />
                  {data.fees}
                </p>
              }
              {(data.start_datetime && data.ut_type != 'Poll' && data.ut_type != 'Survey') &&
                <p className='mb-1'>
                  <FontAwesomeIcon
                    icon={faCalendarAlt}
                    className='me-1'
                    color={brand_colors[this.props.defaultTheme.theme_id].color4}
                    title={t("Start Date/Time")}
                  />
                  {moment(
                    data.start_datetime,
                    'YYYY-MM-DD HH:mm:ss '
                  ).format('dddd, MMMM Do YYYY HH:mm')}
                </p>
              }
              {(data.end_datetime && data.ut_type != 'Poll' && data.ut_type != 'Survey') &&
                <p className='mb-1'>
                  <FontAwesomeIcon
                    icon={faCalendarAlt}
                    className='me-1'
                    color={brand_colors[this.props.defaultTheme.theme_id].color11}
                    title={t("End Date/Time")}
                  />
                  {moment(
                    data.end_datetime,
                    'YYYY-MM-DD HH:mm:ss '
                  ).format('dddd, MMMM Do YYYY HH:mm')}
                </p>
              }

              {data.ut_url && (
                <p className='mb-1'>
                  <FontAwesomeIcon
                    icon={faArrowUpRightFromSquare}
                    className='me-1'
                    color={brand_colors[this.props.defaultTheme.theme_id].color4}
                  />
                  <a
                    rel='noreferrer'
                    href={data.ut_url}
                    target='_blank'>
                    {data.ut_url}
                  </a>
                </p>
              )}
              {/* {data.classmaterial &&
                <p className='mb-1'>
                  <FontAwesomeIcon
                    icon={faBook}
                    className='me-1'
                    color={brand_colors[this.props.defaultTheme.theme_id].color4}
                  />{' '}
                  {data.classmaterial.classmaterial_name}
                </p>
              } */}
              {data.ut_attachment_1 !== null && (data.ut_attachment_1.split('.').pop()).toLowerCase() === 'pdf' && (
                <p className='mb-1'>
                  <FontAwesomeIcon
                    icon={faLink}
                    className='me-1'
                    color={brand_colors[this.props.defaultTheme.theme_id].color4}
                  />
                  <a
                    rel='noreferrer'
                    href={(data.ut_folder) ? portalURL + data.center_id + '/' + data.ut_folder + '/' + data.ut_attachment_1 : portalURL + data.center_id + '/user_timeline/' + data.ut_attachment_1}
                    target='_blank'>
                    {t('Open PDF')}
                  </a>
                </p>
              )}
              {data.ut_attachment_2 !== null && (data.ut_attachment_2.split('.').pop()).toLowerCase() === 'pdf' && (
                <p className='mb-1'>
                  <FontAwesomeIcon
                    icon={faLink}
                    className='me-1'
                    color={brand_colors[this.props.defaultTheme.theme_id].color4}
                  />
                  <a
                    rel='noreferrer'
                    href={(data.ut_folder) ? portalURL + data.center_id + '/' + data.ut_folder + '/' + data.ut_attachment_2 : portalURL + data.center_id + '/user_timeline/' + data.ut_attachment_2}
                    target='_blank'>
                    {t('Open PDF')}
                  </a>
                </p>
              )}

              {(data.ut_type == 'Academic Activity') && <>
                <p className='mb-1'>
                  <strong>{t('Activity')}:</strong>{' '}
                  {data.academic_activities?.aa_name}
                </p>
              </>
              }
            </Col>
          </Row>
          {(data.ut_type == 'Exam' || data.ut_type == 'Online Homework') && (
            <Row>
              <Col className='mb-3 exam-details'>

                <p className='mb-1' title={t('Type')}>
                  <FontAwesomeIcon
                    icon={faTh}
                    className='me-1'
                  />{' '}
                  {data.exams.examstype_name} ({data.exams.examstypegrp_name})
                </p>
                {data.exams.eqdifficulty_name &&
                  <p className='mb-1' title={t('Difficulty')}>
                    <FontAwesomeIcon
                      icon={faTachometer}
                      className='me-1'
                    />{' '}
                    <span
                      style={{
                        color: data.exams
                          .eqdifficulty_color,
                      }}>
                      {data.exams.eqdifficulty_name}
                    </span>
                  </p>
                }
                {(data.exams.total_questions && data.exams.total_questions != 0) &&
                  <p className='mb-1' title={t('Total Questions')}>
                    <FontAwesomeIcon
                      icon={faQuestionCircle}
                      className='me-1'
                    />{' '}
                    {data.exams.total_questions}
                  </p>
                }
                <p className='mb-1' title={t('Date')}>
                  <FontAwesomeIcon
                    icon={faCalendar}
                    className='me-1'
                  />{' '}
                  {moment(
                    data.exams.exams_date,
                    'YYYY-MM-DD'
                  ).format('dddd, MMMM Do YYYY')}
                </p>
                <p className='mb-1' title={t('Total Grade')}>
                  <FontAwesomeIcon
                    icon={faAward}
                    className='me-1'
                  />{' '}
                  {data.exams.exams_grade} ({t('Total Grade')}
                  )
                </p>
              </Col>
            </Row>
          )}

          {data.ut_type == 'Incident Report' && (
            <Row>
              <Col className='mb-3 exam-details'>
                {data.nursereport?.taken_to_hospital == 1 &&
                  <p className='mb-1'>
                    <strong>{t('Taken To Hospital')}: </strong>
                    {data.nursereport?.taken_to_hospital_name || t('Yes')}
                  </p>
                }
                {data.nursereport?.taken_to_hospital == 1 &&
                  <p className='mb-1'>
                    <strong>{t('Taken')}: </strong>
                    {data.nursereport.taken_immediately == 0
                      ? (data.nursereport.taken_later_date) ? moment(data.nursereport.taken_later_date, 'YYYY-MM-DD').format('dddd, MMMM Do YYYY') : t('Not Immediately')
                      : t('Immediately')}
                  </p>
                }
                {data.description2 &&
                  <p className='mb-1'>
                    <strong>{t('Follow-Up')}: </strong>
                    {data.description2}
                  </p>
                }
              </Col>
            </Row>
          )}
          {data.ut_type == 'Medication Consent' && (
            <Row>
              <Col className='mb-3 exam-details'>
                <p className='mb-1'>
                  {(data.user_timeline_tagged[0].utt_confirmed_condition == 'Yes') && <strong>{t('Consent given')}: </strong>}
                  {(data.user_timeline_tagged[0].utt_confirmed_condition == 'No') && <strong>{t('Consent not given')}: </strong>}
                  {(data.user_timeline_tagged[0].utt_confirmed_condition == null) && <strong>{t('Consent pending')} </strong>}
                  {(data.user_timeline_tagged.length > 0 && data.user_timeline_tagged[0].utt_confirmed)
                    ? t('by parent on') + ' ' + moment(data.user_timeline_tagged[0].utt_confirmed).format('DD-MM-YYYY hh:mm A')
                    : ''
                  }
                </p>
              </Col>
            </Row>
          )}
          {data.acs_ids_data && data.acs_ids_data.length > 0 && (
            <Row>
              <Col className='mb-3 exam-details'>
                <strong style={{ opacity: 0.9 }}>{t('Subjects')}: </strong>
                {data.acs_ids_data.map((v, i) => {
                  return <div key={i} className='badge badge-light-success me-1'>{v.acs_name}</div>
                })}
              </Col>
            </Row>
          )}
          {data.ut_type == 'Poll' && (
            <Poll data={data} authData={authData} defaultTheme={defaultTheme} />
          )}
          {data.ut_type == 'Survey' && (
            <Survey data={data} authData={authData} defaultTheme={defaultTheme} />
          )}
          {((data.ut_type == 'Assessment' || data.ut_type == 'CheckList') && data.assess_aspect && this.props.authData.centerData.assesslog_share_attainments == 1) && <div style={{ margin: '5px 0 10px 0' }}>
            {data.assess_aspect.map((v, i) => {
              return <div style={{ marginHorizontal: '0 5px' }} key={i}>
                <span style={{ fontSize: 12, color: brand_colors[this.props.defaultTheme.theme_id].color9, opacity: 0.7, fontWeight: 'bold' }}>{v.learnarea_desc}</span>
                {
                  v.assess_curr.map((vv, ii) => {
                    return <div style={{ marginHorizontal: '0 5px', display: 'flex', flexDirection: 'row', marginVertical: 5 }} key={ii}>
                      <div style={{ display: 'flex', marginRight: 5, justifyContent: 'center', alignItems: 'center' }}>
                        <FontAwesomeIcon
                          icon={faDotCircle}
                          color={brand_colors[this.props.defaultTheme.theme_id].color16}
                          style={{ fontSize: 12 }}
                        />
                      </div>
                      <span style={{ fontSize: 12, color: brand_colors[this.props.defaultTheme.theme_id].color9, opacity: 0.7 }}>{vv.curriculum_code}</span>
                    </div>
                  })
                }
              </div>
            })}
          </div>}

        </Card.Body>
        <div className='card-footer'>
          <div className='d-flex justify-content-between align-items-center flex-row'>
            <div className='d-flex justify-content-center align-items-center p-0'>
              <a
                href='#'
                onClick={() => this.handleLikesModal()}>
                <FontAwesomeIcon
                  icon={faHeart}
                  className={
                    dir == 1 ? 'ms-1' : 'me-1'
                  }
                  style={{ color: ((this.countLikes(data.user_timeline_tagged)) > 0) ? '#f40000' : '' }}
                />
                {t('Likes')}
                {(this.countLikes(data.user_timeline_tagged) > 0) && (
                  <Badge
                    bg='success'
                    style={{
                      borderRadius: 20,
                      position: 'relative',
                      top: '-6px',
                      left: '2px',
                      height: 20,
                      lineHeight: '13px',
                    }}>
                    {this.countLikes(data.user_timeline_tagged)}
                  </Badge>
                )}
              </a>
            </div>
            <div className='d-flex justify-content-center align-items-center p-0'>
              <a
                href='#'
                onClick={() => {
                  this.handleCommentsModal()
                  this.setState(
                    {
                      utp: data,
                      comments: null,
                      commentsFinished: false
                    },
                    () => this.getComments()
                  )
                }}>
                <FontAwesomeIcon
                  icon={faComment}
                  className={
                    dir == 1 ? 'ms-1' : 'me-1'
                  }
                  style={{ color: (data.totalCommentsConversationNew > 0) ? brand_colors[this.props.defaultTheme.theme_id].color4 : (data.user_timeline_comments_total > 0) ? brand_colors[this.props.defaultTheme.theme_id].color1 : '#a4a4a4' }}
                />
                {t('Comments')}
                {(data.totalCommentsConversationNew > 0) && (
                  <Badge
                    bg='success'
                    style={{
                      borderRadius: 20,
                      position: 'relative',
                      top: '-6px',
                      left: '2px',
                      height: 20,
                      lineHeight: '13px',
                    }}>
                    {data.totalCommentsConversationNew}
                  </Badge>
                )}
              </a>
            </div>
            <div className='d-flex justify-content-center align-items-center p-0' >
              <a href='#' onClick={() => {
                this.handleSeenModal()
                this.setState({ utp: data })
              }}>
                <FontAwesomeIcon icon={faEye} className={dir == 1 ? 'ms-1' : 'me-1'} style={{ color: ((this.countSeen(data.user_timeline_tagged)) > 0) ? brand_colors[this.props.defaultTheme.theme_id].color4 : '' }} />
                {t('Seen')}{' '}
                <Badge bg='success' style={{ borderRadius: 20, position: 'relative', top: '-6px', left: '-1px', height: 20, lineHeight: '13px' }}>
                  {this.countSeen(data.user_timeline_tagged)} / {data.user_timeline_tagged.length}
                </Badge>
              </a>
            </div>
            {(data.ut_type == 'Event' || data.ut_type == 'Registration') &&
              <div className='d-flex justify-content-center align-items-center p-0' >
                <a href='#' onClick={() => {
                  this.setState({ utp: data, eventModal: true })
                }}>
                  <FontAwesomeIcon icon={faClipboardCheck} className={dir == 1 ? 'ms-1' : 'me-1'} style={{ color: ((this.eventConfirmed(data.user_timeline_tagged)) > 0) ? brand_colors[this.props.defaultTheme.theme_id].color4 : '' }} />
                  {t('Confirmed')}{' '}
                  <Badge bg='success' style={{ borderRadius: 20, position: 'relative', top: '-6px', left: '-1px', height: 20, lineHeight: '13px' }}>
                    {this.eventConfirmed(data.user_timeline_tagged)} / {data.user_timeline_tagged.length}
                  </Badge>
                </a>
              </div>
            }


            {['Daily Report'].includes(data.ut_type) &&
              <div className='d-flex justify-content-center align-items-center p-0' >
                <a href='#' onClick={() => {
                  modalDailyReport.toggle('Daily Report', data)
                }}>
                  <FontAwesomeIcon icon={faEye} className={dir == 1 ? 'ms-1' : 'me-1'} style={{ color: brand_colors[this.props.defaultTheme.theme_id].color4 }} />
                  {t('Preview')}{' '}
                </a>
              </div>
            }
            {/* RESULTS CAN BE SEEN BY WHOMEVER CREATED AND SPECIFIC ROLES */}
            {['Survey', 'Poll'].includes(data.ut_type) && (data.created_by == authData.loginData.user_id || [1, 8, 12, 22].includes(authData.loginData.role_id)) &&
              <div className='d-flex justify-content-center align-items-center' style={{ padding: '0 5px' }}>
                <a href='#' onClick={() => {
                  this.state.resultsModal.toggleModal(data.ut_id)
                }}>
                  <FontAwesomeIcon
                    icon={faChartArea}
                    className={
                      dir == 1 ? 'ms-1' : 'me-1'
                    }
                    style={{ color: brand_colors[this.props.defaultTheme.theme_id].color4 }}
                  />
                  {t('Results')}
                </a>
              </div>
            }

            {['Academic Activity'].includes(data.ut_type) && (data.created_by == authData.loginData.user_id || [].includes(authData.loginData.role_id)) &&
              <div className='d-flex justify-content-center align-items-center' style={{ padding: '0 5px' }}>
                <a href={appURL + '/assigned-activity?id=' + data.ut_id} target="_blank">
                  <FontAwesomeIcon
                    icon={faChartArea}
                    className={
                      dir == 1 ? 'ms-1' : 'me-1'
                    }
                    style={{ color: brand_colors[this.props.defaultTheme.theme_id].color4 }}
                  />
                  {t('Results')}
                </a>
              </div>
            }
          </div>
        </div>

        {/* LIKES MODAL */}
        <Modal
          size='md'
          show={likesModal}
          onHide={() => this.handleLikesModal()}>
          <Modal.Header closeButton>
            <Modal.Title>{t('Likes')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='tagged-children'>
              {likesModal && this.renderLikes(data.user_timeline_tagged)}
            </div>
          </Modal.Body>
        </Modal>


        {/* COMMENTS MODAL */}
        <Modal
          size='md'
          show={commentsModal}
          onHide={() => this.handleCommentsModal()}>
          <Modal.Header closeButton>
            {(utc_root_id)
              ? <span
                style={{
                  marginRight: '5px',
                  fontSize: '1.5rem',
                  cursor: 'pointer'
                }}
                onClick={() => this.closeConversation()}>
                <FontAwesomeIcon
                  icon={
                    dir == 1
                      ? faArrowAltCircleRight
                      : faArrowAltCircleLeft
                  }
                  color={brand_colors[this.props.defaultTheme.theme_id].color9}
                />
              </span>
              : null
            }
            <Modal.Title>{t('Comments')}</Modal.Title>
          </Modal.Header>
          {commentsModal &&
            <Modal.Body>
              {comments == null ? (
                <div className='d-flex flex-column py-4 align-items-center'>
                  <span className='mb-1'>
                    <Spinner animation='grow' variant='dark' />
                  </span>
                  {t('Fetching comments...')}
                </div>
              ) : (
                <>
                  {comments.length > 0 ? (
                    <>
                      {(this.state.commentsFinished)
                        ? <div className='d-flex flex-column py-4 align-items-center'>
                          <span style={{ color: brand_colors[this.props.defaultTheme.theme_id].color10 }}>{(utc_root_id) ? t('Start of conversation') : t('No more comments')}</span>
                        </div>
                        : <div className='d-flex flex-column py-4 align-items-center'>
                          <span style={{ cursor: 'pointer' }} onClick={() => this.getComments()}>{t('Load more comments')}</span>
                        </div>
                      }

                      {comments.map((item, index) => (
                        <CommentItem
                          key={index}
                          data={item}
                          utc_root_id={utc_root_id}
                          openConversation={this.openConversation}
                          closeConversation={this.closeConversation}
                          index={index}
                          count={comments.length - 1}
                          commentDelAlert={this.state.commentDelAlert}
                          commentDelID={this.state.commentDelID}
                          confirmDeleteComment={this.confirmDeleteComment}
                          cancelDeleteComment={this.cancelDeleteComment}
                        />
                      ))}
                    </>
                  ) : (
                    <div className='d-flex flex-column py-5 align-items-center'>
                      {t('No comments yet!')}
                    </div>
                  )}
                </>
              )}
            </Modal.Body>
          }
          <Modal.Footer>
            <div style={{ gap: 10, display: 'flex', flex: 1, }}>
              <input
                type='file'
                ref={this.commentAttach}
                style={{ display: 'none' }}
                id='comment-attach'
                onChange={() => this.uploadFile()}
              />
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}>
                {attachment !== null ? (
                  <button
                    style={{
                      fontSize: 20,
                      border: 0,
                      backgroundColor: 'transparent',
                    }}
                    onClick={() =>
                      this.setState({
                        attachment: null,
                      })
                    }>
                    <FontAwesomeIcon
                      icon={faTimesCircle}
                      color='rgb(220, 53, 69)'
                    />
                  </button>
                ) : (
                  <button
                    style={{
                      fontSize: 20,
                      border: 0,
                      backgroundColor: 'transparent',
                    }}
                    onClick={this.setCommentAttach}>
                    <FontAwesomeIcon icon={faPaperclip} />
                  </button>
                )}
              </div>
              <div style={{ flex: 1 }}>
                <Form.Control
                  type='text'
                  placeholder={t('Type your comment here...')}
                  onChange={(e) =>
                    this.setState({
                      comment: e.target.value,
                    })
                  }
                  value={comment}
                />
              </div>
              <div>
                <Button
                  disabled={commentSending}
                  onClick={() => this.sendComment()}>
                  {t('SEND')}
                </Button>
              </div>
            </div>
          </Modal.Footer>
          <SweetAlert
            show={this.state.commentDelAlert}
            warning
            showCancel
            confirmBtnText={t('Yes, delete it!')}
            confirmBtnBsStyle='danger'
            title={t('Are you sure?')}
            onConfirm={this.deleteComment}
            onCancel={this.cancelDeleteComment}
            focusCancelBtn>
            {t('You will not be able to recover this comment!')}
          </SweetAlert>
        </Modal>

        {/* DAILY REPORT MODAL */}
        <Modal size='xl' show={modalDailyReport.show} onHide={() => modalDailyReport.toggle()}>
          <Modal.Header closeButton>
            <Modal.Title>{t(modalDailyReport.title)}</Modal.Title>
          </Modal.Header>
          {modalDailyReport.show &&
            <Modal.Body>
              <div className='d-flex flex-row'>
                <div style={{ flex: 1 }} >
                  {/* Hygiene: trueMedicine: trueMilk: trueNap: truebreakfast_comment: "Ggghj"breakfast_time: "16:37"center_id: "2"child_id: "2027"class_id: "880"dreport_log_date: "2023-09-08"dreport_log_id: "3369489"dreport_log_write: "2023-09-08 16:38:37"dreport_sent: nullfood_breakfast_value: "2"food_lunch_value: 1food_snack2_value: 1food_snack_value: "2"juice_value: "3"lunch_comment: "Iiii"lunch_time: "19:37"mood_morning_value: "2"snack1_comment: "Llll"snack1_time: "18:37"snack2_comment: "Hhhh"snack2_time: "14:38"teacher_id: "4"utc_id_first_comment: nullwater_value: "3" */}
                  <div dangerouslySetInnerHTML={{ __html: this.renderDailyReport2() }}></div>
                </div>
                <div style={{ flex: 1 }} >
                  {comments == null ? (
                    <div className='d-flex flex-column py-4 align-items-center'>
                      <span className='mb-1'>
                        <Spinner animation='grow' variant='dark' />
                      </span>
                      {t('Fetching comments...')}
                    </div>
                  ) : (
                    <>
                      {comments.length > 0 ? (
                        <>
                          {(this.state.commentsFinished)
                            ? <div className='d-flex flex-column py-4 align-items-center'>
                              <span style={{ color: brand_colors[this.props.defaultTheme.theme_id].color10 }}>{(utc_root_id) ? t('Start of conversation') : t('No more comments')}</span>
                            </div>
                            : <div className='d-flex flex-column py-4 align-items-center'>
                              <span style={{ cursor: 'pointer' }} onClick={() => this.getComments()}>{t('Load more comments')}</span>
                            </div>
                          }
                          {comments.map((item, index) => (
                            <CommentItem
                              key={index}
                              data={item}
                              utc_root_id={utc_root_id}
                              openConversation={
                                this.openConversation
                              }
                              closeConversation={
                                this.closeConversation
                              }
                              index={index}
                              count={comments.length - 1}
                            />
                          ))}
                        </>
                      ) : (
                        <div className='d-flex flex-column py-5 align-items-center'>
                          {t('No comments yet!')}
                        </div>
                      )}
                    </>
                  )}
                </div>

              </div>
            </Modal.Body>
          }
        </Modal>
        {/* SEEN MODAL */}
        <Modal size='xl' show={seenModal} onHide={() => this.handleSeenModal()}>
          <Modal.Header closeButton>
            <Modal.Title>{t('Seen')}</Modal.Title>
          </Modal.Header>
          {seenModal &&
            <Modal.Body>
              <div className='d-flex flex-row align-items-center justify-content-around mb-2'>
                <div style={{ fontSize: 16, color: brand_colors[this.props.defaultTheme.theme_id].color9, opacity: 0.7 }}>
                  <strong>{t('Total Tagged')}:</strong> {(data.user_timeline_tagged).length}
                </div>
                <div style={{ fontSize: 16, color: brand_colors[this.props.defaultTheme.theme_id].color9, opacity: 0.7 }}>
                  <strong>{t('Total Seen')}:</strong> {(this.countSeen(data.user_timeline_tagged))}
                </div>
              </div>
              <div className='tagged-children'>
                {this.renderSeen(data.user_timeline_tagged)}
              </div>
            </Modal.Body>
          }
        </Modal>
        {/* SEEN MODAL */}
        <Modal size='xl' show={eventModal} onHide={() => this.setState({ eventModal: false })}>
          <Modal.Header>
            <div className='d-flex flex-row align-items-center justify-content-between' style={{ width: '100%' }}>
              <Modal.Title>{t('Confirmations')}</Modal.Title>
              {/* <ReactToPrint
                trigger={() => {
                  return <div style={{ fontSize: 20, fontWeight: 'bold', opacity: 0.8 }} className='cursor-pointer'>
                    <FontAwesomeIcon icon={faPrint} />
                    {t('Export')}
                  </div>
                }}
                content={() => this.eventConfirmedPrint.current}
              /> */}
              <div style={{ fontSize: 20, fontWeight: 'bold', opacity: 0.8 }} className='cursor-pointer' onClick={() => this.exportExcel_renderEventConfirmed(data.user_timeline_tagged)}>
                <FontAwesomeIcon icon={faPrint} />
                {t('Export')}
              </div>

            </div>
          </Modal.Header>
          {eventModal &&
            <Modal.Body>
              <div className='d-flex flex-row align-items-center justify-content-around mb-2'>
                <div style={{ fontSize: 16, color: brand_colors[this.props.defaultTheme.theme_id].color9, opacity: 0.7 }}>
                  <div><strong>{t('Total Tagged')}: </strong> {(data.user_timeline_tagged).length}</div>
                  <div><strong>{t('Total Seen')}: </strong> {(this.countSeen(data.user_timeline_tagged))}</div>
                  <div><strong>{t('Total Pending')}: </strong> {(data.user_timeline_tagged.filter((v, i) => !v.utt_confirmed).length)}</div>
                </div>
                <div style={{ fontSize: 16, color: brand_colors[this.props.defaultTheme.theme_id].color9, opacity: 0.7 }}>
                  <div><strong>{t('Yes')}: </strong> {data.user_timeline_tagged.filter((v, i) => v.utt_confirmed_condition == 'Yes').length}</div>
                  <div><strong>{t('No')}: </strong> {data.user_timeline_tagged.filter((v, i) => v.utt_confirmed_condition == 'No').length}</div>
                  {(this.props.data.ut_type == 'Event') &&
                    <div><strong>{t('Maybe')}: </strong> {data.user_timeline_tagged.filter((v, i) => v.utt_confirmed_condition == 'Maybe').length}</div>
                  }
                </div>
              </div>
              {this.renderEventConfirmed(data.user_timeline_tagged)}
            </Modal.Body >
          }
        </Modal>
        {/* POLL & SURVEY RESULTS MODAL */}
        < Modal size='lg' show={this.state.resultsModal.show} onHide={this.state.resultsModal.toggleModal} >
          <Modal.Header>
            <div className='d-flex flex-row align-items-center justify-content-between' style={{ width: '100%' }}>
              <Modal.Title>{t('Result')}</Modal.Title>
              <div className='d-flex flex-row'>
                <ReactToPrint
                  trigger={() => {
                    return <div style={{ fontSize: 20, fontWeight: 'bold', opacity: 0.8 }} className='cursor-pointer'>
                      <FontAwesomeIcon icon={faPrint} />
                      {t('Export')}
                    </div>
                  }}
                  content={() => this.PollSurveyPrint.current}
                />

                <div className='ms-3 cursor-pointer' style={{ fontSize: 20, fontWeight: 'bold', opacity: 0.8 }} onClick={() => this.exportExcel_Survey(data)}>
                  <FontAwesomeIcon icon={faFileExcel} />
                  Excel
                </div>
              </div>

            </div>
          </Modal.Header>
          <Modal.Body ref={this.PollSurveyPrint}>
            {this.state.resultsModal.show && this.state.resultsModal.data && this.state.resultsModal.data.ut_type == 'Poll' &&
              <div className='p-1'>
                <div style={{ fontSize: 20, fontWeight: '600' }} className='my-2'>{data.ut_title}</div>
                <div className='d-flex flex-row justify-content-around align-items-center'>
                  <div className='d-flex flex-column align-items-center justify-content-center'>
                    <div style={{ fontSize: 12, color: brand_colors[this.props.defaultTheme.theme_id].color9, opacity: 0.7 }}>{t('Total Target')}</div>
                    <Badge bg='primary'>{parseInt(this.state.resultsModal.data.total_user_timeline_tagged) + parseInt(this.state.resultsModal.data.total_user_timeline_tagged_users)}</Badge>
                  </div>
                  <div className='d-flex flex-column align-items-center justify-content-center'>
                    <div style={{ fontSize: 12, color: brand_colors[this.props.defaultTheme.theme_id].color9, opacity: 0.7 }}>{t('Total Answered')}</div>
                    <Badge bg='success'>{this.state.resultsModal.data.poll_option_selected.length}</Badge>
                  </div>
                  <div className='d-flex flex-column align-items-center justify-content-center'>
                    <div style={{ fontSize: 12, color: brand_colors[this.props.defaultTheme.theme_id].color9, opacity: 0.7 }}>{t('Total % Answered')}</div>
                    <Badge bg='warning'>{((this.state.resultsModal.data.poll_option_selected.length / (this.state.resultsModal.data.total_user_timeline_tagged + this.state.resultsModal.data.total_user_timeline_tagged_users)) * 100).toFixed(2)}%</Badge>
                  </div>
                </div>
                <div style={{ margin: '10px 0' }}>
                  <Doughnut
                    height={200}
                    data={{
                      labels: this.state.resultsModal.data.graphData.labels,
                      datasets: [
                        {
                          data: this.state.resultsModal.data.graphData.data,
                          backgroundColor: this.state.resultsModal.data.graphData.colors,
                        },
                      ],
                    }}
                    options={{
                      plugins: {
                        legend: {
                          display: true,
                        },
                      },
                      responsive: false
                    }}
                  />
                </div>
                <div style={{ margin: '10px 0' }}>
                  <div className='d-flex flex-row flex-wrap'>
                    {this.state.resultsModal.data.poll_option_selected.map((v, i) => {
                      let findOpt = this.state.resultsModal.data.poll_options.find((vv) => vv.poll_opt_id == v.poll_opt_id)

                      return <Col key={i} md="6" className='mb-1'>
                        <div style={{ backgroundColor: findOpt.color, borderRadius: 10, padding: 5, margin: 5 }} >
                          <Badge style={{ marginBottom: '5px', }} bg='dark' title={moment(v.created_on).format('DD-MM-YYYY HH:mm:ss')
                          }>{v.full_name} ({v.role_name})</Badge>
                          {v.poll_opt_comment &&
                            <div style={{ fontSize: 12, color: brand_colors[this.props.defaultTheme.theme_id].color9, opacity: 0.7, backgroundColor: brand_colors[this.props.defaultTheme.theme_id].color8, borderRadius: 10, padding: 5 }}>
                              {v.poll_opt_comment}
                            </div>
                          }
                        </div>
                      </Col>
                    })}
                  </div>
                </div>
              </div>
            }
            {this.state.resultsModal.show && this.state.resultsModal.data && this.state.resultsModal.data.ut_type == 'Survey' &&
              <div className='p-1'>
                <div style={{ fontSize: 20, fontWeight: '600' }} className='my-2'>{data.ut_title}</div>
                {/* // TABS */}
                {/* <div className='d-flex flex-row flex-wrap' style={{}}>
                  {this.state.resultsModal.data.graphData.map((v, i) => {
                    return <div key={i} style={{ padding: '10px', margin: '5px', borderBottom: 'solid 2px #000', borderBottomColor: (this.state.resultsModal.activeTab == i) ? brand_colors[this.props.defaultTheme.theme_id].color4 : brand_colors[this.props.defaultTheme.theme_id].color18, textAlign: 'center', cursor: 'pointer', }} onClick={() => this.state.resultsModal.toggleTab(i)}>
                      <div style={{ fontSize: 13, color: brand_colors[this.props.defaultTheme.theme_id].color9, opacity: 0.8 }}>
                        Question No: {i + 1}
                      </div>
                      <Badge bg="dark">{v.eqtype_id}</Badge>
                    </div>
                  })}
                </div>
                <hr /> */}
                {this.state.resultsModal.data.graphData.map((v, i) => {
                  // if (this.state.resultsModal.activeTab == i) {
                  return <div key={i} style={{ borderBottomWidth: 3, borderBottomColor: brand_colors[this.props.defaultTheme.theme_id].color19, marginBottom: 10, padding: 5, borderBottomStyle: 'solid' }}>
                    <div>
                      <div style={{ fontSize: 13, color: brand_colors[this.props.defaultTheme.theme_id].color9, opacity: 0.8 }}>{v.survey_q_description}</div>
                      <Badge bg="dark">{v.eqtype_id}</Badge>
                    </div>
                    <div className='d-flex flex-column justify-content-center align-items-center'>
                      <div className='d-flex flex-row justify-content-around align-items-center' style={{ width: '100%' }}>
                        <div className='d-flex flex-column align-items-center justify-content-center'>
                          <div style={{ fontSize: 12, color: brand_colors[this.props.defaultTheme.theme_id].color9, opacity: 0.7 }}>{t('Total Target')}</div>
                          <Badge bg='primary'>{v.total_tagged}</Badge>
                        </div>
                        <div className='d-flex flex-column align-items-center justify-content-center'>
                          <div style={{ fontSize: 12, color: brand_colors[this.props.defaultTheme.theme_id].color9, opacity: 0.7 }}>{t('Total Answered')}</div>
                          <Badge bg='success'>{v.total_tagged_answered}</Badge>
                        </div>
                        <div className='d-flex flex-column align-items-center justify-content-center'>
                          <div style={{ fontSize: 12, color: brand_colors[this.props.defaultTheme.theme_id].color9, opacity: 0.7 }}>{t('Total % Answered')}</div>
                          <Badge bg='warning'>{v.total_answered_percent}%</Badge>
                        </div>
                      </div>
                      {/* MCQ, MRQ, RATING, SCALE, TF */}
                      {(v.eqtype_id == 'MCQ' || v.eqtype_id == 'MRQ' || v.eqtype_id == 'TF' || v.eqtype_id == 'SCALE' || v.eqtype_id == 'RATING') &&
                        <div style={{ margin: '10px 0' }}>
                          <V.VictoryPie
                            data={[...v.chart_data]}
                            colorScale={[...v.chart_colors]}
                            theme={V.VictoryTheme.material}
                            labels={({ datum }) => datum.x + '(' + datum.y + ')'}
                            labelComponent={<V.VictoryTooltip />}
                          >
                          </V.VictoryPie>
                          <div className='d-flex flex-row justify-content-center align-items-center flex-wrap' style={{ marginTop: 10, backgroundColor: brand_colors[this.props.defaultTheme.theme_id].color19, borderRadius: 10, }}>
                            {v.chart_data.map((v, i) => {
                              return <div style={{ padding: 10, fontSize: 12 }}>{v.x}({v.y})</div>
                            })}
                          </div>
                        </div>
                      }
                      {/* MATRIX */}
                      {(v.eqtype_id == 'MATRIX') &&
                        <div style={{ margin: '10px 0', width: '100%' }}>
                          {v.chart_data.map((vv, ii) => {
                            return <div className='d-flex flex-column' style={{ margin: '10px 0' }}>
                              <strong>{vv.label}</strong>
                              <div className='d-flex justify-content-center align-items-center flex-column align-self-center' style={{ width: '50%' }}>
                                <V.VictoryPie
                                  data={[...vv.data]}
                                  theme={V.VictoryTheme.material}
                                  labels={({ datum }) => datum.x + '(' + datum.y + ')'}
                                  labelComponent={<V.VictoryTooltip />}
                                >
                                </V.VictoryPie>
                              </div>
                              <div className='d-flex flex-row justify-content-center align-items-center flex-wrap' style={{ marginTop: 10, backgroundColor: brand_colors[this.props.defaultTheme.theme_id].color19, borderRadius: 10, }}>
                                {vv.data.map((vvv, iii) => {
                                  return <div style={{ padding: 10, fontSize: 12 }}>{vvv.x}({vvv.y})</div>
                                })}
                              </div>
                            </div>
                          })}
                        </div>
                      }
                      {/* COMMENTS */}
                      {(v.eqtype_id == 'COMMENTS') &&
                        <div style={{ margin: '10px 0', width: '100%' }}>
                          {v.survey_question_options.map((vv, ii) => {
                            return <div>
                              <strong>{vv.survey_q_opt_desc}</strong>
                              {v.survey_answers.map((vvv, iii) => {
                                let survey_q_opt_ids = JSON.parse(vvv['survey_q_opt_ids'])
                                return (survey_q_opt_ids[ii]) && <div className='d-flex flex-row' style={{ margin: '10px 0' }}>
                                  <Badge variant='primary'>{vvv.role_name}</Badge>
                                  <div style={{ padding: '0 10px' }}>{survey_q_opt_ids[ii]}</div>
                                </div>
                              })}
                            </div>
                          })}

                        </div>
                      }
                    </div>
                  </div>
                  // }
                })}
              </div>
            }
          </Modal.Body>
        </Modal >


        {/* approveMediaModal MODAL */}
        <Modal size='xl' show={approveMediaModal.show} onHide={() => approveMediaModal.toggleModal()}>
          <Modal.Header closeButton>
            <Modal.Title>{t('Approve Media')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className={(data.ut_publish_status) ? 'alert alert-success m-1 p-2' : 'alert alert-danger m-1 p-2'}>
              <div className='d-flex flex-row justify-content-between align-items-center'>
                <div className='d-flex flex-column'>
                  <div>{(data.ut_publish_status) ? t('Post has been published') : t('Post has not been approved yet')}</div>
                  {/* <div><strong>{t('Total Media') + ': '}</strong>{user_timeline_media.length}</div> */}
                  {/* <div><strong>{t('Approved Media') + ': '}</strong>{user_timeline_media.filter((v, i) => v.utm_approve).length}</div> */}
                </div>
                <div>
                  {(!data.ut_publish_status && ((this.props.authData.userData.employee_photo_approve && ([1, 8, 12, 15, 19].includes(this.props.authData.loginData.role_id) || data.created_by == this.props.authData.loginData.user_id)) || this.props.authData.loginData.role_id == 22)) &&
                    <Button title={t('Approve All & Publish Now')} variant='primary' size='sm' className='me-2' onClick={() => this.toggleApproveUserTimelineMedia(true, true)}>
                      <FontAwesomeIcon icon={faThumbsUp} style={{ fontSize: 12 }} className='me-2' />
                      {t('Approve All & Publish Now')}
                    </Button>
                  }
                  {/* {(!data.ut_publish_status && [1, 8, 12, 15, 19, 22].includes(this.props.authData.loginData.role_id)) &&
                    <Button title={t('Publish Now')} variant='success' size='sm' className='me-2' onClick={() => this.toggleApproveUserTimelineMedia(false, true)}>
                      <FontAwesomeIcon icon={faPaperPlane} style={{ fontSize: 12 }} className='me-2' />
                      {t('Publish Now')}
                    </Button>
                  }
                  <Button title={t('Update Now')} variant='warning' size='sm' onClick={() => this.toggleApproveUserTimelineMedia()}>
                    <FontAwesomeIcon icon={faPaperPlane} style={{ fontSize: 12 }} className='me-2' />
                    {t('Update')}
                  </Button> */}
                </div>
              </div>
            </div>

            {(approveMediaModal.show && data.ut_type == 'Media' && user_timeline_media.length > 0) &&
              <div className='d-flex flex-row flex-wrap justify-content-center'>
                {user_timeline_media.map((v, i) => {
                  let pictureURL = (v.utm_url)
                    ? v.utm_url
                    : (v.utm_folder)
                      ? portalURL + v.center_id + '/' + v.utm_folder + '/' + v.utm_name
                      : portalURL + v.center_id + '/user_timeline_media/' + v.utm_name
                  return <div className='m-2' style={{ width: '18%' }}>
                    <div
                      className='border position-relative album-picture'
                      style={{
                        borderRadius: '5px', height: '240px', width: '100%', backgroundPosition: 'center center',
                        backgroundImage:
                          'url(' + pictureURL + ')', backgroundSize: 'cover',
                      }}>
                      {(data.created_by == this.props.authData.loginData.user_id || [1, 8, 12, 15, 19, 22].includes(this.props.authData.loginData.role_id)) &&
                        <Button title={t('Delete Photo')} style={{ right: '40px' }} onClick={() => this.deleteUserTimelineMedia(v, i)}
                          variant='danger'
                          className='media-view-btn'>
                          <FontAwesomeIcon icon={faTrash} style={{ fontSize: 12 }} color={brand_colors[this.props.defaultTheme.theme_id].color8} />
                        </Button>
                      }
                      {/* {((this.props.authData.userData.employee_photo_approve && [1, 8, 12, 15, 19].includes(this.props.authData.loginData.role_id)) || this.props.authData.loginData.role_id == 22) &&
                        <Button title={(v.utm_approve) ? t('Disapprove Photo') : t('Approve Photo')} style={{ right: '40px' }} onClick={() => {
                          let user_timeline_media = this.state.user_timeline_media
                          user_timeline_media[i].utm_approve = (user_timeline_media[i].utm_approve) ? 0 : 1
                          this.setState({ user_timeline_media })
                        }}
                          variant={(v.utm_approve) ? 'danger' : 'primary'}
                          className='media-view-btn'>
                          <FontAwesomeIcon icon={(v.utm_approve) ? faThumbsDown : faThumbsUp} style={{ fontSize: 12 }} color={brand_colors[this.props.defaultTheme.theme_id].color8} />
                        </Button>
                      } */}
                      <Button title={t('View Photo')} style={{ right: '10px' }} onClick={() => this.openImageViewer(i, data.center_id, user_timeline_media, 'user_timeline_media', 'utm_name')}
                        variant='warning'
                        className='media-view-btn'>
                        <FontAwesomeIcon icon={faEye} style={{ fontSize: 12 }} color={brand_colors[this.props.defaultTheme.theme_id].color8} />
                      </Button>
                      {v.utm_approve ?
                        <div className='p-1' style={{ position: 'absolute', bottom: 0, backgroundColor: changeColorOpacity(brand_colors[this.props.defaultTheme.theme_id].color8, 0.6), width: '100%', textAlign: 'center' }}>
                          {v.utm_approve_by ? t('Approved by ' + v.full_name) : t('Approved')}
                        </div>
                        : null
                      }
                    </div>
                  </div>
                })}
              </div>
            }
          </Modal.Body>
        </Modal>

        {
          isViewerOpen && (
            <ImageViewer
              backgroundStyle={{ backgroundColor: 'rgba(0,0,0,0.8)' }}
              closeComponent={<FontAwesomeIcon icon={faTimes} style={{ color: brand_colors[this.props.defaultTheme.theme_id].color1 }} />}
              leftArrowComponent={<FontAwesomeIcon icon={faAngleLeft} style={{ color: brand_colors[this.props.defaultTheme.theme_id].color1 }} />}
              rightArrowComponent={<FontAwesomeIcon icon={faAngleRight} style={{ color: brand_colors[this.props.defaultTheme.theme_id].color1 }} />}
              src={images}
              currentIndex={currentImageViewerIndex}
              disableScroll={true}
              closeOnClickOutside={true}
              onClose={this.closeImageViewer}
            />
          )
        }

        <SweetAlert
          show={this.state.deleteModal.show}
          warning
          showCancel
          confirmBtnText={this.state.deleteModal.btnText ? this.state.deleteModal.btnText : t('Yes, delete it!')}
          confirmBtnBsStyle='danger'
          title={(this.state.deleteModal.title) ? this.state.deleteModal.title : t('Are you sure?')}
          onConfirm={this.state.deleteModal.action}
          onCancel={() => this.setState({ deleteModal: { ...this.state.deleteModal, show: false, id: 0, action: () => { } } })}
          focusCancelBtn>
          {(this.state.deleteModal.desc) ? this.state.deleteModal.desc : t('Confirm Delete!')}
        </SweetAlert>

      </Card >
    )
  }
}

export default TimelineCard

/**
 * Survey Component
 */
class Survey extends Component {
  // State
  state = {
    working: false,
    selectedScale: null,
    surveyAnswers: this.props.data.survey.survey_answers,
    surveyQuestions: this.props.data.survey.survey_questions,
  }

  /**
   * Render Survey Slider
   * @param {Object} question
   * @param {number} survey_id
   * @param {number} index
   * @param {number} questions_length
   * @returns
   */
  renderSurveyQSlide = (question, survey_id, index, surveyAnswers) => {


    let findAns = surveyAnswers.find((v, i) => v.survey_q_id == question.survey_q_id)
    let survey_q_opt_ids = []
    let findRating = null
    if (findAns) {
      survey_q_opt_ids = JSON.parse(findAns.survey_q_opt_ids)
      if (question.eqtype_id == 'RATING') {
        findRating = question.survey_question_options.findIndex((vv) => vv.survey_q_opt_id == survey_q_opt_ids[0])
      }
    }
    return (
      <div className='survey-slide px-1' key={index} >
        <div style={{ borderColor: brand_colors[this.props.defaultTheme.theme_id].color19, borderWidth: 5, borderRadius: 10, borderStyle: 'solid' }}>
          <div style={{ backgroundColor: brand_colors[this.props.defaultTheme.theme_id].color8, padding: 5 }}>
            <FontAwesomeIcon icon={faQuestionCircle} className='me-1' style={{ color: brand_colors[this.props.defaultTheme.theme_id].color9, fontSize: 13, opacity: 0.8 }} />{' '}
            <span style={{ color: brand_colors[this.props.defaultTheme.theme_id].color9, fontSize: 13, opacity: 0.8 }}>{question.survey_q_description}</span>
          </div>

          <div style={{ backgroundColor: brand_colors[this.props.defaultTheme.theme_id].color8, padding: 5 }}>
            {question.eqtype_id == 'MATRIX' && (
              <div className='matrix-survey'>
                {question.survey_question_options.map((sqo, index) => {
                  let selectedAns = survey_q_opt_ids.find((vv, ii) => (vv.survey_q_opt_id == sqo.survey_q_opt_id))
                  return <div className='mb-2' key={index}>
                    <Form.Label>{sqo.survey_q_opt_desc}</Form.Label>
                    <Form.Select
                      size='sm'
                      onChange={(e) =>
                        this.updateAnswer(e, question)
                      }
                      defaultValue={(selectedAns) ? JSON.stringify(selectedAns) : 'select'}>
                      <option value='select' disabled>
                        {t('Select...')}
                      </option>
                      {JSON.parse(question.survey_q_answers).map(
                        (sqa, index) => (
                          <option
                            key={index}
                            value={JSON.stringify({
                              survey_q_opt_id:
                                sqo.survey_q_opt_id,
                              survey_q_answer: sqa,
                            })}>
                            {sqa}
                          </option>
                        )
                      )}
                    </Form.Select>
                  </div>
                })}
              </div>
            )}

            {question.eqtype_id == 'COMMENTS' && (
              <div className='custom-styled-form comments-survey'>
                {question.survey_question_options.map((sqo, index) => {
                  let selectedAns = survey_q_opt_ids.find((vv, ii) => ii == index)
                  return <div className='mb-2' key={index}>
                    <Form.Label>{sqo.survey_q_opt_desc}</Form.Label>
                    <Form.Control
                      size='sm'
                      type='text'
                      placeholder={sqo.survey_q_opt_desc}
                      onChange={(e) =>
                        this.updateAnswer(e, question, index)
                      }
                      defaultValue={(selectedAns) ? selectedAns : ''}
                    />
                  </div>
                })}
              </div>
            )}

            {question.eqtype_id == 'RATING' && (
              <div className='rating-survey'>
                {/* <ReactStars
                  count={question.survey_question_options.length}
                  onChange={(newRating) =>
                    this.updateAnswer(newRating, question)
                  }
                  size={24}
                  activeColor={brand_colors[this.props.defaultTheme.theme_id].color2}
                  value={findRating}
                  char={'T'}
                /> */}
                <form className="star-rating">
                  {Array.apply(null, { length: question.survey_question_options.length }).map((_, index) => (
                    <>
                      <input className="radio-input" type="radio" id={`star${question.survey_q_id}_${index}`} name={`star${question.survey_q_id}`} checked={findRating == index} />
                      <label className="radio-label"
                        onClick={() => this.updateAnswer(index, question)}
                        for={`star${question.survey_q_id}_${index}`} title={`${index} stars`}>
                        <span>{index}</span>
                      </label>
                    </>
                  ))}
                </form>
              </div>
            )}

            {question.eqtype_id == 'SCALE' && (
              <div className='scale-survey'>
                {question.survey_question_options.map((sqo, index) => {
                  let selectedAns = survey_q_opt_ids.find((vv, ii) => vv == sqo.survey_q_opt_id)
                  return <label
                    key={index}
                    className={(sqo.survey_q_opt_desc == this.state.selectedScale || selectedAns)
                      ? 'survey-scale-select'
                      : 'selected-scale survey-scale-select'
                    }
                    htmlFor={`scale-${sqo.survey_q_opt_id}-${survey_id}`}
                    onClick={() =>
                      this.setState({
                        selectedScale: sqo.survey_q_opt_desc,
                      })
                    }>
                    <input
                      className='survey-radio'
                      type='radio'
                      name={`scale-${sqo.survey_q_id}`}
                      id={`scale-${sqo.survey_q_opt_id}-${survey_id}`}
                      value={sqo.survey_q_opt_desc}
                      onChange={(e) =>
                        this.updateAnswer(e, question)
                      }
                      checked={(selectedAns) ? true : false}
                    />
                    {sqo.survey_q_opt_desc}
                  </label>
                })}
              </div>
            )}

            {question.eqtype_id == 'TF' && (
              <div className='tf-survey'>
                <div>
                  {question.survey_question_options.map(
                    (sqo, index) => {
                      let selectedAns = survey_q_opt_ids.find((vv, ii) => vv == sqo.survey_q_opt_id)
                      return <Form.Check
                        key={index}
                        label={sqo.survey_q_opt_desc}
                        name={`tf-${question.survey_q_id}`}
                        type='radio'
                        id={`opt-${sqo.survey_q_opt_id}`}
                        value={sqo.survey_q_opt_id}
                        onChange={(e) =>
                          this.updateAnswer(e, question)
                        }
                        checked={(selectedAns) ? true : false}
                      />
                    }
                  )}
                </div>
              </div>
            )}

            {question.eqtype_id == 'MRQ' && (
              <Row className='mrq-survey'>
                {question.survey_question_options.map((sqo, index) => {
                  let selectedAns = survey_q_opt_ids.find((vv, ii) => vv == sqo.survey_q_opt_id)
                  return <Col key={index} md="6" style={{ padding: 10 }}>
                    <div className='mrq-item d-flex flex-column justify-content-center align-items-center' style={{ borderRadius: 10, backgroundColor: brand_colors[this.props.defaultTheme.theme_id].color19, position: 'relative', padding: '10px 10px 10px 20px' }} key={index}>
                      <div style={{ position: 'absolute', top: 5, left: 5 }}>
                        <Form.Check
                          name={`mrq-${question.survey_q_id}`}
                          id={`opt-${sqo.survey_q_opt_id}-${question.survey_q_id}`}
                          value={sqo.survey_q_opt_id}
                          onChange={(e) =>
                            this.updateAnswer(e, question)
                          }
                          checked={(selectedAns) ? true : false}
                        />
                      </div>
                      {sqo.survey_q_opt_attachment &&
                        <div style={{ width: 60 }}>
                          <Image src={portalURL + sqo.center_id + '/survey_question_options/' + sqo.survey_q_opt_attachment} style={{ width: '100%' }} />
                        </div>
                      }
                      <div style={{ color: brand_colors[this.props.defaultTheme.theme_id].color9, fontSize: 12, opacity: 0.8 }}>{sqo.survey_q_opt_desc}</div>
                    </div>
                  </Col>
                })}
              </Row>
            )}

            {question.eqtype_id == 'MCQ' && (
              <Row className='mcq-survey'>
                {question.survey_question_options.map((sqo, index) => {

                  let selectedAns = survey_q_opt_ids.find((vv, ii) => vv == sqo.survey_q_opt_id)
                  return <Col key={index} md="6" style={{ padding: 10 }}>
                    <div className='d-flex flex-column justify-content-center align-items-center flex-wrap' style={{ borderRadius: 10, backgroundColor: brand_colors[this.props.defaultTheme.theme_id].color19, position: 'relative', padding: '10px 10px 10px 20px' }}>
                      <div style={{ position: 'absolute', top: 5, left: 5 }}>
                        <Form.Check
                          name={`mcq-${question.survey_q_id}`}
                          type='radio'
                          id={`opt-${sqo.survey_q_opt_id}`}
                          value={sqo.survey_q_opt_id}
                          onChange={(e) => this.updateAnswer(e, question)}
                          checked={(selectedAns) ? true : false}
                        />
                      </div>
                      {sqo.survey_q_opt_attachment &&
                        <div style={{ width: 60 }}>
                          <Image src={portalURL + sqo.center_id + '/survey_question_options/' + sqo.survey_q_opt_attachment} style={{ width: '100%' }} />
                        </div>
                      }
                      <div style={{ color: brand_colors[this.props.defaultTheme.theme_id].color9, fontSize: 12, opacity: 0.8 }}>{sqo.survey_q_opt_desc}</div>
                    </div>
                  </Col>
                })}
              </Row>
            )}

            {question.survey_q_comment_allow == 1 &&
              <div className='custom-styled-form'>
                <Form.Group className='mb-3 '>
                  <Form.Label>
                    {question.survey_q_comment_label}
                  </Form.Label>
                  < Form.Control as="textarea" rows={1} defaultValue={(findAns) ? findAns.survey_ans_comment : ''}
                    onBlur={(e) => this.updateComment(question, e.target.value)}
                    placeholder={question.survey_q_comment_label}
                  />
                </Form.Group>
              </div>
            }

          </div>
        </div>
      </div>
    )
  }

  updateComment = (question, value) => {
    const { surveyAnswers } = this.state
    const { authData } = this.props
    const surveyAnswersIndex = surveyAnswers.findIndex((v, i) => v.survey_q_id == question.survey_q_id)
    if (surveyAnswersIndex !== -1) {
      surveyAnswers[surveyAnswersIndex]['survey_ans_comment'] = value
    } else {
      surveyAnswers.push({
        user_id: authData.loginData.user_id,
        survey_id: question.survey_id,
        survey_q_id: question.survey_q_id,
        survey_q_opt_ids: JSON.stringify([]),
        survey_ans_comment: value
      })
    }
    this.setState({ surveyAnswers })
  }

  /**
   * Update Answer
   * @param {*} e Event
   * @param {Object} question Question object
   * @param {number} index Indexing
   */
  updateAnswer = (e, question, index = 0) => {
    const { surveyAnswers } = this.state
    const { authData } = this.props

    if (question.eqtype_id == 'MATRIX') {
      const value = JSON.parse(e.target.value)
      const findAnswer = (item) =>
        item.survey_q_id == question.survey_q_id
      const surveyAnswersIndex = surveyAnswers.findIndex(findAnswer)

      if (surveyAnswersIndex !== -1) {
        let survey_q_opt_ids = JSON.parse(
          surveyAnswers[surveyAnswersIndex].survey_q_opt_ids
        )

        let survey_q_opt_ids_index = survey_q_opt_ids.findIndex(
          (v, i) => v.survey_q_opt_id == value.survey_q_opt_id
        )
        if (survey_q_opt_ids_index !== -1) {
          survey_q_opt_ids[survey_q_opt_ids_index] = {
            survey_q_opt_id: value.survey_q_opt_id,
            survey_q_answer: value.survey_q_answer,
          }
        } else {
          survey_q_opt_ids.push({
            survey_q_opt_id: value.survey_q_opt_id,
            survey_q_answer: value.survey_q_answer,
          })
        }
        surveyAnswers[surveyAnswersIndex] = {
          ...surveyAnswers[surveyAnswersIndex],
          survey_q_opt_ids: JSON.stringify(survey_q_opt_ids),
        }
      } else {
        surveyAnswers.push({
          user_id: authData.loginData.user_id,
          survey_id: question.survey_id,
          survey_q_id: question.survey_q_id,
          survey_q_opt_ids: JSON.stringify([value]),
          survey_ans_comment: ''
        })
      }

      this.setState({ surveyAnswers })
    }

    if (question.eqtype_id == 'COMMENTS') {
      const value = e.target.value

      const findAnswer = (item) =>
        item.survey_q_id == question.survey_q_id
      const surveyAnswersIndex = surveyAnswers.findIndex(findAnswer)

      if (surveyAnswersIndex !== -1) {
        let survey_q_opt_ids = JSON.parse(
          surveyAnswers[surveyAnswersIndex].survey_q_opt_ids
        )
        survey_q_opt_ids[index] = value
        surveyAnswers[surveyAnswersIndex] = {
          ...surveyAnswers[surveyAnswersIndex],
          survey_q_opt_ids: JSON.stringify(survey_q_opt_ids),
        }
      } else {
        surveyAnswers.push({
          user_id: authData.loginData.user_id,
          survey_id: question.survey_id,
          survey_q_id: question.survey_q_id,
          survey_q_opt_ids: JSON.stringify([value]),
          survey_ans_comment: ''
        })
      }

      this.setState({ surveyAnswers })
    }

    if (question.eqtype_id == 'MRQ') {
      const value = e.target.value
      let survey_q_opt_ids = []

      const findAnswer = (item) =>
        item.survey_q_id == question.survey_q_id
      const surveyAnswersIndex = surveyAnswers.findIndex(findAnswer)

      if (surveyAnswersIndex !== -1) {
        survey_q_opt_ids = JSON.parse(
          surveyAnswers[surveyAnswersIndex].survey_q_opt_ids
        )
        if (survey_q_opt_ids.includes(value)) {
          survey_q_opt_ids.splice(
            survey_q_opt_ids.indexOf(value.survey_q_opt_id),
            1
          )
        } else {
          survey_q_opt_ids.push(value)
        }
        surveyAnswers[surveyAnswersIndex] = {
          ...surveyAnswers[surveyAnswersIndex],
          survey_q_opt_ids: JSON.stringify(survey_q_opt_ids),
        }
      } else {
        surveyAnswers.push({
          user_id: authData.loginData.user_id,
          survey_id: question.survey_id,
          survey_q_id: question.survey_q_id,
          survey_q_opt_ids: JSON.stringify([value]),
          survey_ans_comment: ''
        })
      }

      this.setState({ surveyAnswers })
    }

    if (question.eqtype_id == 'MCQ' || question.eqtype_id == 'TF') {
      const value = e.target.value

      const findAnswer = (item) =>
        item.survey_q_id == question.survey_q_id
      const surveyAnswersIndex = surveyAnswers.findIndex(findAnswer)

      if (surveyAnswersIndex !== -1) {
        surveyAnswers[surveyAnswersIndex] = {
          ...surveyAnswers[surveyAnswersIndex],
          survey_q_opt_ids: JSON.stringify([value]),
        }
      } else {
        surveyAnswers.push({
          user_id: authData.loginData.user_id,
          survey_id: question.survey_id,
          survey_q_id: question.survey_q_id,
          survey_q_opt_ids: JSON.stringify([value]),
          survey_ans_comment: ''
        })
      }

      this.setState({ surveyAnswers })
    }

    if (question.eqtype_id == 'SCALE' || question.eqtype_id == 'RATING') {
      let value

      if (question.eqtype_id == 'RATING') {
        value = e
      } else {
        value = e.target.value
      }

      const findAnswer = (item) =>
        item.survey_q_id == question.survey_q_id
      const surveyAnswersIndex = surveyAnswers.findIndex(findAnswer)

      const survey_q_opt_ids =
        question.survey_question_options[value].survey_q_opt_id

      if (surveyAnswersIndex !== -1) {
        surveyAnswers[surveyAnswersIndex] = {
          ...surveyAnswers[surveyAnswersIndex],
          survey_q_opt_ids: JSON.stringify([survey_q_opt_ids]),
        }
      } else {
        surveyAnswers.push({
          user_id: authData.loginData.user_id,
          survey_id: question.survey_id,
          survey_q_id: question.survey_q_id,
          survey_q_opt_ids: JSON.stringify([survey_q_opt_ids]),
          survey_ans_comment: ''
        })
      }

      this.setState({ surveyAnswers })
    }
  }

  /**
   * Submit Survey
   */
  submitSurvey = async () => {
    this.setState({
      working: true,
    })
    const { surveyAnswers } = this.state
    const { data, authData } = this.props

    try {
      const fData = new FormData()
      fData.append('survey_id', data.survey.survey_id)
      fData.append('user_id', authData.loginData.user_id)
      fData.append('center_id', authData.centerData.center_id)
      fData.append(
        'center_timezone',
        authData.centerData.center_timezone
      )
      fData.append('survey_answers', JSON.stringify(surveyAnswers))

      const req = await axios.post(
        apiURL + 'user-timeline/save-survey-option-selected',
        fData,
        {
          params: {
            auth_key: authData.authKey,
          },
        }
      )

      if (req.status === 200) {
        this.setState({
          working: false,
          submitted: true,
        })

        toast.success(t('Survey submitted successfully!'))
      }
    } catch (err) {
      console.log('err', err)
      toast.error(t('Something went wrong!'))

    }
  }

  /**
   * Render Component
   * @returns {HTMLElement}
   */
  render() {

    const { data, dir } = this.props
    const survey_not_started = moment(data.current_datetime, 'YYYY-MM-DD HH:mm:ss').isBefore(moment(data.survey.survey_start, 'YYYY-MM-DD HH:mm:ss'))
    const survey_ended = moment(data.current_datetime, 'YYYY-MM-DD HH:mm:ss').isAfter(moment(data.survey.survey_end, 'YYYY-MM-DD HH:mm:ss'))


    const SlickButton = ({
      currentSlide,
      slideCount,
      children,
      ...props
    }) => (
      <FontAwesomeIcon
        {...props}
        icon={props.dir == 'next' ? faChevronRight : faChevronLeft}
        color={brand_colors[this.props.defaultTheme.theme_id].color2}
      />
    )

    const generalSliderSettings = {
      infinite: false,
      speed: 500,
      slidesToScroll: 1,
      nextArrow: <SlickButton dir='next' />,
      prevArrow: <SlickButton dir='prev' />,
      slidesToShow: 1,
      adaptiveHeight: true,
      dots: true,
      // dotsClass: 'slick-dots-custom'
      beforeChange: (oldIndex, newIndex) => {
        this.setState({ currentSurveyQuestion: newIndex })
      }
    }

    return (
      <>
        <div className='mb-1' >
          {survey_not_started && <Badge bg='warning'>Survey not started yet!</Badge>
          }
          {!survey_not_started && survey_ended && <Badge bg='danger'>Survey Ended</Badge>}
        </div>
        <div className='survey-carousel' style={{ position: 'relative' }}>
          <Slider {...generalSliderSettings}>
            {data.survey.survey_questions.map((question, index) =>
              this.renderSurveyQSlide(
                question,
                data.survey.survey_id,
                index,
                this.state.surveyAnswers
              )
            )}
          </Slider>
          <div className='survey-slide-footer'>
            {(data.survey.survey_questions.length - 1 == this.state.currentSurveyQuestion) &&
              data.survey.allow_submit_by_user &&
              !survey_not_started && !survey_ended &&
              (data.survey.survey_allow_resubmit == 1 || data.survey.survey_answers.length <= 0) &&
              (<Button
                type='submit'
                variant='success'
                className='mt-2'
                onClick={() => this.submitSurvey()}
                size='sm'
                disabled={this.state.working}>
                {t('Submit')}{' '}
                {this.state.working && <Spinner
                  as='span'
                  animation='grow'
                  size='sm'
                  role='status'
                  aria-hidden='true'
                />}
              </Button>)
            }
          </div>
        </div>
      </>
    )
  }
}

/**
 * Poll Component
 */
class Poll extends Component {
  constructor(props) {
    super()
    // State
    this.state = {
      poll_opt_id: (props.data.poll && props.data.poll.poll_option_selected !== false) ? props.data.poll.poll_option_selected.poll_opt_id : null,
      poll_opt_comment: (props.data.poll && props.data.poll.poll_option_selected !== false) ? props.data.poll.poll_option_selected.poll_opt_comment : '',
      working: false,
    }
  }


  /**
   * Poll Submit
   * @param {*} e Event
   * @returns
   */
  pollSubmit = async (e) => {
    e.preventDefault()
    this.setState({
      working: true,
    })
    const { poll_opt_id, poll_opt_comment } = this.state
    const { data, authData } = this.props

    if (poll_opt_id == null) {
      this.setState({
        working: false,
      })
      toast.error(t('Please select poll option to submit.'))
      return
    } else {
      try {
        const fData = new FormData()
        fData.append('poll_id', data.poll.poll_id)
        fData.append('user_id', authData.loginData.user_id)
        fData.append('center_id', authData.centerData.center_id)
        fData.append(
          'center_timezone',
          authData.centerData.center_timezone
        )
        fData.append('poll_option_selected', JSON.stringify({ poll_opt_id: poll_opt_id, poll_opt_comment: poll_opt_comment }))

        const res = await axios.post(
          apiURL + 'user-timeline/save-poll-option-selected',
          fData,
          {
            params: {
              auth_key: authData.authKey,
            },
          }
        )

        if (res.status === 200) {

          if (res.data.type) {
            toast.success(t(res.data.message))
            this.setState({
              working: false,
            })
          } else {
            if (res.data.data != null) {
              handleFormErrors(res.data.data)
            } else {
              toast.error(t(res.data.message))
            }
            this.setState({
              working: false,
            })
          }



        }
      } catch (err) {
        console.log('err', err)

      }
    }
  }





  /**
   * Render Component
   * @returns {HTMLElement}
   */
  render() {
    const { working } = this.state
    const { data } = this.props

    if (!data.poll) return;

    const poll_not_started = moment(data.current_datetime, 'YYYY-MM-DD HH:mm:ss').isBefore(moment(data.poll.poll_start, 'YYYY-MM-DD HH:mm:ss'))
    const poll_ended = moment(data.current_datetime, 'YYYY-MM-DD HH:mm:ss').isAfter(moment(data.poll.poll_end, 'YYYY-MM-DD HH:mm:ss'))
    return (<>
      <div className='mb-1'>
        {poll_not_started && <Badge bg='warning'>Poll not started yet!</Badge>}
        {!poll_not_started && poll_ended && <Badge bg='danger'>Poll Ended</Badge>}
      </div>
      <Form className='poll-options mb-3' onSubmit={this.pollSubmit}>

        {data.poll.poll_options.map((opt, x) => {
          return <React.Fragment key={x}>
            <Form.Check label={opt.poll_opt_name} name={`poll-${data.poll.poll_id}`} type='radio' id={`opt-${opt.poll_opt_id}`}
              disabled={
                (this.props.data.poll
                  .poll_option_selected &&
                  data.poll.poll_allow_resubmit == "0") ||
                working || !data.poll.allow_submit_by_user || poll_ended
              }
              value={opt.poll_opt_id}
              checked={this.state.poll_opt_id == opt.poll_opt_id}
              onChange={() =>
                this.setState({ poll_opt_id: opt.poll_opt_id })
              }
            />
          </React.Fragment>
        })}

        <div className='custom-styled-form'>
          <Form.Group className='mb-3 '>
            <Form.Label>
              {t('Comment')}
            </Form.Label>
            < Form.Control as="textarea" rows={1} defaultValue={this.state.poll_opt_comment} onBlur={(event) => this.setState({ poll_opt_comment: event.target.value })} disabled={
              (this.props.data.poll
                .poll_option_selected &&
                data.poll.poll_allow_resubmit == "0") ||
              working || !data.poll.allow_submit_by_user || poll_ended
            } />
          </Form.Group>
        </div>

        {data.poll.allow_submit_by_user && (
          <>
            {(
              (this.props.data.poll.poll_option_selected && data.poll.poll_allow_resubmit == 1)
              ||
              (!this.props.data.poll.poll_option_selected)
            ) && !poll_not_started && !poll_ended && (
                <Button
                  type='submit'
                  variant='success'
                  className='mt-2'
                  disabled={working}
                  size='sm'>
                  {t('Submit')}{' '}
                  {working && (
                    <Spinner as='span' animation='grow' size='sm' role='status' aria-hidden='true' />
                  )}
                </Button>
              )}
          </>
        )}
      </Form>
    </>

    )
  }
}
