import React, { Component } from 'react';
import {
    Container, Card, Row, Col, ButtonGroup,
    Button,
    DropdownButton,
    Dropdown,
    Table,
    Form,
    Spinner,
    Badge,
    Modal, InputGroup
} from 'react-bootstrap';
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import { t } from '../../../helpers/translation_helper';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faCheckCircle, faSearch, faUser, faXmark, faLayerGroup, faHandsHoldingChild, faPeopleGroup, faListCheck, faPrint, faPencil, faTrash, faCheckDouble, faSortDown, faSortUp, faThumbsUp, faThumbsDown, faBook } from '@fortawesome/free-solid-svg-icons';
import { brand_colors } from '../../../helpers/brand_colors_helper';
import { faCalendarAlt, faEye, faEyeSlash, faFileExcel, faFilePdf } from '@fortawesome/free-regular-svg-icons';
import moment from 'moment';
import { toast } from 'react-toastify';
import { GET, POST } from '../../../api';
import Select from 'react-select';
import { renderTableBody, renderTableHead } from '../../../helpers/table_helper';
import { DateRangePicker } from "react-date-range";
import { uniqueByAttribute2 } from "../../../helpers/general_helpers";
import { Bar } from "react-chartjs-2";
const portalURL = process.env.REACT_APP_PORTAL_URL;
const Chance = require('chance');
const chance = new Chance();


const barChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            display: false,
            position: 'bottom',
        },
    },
    scales: {
        x: {
            ticks: {
                callback: function (value) {
                    return this.getLabelForValue(value).substr(0, 15) + '...';
                }
            }
        }
    }
};

class EvaluationAnalysisReport extends Component {

    state = {
        // deleteModal: { show: false, id: 0, action: () => { }, title: null, desc: null },
        selectedDateRange: { startDate: new Date(moment().subtract(1, 'years')), endDate: new Date(), key: 'selection' },
        generalModalAllEmployeeStatus: false,
        cdt: {
            fetching: false,
            search: '',
            rows: 10,
            order: 'appraisalempl.appraisalempl_id',
            dir: 'ASC',
            lastPage: 0,
            page: 0,
            totalRecords: 0,
            offset: 0,
            rowsOptions: [10, 25, 50, 'All'],
            columns: [
                {
                    id: 'employees.appraisalempl_date',
                    column: 'appraisalempl_date',
                    name: 'Month',
                    visibility: true,
                    width: 130,
                    sortable: false
                },
                {
                    id: 'cent.reg_year',
                    column: 'reg_year',
                    name: 'Academic Year',
                    visibility: true,
                    sortable: true
                },
                {
                    id: 'terms.term_name',
                    column: 'term_name',
                    name: 'Term',
                    visibility: true,
                    sortable: true
                },
                {
                    id: 'employees.employee_name',
                    column: 'employee_name',
                    name: 'Teacher',
                    visibility: true,
                    sortable: true
                },
                {
                    id: 'employees.broad_experience',
                    column: 'broad_experience',
                    name: 'Experience',
                    visibility: true,
                    sortable: true
                },
                {
                    id: 'employees.coordinator',
                    column: 'coordinator',
                    name: 'Coordinator',
                    visibility: true,
                    sortable: false
                },
                {
                    id: 'academic_classmain_subject.acs_name',
                    column: 'acs_name',
                    name: 'Subject',
                    visibility: true,
                    sortable: true
                },
                {
                    id: 'score',
                    column: 'score',
                    name: 'Score',
                    visibility: true,
                    sortable: false
                },
                {
                    id: 'ageg.agegroup_code',
                    column: 'agegroup_code',
                    name: 'Department',
                    visibility: true,
                    sortable: true
                },
                {
                    id: 'classes.class_theme',
                    column: 'class_theme',
                    name: 'Grade',
                    visibility: true,
                    sortable: true
                },
            ],
            data: [],
        },
        selectedTerm: null,
        selectedSubject: null,
        termData: [],
        ageGroupData: [],
        selectedAgeGroup: null,
        subjectData: [],
        employeeScoreResult: []
    }

    componentDidMount() {
        this.fetch({
            showStatus: this.state.showStatus,
            search: this.state.cdt.search,
            limit: this.state.cdt.rows,
            order: this.state.cdt.order,
            dir: this.state.cdt.dir,
            page: this.state.cdt.page
        });
        this.handleGetAllTerm();
        this.handleGetAllAgeGroup()
    }

    fetch = async (params) => {
        console.log("param", this.state)

        this.setState({
            showDateRange: false,
            cdt: {
                ...this.state.cdt,
                fetching: true,
            }
        });
        try {
            const { center_id } = this.props.authData.centerData;
            let age_group_id = this.state.selectedAgeGroup ? this.state.selectedAgeGroup.value : 0;
            let term_id = this.state.selectedTerm ? this.state.selectedTerm.value : 0;
            let subject_id = this.state.selectedSubject ? this.state.selectedSubject.value : 0;
            let startDate = (this.state.selectedDateRange) ? moment(this.state.selectedDateRange.startDate).format('YYYY-MM-DD') + ' 00:00:00' : null
            let endDate = (this.state.selectedDateRange) ? moment(this.state.selectedDateRange.endDate).format('YYYY-MM-DD') + ' 00:00:00' : null
            console.log(center_id)
            const fData = new FormData();
            fData.append('center_id', center_id);
            fData.append('age_group_id', age_group_id)
            fData.append('term_id', term_id)
            fData.append('subject_id', subject_id)
            fData.append('date_from', startDate)
            fData.append('date_to', endDate)
            fData.append('search', params.search)
            fData.append('limit', params.limit);
            fData.append('order', params.order);
            fData.append('dir', params.dir);
            fData.append('offset', parseInt(params.page) * parseInt(params.limit));
            const res = await POST('appraisal/get-appraisal-list-v2', fData)
            console.log("res.data.data", res.data.data)
            this.setState({
                cdt: {
                    ...this.state.cdt,
                    fetching: false,
                    search: params.search,
                    rows: parseInt(params.limit),
                    offset: parseInt(params.page) * parseInt(params.limit),
                    order: params.order,
                    dir: params.dir,
                    page: params.page,
                    totalRecords: parseInt(res.data.total_count_filtered),
                    lastPage: Math.ceil(parseInt(res.data.total_count_filtered) / parseInt(params.limit)),
                    data: res.data.data,
                },
            })
        }
        catch (e) {
            console.log(e)
        }
    }

    showHideColumn = (column_index) => {
        const columns = this.state.cdt.columns;
        columns[column_index].visibility = !columns[column_index].visibility;
        this.setState({ ctd: { ...this.state.ctd, columns } })
    }

    exportExcel = async () => {

        const excelData = this.state.cdt.data.map((item) => {
            const row = {};
            this.state.cdt.columns
                .filter((column) => column.visibility)
                .map((column) => {

                    if (column.column == 'Photo') {
                        row[column.name] = item[column.column] == 0 ? 'NO' : 'YES';
                    }
                    else {
                        row[column.name] = item[column.column];
                    }
                });
            return row;
        });

        const fileName = 'Evaluation Analysis Report' + moment().format('DD-MM-YYYY');
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
        const ws = XLSX.utils.json_to_sheet(excelData);

        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });

        FileSaver.saveAs(data, fileName + fileExtension);
    }

    getMon = (dateEmployee) => {
        const dateObject = new Date(dateEmployee);

        // getMonth() returns a value between 0 and 11, where 0 is January and 11 is December
        const month = dateObject.getMonth() + 1; // Adding 1 to convert from 0-based to 1-based index

        const monthNames = [
            "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];

        return monthNames[month]

    }
    renderCell = (column_data, column_id, column_index, dataObj) => {

        const { authData } = this.props;
        const { center_id } = authData.centerData;

        let tdData;
        if (column_id == 'child_sen') {
            tdData = column_data == 0 ? 'NO' : 'YES';
        } else if (column_id == 'marital_status') {
            tdData = column_data == 0 ? 'NO' : 'YES';
        }
        else if (column_id == 'appraisalempl_date') {
            tdData = this.getMon(column_data)
        }
        // else if (column_id == 'joining_date') {
        //
        //     let appraisalEmployeeDate = dataObj.appraisalempl_date
        //     let joiningDate = column_data
        //
        //     let currentTime = new Date(appraisalEmployeeDate).getTime();
        //     let birthDateTime= new Date(joiningDate).getTime();
        //     let difference = Math.abs(currentTime - birthDateTime)
        //     var ageInYears=difference/(1000*60*60*24*365.25)
        //
        //     tdData = Math.round(ageInYears * 10 )/ 10
        // }
        else {
            tdData = column_data;
        }



        return <td key={column_index}>{tdData}</td>;
    }

    handleGetAllTerm = async () => {
        try {
            const res = await GET('appraisal/get-terms-centerlic')
            if (res) {
                this.setState({
                    termData: res.data.map(term => {
                        return {
                            label: term.term_name,
                            value: term.term_id,
                            status: term.term_active
                        }
                    }),
                });
            }
        }
        catch (e) {
            console.log(e)
        }
    }
    handleGetAllAgeGroup = async () => {
        try {
            const res = await GET('appraisal/get-age-groups')
            if (res) {
                this.setState({
                    ageGroupData: res.data.map(item => {
                        return {
                            label: item.agegroup_code,
                            value: item.agegroup_id
                        }
                    })
                })
            }
        }
        catch (e) {
            console.log(e)
        }
    }
    handleGetAllSubject = async (obj) => {
        this.setState({ selectedAgeGroup: obj })
        try {
            const res = await GET('appraisal/get-assigned-subjects', {
                params: {
                    agegroup_id: obj.value
                }
            })
            if (res) {
                let uniqueSubject = uniqueByAttribute2(res.data, ['acs_id']);
                this.setState({
                    subjectData: uniqueSubject.map(item => {
                        return {
                            label: item.acs_name,
                            value: item.acs_id,
                        }
                    })
                })
            }

        }
        catch (e) {
            console.log(e)
        }
    }
    handleOpenModal = () => {
        this.setState({ generalModalAllEmployeeStatus: true })
        const employeeScore = this.state.cdt.data.map(item => {
            return {
                name: item.employee_name,
                score: item.score
            }
        })
        this.setState({ employeeScoreResult: employeeScore })
    }
    handleCloseModal = () => {
        this.setState({ generalModalAllEmployeeStatus: false })
    }
    render() {
        console.log('this.state', this.state)
        const { cdt } = this.state
        const data = {
            labels: this.state.employeeScoreResult.map(item => item.name),
            datasets: [
                {
                    label: 'Employee Scores',
                    data: this.state.employeeScoreResult.map(item => item.score),
                    backgroundColor: brand_colors[this.props.defaultTheme.theme_id].sidebar_bg,
                    borderWidth: 1,
                },
            ],
        };

        return (
            <>
                <div className='grey-section'>
                    <Row className='align-items-center'>
                        <Col md={11}>
                            <Row className='align-items-center'>
                                {/* DATE */}
                                <Col md={3} className='position-relative pe-0 mt-1'>
                                    <InputGroup style={{ marginTop: 20 }}>
                                        <Form.Control
                                            onClick={() => this.setState({ showDateRange: true })}
                                            style={{
                                                paddingLeft: 20,
                                                borderTopLeftRadius: 20,
                                                borderBottomLeftRadius: this.state.showDateRange ? 7 : 20,
                                            }}
                                            value={
                                                moment(this.state.selectedDateRange.startDate).format('DD-MM-YYYY') + ' -- ' + moment(this.state.selectedDateRange.endDate).format('DD-MM-YYYY')
                                            }
                                            readOnly
                                        />
                                        <Button variant={this.state.showDateRange ? 'danger' : 'primary'}
                                            style={{ width: 40 }}
                                            onClick={() => this.setState({ showDateRange: !this.state.showDateRange })}
                                            id="button-addon2">
                                            <FontAwesomeIcon icon={this.state.showDateRange ? faXmark : faCalendarAlt} style={{ fontSize: 16, color: brand_colors[this.props.defaultTheme.theme_id].color8, }} />
                                        </Button>
                                    </InputGroup>
                                    {this.state.showDateRange &&
                                        <div style={{
                                            backgroundColor: '#fff',
                                            padding: 10,
                                            position: 'absolute',
                                            border: '#F2F2F2 2px solid',
                                            zIndex: 1,
                                            top: 58,
                                            borderRadius: 7
                                        }}>
                                            <DateRangePicker
                                                ranges={[this.state.selectedDateRange]}
                                                onChange={(date) => {

                                                    this.setState({
                                                        selectedDateRange: { startDate: new Date(date.selection.startDate), endDate: new Date(date.selection.endDate), key: 'selection' }
                                                    })
                                                }}
                                                style={{ backgroundColor: '#fff' }}
                                            />
                                        </div>
                                    }
                                </Col>
                                <Col md={3} className='pe-0 custom-styled-form'>
                                    <Form.Label>{t('Term')}</Form.Label>
                                    <Select
                                        classNamePrefix='custom-styled-select'
                                        placeholder={t('Select Term')}
                                        options={this.state.termData}
                                        // onChange={this.handleGroupChange ,this.setState({ showDateRange: false }) }
                                        onChange={(e) => {
                                            // this.handleGroupChange(e);
                                            this.setState({ selectedTerm: e });
                                        }}
                                        value={this.state.selectedTerm}
                                        menuPortalTarget={document.body}
                                        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                        formatOptionLabel={option => (
                                            <div className='d-flex align-items-center'>
                                                <span style={{ fontSize: 16 }} className='me-1'>{option.label}</span>
                                                {option.status == 1 && <Badge pill bg="success">{t('Active')}</Badge>}
                                            </div>
                                        )}
                                    />
                                </Col>
                                <Col md={3} className='pe-0 custom-styled-form'>
                                    <Form.Label>{t('Grade')}</Form.Label>
                                    <Select
                                        classNamePrefix='custom-styled-select'
                                        placeholder={t('Select Grade')}
                                        options={this.state.ageGroupData}
                                        onChange={(e) => {
                                            this.handleGetAllSubject(e);
                                            this.setState({ selectedAgeGroup: e });
                                        }}
                                        value={this.state.selectedAgeGroup}
                                        menuPortalTarget={document.body}
                                        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                    />
                                </Col>
                                <Col md={3} className='pe-0 custom-styled-form'>
                                    <Form.Label>{t('Subject')}</Form.Label>
                                    <Select
                                        classNamePrefix='custom-styled-select'
                                        placeholder={t('Select Subject')}
                                        options={this.state.subjectData}
                                        onChange={(obj) => { this.setState({ selectedSubject: obj }) }}
                                        value={this.state.selectedSubject}
                                        defaultValue={this.state.selectedTerm1}
                                        menuPortalTarget={document.body}
                                        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col style={{ marginTop: 15 }}>
                            <Button variant={'success'}
                                style={{
                                    width: '100%',
                                    borderTopRightRadius: 20,
                                    borderBottomRightRadius: 20,
                                }}
                                onClick={() => this.fetch({
                                    showStatus: this.state.showStatus,
                                    search: this.state.cdt.search,
                                    limit: this.state.cdt.rows,
                                    order: this.state.cdt.order,
                                    dir: this.state.cdt.dir,
                                    page: this.state.cdt.page
                                })}>
                                {this.state.fetching
                                    ? <Spinner as='span' animation='grow' size='sm' role='status' aria-hidden='true' />
                                    : <FontAwesomeIcon icon={faSearch} style={{ fontSize: 16, color: brand_colors[this.props.defaultTheme.theme_id].color8, }} />
                                }
                            </Button>
                        </Col>
                    </Row>
                </div>
                <Card
                    className='border-0'
                    style={{ borderRadius: '5px' }}>
                    <Card.Header style={{
                        backgroundColor: '#fff',
                        borderTopLeftRadius: '20px',
                        borderTopRightRadius: '20px',
                    }}>
                        <Row>
                            <Col md={5} className='d-flex align-items-center'>
                                <ButtonGroup size='sm' className='me-3 float-left'>
                                    <Button onClick={() => this.exportExcel()}>
                                        <FontAwesomeIcon icon={faFileExcel} /> {t('Excel')}
                                    </Button>
                                    <DropdownButton
                                        autoClose={'outside'}
                                        size='sm'
                                        as={ButtonGroup}
                                        title={t('Column Visibility')}>
                                        {this.state.cdt.columns.map((column, column_index) => (
                                            <Dropdown.Item key={column_index} className={column.visibility ? 'column-name-item' : 'column-name-item active'} onClick={() => this.showHideColumn(column_index)}>
                                                <FontAwesomeIcon icon={column.visibility ? faEye : faEyeSlash} /> {column.name}
                                            </Dropdown.Item>
                                        ))}
                                    </DropdownButton>
                                </ButtonGroup>
                                <div style={{ display: 'inline-block' }}>
                                    {t('Show')}
                                    <Form.Select
                                        value={this.state.cdt.rows}
                                        size="sm"
                                        onChange={(e) => this.fetch({
                                            search: this.state.cdt.search,
                                            limit: e.target.value,
                                            order: this.state.cdt.order,
                                            dir: this.state.cdt.dir,
                                            page: this.state.cdt.page
                                        })}
                                        style={{ width: 70, marginInline: 5, display: 'inline-block' }}>
                                        {this.state.cdt.rowsOptions.map((row, row_index) => (<option key={row_index} >{row}</option>))}
                                    </Form.Select>
                                    {t('Records')}
                                </div>
                            </Col>
                            <Col md={1}>
                                <Button variant={'success'}
                                    onClick={() => this.handleOpenModal()}>
                                    Graph
                                </Button>
                            </Col>
                            <Col md={6} className='d-flex justify-content-end'>
                                <div className='cdt-search'>
                                    <input type='text'
                                        ref={this.searchFieldRef}
                                        defaultValue={this.state.cdt.search}
                                        onChange={(e) => this.fetch({
                                            search: e.target.value,
                                            limit: this.state.cdt.rows,
                                            order: this.state.cdt.order,
                                            dir: this.state.cdt.dir,
                                            page: 0
                                        })}
                                        placeholder={t('Search...')} />
                                    <button
                                        onClick={() => {
                                            this.fetch({
                                                search: '',
                                                limit: this.state.cdt.rows,
                                                order: this.state.cdt.order,
                                                dir: this.state.cdt.dir,
                                                page: 0
                                            });
                                            this.searchFieldRef.current.value = '';
                                        }}>
                                        <FontAwesomeIcon
                                            icon={this.state.cdt.search == '' ? faSearch : faXmark}
                                            color={brand_colors[this.props.defaultTheme.theme_id].color18}
                                        />
                                    </button>
                                </div>
                            </Col>

                        </Row>
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            {this.state.cdt.fetching &&
                                <Col md={12} className='py-5 d-flex flex-column align-items-center'>
                                    <Spinner as='span' animation='grow' size='sm' /> {t('Loading Data')}
                                </Col>
                            }
                            {!this.state.cdt.fetching && this.state.cdt.data.length === 0 &&
                                <Col md={12} className='py-5 d-flex flex-column align-items-center'>
                                    {t('No Data Found')}
                                </Col>
                            }
                            {!this.state.cdt.fetching && this.state.cdt.data.length !== 0 &&

                                <Col md={12} className='pt-2'>
                                    <div id="datatable-list" className='mb-3' style={{ width: 'initial' }}>
                                        <Table responsive className='students-master-report'>
                                            {renderTableHead(this)}
                                            {renderTableBody(this)}
                                        </Table>
                                    </div>
                                </Col>
                            }
                        </Row>
                    </Card.Body>
                </Card>

                <Modal
                    size='xl'
                    show={this.state.generalModalAllEmployeeStatus}
                    onHide={this.handleCloseModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>{t('Evaluation Analysis Report ')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className='custom-styled-form'>
                            <strong>{this.state.selectedDateRange &&
                                moment(this.state.selectedDateRange.endDate).format('YYYY-MM-DD')
                                + '----' +
                                moment(this.state.selectedDateRange.startDate).format('YYYY-MM-DD')
                            }
                            </strong>
                            <strong>{this.state.selectedTerm &&
                                this.state.selectedTerm.label}
                            </strong>
                            <strong>{this.state.selectedAgeGroup &&
                                this.state.selectedAgeGroup.label}
                            </strong>
                            <strong>{this.state.selectedSubject &&
                                this.state.selectedSubject.label}
                            </strong>
                        </Row>
                        <hr />

                        <Card
                            className='border-0'
                            style={{ borderRadius: '20px', marginBottom: 20 }}>
                            <Card.Body>
                                <div className='mb-1 text-center'>
                                    <strong>{t('Employees')}</strong>
                                </div>
                                <Row>
                                    <Col md={12}>
                                        <div>
                                            <Bar options={barChartOptions} data={data} height={305} />
                                        </div>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>

                    </Modal.Body>
                </Modal>

            </>
        );
    }
}

const mapStateToProps = (state) => ({
    authData: state.auth.authData,
    selectedClass: state.selectedClass.data,
    defaultLanguage: state.language.defaultLanguage,
    defaultTheme: state.theme.defaultTheme,
});


export default connect(mapStateToProps, null)(EvaluationAnalysisReport);

