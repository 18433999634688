import React, { Component } from 'react';
import { Container, Card, Row, Col, ButtonGroup, Button, DropdownButton, Dropdown, Form, Modal, Spinner, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faCheckCircle, faSearch, faUser, faXmark, faLayerGroup, faHandsHoldingChild, faPeopleGroup, faListCheck, faPrint, faPencil, faTrash, faCheckDouble, faSortDown, faSortUp, faThumbsUp, faThumbsDown, faArrowsTurnToDots } from '@fortawesome/free-solid-svg-icons';
import { faEye, faEyeSlash, faFileExcel, faFilePdf } from '@fortawesome/free-regular-svg-icons';
import { t } from '../../helpers/translation_helper';
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import NavBar from '../_partials/NavBar/_NavBar';
import Header from '../_partials/Header/_Header';
import { connect } from 'react-redux';
import moment from 'moment';
import { brand_colors } from '../../helpers/brand_colors_helper';
import { GET, POST } from '../../api';
import { toast } from 'react-toastify';
import { handleFormErrors } from '../../helpers/form_helpers';
import { setAddEditClassModal } from '../../redux/slices/addEditClassModalSlice';
import AddEditClass from '../_common/AddEditClass/_AddEditClass';
import Select from 'react-select'
import imgDescription from '../../assets/images/description.png';
import SweetAlert from 'react-bootstrap-sweetalert';
import { renderTableBody, renderTableHead } from '../../helpers/table_helper';
var Chance = require('chance');
var chance = new Chance();


class Classes extends Component {
    constructor(props) {
        super(props);

        this.searchFieldRef = React.createRef();

        this.state = {
            deleteModal: { show: false, id: 0, action: () => { }, toggle: (deleteModal) => this.setState({ deleteModal: deleteModal }) },
            modalMoveClassGrade: {
                show: false,
                toggle: (title = null, classData = null) => this.setState({ modalMoveClassGrade: { ...this.state.modalMoveClassGrade, show: !this.state.modalMoveClassGrade.show, title, classData, agegroup: (classData) ? { value: classData.agegroup_id, label: classData.agegroup_code } : null } }),
                title: 'Move to Grade',
                save: this.updateClassAgeGroup,
                saving: false,
                classData: null,
                agegroup: null,
                setAgeGroup: (agegroup) => this.setState({ modalMoveClassGrade: { ...this.state.modalMoveClassGrade, agegroup } })
            },
            working: false,
            cdt: {
                fetching: false,
                search: '',
                rows: 10,
                offset: 0,
                order: 'c.class_id',
                dir: 'DESC',
                lastPage: 0,
                page: 0,
                totalRecords: 0,
                rowsOptions: [5, 10, 25, 50, 'All'],
                columns: [
                    {
                        id: 'c.class_id',
                        column: 'class_id',
                        name: 'Actions',
                        visibility: true
                    },
                    {
                        id: 'c.class_theme',
                        column: 'class_theme',
                        name: 'Name',
                        visibility: true
                    },
                    {
                        id: 'c.agegroup_code',
                        column: 'agegroup_code',
                        name: 'Grade',
                        visibility: true
                    },
                ],
                data: [],
            },
            AgeGroupData: {
                openSearch: false,
                toggleSearch: () => this.setState({ AgeGroupData: { ...this.state.AgeGroupData, openSearch: !this.state.AgeGroupData.openSearch, search: '' } }, () => {
                    if (!this.state.AgeGroupData.openSearch) {
                        this.getAgeGroup()
                    }
                }),
                changeSearch: (search = '') => this.setState({ AgeGroupData: { ...this.state.AgeGroupData, search } }, () => this.getAgeGroup()),
                search: '',
                orderName: 'Name ASC',
                order: 'agegroup.agegroup_code',
                dir: 'ASC',
                changeOrder: (orderName = 'Name ASC', order = 'agegroup.agegroup_code', dir = 'DESC') => this.setState({ AgeGroupData: { ...this.state.AgeGroupData, orderName, order, dir } }, () => this.getAgeGroup()),
                limit: 100,
                offset: 0,
                data: [],
                total_count: 0,
                total_count_filtered: 0,
                refreshing: false,
                finished: false,
            },
        }
    }

    componentDidMount() {
        const {
            search,
            rows: limit,
            order,
            dir
        } = this.state.cdt;
        this.fetch(search, limit, order, dir, 0);
        this.getAgeGroup()
    }

    fetch = async (search, limit, order, dir, page) => {

        this.setState({
            cdt: {
                ...this.state.cdt,
                fetching: true,
            }
        });

        if (this.fetchReq) {
            this.fetchReq.abort();
        }
        this.fetchReq = new AbortController();

        try {
            const res = await GET('classes/get', {
                signal: this.fetchReq.signal,
                params: {
                    search,
                    order,
                    dir,
                    limit,
                    offset: parseInt(page) * parseInt(limit)
                },
            });

            if (res.status === 200) {
                this.setState({
                    cdt: {
                        ...this.state.cdt,
                        fetching: false,
                        search,
                        rows: parseInt(limit),
                        offset: parseInt(page) * parseInt(limit),
                        order,
                        dir,
                        page,
                        totalRecords: parseInt(res.data.total_count_filtered),
                        lastPage: Math.ceil(parseInt(res.data.total_count_filtered) / parseInt(limit)),
                        data: res.data.data.map((item) => {
                            return {
                                uuid: chance.guid(),
                                ...item,
                            }
                        }),
                    }
                });
            }

        } catch (err) {
            console.log('err', err)
            this.setState({
                cdt: {
                    ...this.state.cdt,
                    fetching: false,
                }
            });

        }
    }

    showHideColumn = (column_index) => {
        const columns = this.state.cdt.columns;
        columns[column_index].visibility = !columns[column_index].visibility;
        this.setState({ ctd: { ...this.state.ctd, columns } })
    }

    handleOpenFormModal = () => {
        this.setState({
            showFormModal: true
        });
    }

    handleCloseFormModal = () => {
        this.setState({
            showFormModal: false,
            isEditing: false,
            ...this.defaultFormFields
        });
    }

    getAgeGroup = async () => {
        if (this.getAgeGroupReq) {
            this.getAgeGroupReq.abort();
        }
        this.getAgeGroupReq = new AbortController();
        this.setState({
            AgeGroupData: {
                ...this.state.AgeGroupData,
                refreshing: true
            }
        })
        const res = await GET('age-group/get',
            {
                params: {
                    search: this.state.AgeGroupData.search,
                    order: this.state.AgeGroupData.order,
                    dir: this.state.AgeGroupData.dir,
                    limit: this.state.AgeGroupData.limit,
                    offset: this.state.AgeGroupData.offset,
                },
                signal: this.getAgeGroupReq.signal,
            }
        );

        this.setState({
            AgeGroupData: {
                ...this.state.AgeGroupData,
                data: res.data.data,
                total_count: res.data.total_count,
                total_count_filtered: res.data.total_count_filtered,
                refreshing: false,
                finished: true
            },
        });

    };

    updateClassAgeGroup = async () => {

        if (this.updateClassAgeGroupReq) {
            this.updateClassAgeGroupReq.abort();
        }
        this.updateClassAgeGroupReq = new AbortController();
        let modalMoveClassGrade = this.state.modalMoveClassGrade
        if (modalMoveClassGrade.classData.agegroup_id == modalMoveClassGrade.agegroup.value) {
            toast.warning('Grade not changed, Please select a grade to move Class to that Grade.');
            return
        }

        this.setState({
            modalMoveClassGrade: {
                ...this.state.modalMoveClassGrade,
                saving: true
            }
        })
        const res = await GET('classes/update-age-group', {
            params: {
                class_id: modalMoveClassGrade.classData.class_id,
                agegroup_id: modalMoveClassGrade.agegroup.value,
            },
            signal: this.updateClassAgeGroupReq.signal,
        });

        if (res) {
            console.log('res', res);
            this.setState({
                modalMoveClassGrade: {
                    ...this.state.modalMoveClassGrade,
                    saving: false,
                    show: false
                }
            }, () => {
                this.fetch(this.state.cdt.search, this.state.cdt.rows, this.state.cdt.order, this.state.cdt.dir, this.state.cdt.page)
            })
        }
    }

    edit = (data) => {
        this.props.setAddEditClassModal({ showModal: true, agegroup_id: data.agegroup_id, gcycle_id: (data.gcycle_id) ? data.gcycle_id : 0, class_id: data.class_id, isEditing: true })
    }

    delete = (data) => {
        this.setState({
            deleteModal: {
                ...this.state.deleteModal, show: true, action: () => {
                    GET('classes/delete', {
                        params: {
                            class_id: data.class_id
                        },
                    }).then((res) => {
                        if (res.data.type) {
                            this.refreshClasses()
                        }
                        toast[res.data.messageType](t(res.data.message));
                        this.props.deleteModal.toggle({ ...this.props.deleteModal, show: false, id: null, action: () => { } })
                    })
                }
            }
        })
    }

    exportExcel = async () => {

        const excelData = this.state.cdt.data.map((item) => {
            const row = {};
            this.state.cdt.columns
                .filter((column) => column.visibility)
                .map((column) => {
                    if (column.name !== 'Actions') {
                        row[column.name] = item[column.column];
                    }
                });
            return row;
        });

        const fileName = 'Classes Export ' + moment().format('DD-MM-YYYY');
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
        const ws = XLSX.utils.json_to_sheet(excelData);

        const cellStyle = {
            font: {
                bold: true,
            },
        }

        Array.from('ABC').map((letter) => {
            ws[letter + "1"].s = cellStyle;
        });

        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });

        FileSaver.saveAs(data, fileName + fileExtension);
    }

    toggleClassStatus = async (uuid) => {
        let data = this.state.cdt.data;
        let findIndex = data.findIndex(item => item.uuid === uuid);

        console.log('findIndex', data[findIndex]);

        data[findIndex].status = !data[findIndex].status;

        this.setState({
            cdt: {
                ...this.state.cdt,
                data
            }
        });

        toast.success('Class status updated successfully!');

        try {
            const fData = new FormData();
            fData.append('class_id', data[findIndex].class_id);

            const res = await POST('classes/toggle-status', fData);

            console.log('res', res);
        } catch (err) {
            console.log(err);
        }

    }

    renderCell = (column_data, column_id, column_index, dataObj) => {
        const { authData } = this.props;
        const { center_id } = authData.centerData;

        let tdData;

        // ----------------------------------------------------------
        if (column_id == 'class_id') { // Action Buttons
            tdData = <>
                <Button
                    title={t('Edit')}
                    onClick={() => this.edit(dataObj)}
                    variant="warning" size='sm' className='me-1'>
                    <FontAwesomeIcon icon={faPencil} />
                </Button>
                <Button
                    title={t('Delete')}
                    onClick={() => this.delete(dataObj)}
                    variant="danger" size='sm' className='me-1'>
                    <FontAwesomeIcon icon={faTrash} />
                </Button>
                <Button
                    title={t('Move Class Grade')}
                    onClick={() => this.state.modalMoveClassGrade.toggle('Move to Grade', dataObj)}
                    variant="danger" size='sm' className='me-1' style={{ backgroundColor: brand_colors[this.props.defaultTheme.theme_id].color2 }}>
                    <FontAwesomeIcon icon={faArrowsTurnToDots} />
                </Button>
                <Button
                    onClick={() => this.toggleClassStatus(dataObj.uuid)}
                    variant={dataObj.status == 1 ? 'success' : 'danger'} size='sm' className='me-1'>
                    <FontAwesomeIcon icon={dataObj.status == 1 ? faThumbsUp : faThumbsDown} />
                </Button>
            </>;
            // -----------------------------------------------------------
        } else {

            tdData = column_data;
        }


        return <td key={column_index}>{tdData}</td>;
    }

    refreshClasses = () => {
        this.fetch(this.state.cdt.search, this.state.cdt.rows, this.state.cdt.order, this.state.cdt.dir, this.state.cdt.page)
    }

    render() {
        let { modalMoveClassGrade, AgeGroupData, deleteModal, cdt } = this.state;

        return (
            <Container fluid>
                <div id='iedu-layout'>
                    <NavBar />
                    <div id='page-content'>
                        <Header lite={true} heading={t('Classes')} backBtn={true} />
                        <div className='grey-section'>
                            <Card
                                className='border-0'
                                style={{ borderRadius: '20px' }}>
                                <Card.Body>
                                    <Row>
                                        <Col md={6}>
                                            <ButtonGroup size='sm' className='me-3 float-left'>
                                                {/* <Button><FontAwesomeIcon icon={faFilePdf} /> {t('PDF')}</Button> */}
                                                <Button onClick={() => this.exportExcel()}>
                                                    <FontAwesomeIcon icon={faFileExcel} /> {t('Excel')}
                                                </Button>
                                                {/* <Button><FontAwesomeIcon icon={faPrint} /> {t('Print')}</Button> */}
                                                <DropdownButton
                                                    autoClose={'outside'}
                                                    size='sm'
                                                    as={ButtonGroup}
                                                    title={t('Column Visibility')}>
                                                    {this.state.cdt.columns.map((column, column_index) => (
                                                        <Dropdown.Item key={column_index} className={column.visibility ? 'column-name-item' : 'column-name-item active'} onClick={() => this.showHideColumn(column_index)}>
                                                            <FontAwesomeIcon icon={column.visibility ? faEye : faEyeSlash} /> {column.name}
                                                        </Dropdown.Item>
                                                    ))}
                                                </DropdownButton>
                                            </ButtonGroup>
                                            <div style={{ display: 'inline-block' }}>
                                                {t('Show')}
                                                <Form.Select
                                                    value={this.state.cdt.rows}
                                                    size="sm"
                                                    onChange={(e) => this.fetch(this.state.cdt.search, e.target.value, this.state.cdt.order, this.state.cdt.dir, this.state.cdt.page)}
                                                    style={{ width: 70, marginInline: 5, display: 'inline-block' }}>
                                                    {this.state.cdt.rowsOptions.map((row, row_index) => (<option key={row_index} >{row}</option>))}
                                                </Form.Select>
                                                {t('Records')}
                                            </div>
                                        </Col>
                                        <Col md={6} className='d-flex justify-content-end'>
                                            <div className='cdt-search'>
                                                <input type='text'
                                                    ref={this.searchFieldRef}
                                                    defaultValue={this.state.cdt.search}
                                                    onChange={(e) => this.fetch(e.target.value, this.state.cdt.rows, this.state.cdt.order, this.state.cdt.dir, 0)}
                                                    placeholder={t('Search...')} />
                                                <button
                                                    onClick={() => {
                                                        this.fetch('', this.state.cdt.rows, this.state.cdt.order, this.state.cdt.dir, 0);
                                                        this.searchFieldRef.current.value = '';
                                                    }}>
                                                    <FontAwesomeIcon
                                                        icon={this.state.cdt.search == '' ? faSearch : faXmark}
                                                        color={brand_colors[this.props.defaultTheme.theme_id].color18}
                                                    />
                                                </button>
                                            </div>
                                            {/*! WE NEED AGEGROUP SELECTOR FIRST */}
                                            {/* <button
                                                onClick={() => this.props.setAddEditClassModal({ showModal: true, agegroup_id, gcycle_id: (gcycle_id) ? gcycle_id.gcycle_id : 0, class_id: 0, isEditing: false })}
                                                className='btn btn-success btn-sm ms-3'><FontAwesomeIcon icon={faPlus} color={brand_colors[this.props.defaultTheme.theme_id].color8} style={{ fontSize: 12 }} />{' '}{t('Add classes')}</button> */}
                                        </Col>
                                        {this.state.cdt.fetching &&
                                            <Col md={12} className='py-5 d-flex flex-column align-items-center'>
                                                <Spinner as='span' animation='grow' size='sm' /> {t('Loading Data')}
                                            </Col>
                                        }
                                        {!this.state.cdt.fetching && this.state.cdt.data.length === 0 &&
                                            <Col md={12} className='py-5 d-flex flex-column align-items-center'>
                                                {t('No Data Found')}
                                            </Col>
                                        }
                                        {!this.state.cdt.fetching && this.state.cdt.data.length !== 0 &&
                                            <Col md={12} className='pt-2'>
                                                <div id="datatable-list" className='mb-3'>
                                                    <Table responsive>
                                                        {renderTableHead(this)}
                                                        {renderTableBody(this)}
                                                    </Table>
                                                </div>
                                                {this.state.cdt.rows < this.state.cdt.totalRecords &&
                                                    <ButtonGroup className='pagination'>
                                                        {Array.from({ length: this.state.cdt.lastPage }).map((_, index) => (
                                                            <Button
                                                                key={index}
                                                                variant="light"
                                                                onClick={() => this.fetch(this.state.cdt.search, this.state.cdt.rows, this.state.cdt.order, this.state.cdt.dir, index)}
                                                                className={this.state.cdt.page === index ? 'active' : ''}>{index + 1}</Button>
                                                        ))}
                                                    </ButtonGroup>
                                                }
                                            </Col>
                                        }
                                    </Row>
                                </Card.Body>
                            </Card>
                        </div>
                    </div>
                </div>
                {/* CLASS ADD/EDIT */}
                <AddEditClass hideShowClasses={this.refreshClasses} />

                {/* MODAL MOVE CLASS GRADE */}
                <Modal show={modalMoveClassGrade.show} onHide={() => modalMoveClassGrade.toggle()} size="sm">
                    <Modal.Header closeButton>
                        <Modal.Title>{modalMoveClassGrade.title}</Modal.Title>
                    </Modal.Header>
                    {modalMoveClassGrade.show &&
                        <Modal.Body className='custom-styled-form'>
                            {modalMoveClassGrade.show &&
                                <Form.Group className='mx-1'>
                                    <Form.Label>
                                        <img src={imgDescription} />
                                        {t('Cycle')}
                                    </Form.Label>
                                    <Select value={modalMoveClassGrade.agegroup} options={this.state.AgeGroupData.data.map((v, i) => {
                                        return { label: v.agegroup_code, value: v.agegroup_id }
                                    })} onChange={(obj) => modalMoveClassGrade.setAgeGroup(obj)} />
                                </Form.Group>
                            }
                        </Modal.Body>
                    }
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => modalMoveClassGrade.toggle()}>
                            {modalMoveClassGrade.saving
                                ? <Spinner animation='grow' size='sm' variant='dark' />
                                : t('Close')}
                        </Button>
                        <Button variant="primary" onClick={modalMoveClassGrade.save}>

                            {modalMoveClassGrade.saving
                                ? <Spinner animation='grow' size='sm' variant='dark' />
                                : t('Save Changes')}
                        </Button>
                    </Modal.Footer>
                </Modal>

                <SweetAlert show={this.state.deleteModal.show} warning showCancel confirmBtnText={t('Yes, delete it!')} confirmBtnBsStyle='danger' title={t('Are you sure?')} onConfirm={this.state.deleteModal.action} onCancel={() => this.setState({ deleteModal: { ...this.state.deleteModal, show: false, id: 0, action: () => { } } })} focusCancelBtn>
                    {t('Confirm Delete!')}
                </SweetAlert>
            </Container>
        );
    }
}

const mapStateToProps = (state) => ({
    authData: state.auth.authData,
    defaultLanguage: state.language.defaultLanguage,
    defaultTheme: state.theme.defaultTheme,
});

const mapDispatchToProps = () => ({
    setAddEditClassModal,
});

export default connect(mapStateToProps, mapDispatchToProps())(Classes);
