import React, { Component } from 'react';
import { Container, Card, Row, Col, ButtonGroup, Button, DropdownButton, Dropdown, Form, Modal, Spinner, Table, InputGroup, Badge, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faCheckCircle, faSearch, faUser, faXmark, faLayerGroup, faHandsHoldingChild, faPeopleGroup, faListCheck, faPrint, faPencil, faTrash, faCheckDouble, faSortDown, faSortUp, faThumbsUp, faThumbsDown, faL, faChevronUp, faChevronDown, faTimes, faTimesCircle, faMinusCircle } from '@fortawesome/free-solid-svg-icons';
import { faCalendar, faEye, faEyeSlash, faFileExcel, faFilePdf } from '@fortawesome/free-regular-svg-icons';
import { t } from '../../helpers/translation_helper';
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import Select from 'react-select';
import { connect } from 'react-redux';
import moment from 'moment';
import { brand_colors } from '../../helpers/brand_colors_helper';
import { GET, POST } from '../../api';
import { toast } from 'react-toastify';
import { handleFormErrors } from '../../helpers/form_helpers';
import { DateRangePicker } from 'react-date-range';
import axios from 'axios';
import Pagination from '../_partials/Pagination';
import SweetAlert from 'react-bootstrap-sweetalert';
import { changeColorOpacity, DDL_ExamstypegrpTypes, DDL_ExamstypegrpTypes2, uniqueByAttribute, uniqueByAttribute2 } from '../../helpers/general_helpers';
import { renderTableBody, renderTableHead } from '../../helpers/table_helper';
var Chance = require('chance');
var chance = new Chance();

class TermAgegroupAssessment extends Component {

    searchFieldRef = React.createRef();
    state = {
        DDL_examstypegrp_types: DDL_ExamstypegrpTypes(),
        DDL_examstypegrp_types2: DDL_ExamstypegrpTypes2(),
        deleteModal: { show: false, id: 0, action: () => { } },
        modalTermAgegroup: {
            show: false,
            title: 'Assessment Percentage',
            toggle: (agegroup_id = null, acs_id = null) => this.setState({ modalTermAgegroup: { ...this.state.modalTermAgegroup, show: !this.state.modalTermAgegroup.show } }, () => {
                if (this.state.modalTermAgegroup.show) {
                    this.getTermAgegroupAssessment(agegroup_id, acs_id)
                }
            }),
            save: () => this.saveTermAgegroupAssessment()
        },
        agegroup: [],
        examstypegrp: [],
        cdt: {
            fetching: false,
            search: '',
            uniqueAgegroups: [],
            columns: [
                {
                    id: 'term_linked_agegroup_assessment.tlaa_id',
                    column: 'tlaa_id',
                    name: 'Actions',
                    visibility: true
                },
                {
                    id: 'term_linked_agegroup_assessment.agegroup_code',
                    column: 'agegroup_code',
                    name: 'Grade',
                    visibility: true
                },
                {
                    id: 'term_linked_agegroup_assessment.acs_name',
                    column: 'acs_name',
                    name: 'Subject',
                    visibility: true
                },
                {
                    id: 'term_linked_agegroup_assessment.term_d_1',
                    column: 'term_d_1',
                    name: 'Term 1',
                    visibility: true
                },
                {
                    id: 'term_linked_agegroup_assessment.term_d_2',
                    column: 'term_d_2',
                    name: 'Term 2',
                    visibility: true
                },
                {
                    id: 'term_linked_agegroup_assessment.term_d_3',
                    column: 'term_d_3',
                    name: 'Term 3',
                    visibility: true
                },
                {
                    id: 'term_linked_agegroup_assessment.term_d_4',
                    column: 'term_d_4',
                    name: 'Term 4',
                    visibility: true
                },
            ],
            data: [],
        },
        termLinkedAgegroupAssessmentData: {
            edit: false,
            agegroup: null,
            acs_ids: [],
            term_d_1: '0.00',
            term_d_2: '0.00',
            term_d_3: '0.00',
            term_d_4: '0.00',
            details: []
        },
        term_linked_agegroup_assessment_detail: null
    }

    componentDidMount() {
        const {
            search,
        } = this.state.cdt;
        this.fetchAll({
            search
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.authData.centerData.center_id != this.props.authData.centerData.center_id) {
            const {
                search,
            } = this.state.cdt;
            this.fetchAll({
                search
            });
        }
    }

    getTermAgegroupAssessment = async (agegroup_id = null, acs_id = null) => {
        if (this.getTermAgegroupAssessmentReq) {
            this.getTermAgegroupAssessmentReq.abort();
        }
        this.getTermAgegroupAssessmentReq = new AbortController();

        const res = await GET('term-agegroup-assessment/get-term-linked-agegroup-assessment',
            {
                signal: this.getTermAgegroupAssessmentReq.signal,
                params: {
                    agegroup_id: (agegroup_id) ? agegroup_id : null,
                    acs_id: (acs_id) ? acs_id : null
                }
            }
        );
        if (res) {
            let resData = res.data
            let editAgegroup = null
            let editAcs = null

            let agegroup = resData.agegroup.map((v, i) => {
                v.academic_classmain_subject = v.academic_classmain_subject.filter((vv, ii) => {
                    // WHILE EDITING
                    if (acs_id && vv.acs_id == acs_id) {
                        editAcs = { ...vv, label: vv.acs_name, value: vv.acs_id }
                    }

                    let findSubject = resData.data.find((vvv, iii) => vvv.acs_id == vv.acs_id)
                    if (findSubject == undefined) {
                        return vv
                    }
                })

                // WHILE EDITING
                if (agegroup_id && v.agegroup_id == agegroup_id) {
                    editAgegroup = { ...v, label: v.agegroup_code, value: v.agegroup_id }
                }

                return v
            })

            let termLinkedAgegroupAssessmentDataDetails = []
            let examstypegrp = []
            resData.examstypegrp.map((v, i) => {
                let term_linked_agegroup_assessment_detail = (agegroup_id && resData.data.length > 0) ? resData.data[0]['term_linked_agegroup_assessment_detail'] : null
                if (term_linked_agegroup_assessment_detail && term_linked_agegroup_assessment_detail.length > 0) {
                    let f_tlasd = term_linked_agegroup_assessment_detail.find((vv, ii) => vv.examstypegrp_id == v.examstypegrp_id && vv.examstype_id == v.examstype_id)
                    if (f_tlasd) {
                        termLinkedAgegroupAssessmentDataDetails.push({ ...v, tlaa_d_percentage_1: (f_tlasd) ? f_tlasd.tlaa_d_percentage_1 : '0.00', tlaa_d_percentage_2: (f_tlasd) ? f_tlasd.tlaa_d_percentage_2 : '0.00', tlaa_d_percentage_3: (f_tlasd) ? f_tlasd.tlaa_d_percentage_3 : '0.00', tlaa_d_percentage_4: (f_tlasd) ? f_tlasd.tlaa_d_percentage_4 : '0.00', tlaa_d_comment_1: (f_tlasd) ? f_tlasd.tlaa_d_comment_1 : '', tlaa_d_comment_2: (f_tlasd) ? f_tlasd.tlaa_d_comment_2 : '', tlaa_d_comment_3: (f_tlasd) ? f_tlasd.tlaa_d_comment_3 : '', tlaa_d_comment_4: (f_tlasd) ? f_tlasd.tlaa_d_comment_4 : '' })
                    } else {
                        examstypegrp.push({ ...v, label: v.label, value: v.value })
                    }
                } else {
                    examstypegrp.push({ ...v, label: v.label, value: v.value })
                }
            })

            this.setState({
                agegroup: agegroup,
                examstypegrp: examstypegrp,
                termLinkedAgegroupAssessmentData: {
                    ...this.state.termLinkedAgegroupAssessmentData,
                    edit: (agegroup_id && editAgegroup) ? true : false,
                    agegroup: (agegroup_id && editAgegroup) ? { value: editAgegroup.agegroup_id, label: editAgegroup.agegroup_code, agegroup_id: editAgegroup.agegroup_id, agegroup_code: editAgegroup.agegroup_code, ...editAgegroup } : null,
                    acs_ids: (agegroup_id && editAcs) ? [{ value: editAcs.acs_id, label: editAcs.acs_name, acs_id: editAcs.acs_id, acs_name: editAcs.acs_name, ...editAcs }] : [],
                    term_d_1: (agegroup_id && resData.data.length > 0) ? resData.data[0].term_d_1 : '0.00',
                    term_d_2: (agegroup_id && resData.data.length > 0) ? resData.data[0].term_d_2 : '0.00',
                    term_d_3: (agegroup_id && resData.data.length > 0) ? resData.data[0].term_d_3 : '0.00',
                    term_d_4: (agegroup_id && resData.data.length > 0) ? resData.data[0].term_d_4 : '0.00',
                    details: termLinkedAgegroupAssessmentDataDetails
                }
            });
        }
    };

    saveTermAgegroupAssessment = async () => {
        if (this.saveTermAgegroupAssessmentReq) {
            this.saveTermAgegroupAssessmentReq.abort()
        }
        this.saveTermAgegroupAssessmentReq = new AbortController

        let termLinkedAgegroupAssessmentData = JSON.parse(JSON.stringify(this.state.termLinkedAgegroupAssessmentData))
        console.log('termLinkedAgegroupAssessmentData', termLinkedAgegroupAssessmentData);

        let termTotal = parseFloat(termLinkedAgegroupAssessmentData.term_d_1) + parseFloat(termLinkedAgegroupAssessmentData.term_d_2) + parseFloat(termLinkedAgegroupAssessmentData.term_d_3) + parseFloat(termLinkedAgegroupAssessmentData.term_d_4)
        let total_tlaa_d_percentage_1 = 0;
        let total_tlaa_d_percentage_2 = 0;
        let total_tlaa_d_percentage_3 = 0;
        let total_tlaa_d_percentage_4 = 0;
        termLinkedAgegroupAssessmentData.details.map((v, i) => {
            total_tlaa_d_percentage_1 += parseFloat(v.tlaa_d_percentage_1)
            total_tlaa_d_percentage_2 += parseFloat(v.tlaa_d_percentage_2)
            total_tlaa_d_percentage_3 += parseFloat(v.tlaa_d_percentage_3)
            total_tlaa_d_percentage_4 += parseFloat(v.tlaa_d_percentage_4)
        })


        if (!termLinkedAgegroupAssessmentData.agegroup) {
            toast['warning'](t('Please select a Grade!'));
            return
        }
        if (termLinkedAgegroupAssessmentData.acs_ids.length <= 0) {
            toast['warning'](t('Please select at least one Subject!'));
            return
        }
        if (termTotal != 100) {
            toast['warning'](t('All Terms total should be 100!'));
            return
        }
        if (total_tlaa_d_percentage_1 != 100) {
            toast['warning'](t('The total of all Groups in Term 1 should be 100!'));
            return
        }
        if (total_tlaa_d_percentage_2 != 100) {
            toast['warning'](t('The total of all Groups in Term 2 should be 100!'));
            return
        }
        if (total_tlaa_d_percentage_3 != 100) {
            toast['warning'](t('The total of all Groups in Term 3 should be 100!'));
            return
        }
        // if (total_tlaa_d_percentage_4 != 100) {
        //     toast['warning'](t('The total of all Groups in Term 4 should be 100!'));
        //     return
        // }

        termLinkedAgegroupAssessmentData.agegroup_id = termLinkedAgegroupAssessmentData.agegroup?.agegroup_id
        termLinkedAgegroupAssessmentData.acs_ids = termLinkedAgegroupAssessmentData.acs_ids.map((v, i) => v.acs_id)

        let fData = new FormData()
        fData.append('termLinkedAgegroupAssessmentData', JSON.stringify(termLinkedAgegroupAssessmentData))
        let res = await POST('term-agegroup-assessment/save', fData, {
            signal: this.saveTermAgegroupAssessmentReq.signal
        })
        if (res) {
            let resData = res.data
            if (resData.type) {
                this.state.modalTermAgegroup.toggle()
                this.fetchAll({
                    search: this.state.cdt.search,
                })
            }
            toast[resData.messageType](t(resData.message));
        }
    }

    setStateFdata = (i, field, value) => {
        let termLinkedAgegroupAssessmentData = this.state.termLinkedAgegroupAssessmentData
        termLinkedAgegroupAssessmentData.details[i][field] = value
        this.setState({ termLinkedAgegroupAssessmentData })
    }


    fetchAll = async (params) => {
        this.setState({
            cdt: {
                ...this.state.cdt,
                fetching: true,
            }
        });

        if (this.getAllReq) {
            this.getAllReq.abort();
        }
        this.getAllReq = new AbortController();

        try {
            const res = await GET('term-agegroup-assessment/get-all', {
                signal: this.getAllReq.signal,
                params: {
                    search: params.search
                }
            });

            if (res) {
                let data = res.data.data
                let uniqueAgegroups = uniqueByAttribute2(data, ['agegroup_id']);

                this.setState({
                    cdt: {
                        ...this.state.cdt,
                        fetching: false,
                        search: params.search,
                        data: res.data.data.map((item) => {
                            return {
                                uid: chance.guid(),
                                ...item,
                            }
                        }),
                        uniqueAgegroups: uniqueAgegroups
                    }
                });
            }
        } catch (err) {
            console.log('err', err)
            this.setState({
                cdt: {
                    ...this.state.cdt,
                    fetching: false,
                }
            });

        }
    }

    showHideColumn = (column_index) => {
        const columns = this.state.cdt.columns;
        columns[column_index].visibility = !columns[column_index].visibility;
        this.setState({ ctd: { ...this.state.ctd, columns } })
    }

    exportExcel = async () => {

        const excelData = this.state.cdt.data.map((item) => {
            const row = {};
            this.state.cdt.columns
                .filter((column) => column.visibility)
                .map((column) => {
                    if (column.name !== 'Actions') {
                        row[column.name] = item[column.column];
                    }
                });
            return row;
        });

        const fileName = 'Assessment-Term-Grade Export ' + moment().format('DD-MM-YYYY');
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
        const ws = XLSX.utils.json_to_sheet(excelData);

        const cellStyle = {
            font: {
                bold: true,
            },
        }

        Array.from('A').map((letter) => {
            ws[letter + "1"].s = cellStyle;
        });

        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });

        FileSaver.saveAs(data, fileName + fileExtension);
    }

    renderCell = (column_data, column_id, column_index, dataObj) => {
        const { authData } = this.props;
        const { center_id } = authData.centerData;

        let tdData;

        // --------------------------------------------
        if (column_id == 'tlaa_id') { // Action Buttons
            tdData = <>
                {/* <Button
                    onClick={() => this.delete(dataObj)}
                    variant="danger" size='sm' className='me-1'>
                    <FontAwesomeIcon icon={faTrash} />
                </Button> */}
                <Button
                    onClick={() => this.state.modalTermAgegroup.toggle(dataObj.agegroup_id, dataObj.acs_id)}
                    variant="warning" size='sm' className='me-1'>
                    <FontAwesomeIcon icon={faPencil} />
                </Button>
            </>;
        } else if ([].includes(column_id)) {

        } else {
            tdData = column_data;
        }
        return <td key={column_index}>{tdData}</td>;
    }



    render() {
        let { modalTermAgegroup, termLinkedAgegroupAssessmentData, cdt } = this.state
        console.log('getTermAgegroupAssessmentData', this.state);

        let termTotal = parseFloat(termLinkedAgegroupAssessmentData.term_d_1) + parseFloat(termLinkedAgegroupAssessmentData.term_d_2) + parseFloat(termLinkedAgegroupAssessmentData.term_d_3) + parseFloat(termLinkedAgegroupAssessmentData.term_d_4)
        let total_tlaa_d_percentage_1 = 0;
        let total_tlaa_d_percentage_2 = 0;
        let total_tlaa_d_percentage_3 = 0;
        let total_tlaa_d_percentage_4 = 0;
        return (
            <>
                <div className='shadow-box mt-1' style={{
                    marginInline: 5
                }}>
                    <Card
                        className='border-0'
                        style={{ borderRadius: '20px' }}>
                        <Card.Header style={{
                            backgroundColor: '#fff',
                            borderTopLeftRadius: '20px',
                            borderTopRightRadius: '20px',
                            fontWeight: '700'
                        }}>
                            <Row>
                                <Col md={12}>
                                    <Alert variant='info'>
                                        <div className='d-flex flex-row justify-content-between align-items-center'>
                                            <div>{t('Please assign Percentage to each Grade & Subject for terms.')}</div>
                                            <Button variant='info' onClick={() => modalTermAgegroup.toggle()}>
                                                {t('Assign Percentage')}
                                                <FontAwesomeIcon icon={faPlus} className='ms-2' />
                                            </Button>
                                        </div>
                                    </Alert>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6} className='d-flex align-items-center'>
                                    {/* {t('Groups')} */}
                                </Col>
                                <Col md={6} className='d-flex justify-content-end'>
                                    <div className='cdt-search'>
                                        <input type='text'
                                            ref={this.searchFieldRef}
                                            defaultValue={this.state.cdt.search}
                                            onChange={(e) => this.fetchAll({
                                                search: e.target.value,
                                            })}
                                            placeholder={t('Search...')} />
                                        <button
                                            onClick={() => {
                                                this.fetchAll({
                                                    search: '',
                                                });
                                                this.searchFieldRef.current.value = '';
                                            }}>
                                            <FontAwesomeIcon
                                                icon={this.state.cdt.search == '' ? faSearch : faXmark}
                                                color={brand_colors[this.props.defaultTheme.theme_id].color18}
                                            />
                                        </button>
                                    </div>
                                </Col>
                            </Row>
                        </Card.Header>
                        <Card.Body style={{ paddingTop: 8 }}>
                            <Row>
                                {this.state.cdt.fetching &&
                                    <Col md={12} className='py-5 d-flex flex-column align-items-center'>
                                        <Spinner as='span' animation='grow' size='sm' /> {t('Loading Data')}
                                    </Col>
                                }
                                {!this.state.cdt.fetching && this.state.cdt.data.length === 0 &&
                                    <Col md={12} className='py-5 d-flex flex-column align-items-center'>
                                        {t('No Data Found')}
                                    </Col>
                                }
                            </Row>
                            <div className='grey-section'>
                                {!this.state.cdt.fetching && this.state.cdt.data.length !== 0 &&
                                    this.state.cdt.uniqueAgegroups.map((v, i) => {
                                        return <Card className='ass-rep-card'>
                                            <Card.Body>
                                                <div className='d-flex flex-row align-items-center '>
                                                    <div className='d-flex flex-column justify-content-center align-items-start' style={{ width: 150 }}>
                                                        <div><strong>{v.agegroup_code}</strong></div>
                                                        <div style={{ backgroundColor: brand_colors[this.props.defaultTheme.theme_id].color4_light, borderRadius: 7, color: brand_colors[this.props.defaultTheme.theme_id].color4, padding: '0.1rem 0.6rem', fontSize: 14 }}>{v.gcycle_desc}</div>
                                                    </div>
                                                    <div className={'d-flex flex-row flex-wrap'} style={{ flex: 1 }}>
                                                        {this.state.cdt.data.map((vv, ii) => {
                                                            if (vv.agegroup_id == v.agegroup_id) {
                                                                return <div className='me-2 mb-1 cursor-pointer' style={{ fontSize: 18 }} onClick={() => modalTermAgegroup.toggle(vv.agegroup_id, vv.acs_id)}>
                                                                    <div className={(vv.tlaa_id) ? 'badge badge-light-success' : 'badge badge-light-danger'}>
                                                                        <div className='d-flex flex-column justify-content-center'>
                                                                            <FontAwesomeIcon icon={(vv.tlaa_id) ? faCheckCircle : faTimesCircle} className={'mb-1'} />
                                                                            <div>{vv.acs_name}</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }
                                                        })}
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    })
                                }
                            </div>
                        </Card.Body>
                    </Card>
                </div>
                {/* modalTermAgegroup */}
                <Modal show={modalTermAgegroup.show} onHide={() => modalTermAgegroup.toggle()} size="xl">
                    <Modal.Header closeButton>
                        <Modal.Title>{modalTermAgegroup.title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='custom-styled-form'>
                        {modalTermAgegroup.show &&
                            <div className='d-flex flex-column'>
                                <div className='d-flex flex-row flex-wrap align-items-center mb-5 border-bottom pb-2'>
                                    <div style={{ minWidth: 200 }}>
                                        {(termLinkedAgegroupAssessmentData.edit)
                                            ? <div style={{ fontSize: 20, fontWeight: '600' }}>Grade: {termLinkedAgegroupAssessmentData.agegroup.label}</div>
                                            : <Select
                                                placeholder={t('-- Select Grade--')}
                                                options={this.state.agegroup.map((v, i) => { return { label: v.agegroup_code, value: v.agegroup_id, ...v } })}
                                                onChange={(obj) => this.setState({ termLinkedAgegroupAssessmentData: { ...termLinkedAgegroupAssessmentData, agegroup: obj, acs_ids: [] } })}
                                                value={termLinkedAgegroupAssessmentData.agegroup}
                                                menuPortalTarget={document.body}
                                                styles={{ menuPortal: (base) => ({ ...base, zIndex: 99999999999999 }) }}
                                            />
                                        }
                                    </div>
                                    <div className='ms-2'>
                                        {(termLinkedAgegroupAssessmentData.edit)
                                            ? <div style={{ fontSize: 20, fontWeight: '600' }}>Subject: {termLinkedAgegroupAssessmentData.acs_ids[0].label}</div>
                                            : <Select
                                                isMulti={true}
                                                closeMenuOnSelect={false}
                                                placeholder={t('-- Select Subjects--')}
                                                options={this.state.termLinkedAgegroupAssessmentData.agegroup?.academic_classmain_subject.map((v, i) => { return { label: v.acs_name, value: v.acs_id, ...v } })}
                                                onChange={(obj) => this.setState({ termLinkedAgegroupAssessmentData: { ...termLinkedAgegroupAssessmentData, acs_ids: obj } })}
                                                value={termLinkedAgegroupAssessmentData.acs_ids}
                                                menuPortalTarget={document.body}
                                                styles={{ menuPortal: (base) => ({ ...base, zIndex: 99999999999999 }) }}
                                            />
                                        }
                                    </div>

                                </div>
                                <div>
                                    <Table striped>
                                        <thead>
                                            <tr className='table-success'>
                                                <th style={{ width: '16.66%' }}></th>
                                                <th style={{ width: '16.66%' }}>
                                                    <div style={{ fontSize: 20, textAlign: 'center' }}>Term 1</div>
                                                    <InputGroup>
                                                        <Form.Control placeholder={t('%')}
                                                            value={termLinkedAgegroupAssessmentData.term_d_1}
                                                            onChange={(e) => this.setState({ termLinkedAgegroupAssessmentData: { ...termLinkedAgegroupAssessmentData, term_d_1: e.target.value } })} />
                                                        <InputGroup.Text>%</InputGroup.Text>
                                                    </InputGroup>
                                                </th>
                                                <th style={{ width: '16.66%' }}>
                                                    <div style={{ fontSize: 20, textAlign: 'center' }}>Term 2</div>
                                                    <InputGroup>
                                                        <Form.Control placeholder={t('%')}
                                                            value={termLinkedAgegroupAssessmentData.term_d_2}
                                                            onChange={(e) => this.setState({ termLinkedAgegroupAssessmentData: { ...termLinkedAgegroupAssessmentData, term_d_2: e.target.value } })} />
                                                        <InputGroup.Text>%</InputGroup.Text>
                                                    </InputGroup>
                                                </th>
                                                <th style={{ width: '16.66%' }}>
                                                    <div style={{ fontSize: 20, textAlign: 'center' }}>Term 3</div>
                                                    <InputGroup>
                                                        <Form.Control placeholder={t('%')}
                                                            value={termLinkedAgegroupAssessmentData.term_d_3}
                                                            onChange={(e) => this.setState({ termLinkedAgegroupAssessmentData: { ...termLinkedAgegroupAssessmentData, term_d_3: e.target.value } })} />
                                                        <InputGroup.Text>%</InputGroup.Text>
                                                    </InputGroup>
                                                </th>
                                                <th style={{ width: '16.66%' }}>
                                                    <div style={{ fontSize: 20, textAlign: 'center' }}>Term 4</div>
                                                    <InputGroup>
                                                        <Form.Control placeholder={t('%')}
                                                            value={termLinkedAgegroupAssessmentData.term_d_4}
                                                            onChange={(e) => this.setState({ termLinkedAgegroupAssessmentData: { ...termLinkedAgegroupAssessmentData, term_d_4: e.target.value } })} />
                                                        <InputGroup.Text>%</InputGroup.Text>
                                                    </InputGroup>
                                                </th>
                                                <th style={{ width: '16.66%' }}>
                                                    <InputGroup hasValidation>
                                                        <Form.Control type="text" disabled value={termTotal} isInvalid={(termTotal > 100) ? true : false} />
                                                        <InputGroup.Text>%</InputGroup.Text>
                                                        <Form.Control.Feedback type="invalid">
                                                            {t('Total should not be bigger than 100')}
                                                        </Form.Control.Feedback>
                                                    </InputGroup>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td colSpan={6} className='table-info'>
                                                    <div className='d-flex flex-row justify-content-end'>
                                                        <div className='me-2' style={{ width: 400 }}>
                                                            <Select
                                                                placeholder={t('-- Add New Row -- ')}
                                                                options={this.state.examstypegrp.filter((v, i) => {
                                                                    let findUsed = termLinkedAgegroupAssessmentData.details.find((vv, ii) => vv.examstype_id == v.examstype_id)
                                                                    if (!findUsed) {
                                                                        return v
                                                                    }
                                                                })}
                                                                onChange={(obj) => this.setState({ term_linked_agegroup_assessment_detail: obj })}
                                                                value={this.state.term_linked_agegroup_assessment_detail}
                                                                menuPortalTarget={document.body}
                                                                styles={{ menuPortal: (base) => ({ ...base, zIndex: 99999999999999 }) }}
                                                                formatOptionLabel={option => {
                                                                    console.log('option', option);
                                                                    let find_type = this.state.DDL_examstypegrp_types.find((vv) => vv.value == option.examstypegrp_type)
                                                                    let find_type2 = this.state.DDL_examstypegrp_types2.find((vv) => vv.value == option.examstypegrp_type2)
                                                                    return <div>
                                                                        <div>{option.examstype_name} ({option.examstypegrp_name})</div>
                                                                        <span style={{ fontSize: 12, fontWeight: 'bold', backgroundColor: changeColorOpacity(find_type.color, 0.2), color: find_type.color, borderRadius: 7, padding: '0.1rem 0.5rem' }}>{option.examstypegrp_type}</span>
                                                                        <span className='ms-1' style={{ fontSize: 12, fontWeight: 'bold', backgroundColor: changeColorOpacity(find_type2.color, 0.2), color: find_type2.color, borderRadius: 7, padding: '0.1rem 0.5rem' }}>{option.examstypegrp_type2}</span>
                                                                    </div>
                                                                }}
                                                            />
                                                        </div>
                                                        <Button variant="primary" onClick={() => {
                                                            if (!this.state.term_linked_agegroup_assessment_detail) {
                                                                toast['warning'](t('Please select Type first!'))
                                                                return false
                                                            }
                                                            let termLinkedAgegroupAssessmentDataNew = termLinkedAgegroupAssessmentData
                                                            termLinkedAgegroupAssessmentDataNew.details.push({ ...this.state.term_linked_agegroup_assessment_detail, tlaa_d_percentage_1: '0.00', tlaa_d_percentage_2: '0.00', tlaa_d_percentage_3: '0.00', tlaa_d_percentage_4: '0.00', tlaa_d_comment_1: '', tlaa_d_comment_2: '', tlaa_d_comment_3: '', tlaa_d_comment_4: '', })
                                                            this.setState({
                                                                termLinkedAgegroupAssessmentData: termLinkedAgegroupAssessmentDataNew, term_linked_agegroup_assessment_detail: null
                                                            })
                                                        }}>
                                                            <FontAwesomeIcon icon={faPlus} />
                                                        </Button>

                                                    </div>
                                                </td>
                                            </tr>
                                            {termLinkedAgegroupAssessmentData.details.map((v, i) => {
                                                total_tlaa_d_percentage_1 += parseFloat(v.tlaa_d_percentage_1)
                                                total_tlaa_d_percentage_2 += parseFloat(v.tlaa_d_percentage_2)
                                                total_tlaa_d_percentage_3 += parseFloat(v.tlaa_d_percentage_3)
                                                total_tlaa_d_percentage_4 += parseFloat(v.tlaa_d_percentage_4)
                                                let find_type = this.state.DDL_examstypegrp_types.find((vv) => vv.value == v.examstypegrp_type)
                                                let find_type2 = this.state.DDL_examstypegrp_types2.find((vv) => vv.value == v.examstypegrp_type2)
                                                return <tr>
                                                    <td>
                                                        <div>{v.examstype_name} ({v.examstypegrp_name})</div>
                                                        <span style={{ fontSize: 12, fontWeight: 'bold', backgroundColor: changeColorOpacity(find_type.color, 0.2), color: find_type.color, borderRadius: 7, padding: '0.1rem 0.5rem' }}>{v.examstypegrp_type}</span>
                                                        <span className='ms-1' style={{ fontSize: 12, fontWeight: 'bold', backgroundColor: changeColorOpacity(find_type2.color, 0.2), color: find_type2.color, borderRadius: 7, padding: '0.1rem 0.5rem' }}>{v.examstypegrp_type2}</span>
                                                        <div>
                                                            <FontAwesomeIcon icon={faMinusCircle} style={{ color: brand_colors[this.props.defaultTheme.theme_id].color11 }} className='cursor-pointer' title={t('Remove')} onClick={() => {
                                                                let termLinkedAgegroupAssessmentDataNew = termLinkedAgegroupAssessmentData
                                                                termLinkedAgegroupAssessmentDataNew.details.splice(i, 1)
                                                                this.setState({ termLinkedAgegroupAssessmentData: termLinkedAgegroupAssessmentDataNew })
                                                            }} />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <InputGroup className="mb-1">
                                                            <Form.Control placeholder={t('%')}
                                                                value={v.tlaa_d_percentage_1}
                                                                onChange={(e) => this.setStateFdata(i, 'tlaa_d_percentage_1', e.target.value)} />
                                                            <InputGroup.Text>%</InputGroup.Text>
                                                        </InputGroup>
                                                        <Form.Group >
                                                            <Form.Control type="text" placeholder={t('Comment')}
                                                                value={v.tlaa_d_comment_1}
                                                                onChange={(e) => this.setStateFdata(i, 'tlaa_d_comment_1', e.target.value)}
                                                            />
                                                        </Form.Group>
                                                    </td>
                                                    <td>
                                                        <InputGroup className="mb-1">
                                                            <Form.Control placeholder={t('%')}
                                                                value={v.tlaa_d_percentage_2}
                                                                onChange={(e) => this.setStateFdata(i, 'tlaa_d_percentage_2', e.target.value)} />
                                                            <InputGroup.Text>%</InputGroup.Text>
                                                        </InputGroup>
                                                        <Form.Group>
                                                            <Form.Control type="text" placeholder={t('Comment')}
                                                                value={v.tlaa_d_comment_2}
                                                                onChange={(e) => this.setStateFdata(i, 'tlaa_d_comment_2', e.target.value)}
                                                            />
                                                        </Form.Group>
                                                    </td>
                                                    <td>
                                                        <InputGroup className="mb-1">
                                                            <Form.Control placeholder={t('%')}
                                                                value={v.tlaa_d_percentage_3}
                                                                onChange={(e) => this.setStateFdata(i, 'tlaa_d_percentage_3', e.target.value)} />
                                                            <InputGroup.Text>%</InputGroup.Text>
                                                        </InputGroup>
                                                        <Form.Group>
                                                            <Form.Control type="text" placeholder={t('Comment')}
                                                                value={v.tlaa_d_comment_3}
                                                                onChange={(e) => this.setStateFdata(i, 'tlaa_d_comment_3', e.target.value)}
                                                            />
                                                        </Form.Group>
                                                    </td>
                                                    <td>
                                                        <InputGroup className="mb-1">
                                                            <Form.Control placeholder={t('%')}
                                                                value={v.tlaa_d_percentage_4}
                                                                onChange={(e) => this.setStateFdata(i, 'tlaa_d_percentage_4', e.target.value)} />
                                                            <InputGroup.Text>%</InputGroup.Text>
                                                        </InputGroup>
                                                        <Form.Group>
                                                            <Form.Control type="text" placeholder={t('Comment')}
                                                                value={v.tlaa_d_comment_4}
                                                                onChange={(e) => this.setStateFdata(i, 'tlaa_d_comment_4', e.target.value)}
                                                            />
                                                        </Form.Group>
                                                    </td>
                                                    <td></td>
                                                </tr>
                                            })}

                                        </tbody>
                                        <tfoot>
                                            <tr className='table-success'>
                                                <td></td>
                                                <td>
                                                    <InputGroup hasValidation>
                                                        <Form.Control type="text" disabled value={total_tlaa_d_percentage_1} isInvalid={(total_tlaa_d_percentage_1 > 100) ? true : false} />
                                                        <InputGroup.Text>%</InputGroup.Text>
                                                        <Form.Control.Feedback type="invalid">
                                                            {t('Total should not be bigger than 100')}
                                                        </Form.Control.Feedback>
                                                    </InputGroup>
                                                </td>
                                                <td>
                                                    <InputGroup hasValidation>
                                                        <Form.Control type="text" disabled value={total_tlaa_d_percentage_2} isInvalid={(total_tlaa_d_percentage_2 > 100) ? true : false} />
                                                        <InputGroup.Text>%</InputGroup.Text>
                                                        <Form.Control.Feedback type="invalid">
                                                            {t('Total should not be bigger than 100')}
                                                        </Form.Control.Feedback>
                                                    </InputGroup>
                                                </td>
                                                <td>
                                                    <InputGroup hasValidation>
                                                        <Form.Control type="text" disabled value={total_tlaa_d_percentage_3} isInvalid={(total_tlaa_d_percentage_3 > 100) ? true : false} />
                                                        <InputGroup.Text>%</InputGroup.Text>
                                                        <Form.Control.Feedback type="invalid">
                                                            {t('Total should not be bigger than 100')}
                                                        </Form.Control.Feedback>
                                                    </InputGroup>
                                                </td>
                                                <td>
                                                    <InputGroup hasValidation>
                                                        <Form.Control type="text" disabled value={total_tlaa_d_percentage_4} isInvalid={(total_tlaa_d_percentage_4 > 100) ? true : false} />
                                                        <InputGroup.Text>%</InputGroup.Text>
                                                        <Form.Control.Feedback type="invalid">
                                                            {t('Total should not be bigger than 100')}
                                                        </Form.Control.Feedback>
                                                    </InputGroup>
                                                </td>
                                                <td></td>
                                            </tr>
                                        </tfoot>
                                    </Table>
                                </div>
                            </div>
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => modalTermAgegroup.toggle()}>
                            {t('Close')}
                        </Button>
                        <Button variant="primary" onClick={modalTermAgegroup.save}>
                            {t('Save Changes')}
                        </Button>
                    </Modal.Footer>
                </Modal >
                <SweetAlert show={this.state.deleteModal.show} warning showCancel confirmBtnText={t('Yes, delete it!')} confirmBtnBsStyle='danger' title={t('Are you sure?')} onConfirm={this.state.deleteModal.action} onCancel={() => this.setState({ deleteModal: { ...this.state.deleteModal, show: false, id: 0, action: () => { } } })} focusCancelBtn>
                    {t('Confirm Delete!')}
                </SweetAlert>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    authData: state.auth.authData,
    defaultLanguage: state.language.defaultLanguage,
    defaultTheme: state.theme.defaultTheme,
});

export default connect(mapStateToProps, null)(TermAgegroupAssessment);
