import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

export const addEditPurchasesModalSlice = createSlice({
    name: 'addEditPurchasesModal',
    initialState: {
        showModal: false,
        title: 'New Purchases',
        editMode: false,
        servicedetails: [],
        purchases_type: '',
        reg_date: moment().format('YYYY-MM-DD'),
        poMode: false
    },
    reducers: {
        togglePurchasesModal: (state, action) => {
            state.showModal = !state.showModal
            state.title = (action.payload && action.payload.title) ? action.payload.title : 'New Purchases'
            state.reg_date = (action.payload && action.payload.reg_date) ? action.payload.reg_date : moment().format('YYYY-MM-DD')
            state.editMode = (action.payload && action.payload.editMode) ? action.payload.editMode : false
            state.servicedetails = (action.payload && action.payload.servicedetails) ? action.payload.servicedetails : []
            state.old_servicedetails = (action.payload && action.payload.old_servicedetails) ? action.payload.old_servicedetails : []
            state.tax_exempt = (action.payload && action.payload.tax_exempt) ? action.payload.tax_exempt : ''
            state.selectedSuppliersData = (action.payload && action.payload.selectedSuppliersData) ? action.payload.selectedSuppliersData : null
            state.selectedPurchasesTypeData = (action.payload && action.payload.selectedPurchasesTypeData) ? action.payload.selectedPurchasesTypeData : null;
            state.poMode = (action.payload && action.payload.poMode) ? action.payload.poMode : false
        }
    },
});

export const { togglePurchasesModal } = addEditPurchasesModalSlice.actions;
export default addEditPurchasesModalSlice.reducer;
