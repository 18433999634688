import React, { Component } from 'react';
import { Container, Card, Row, Col, ButtonGroup, Button, Form, Spinner, Modal, Badge, InputGroup } from 'react-bootstrap';
import { t } from '../../helpers/translation_helper';
import NavBar from '../_partials/NavBar/_NavBar';
import Header from '../_partials/Header/_Header';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faCheckCircle, faSearch, faUser, faXmark, faLayerGroup, faHandsHoldingChild, faPeopleGroup, faListCheck, faAngleLeft, faAngleRight, faEllipsis, faWalking, faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { brand_colors } from '../../helpers/brand_colors_helper';
import { Tooltip as Tooltiper } from 'react-tooltip';
import { getStudents, setStudents } from '../../redux/slices/studentListSlice';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import walkingSolidBlue from '../../assets/images/student-list/walking-solid-blue.png';
import walkingSolidOrink from '../../assets/images/student-list/walking-solid-orink.png';
import quickTime from '../../assets/images/student-list/quick-time-white.png';
import selectTime from '../../assets/images/student-list/select-time-white.png';
import moment from 'moment';
import { isTeacher } from '../../helpers/general_helpers';
import { GET, POST } from '../../api';
import ReactPaginate from 'react-paginate';
import Pagination from '../_partials/Pagination';
import { checkRegistration } from '../../helpers/general_helpers';
import { sendCloudMessage } from '../../helpers/cloud_message';
import { DateRangePicker } from 'react-date-range';
const portalURL = process.env.REACT_APP_PORTAL_URL;
const apiURL = process.env.REACT_APP_API_URL;

let controller;

class StudentCustomAttendance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchExpanded: false,
      studentIndex: null,
      workingIndex: [],
      cdt: {
        rows: [10, 25, 50, 'All'],
      },
      absences: [],
      showAttModal: false,
      showBulkAttModal: false,
      student: null,
      attType: null,
      attTime: '',
      attComment: '',
      attAbsType: '',
      acs_id: null,
      ttd_id: null,
      class_id: null,
      working: false,
      studentList: [],
      timetable: null,
      timetable_periods: null,
      selectedTimetable: null,
      selectedTTSubs_working: false,
      selectedTTSubs: [],
      selectedTTSub: null,
      week: [],
      date: null,
      // showAllClasses: [1,2,8,12,22].includes(props.authData.loginData.role_id) ? true : false
      showAllClasses: false,
      selectedDateRange: { startDate: new Date(), endDate: new Date(), key: 'selection' },
      showDateRange: false,
    }
    this.searchFieldRef = React.createRef();
  }

  componentDidMount() {
    this.generateWeek();
    this.getAbsences();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.selectedClass && prevProps.selectedClass.class.class_id !== this.props.selectedClass.class.class_id) {
      this.getStudents({
        rows: this.props.studentListRows,
        page: 0,
        search: '',
        status: this.props.studentListStatus
      });
      this.setState({ searchExpanded: false });
      this.searchFieldRef.current.value = '';
    }
  }

  getStudents = (params) => {
    this.props.getStudents({
      search: params.search,
      limit: params.rows,
      page: params.page,
      child_status: params.status,
      order: 'children.child_name',
      dir: 'asc',
      attendance_custom: true,
      attendance_startdate: this.state.week[0],
      attendance_enddate: this.state.week[6],
      showAllClasses: this.state.showAllClasses,
      registrations: true,
    });
    this.setState({ studentList: [] });
  }

  getAbsences = async () => {

    const { authData } = this.props;
    const { center_id } = authData.centerData;
    const { auth_key } = authData.loginData;

    try {
      const res = await GET('children/get-absences');

      if (res.status === 200) {
        this.setState({
          absences: res.data
        });
      }

    } catch (err) {
      console.log('err', err)

    }
  }

  customAttendance = async () => {

    const { studentIndex, student: studentData, date } = this.state;
    this.setState({ workingIndex: [studentIndex] });

    const {
      authData,
      studentListData: students,
    } = this.props;
    const { auth_key } = authData.loginData;
    const { center_id, center_timezone } = authData.centerData;
    const { attType, attAbsType, attComment, sub_ttd_id, attTime, attAbsent } = this.state;

    const findAttendance = studentData.childattendance_custom.find(item => item.att_date == date);


    let attendance = {
      class_id: studentData.class_id,
      child_id: studentData.child_id,
      center_id,
      att_comment: attComment,
      absent_start_date: moment(this.state.selectedDateRange.startDate).format('YYYY-MM-DD'),
      absent_end_date: moment(this.state.selectedDateRange.endDate).format('YYYY-MM-DD'),
    };

    if (attType == 'checkin') {
      attendance['att_datetime_in'] = date + ' ' + attTime;
      attendance['att_datetime_out'] = findAttendance ? findAttendance.att_datetime_out : null;
    }

    if (attType == 'checkout') {
      attendance['att_datetime_in'] = findAttendance ? findAttendance.att_datetime_in : null;
      attendance['att_datetime_out'] = date + ' ' + attTime;
    }

    if (attType == 'reset_checkin') {
      attendance['att_datetime_in'] = null;
      attendance['att_datetime_out'] = findAttendance ? findAttendance.att_datetime_out : null;
    }

    if (attType == 'reset_checkout') {
      attendance['att_datetime_in'] = findAttendance ? findAttendance.att_datetime_in : null;
      attendance['att_datetime_out'] = null;
    }

    if (attType == 'reset_absent') {
      attendance['absent'] = 0;
      attendance['absence_id'] = 0;
      attendance['att_datetime_in'] = null;
      attendance['att_datetime_out'] = null;
    }

    // Absent
    if (attAbsent && !parseInt(attAbsType)) {
      toast.warning(t('Please select Absence'));
      this.setState({ workingIndex: [] });
      return
    }
    attendance['absence_id'] = attAbsType ? attAbsType : 0;
    attendance['absent'] = attAbsent ? 1 : 0;


    const fData = new FormData();
    fData.append('center_timezone', center_timezone);
    fData.append('toDate', this.state.date);
    fData.append('attendance', JSON.stringify(attendance));


    try {
      const res = await POST('children/save-attendance-new-custom-day', fData);

      if (res.status === 200) {
        toast.success(t(res.data.message));

        let updatedStudentsList = JSON.parse(JSON.stringify(students));
        const findIndex = students.findIndex((student) => student.child_id == studentData.child_id);

        res.data.data.map(att_data => {
          const findAttIndex = studentData.childattendance_custom.findIndex(item => item.att_date == att_data.att_date && item.ttd_id == null);

          if (findAttIndex !== -1) {
            updatedStudentsList[findIndex].childattendance_custom[findAttIndex] = att_data;
          } else {
            updatedStudentsList[findIndex].childattendance_custom.push(att_data);
          }
        });

        // SEND NOTIFICATION ONLY IN CASE OF ABSENCE
        if (parseInt(res.data.data.absent) != 0 && moment(res.data.data.att_date).isSame(moment().format('YYYY-MM-DD'))) {
          sendCloudMessage(updatedStudentsList[findIndex].parent_user_id, 'Todays Attendance', updatedStudentsList[findIndex].child_name + ' has been marked absent. ' + res.data.data.att_comment, {})
        }

        this.props.setStudents(updatedStudentsList);
        this.handleCloseAttModal();

      }

    } catch (err) {
      console.log('err', err)

      this.setState({ working: false, workingIndex: [] });
    }

  }

  bulkCustomAttendance = async () => {

    const { studentList, attType, date, attTime, attAbsent, attAbsType } = this.state;
    const { authData } = this.props;
    const { auth_key } = authData.loginData;
    const { center_id, center_timezone } = authData.centerData;

    if (studentList.length === 0) {
      toast.error(t('Select at least one child for bulk attendance!'));
      return;
    }

    const workingIndex = studentList.map((student) => {
      return student.student_index;
    })

    this.setState({ working: true, workingIndex });

    const fData = new FormData();
    fData.append('center_id', center_id);
    fData.append('attType', attAbsent == 1 ? 'absent' : attType);
    fData.append('att_date', date);

    const childrenList = studentList.map((student) => {
      return {
        child_id: student.student_id,
        class_id: student.class_id
      }
    });
    fData.append('childrenList', JSON.stringify(childrenList));


    if (attType == 'checkin') {
      fData.append('att_datetime_in', date + ' ' + attTime);
      fData.append('att_datetime_out', null);
    }

    if (attType == 'checkout') {
      fData.append('att_datetime_in', null);
      fData.append('att_datetime_out', date + ' ' + attTime);
    }

    if (attAbsent && !parseInt(attAbsType)) {
      toast.warning(t('Please select Absence'));
      this.setState({ workingIndex: [] });
      return
    }

    if (attAbsType) {
      fData.append('absence_id', attAbsType ? attAbsType : 0);
    }
    fData.append('absent', attAbsent ? 1 : 0);

    try {

      const res = await POST('children/save-attendance-for-all-new', fData);

      toast.success(t(res.data.message));

      const updatedStudentsList = JSON.parse(JSON.stringify(this.props.studentListData));

      for (const student_id in res.data.data) {

        const findIndex = updatedStudentsList.findIndex((student) => student.child_id == student_id);

        const findAttIndex = updatedStudentsList[findIndex].childattendance_custom.findIndex(item => item.att_date == date && item.ttd_id == null);

        if (findAttIndex !== -1) {
          updatedStudentsList[findIndex].childattendance_custom[findAttIndex] = res.data.data[student_id][0];
        } else {
          updatedStudentsList[findIndex].childattendance_custom.push(res.data.data[student_id][0]);
        }

      }

      this.props.setStudents(updatedStudentsList);
      this.handleCloseAttModal();
      this.checkUncheckAllStudents(true);

    } catch (err) {
      console.log('err', err)
      toast.error(t('Something went wrong!'));

      this.setState({ working: false });
    }

  }

  handleOpenAttModal = (studentIndex, student, attType, date) => {
    this.setState({
      studentIndex,
      showAttModal: true,
      student,
      attType,
      date,
      selectedDateRange: {
        startDate: new Date(Date.parse(date)),
        endDate: new Date(Date.parse(date)),
        key: 'selection'
      }
    });

    console.log({ studentIndex, student, attType, date });
  }

  handleCloseAttModal = () => {
    this.setState({
      workingIndex: [],
      showAttModal: false,
      showBulkAttModal: false,
      student: {},
      attTime: '',
      attComment: '',
      attAbsType: '',
      working: false,
      attAbsent: 0,
      date: null
    });
  }

  handleChangeCheckField = (e) => {
    this.setState(
      {
        [e.target.name]: this.state[e.target.name] == 0 ? 1 : 0,
      }
    );
  };

  checkUncheckStudent = (student_id, class_id, student_index) => {

    const findIndex = this.state.studentList.findIndex((c) => c.student_id == student_id);

    const studentList = this.state.studentList;

    if (findIndex == -1) {
      studentList.push({ student_id, class_id, student_index });
    } else {
      studentList.splice(findIndex, 1);
    }

    this.setState({ studentList });

  }

  checkUncheckAllStudents = (uncheck = false) => {
    if (uncheck) {
      this.setState({ studentList: [] });
      [...document.querySelectorAll('.child-selector')].map((checkbox) => {
        checkbox.checked = false;
      });
    } else {

      const studentList = [];

      this.props.studentListData.map((student, student_index) => {
        studentList.push({ student_id: student.child_id, student_index, class_id: student.class_id });
      });

      this.setState({ studentList });

      [...document.querySelectorAll('.child-selector')].map((checkbox) => {
        checkbox.checked = true;
      });
    }
  }

  changeWeek = (direction) => {
    console.log(direction);
    const { week } = this.state;
    if (direction === 'past') {
      this.generateWeek(moment(week[0]).subtract(6, 'days').format('YYYY-MM-DD'));
    } else if (direction === 'future') {
      this.generateWeek(moment(week[6]).add(1, 'days').format('YYYY-MM-DD'));
    } else {
      this.generateWeek();
    }
  }

  generateWeek = async (date = null) => {

    this.setState({ fetching: true, week: [] });


    if (!date) {
      date = new Date();
    } else {
      date = new Date(date);
    }
    const firstDay = new Date(
      date.setDate(date.getDate() - date.getDay()),
    );

    let week = [];

    for (let i = 0; i <= 6; i++) {
      let first = firstDay.getDate() - firstDay.getDay() + i;
      let day = new Date(firstDay.setDate(first)).toISOString().slice(0, 10);
      week.push(day);
    }

    this.setState({ week }, () => this.getStudents({
      rows: this.props.studentListRows,
      page: this.props.studentListPage,
      search: '',
      status: this.props.studentListStatus
    }));

  }

  render() {

    let { showAllClasses } = this.state
    const centerWeekends = this.props.authData.centerData.weekend_days?.split(',');

    return (
      <>
        <Card
          className='border-0'
          style={{ borderRadius: '20px' }}>
          <Card.Body>
            <Row>
              <Col md={6} className='d-flex flex-column'>
                <div className='d-flex flex-row align-items-center'>
                  <strong>{t("Custom Attendance")}</strong>
                  <div style={{ marginLeft: 20, marginRight: 10 }}>
                    {t('Show')}
                    <Form.Select
                      value={this.props.studentListRows}
                      size="sm"
                      onChange={(e) => this.getStudents({
                        rows: e.target.value,
                        page: 0,
                        search: this.props.studentListSearch,
                        status: this.props.studentListStatus
                      })}
                      style={{ width: 70, marginInline: 5, display: 'inline-block' }}>
                      {this.state.cdt.rows.map((row, row_index) => (<option key={row_index} >{row}</option>))}
                    </Form.Select>
                    {t('Records')}
                  </div>
                  <div className='expandable-search ms-2' style={{ width: this.state.searchExpanded ? 200 : 35 }}>
                    <input
                      ref={this.searchFieldRef}
                      defaultValue={this.props.studentListSearch}
                      onChange={(e) => this.getStudents({
                        rows: this.props.studentListRows,
                        page: 0,
                        search: e.target.value,
                        status: this.props.studentListStatus
                      })}
                      type='text'
                      style={{ width: this.state.searchExpanded ? 165 : 0, paddingLeft: this.state.searchExpanded ? 15 : 0, opacity: this.state.searchExpanded ? 1 : 0 }}
                      placeholder={t('Search Student...')} />
                    <button
                      data-tooltip-id="tooltip"
                      data-tooltip-content={t('Search Student')}
                      data-tooltip-place="top"
                      onClick={() => {
                        this.setState({ searchExpanded: !this.state.searchExpanded });
                        this.props.studentListSearch != '' && this.state.searchExpanded && this.getStudents({
                          rows: this.props.studentListRows,
                          page: 0,
                          search: '',
                          status: this.props.studentListStatus
                        });
                        this.searchFieldRef.current.value = '';
                        !this.state.searchExpanded && this.searchFieldRef.current.focus();
                      }}>
                      <FontAwesomeIcon
                        icon={this.state.searchExpanded ? faXmark : faSearch}
                        color={brand_colors[this.props.defaultTheme.theme_id].color18}
                      />
                    </button>
                  </div>
                </div>
              </Col>
              <Col md={6} className='d-flex justify-content-end'>
                <div>
                  <Badge bg='info' className='me-2'>{t('Total Students:')} {this.props.studentListTotalCount}</Badge>
                </div>
                <div style={{ marginRight: 20 }}>
                  {false && [1, 2, 8, 12, 22].includes(this.props.authData.loginData.role_id) &&
                    <Button onClick={() => this.setState({ showAllClasses: !showAllClasses }, () => {
                      this.getStudents({
                        rows: this.props.studentListRows,
                        status: this.props.studentListStatus,
                        order: this.props.studentListOrder,
                        dir: this.props.studentListDir,
                        page: 0,
                        search: this.props.studentListSearch
                      })
                    })} variant={(showAllClasses) ? "success" : "outline-success"} size='sm' className='me-3' title={(showAllClasses) ? t('Show Selected Class only') : t('Show All Classes')}>
                      {showAllClasses && <FontAwesomeIcon icon={faCheckCircle} color={brand_colors[this.props.defaultTheme.theme_id].color8} className='me-1' />}
                      {(showAllClasses) ? t('Showing All Classes') : t('Showing Selected Class')}
                    </Button>
                  }
                  <ButtonGroup size='sm'>
                    <Button
                      onClick={() => this.getStudents({
                        rows: this.props.studentListRows,
                        status: true,
                        page: 0,
                        search: this.props.studentListSearch
                      })}
                      variant={this.props.studentListStatus ? 'success' : 'light'}
                    >{t('Only Active')}</Button>
                    <Button
                      onClick={() => this.getStudents({
                        rows: this.props.studentListRows,
                        status: false,
                        page: 0,
                        search: this.props.studentListSearch
                      })}
                      variant={this.props.studentListStatus ? 'light' : 'success'}
                    >{t('Show All')}</Button>
                  </ButtonGroup>
                </div>
              </Col>
            </Row>
            <Row>
              {this.props.studentListFetching &&
                <Col md={12} className='py-5 d-flex flex-column align-items-center'>
                  <Spinner as='span' animation='grow' size='sm' /> {t('Loading Students')}
                </Col>
              }
              {!this.props.studentListFetching &&
                <Col md={12} className='pt-1 student-attendance-list'>
                  <Row style={{
                    borderBottom: '#DEE2E6 1px solid',
                    paddingBottom: 10,
                  }}>
                    <Col md={3} className='d-flex align-items-center'>
                      <div>
                        <Button
                          className='me-1'
                          size='sm'
                          title={t('Current Week')}
                          onClick={() => this.changeWeek('current')}
                          variant="dark">
                          {t('Current')}
                        </Button>
                        <ButtonGroup size='sm'>
                          <Button
                            title={t('Previous Week')}
                            onClick={() => this.changeWeek('past')}
                            variant="dark">
                            <FontAwesomeIcon
                              icon={faAngleLeft}
                              color={'#ffffff'}
                            />
                          </Button>
                          <Button
                            title={t('Next Week')}
                            onClick={() => this.changeWeek('future')}
                            variant="dark">
                            <FontAwesomeIcon
                              icon={faAngleRight}
                              color={'#ffffff'}
                            />
                          </Button>
                        </ButtonGroup>
                      </div>
                    </Col>
                    {this.state.week
                      .filter(day => !centerWeekends.includes(moment(day).format('dddd').toLowerCase()))
                      .map((day, day_index) => (
                        <Col key={day_index}
                          className='d-flex justify-content-center align-items-center flex-column'
                          style={{
                            textAlign: 'center',
                            fontWeight: 700
                          }}>{moment(day).format('DD-MM-YYYY')}
                          <Badge pill bg="dark">{moment(day).format('dddd').toUpperCase()}</Badge>
                        </Col>
                      ))}
                  </Row>
                  <Row className='student-attendance-list-item' style={{
                    backgroundColor: 'rgb(247 247 247)',
                    paddingTop: 10
                  }}>
                    <Col md={3} className='d-flex flex-column'>
                      <strong>{t('Bulk Attendance')}</strong>
                      <div>
                        <button className='btn btn-sm btn-success me-1' onClick={() => this.checkUncheckAllStudents()}>{t('Check All')}</button>
                        <button className='btn btn-sm btn-danger' onClick={() => this.checkUncheckAllStudents(true)}>{t('Uncheck All')}</button>
                      </div>
                    </Col>
                    {this.state.week
                      .filter(day => !centerWeekends.includes(moment(day).format('dddd').toLowerCase()))
                      .map((day, day_index) => (
                        <Col key={day_index}
                          className='d-flex flex-column align-items-center'>
                          <div>
                            <button
                              data-tooltip-id="tooltip"
                              data-tooltip-content={t('Bulk Check-In')}
                              data-tooltip-place="top" style={{ border: 0, backgroundColor: 'transparent' }}
                              onClick={() => this.setState({ attType: 'checkin', showBulkAttModal: true, date: day })}>
                              <FontAwesomeIcon icon={faWalking} style={{ fontSize: 30, color: brand_colors[this.props.defaultTheme.theme_id].color1 }} />
                            </button>
                          </div>
                          <div>
                            <button
                              data-tooltip-id="tooltip"
                              data-tooltip-content={t('Bulk Check-Out')}
                              data-tooltip-place="top" style={{ border: 0, backgroundColor: 'transparent' }}
                              onClick={() => this.setState({ attType: 'checkout', showBulkAttModal: true, date: day })}>
                              <FontAwesomeIcon icon={faWalking} style={{ fontSize: 30, color: brand_colors[this.props.defaultTheme.theme_id].color16 }} />
                            </button>
                          </div>
                        </Col>
                      ))}
                  </Row>
                  {this.props.studentListData && this.props.studentListData.map((student, student_index) => (
                    <Row key={student_index}
                      style={{ opacity: this.state.workingIndex.includes(student_index) ? 0.5 : 1 }}
                      className='student-attendance-list-item'>
                      <Col md={3} className='d-flex align-items-center'>
                        <div className='me-3'><input type='checkbox' onClick={(e) => this.checkUncheckStudent(e.target.value, student.class_id, student_index)} value={student.child_id} className='child-selector form-check-input' /></div>
                        <div>
                          <div style={{
                            backgroundImage: `url(${(student.picture) ? portalURL + student.center_id + '/children/' + student.picture : 'https://via.placeholder.com/150x150?text=' + student.child_name[0].toUpperCase()})`,
                            width: 60,
                            height: 60,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center center',
                            borderRadius: 30,
                            marginRight: 15
                          }}></div>
                        </div>
                        {student.child_name}
                        {this.state.workingIndex.includes(student_index) &&
                          <Spinner animation="border" size='sm' variant="dark" className='ms-3' />
                        }
                      </Col>
                      {this.state.week
                        .filter(day => !centerWeekends.includes(moment(day).format('dddd').toLowerCase()))
                        .map((day, day_index) => {
                          const findAttendance = student.childattendance_custom?.find(item => item.att_date == day);
                          // console.groupCollapsed('%c' + student.child_name + ' - ' + day, 'font-size:20px');
                          // console.table(findAttendance);
                          // console.groupEnd();
                          return (
                            <Col
                              key={day_index}
                              className='d-flex flex-row align-items-center justify-content-center'>
                              <div>
                                {checkRegistration(student, day, {})}
                              </div>
                              <div className='d-flex flex-column align-items-center justify-content-center position-relative'>
                                <div>
                                  {(!findAttendance || (findAttendance?.att_datetime_in == null && !parseInt(findAttendance?.absent))) &&
                                    <button
                                      disabled={this.state.workingIndex.includes(student_index)}
                                      data-tooltip-id="tooltip"
                                      data-tooltip-content={t('Check-In')}
                                      data-tooltip-place="top"
                                      className='btn btn-sm btn-warning d-inline-flex justify-content-center align-items-center' style={{ border: 0, backgroundColor: 'transparent' }}
                                      onClick={() => this.handleOpenAttModal(student_index, student, 'checkin', day)}>
                                      <FontAwesomeIcon icon={faWalking} style={{ fontSize: 30, color: brand_colors[this.props.defaultTheme.theme_id].color1 }} />
                                    </button>
                                  }
                                  {findAttendance && findAttendance.att_datetime_in &&
                                    <button
                                      disabled={this.state.workingIndex.includes(student_index)}
                                      data-tooltip-id="tooltip"
                                      data-tooltip-content={t('Reset Check-In')}
                                      data-tooltip-place="top"
                                      className='btn btn-sm btn-primary my-1' style={{ borderRadius: 30, paddingBlock: 2, backgroundColor: brand_colors[this.props.defaultTheme.theme_id].color1 }}
                                      onClick={() => this.setState({
                                        studentIndex: student_index,
                                        student,
                                        attType: 'reset_checkin',
                                        date: day
                                      }, () => this.customAttendance())}>
                                      {moment(findAttendance.att_datetime_in).format('HH:mm:ss')}
                                      <FontAwesomeIcon icon={faXmark} color={brand_colors[this.props.defaultTheme.theme_id].color8} className='ms-2' />
                                    </button>
                                  }
                                  {findAttendance && parseInt(findAttendance.absent) == 1 &&
                                    <button
                                      disabled={this.state.workingIndex.includes(student_index)}
                                      data-tooltip-id="tooltip"
                                      data-tooltip-content={t('Reset Absent')}
                                      data-tooltip-place="top"
                                      className='btn btn-sm btn-danger my-1' style={{ borderRadius: 30, paddingBlock: 2 }} onClick={() => this.setState({
                                        studentIndex: student_index,
                                        student,
                                        attType: 'reset_absent',
                                        date: day
                                      }, () => this.customAttendance())}>
                                      {findAttendance.absence_id != 0 && findAttendance.absence_id != null ? this.state.absences.find((abs) => abs.absence_id == findAttendance.absence_id)?.absence_name : t('Absent')}
                                      <FontAwesomeIcon icon={faXmark} color={brand_colors[this.props.defaultTheme.theme_id].color9} className='ms-2' />
                                    </button>
                                  }
                                </div>
                                <div>
                                  {(!findAttendance || (findAttendance?.att_datetime_out == null && !parseInt(findAttendance?.absent))) &&
                                    <button
                                      disabled={this.state.workingIndex.includes(student_index)}
                                      data-tooltip-id="tooltip"
                                      data-tooltip-content={t('Check-Out')}
                                      data-tooltip-place="top"
                                      className='btn btn-sm btn-warning d-inline-flex justify-content-center align-items-center' style={{ border: 0, backgroundColor: 'transparent' }}
                                      onClick={() => this.handleOpenAttModal(student_index, student, 'checkout', day)}>
                                      <FontAwesomeIcon icon={faWalking} style={{ fontSize: 30, color: brand_colors[this.props.defaultTheme.theme_id].color16 }} />
                                    </button>
                                  }
                                  {findAttendance && findAttendance.att_datetime_out &&
                                    <button className='btn btn-sm btn-warning my-1'
                                      disabled={this.state.workingIndex.includes(student_index)}
                                      data-tooltip-id="tooltip"
                                      data-tooltip-content={t('Reset Check-Out')}
                                      data-tooltip-place="top"
                                      style={{ borderRadius: 30, paddingBlock: 1, backgroundColor: brand_colors[this.props.defaultTheme.theme_id].color16 }}
                                      onClick={() => this.setState({
                                        studentIndex: student_index,
                                        student,
                                        attType: 'reset_checkout',
                                        date: day
                                      }, () => this.customAttendance())}>
                                      {moment(findAttendance.att_datetime_out).format('HH:mm:ss')}
                                      <FontAwesomeIcon icon={faXmark} color={brand_colors[this.props.defaultTheme.theme_id].color8} className='ms-2' />
                                    </button>
                                  }
                                  {findAttendance && parseInt(findAttendance.absent) == 1 &&
                                    <button className='btn btn-sm btn-danger my-1'
                                      disabled={this.state.workingIndex.includes(student_index)}
                                      data-tooltip-id="tooltip"
                                      data-tooltip-content={t('Reset Absent')}
                                      data-tooltip-place="top"
                                      style={{ borderRadius: 30, paddingBlock: 1 }}
                                      onClick={() => this.setState({
                                        studentIndex: student_index,
                                        student,
                                        attType: 'reset_absent',
                                        date: day
                                      }, () => this.customAttendance())}>
                                      {findAttendance.absence_id != 0 && findAttendance.absence_id != null ? this.state.absences.find((abs) => abs.absence_id == findAttendance.absence_id)?.absence_name : t('Absent')}
                                      <FontAwesomeIcon icon={faXmark} color={brand_colors[this.props.defaultTheme.theme_id].color9} className='ms-2' />
                                    </button>
                                  }
                                </div>
                              </div>
                            </Col>
                          )
                        })}
                    </Row>
                  ))}
                </Col>
              }
            </Row>
            <Pagination
              pageCount={this.props.studentListLastPage}
              forcePage={this.props.studentListPage}
              callbackParams={{
                rows: this.props.studentListRows,
                status: this.props.studentListStatus,
                search: this.props.studentListSearch
              }}
              callback={this.getStudents}
            />
            <Modal show={this.state.showAttModal} onHide={this.handleCloseAttModal}>
              <Modal.Header closeButton>
                <Modal.Title>{t('Attendance')} | {moment(this.state.date).format('DD-MM-YYYY')}  <Badge pill bg="dark">{moment(this.state.date).format('dddd').toUpperCase()}</Badge></Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Row>
                  <Col md={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>{t('Attendance Time')}</Form.Label>
                      <input
                        disabled={this.state.attAbsent}
                        type='time'
                        className='form-control'
                        value={this.state.attTime}
                        onChange={(e) => {
                          this.setState({ attTime: e.target.value });
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form className='me-3 pt-2'>
                      <Form.Check
                        type="switch"
                        label={t('Absent')}
                        onChange={() => this.setState({
                          attAbsent: !this.state.attAbsent,
                          attAbsType: ''
                        })}
                      />
                    </Form>
                  </Col>
                  <Col md={8} className='mb-3'>
                    <Form.Select disabled={!this.state.attAbsent} value={this.state.attAbsType} aria-label="Default select example" onChange={(e) => this.setState({ attAbsType: e.target.value })}>
                      <option value={''} >{t('--Select--')}</option>
                      {this.state.absences.map((abs, abs_i) => (
                        <option value={abs.absence_id} key={abs_i}>{abs.absence_name}</option>
                      ))}
                    </Form.Select>
                  </Col>
                  <Col md={12}>
                    <Form.Group className="mb-3">
                      <input
                        type='text'
                        placeholder={t('Comment')}
                        className='form-control'
                        defaultValue={this.state.attComment}
                        onBlur={(e) => {
                          this.setState({ attComment: e.target.value });
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={12} className='position-relative'>
                    <InputGroup>
                      <Form.Control
                        onClick={() => this.setState({ showDateRange: true })}
                        value={
                          moment(this.state.selectedDateRange.startDate).format('DD-MM-YYYY') + ' -- ' + moment(this.state.selectedDateRange.endDate).format('DD-MM-YYYY')
                        }
                        readOnly
                        disabled={!this.state.attAbsent}
                      />
                      <Button variant={this.state.showDateRange ? 'danger' : 'primary'}
                        disabled={!this.state.attAbsent}
                        style={{ width: 40 }}
                        onClick={() => this.setState({ showDateRange: !this.state.showDateRange })}
                        id="button-addon2">
                        <FontAwesomeIcon icon={this.state.showDateRange ? faXmark : faCalendarAlt} style={{ fontSize: 16, color: brand_colors[this.props.defaultTheme.theme_id].color8, }} />
                      </Button>
                    </InputGroup>
                    {this.state.showDateRange &&
                      <div style={{
                        backgroundColor: '#fff',
                        padding: 10,
                        position: 'absolute',
                        border: '#F2F2F2 2px solid',
                        zIndex: 2,
                        top: 37,
                        left: -45,
                        borderRadius: 7
                      }}>
                        <DateRangePicker
                          ranges={[this.state.selectedDateRange]}
                          onChange={(date) => {
                            this.setState({
                              selectedDateRange: { startDate: new Date(date.selection.startDate), endDate: new Date(date.selection.endDate), key: 'selection' }
                            })
                          }}
                          style={{ backgroundColor: '#fff' }}
                          minDate={new Date(moment(this.state.date).format('YYYY'), moment(this.state.date).subtract(1, 'month').format('MM'), moment(this.state.date).format('DD'))}
                        />
                      </div>
                    }
                  </Col>
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <Button disabled={this.state.working} variant="warning" onClick={() => {
                  this.customAttendance();
                }}>
                  {t('Update')}
                </Button>
              </Modal.Footer>
            </Modal>
            <Modal show={this.state.showBulkAttModal} onHide={() => this.setState({ showBulkAttModal: false })}>
              <Modal.Header closeButton>
                <Modal.Title>{t('Attendance')}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Row>
                  <Col md={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>{t('Attendance Time')}</Form.Label>
                      <input
                        disabled={this.state.attAbsent}
                        type='time'
                        className='form-control'
                        value={this.state.attTime}
                        onChange={(e) => {
                          this.setState({ attTime: e.target.value });
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form className='me-3 pt-2'>
                      <Form.Check
                        type="switch"
                        label={t('Absent')}
                        onChange={() => this.setState({
                          attAbsent: !this.state.attAbsent,
                          attAbsType: ''
                        })}
                      />
                    </Form>
                  </Col>
                  <Col md={8} className='mb-3'>
                    <Form.Select disabled={!this.state.attAbsent} value={this.state.attAbsType} aria-label="Default select example" onChange={(e) => this.setState({ attAbsType: e.target.value })}>
                      <option value={''} >{t('--Select--')}</option>
                      {this.state.absences.map((abs, abs_i) => (
                        <option value={abs.absence_id} key={abs_i}>{abs.absence_name}</option>
                      ))}
                    </Form.Select>
                  </Col>
                  {/* <Col md={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>{t('Attendance Time')}</Form.Label>
                      <input
                        disabled={this.state.attAbsent}
                        type='time'
                        className='form-control'
                        value={this.state.attTime}
                        onChange={(e) => {
                          this.setState({ attTime: e.target.value });
                        }}
                      />
                    </Form.Group>
                  </Col> */}
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <Button disabled={this.state.working} variant="warning" onClick={() => {
                  this.bulkCustomAttendance();
                }}>
                  {t('Update')}
                </Button>
              </Modal.Footer>
            </Modal>
          </Card.Body>
        </Card>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  selectedClass: state.selectedClass.data,
  defaultLanguage: state.language.defaultLanguage,
  defaultTheme: state.theme.defaultTheme,
  studentListFetching: state.studentList.fetching,
  studentListData: state.studentList.data,
  studentListSearch: state.studentList.query,
  studentListRows: state.studentList.rows,
  studentListOffset: state.studentList.offset,
  studentListStatus: state.studentList.status,
  studentListOrder: state.studentList.order,
  studentListDir: state.studentList.dir,
  studentListLastPage: state.studentList.last_page,
  studentListPage: state.studentList.page,
  studentListTotalRecords: state.studentList.total_records,
  studentListTotalCount: state.studentList.total_count,
});

const mapDispatchToProps = () => ({
  getStudents,
  setStudents
});

export default connect(mapStateToProps, mapDispatchToProps())(StudentCustomAttendance);
