import React, { Component } from 'react';
import { Container, Card, Row, Col, Table, Badge, Button, Modal } from 'react-bootstrap';
import { t } from '../../helpers/translation_helper';
import NavBar from '../_partials/NavBar/_NavBar';
import Header from '../_partials/Header/_Header';
import { connect } from 'react-redux';
import StudentsSidebar from './StudentsSidebar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBoxArchive, faEye, faInfoCircle, faSchool, faUserCheck, faUserXmark, faUsers } from '@fortawesome/free-solid-svg-icons';
import { brand_colors } from '../../helpers/brand_colors_helper';
import { GET } from '../../api';
import { toast } from 'react-toastify';

class StudentsDashboard extends Component {

  state = {
    currentPeriodRegistrations: '...',
    totalStudents: '...',
    activeStudents: '...',
    inactiveStudents: '...',
    reportClassCapacityData: null,
    showBookedSeatsModal: false,
    bookedAvailableSeats: {
      total: 0,
      mon: 0,
      tue: 0,
      wed: 0,
      thu: 0,
      fri: 0,
      sat: 0,
      sun: 0,
    },
  }

  componentDidMount() {
    this.studentsData();
    this.reportClassCapacity();
  }

  studentsData = async () => {

    this.setState({ currentPeriodRegistrations: '...' });

    const { authData } = this.props;
    const { auth_key } = authData.loginData;
    const { center_id } = authData.centerData;

    try {
      const res = await GET('students-dashboard/students-data');

      if (res.status == 200) {

        this.setState({
          currentPeriodRegistrations: res.data.total_registrations,
          totalStudents: res.data.total_students,
          activeStudents: res.data.active_students,
          inactiveStudents: res.data.archived_students,
        });
      }

    } catch (err) {
      console.log('err', err)
      toast.error(t('Something went wrong1!'));
    }
  }

  reportClassCapacity = async (term_id) => {

    this.setState({ reportClassCapacityData: null });

    const { authData } = this.props;
    const { auth_key } = authData.loginData;
    const { center_id } = authData.centerData;

    try {
      const res = await GET('students-dashboard/report-class-capacity');

      if (res.status == 200) {

        this.setState({ reportClassCapacityData: res.data.data });

      }

    } catch (err) {
      console.log('err', err)

      toast.error(t('Something went wrong!'));
    }
  }

  render() {
    return (
      <Container fluid>
        <div id='iedu-layout'>
          <NavBar />
          <div id='page-content'>
            <Header lite={true} heading={t('Students Dashboard')} backBtn />
            <div style={{ display: 'flex' }}>
              <StudentsSidebar />
              <div style={{
                flex: 1,
                height: 'calc(100vh - 113px)',
                overflowY: 'auto',
                overflowX: 'hidden',
                paddingBottom: 10,
              }}>
                <div className='grey-section'>
                  <Row>
                    <Col md={3}>
                      <Card
                        className='border-0 mb-4'
                        style={{ borderRadius: '27px' }}>
                        <Card.Body>
                          <div style={{
                            margin: '0 auto 10px',
                            width: 100,
                            height: 100,
                            borderRadius: 50,
                            backgroundColor: brand_colors[this.props.defaultTheme.theme_id].color3,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            opacity: 0.7
                          }}>
                            <FontAwesomeIcon icon={faSchool} style={{ fontSize: 40, color: brand_colors[this.props.defaultTheme.theme_id].color8 }} />
                          </div>
                          <div className='text-center mb-3'>
                            <strong style={{ fontSize: 48 }}>{this.state.currentPeriodRegistrations}</strong>
                          </div>
                          <div className='mb-1 text-center'>
                            <strong className='text-muted'>{t('Current Period Registrations')}</strong>
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col md={3}>
                      <Card
                        className='border-0 mb-4'
                        style={{ borderRadius: '27px' }}>
                        <Card.Body>
                          <div style={{
                            margin: '0 auto 10px',
                            width: 100,
                            height: 100,
                            borderRadius: 50,
                            backgroundColor: brand_colors[this.props.defaultTheme.theme_id].color1,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            opacity: 0.7
                          }}>
                            <FontAwesomeIcon icon={faUsers} style={{ fontSize: 40, color: brand_colors[this.props.defaultTheme.theme_id].color8 }} />
                          </div>
                          <div className='text-center mb-3'>
                            <strong style={{ fontSize: 48 }}>{this.state.totalStudents}</strong>
                          </div>
                          <div className='mb-1 text-center'>
                            <strong className='text-muted'>{t('Total Students')}</strong>
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col md={3}>
                      <Card
                        className='border-0 mb-4'
                        style={{ borderRadius: '27px' }}>
                        <Card.Body>
                          <div style={{
                            margin: '0 auto 10px',
                            width: 100,
                            height: 100,
                            borderRadius: 50,
                            backgroundColor: brand_colors[this.props.defaultTheme.theme_id].color13,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            opacity: 0.7
                          }}>
                            <FontAwesomeIcon icon={faUserCheck} style={{ fontSize: 40, color: brand_colors[this.props.defaultTheme.theme_id].color8 }} />
                          </div>
                          <div className='text-center mb-3'>
                            <strong style={{ fontSize: 48 }}>{this.state.activeStudents}</strong>
                          </div>
                          <div className='mb-1 text-center'>
                            <strong className='text-muted'>{t('Active Students')}</strong>
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col md={3}>
                      <Card
                        className='border-0 mb-4'
                        style={{ borderRadius: '27px' }}>
                        <Card.Body>
                          <div style={{
                            margin: '0 auto 10px',
                            width: 100,
                            height: 100,
                            borderRadius: 50,
                            backgroundColor: brand_colors[this.props.defaultTheme.theme_id].color16,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            opacity: 0.7
                          }}>
                            <FontAwesomeIcon icon={faBoxArchive} style={{ fontSize: 40, color: brand_colors[this.props.defaultTheme.theme_id].color8 }} />
                          </div>
                          <div className='text-center mb-3'>
                            <strong style={{ fontSize: 48 }}>{this.state.inactiveStudents}</strong>
                          </div>
                          <div className='mb-1 text-center'>
                            <strong className='text-muted'>{t('Archived Students')}</strong>
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col md={12}>
                      <Card
                        className='border-0'
                        style={{ borderRadius: 27 }}>
                        <Card.Body>
                          <Row>
                            <Col className='ps-5'><div className='text-center mb-2'>
                              <strong>{t('Booked/Available Seats Per Class')}</strong>
                            </div></Col>
                          </Row>
                          <Table className='mb-0'>
                            <thead>
                              <tr>
                                <th width='20%'>{t('Grade')}</th>
                                <th width='20%'>{t('Class')}</th>
                                <th width='20%'>{t('Capacity')}</th>
                                <th width='30%'>{t('Total Registrations')}</th>
                              </tr>
                            </thead>
                          </Table>
                          <div>
                            <Table>
                              <tbody>
                                {this.state.reportClassCapacityData ?
                                  <>
                                    {this.state.reportClassCapacityData.map((item, index) => <tr key={index}>
                                      <td width="20%">{item.agegroup_code}</td>
                                      <td width="20%">{item.class_theme}</td>
                                      <td width="20%" style={{ paddingLeft: 10 }}><Badge bg='info'>{item.child_count}</Badge></td>
                                      <td width="30%" style={{ paddingLeft: 15 }}>
                                        <Row>
                                          <Col><Badge bg='primary'>{item.total_registered}</Badge></Col>
                                          <Col className='text-end'>
                                            <Button size='sm' variant='warning'
                                              onClick={() => this.setState({
                                                showBookedSeatsModal: true,
                                                bookedAvailableSeats: {
                                                  total: parseInt(item.child_count),
                                                  mon: parseInt(item.total_session_mon),
                                                  tue: parseInt(item.total_session_tue),
                                                  wed: parseInt(item.total_session_wed),
                                                  thu: parseInt(item.total_session_thu),
                                                  fri: parseInt(item.total_session_fri),
                                                  sat: parseInt(item.total_session_sat),
                                                  sun: parseInt(item.total_session_sun),
                                                }
                                              })}
                                              style={{ lineHeight: '10px', padding: 5, }}>
                                              <FontAwesomeIcon icon={faEye} style={{ fontSize: 10 }} />
                                            </Button>
                                          </Col>
                                        </Row>
                                      </td>
                                    </tr>)}
                                  </>
                                  :
                                  <tr>
                                    <td align='center' colSpan={3}>{t('Loading...')}</td>
                                  </tr>
                                }

                              </tbody>
                            </Table>
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Booked Seats Modal */}
        <Modal show={this.state.showBookedSeatsModal}
          onHide={() => this.setState({ showBookedSeatsModal: false })}
          size='lg'>
          <Modal.Header closeButton>
            <Modal.Title>{t('Booked/Available Seats Per Class')}&nbsp;&nbsp;&nbsp;
              <Badge bg='info' className='mx-1 px-2'>{t('Booked')}</Badge> <Badge bg='success' className='mx-1 px-2'>{t('Available')}</Badge></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Table bordered>
              <thead>
                <tr>
                  <th className='text-center'>{t('Monday')}</th>
                  <th className='text-center'>{t('Tuesday')}</th>
                  <th className='text-center'>{t('Wednesday')}</th>
                  <th className='text-center'>{t('Thursday')}</th>
                  <th className='text-center'>{t('Friday')}</th>
                  <th className='text-center'>{t('Saturday')}</th>
                  <th className='text-center'>{t('Sunday')}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td align='center'>
                    <Badge bg='info' className='mx-1 px-2'>{this.state.bookedAvailableSeats.mon}</Badge>
                    <Badge bg='success' className='mx-1 px-2'>{this.state.bookedAvailableSeats.total - this.state.bookedAvailableSeats.mon}</Badge>
                  </td>
                  <td align='center'>
                    <Badge bg='info' className='mx-1 px-2'>{this.state.bookedAvailableSeats.tue}</Badge>
                    <Badge bg='success' className='mx-1 px-2'>{this.state.bookedAvailableSeats.total - this.state.bookedAvailableSeats.tue}</Badge>
                  </td>
                  <td align='center'>
                    <Badge bg='info' className='mx-1 px-2'>{this.state.bookedAvailableSeats.wed}</Badge>
                    <Badge bg='success' className='mx-1 px-2'>{this.state.bookedAvailableSeats.total - this.state.bookedAvailableSeats.wed}</Badge>
                  </td>
                  <td align='center'>
                    <Badge bg='info' className='mx-1 px-2'>{this.state.bookedAvailableSeats.thu}</Badge>
                    <Badge bg='success' className='mx-1 px-2'>{this.state.bookedAvailableSeats.total - this.state.bookedAvailableSeats.thu}</Badge>
                  </td>
                  <td align='center'>
                    <Badge bg='info' className='mx-1 px-2'>{this.state.bookedAvailableSeats.fri}</Badge>
                    <Badge bg='success' className='mx-1 px-2'>{this.state.bookedAvailableSeats.total - this.state.bookedAvailableSeats.fri}</Badge>
                  </td>
                  <td align='center'>
                    <Badge bg='info' className='mx-1 px-2'>{this.state.bookedAvailableSeats.sat}</Badge>
                    <Badge bg='success' className='mx-1 px-2'>{this.state.bookedAvailableSeats.total - this.state.bookedAvailableSeats.sat}</Badge>
                  </td>
                  <td align='center'>
                    <Badge bg='info' className='mx-1 px-2'>{this.state.bookedAvailableSeats.sun}</Badge>
                    <Badge bg='success' className='mx-1 px-2'>{this.state.bookedAvailableSeats.total - this.state.bookedAvailableSeats.sun}</Badge>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Modal.Body>
        </Modal>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  defaultLanguage: state.language.defaultLanguage,
  defaultTheme: state.theme.defaultTheme,
});

export default connect(mapStateToProps, null)(StudentsDashboard);
