import { lazy } from 'react';

import Dashboard from '../components/Dashboard/_Dashboard';
import ClassBlog from '../components/ClassBlog/_ClassBlog';
import Exams from '../components/Exams/_Exams';
import Activities from '../components/Activities/_Activities';
import Subjects from '../components/Subjects/_Subjects';
import Lessons from '../components/Subjects/Lessons';
import Lesson from '../components/Subjects/Lesson';
import WeeklyLessonPlans from '../components/WeeklyLessonPlan/_WeeklyLessonPlans';
import Planner from '../components/WeeklyLessonPlan/Planner';
import AssignedActivity from '../components/AssignedActivity/_AssignedActivity';
import ClassView from '../components/ClassView/_ClassView';
import Students from '../components/Students/_Students';
import StudentInformation from '../components/Students/StudentInformation';
import StudentTracker from '../components/StudentTracker/_StudentTracker';
import StudentAttainment from '../components/StudentAttainment/_StudentAttainment';
import AssessmentCoverage from '../components/AssessmentCoverage/_AssessmentCoverage';
import StudentAssessment from '../components/Reports/StudentAssessment';
import ReportExams from '../components/Reports/ReportExams';
import ReportCurriculumResult from '../components/Reports/ReportCurriculumResult';
import Appointments from '../components/Appointments/_Appointments';
import AdminDashboard from '../components/Admin/Dashboard/_AdminDashboard';
import Main from '../components/Admin/Dashboard/Main/_Main';
import Admissions from '../components/Admin/Dashboard/Admissions/_Admissions';
import HR from '../components/Admin/Dashboard/HR/_HR';
import OtherDashboard from '../components/Admin/Dashboard/OtherDashboard/_OtherDashboard';
import LMS from '../components/LMS/_LMS';
import SchoolSetup from '../components/Admin/SchoolSetup/_SchoolSetup';
import RegistrationPeriods from '../components/Admin/SchoolSetup/RegistrationPeriods';
import StudentAttendance from '../components/Students/StudentAttendance';
import StudentPastoralCare from '../components/Students/StudentPastoralCare';
import StudentGroups from '../components/Students/StudentGroups';
import StudentInformationAdmin from '../components/Students/StudentInformationAdmin';
import Discounts from '../components/Discounts/_Discounts';
import TermPolicies from '../components/TermPolicies/_TermPolicies';
import Services from '../components/Services/_Services';
import FeeStructure from '../components/FeeStructure/_FeeStructure';
import WeeklyClassPlan from '../components/WeeklyClassPlan/_WeeklyClassPlan';
import SubjectActions from '../components/SubjectActions/_SubjectActions';
import Calendar from '../components/Calendar/_Calendar';
import GradesAndAcademic from '../components/GradesAndAcademic/_GradesAndAcademic';
import ReportCohortExams from '../components/Reports/ReportCohortExams';
import ReportStudentIntegrity from '../components/Reports/ReportStudentIntegrity';
import StudentReports from '../components/Students/StudentReports';
import ReportClassAnalysis from '../components/Reports/ReportClassAnalysis';
import ReportSubjectAnalysis from '../components/Reports/ReportSubjectAnalysis';
import StudentPrintCards from '../components/Students/StudentPrintCards';
import TimetableManagement from '../components/TimetableManagement/_TimetableManagement';
import StudentPickDrop from "../components/StudentPickDrop/_StudentPickDrop";
import StudentRegistrationSimple from '../components/Students/StudentRegistrationSimple';
import CurriculumBuilder from '../components/CurriculumBuilder/_CurriculumBuilder';
import ClassAssignment from '../components/ClassAssignment/_ClassAssignment';
import AbsenceTypes from '../components/AbsenceTypes/_AbsenceTypes';
import ExamGroupsTypes from '../components/ExamGroupsTypes/_ExamGroupsTypes';
import EarlyChildhood from '../components/EarlyChildhood/_EarlyChildhood';
import ClassMain from '../components/ClassMain/_ClassMain';
import ReportVatInvoices from '../components/ReportVatInvoices/_ReportVatInvoices';
import ReportAttendance from '../components/ReportAttendance/_ReportAttendance';
import StudentAttendanceReport from '../components/StudentAttendanceReport/_StudentAttendanceReport';
import ClassAttendanceReport from '../components/ClassAttendanceReport/_ClassAttendanceReport';
import SchoolAbsentReport from '../components/SchoolAbsentReport/_SchoolAbsentReport';
import ElectiveSubjectStudentsReport from '../components/ElectiveSubjectStudentsReport/_ElectiveSubjectStudentsReport';
import ClassesAnalysisReport from '../components/ClassesAnalysisReport/_ClassesAnalysisReport';
import RegistrationsHistory from '../components/RegistrationsHistory/_RegistrationsHistory';
import RegisteredParentsReport from '../components/RegisteredParentsReport/_RegisteredParentsReport';
import RegisteredStudentsReport from '../components/RegisteredStudentsReport/_RegisteredStudentsReport';
import StudentsMasterReport from '../components/StudentsMasterReport/_StudentsMasterReport';
import StudentDemography from '../components/StudentDemography/_StudentDemography';
import HealthSetup from '../components/HealthSetup/_HealthSetup';
import CenterRoles from '../components/CenterRoles/_CenterRoles';
import TimetableDetailsGenerator from '../components/TimetableManagement/TimetableDetailsGenerator';
import StudentRegistrationAdvance from '../components/Students/StudentRegistrationAdvance';
import StudentCustomAttendance from '../components/Students/StudentCustomAttendance';
import ChatMonitor from '../components/ChatMonitor/_ChatMonitor';
import Classes from '../components/Classes/_Classes';
import Parents from '../components/Students/Parents';
// import ReportSubjectRubric from '../components/Reports/ReportSubjectRubric';
import ReportExamAnalysis from '../components/Reports/ReportExamAnalysis';
import ReportCard from '../components/Reports/ReportCard';
import ReportCardGenerator from '../components/ReportCardGenerator/_ReportCardGenerator';
import TermRegistrations from "../components/TermRegistrations/_TermRegistrations";
import StudentDocumentVerification from "../components/StudentDocumentVerification/_StudentDocumentVerification";
import BalanceByClass from "../components/BalanceByClass/_BalanceByClass";
import NationalitiesByClass from "../components/NationalitiesByClass/_NationalitiesByClass";
import EmployeeLeavesReport from "../components/EmployeeLeavesReport/EmployeeLeavesReport";
import EmployeeMasterReport from "../components/EmployeeMasterReport/_EmployeeMasterReport";
import EmployeeSummaryReport from "../components/EmployeeSummaryReport/_EmployeeSummaryReport";
import EmployeeExperienceReport from "../components/EmployeeExperienceReport/_EmployeeExperienceReport";
import ProReport from "../components/ProReport/_ProReport";
import ActiveRegistrations from "../components/ActiveRegistrations/_ActiveRegistrations";
import ActiveStudents from "../components/ActiveStudents/_ActiveStudents";
import ReportEmployeeAttendance from "../components/EmployeeAttendanceReport/_ReportEmployeeAttendance";
import ReportEmployeeDemography from "../components/EmployeeDemography/_ReportEmployeeDemography";
import SubjectRubricInput from '../components/_partials/Widgets/SubjectRubricInput';
import EmployeeEvaluationReport from "../components/Evaluations/EmployeeEvaluationReport/_EmployeeEvaluationReport";
import ExamGroups from '../components/ExamGroupsTypes/ExamGroups';
import ExamTypes from '../components/ExamGroupsTypes/ExamTypes';
import TermAgegroupAssessment from '../components/ExamGroupsTypes/TermAgegroupAssessment';
import Gradeslevel from '../components/ExamGroupsTypes/Gradeslevel';
import ReportSubjectRubricRatio from '../components/Reports/ReportSubjectRubricRatio';
import EvaluationMenu from '../components/Evaluations/EvaluationMenu';
import ReportAppraisalGroup from '../components/Evaluations/EmployeePerforma/ReportAppraisalGroup';
import Evaluations from '../components/Evaluations/_Evaluations';
import EvaluationSetup from '../components/Evaluations/EvaluationSetup/_EvaluationSetup';
import ReportResultRubric from '../components/Reports/ReportResultRubric';
import Journal from '../components/Journal/_Journal';
import ConsolidateDashboard from '../components/ConsolidateDashboard/_ConsolidateDashboard';
import ProgressAnalysis from '../components/ProgressAnalysis/_ProgressAnalysis';
import MergeClasses from '../components/MergeClasses/_MergeClasses';
import AwardsWarnings from '../components/AwardsWarnings/_AwardsWarnings';
import StudentsDashboard from '../components/Students/StudentsDashboard';

// import ReportInvoices from '../components/ReportCenter/AccountingReports/ReportInvoices';
// import ReportChequesInHand from '../components/ReportCenter/AccountingReports/';
// import ReportChequesList from '../components/ReportCenter/AccountingReports/';
// import ReportBalanceSheet from '../components/ReportCenter/FinancialReports/ReportBalanceSheet';
// import ReportTrialBalance from '../components/ReportCenter/FinancialReports/ReportTrialBalance';
// import ReportIncomeStatement from '../components/ReportCenter/FinancialReports/ReportIncomeStatement';
// import ReportVat from '../components/ReportCenter/VatReports/ReportVat';
// import ReportVatList from '../components/ReportCenter/VatReports/ReportVatList';
// import OperationsMenu from '../components/OperationsMenu/_OperationsMenu';
// import RegistrationsList from '../components/OperationsMenu/RegistrationsList';
// import RegistrationsAdvanceList from '../components/OperationsMenu/RegistrationsAdvanceList';
// import Invoices from '../components/OperationsMenu/Invoices';
// import Receipts from '../components/OperationsMenu/Receipts';
// import ReportSOA from '../components/OperationsMenu/ReportSOA';
// import BatchRegisteration from '../components/OperationsMenu/BatchRegisteration';
// import PaymentVoucher from '../components/Finance/PaymentVoucher';
// import Purchases from '../components/Finance/Purchases';
// import Expenses from '../components/Finance/Expenses';
// import FinanceNav from '../components/Finance/_FinanceNav';
// import CashExpenses from '../components/Finance/CashExpenses';
// import BankDeposits from '../components/Finance/BankDeposits';
// import ReplaceCheque from '../components/Finance/ReplaceCheque';
// import JournalVoucher from '../components/Finance/JournalVoucher';
// import BankReconciliation from '../components/Finance/BankReconciliation';
// import AccountsSetup from '../components/Accounts/';
// import VATAccounts from '../components/Accounts/';
// import Banks from '../components/Accounts/';
// import ReceiptsAccounts from '../components/Accounts/';
// import CostCenters from '../components/Accounts/';
// import ExpensesAccounts from '../components/Accounts/';
// import Suppliers from '../components/Accounts/';
// import AccountsSetupNav from '../components/Accounts/';
// import CRM from '../components/Admin/Dashboard/CRM/_CRM';
// import CRMNav from '../components/CRM/_CRM';
// import Leads from '../components/CRM/Leads';
// import EmailTemplates from '../components/CRM/EmailTemplates';
// import Referrals from '../components/CRM/Referrals';
// import VisitBy from '../components/CRM/VisitBy';
// import NoEnrollment from '../components/CRM/NoEnrollment';
// import HRPayroll from '../components/HRPayroll/_HRPayrollMenu';
// import DownloadMedia from '../components/DownloadMedia/_DownloadMedia';
const UserTimeline = lazy(() => import('../components/UserTimeline/_UserTimeline'));
const ConsolidatedCommunication = lazy(() => import('../components/UserTimeline/_ConsolidatedCommunication'));
const UTObservation = lazy(() => import('../components/UTObservation/_UTObservation'));
const UTObservationsReview = lazy(() => import('../components/UTObservationsReview/_UTObservationsReview'));

const EarlyLearningGoalsTracker = lazy(() => import('../components/EarlyLearningGoalsTracker/_EarlyLearningGoalsTracker'));

const ReportMoe = lazy(() => import('../components/Reports/ReportMoe'));
const ReportMoe_30052024 = lazy(() => import('../components/Reports/ReportMoe_30052024'));
const HRPayroll = lazy(() => import('../components/HRPayroll/_HRPayrollMenu'));

const CRM = lazy(() => import('../components/Admin/Dashboard/CRM/_CRM'));
const CRMNav = lazy(() => import('../components/CRM/_CRMNav'));
const Leads = lazy(() => import('../components/CRM/Leads'));
const EmailTemplates = lazy(() => import('../components/CRM/EmailTemplates'));
const Referrals = lazy(() => import('../components/CRM/Referrals'));
const VisitBy = lazy(() => import('../components/CRM/VisitBy'));
const NoEnrollment = lazy(() => import('../components/CRM/NoEnrollment'));

const AccountsSetup = lazy(() => import('../components/Accounts/_AccountsSetup'));
const VATAccounts = lazy(() => import('../components/Accounts/VATAccounts'));
const Banks = lazy(() => import('../components/Accounts/Banks'));
const ReceiptsAccounts = lazy(() => import('../components/Accounts/ReceiptsAccounts'));
const CostCenters = lazy(() => import('../components/Accounts/CostCenters'));
const ExpensesAccounts = lazy(() => import('../components/Accounts/ExpensesAccounts'));
const Suppliers = lazy(() => import('../components/Accounts/Suppliers'));
const AccountsSetupNav = lazy(() => import('../components/Accounts/_AccountsSetupNav'));

const OperationsNav = lazy(() => import('../components/Operations/_OperationsNav'));
const ChangeClass = lazy(() => import('../components/Operations/ChangeClass'));
const RegistrationsList = lazy(() => import('../components/Operations/RegistrationsList'));
const RegistrationsProformaList = lazy(() => import('../components/Operations/RegistrationsProformaList'));
const RegistrationsAdvanceList = lazy(() => import('../components/Operations/RegistrationsAdvanceList'));
const Invoices = lazy(() => import('../components/Operations/Invoices'));
const Receipts = lazy(() => import('../components/Operations/Receipts'));
const ReportSOA = lazy(() => import('../components/Operations/ReportSOA'));
const BatchRegistration = lazy(() => import('../components/Operations/BatchRegistration'));

const PaymentVoucher = lazy(() => import('../components/Finance/PaymentVoucher'));
const Purchases = lazy(() => import('../components/Finance/Purchases'));
const Expenses = lazy(() => import('../components/Finance/Expenses'));
const DirectExpenses = lazy(() => import('../components/Finance/DirectExpenses'));
const FinanceMain = lazy(() => import('../components/Finance/_FinanceMain'));
const FinanceNav = lazy(() => import('../components/Finance/FinanceNav'));
const CashExpenses = lazy(() => import('../components/Finance/CashExpenses'));
const BankDeposits = lazy(() => import('../components/Finance/BankDeposits'));
const ReplaceCheque = lazy(() => import('../components/Finance/ReplaceCheque'));
const JournalVoucher = lazy(() => import('../components/Finance/JournalVoucher'));
const BankReconciliation = lazy(() => import('../components/Finance/BankReconciliation'));

const AlumniStudents = lazy(() => import('../components/Alumni/AlumniStudents'));
const AlumniCommunication = lazy(() => import('../components/Alumni/AlumniCommunication'));

const ReportInvoices = lazy(() => import('../components/ReportCenter/AccountingReports/ReportInvoices'));
const RevenueRegister = lazy(() => import('../components/ReportCenter/AccountingReports/RevenueRegister'));
const ReportBillOfEntries = lazy(() => import('../components/ReportCenter/AccountingReports/ReportBillOfEntries'));
const ReportStudentStatement = lazy(() => import('../components/ReportCenter/AccountingReports/ReportStudentStatement'));
const ReportChequesInHand = lazy(() => import('../components/ReportCenter/AccountingReports/ReportChequesInHand'));
const ReportChequesList = lazy(() => import('../components/ReportCenter/AccountingReports/ReportChequesList'));
const ReportBalanceSheet = lazy(() => import('../components/ReportCenter/FinancialReports/ReportBalanceSheet'));
const ReportTrialBalance = lazy(() => import('../components/ReportCenter/FinancialReports/ReportTrialBalance'));
const ReportIncomeStatement = lazy(() => import('../components/ReportCenter/FinancialReports/ReportIncomeStatement'));
const ReportVat = lazy(() => import('../components/ReportCenter/VatReports/ReportVat'));
const ReportVatList = lazy(() => import('../components/ReportCenter/VatReports/ReportVatList'));
const DownloadMedia = lazy(() => import('../components/DownloadMedia/_DownloadMedia'));
const Album = lazy(() => import('../components/DownloadMedia/Album'));

const ReportCenter = lazy(() => import('../components/ReportCenter/_ReportCenter'));
const HRReports = lazy(() => import('../components/ReportCenter/HRReports'));
const AdminReports = lazy(() => import('../components/ReportCenter/AdminReports'));
const StudentAdminReports = lazy(() => import('../components/Students/AdminReports'));
const AttendanceReports = lazy(() => import('../components/ReportCenter/AttendanceReports'));
const FinancialReports = lazy(() => import('../components/ReportCenter/FinancialReports'));
const AcademicReports = lazy(() => import('../components/ReportCenter/AcademicReports'));
const PayrollSetup = lazy(() => import('../components/PayrollSetup/_PayrollSetup'));
const AbsencesSetup = lazy(() => import('../components/PayrollSetup/AbsencesSetup'));
const AdditionsSetup = lazy(() => import('../components/PayrollSetup/AdditionsSetup'));
const AllowancesSetup = lazy(() => import('../components/PayrollSetup/AllowancesSetup'));
const DeductionsSetup = lazy(() => import('../components/PayrollSetup/DeductionsSetup'));
const LeavesSetup = lazy(() => import('../components/PayrollSetup/LeavesSetup'));
const PRSetup = lazy(() => import('../components/PayrollSetup/Setup'));
const Payroll = lazy(() => import('../components/Payroll/Payroll'));
const MonthlyPayroll = lazy(() => import('../components/Payroll/MonthlyPayroll'));
const VatReports = lazy(() => import('../components/ReportCenter/VatReports'));
const AccountingReports = lazy(() => import('../components/ReportCenter/AccountingReports'));
const StudentPromotion = lazy(() => import('../components/StudentPromotion/_StudentPromotion'));
const Navigation = lazy(() => import('../components/Navigation/_Navigation'));
const CenterNav = lazy(() => import('../components/Navigation/CenterNav'));
const NavTree = lazy(() => import('../components/Navigation/NavTree'));
const NavManager = lazy(() => import('../components/Navigation/NavManager'));
const Withdrawal = lazy(() => import('../components/Withdrawal/_Withdrawal'));
const WithdrawalSetup = lazy(() => import('../components/Withdrawal/WithdrawalSetup'));
const WithdrawalView = lazy(() => import('../components/Withdrawal/WithdrawalView'));
const WithdrawalAdmin = lazy(() => import('../components/Withdrawal/WithdrawalAdmin'));
const WithdrawalManager = lazy(() => import('../components/Withdrawal/WithdrawalManager'));
const WithdrawalAccountant = lazy(() => import('../components/Withdrawal/WithdrawalAccountant'));
const WithdrawalParent = lazy(() => import('../components/Withdrawal/WithdrawalParent'));
const PayrollMain = lazy(() => import('../components/Payroll/_PayrollMain'));
const LeadsFollowUpReport = lazy(() => import('../components/ReportCenter/LeadsFollowUpReport'));
const EmployeeAbsences = lazy(() => import('../components/Payroll/EmployeeAbsences'));
const EmployeeAdditions = lazy(() => import('../components/Payroll/EmployeeAdditions'));
const EmployeeDeductions = lazy(() => import('../components/Payroll/EmployeeDeductions'));
const EmployeeAllowances = lazy(() => import('../components/Payroll/EmployeeAllowances'));
const EmployeeLeaves = lazy(() => import('../components/Payroll/EmployeeLeaves'));
const EmployeesMonthlyAttendanceReport = lazy(() => import('../components/EmployeesMonthlyAttendanceReport/_EmployeesMonthlyAttendanceReport'));
const EmployeeTimetableAvailability = lazy(() => import('../components/EmployeeTimetableAvailability/_EmployeeTimetableAvailability'));
const TimetablesGenerator = lazy(() => import('../components/TimetablesGenerator/_TimetablesGenerator'));
const TimetableMain = lazy(() => import('../components/Timetable/_TimetableMain'));

export const NavigationData = [
  {
    id: 'dashboard',
    name: 'Dashboard',
    path: '/dashboard',
    element: <Dashboard />
  },
  {
    id: 'class-blog',
    name: 'Class Blog',
    path: '/class-blog',
    element: <ClassBlog />
  },
  {
    id: 'activities',
    name: 'Activities',
    path: '/activities',
    element: <Activities />
  },
  {
    id: 'subjects',
    name: 'Subjects',
    path: '/subjects',
    element: <Subjects />
  },
  {
    id: 'lessons',
    name: 'Lessons',
    path: '/subject/lessons',
    element: <Lessons />
  },
  {
    id: 'lesson',
    name: 'Lesson',
    path: '/subject/lesson/planner',
    element: <Lesson />
  },
  {
    id: 'weekly-lesson-plans',
    name: 'Weekly Lesson Plans',
    path: '/weekly-lesson-plans',
    element: <WeeklyLessonPlans />
  },
  {
    id: 'weekly-lesson-planner',
    name: 'Weekly Lesson Planner',
    path: '/weekly-lesson-plans/:acs_id/:tp_id/:tt_id/:acs_name',
    element: <Planner />
  },
  {
    id: 'user-timeline',
    name: 'Communication',
    path: '/user-timeline',
    element: <UserTimeline />
  },
  {
    id: 'consolidated-communication',
    name: 'Consolidated Communication',
    path: '/consolidated-communication',
    element: <ConsolidatedCommunication />
  },
  {
    id: 'assigned-activity',
    name: 'Assigned Activities',
    path: '/assigned-activity',
    element: <AssignedActivity />
  },
  {
    id: 'tasks-list',
    name: 'Tasks List',
    path: '/tasks-list',
    element: <ClassView />
  },
  // {
  //   id: 'projects',
  //   name: 'Projects',
  //   path: '/projects',
  //   element: <Projects />
  // },
  {
    id: 'exams',
    name: 'Exams',
    path: '/exams',
    element: <Exams examstypegrp_type="Exam" />
  },
  {
    id: 'exams',
    name: 'Homework',
    path: '/homework',
    element: <Exams examstypegrp_type="Homework" />
  },
  {
    id: 'exams',
    name: 'Assignment',
    path: '/assignment',
    element: <Exams examstypegrp_type="Assignment" />
  },
  {
    id: 'exams',
    name: 'Project',
    path: '/project',
    element: <Exams examstypegrp_type="Project" />
  },
  {
    id: 'exams',
    name: 'Other Assessment',
    path: '/other-assessment',
    element: <Exams examstypegrp_type="Other" />
  },
  {
    id: 'student-tracker',
    name: 'Student Attainment',
    path: '/student-tracker',
    element: <StudentTracker />
  },
  {
    id: 'student-attainment',
    name: 'Student Attainment',
    path: '/student-attainment',
    element: <StudentAttainment />
  },
  {
    id: 'assessment-coverage',
    name: 'Class Tracker',
    path: '/assessment-coverage',
    element: <AssessmentCoverage />
  },
  {
    id: 'student-journal',
    name: 'Journal',
    path: '/student-assessment',
    element: <StudentAssessment />
  },
  // {
  //     id: 'ey-report',
  //     name: 'Journal',
  //     path: '/ey-report',
  //     element: <EYReport />
  // },
  {
    id: 'report-exams',
    name: 'Report Exams',
    path: '/report-exams',
    element: <ReportExams />
  },
  {
    id: 'report-class-analysis',
    name: 'Report Class Analysis',
    path: '/report-class-analysis',
    element: <ReportClassAnalysis />
  },
  {
    id: 'report-subject-analysis',
    name: 'Report Subject Analysis',
    path: '/report-subject-analysis',
    element: <ReportSubjectAnalysis />
  },
  // {
  //     id: 'report-subject-rubric',
  //     name: 'Report Subject Rubric',
  //     path: '/report-subject-rubric',
  //     element: <ReportSubjectRubric />
  // },
  {
    id: 'report-exam-analysis',
    name: 'Report Exam Analysis',
    path: '/report-exam-analysis',
    element: <ReportExamAnalysis />
  },
  {
    id: 'report-result-rubric',
    name: 'Report Result Rubric',
    path: '/report-result-rubric',
    element: <ReportResultRubric />
  },
  {
    id: 'report-moe',
    name: 'Report MOE',
    path: '/report-moe',
    element: <ReportMoe />
  },
  {
    id: 'report-moe-old',
    name: 'Report MOE',
    path: '/report-moe-old',
    element: <ReportMoe_30052024 />
  },
  {
    id: 'report-subject-rubric-ratio',
    name: 'Report Subject Rubric Ratio',
    path: '/report-subject-rubric-ratio',
    element: <ReportSubjectRubricRatio />
  },
  {
    id: 'subject-rubric-input',
    name: 'Subject Rubric Input',
    path: '/subject-rubric-input',
    element: <SubjectRubricInput />
  },
  {
    id: 'report-curriculum-result',
    name: 'Objective Result',
    path: '/report-curriculum-result',
    element: <ReportCurriculumResult />
  },
  {
    id: 'report-student-integrity',
    name: 'Student Integrity',
    path: '/report-student-integrity',
    element: <ReportStudentIntegrity />
  },
  {
    id: 'report-card',
    name: 'Student Card',
    path: '/report-card',
    element: <ReportCard />
  },
  {
    id: 'report-card-generator',
    name: 'Report Card Generator',
    path: '/report-card-generator',
    element: <ReportCardGenerator />
  },
  {
    id: 'report-cohort-exams',
    name: 'Cohort Exams',
    path: '/report-cohort-exams',
    element: <ReportCohortExams />
  },
  {
    id: 'curriculum-builder',
    name: 'Curriculum Builder',
    path: '/curriculum-builder',
    element: <CurriculumBuilder />
  },
  {
    id: 'appointments',
    name: 'Appointments',
    path: '/appointments',
    element: <Appointments />
  },
  {
    id: 'admin-dashboard',
    name: 'Admin Dashboard',
    path: '/admin/dashboard',
    element: <AdminDashboard />,
    children: [
      {
        id: 'admin-dashboard-main',
        name: 'Dashboard',
        path: 'main',
        element: <Main />
      },
      {
        id: 'admin-dashboard-other',
        name: 'Dashboard',
        path: 'other',
        element: <OtherDashboard />
      },
      {
        id: 'admin-dashboard-crm',
        name: 'CRM',
        path: 'crm',
        element: <CRM />
      },
      {
        id: 'admin-dashboard-admissions',
        name: 'Admissions',
        path: 'admissions',
        element: <Admissions />
      },
      {
        id: 'admin-dashboard-hr',
        name: 'HR',
        path: 'hr',
        element: <HR />
      },
    ]
  },
  {
    id: 'lms',
    name: 'LMS',
    path: '/lms',
    element: <LMS />,
  },
  {
    id: 'admin-school-setup',
    name: 'School Setup',
    path: '/admin/school-setup',
    element: <SchoolSetup />
  },
  {
    id: 'curriculum-builder',
    name: 'Curriculum Builder',
    path: '/curriculum-builder',
    element: <CurriculumBuilder />
  },
  {
    id: 'students',
    name: 'Students',
    path: '/students',
    element: <Students />,
    children: [
      {
        id: 'student-information',
        name: 'Students',
        path: 'student-information',
        element: <StudentInformationAdmin />
      },
      {
        id: 'student-reports',
        name: 'Student Reports',
        path: 'student-reports',
        element: <StudentReports />
      },
      {
        id: 'student-registration-simple',
        name: 'Student Registration',
        path: 'student-registration-simple',
        element: <StudentRegistrationSimple />
      },
      {
        id: 'student-registration-advance',
        name: 'Student Registration Advance',
        path: 'student-registration-advance',
        element: <StudentRegistrationAdvance />
      },
      {
        id: 'students',
        name: 'Students',
        path: 'information',
        element: <StudentInformation />
      },
      {
        id: 'parents',
        name: 'Parents',
        path: 'parents',
        element: <Parents />
      },
      {
        id: 'student-attendance',
        name: 'Attendance',
        path: 'attendance',
        element: <StudentAttendance />
      },
      {
        id: 'student-custom-attendance',
        name: 'Custom Attendance',
        path: 'custom-attendance',
        element: <StudentCustomAttendance />
      },
      {
        id: 'student-pastoral-care',
        name: 'Pastoral Care',
        path: 'pastoral-care',
        element: <StudentPastoralCare />
      },
      {
        id: 'student-groups',
        name: 'Groups',
        path: 'groups',
        element: <StudentGroups />
      },
      {
        id: 'student-print-cards',
        name: 'Print Cards',
        path: 'student-print-cards',
        element: <StudentPrintCards />
      },
      {
        id: 'registrations-proforma-list',
        name: 'Registrations Proforma',
        path: 'registrations-proforma-list',
        element: <RegistrationsProformaList />
      },
    ]
  },
  {
    id: 'alumni-students',
    name: 'Alumni Students',
    path: 'alumni-students',
    element: <AlumniStudents />,
  },
  // {
  //   id: 'employees',
  //   name: 'Employees',
  //   path: 'list',
  //   element: <List />,
  //   parent: {
  //     path: 'employees',
  //     element: <Employees />,
  //   }
  // },
  // {
  //   id: 'employee-print-cards',
  //   name: 'Employees',
  //   path: 'employee-print-cards',
  //   element: <EmployeePrintCards />,
  //   parent: {
  //     path: 'employees',
  //     element: <Employees />,
  //   }
  // },
  {
    id: 'class-assignment',
    name: 'Class Assignment',
    path: '/class-assignment',
    element: <ClassAssignment />,
  },
  {
    id: 'discounts',
    name: 'Discounts',
    path: '/discounts',
    element: <Discounts />
  },
  {
    id: 'termpolicies',
    name: 'Term Fee Policies',
    path: '/termpolicies',
    element: <TermPolicies />
  },
  {
    id: 'services',
    name: 'Services',
    path: '/services',
    element: <Services />
  },

  {
    id: 'catfees',
    name: 'Fee Structure',
    path: '/catfees',
    element: <FeeStructure />
  },
  {
    id: 'weekly-class-plan',
    name: 'Weekly Class Plan',
    path: '/weekly-class-plan',
    element: <WeeklyClassPlan />
  },
  {
    id: 'subject-actions',
    name: 'Subject Actions',
    path: '/subject-actions',
    element: <SubjectActions />
  },
  {
    id: 'grades-academic',
    name: 'Grades & Academic',
    path: '/grades-academic',
    element: <GradesAndAcademic />
  },
  {
    id: 'employee-attendance-report',
    name: 'Employee Attendance Report',
    path: '/employee-attendance-report',
    element: <ReportEmployeeAttendance />
  },
  {
    id: 'employee-demography',
    name: 'Employee Demography',
    path: '/employee-demography',
    element: <ReportEmployeeDemography />
  },
  {
    id: 'calendar',
    name: 'Calendar',
    path: '/calendar',
    element: <Calendar />
  },
  {
    id: 'registration-periods',
    name: 'Registration Periods',
    path: '/registration-periods',
    element: <RegistrationPeriods />
  },
  {
    id: 'timetable-management',
    name: 'Timetable Management',
    path: '/timetable-management',
    element: <TimetableManagement />
  },
  {
    id: 'timetable-details-generator',
    name: 'Timetable Details Generator',
    path: '/timetable-details-generator',
    element: <TimetableDetailsGenerator />
  },
  {
    id: 'student-pick-drop',
    name: 'Student Pick Drop',
    path: '/student-pick-drop',
    element: <StudentPickDrop />
  },
  {
    id: 'absence-types',
    name: 'Absence Types',
    path: '/absence-types',
    element: <AbsenceTypes />
  },
  {
    id: 'early-childhood',
    name: 'Early Childhood',
    path: '/early-childhood',
    element: <EarlyChildhood />
  },
  {
    id: 'class-main',
    name: 'Class Main',
    path: '/class-main',
    element: <ClassMain />
  },
  {
    id: 'classes',
    name: 'Classes',
    path: '/classes',
    element: <Classes />
  },
  {
    id: 'report-vat-invoices',
    name: 'Report VAT Invoices',
    path: '/report-vat-invoices',
    element: <ReportVatInvoices />
  },
  {
    id: 'report-attendance',
    name: 'Report Attendance',
    path: '/report-attendance',
    element: <ReportAttendance />
  },
  {
    id: 'employees-monthly-attendance-report',
    name: 'Employees Monthly Attendance Report',
    path: '/employees-monthly-attendance-report',
    element: <EmployeesMonthlyAttendanceReport />
  },
  {
    id: 'chat-monitor',
    name: 'Chat Monitor',
    path: '/chat-monitor',
    element: <ChatMonitor />
  },
  {
    id: 'student-attendance-report',
    name: 'Student Attendance Report',
    path: '/student-attendance-report',
    element: <StudentAttendanceReport />
  },
  {
    id: 'class-attendance-report',
    name: 'Class Attendance Report',
    path: '/class-attendance-report',
    element: <ClassAttendanceReport />
  },
  {
    id: 'school-absent-report',
    name: 'School Absent Report',
    path: '/school-absent-report',
    element: <SchoolAbsentReport />
  },
  {
    id: 'report-balance-sheet',
    name: 'Balance Sheet Report',
    path: '/report-balance-sheet',
    element: <ReportBalanceSheet />
  },
  {
    id: 'report-trial-balance',
    name: 'Trial Balance',
    path: '/report-trial-balance',
    element: <ReportTrialBalance />
  },
  {
    id: 'report-income-statement',
    name: 'Income Statement',
    path: '/report-income-statement',
    element: <ReportIncomeStatement />
  },
  {
    id: 'report-vat',
    name: 'VAT Report',
    path: '/report-vat',
    element: <ReportVat />
  },
  {
    id: 'report-vat-list',
    name: 'VAT List',
    path: '/report-vat-list',
    element: <ReportVatList />
  },
  {
    id: 'report-cheques-in-hand',
    name: 'Cheques In Hand',
    path: '/report-cheques-in-hand',
    element: <ReportChequesInHand />
  },
  {
    id: 'report-cheques-list',
    name: 'Cheques Report',
    path: '/report-cheques-list',
    element: <ReportChequesList />
  },
  {
    id: 'report-invoices',
    name: 'Invoices Report',
    path: '/report-invoices',
    element: <ReportInvoices />
  },
  {
    id: 'report-student-statement',
    name: 'Student Statement',
    path: '/report-student-statement',
    element: <ReportStudentStatement />
  },
  {
    id: 'report-bill-of-entries',
    name: 'Parent Statement',
    path: '/report-bill-of-entries',
    element: <ReportBillOfEntries />
  },
  {
    id: 'revenue-register',
    name: 'Revenue Register',
    path: '/revenue-register',
    element: <RevenueRegister />
  },
  {
    id: 'elective-subject-students-report',
    name: 'Elective Subject Students Report',
    path: '/elective-subject-students-report',
    element: <ElectiveSubjectStudentsReport />
  },
  {
    id: 'classes-analysis-report',
    name: 'Classes Analysis Report',
    path: '/classes-analysis-report',
    element: <ClassesAnalysisReport />
  },
  {
    id: 'registrations-history',
    name: 'Registrations History',
    path: '/registrations-history',
    element: <RegistrationsHistory />
  },
  {
    id: 'registered-parents-report',
    name: 'Registered Parents Report',
    path: '/registered-parents-report',
    element: <RegisteredParentsReport />
  },
  {
    id: 'registered-students-report',
    name: 'Registered students Report',
    path: '/registered-students-report',
    element: <RegisteredStudentsReport />
  },
  {
    id: 'term-registrations',
    name: 'Term Registrations',
    path: '/term-registrations',
    element: <TermRegistrations />
  },
  {
    id: 'student-document-verification',
    name: 'Student Document Verification',
    path: '/student-document-verification',
    element: <StudentDocumentVerification />
  },
  {
    id: 'balance-by-class',
    name: 'Balance By Class',
    path: '/balance-by-class',
    element: <BalanceByClass />
  },
  {
    id: 'nationalities-by-class',
    name: 'Nationalities By Class',
    path: '/nationalities-by-class',
    element: <NationalitiesByClass />
  },
  {
    id: 'employee-leaves-report',
    name: 'Employee Leaves Report',
    path: '/employee-leaves-report',
    element: <EmployeeLeavesReport />
  },
  {
    id: 'employee-master-report',
    name: 'Employee Master Report',
    path: '/employee-master-report',
    element: <EmployeeMasterReport />
  },
  {
    id: 'employee-summary-report',
    name: 'Employee Summary Report',
    path: '/employee-summary-report',
    element: <EmployeeSummaryReport />
  },
  {
    id: 'employee-experience-report',
    name: 'Employee Experience Report',
    path: '/employee-experience-report',
    element: <EmployeeExperienceReport />
  },
  {
    id: 'active-registrations',
    name: 'Active Registrations',
    path: '/active-registrations',
    element: <ActiveRegistrations />
  },
  {
    id: 'active-students',
    name: 'Active Students',
    path: '/active-students',
    element: <ActiveStudents />
  },
  {
    id: 'pro-report',
    name: 'Pro Report',
    path: '/pro-report',
    element: <ProReport />
  },
  {
    id: 'students-master-report',
    name: 'Students Master Report',
    path: '/students-master-report',
    element: <StudentsMasterReport />
  },
  {
    id: 'observation',
    name: 'Observation',
    path: '/observation',
    element: <UTObservation />
  },
  // {
  //   id: 'students-demography-report',
  //   name: 'Students Demography Report',
  //   path: '/students-demography-report',
  //   element: <StudentsDemographyReport />
  // },
  {
    id: 'health-setup',
    name: 'Health Setup',
    path: '/health-setup',
    element: <HealthSetup />
  },
  {
    id: 'center-roles',
    name: 'Roles',
    path: '/center-roles',
    element: <CenterRoles />
  },
  {
    id: 'student-demography',
    name: 'Student Demography',
    path: '/student-demography',
    element: <StudentDemography />
  },
  {
    id: 'hr-payroll',
    name: 'HR & Payroll',
    path: '/hr-payroll',
    element: <HRPayroll />
  },
  // {
  //     id: 'evaluations',
  //     name: 'Evaluations',
  //     path: '/evaluations',
  //     path: <Evaluations />,
  // },
  // {
  //     id: 'report-appraisal-group',
  //     name: 'Report Appraisal Group',
  //     path: '/report-appraisal-group',
  //     path: <ReportAppraisalGroup />,
  // },
  // {
  //   id: 'admin-dashboard',
  //   name: 'Admin Dashboard',
  //   path: '/admin-dashboard',
  //   element: <Dashboard />
  // },
  {
    id: 'journal',
    name: 'Journal',
    path: '/journal',
    element: <Journal />
  },
  {
    id: 'consolidate-dashboard',
    name: 'Consolidate Dashboard',
    path: '/consolidate-dashboard',
    element: <ConsolidateDashboard />
  },
  {
    id: 'finance-main',
    name: 'Finance Main',
    path: '/finance-main',
    element: <FinanceMain />
  },
  {
    id: 'finance',
    name: 'Finance',
    path: '/finance',
    element: <FinanceNav />,
    children: [
      {
        id: 'expenses',
        name: 'expenses',
        path: 'expenses',
        element: <Expenses />
      },
      {
        id: 'direct-expenses',
        name: 'Direct Expenses',
        path: 'direct-expenses',
        element: <DirectExpenses />
      },
      {
        id: 'payment-voucher',
        name: 'Payment Voucher',
        path: 'payment-voucher',
        element: <PaymentVoucher />
      },
      {
        id: 'cash-expenses',
        name: 'Cash Expenses',
        path: 'cash-expenses',
        element: <CashExpenses />
      },
      {
        id: 'purchases',
        name: 'Purchases',
        path: 'purchases',
        element: <Purchases />
      },
      {
        id: 'bank-deposits',
        name: 'Bank Transactions',
        path: 'bank-deposits',
        element: <BankDeposits />
      },
      {
        id: 'replace-cheque',
        name: 'Cheque Replacement',
        path: 'replace-cheque',
        element: <ReplaceCheque />
      },
      {
        id: 'journal-voucher',
        name: 'Journal Voucher',
        path: 'journal-voucher',
        element: <JournalVoucher />
      },
      {
        id: 'bank-reconciliation',
        name: 'Bank Reconciliation',
        path: 'bank-reconciliation',
        element: <BankReconciliation />
      },
      {
        id: 'financial-reports',
        name: 'Financial Reports',
        path: 'financial-reports',
        element: <FinancialReports />
      },
    ]
  },
  {
    id: 'accounts',
    name: 'Accounts',
    path: '/accounts',
    element: <AccountsSetupNav />,
    children: [
      {
        id: 'accounts-setup',
        name: 'Accounts Setup',
        path: 'accounts-setup',
        element: <AccountsSetup />
      },
      {
        id: 'vat-accounts',
        name: 'VAT Accounts',
        path: 'vat-accounts',
        element: <VATAccounts />
      },
      {
        id: 'banks',
        name: 'Banks',
        path: 'banks',
        element: <Banks />
      },
      {
        id: 'receipts-accounts',
        name: 'Receipts Accounts',
        path: 'receipts-accounts',
        element: <ReceiptsAccounts />
      },
      {
        id: 'cost-centers',
        name: 'Cost Centers',
        path: 'cost-centers',
        element: <CostCenters />
      },
      {
        id: 'expenses-accounts',
        name: 'Expenses Accounts',
        path: 'expenses-accounts',
        element: <ExpensesAccounts />
      },
      {
        id: 'suppliers',
        name: 'Suppliers',
        path: 'suppliers',
        element: <Suppliers />
      },
    ]
  },
  {
    id: 'observations-review',
    name: 'Observations Review',
    path: '/observations-review',
    element: <UTObservationsReview />
  },
  {
    id: 'progress-analysis',
    name: 'Progress Analysis',
    path: '/progress-analysis',
    element: <ProgressAnalysis />
  },
  {
    id: 'merge-classes',
    name: 'Merge Classes',
    path: '/merge-classes',
    element: <MergeClasses />
  },
  {
    id: 'awards-warnings',
    name: 'Awards/Warnings',
    path: '/awards-warnings',
    element: <AwardsWarnings />
  },
  {
    id: 'download-media',
    name: 'Download Media',
    path: '/download-media',
    element: <DownloadMedia />
  },
  {
    id: 'album',
    name: 'Album',
    path: '/album',
    element: <Album />,
  },
  {
    id: 'admin-reports',
    name: 'Admin Reports',
    path: '/admin-reports',
    element: <StudentAdminReports />,
  },
  {
    id: 'students-dashboard',
    name: 'Students Dashboard',
    path: '/students-dashboard',
    element: <StudentsDashboard />,
  },
  {
    id: 'alumni-communication',
    name: 'Alumni Communication',
    path: 'alumni-communication',
    element: <AlumniCommunication />,
  },
  {
    id: 'crm',
    name: 'CRM',
    path: '/crm',
    element: <CRMNav />,
    children: [
      {
        id: 'crm-dashboard',
        name: 'CRM Dashboard',
        path: 'dashboard',
        element: <CRM />
      },
      {
        id: 'waiting',
        name: 'Leads',
        path: 'waiting',
        element: <Leads />
      },
      {
        id: 'center-email-templates',
        name: 'Center Email Templates',
        path: 'center-email-templates',
        element: <EmailTemplates />
      },
      {
        id: 'referrals',
        name: 'Referrals',
        path: 'referrals',
        element: <Referrals />
      },
      {
        id: 'visitby',
        name: 'Communication Source',
        path: 'visitby',
        element: <VisitBy />
      },
      {
        id: 'noenrolment',
        name: 'No Enrollment Reasons',
        path: 'noenrolment',
        element: <NoEnrollment />
      },
    ]
  },
  {
    id: 'operations',
    name: 'Operations',
    path: '/operations',
    element: <OperationsNav />,
    children: [
      {
        id: 'registrations-proforma-list',
        name: 'Registrations Proforma',
        path: 'registrations-proforma-list',
        element: <RegistrationsProformaList />
      },
      {
        id: 'registrations-list',
        name: 'Registrations List',
        path: 'registrations-list',
        element: <RegistrationsList />
      },
      {
        id: 'registrations-advance-list',
        name: 'Registrations Invoice',
        path: 'registrations-advance-list',
        element: <RegistrationsAdvanceList />
      },
      {
        id: 'invoices',
        name: 'Invoices',
        path: 'invoices',
        element: <Invoices />
      },
      {
        id: 'receipts',
        name: 'Receipts',
        path: 'receipts',
        element: <Receipts />
      },
      {
        id: 'report-soa',
        name: 'Statement of Account',
        path: 'report-soa',
        element: <ReportSOA />
      },
      {
        id: 'batch-registration',
        name: 'Batch Registration',
        path: 'batch-registration',
        element: <BatchRegistration />
      },
      {
        id: 'change-class',
        name: 'Change Class',
        path: 'change-class',
        element: <ChangeClass />
      },
    ]
  },
  {
    id: 'report-center',
    name: 'Report Center',
    path: '/report-center',
    element: <ReportCenter />,
    children: [
      {
        id: 'hr-reports',
        name: 'HR Reports',
        path: 'hr-reports',
        element: <HRReports />
      },
      {
        id: 'admin-reports',
        name: 'Admin Reports',
        path: 'admin-reports',
        element: <AdminReports />
      },
      {
        id: 'attendance-reports',
        name: 'Attendance Reports',
        path: 'attendance-reports',
        element: <AttendanceReports />
      },
      {
        id: 'financial-reports',
        name: 'Financial Reports',
        path: 'financial-reports',
        element: <FinancialReports />
      },
      {
        id: 'accounting-reports',
        name: 'Accounting Reports',
        path: 'accounting-reports',
        element: <AccountingReports />
      },
      {
        id: 'vat-reports',
        name: 'Vat Reports',
        path: 'vat-reports',
        element: <VatReports />
      },
      {
        id: 'academic-reports',
        name: 'Academic Reports',
        path: 'academic-reports',
        element: <AcademicReports />
      },
    ]
  },
  {
    id: 'payroll-setup',
    name: 'Payroll Setup',
    path: '/payroll-setup',
    element: <PayrollSetup />,
    children: [
      {
        id: 'absences',
        name: 'Absences Setup',
        path: 'absences',
        element: <AbsencesSetup />
      },
      {
        id: 'additions',
        name: 'Additions Setup',
        path: 'additions',
        element: <AdditionsSetup />
      },
      {
        id: 'allowances',
        name: 'Allowances Setup',
        path: 'allowances',
        element: <AllowancesSetup />
      },
      {
        id: 'deductions',
        name: 'Deductions Setup',
        path: 'deductions',
        element: <DeductionsSetup />
      },
      {
        id: 'leaves',
        name: 'Leaves Setup',
        path: 'leaves',
        element: <LeavesSetup />
      },
      {
        id: 'setup',
        name: 'PR Setup',
        path: 'setup',
        element: <PRSetup />
      },
    ]
  },
  {
    id: 'payroll',
    name: 'Payroll',
    path: '/payroll',
    element: <Payroll />,
    children: [
      {
        id: 'monthly-payroll',
        name: 'Monthly Payroll',
        path: 'monthly-payroll',
        element: <MonthlyPayroll />
      },
      {
        id: 'employee-absences',
        name: 'Employee Absences',
        path: 'employee-absences',
        element: <EmployeeAbsences />
      },
      {
        id: 'employee-additions',
        name: 'Employee Additions',
        path: 'employee-additions',
        element: <EmployeeAdditions />
      },
      {
        id: 'employee-deductions',
        name: 'Employee Deductions',
        path: 'employee-deductions',
        element: <EmployeeDeductions />
      },
      {
        id: 'employee-allowances',
        name: 'Employee Allowances',
        path: 'employee-allowances',
        element: <EmployeeAllowances />
      },
      {
        id: 'employee-leaves',
        name: 'Employee Leaves',
        path: 'employee-leaves',
        element: <EmployeeLeaves />
      },
    ]
  },
  {
    id: 'payroll-main',
    name: 'Payroll Main',
    path: '/payroll-main',
    element: <PayrollMain />,
  },
  {
    id: 'exam-groups-types',
    name: 'Exam Groups & Types',
    path: '/exam-groups-types',
    element: <ExamGroupsTypes />,
    children: [
      {
        id: 'exam-groups-types-1',
        name: 'Groups',
        path: 'exam-groups-types-1',
        element: <ExamGroups />
      },
      {
        id: 'exam-groups-types-2',
        name: 'Types',
        path: 'exam-groups-types-2',
        element: <ExamTypes />
      },
      {
        id: 'exam-groups-types-4',
        name: 'Marks Percentage',
        path: 'exam-groups-types-4',
        element: <TermAgegroupAssessment />
      },
      {
        id: 'exam-groups-types-3',
        name: 'Marks Definition',
        path: 'exam-groups-types-3',
        element: <Gradeslevel />
      },
    ]
  },
  {
    id: 'evaluation-menu',
    name: 'Evaluation Menu',
    path: '/evaluation-menu',
    element: <EvaluationMenu />,
    children: [
      {
        id: 'evaluations',
        name: 'Evaluations',
        path: 'evaluations',
        element: <Evaluations />
      },
      {
        id: 'report-appraisal-group',
        name: 'Report Appraisal Group',
        path: 'report-appraisal-group',
        element: <ReportAppraisalGroup />
      },
      {
        id: 'employee-evaluation-report',
        name: 'Employee Evaluation Report',
        path: 'employee-evaluation-report',
        element: <EmployeeEvaluationReport />
      },
      {
        id: 'evaluation-setup',
        name: 'Evaluation Setup',
        path: 'employee-evaluation-report',
        element: <EvaluationSetup />
      },
    ]
  },
  {
    id: 'early-learning-goals-tracker',
    name: 'Early Learning Goals Tracker',
    path: '/early-learning-goals-tracker',
    element: <EarlyLearningGoalsTracker />
  },
  {
    id: 'student-promotion',
    name: 'Student Promotion',
    path: '/student-promotion',
    element: <StudentPromotion />
  },
  {
    id: 'leads-follow-up-report',
    name: 'Leads Follow Up Report',
    path: '/leads-follow-up-report',
    element: <LeadsFollowUpReport />
  },
  {
    id: 'navigation',
    name: 'Navigation',
    path: '/navigation',
    element: <Navigation />,
    children: [
      {
        id: 'nav-tree',
        name: 'Navigation Tree',
        path: 'nav-tree',
        element: <NavTree />
      },
      {
        id: 'nav-manager',
        name: 'Navigation Manager',
        path: 'nav-manager',
        element: <NavManager />
      },
      {
        id: 'center-nav',
        name: 'Center Navigation',
        path: 'center-nav',
        element: <CenterNav />
      },
    ]
  },
  {
    id: 'withdrawal',
    name: 'Withdrawal',
    path: '/withdrawal',
    element: <Withdrawal />,
    children: [
      {
        id: 'withdrawal-view',
        name: 'Withdrawal View',
        path: 'withdrawal-view',
        element: <WithdrawalView />
      },
      {
        id: 'withdrawal-setup',
        name: 'Withdrawal Setup',
        path: 'withdrawal-setup',
        element: <WithdrawalSetup />
      },
      {
        id: 'withdrawal-admin',
        name: 'Withdrawal Admin',
        path: 'withdrawal-admin',
        element: <WithdrawalAdmin />
      },
      {
        id: 'withdrawal-manager',
        name: 'Withdrawal Manager',
        path: 'withdrawal-manager',
        element: <WithdrawalManager />
      },
      {
        id: 'withdrawal-accountant',
        name: 'Withdrawal Accountant',
        path: 'withdrawal-accountant',
        element: <WithdrawalAccountant />
      },
      {
        id: 'withdrawal-parent',
        name: 'Withdrawal Parent',
        path: 'withdrawal-parent',
        element: <WithdrawalParent />
      },
    ]
  },
  {
    id: 'employee-timetable-availability',
    name: 'Employee Timetable Availability',
    path: '/employee-timetable-availability',
    element: <EmployeeTimetableAvailability />
  },
  {
    id: 'timetables-generator',
    name: 'Timetables Generator',
    path: '/timetables-generator',
    element: <TimetablesGenerator />
  },
  {
    id: 'timetable-main',
    name: 'Timetable Main',
    path: '/timetable-main',
    element: <TimetableMain />
  },
]