import React, { Component } from 'react';
import { Container, Card, Table, Row, Col, Button, Offcanvas, Modal, Form, Spinner, Tabs, Tab, Badge, ButtonGroup } from 'react-bootstrap';
import { t } from '../../../helpers/translation_helper';
import { connect } from 'react-redux';
import Select from 'react-select';
import axios from 'axios';
import { toast } from 'react-toastify';
import Flatpickr from 'react-flatpickr';
import moment from 'moment';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { TagsInput } from 'react-tag-input-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleLeft, faCircleCheck, faEdit, faEye, faFilePdf, faPencil, faPlus, faSquare, faSquareCheck, faTimes, faTimesCircle, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { faCircle, faCheckCircle, faCircleDot, faComments, } from '@fortawesome/free-regular-svg-icons';
import { brand_colors } from '../../../helpers/brand_colors_helper';
import ClassSelector from '../../_partials/Header/ClassSelector';
import SubjectSelector from '../../_partials/Header/SubjectSelector';
import Paginate from '../../_partials/Paginate/_Paginate';
import { GET, POST } from '../../../api';
import { DDL_ExamstypegrpTypes, changeColorOpacity, getExtension } from '../../../helpers/general_helpers';

var Chance = require('chance');
var chance = new Chance();

// API URL
const apiURL = process.env.REACT_APP_API_URL;
const portalURL = process.env.REACT_APP_PORTAL_URL;

class AddEditExam extends Component {
  constructor(props) {
    super(props);
    this.state = {
      DDL_examstypegrp_types: DDL_ExamstypegrpTypes(),
      // General states
      // is_exampaper: 0,
      exams: [],
      showCreateUpdateExamCanvas: false,
      showCreateUpdateQuestionModal: false,
      working: false,
      classes: [],
      terms: [],
      groups: [],
      lessons: [],
      children: [],
      rawChildren: [],
      examTypes: [],
      questionTypes: [],
      difficultyTypes: [],
      objectives: [],
      disableLessons: true,
      isEditingQuestion: false,
      editingQuestionId: undefined,
      // --------------
      // Exam form data
      exams_id: 0,
      selectedClass: null,
      selectedTerm: null,
      selectedSubject: null,
      selectedLessons: [],
      selectedExamType: null,
      examName: '',
      weight: 0,
      examNote: '',
      exams_grade: 0,
      examDate: moment().format('YYYY-MM-DD'),
      endDate: '',
      openTime: '',
      closeTime: '',
      shuffleQs: 0,
      resubmitAllowed: 0,
      showCorrectAnswer: 0,
      retakenExam: 0,
      is_exampaper: 1,
      eqtype_id: 0,
      exams_status: 0,
      exams_attachment: null,
      exams_attachment_prev: '',
      // ------------------
      // Question form data
      uuid: '',
      examsquest_id: 0,
      examsquest_desc1: '',
      examsquest_desc2: '',
      examsquest_desc3: '',
      exams_notes: '',
      sub_obj: '',
      examsquest_grade: 0.00,
      examsquest_order: 0,
      examsquest_difficulty: null,
      examsquest_duration: 0,
      eqtype_id: null,
      asl_ids: '',
      is_mandatory: 0,
      shuffle_allowed: 0,
      examsquest_file1: '',
      examsquest_file2: '',
      examsquest_file3: '',
      examsquest_file4: '',
      examsquest_file5: '',
      examsquest_file1_prev: '',
      examsquest_file2_prev: '',
      examsquest_file3_prev: '',
      examsquest_file4_prev: '',
      examsquest_file5_prev: '',
      examsquest_audio: '',
      examsquestoptions: [],
      aslr_ids: [],
      asl_ids_data: [],
      // ------------------
      // Group of questions
      questions: [],
      // Paginate
      limit: 12,
      totalRecords: 0,
      // Form sections
      formSectionActive: {
        parent: '1',
        child: '1.1',
      },
      formSections: [
        {
          uid: '1',
          active: true,
          completed: false,
          name: 'Details',
          desc: null,
          data: [
            {
              uid: '1.2',
              active: false,
              completed: false,
              name: 'Terms',
              desc: null,
              image: require('../../../assets/images/lesson/lesson-focus.png'),
            },
            {
              uid: '1.6',
              active: false,
              completed: false,
              name: 'Name',
              desc: null,
              image: require('../../../assets/images/lesson/lesson-focus.png'),
            },
            {
              uid: '1.7',
              active: false,
              completed: false,
              name: 'Type',
              desc: null,
              image: require('../../../assets/images/lesson/lesson-focus.png'),
            },
            {
              uid: '1.4',
              active: false,
              completed: false,
              name: 'Lessons',
              desc: null,
              image: require('../../../assets/images/lesson/lesson-focus.png'),
            },
            {
              uid: '1.5',
              active: false,
              completed: false,
              name: 'Tag Students',
              desc: null,
              image: require('../../../assets/images/lesson/lesson-focus.png'),
            },
            // {
            //   uid: '1.8',
            //   active: false,
            //   completed: false,
            //   name: 'Weight',
            //   desc: null,
            //   image: require('../../../assets/images/lesson/lesson-focus.png'),
            // },
            {
              uid: '1.9',
              active: false,
              completed: false,
              name: 'Date',
              desc: null,
              image: require('../../../assets/images/lesson/lesson-focus.png'),
            },
            {
              uid: '1.10',
              active: false,
              completed: false,
              name: 'Open Time',
              desc: null,
              image: require('../../../assets/images/lesson/lesson-focus.png'),
            },
            {
              uid: '1.11',
              active: false,
              completed: false,
              name: 'Close Time',
              desc: null,
              image: require('../../../assets/images/lesson/lesson-focus.png'),
            },
            {
              uid: '1.12',
              active: false,
              completed: false,
              name: 'Note',
              desc: null,
              image: require('../../../assets/images/lesson/lesson-focus.png'),
            },
            {
              uid: '1.13',
              active: false,
              completed: false,
              name: 'Shuffle Questions',
              desc: null,
              image: require('../../../assets/images/lesson/lesson-focus.png'),
            },
          ],
        },
      ],
      questionsImport: {
        modal: {
          show: false
        },
        examsquest_ids: [],
        questionsForImport: [],
        limit: 12,
        totalRecords: 0,
        working: false
      },
      dragAreaColor: {
        exams_attachment: brand_colors[this.props.defaultTheme.theme_id].color18,
        toggleColor: (field, value) => this.setState({ dragAreaColor: { ...this.state.dragAreaColor, [field]: value } })
      }
    };
    this.exams_attachment = React.createRef();
    this.examsquest_file1 = React.createRef();
    this.examsquest_file2 = React.createRef();
    this.examsquest_file3 = React.createRef();
    this.examsquest_file4 = React.createRef();
    this.examsquest_file5 = React.createRef();
    this.tagStudent = React.createRef();
  }


  componentDidMount() {
    // const { homework } = this.props;
    // if (homework) {
    //   this.setState({
    //     formSections: [
    //       {
    //         uid: '1',
    //         active: true,
    //         completed: false,
    //         name: this.props.examstypegrp_type,
    //         desc: null,
    //         data: [
    //           {
    //             uid: '1.2',
    //             active: false,
    //             completed: false,
    //             name: 'Terms',
    //             desc: null,
    //             image: require('../../../assets/images/lesson/lesson-focus.png'),
    //           },
    //           {
    //             uid: '1.3',
    //             active: false,
    //             completed: false,
    //             name: 'Course',
    //             desc: null,
    //             image: require('../../../assets/images/lesson/lesson-focus.png'),
    //           },
    //           {
    //             uid: '1.4',
    //             active: false,
    //             completed: false,
    //             name: 'Lessons',
    //             desc: null,
    //             image: require('../../../assets/images/lesson/lesson-focus.png'),
    //           },
    //           {
    //             uid: '1.5',
    //             active: false,
    //             completed: false,
    //             name: 'Tag Students',
    //             desc: null,
    //             image: require('../../../assets/images/lesson/lesson-focus.png'),
    //           },
    //           {
    //             uid: '1.6',
    //             active: false,
    //             completed: false,
    //             name: 'Name',
    //             desc: null,
    //             image: require('../../../assets/images/lesson/lesson-focus.png'),
    //           },
    //           {
    //             uid: '1.7',
    //             active: false,
    //             completed: false,
    //             name: 'Type',
    //             desc: null,
    //             image: require('../../../assets/images/lesson/lesson-focus.png'),
    //           },
    //           // {
    //           //   uid: '1.8',
    //           //   active: false,
    //           //   completed: false,
    //           //   name: 'Weight',
    //           //   desc: null,
    //           //   image: require('../../../assets/images/lesson/lesson-focus.png'),
    //           // },
    //           {
    //             uid: '1.9',
    //             active: false,
    //             completed: false,
    //             name: 'Start Date',
    //             desc: null,
    //             image: require('../../../assets/images/lesson/lesson-focus.png'),
    //           },
    //           {
    //             uid: '1.10',
    //             active: false,
    //             completed: false,
    //             name: 'End Date',
    //             desc: null,
    //             image: require('../../../assets/images/lesson/lesson-focus.png'),
    //           },
    //           {
    //             uid: '1.11',
    //             active: false,
    //             completed: false,
    //             name: 'Open Time',
    //             desc: null,
    //             image: require('../../../assets/images/lesson/lesson-focus.png'),
    //           },
    //           {
    //             uid: '1.12',
    //             active: false,
    //             completed: false,
    //             name: 'Close Time',
    //             desc: null,
    //             image: require('../../../assets/images/lesson/lesson-focus.png'),
    //           },
    //           {
    //             uid: '1.13',
    //             active: false,
    //             completed: false,
    //             name: 'Note',
    //             desc: null,
    //             image: require('../../../assets/images/lesson/lesson-focus.png'),
    //           },
    //           {
    //             uid: '1.14',
    //             active: false,
    //             completed: false,
    //             name: 'Shuffle Question',
    //             desc: null,
    //             image: require('../../../assets/images/lesson/lesson-focus.png'),
    //           },
    //         ],
    //       },
    //     ]
    //   });
    // }
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.showCreateUpdateExamCanvas != this.state.showCreateUpdateExamCanvas && this.state.showCreateUpdateExamCanvas) {
      if (this.state.terms.length <= 0) {
        this.getTerms();
      }
      this.getExamTypes();
      if (this.state.difficultyTypes.length <= 0) {
        this.getDifficultyTypes();
      }
      if (this.state.questionTypes.length <= 0) {
        this.getQuestionTypes();
      }
    }
  }

  handleShowCreateUpdateExamCanvas = async (isEditing = false, editingData = {}) => {
    let selectedTerm = {}
    this.state.terms.map((item) => {
      if (item.term_active == 1) {
        selectedTerm = {
          value: item.value,
          label: item.label,
          term_active: item.term_active
        }
      }
    });

    this.setState(
      { isEditing: false, exams_id: 0, selectedClass: null, selectedTerm: selectedTerm, selectedSubject: null, selectedLessons: [], selectedChildren: [], selectedExamType: null, examName: '', weight: 0, examNote: '', exams_grade: 0, examDate: moment().format('YYYY-MM-DD'), openTime: '', closeTime: '', shuffleQs: 0, questions: [], disableLessons: true, groups: [], exams_attachment: '', exams_attachment_prev: '', showCreateUpdateExamCanvas: true }, async () => {
        if (isEditing) {
          const { authData, homework } = this.props;
          const { employee_id } = authData.userData;

          try {
            const res = await GET('exams/edit-exam', {
              params: {
                employee_id,
                exams_id: editingData.exams_id,
              },
            });

            if (res.status == 200) {
              const { center_id, class_id, class_theme, acs_code, acs_id, acs_name, asl_ids, asl_data, close_time: closeTime, due_date, employee_id, exams_coefficient: weight, exams_date: examDate, end_date: endDate, exams_grade, exams_id, exams_name: examName, exams_notes: examNote, exams_post_date, exams_status, examsquest, examstype_id, examstype_name, is_exampaper, is_homework, open_time: openTime, term_id, term_name, trophy_id, exams_child_ids_data, exams_attachment } = res.data;

              // Lessons
              const selectedLessons = asl_data.map(
                (item) => {
                  return {
                    value: item.asl_id,
                    label: item.asl_name,
                  };
                }
              );

              // Tagged students
              const taggedStudents = exams_child_ids_data.map((item) => {
                return {
                  value: item.child_id,
                  label: item.child_name,
                };
              });

              const examsquest2 = examsquest.map((item) => {

                item['uuid'] = chance.guid();

                item['eqtype_id'] = {
                  value: item.eqtype_id,
                  label: item.eqtype_name
                };

                item['examsquest_difficulty'] = {
                  value: item.eqdifficulty_value,
                  label: item.eqdifficulty_name
                };

                ['examsquest_file1', 'examsquest_file2', 'examsquest_file3', 'examsquest_file4', 'examsquest_file5'].map((field_name, field_index) => {
                  if (item[field_name]) {
                    let examsquest_file1 = item[field_name].indexOf(".pdf") != -1 ? 'pdf' : portalURL + center_id + '/examsquest/' + item[field_name]
                    item[field_name + '_prev'] = examsquest_file1;
                  }
                });
                const examsquestoptions2 = item.examsquestoptions.map((item2) => {
                  item2['uuid'] = chance.guid();
                  return item2;
                });
                item['examsquestoptions'] = examsquestoptions2;
                return item;
              });

              console.log('examsquest2', examsquest2);

              this.setState(
                {
                  isEditing,
                  exams_id,
                  is_exampaper,
                  selectedClass: {
                    label: class_theme,
                    value: class_id,
                  },
                  selectedTerm: {
                    label: term_name,
                    value: term_id,
                  },
                  selectedSubject: {
                    label: acs_name,
                    value: acs_id,
                  },
                  selectedExamType: {
                    label: examstype_name,
                    value: examstype_id,
                  },
                  examName,
                  weight,
                  examNote,
                  exams_grade,
                  examDate,
                  endDate,
                  openTime,
                  closeTime,
                  shuffleQs: 0,
                  resubmitAllowed: 0,
                  showCorrectAnswer: 0,
                  retakenExam: 0,
                  is_homework: this.props.examstypegrp_type == 'Homework' ? 1 : 0,
                  questions: examsquest2,
                  disableLessons: false,
                  exams_attachment: exams_attachment ? { url: portalURL + center_id + '/exams/' + exams_attachment, data: { type: getExtension(exams_attachment) } } : null,
                  selectedChildren: taggedStudents,
                  selectedLessons: selectedLessons,
                },
                () => {
                  this.getChildren();
                  this.getLessons();
                  this.checkCompletedFormSections();
                  if (!is_exampaper) {
                    this.getImportQuestions();
                  }
                  // this.setState({
                  //   selectedChildren: taggedStudents,
                  //   selectedLessons: selectedLessons,
                  // });
                }
              );
            }
          } catch (err) {
            console.log('err', err)
            toast.error(
              t('Something went wrong!')
            );
          }
        } else {
          this.getChildren();
          this.getLessons();
          this.checkCompletedFormSections();
        }
      })
  }

  handleCloseCreateUpdateCanvas = () => {
    this.setState({ showCreateUpdateExamCanvas: false });
  };

  resetExamForm = () => {
    this.setState(
      {
        isEditing: false,
        exams_id: 0,
        selectedClass: null,
        selectedTerm: null,
        selectedSubject: null,
        selectedLessons: [],
        selectedChildren: [],
        selectedExamType: null,
        examName: '',
        weight: 0,
        examNote: '',
        exams_grade: 0,
        examDate: moment().format('YYYY-MM-DD'),
        openTime: '',
        closeTime: '',
        shuffleQs: 0,
        questions: [],
        disableLessons: true,
        groups: [],
        exams_attachment: '',
        exams_attachment_prev: ''
      },
      () => {
        this.checkCompletedFormSections();
      }
    );
  };

  getTerms = async () => {
    const res = await GET('exams/get-terms');
    const terms = [];
    let selectedTerm = {}
    res.data.map((item) => {
      terms.push({
        value: item.term_id,
        label: item.term_name,
        term_active: item.term_active
      });
      if (item.term_active == 1) {
        selectedTerm = {
          value: item.term_id,
          label: item.term_name,
          term_active: item.term_active
        }
      }
    });
    this.setState({ terms, selectedTerm }, () => this.getChildren());
  };

  getGroups = async (subObj) => {

    this.setState({ groups: [] });

    const { authData, selectedClass, selectedSubject } = this.props;
    const { auth_key } = authData.loginData;
    const { employee_id } = authData.userData;
    const { center_id } = authData.centerData;

    const request_params = {
      class_id: selectedClass.class.class_id,
      employee_id,
      acs_id: selectedSubject.acs_id,
    };

    try {
      const res = await GET('student-group/fetch', {
        params: request_params,
      });

      if (res.status === 200) {
        this.setState({ groups: res.data });
      }

    } catch (err) {
      console.log('err', err)
      toast.error(t('Something went wrong!'));

    }
  };

  getExamTypes = async () => {
    const res = await GET('exams/get-exam-types', {
      params: {
        examstypegrp_type: this.props.examstypegrp_type
      }
    });
    if (res) {
      const examTypes = [];
      res.data.map((item) => {
        examTypes.push({
          value: item.examstype_id,
          label: item.examstype_name,
        });
      });
      this.setState({ examTypes });
    }
  };

  getDifficultyTypes = async () => {
    const { authData } = this.props;
    const { auth_key } = authData.loginData;
    const { center_id } = authData.centerData;

    try {
      const res = await GET('exams/get-question-difficulty');

      const difficultyTypes = [];

      res.data.map((item) => {
        difficultyTypes.push({
          value: item.eqdifficulty_id,
          label: item.eqdifficulty_name,
        });
      });

      this.setState({ difficultyTypes });
    } catch (err) {
      console.log('err', err)
      toast.error(
        t('Something went wrong while fetching question difficulties!')
      );

    }
  };

  getChildren = async () => {
    const { authData, selectedClass } = this.props;
    const { center_id } = authData.centerData;
    const { auth_key } = authData.loginData;
    const { employee_id } = authData.userData;
    const { selectedTerm } = this.state;

    this.setState({ disableChildren: true });

    if (selectedClass && selectedTerm) {

      const request_params = {
        class_ids: [selectedClass.class.class_id],
        term_id: selectedTerm.value,
        studentgroup: true,
        employee_id,
      };

      try {
        const res = await GET('exams/get-children-list', {
          params: request_params,
        });
        console.log('get-children-list', res);
        const children = [];
        res.data.map((item) => {
          children.push({
            value: item.child_id,
            label: item.child_name,
          });
        });

        this.setState({
          children,
          rawChildren: res.data,
          disableChildren: false
        });
      } catch (err) {
        console.log('err', err)
        toast.error(t('Something went wrong while fetching students!'));
      }
    }
  };

  getLessons = async () => {
    const { authData, selectedClass, selectedSubject } = this.props;
    const { auth_key } = authData.loginData;
    const { center_id } = authData.centerData;

    this.setState({ disableLessons: true, lessons: [] });

    const fData = new FormData();
    fData.append('center_id', center_id);
    fData.append('class_id', selectedClass.class.class_id);
    fData.append('acs_id', selectedSubject.acs_id);
    fData.append('limit', 1000);
    fData.append('offset', 0);

    try {
      const res = await axios.post(apiURL + 'academic-subject-lessons/get', fData, {
        params: {
          auth_key: auth_key,
          appname: process.env.REACT_APP_NAME,
        },
      });
      if (res.status == 200) {
        const lessons = [];
        res.data.data.map((item) => {
          lessons.push({
            value: item.asl_id,
            label: item.asl_name,
          });
        });

        this.setState({ lessons, disableLessons: false }, () =>
          this.checkCompletedFormSections()
        );
      }
    } catch (err) {
      console.log('err', err)
      toast.error(t('Something went wrong while fetching lessons!'));

    }

  };

  getImportQuestions = async (limit = this.state.questionsImport.limit, offset = 0) => {

    this.setState({
      questionsImport: {
        ...this.state.questionsImport,
        questionsForImport: [],
      }
    });

    const { authData, selectedClass, selectedSubject } = this.props;
    const { auth_key } = authData.loginData;
    const { center_id } = authData.centerData;

    const request_params = {
      limit,
      offset,
      search: '',
      acs_id: selectedSubject.acs_id,
    };

    try {
      const res = await GET('exams/get-import-questions', {
        params: request_params,
      });

      if (res.status == 200) {
        this.setState({
          questionsImport: {
            ...this.state.questionsImport,
            questionsForImport: res.data.data,
            totalRecords: res.data.total_count
          }
        });
      }

    } catch (err) {
      console.log('err', err)
      toast.error(t('Something went wrong!'));

    }

  }

  handleImportQuestionModal = (status = true) => {

    if (this.state.selectedSubject == null && status) {
      toast.error(t('Please select subject first!'));
      return;
    }

    this.setState({
      questionsImport: {
        ...this.state.questionsImport,
        examsquest_ids: [],
        modal: {
          show: status
        }
      }
    });
  }

  checkUncheckQuestion = (qid) => {
    const findIndex = this.state.questionsImport.examsquest_ids.findIndex((q) => q == qid);

    const qids = this.state.questionsImport.examsquest_ids;

    if (findIndex == -1) {
      qids.push(qid);
    } else {
      qids.splice(findIndex, 1);
    }

    this.setState({
      questionsImport: {
        ...this.state.questionsImport,
        examsquest_ids: qids
      }
    });
  }

  insertImportQuestions = async () => {

    this.setState({
      questionsImport: {
        ...this.state.questionsImport,
        working: true
      }
    });

    const { authData } = this.props;
    const { auth_key } = authData.loginData;
    const { center_id } = authData.centerData;
    const { exams_id, questionsImport } = this.state;
    const { examsquest_ids } = questionsImport;

    const fData = new FormData();
    fData.append('center_id', center_id);
    fData.append('exams_id', exams_id);
    fData.append('examsquest_ids', JSON.stringify(examsquest_ids));

    try {
      const res = await axios.post(apiURL + 'exams/insert-import-questions', fData, {
        params: {
          auth_key: auth_key,
          appname: process.env.REACT_APP_NAME,
        },
      });

      if (res.data.type) {

        toast.success(t(res.data.message));

        const examsquest2 = res.data.data.map((item) => {

          item['uuid'] = chance.guid();

          item['eqtype_id'] = {
            value: item.eqtype_id,
            label: item.eqtype_name
          };

          item['examsquest_difficulty'] = {
            value: item.eqdifficulty_value,
            label: item.eqdifficulty_name
          };

          const examsquestoptions2 = item.examsquestoptions.map((item2) => {
            item2['uuid'] = chance.guid();
            return item2;
          });


          item['examsquestoptions'] = examsquestoptions2;


          return item;
        });


        this.setState({
          questions: [
            ...this.state.questions,
            ...examsquest2
          ],
          questionsImport: {
            ...this.state.questionsImport,
            working: false,
            modal: {
              show: false
            }
          }
        });
      } else {
        toast.error(t(res.data.message));
        this.setState({
          questionsImport: {
            ...this.state.questionsImport,
            working: false
          }
        });
      }


    } catch (err) {
      console.log('err', err)
      toast.error(t('Something went wrong!'));
      this.setState({
        questionsImport: {
          ...this.state.questionsImport,
          working: false
        }
      });

    }

  }

  checkCompletedFormSections = () => {
    let formSections = this.state.formSections;
    formSections = formSections.map((v, i) => {
      let data = v.data;
      let sectionComplete = true;
      data = data.map((vv, ii) => {
        if (vv.uid == '1.1') {
          let selectedClass = this.state.selectedClass;
          let subSectionComplete = true;
          if (selectedClass) {
            subSectionComplete = true;
          } else {
            subSectionComplete = false;
            sectionComplete = false;
          }
          return { ...vv, completed: subSectionComplete };
        } else if (vv.uid == '1.2') {
          let selectedTerm = this.state.selectedTerm;
          let subSectionComplete = true;
          if (selectedTerm) {
            subSectionComplete = true;
          } else {
            subSectionComplete = false;
            sectionComplete = false;
          }
          return { ...vv, completed: subSectionComplete };
        } else if (vv.uid == '1.4') {
          let selectedLessons = this.state.selectedLessons;
          let subSectionComplete = true;
          if (selectedLessons && selectedLessons.length > 0) {
            subSectionComplete = true;
          } else {
            subSectionComplete = false;
            sectionComplete = false;
          }
          return { ...vv, completed: subSectionComplete };
        } else if (vv.uid == '1.5') {
          let selectedChildren = this.state.selectedChildren;
          let subSectionComplete = true;
          if (selectedChildren && selectedChildren.length > 0) {
            subSectionComplete = true;
          } else {
            subSectionComplete = false;
            sectionComplete = false;
          }
          return { ...vv, completed: subSectionComplete };
        } else if (vv.uid == '1.6') {
          let examName = this.state.examName;
          let subSectionComplete = true;
          if (examName) {
            subSectionComplete = true;
          } else {
            subSectionComplete = false;
            sectionComplete = false;
          }
          return { ...vv, completed: subSectionComplete };
        } else if (vv.uid == '1.7') {
          let selectedExamType = this.state.selectedExamType;
          let subSectionComplete = true;
          if (selectedExamType) {
            subSectionComplete = true;
          } else {
            subSectionComplete = false;
            sectionComplete = false;
          }
          return { ...vv, completed: subSectionComplete };
        } else if (vv.uid == '1.8') {
          let weight = this.state.weight;
          let subSectionComplete = true;
          if (weight) {
            subSectionComplete = true;
          } else {
            subSectionComplete = false;
            sectionComplete = false;
          }
          return { ...vv, completed: subSectionComplete };
        } else if (vv.uid == '1.9') {
          let examDate = this.state.examDate;
          let subSectionComplete = true;
          if (examDate) {
            subSectionComplete = true;
          } else {
            subSectionComplete = false;
            sectionComplete = false;
          }
          return { ...vv, completed: subSectionComplete };
        } else if (vv.uid == '1.10') {
          let openTime = this.state.openTime;
          let subSectionComplete = true;
          if (openTime) {
            subSectionComplete = true;
          } else {
            subSectionComplete = false;
            sectionComplete = false;
          }
          return { ...vv, completed: subSectionComplete };
        } else if (vv.uid == '1.11') {
          let closeTime = this.state.closeTime;
          let subSectionComplete = true;
          if (closeTime) {
            subSectionComplete = true;
          } else {
            subSectionComplete = false;
            sectionComplete = false;
          }
          return { ...vv, completed: subSectionComplete };
        } else if (vv.uid == '1.12') {
          let examNote = this.state.examNote;
          let subSectionComplete = true;
          if (examNote) {
            subSectionComplete = true;
          } else {
            subSectionComplete = false;
            sectionComplete = false;
          }
          return { ...vv, completed: subSectionComplete };
        } else if (vv.uid == '1.13') {
          let shuffleQs = this.state.shuffleQs;
          let subSectionComplete = true;
          if (shuffleQs == 1) {
            subSectionComplete = true;
          } else {
            subSectionComplete = false;
            sectionComplete = false;
          }
          return { ...vv, completed: subSectionComplete };
        } else {
          sectionComplete = false;
          return { ...vv };
        }
      });
      return { ...v, data, completed: sectionComplete };
    });
    this.setState({ formSections: formSections });
  };

  changeToFromSection = (parent, child) => {
    this.setState(
      {
        formSectionActive: { parent: parent, child: child },
      },
      () => {
        setTimeout(() => {
          const element = document.getElementById(
            'form_section_' + child
          );
          if (element) {
            // 👇 Will scroll smoothly to the top of the next section
            element.scrollIntoView({ behavior: 'smooth' });
          }
        }, 300);
      }
    );
  };

  handleSelectField = (field_name) => (e) => {
    this.setState(
      {
        [field_name]: e,
      },
      () => {
        this.checkCompletedFormSections();
      }
    );

    if (field_name == 'eqtype_id') {
      this.setState({ examsquestoptions: [] }, () => {
        this.checkCompletedFormSections();
      });
    }
  };

  previewFile = (e) => {
    const reader = new FileReader();

    const selectedFile = e.target.files[0];

    if (selectedFile) {
      reader.readAsDataURL(selectedFile);
    }

    if (
      !selectedFile.type.includes('image') &&
      !selectedFile.type.includes('pdf')
    ) {
      toast.error(t('Only image or PDF file is allowed!'));
      return;
    }

    reader.onload = (readerEvent) => {
      if (selectedFile.type.includes('image')) {
        this.setState({
          [e.target.name + '_prev']: readerEvent.target.result,
          [e.target.name]: e.target.files[0],
        });
      } else if (selectedFile.type.includes('pdf')) {
        this.setState({
          [e.target.name + '_prev']: 'pdf',
          [e.target.name]: e.target.files[0],
        });
      }
    };
  };

  handleSaveForm = async () => {
    const { auth_key } = this.props.authData.loginData;
    const { selectedClass, selectedSubject, homework } = this.props;
    const { employee_id } = this.props.authData.userData;
    const { center_id } = this.props.authData.centerData;
    const {
      selectedTerm,
      selectedExamType,
      selectedLessons,
      selectedChildren,
      examName,
      examNote,
      exams_grade,
      weight,
      examDate,
      endDate,
      shuffleQs,
      questions,
      openTime,
      closeTime,
      exams_id,
      is_exampaper,
      exams_status,
      exams_attachment
    } = this.state;

    // Validation
    if (!selectedTerm) {
      toast.error(t('Term is required!'));
      return;
    }
    if (!selectedExamType) {
      toast.error(t('Exam Type is required!'));
      return;
    }
    // if (!selectedLessons) {
    //   toast.error(t('Lessons are required!'));
    //   return;
    // }
    if (!selectedChildren) {
      toast.error(t('Tag Students are required!'));

      return;
    }
    if (examName == '') {
      toast.error(t('Exam name is required!'));
      return;
    }
    if (homework) {
      if (examDate == '') {
        toast.error(t('Start date is required!'));
        return;
      }
      if (endDate == '') {
        toast.error(t('End date is required!'));
        return;
      }
    } else {
      if (examDate == '') {
        toast.error(t('Exam date is required!'));
        return;
      }
    }
    if (is_exampaper == 0 && openTime == '') {
      toast.error(t('Open time is required!'));
      return;
    }
    if (is_exampaper == 0 && closeTime == '') {
      toast.error(t('Close time is required!'));
      return;
    }

    this.setState({ working: true });

    const asl_ids = (selectedLessons) ? selectedLessons.map((item) => { return item.value; }) : []
    const exams_child_ids = selectedChildren.map((item) => {
      return item.value;
    });

    const fData = new FormData();
    fData.append(
      'classmain_id',
      this.props.selectedClass.class.classmain_id
    );
    if (exams_id != 0) {
      fData.append('exams_id', exams_id);
      fData.append('Exams[exams_id]', exams_id);
    }
    fData.append('Exams[center_id]', center_id);
    fData.append('Exams[employee_id]', employee_id);
    fData.append('Exams[class_id]', selectedClass.class.class_id);
    fData.append('Exams[term_id]', selectedTerm.value);
    fData.append('Exams[acs_id]', selectedSubject.acs_id);
    fData.append('Exams[examstype_id]', selectedExamType.value);
    fData.append('Exams[asl_ids]', JSON.stringify(asl_ids));
    fData.append('Exams[exams_name]', examName);
    fData.append('Exams[exams_notes]', examNote);
    fData.append('Exams[exams_grade]', exams_grade);
    fData.append('Exams[exams_coefficient]', weight);
    fData.append('Exams[exams_date]', examDate);
    fData.append('Exams[end_date]', endDate);
    fData.append('Exams[exams_child_ids]', JSON.stringify(exams_child_ids));
    fData.append('Exams[open_time]', openTime);
    fData.append('Exams[close_time]', closeTime);
    fData.append('Exams[is_homework]', this.props.examstypegrp_type == 'Homework' ? 1 : 0);
    fData.append('Exams[shuffle_questions]', shuffleQs);
    fData.append('Exams[resubmit_allowed]', 0);
    fData.append('Exams[show_correct_answer]', 0);
    fData.append('Exams[retake_exam]', 0);
    // fData.append('Exams[is_exampaper]', 0);
    fData.append('Exams[exams_status]', exams_status);
    fData.append('Exams[is_exampaper]', is_exampaper);

    if (exams_attachment) {
      if (exams_attachment.data) {
        fData.append('exams_attachment', exams_attachment.data);
      } else {
        fData.append('exams_attachment', exams_attachment);
      }
    }

    // Questions
    if (questions.length > 0) {
      questions.map((quest, quest_i) => {
        fData.append('Examsquest[' + quest.uuid + '][uuid]', quest.uuid);
        fData.append('Examsquest[' + quest.uuid + '][examsquest_id]', quest.examsquest_id);
        fData.append('Examsquest[' + quest.uuid + '][examsquest_desc1]', quest.examsquest_desc1);
        fData.append('Examsquest[' + quest.uuid + '][examsquest_desc2]', quest.examsquest_desc2);
        fData.append('Examsquest[' + quest.uuid + '][examsquest_desc3]', quest.examsquest_desc3);
        fData.append('Examsquest[' + quest.uuid + '][exams_notes]', quest.exams_notes);
        fData.append('Examsquest[' + quest.uuid + '][examsquest_grade]', quest.examsquest_grade);
        fData.append('Examsquest[' + quest.uuid + '][examsquest_order]', quest.examsquest_order);
        fData.append('Examsquest[' + quest.uuid + '][aslr_ids]', JSON.stringify(quest.asl_ids_data.map((item) => { return parseInt(item.aslr_id) })));
        fData.append('Examsquest[' + quest.uuid + '][examsquest_difficulty]', quest.examsquest_difficulty !== null ? quest.examsquest_difficulty.value : null);
        fData.append('Examsquest[' + quest.uuid + '][examsquest_duration]', quest.examsquest_duration ? quest.examsquest_duration : 0);
        fData.append('Examsquest[' + quest.uuid + '][eqtype_id]', quest.eqtype_id !== null ? quest.eqtype_id.value : null);
        fData.append('Examsquest[' + quest.uuid + '][is_mandatory]', quest.is_mandatory);
        fData.append('Examsquest[' + quest.uuid + '][shuffle_allowed]', quest.shuffle_allowed);
        fData.append('examsquest_file1[' + quest.uuid + ']', quest.examsquest_file1);
        fData.append('examsquest_file2[' + quest.uuid + ']', quest.examsquest_file2);
        fData.append('examsquest_file3[' + quest.uuid + ']', quest.examsquest_file3);
        fData.append('examsquest_file4[' + quest.uuid + ']', quest.examsquest_file4);
        fData.append('examsquest_file5[' + quest.uuid + ']', quest.examsquest_file5);

        // Options
        if (quest.examsquestoptions.length > 0) {
          quest.examsquestoptions.map((opt, opt_i) => {
            fData.append('Examsquest[' + quest.uuid + '][examsquestoptions][' + opt.uuid + '][uuid]', opt.uuid);
            fData.append('Examsquest[' + quest.uuid + '][examsquestoptions][' + opt.uuid + '][examsquestoptions_id]', opt.examsquestoptions_id);
            fData.append('Examsquest[' + quest.uuid + '][examsquestoptions][' + opt.uuid + '][option_text]', opt.option_text);
            fData.append('Examsquest[' + quest.uuid + '][examsquestoptions][' + opt.uuid + '][option_value]', opt.option_value);
            fData.append('Examsquest[' + quest.uuid + '][examsquestoptions][' + opt.uuid + '][is_checked]', opt.is_checked);
            fData.append('option_text_picture[' + opt.uuid + ']', opt.option_text_picture);
            fData.append('option_value_picture[' + opt.uuid + ']', opt.option_value_picture);
          });
        }
      });
    }

    try {
      const res = await POST('exams/save-exam', fData);

      if (res.data.type) {

        this.setState({ working: false, showCreateUpdateExamCanvas: false }, () => {
          this.props.callBack();
        });
      } else {
        toast.error(t(res.data.message));
        this.setState({ working: false });
      }


    } catch (err) {
      console.log('err', err)
      toast.error(t('Something went wrong while saving!'));
      this.setState({ working: false });

    }
  };

  handleClassChange = () => {
    console.log('asd');
    this.getChildren();
    this.checkCompletedFormSections();
  };

  handleTermChange = (obj) => {
    this.setState({ selectedTerm: obj }, () => {
      this.getChildren();
      this.checkCompletedFormSections();
    });
  };

  handleSubjectChange = () => {
    this.getLessons();
    this.getGroups();
    this.getImportQuestions();
  };

  filterStudentByGroup = (groupID) => {
    const { selectedSubject, rawChildren, selectedChildren } = this.state;
    const { value: acs_id } = selectedSubject;

    const filterChildren = rawChildren
      .filter((rc) => {
        let a = selectedChildren.find((item) => item.value == rc.child_id);
        let b = rc.studentgroups.some((sg) => sg.stdgrp_id == groupID && sg.acs_id == acs_id)
        return !a && b;
      })
      .map((child) => {
        return {
          value: child.child_id,
          label: child.child_name,
        }
      });

    this.setState({ selectedChildren: [...this.state.selectedChildren, ...filterChildren] }, () => {
      this.checkCompletedFormSections()
    });
  }

  handleChangeTextField = (e) => {

    let value = e.target.value;

    if (e.target.name == 'exams_grade') {
      value = !value ? 0 : value;
    }

    this.setState(
      {
        [e.target.name]: value,
      },
      () => {
        this.checkCompletedFormSections();
      }
    );
  };

  handleShowCreateUpdateQuestionModal = () => {

    this.getObjectives();

    this.resetQuestionForm();
    this.setState({ showCreateUpdateQuestionModal: true }, () => {
      const modal = document.querySelector('.modal');
      modal.removeAttribute('tabindex');
    });
  };

  handleCloseCreateUpdateQuestionModal = () => {
    this.setState({ showCreateUpdateQuestionModal: false });
  };

  getQuestionTypes = async () => {
    const { authData } = this.props;
    const { auth_key } = authData.loginData;
    const { center_id } = authData.centerData;

    try {
      const res = await GET('exams/get-question-types');

      const questionTypes = [];

      res.data.map((item) => {
        questionTypes.push({
          value: item.eqtype_id,
          label: item.eqtype_name,
        });
      });

      this.setState({ questionTypes });
    } catch (err) {
      console.log('err', err)
      toast.error(
        t('Something went wrong while fetching question types!')
      );

    }
  };

  resetQuestionForm = () => {
    this.setState({
      isEditingQuestion: false,
      editingQuestionId: undefined,
      uuid: '',
      examsquest_id: 0,
      examsquest_desc1: '',
      examsquest_desc2: '',
      examsquest_desc3: '',
      exam_notes: '',
      sub_obj: '',
      examsquest_grade: 0.00,
      examsquest_order: 0,
      examsquest_difficulty: null,
      examsquest_duration: 0,
      eqtype_id: null,
      asl_ids: '',
      is_mandatory: 0,
      shuffle_allowed: 0,
      examsquest_file1: '',
      examsquest_file2: '',
      examsquest_file3: '',
      examsquest_file4: '',
      examsquest_file5: '',
      examsquest_file1_prev: '',
      examsquest_file2_prev: '',
      examsquest_file3_prev: '',
      examsquest_file4_prev: '',
      examsquest_file5_prev: '',
      examsquest_audio: '',
      examsquestoptions: [],
      aslr_ids: [],
      asl_ids_data: [],
      objectives: []
    });
  };

  handleChangeCheckField = (e) => {
    this.setState(
      {
        [e.target.name]: this.state[e.target.name] == 0 ? 1 : 0,
      },
      () => {
        this.checkCompletedFormSections();
      }
    );
  };

  handleOptionCheckChange = (index, state_name, select_single = false) => {
    const { examsquestoptions } = this.state;

    if (select_single) {
      for (var i = 0; i < examsquestoptions.length; i++) {
        examsquestoptions[i][state_name] = 0;
      }
    }

    examsquestoptions[index][state_name] =
      examsquestoptions[index][state_name] == 0 ? 1 : 0;
    this.setState({ examsquestoptions });
  };

  handleOptionTagsChange = (index, name) => (e) => {
    const { examsquestoptions } = this.state;
    examsquestoptions[index][name] = e;
    this.setState({ examsquestoptions });
  };

  handleOptionFileChange = (index) => (e) => {
    const { examsquestoptions } = this.state;
    examsquestoptions[index][e.target.name] = e.target.files[0];
    this.setState({ examsquestoptions });
  };

  handleOptionTextChange = (index) => (e) => {
    const { examsquestoptions } = this.state;
    examsquestoptions[index][e.target.name] = e.target.value;
    this.setState({ examsquestoptions });
  };

  addUpdateQuestion = () => {
    const {
      isEditingQuestion,
      editingQuestionId,
      uuid,
      examsquest_id,
      examsquest_desc1,
      examsquest_desc2,
      examsquest_desc3,
      exams_notes,
      sub_obj,
      examsquest_grade,
      examsquest_order,
      examsquest_difficulty,
      examsquest_duration,
      eqtype_id,
      asl_ids,
      is_mandatory,
      shuffle_allowed,
      examsquest_file1,
      examsquest_file2,
      examsquest_file3,
      examsquest_file4,
      examsquest_file5,
      examsquest_file1_prev,
      examsquest_file2_prev,
      examsquest_file3_prev,
      examsquest_file4_prev,
      examsquest_file5_prev,
      examsquest_audio,
      examsquestoptions,
      questions,
      aslr_ids,
      asl_ids_data,
    } = this.state;

    // Question difficulty is required
    if (examsquest_difficulty == null) {
      toast.error(t('Question difficulty is required!'));
      return;
    }

    // Question type is required
    if (eqtype_id == null) {
      toast.error(t('Question type is required!'));
      return;
    }

    // Don't let user add question
    // if question type is true/false
    // and option are less than 2
    if (eqtype_id.value == 3 && examsquestoptions.length < 2) {
      toast.error(t('Please add two options for true/false type!'));
      return;
    }

    if (isEditingQuestion) {
      questions[editingQuestionId] = {
        uuid,
        examsquest_id,
        examsquest_desc1,
        examsquest_desc2,
        examsquest_desc3,
        exams_notes,
        sub_obj,
        examsquest_grade,
        examsquest_order,
        examsquest_difficulty,
        examsquest_duration,
        eqtype_id,
        asl_ids,
        is_mandatory,
        shuffle_allowed,
        examsquest_file1,
        examsquest_file2,
        examsquest_file3,
        examsquest_file4,
        examsquest_file5,
        examsquest_file1_prev,
        examsquest_file2_prev,
        examsquest_file3_prev,
        examsquest_file4_prev,
        examsquest_file5_prev,
        examsquest_audio,
        examsquestoptions,
        aslr_ids: asl_ids_data,
        asl_ids_data
      };
    } else {
      questions.push({
        uuid: chance.guid(),
        examsquest_id,
        examsquest_desc1,
        examsquest_desc2,
        examsquest_desc3,
        exams_notes,
        sub_obj,
        examsquest_grade,
        examsquest_order,
        examsquest_difficulty,
        examsquest_duration,
        eqtype_id,
        asl_ids,
        is_mandatory,
        shuffle_allowed,
        examsquest_file1,
        examsquest_file2,
        examsquest_file3,
        examsquest_file4,
        examsquest_file5,
        examsquest_file1_prev,
        examsquest_file2_prev,
        examsquest_file3_prev,
        examsquest_file4_prev,
        examsquest_file5_prev,
        examsquest_audio,
        examsquestoptions,
        aslr_ids: asl_ids_data,
        asl_ids_data
      });
    }

    this.setState({ questions }, () => this.sumUpExamGrades());

    this.handleCloseCreateUpdateQuestionModal();
  };

  editQuestion = (id) => {
    const { questions } = this.state;

    console.log('questions[id]', questions[id]);

    const aslr_ids = questions[id].asl_ids_data?.map((item) => {
      return {
        ...item,
        label: item.aslr_desc,
        value: item.aslr_id,
      }
    });

    // this.handleShowCreateUpdateQuestionModal();
    this.setState({
      isEditingQuestion: false,
      editingQuestionId: undefined,
      uuid: '',
      examsquest_id: 0,
      examsquest_desc1: '',
      examsquest_desc2: '',
      examsquest_desc3: '',
      exam_notes: '',
      sub_obj: '',
      examsquest_grade: 0.00,
      examsquest_order: 0,
      examsquest_difficulty: null,
      examsquest_duration: 0,
      eqtype_id: null,
      asl_ids: '',
      is_mandatory: 0,
      shuffle_allowed: 0,
      examsquest_file1: '',
      examsquest_file2: '',
      examsquest_file3: '',
      examsquest_file4: '',
      examsquest_file5: '',
      examsquest_file1_prev: '',
      examsquest_file2_prev: '',
      examsquest_file3_prev: '',
      examsquest_file4_prev: '',
      examsquest_file5_prev: '',
      examsquest_audio: '',
      examsquestoptions: [],
      aslr_ids: [],
      asl_ids_data: [],
      objectives: [],

      ...questions[id],
      aslr_ids,
      asl_ids_data: aslr_ids,
      isEditingQuestion: true,
      editingQuestionId: id,
      showCreateUpdateQuestionModal: true
    }, () => {
      this.getObjectives();
      const modal = document.querySelector('.modal');
      modal.removeAttribute('tabindex');
    });
  };

  deleteQuestion = (id) => {
    const { questions } = this.state;
    questions.splice(id, 1);
    this.setState({ questions }, () => this.sumUpExamGrades());
  };

  sumUpExamGrades = () => {

    const exams_grade = this.state.questions.reduce((prevVal, currVal) => { return parseFloat(prevVal) + parseFloat(currVal.examsquest_grade) }, 0);

    this.setState({ exams_grade });
  }

  addOption = () => {
    const { examsquestoptions, eqtype_id } = this.state;

    // Don't let user add more than two options
    // if question type is true/false
    if (eqtype_id.value == 3 && examsquestoptions.length === 2) {
      toast.error(
        t('Only two options can be added for true/false type!')
      );
      return;
    }

    const option = {
      uuid: chance.guid(),
      examsquestoptions_id: 0,
      option_text: '',
      option_value: '',
      option_text_picture: '',
      option_value_picture: '',
      is_checked: 0,
    };

    examsquestoptions.push(option);

    this.setState(examsquestoptions);
  };

  deleteOption = (id) => {
    const { examsquestoptions } = this.state;
    examsquestoptions.splice(id, 1);
    this.setState({ examsquestoptions });
  };

  getObjectives = async () => {
    if (this.state.selectedLessons?.length > 0) {

      const { selectedClass } = this.props;

      const lessonIDs = this.state.selectedLessons.map((item) => {
        return item.value
      });

      try {
        const res = await GET('exams/get-competencies', {
          params: {
            asl_ids: JSON.stringify(lessonIDs),
            agegroup_id: selectedClass.class.agegroup_id,
          }
        });

        const objectives = res.data.map((item) => {
          return {
            ...item,
            value: item.aslr_id,
            label: item.aslr_desc,
          }
        });

        this.setState({ objectives });
      } catch (err) {
        console.log('err', err)

      }

    }
  }

  handleDragFileArea = (e, name) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.type === "dragover") {
      this.state.dragAreaColor.toggleColor(name, brand_colors[this.props.defaultTheme.theme_id].color1)
    } else if (e.type === "dragleave") {
      this.state.dragAreaColor.toggleColor(name, brand_colors[this.props.defaultTheme.theme_id].color18)
    }
  }

  readFile = (selectedFile) => {
    return new Promise(function (resolve, reject) {
      let reader = new FileReader();
      // 'audio/x-m4a', 'audio/mp3', 'audio/mp4', 'application/pdf',
      const allowedFiles = [
        'image/jpeg',
        'image/jpg',
        'image/png',
        'application/pdf'
      ];
      const allowedFilesDisplay = ['Image', 'PDF'];//'PDF', 
      const allowedSize = 5000000;
      if (selectedFile.size) {
        if (selectedFile.size >= allowedSize) {
          toast.error(t('Max file size allowed is ' + allowedSize / 1000000 + ' MB!'));
          resolve({ type: 'error' });
          return;
        } else if (!allowedFiles.includes(selectedFile.type)) {
          toast.error(t('Allowed file type are ' + allowedFilesDisplay.toString()));
          resolve({ type: 'error' });
          return;
        } else {
          if (selectedFile.type.includes('image')) {
            reader.readAsDataURL(selectedFile);
          } else if (selectedFile.type.includes('audio')) {
            reader.readAsArrayBuffer(selectedFile);
          } else {
            reader.readAsText(selectedFile);
          }
        }
        reader.onload = async (readerEvent) => {
          resolve({ type: 'success', data: selectedFile, url: readerEvent.target.result });
        };
        reader.onerror = (error) => {
          resolve({ type: 'error' });
        };
      } else {
        resolve({ type: 'error' });
      }
    });
  };

  addAttachment = (e) => {
    const selectedFiles = e.target.files;
    let allFiles = [];
    for (let i = 0; i < selectedFiles.length; i++) {
      allFiles.push(this.readFile(selectedFiles[i]));
    }
    Promise.all(allFiles).then((values) => {
      let attachments = e.target.multiple ? this.state[e.target.name] : null;
      let selectedFiles = values;
      selectedFiles.map((v, i) => {
        if (v.data?.type) {
          if (e.target.multiple) {
            attachments.push({ url: v.url, data: v.data });
          } else {
            attachments = { url: v.url, data: v.data };
          }
        }
      });
      this.setState({ [e.target.name]: attachments, dragAreaColor: { ...this.state.dragAreaColor, [e.target.name]: brand_colors[this.props.defaultTheme.theme_id].color18 } }, () => this.checkCompletedFormSections());
    });
  };

  render() {

    const { showCreateUpdateExamCanvas, showCreateUpdateQuestionModal, working, isEditingQuestion, classes, terms, lessons, examTypes, children, disableLessons, selectedClass, selectedTerm, selectedExamType, selectedSubject, selectedLessons, selectedChildren, examName, exams_attachment, weight, examNote, examDate, endDate, openTime, closeTime, examsquest_desc1, examsquest_desc2, examsquest_desc3, exam_notes, examsquest_grade, examsquest_duration, difficultyTypes, questionTypes, examsquest_difficulty, eqtype_id, examsquest_file1, examsquest_file1_prev, examsquest_file2, examsquest_file2_prev, examsquest_file3, examsquest_file3_prev, examsquest_file4, examsquest_file4_prev, examsquest_file5, examsquest_file5_prev, is_mandatory, shuffle_allowed, questions, examsquestoptions, formSectionActive, formSections, isEditing, is_exampaper } = this.state;

    if (!showCreateUpdateExamCanvas) {
      return null
    }

    const { authData, homework, examstypegrp_type } = this.props;
    const { auth_key } = authData.loginData;

    console.log('AddEditExam', this.state, this.props);


    let this_examstypegrp_type = this.state.DDL_examstypegrp_types.find((v, i) => v.value == examstypegrp_type)

    return (
      <>
        <Offcanvas
          show={showCreateUpdateExamCanvas}
          onHide={this.handleCloseCreateUpdateCanvas}
          style={{ height: '100%' }}
          placement='bottom'>
          <Offcanvas.Header
            id='iedu-header'
            style={{
              marginTop: '1rem',
              marginRight: '1rem',
              marginLeft: '1rem',
              marginBottom: 0,
            }} className='d-flex flex-row justify-content-between'>
            <div className='d-flex flex-row'>
              <span
                className='header-back-btn'
                onClick={() =>
                  this.handleCloseCreateUpdateCanvas()
                }
                style={{ marginRight: 10 }}>
                <FontAwesomeIcon
                  icon={faArrowAltCircleLeft}
                  color={brand_colors[this.props.defaultTheme.theme_id].color9}
                />
              </span>
              <Offcanvas.Title>
                <span className='me-2' style={{ fontWeight: 'bold', backgroundColor: changeColorOpacity(this_examstypegrp_type.color, 0.2), color: this_examstypegrp_type.color, borderRadius: 10, padding: '0.1rem 0.5rem', textAlign: 'center' }}>{this_examstypegrp_type.label}</span>
              </Offcanvas.Title>
            </div>
            {['Exam', 'Homework'].includes(examstypegrp_type) &&
              <div>
                <ButtonGroup size="sm" className='mx-3' style={{ borderRadius: 5, overflow: 'hidden' }}>
                  <Button
                    className='px-3'
                    style={{ fontWeight: '600', fontSize: 18 }}
                    onClick={() => this.setState({ is_exampaper: 0, exams_grade: 0, })}
                    variant={this.state.is_exampaper == 0 ? 'primary' : 'light'}>{t('Online')}</Button>
                  <Button
                    className='px-3'
                    style={{ fontWeight: '600', fontSize: 18 }}
                    onClick={() => this.setState({ is_exampaper: 1, exams_grade: 0, })}
                    variant={this.state.is_exampaper == 1 ? 'primary' : 'light'}>{t('Recitation')}</Button>
                </ButtonGroup>
              </div>
            }
            <div className='d-flex flex-row justify-content-end'>
              <ClassSelector func={[this.handleClassChange]} minimal={true} />
              <SubjectSelector func={[this.handleSubjectChange]} />
              <Button
                className='ms-3'
                disabled={working}
                variant={(isEditing) ? 'warning' : 'success'}
                onClick={() => this.handleSaveForm()}>
                {(isEditing) ? t('Update') : t('Save')}{' '}
                {working ? (
                  <Spinner
                    as='span'
                    animation='grow'
                    size='sm'
                    role='status'
                    aria-hidden='true'
                  />
                ) : (
                  ''
                )}
              </Button>
            </div>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div
              className={'d-flex flex-row grey-section'}
              style={{
                overflow: 'initial',
                backgroundImage:
                  'url(https://academic.educore.io/static/media/pegboard-bg.9b84e4936acef29bbcd9.png)',
                backgroundRepeat: 'repeat',
                backgroundPosition: '10px 12px',
                overflow: 'hidden',
              }}>
              <div
                style={{
                  backgroundColor: brand_colors[this.props.defaultTheme.theme_id].color8,
                  marginLeft: 10,
                  marginRight: 10,
                  borderRadius: '20px',
                  width: '250px',
                  height: '100%',
                  top: '5px',
                  position: 'sticky',
                }}>
                <div
                  style={{
                    paddingRight: '1.5rem',
                    paddingBottom: '1.5rem',
                    paddingTop: '1rem',
                  }}>
                  {formSections.map((v, i) => {
                    return (
                      <div key={i}>
                        <div
                          className='d-flex flex-row align-items-center'
                          style={{
                            cursor: 'pointer',
                            borderRadius: '20px',
                            paddingLeft: '5px',
                            paddingRight: '5px',
                            backgroundColor:
                              v.uid ==
                                formSectionActive.parent
                                ? brand_colors[this.props.defaultTheme.theme_id].color19
                                : brand_colors[this.props.defaultTheme.theme_id].color8,
                            zIndex: 1,
                            position: 'relative',
                          }}
                          onClick={() =>
                            this.changeToFromSection(
                              v.uid,
                              v.uid + '.1'
                            )
                          }>
                          <FontAwesomeIcon
                            icon={
                              v.completed
                                ? faCheckCircle
                                : faCircle
                            }
                            color={
                              v.uid ==
                                formSectionActive.parent
                                ? brand_colors[this.props.defaultTheme.theme_id].color20
                                : v.completed
                                  ? brand_colors[this.props.defaultTheme.theme_id].color4
                                  : brand_colors[this.props.defaultTheme.theme_id].color9
                            }
                            style={{
                              fontSize: 12,
                              marginRight: 5,
                              opacity:
                                v.uid ==
                                  formSectionActive.parent ||
                                  v.completed
                                  ? 1
                                  : 0.7,
                            }}
                          />
                          <span
                            style={{
                              fontSize: 18,
                              fontWeight: '600',
                              color:
                                v.uid ==
                                  formSectionActive.parent
                                  ? brand_colors[this.props.defaultTheme.theme_id].color20
                                  : brand_colors[this.props.defaultTheme.theme_id].color9,
                              opacity:
                                v.uid ==
                                  formSectionActive.parent
                                  ? 1
                                  : 0.7,
                            }}>
                            {v.name}
                          </span>
                        </div>

                        <div
                          style={{
                            paddingLeft: '1rem',
                            borderLeft:
                              'solid 1px color18',
                            marginLeft: '1.5rem',
                          }}>
                          {v.data.map((vv, ii) => {

                            if (['Shuffle Questions', 'Open Time', 'Close Time'].includes(vv.name) && this.state.is_exampaper == 1) {
                              return false;
                            }

                            return (
                              <div
                                key={ii}
                                className='d-flex flex-row align-items-center'
                                style={{
                                  marginBottom: 5,
                                  marginTop: 2,
                                  cursor: 'pointer',
                                }}
                                onClick={() =>
                                  this.changeToFromSection(
                                    v.uid,
                                    vv.uid
                                  )
                                }>
                                <FontAwesomeIcon
                                  icon={
                                    vv.completed
                                      ? faCheckCircle
                                      : faCircle
                                  }
                                  color={
                                    vv.completed
                                      ? brand_colors[this.props.defaultTheme.theme_id].color4
                                      : brand_colors[this.props.defaultTheme.theme_id].color18
                                  }
                                  style={{
                                    fontSize: 12,
                                    marginRight: 5,
                                  }}
                                />
                                <span
                                  style={{
                                    fontSize: 14,
                                    color: vv.completed
                                      ? brand_colors[this.props.defaultTheme.theme_id].color4
                                      : brand_colors[this.props.defaultTheme.theme_id].color18,
                                  }}>
                                  {vv.name}
                                </span>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <Col
                className='custom-styled-form hide-scrollbar'
                style={{ backgroundColor: brand_colors[this.props.defaultTheme.theme_id].color8, marginLeft: 10, marginRight: 10, borderRadius: '20px', padding: '1rem', overflow: 'auto', height: '75vh', }}>
                <Row>
                  <Col md={12}>
                    <Row>
                      <Col md={3}>
                        <div id='form_section_1.2'>
                          <Form.Group className='mb-3'>
                            <Form.Label>
                              {t('Term')}
                            </Form.Label>
                            <Select
                              classNamePrefix='custom-styled-select'
                              placeholder={t('Select Term')}
                              options={terms}
                              onChange={this.handleTermChange}
                              value={selectedTerm}
                              menuPortalTarget={document.body}
                              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                              formatOptionLabel={option => (
                                <div className='d-flex align-items-center' style={{ fontSize: 13 }}>
                                  <span className='me-1'>{option.label}</span>
                                  {option.term_active == 1 && <Badge pill bg="success">{t('Active')}</Badge>}
                                </div>
                              )}
                            />
                          </Form.Group>
                        </div>
                      </Col>
                      <Col md={3}>
                        <div id='form_section_1.6'>
                          <Form.Group className='mb-3'>
                            <Form.Label>
                              {t(examstypegrp_type + ' Name')}
                            </Form.Label>
                            <Form.Control
                              type='text'
                              onBlur={this.handleChangeTextField}
                              name='examName'
                              defaultValue={examName}
                            />
                          </Form.Group>
                        </div>
                      </Col>
                      <Col md={3}>
                        <div id='form_section_1.7'>
                          <Form.Group className='mb-3'>
                            <Form.Label>
                              {t(examstypegrp_type + ' Type')}
                            </Form.Label>
                            <Select
                              classNamePrefix='custom-styled-select'
                              menuPortalTarget={document.body}
                              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999, }), }}
                              // isClearable={true}
                              options={examTypes}
                              onChange={this.handleSelectField('selectedExamType')}
                              value={selectedExamType}
                            />
                          </Form.Group>
                        </div>
                      </Col>
                      <Col md={3}>
                        <div id='form_section_1.12'>
                          <Form.Group className='mb-3'>
                            <Form.Label>
                              {t('Total ' + examstypegrp_type + ' Grade')}
                            </Form.Label>
                            <Form.Control
                              type='text'
                              onChange={this.handleChangeTextField}
                              name='exams_grade'
                              value={parseFloat(this.state.exams_grade)}
                              disabled={this.state.is_exampaper == 0}
                            />
                          </Form.Group>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={3}>
                        <div id='form_section_1.9'>
                          <Form.Group className='mb-3'>
                            <Form.Label>
                              {t('Start Date')}
                            </Form.Label>
                            <Flatpickr className='form-control' value={examDate} onChange={([date]) => {
                              this.setState({
                                examDate: moment(date).format('YYYY-MM-DD'),
                              }, () => this.checkCompletedFormSections())
                            }}
                            />
                          </Form.Group>
                        </div>
                      </Col>
                      {['Homework'].includes(examstypegrp_type) &&
                        <Col md={3}>
                          <div id='form_section_1.9'>
                            <Form.Group className='mb-3'>
                              <Form.Label>
                                {t('End Date')}
                              </Form.Label>
                              <Flatpickr className='form-control' value={endDate} onChange={([date]) => {
                                this.setState({ endDate: moment(date).format('YYYY-MM-DD') },
                                  () => this.checkCompletedFormSections())
                              }}
                              />
                            </Form.Group>
                          </div>
                        </Col>
                      }
                      {is_exampaper == 0 &&
                        <Col md={2}>
                          <div id='form_section_1.10'>
                            <Form.Group className='mb-3'>
                              <Form.Label>
                                {t('Open Time')}
                              </Form.Label>
                              <input type='time' className='form-control' value={openTime} onChange={(e) => this.setState({ openTime: e.target.value }, () => this.checkCompletedFormSections())} />
                            </Form.Group>
                          </div>
                        </Col>
                      }
                      {is_exampaper == 0 &&
                        <Col md={2}>
                          <div id='form_section_1.11'>
                            <Form.Group className='mb-3'>
                              <Form.Label>
                                {t('Close Time')}
                              </Form.Label>
                              <input type='time' className='form-control' value={closeTime} onChange={(e) => this.setState({ closeTime: e.target.value }, () => this.checkCompletedFormSections())} />
                            </Form.Group>
                          </div>
                        </Col>
                      }
                    </Row>
                    <Row>
                      <Col md={12}>
                        <div id='form_section_1.4'>
                          <Form.Group className='mb-3'>
                            <Form.Label>
                              {t('Lessons')}
                            </Form.Label>
                            <Select
                              classNamePrefix='custom-styled-select'
                              menuPortalTarget={document.body}
                              styles={{
                                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                              }}
                              options={lessons}
                              // isDisabled={
                              //   disableLessons
                              // }
                              onChange={this.handleSelectField('selectedLessons')}
                              closeMenuOnSelect={false}
                              value={selectedLessons}
                              isMulti
                              className='basic-multi-select'
                            />
                          </Form.Group>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <div id='form_section_1.12'>
                          <Form.Group className='mb-3'>
                            <Form.Label>
                              {t('Note')}
                            </Form.Label>
                            <Form.Control
                              type='text'
                              onBlur={this.handleChangeTextField}
                              name='examNote'
                              defaultValue={examNote}
                            />
                          </Form.Group>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={8}>
                        <Row>
                          {/* STUDENT GROUPS */}
                          {this.state.groups.length > 0 &&
                            <Col md={12}>
                              {this.state.groups.map((item, index) => <button
                                key={index}
                                disabled={selectedTerm ? false : true}
                                style={{ backgroundColor: item.stdgrp_color, color: '#fff', fontSize: 12, fontWeight: 700, border: 0, borderRadius: 10, marginRight: 5, cursor: 'pointer' }}
                                onClick={() => this.filterStudentByGroup(item.stdgrp_id)}
                              >{item.stdgrp_name}</button>)}
                            </Col>
                          }
                          <Col md={12}>
                            <div id='form_section_1.5'>
                              <Form.Group className='mb-1'>
                                <Form.Label>
                                  {t('Tag Students')}
                                </Form.Label>
                                <Select
                                  classNamePrefix='custom-styled-select'
                                  ref={this.tagStudent}
                                  menuPortalTarget={document.body}
                                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999, }), }}
                                  closeMenuOnSelect={false}
                                  value={selectedChildren}
                                  isMulti
                                  options={children}
                                  className='basic-multi-select'
                                  onChange={this.handleSelectField('selectedChildren')}
                                  isDisabled={selectedTerm ? false : true}
                                />
                              </Form.Group>
                            </div>
                          </Col>
                          <Col md={12}>
                            <ButtonGroup size="sm">
                              <Button variant="primary"
                                disabled={selectedTerm ? false : true}
                                onClick={() => {
                                  this.tagStudent.current.setValue(children)
                                  this.setState({ selectedChildren: children }, () => {
                                    this.checkCompletedFormSections()
                                  })
                                }}>{t('Select All')}</Button>
                              <Button variant="warning"
                                disabled={selectedTerm ? false : true}
                                onClick={() => {
                                  this.tagStudent.current.setValue([])
                                  this.setState({ selectedChildren: [] }, () => this.checkCompletedFormSections())
                                }}>{t('De-Select All')}</Button>
                            </ButtonGroup>
                          </Col>

                        </Row>
                        <Row>
                          {/* !HIDDEN */}
                          <Col md={3} style={{ display: 'none' }}>
                            <div id='form_section_1.8'>
                              <Form.Group className='mb-3'>
                                <Form.Label>
                                  {t('Weight')}
                                </Form.Label>
                                <Form.Control
                                  type='text'
                                  onBlur={this.handleChangeTextField}
                                  name='weight'
                                  defaultValue={weight}
                                />
                              </Form.Group>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      <Col md={4} style={{ paddingTop: 24 }}>
                        <div style={{ flexDirection: 'column' }} className='pic-prev d-flex justify-content-center mb-3' id='form_section_2.3'>
                          <div style={{ height: 192, padding: 10, overflow: 'hidden', borderStyle: 'dashed', borderColor: this.state.dragAreaColor.exams_attachment, borderWidth: 2, borderRadius: 20, position: 'relative' }} className='d-flex justify-content-center align-items-center'
                            onDragEnter={(e) => this.handleDragFileArea(e, 'exams_attachment')} onDragLeave={(e) => this.handleDragFileArea(e, 'exams_attachment')} onDragOver={(e) => this.handleDragFileArea(e, 'exams_attachment')} onDrop={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              if (e.dataTransfer.files && e.dataTransfer.files[0]) {
                                this.addAttachment({ target: { files: e.dataTransfer.files, multiple: false, name: 'exams_attachment' } })
                              }
                            }}>
                            {exams_attachment && exams_attachment != 'deleted' && (
                              <>
                                {exams_attachment.data.type.includes('pdf') &&
                                  <FontAwesomeIcon icon={faFilePdf} style={{ fontSize: 60, }} />
                                }
                                {!exams_attachment.data.type.includes('pdf') &&
                                  <img src={exams_attachment.url} alt={t('Preview')} style={{ height: '100%' }} />
                                }
                              </>
                            )}
                            {(!exams_attachment || exams_attachment == 'deleted') &&
                              <button onClick={() => this.exams_attachment.current.click()} style={{ background: 'none', border: 0, flex: 1, height: '100%', lineHeight: '20px' }}>
                                {/* <FontAwesomeIcon icon={faPlus} style={{ fontSize: 30, color: color18 }} /> */}
                                <span style={{ fontSize: 20, fontWeight: 'bold', color: brand_colors[this.props.defaultTheme.theme_id].color18 }}>{t('Attachment')}</span>
                                <br />
                                <small className='text-muted'>
                                  {t('Select or Drag & Drop')}
                                </small>
                                <br />
                                <small className='text-danger'>
                                  {t('(only Image or PDF)')}
                                </small>
                              </button>
                            }
                            {exams_attachment && exams_attachment != 'deleted' && (
                              <FontAwesomeIcon icon={faTrashCan} style={{ fontSize: 14, color: brand_colors[this.props.defaultTheme.theme_id].color8, position: 'absolute', top: 6, right: 6, cursor: 'pointer', backgroundColor: brand_colors[this.props.defaultTheme.theme_id].color11, borderRadius: 100, padding: 5 }} onClick={() => this.setState({ exams_attachment: 'deleted' })}
                              />
                            )}
                          </div>
                          <Form.Control className='d-none' ref={this.exams_attachment} type='file' onChange={this.addAttachment} name='exams_attachment' />
                        </div>
                      </Col>
                    </Row>
                    {this.state.is_exampaper == 0 && <hr />}
                  </Col>
                  {this.state.is_exampaper == 0 &&
                    <Col md={12}>
                      <div className='d-flex justify-content-between align-items-center mb-4'>
                        <h6 className='mb-0'>
                          <div className='d-flex flex-row'>
                            <div className='me-3'>{t('Questions')}</div>
                            {this.state.is_exampaper == 0 &&
                              <div id='form_section_1.13'>
                                <Form>
                                  <Form.Check
                                    type='switch'
                                    label={t('Shuffle Questions')}
                                    onChange={this.handleChangeCheckField}
                                    name='shuffleQs'
                                  />
                                </Form>
                              </div>
                            }
                          </div>
                        </h6>
                        <div>
                          <Button
                            variant={isEditing ? 'primary' : 'quaternary'}
                            className='me-2'
                            title={!isEditing ? t('Please save exam for importing questions') : t('Import Questions')}
                            style={{
                              cursor: isEditing ? 'pointer' : 'not-allowed',
                              opacity: isEditing ? 1 : 0.3
                            }}
                            onClick={() => isEditing ? this.handleImportQuestionModal() : toast.error(t('Please Save first!'))}
                          >
                            <FontAwesomeIcon className='me-1' icon={faPlus} color={brand_colors[this.props.defaultTheme.theme_id].color8} />
                            {t('Import Questions')}
                          </Button>
                          <Button variant='success' onClick={this.handleShowCreateUpdateQuestionModal}>
                            {t('Add Question')}
                          </Button>
                        </div>
                      </div>
                      <div className='questions'>
                        {questions.map((item, index) => (
                          <Card
                            key={index}
                            className='mb-3 q-card'>
                            <Card.Body>
                              <button
                                title={t('Edit')}
                                className='btn-primary btn-icon-primary'
                                onClick={() =>
                                  this.editQuestion(
                                    index
                                  )
                                }>
                                <FontAwesomeIcon
                                  icon={faEdit}
                                  color={brand_colors[this.props.defaultTheme.theme_id].color8}
                                  style={{
                                    fontSize: 13,
                                  }}
                                />
                              </button>
                              <button
                                title={t('Delete')}
                                onClick={() =>
                                  this.deleteQuestion(
                                    index
                                  )
                                }
                                className='btn-icon-danger'>
                                <FontAwesomeIcon
                                  icon={faTimes}
                                  color={brand_colors[this.props.defaultTheme.theme_id].color8}
                                  style={{
                                    fontSize: 15,
                                  }}
                                />
                              </button>
                              <div className='q-card-inner'>
                                <div>
                                  <Badge bg="primary">
                                    {
                                      item
                                        .eqtype_id
                                        .label
                                    }
                                  </Badge>{' '}
                                  <Badge bg="warning">
                                    {t(
                                      'Marks: '
                                    ) +
                                      item.examsquest_grade
                                    }
                                  </Badge>
                                  {' '}
                                  <Badge bg="info">
                                    {t(
                                      'Duration: '
                                    ) +
                                      item.examsquest_duration + ' ' + t('Minutes')
                                    }
                                  </Badge>
                                  {' '}
                                  <Badge bg="danger">
                                    {t(
                                      'Difficulty: '
                                    ) +
                                      item.eqdifficulty_name
                                    }
                                  </Badge>
                                </div>
                                <h3>
                                  <strong>
                                    <small>
                                      {item.examsquest_desc1.replace(
                                        /(<([^>]+)>)/gi,
                                        ' '
                                      )}
                                    </small>
                                  </strong>
                                </h3>
                                <div className='quest-answers'>
                                  {item.examsquestoptions.map((item2, index) => (
                                    <div key={index}>
                                      {item2.is_checked == 1 ? <div><Badge bg="success">{index + 1}: {item2.option_text}</Badge></div> : <div>
                                        <strong>{index + 1}: {item
                                          .eqtype_id.value == 8 ? item2.option_value : item2.option_text}</strong>
                                      </div>}
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </Card.Body>
                          </Card>
                        ))}
                      </div>
                    </Col>
                  }
                </Row>
              </Col>
            </div>
          </Offcanvas.Body >
        </Offcanvas >
        <Modal
          // fullscreen
          size='xl'
          show={showCreateUpdateQuestionModal}
          onHide={this.handleCloseCreateUpdateQuestionModal}>
          <Modal.Header closeButton>
            <Modal.Title>
              {isEditingQuestion
                ? t('Update Question')
                : t('Add Question')}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Tabs
              defaultActiveKey='qna'
              id='qna-tabs'
              justify
              className='mb-3'>
              <Tab
                eventKey='qna'
                title={t('Questions & Answers')}>
                <Row>
                  <Col md={6}>
                    <Row>
                      <Col md={12} className='mb-2'>
                        <CKEditor
                          config={{
                            ckfinder: {
                              uploadUrl: apiURL + '/authentication/ckeditor-upload?auth_key=' + auth_key
                            },
                            toolbar: {
                              items: [
                                'heading',
                                '|',
                                'bold',
                                'italic',
                                '|',
                                'numberedList',
                                'bulletedList',
                                '|',
                                'indent',
                                'outdent',
                                '|',
                                'link',
                                'blockQuote',
                                'imageUpload',
                                'insertTable',
                                '|',
                                'undo',
                                'redo'
                              ]
                            }
                          }}
                          style={{ height: 200 }}
                          editor={Editor}
                          data={examsquest_desc1}
                          onBlur={(
                            event,
                            editor
                          ) => {
                            const data =
                              editor.getData();
                            this.setState({
                              examsquest_desc1:
                                data,
                            });
                          }}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={4}>
                        <Form.Group
                          controlId='file1'
                          className='mb-3'>
                          <Form.Label>
                            {t('File 1')}{' '}
                            <small className='text-danger'>
                              {t(
                                '(only Image or Pdf)'
                              )}
                            </small>
                          </Form.Label>
                          <div
                            style={{ flexDirection: 'column', }}
                            className='pic-prev d-flex justify-content-center'>
                            <div
                              style={{ borderRadius: 5, borderStyle: 'dashed', borderWidth: 2, borderColor: '#ced4da', height: 158, display: 'flex', overflow: 'hidden', }}>
                              {examsquest_file1 !==
                                '' &&
                                examsquest_file1_prev !==
                                '' ? (
                                <div
                                  className='d-flex justify-content-center align-items-center'
                                  style={{ flex: 1, position: 'relative', }}>
                                  <button
                                    style={{ padding: 3, border: 3, background: 'none', position: 'absolute', top: 0, right: 0, }}
                                    onClick={() => {
                                      this.setState(
                                        {
                                          examsquest_file1:
                                            '',
                                          examsquest_file1_prev:
                                            '',
                                        }
                                      );
                                      this.examsquest_file1.current.value =
                                        null;
                                    }}>
                                    <FontAwesomeIcon
                                      icon={
                                        faTimesCircle
                                      }
                                      color='rgb(220, 53, 69)'
                                      style={{
                                        fontSize: 20,
                                      }}
                                    />
                                  </button>
                                  {examsquest_file1_prev ==
                                    'pdf' ? (
                                    <FontAwesomeIcon
                                      icon={
                                        faFilePdf
                                      }
                                      style={{
                                        fontSize: 60,
                                      }}
                                    />
                                  ) : (
                                    <img
                                      src={
                                        examsquest_file1_prev
                                      }
                                      alt={t(
                                        'Preview'
                                      )}
                                      width='100'
                                    />
                                  )}
                                </div>
                              ) : (
                                <button
                                  onClick={() =>
                                    this.examsquest_file1.current.click()
                                  }
                                  style={{
                                    background:
                                      'none',
                                    border: 0,
                                    flex: 1,
                                  }}>
                                  <FontAwesomeIcon
                                    icon={
                                      faPlus
                                    }
                                    style={{
                                      fontSize: 30,
                                    }}
                                  />
                                </button>
                              )}
                            </div>
                            <Form.Control
                              className='d-none'
                              ref={
                                this
                                  .examsquest_file1
                              }
                              type='file'
                              onChange={
                                this.previewFile
                              }
                              name='examsquest_file1'
                              accept='.jpg, .jpeg, .png, .pdf'
                            />
                          </div>
                        </Form.Group>
                      </Col>
                      <Col md={8}>
                        <Row>
                          <Col md={6}>
                            <Form.Group className='mb-3'>
                              <Form.Label>
                                {t(
                                  'Question Marks'
                                )}
                              </Form.Label>
                              <Form.Control
                                type='text'
                                onBlur={
                                  this
                                    .handleChangeTextField
                                }
                                name='examsquest_grade'
                                defaultValue={
                                  examsquest_grade
                                }
                              />
                            </Form.Group>
                          </Col>
                          <Col md={6}>
                            <Form.Group className='mb-3'>
                              <Form.Label>
                                {t('Minutes')}
                              </Form.Label>
                              <Form.Control
                                type='text'
                                onBlur={
                                  this
                                    .handleChangeTextField
                                }
                                name='examsquest_duration'
                                defaultValue={
                                  examsquest_duration
                                }
                              />
                            </Form.Group>
                          </Col>
                          <Col md={6}>
                            <Form.Group className='mb-3'>
                              <Form.Label>
                                {t(
                                  'Difficulty'
                                )}
                              </Form.Label>
                              <Select
                                options={
                                  difficultyTypes
                                }
                                onChange={this.handleSelectField(
                                  'examsquest_difficulty'
                                )}
                                value={
                                  examsquest_difficulty
                                }
                              />
                            </Form.Group>
                          </Col>
                          <Col md={6}>
                            <Form.Group className='mb-3'>
                              <Form.Label>
                                {t('Type')}
                              </Form.Label>
                              <Select
                                options={
                                  questionTypes
                                }
                                onChange={this.handleSelectField(
                                  'eqtype_id'
                                )}
                                value={
                                  eqtype_id
                                }
                              />
                            </Form.Group>
                          </Col>
                          <Col md={6}>
                            <Form>
                              <Form.Check
                                type='switch'
                                checked={
                                  is_mandatory ==
                                    0
                                    ? false
                                    : true
                                }
                                label={t(
                                  'Mandatory'
                                )}
                                onChange={
                                  this
                                    .handleChangeCheckField
                                }
                                name='is_mandatory'
                              />
                            </Form>
                          </Col>
                          <Col md={6}>
                            <Form>
                              <Form.Check
                                type='switch'
                                checked={
                                  shuffle_allowed ==
                                    0
                                    ? false
                                    : true
                                }
                                label={t(
                                  'Shuffle Options'
                                )}
                                onChange={
                                  this
                                    .handleChangeCheckField
                                }
                                name='shuffle_allowed'
                              />
                            </Form>
                          </Col>
                        </Row>
                      </Col>
                      <Col md={12}>
                        <Form.Group className='mb-3'>
                          <Form.Label>
                            {t(
                              'Objectives'
                            )}
                          </Form.Label>
                          <Select
                            isMulti
                            options={
                              this.state.objectives
                            }
                            onChange={this.handleSelectField(
                              'asl_ids_data'
                            )}
                            value={
                              this.state.asl_ids_data
                            }
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={6}>
                    <div className='d-flex justify-content-between align-items-center mb-4'>
                      <h6 className='mb-0'>
                        {t('Question Options')}
                      </h6>
                      <Button
                        disabled={
                          eqtype_id !== null &&
                            eqtype_id.value !== '4' &&
                            eqtype_id.value !== '5' &&
                            eqtype_id.value !== '7' &&
                            eqtype_id.value !== '9'
                            ? false
                            : true
                        }
                        variant='success'
                        onClick={this.addOption}>
                        {t('Add Option')}
                      </Button>
                    </div>
                    {examsquestoptions.map(
                      (item, index) => (
                        <Card
                          className='mb-3 quest-opt-card'
                          key={index}>
                          {(eqtype_id.value == 1 ||
                            eqtype_id.value ==
                            3) && (
                              <button
                                className='mark-correct'
                                style={{
                                  backgroundColor:
                                    item.is_checked ==
                                      0
                                      ? brand_colors[this.props.defaultTheme.theme_id].color10
                                      : brand_colors[this.props.defaultTheme.theme_id].color4,
                                }}
                                onClick={() =>
                                  this.handleOptionCheckChange(
                                    index,
                                    'is_checked',
                                    true
                                  )
                                }>
                                <FontAwesomeIcon
                                  icon={
                                    item.is_checked ==
                                      0
                                      ? faCircle
                                      : faCircleCheck
                                  }
                                  color={brand_colors[this.props.defaultTheme.theme_id].color8}
                                  style={{
                                    fontSize: 17,
                                  }}
                                />
                                <span className='ms-1'>
                                  {t(
                                    'Mark as correct'
                                  )}
                                </span>
                              </button>
                            )}
                          {eqtype_id.value == 2 && (
                            <button
                              className='mark-correct'
                              style={{
                                paddingLeft: 8,
                                backgroundColor:
                                  item.is_checked ==
                                    0
                                    ? brand_colors[this.props.defaultTheme.theme_id].color10
                                    : brand_colors[this.props.defaultTheme.theme_id].color4,
                              }}
                              onClick={() =>
                                this.handleOptionCheckChange(
                                  index,
                                  'is_checked'
                                )
                              }>
                              <FontAwesomeIcon
                                icon={
                                  item.is_checked ==
                                    0
                                    ? faSquare
                                    : faSquareCheck
                                }
                                color={brand_colors[this.props.defaultTheme.theme_id].color8}
                                style={{
                                  fontSize: 17,
                                }}
                              />
                              <span className='ms-1'>
                                {t(
                                  'Mark as correct'
                                )}
                              </span>
                            </button>
                          )}
                          <button
                            onClick={() =>
                              this.deleteOption(
                                index
                              )
                            }
                            className='btn-icon-danger'>
                            <FontAwesomeIcon
                              icon={faTimes}
                              color={brand_colors[this.props.defaultTheme.theme_id].color8}
                              style={{
                                fontSize: 16,
                              }}
                            />
                          </button>
                          <Card.Body>
                            {eqtype_id.value ==
                              8 ? (
                              <TagsInput
                                value={
                                  item.option_value
                                }
                                onChange={this.handleOptionTagsChange(
                                  index,
                                  'option_value'
                                )}
                                name='option_value'
                              />
                            ) : (
                              <Row>
                                <Col
                                  md={eqtype_id.value == 6 ? 6 : 12}>
                                  {(item.option_text_picture && typeof item.option_text_picture == 'string') &&
                                    <a target="_blank" href={portalURL + item.center_id + '/examsquestoptions/' + item.option_text_picture}>Selected File</a>
                                  }
                                  {(eqtype_id.value !== '3') && (
                                    <Form.Control
                                      type='file'
                                      className='mb-2'
                                      name='option_text_picture'
                                      onChange={this.handleOptionFileChange(
                                        index
                                      )}
                                    />
                                  )}
                                  <Form.Control
                                    type='text'
                                    name='option_text'
                                    value={
                                      item.option_text
                                    }
                                    onChange={this.handleOptionTextChange(
                                      index
                                    )}
                                  />
                                </Col>
                                {eqtype_id.value ==
                                  6 && (
                                    <Col
                                      md={6}
                                      className='ps-0'>
                                      {(item.option_value_picture && typeof item.option_value_picture == 'string') &&
                                        <a target="_blank" href={portalURL + item.center_id + '/examsquestoptions/' + item.option_value_picture}>Selected File</a>
                                      }
                                      <Form.Control
                                        type='file'
                                        className='mb-2'
                                        name='option_value_picture'
                                        onChange={this.handleOptionFileChange(
                                          index
                                        )}
                                      />
                                      <Form.Control
                                        type='text'
                                        name='option_value'
                                        value={
                                          item.option_value
                                        }
                                        onChange={this.handleOptionTextChange(
                                          index
                                        )}
                                      />
                                    </Col>
                                  )}
                              </Row>
                            )}
                          </Card.Body>
                        </Card>
                      )
                    )}
                  </Col>
                </Row>
              </Tab>
              <Tab eventKey='advanced' title={t('Advanced')}>
                <Row>
                  <Col md={6}>
                    <Form.Group className='mb-3'>
                      <Form.Label>
                        {t('Hint 1')}
                      </Form.Label>
                      <Form.Control
                        type='text'
                        onChange={
                          this.handleChangeTextField
                        }
                        name='examsquest_desc2'
                        value={examsquest_desc2}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className='mb-3'>
                      <Form.Label>
                        {t('Hint 2')}
                      </Form.Label>
                      <Form.Control
                        type='text'
                        onChange={
                          this.handleChangeTextField
                        }
                        name='examsquest_desc3'
                        value={examsquest_desc3}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    <Form.Group className='mb-3'>
                      <Form.Label>
                        {t('General Note')}
                      </Form.Label>
                      <Form.Control
                        type='text'
                        onChange={
                          this.handleChangeTextField
                        }
                        name='exam_notes'
                        value={exam_notes}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={3}>
                    <Form.Group
                      controlId='file2'
                      className='mb-3'>
                      <Form.Label>
                        {t('File 2')}{' '}
                        <small className='text-danger'>
                          {t('(only Image or Pdf)')}
                        </small>
                      </Form.Label>
                      <div
                        style={{
                          flexDirection: 'column',
                        }}
                        className='pic-prev d-flex justify-content-center'>
                        <div
                          style={{
                            borderRadius: 5,
                            borderStyle: 'dashed',
                            borderWidth: 2,
                            borderColor: '#ced4da',
                            height: 158,
                            display: 'flex',
                            overflow: 'hidden',
                          }}>
                          {examsquest_file2 !== '' &&
                            examsquest_file2_prev !==
                            '' ? (
                            <div
                              className='d-flex justify-content-center align-items-center'
                              style={{
                                flex: 1,
                                position:
                                  'relative',
                              }}>
                              <button
                                style={{
                                  padding: 3,
                                  border: 3,
                                  background:
                                    'none',
                                  position:
                                    'absolute',
                                  top: 0,
                                  right: 0,
                                }}
                                onClick={() => {
                                  this.setState(
                                    {
                                      examsquest_file2:
                                        '',
                                      examsquest_file2_prev:
                                        '',
                                    }
                                  );
                                  this.examsquest_file2.current.value =
                                    null;
                                }}>
                                <FontAwesomeIcon
                                  icon={
                                    faTimesCircle
                                  }
                                  color='rgb(220, 53, 69)'
                                  style={{
                                    fontSize: 20,
                                  }}
                                />
                              </button>
                              {examsquest_file2_prev ==
                                'pdf' ? (
                                <FontAwesomeIcon
                                  icon={
                                    faFilePdf
                                  }
                                  style={{
                                    fontSize: 60,
                                  }}
                                />
                              ) : (
                                <img
                                  src={
                                    examsquest_file2_prev
                                  }
                                  alt={t(
                                    'Preview'
                                  )}
                                  width='100'
                                />
                              )}
                            </div>
                          ) : (
                            <button
                              onClick={() =>
                                this.examsquest_file2.current.click()
                              }
                              style={{
                                background:
                                  'none',
                                border: 0,
                                flex: 1,
                              }}>
                              <FontAwesomeIcon
                                icon={faPlus}
                                style={{
                                  fontSize: 30,
                                }}
                              />
                            </button>
                          )}
                        </div>
                        <Form.Control
                          className='d-none'
                          ref={this.examsquest_file2}
                          type='file'
                          onChange={this.previewFile}
                          name='examsquest_file2'
                          accept='.jpg, .jpeg, .png, .pdf'
                        />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col md={3}>
                    <Form.Group
                      controlId='file3'
                      className='mb-3'>
                      <Form.Label>
                        {t('File 3')}{' '}
                        <small className='text-danger'>
                          {t('(only Image or Pdf)')}
                        </small>
                      </Form.Label>
                      <div
                        style={{
                          flexDirection: 'column',
                        }}
                        className='pic-prev d-flex justify-content-center'>
                        <div
                          style={{
                            borderRadius: 5,
                            borderStyle: 'dashed',
                            borderWidth: 2,
                            borderColor: '#ced4da',
                            height: 158,
                            display: 'flex',
                            overflow: 'hidden',
                          }}>
                          {examsquest_file3 !== '' &&
                            examsquest_file3_prev !==
                            '' ? (
                            <div
                              className='d-flex justify-content-center align-items-center'
                              style={{
                                flex: 1,
                                position:
                                  'relative',
                              }}>
                              <button
                                style={{
                                  padding: 3,
                                  border: 3,
                                  background:
                                    'none',
                                  position:
                                    'absolute',
                                  top: 0,
                                  right: 0,
                                }}
                                onClick={() => {
                                  this.setState(
                                    {
                                      examsquest_file3:
                                        '',
                                      examsquest_file3_prev:
                                        '',
                                    }
                                  );
                                  this.examsquest_file3.current.value =
                                    null;
                                }}>
                                <FontAwesomeIcon
                                  icon={
                                    faTimesCircle
                                  }
                                  color='rgb(220, 53, 69)'
                                  style={{
                                    fontSize: 20,
                                  }}
                                />
                              </button>
                              {examsquest_file3_prev ==
                                'pdf' ? (
                                <FontAwesomeIcon
                                  icon={
                                    faFilePdf
                                  }
                                  style={{
                                    fontSize: 60,
                                  }}
                                />
                              ) : (
                                <img
                                  src={
                                    examsquest_file3_prev
                                  }
                                  alt={t(
                                    'Preview'
                                  )}
                                  width='100'
                                />
                              )}
                            </div>
                          ) : (
                            <button
                              onClick={() =>
                                this.examsquest_file3.current.click()
                              }
                              style={{
                                background:
                                  'none',
                                border: 0,
                                flex: 1,
                              }}>
                              <FontAwesomeIcon
                                icon={faPlus}
                                style={{
                                  fontSize: 30,
                                }}
                              />
                            </button>
                          )}
                        </div>
                        <Form.Control
                          className='d-none'
                          ref={this.examsquest_file3}
                          type='file'
                          onChange={this.previewFile}
                          name='examsquest_file3'
                          accept='.jpg, .jpeg, .png, .pdf'
                        />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col md={3}>
                    <Form.Group
                      controlId='file4'
                      className='mb-3'>
                      <Form.Label>
                        {t('File 4')}{' '}
                        <small className='text-danger'>
                          {t('(only Image or Pdf)')}
                        </small>
                      </Form.Label>
                      <div
                        style={{
                          flexDirection: 'column',
                        }}
                        className='pic-prev d-flex justify-content-center'>
                        <div
                          style={{
                            borderRadius: 5,
                            borderStyle: 'dashed',
                            borderWidth: 2,
                            borderColor: '#ced4da',
                            height: 158,
                            display: 'flex',
                            overflow: 'hidden',
                          }}>
                          {examsquest_file4 !== '' &&
                            examsquest_file4_prev !==
                            '' ? (
                            <div
                              className='d-flex justify-content-center align-items-center'
                              style={{
                                flex: 1,
                                position:
                                  'relative',
                              }}>
                              <button
                                style={{
                                  padding: 3,
                                  border: 3,
                                  background:
                                    'none',
                                  position:
                                    'absolute',
                                  top: 0,
                                  right: 0,
                                }}
                                onClick={() => {
                                  this.setState(
                                    {
                                      examsquest_file4:
                                        '',
                                      examsquest_file4_prev:
                                        '',
                                    }
                                  );
                                  this.examsquest_file4.current.value =
                                    null;
                                }}>
                                <FontAwesomeIcon
                                  icon={
                                    faTimesCircle
                                  }
                                  color='rgb(220, 53, 69)'
                                  style={{
                                    fontSize: 20,
                                  }}
                                />
                              </button>
                              {examsquest_file4_prev ==
                                'pdf' ? (
                                <FontAwesomeIcon
                                  icon={
                                    faFilePdf
                                  }
                                  style={{
                                    fontSize: 60,
                                  }}
                                />
                              ) : (
                                <img
                                  src={
                                    examsquest_file4_prev
                                  }
                                  alt={t(
                                    'Preview'
                                  )}
                                  width='100'
                                />
                              )}
                            </div>
                          ) : (
                            <button
                              onClick={() =>
                                this.examsquest_file4.current.click()
                              }
                              style={{
                                background:
                                  'none',
                                border: 0,
                                flex: 1,
                              }}>
                              <FontAwesomeIcon
                                icon={faPlus}
                                style={{
                                  fontSize: 30,
                                }}
                              />
                            </button>
                          )}
                        </div>
                        <Form.Control
                          className='d-none'
                          ref={this.examsquest_file4}
                          type='file'
                          onChange={this.previewFile}
                          name='examsquest_file4'
                          accept='.jpg, .jpeg, .png, .pdf'
                        />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col md={3}>
                    <Form.Group
                      controlId='file5'
                      className='mb-3'>
                      <Form.Label>
                        {t('File 5')}{' '}
                        <small className='text-danger'>
                          {t('(only Image or Pdf)')}
                        </small>
                      </Form.Label>
                      <div
                        style={{
                          flexDirection: 'column',
                        }}
                        className='pic-prev d-flex justify-content-center'>
                        <div
                          style={{
                            borderRadius: 5,
                            borderStyle: 'dashed',
                            borderWidth: 2,
                            borderColor: '#ced4da',
                            height: 158,
                            display: 'flex',
                            overflow: 'hidden',
                          }}>
                          {examsquest_file5 !== '' &&
                            examsquest_file5_prev !==
                            '' ? (
                            <div
                              className='d-flex justify-content-center align-items-center'
                              style={{
                                flex: 1,
                                position:
                                  'relative',
                              }}>
                              <button
                                style={{
                                  padding: 3,
                                  border: 3,
                                  background:
                                    'none',
                                  position:
                                    'absolute',
                                  top: 0,
                                  right: 0,
                                }}
                                onClick={() => {
                                  this.setState(
                                    {
                                      examsquest_file5:
                                        '',
                                      examsquest_file5_prev:
                                        '',
                                    }
                                  );
                                  this.examsquest_file5.current.value =
                                    null;
                                }}>
                                <FontAwesomeIcon
                                  icon={
                                    faTimesCircle
                                  }
                                  color='rgb(220, 53, 69)'
                                  style={{
                                    fontSize: 20,
                                  }}
                                />
                              </button>
                              {examsquest_file5_prev ==
                                'pdf' ? (
                                <FontAwesomeIcon
                                  icon={
                                    faFilePdf
                                  }
                                  style={{
                                    fontSize: 60,
                                  }}
                                />
                              ) : (
                                <img
                                  src={
                                    examsquest_file5_prev
                                  }
                                  alt={t(
                                    'Preview'
                                  )}
                                  width='100'
                                />
                              )}
                            </div>
                          ) : (
                            <button
                              onClick={() =>
                                this.examsquest_file5.current.click()
                              }
                              style={{
                                background:
                                  'none',
                                border: 0,
                                flex: 1,
                              }}>
                              <FontAwesomeIcon
                                icon={faPlus}
                                style={{
                                  fontSize: 30,
                                }}
                              />
                            </button>
                          )}
                        </div>
                        <Form.Control
                          className='d-none'
                          ref={this.examsquest_file5}
                          type='file'
                          onChange={this.previewFile}
                          name='examsquest_file5'
                          accept='.jpg, .jpeg, .png, .pdf'
                        />
                      </div>
                    </Form.Group>
                  </Col>
                </Row>
              </Tab>
            </Tabs>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant='secondary'
              onClick={this.handleCloseCreateUpdateQuestionModal}>
              {t('Cancel')}
            </Button>
            <Button
              variant='primary'
              onClick={this.addUpdateQuestion}>
              {isEditingQuestion
                ? t('Update Question')
                : t('Add Question')}
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal size='lg' show={this.state.questionsImport.modal.show} onHide={() => this.handleImportQuestionModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>{t('Import Questions')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.questionsImport.questionsForImport.map((item, index) => (
              <Card
                key={index}
                className='mb-3 q-card'>
                <Card.Body>
                  <input type='checkbox' onClick={(e) => this.checkUncheckQuestion(item.examsquest_id)} className='question-selector form-check-input' />
                  <div className='q-card-inner'>
                    <div>
                      <Badge bg="primary">
                        {
                          item
                            .eqtype_id
                            .label
                        }
                      </Badge>{' '}
                      <Badge bg="warning">
                        {t(
                          'Marks: '
                        ) +
                          item.examsquest_grade
                        }
                      </Badge>
                      {' '}
                      <Badge bg="info">
                        {t(
                          'Duration: '
                        ) +
                          item.examsquest_duration + ' ' + t('Minutes')
                        }
                      </Badge>
                      {' '}
                      <Badge bg="danger">
                        {t(
                          'Difficulty: '
                        ) +
                          item.eqdifficulty_name
                        }
                      </Badge>
                    </div>
                    <h3>
                      <strong>
                        <small>
                          {item.examsquest_desc1.replace(
                            /(<([^>]+)>)/gi,
                            ' '
                          )}
                        </small>
                      </strong>
                    </h3>
                  </div>
                </Card.Body>
              </Card>
            ))}
            <Paginate
              limit={this.state.questionsImport.limit}
              totalRecords={
                this.state.questionsImport.totalRecords
              }
              pageChange={
                this.getImportQuestions
              }
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => this.handleImportQuestionModal(false)}>
              {t('Close')}
            </Button>
            <Button disabled={this.state.questionsImport.working} variant="primary" onClick={() => this.insertImportQuestions()}>
              {t('Import Questions')}
              {this.state.questionsImport.working && (
                <>
                  {' '}
                  <Spinner
                    as='span'
                    animation='grow'
                    size='sm'
                    role='status'
                    aria-hidden='true'
                  />
                </>
              )}
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default AddEditExam;