import React, { Component } from 'react'
import { Container, Card, Row, Col, Button, DropdownButton, Dropdown, ButtonGroup, Modal, Form, Badge, Table, Alert, Image } from 'react-bootstrap'
import { t } from '../../helpers/translation_helper'
import NavBar from '../_partials/NavBar/_NavBar'
import Header from '../_partials/Header/_Header'
import { connect } from 'react-redux'
import { faArrowRight, faArrowsTurnToDots, faBook, faBullseye, faChartBar, faChartLine, faCircleDot, faFilePdf, faListOl, faPlus, faSearch, faUserCheck, faXmark } from '@fortawesome/free-solid-svg-icons'
import { brand_colors } from '../../helpers/brand_colors_helper'
import { NavLink } from 'react-router-dom'
import { changeColorOpacity, ifNavAllowed } from '../../helpers/general_helpers'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import GeneralCard from '../../helpers/GeneralCard'
import { toast } from 'react-toastify'
import { GET, POST } from '../../api'
import axios from 'axios'
import imgDescription from '../../assets/images/description.png'
import Select from 'react-select'
import { handleFormErrors } from '../../helpers/form_helpers'
import moment from 'moment'

const portalURL = process.env.REACT_APP_PORTAL_URL

class ReportCard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            gradesClassesData: [],
            reportcard_detailsModal: {
                show: false,
                title: '',
                toggle: (params, title, data = []) => this.setState({ reportcard_detailsModal: { ...this.state.reportcard_detailsModal, show: !this.state.reportcard_detailsModal.show, title, data, params } }, () => {
                    if (this.state.reportcard_detailsModal.show) {
                        this.getReportcardDetails()
                    }
                }),
                params: null,
                data: [],
                academic_classmain_subject: { data: [], selected: [] },
                save: () => this.saveReportCardDetails(),
                saving: false,
            }
        }
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.activeReportCard.repcard_id != prevProps.activeReportCard.repcard_id && this.props.data.repcard_id == this.props.activeReportCard.repcard_id) {
            this.getGradesClasses()
        }
    }


    getGradesClasses = async () => {
        if (this.getGradesClassesReq) {
            this.getGradesClassesReq.abort()
        }
        this.getGradesClassesReq = new AbortController()

        try {
            const res = await GET('report-card-generator/get-grades-classes',
                {
                    params: {
                        repcard_id: this.props.data.repcard_id
                    },
                    signal: this.getGradesClassesReq.signal,
                }
            )
            this.setState({ gradesClassesData: res.data, })
        } catch (err) {
            console.log('err', err)
            if (axios.isCancel(err)) {

            } else {
                toast.error(t('Something went wrong while fetching data!'))
            }
        }
    }

    getReportcardDetails = async () => {
        if (this.getGradesClassesReq) {
            this.getGradesClassesReq.abort()
        }
        this.getGradesClassesReq = new AbortController()

        try {
            const res = await GET('report-card-generator/get-reportcard-details',
                {
                    params: {
                        class_id: this.state.reportcard_detailsModal.params.class_id,
                        agegroup_id: this.state.reportcard_detailsModal.params.agegroup_id,
                        repcard_id: this.state.reportcard_detailsModal.params.repcard_id
                    },
                    signal: this.getGradesClassesReq.signal,
                }
            )
            let data = res.data.registrations.map((v, i) => {
                return { ...v, reportcard_detail_status: v.reportcard_detail_status }
            })
            let academic_classmain_subject = res.data.academic_classmain_subject.map((v, i) => { return { ...v, label: v.acs_name, value: v.acs_id } })

            this.setState({ reportcard_detailsModal: { ...this.state.reportcard_detailsModal, data: data, academic_classmain_subject: { data: academic_classmain_subject, selected: academic_classmain_subject } } })
        } catch (err) {
            console.log('err', err)
            if (axios.isCancel(err)) {

            } else {
                toast.error(t('Something went wrong while fetching data!'))
            }
        }
    }

    saveReportCardDetails = async () => {

        if (this.saveReportCardDetailsReq) {
            this.saveReportCardDetailsReq.abort()
        }
        this.saveReportCardDetailsReq = new AbortController()

        this.setState({ reportcard_detailsModal: { ...this.state.reportcard_detailsModal, saving: true } })
        let ReportcardDetailsData = this.state.reportcard_detailsModal.data.map((v, i) => {
            return { ...v, reportcard_detail_status: v.reportcard_detail_status }
        })

        const fData = new FormData()
        fData.append('class_id', this.state.reportcard_detailsModal.params.class_id)
        fData.append('repcard_id', this.state.reportcard_detailsModal.params.repcard_id)
        fData.append('ReportcardDetailsData', JSON.stringify(ReportcardDetailsData))
        fData.append('acs_ids', JSON.stringify(this.state.reportcard_detailsModal.academic_classmain_subject.selected.map((v, i) => parseInt(v.acs_id))))

        const res = await POST('report-card-generator/save-reportcard-details', fData, {
            signal: this.saveReportCardDetailsReq.signal,
        })
        if (res) {
            let resData = res.data
            if (resData.type) {
                this.setState({
                    reportcard_detailsModal: {
                        ...this.state.reportcard_detailsModal,
                        show: false,
                        saving: false,
                    },

                }, () => this.getGradesClasses())
                toast.success(t(res.data.message))
            } else {
                if (resData.data != null) {
                    handleFormErrors(resData.data)
                } else {
                    toast.error(res.data.message)
                }
                this.setState({
                    reportcard_detailsModal: {
                        ...this.state.reportcard_detailsModal,
                        saving: false,
                    }
                })
            }
        }
    }

    render() {
        const { lang_orientation: dir } = this.props.defaultLanguage
        const { gradesClassesData, reportcard_detailsModal } = this.state
        const { defaultTheme, activeReportCard } = this.props
        const { repcard_id, repcard_name, center_id, term_d_ids, centerlic_id, repcardtype_id, reg_year, repcardtype_title, ab_name, ab_color, repcardtype_desc, repcardtype_gradeslevel } = this.props.data
        let thisActive = (activeReportCard.repcard_id == repcard_id)

        console.log('RCARD', this.state)
        return (
            <div key={this.props.key} style={{
                padding: '1rem', minWidth: '30vw', backgroundColor: brand_colors[this.props.defaultTheme.theme_id].color8, borderRadius: '1rem', margin: '1rem 0', position: 'relative', boxShadow: '-1px 2px 16px 2px rgba(0,0,0,0.1)',
            }}>
                <div className='d-flex flex-row justify-content-between align-items-center'>
                    <div className='d-flex flex-row align-items-center justify-content-between pe-2 me-2' style={{ flex: 1, borderRight: 'solid 3px ' + brand_colors[this.props.defaultTheme.theme_id].color7 }}>
                        <div>
                            <strong style={{ fontSize: '1rem' }}>{repcard_name}</strong>
                        </div>
                        <div style={{ backgroundColor: brand_colors[this.props.defaultTheme.theme_id].color4_light, borderRadius: '1rem', color: brand_colors[this.props.defaultTheme.theme_id].color4, padding: '0.2rem 0.8rem' }}>{reg_year}</div>
                    </div>
                    <div className='d-flex flex-row align-items-center' style={{ flex: 1, }}>
                        <div style={{ fontSize: '0.8rem', color: ab_color, opacity: 0.8, marginRight: 10, fontWeight: '600' }}>
                            {ab_name}
                        </div>
                        <div style={{ fontSize: '0.8rem', color: brand_colors[this.props.defaultTheme.theme_id].color9, opacity: 0.8 }}>
                            <Alert variant='info' className='p-1 m-0' title={repcardtype_desc}>
                                <strong>{repcardtype_title}</strong> <Badge bg="info" >{repcardtype_gradeslevel == 1 ? t('Using Marks Definition') : t('By Marks')}</Badge>
                            </Alert>
                        </div>
                    </div>
                    <div className='d-flex justify-content-end align-items-center ms-2' style={{}}>

                        <div className='d-flex flex-row align-items-center me-2 cursor-pointer' style={{ backgroundColor: thisActive ? brand_colors[this.props.defaultTheme.theme_id].color4_light : brand_colors[this.props.defaultTheme.theme_id].color7, borderRadius: '1rem', padding: '0.2rem 0.8rem' }} onClick={() => activeReportCard.toggle(thisActive ? 0 : repcard_id)}>
                            <div className='d-flex align-items-center justify-content-center me-2' style={{ backgroundColor: brand_colors[this.props.defaultTheme.theme_id].color8, borderRadius: '100%', height: '1.1rem', width: '1.1rem' }}>
                                {thisActive && <FontAwesomeIcon icon={faCircleDot} color={brand_colors[this.props.defaultTheme.theme_id].color4} style={{ fontSize: '1rem', }} />}
                            </div>
                            <div style={{ color: thisActive ? brand_colors[this.props.defaultTheme.theme_id].color4 : brand_colors[this.props.defaultTheme.theme_id].color18, fontWeight: '600', fontSize: '1rem' }}>
                                {t('Preview')}
                            </div>
                        </div>
                        <div style={{ margin: '0 0.5rem' }} className='cursor-pointer' title={t('Edit')} onClick={() => this.props.edit(this.props.data, this.props.index)}>
                            <Image src={require('../../assets/images/edit.png')} style={{ width: '1.5rem', height: '1.5rem' }} />
                        </div>
                    </div>
                </div>

                {/* SHOW PREVIEW */}
                {
                    thisActive &&
                    <div>
                        {gradesClassesData.map((v, i) => {
                            return <Row className='my-3'>
                                <Col md={12}>
                                    <div className='grey-section-header'>
                                        <span style={{ fontWeight: 600, opacity: 0.8 }}>{v.agegroup_code}</span>
                                    </div>
                                </Col>
                                <Col md={12}>
                                    <Row>
                                        {v.classes.map((vv, ii) => {
                                            let data = {
                                                allData: vv,
                                                name: vv.class_theme,
                                                text1: <div><span className='mx-1 text-success'>{vv.posted} Posted</span> | <span className='mx-1 text-danger'>{vv.total - vv.posted} Remaining</span></div>,
                                                status: (vv.status == 1 == 1) ? true : false,
                                                cardImage: (vv.class_logo) ? portalURL + vv.center_id + '/classes/' + vv.class_logo : require('../../assets/images/noimage.png'),
                                                actions: [
                                                    {
                                                        type: 'preview', name: 'Show Students', image: null, iconName: faArrowRight, iconColor: brand_colors[this.props.defaultTheme.theme_id].color2, onClick: () => reportcard_detailsModal.toggle({ repcard_id, class_id: vv.class_id, agegroup_id: vv.agegroup_id }, repcard_name + ' - ' + vv.class_theme, [])
                                                    },
                                                ],
                                            }
                                            return <GeneralCard data={data} />
                                        })}
                                    </Row>
                                </Col>
                            </Row>
                        })}
                    </div>
                }

                <Modal show={reportcard_detailsModal.show} onHide={() => reportcard_detailsModal.toggle()} size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title>{reportcard_detailsModal.title}</Modal.Title>
                    </Modal.Header>
                    {reportcard_detailsModal.show &&
                        <Modal.Body className='custom-styled-form'>
                            <div className='mb-2'>
                                <Alert variant='info' className='p-1'>
                                    {t('Choose Subjects for the report, if you select Elective subjects they will filter down according to Child automatically.')}
                                </Alert>
                                <Select
                                    isMulti
                                    options={reportcard_detailsModal.academic_classmain_subject.data}
                                    onChange={(event) =>
                                        this.setState({ reportcard_detailsModal: { ...reportcard_detailsModal, academic_classmain_subject: { ...reportcard_detailsModal.academic_classmain_subject, selected: event } } })
                                    }
                                    value={reportcard_detailsModal.academic_classmain_subject.selected}
                                    menuPortalTarget={document.body}
                                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                    formatOptionLabel={option => (
                                        <div>
                                            <span style={{ fontSize: 14 }}>
                                                {option.label}
                                                {!parseInt(option.acs_mandatory) &&
                                                    <Badge className='ms-1' bg='success'>Elective</Badge>
                                                }
                                            </span>
                                        </div>
                                    )}
                                />
                            </div>

                            <Table size='sm'>
                                <thead>
                                    <tr>
                                        <th>
                                            <ButtonGroup size="sm" className='ms-3'>
                                                <Button variant="primary" onClick={() => {
                                                    reportcard_detailsModal.data = reportcard_detailsModal.data.map((v, i) => {
                                                        return { ...v, reportcard_detail_status: (v.reportcard_detail_status) ? v.reportcard_detail_status : moment().format('YYYY-MM-DD HH:mm:ss') }
                                                    })
                                                    this.setState({ reportcard_detailsModal })
                                                }}>{t('Select All')}</Button>
                                                <Button variant="warning" onClick={() => {
                                                    reportcard_detailsModal.data = reportcard_detailsModal.data.map((v, i) => {
                                                        return { ...v, reportcard_detail_status: null }
                                                    })
                                                    this.setState({ reportcard_detailsModal })
                                                }}>{t('De-Select All')}</Button>
                                            </ButtonGroup>
                                        </th>
                                        <th>{t('Posted')}</th>
                                        <th>{t('Actions')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {reportcard_detailsModal.data.map((v, i) => {
                                        return <tr>
                                            <td>
                                                <div className='d-flex flex-row align-items-start'>
                                                    <Form.Check type={'checkbox'} checked={(v.reportcard_detail_status) ? true : false} onChange={() => {
                                                        reportcard_detailsModal.data[i].reportcard_detail_status = (reportcard_detailsModal.data[i].reportcard_detail_status) ? null : moment().format('YYYY-MM-DD HH:mm:ss')
                                                        this.setState({ reportcard_detailsModal })
                                                    }} />
                                                    <div className='ms-3'>{v.child_name}</div>
                                                </div>
                                            </td>
                                            <td>{(v.reportcard_detail_status)
                                                ? <Badge bg='success'>
                                                    {moment(v.reportcard_detail_status).format('DD-MM-YYYY HH:mm:ss')}
                                                </Badge>
                                                : <Badge bg='danger'>
                                                    {t('NO')}
                                                </Badge>
                                            }</td>
                                            <td>
                                                <FontAwesomeIcon className='cursor-pointer' icon={faFilePdf} color={brand_colors[defaultTheme.theme_id].color11} onClick={() => {
                                                    let acs_ids = JSON.stringify(reportcard_detailsModal.academic_classmain_subject.selected.map((v) => v.acs_id))

                                                    let URL = process.env.REACT_APP_API_URL + "report-card-generator/generate-reportcard?" +
                                                        "auth_key=" + this.props.authData.authKey +
                                                        "&child_id=" + v.child_id +
                                                        "&class_id=" + reportcard_detailsModal.params.class_id +
                                                        "&agegroup_id=" + reportcard_detailsModal.params.agegroup_id +
                                                        "&repcard_id=" + reportcard_detailsModal.params.repcard_id +
                                                        "&acs_ids=" + acs_ids +
                                                        "&center_uuid=" + this.props.authData.centerData.center_uuid
                                                    window.open(
                                                        URL,
                                                        "PDF" + "_" + v.child_id + "_" + reportcard_detailsModal.params.repcard_id
                                                    );
                                                }} />
                                            </td>
                                        </tr>
                                    })}
                                </tbody>
                            </Table>
                        </Modal.Body>
                    }
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => reportcard_detailsModal.toggle()}>
                            {t('Close')}
                        </Button>
                        <Button variant="primary" onClick={reportcard_detailsModal.save}>
                            {t('Post Changes')}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div >
        )
    }
}

const mapStateToProps = (state) => ({
    authData: state.auth.authData,
    defaultLanguage: state.language.defaultLanguage,
    defaultTheme: state.theme.defaultTheme
})

export default connect(mapStateToProps, null)(ReportCard)
